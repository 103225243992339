import moment from 'moment';
import { getWalletServiceAPI } from './getWalletService-api';
import { addUpdateWalletServiceAPI } from './addUpdateWalletService-api';
import { getPlanInformation } from '../util/localStorageRepo';

export const cancelSSARecurringAPI = async () => {

  let payload = null
  let getWalletResponse = null
  let today = moment().format('YYYY-MM-DD')

  let response = await getWalletServiceAPI(getPlanInformation().accountId)
    if(response && response.data.statusCode === 200) {
      payload = response.data.gpGetWalletResponse.data
      getWalletResponse = response.data;
      if(!getWalletResponse.paymentMethod.includes('CMS')) {
        let timelines = payload.ssaTimelines
        let filteredTimeLines = timelines.filter(el=> moment(el.startDate) < moment(today))
        payload.ssaTimelines = filteredTimeLines

        let res = await addUpdateWalletServiceAPI(payload)
          if(res && res.success) {
            return {callFailed: false};
          } else {
            return {callFailed: true};
          }
      } else if(getWalletResponse.paymentMethod.includes('CMS')) {
        let timelines1 = payload.ssaTimelines
        let nextStopDate = payload.options.ssaNextAvailableStopDate
        let filteredTimeLines1 = timelines1.filter(el=> moment(el.startDate) < moment(today))
        filteredTimeLines1 = filteredTimeLines1.map(el=> {
          if(!(moment(el.startDate, "'YYYY-MM-DD'") > moment(today,'YYYY-MM-DD')) && el.stopDate == null ) {
            el.stopDate = nextStopDate
          }
          return el
        })
        payload.ssaTimelines = filteredTimeLines1

        let res = await addUpdateWalletServiceAPI(payload)
          if(res && res.success) {
            return {callFailed: false, stopDate : nextStopDate};
          } else {
            return {callFailed: true};
          }
      }
    } else {
      return {callFailed: true};
    }

};


export default cancelSSARecurringAPI;