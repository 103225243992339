import { Link, Typography, PopupTip } from '@uhc-tempo/components';
import React, { Component, Fragment } from 'react'
import { overviewBillPayAem, overviewSummAem } from '../paymentOverview/paymentOverview';
import moment from 'moment';
import { Markup } from 'interweave';
import GridSpinner from '../../UI/Spinner/GridSpinner';
import pdficon from '../../tokens/icons/pdf-icon.png';
import { triggerEvent } from '../../../server/util/analytics';
import { convertToCurrency } from '../../../server/util/util';
import PaymentModals from '../modals/PaymentModals';
import { trackLinkClick} from '../../../server/util/analytics';
import { editPaymentBtnClickSubject } from '../../../server/observableService';
import secureLocalStorage from 'react-secure-storage';
const camelCase = require('camelcase');
export class Table extends Component {
    constructor(props) {
        super(props);
        this.getPaymentCells = this.getPaymentCells.bind(this);
        this.getBillingCells = this.getBillingCells.bind(this);
        this.getTableHeaders = this.getTableHeaders.bind(this);
        this.amountToolTipRef = React.createRef();
    }

    componentDidMount() {
        this.isbToolTipAnalytics();
      }

    componentDidUpdate() {
        this.isbToolTipAnalytics();
    }

    componentWillUnmount() {
        const amountToolTipbtnId = document.getElementById('paymentdue_' + this.props.planNum);
        if (amountToolTipbtnId) {
            amountToolTipbtnId.removeEventListener('click', this.tooltipAnalytics);
        }
    }

    isbToolTipAnalytics() {
        const amountToolTip = this.amountToolTipRef.current;
            if (amountToolTip) {
                let amountToolTipTrace = amountToolTip.children[0]?.children[0]?.children[0]?.id;
                if (amountToolTipTrace) {
                    let amountToolTipbtnId = document.getElementById(amountToolTipTrace);
                    if (amountToolTipbtnId) {
                        amountToolTipbtnId.addEventListener('click', this.tooltipAnalytics);
                    }
                }  
            }
        return true;
    }

    tooltipAnalytics =() => {
        trackLinkClick('payment history:processed payments:tool-tip:amount')
        console.log('payment history:processed payments:tool-tip:amount')
    }

    isWithdrawalDateToday = (date, createdDate) => {
        return (date === moment(createdDate).format('YYYY-MM-DD'))
    } 

    goToEditPayment(index) {
        trackLinkClick("PaymentHistory:pendingTab:Edit")
        secureLocalStorage.setItem('editPayment', true);
        secureLocalStorage.setItem('paymentToEdit', JSON.stringify(this.props.data?.[index]));
        secureLocalStorage.setItem('estimatedAnnualPremium', parseFloat(this.props.estimatedAnnualPremium ? this.props.estimatedAnnualPremium :"0.00"));
        // document.getElementById('makeOneTime_' + this.props.planNum).click();
        editPaymentBtnClickSubject.next('editPayment');
    }

    getPendingPaymentStatus(cell) {
        if (this.props.plan.productType === 'SHIP') { 
            return overviewBillPayAem.auth_pending_tab_txt
        } else {
            if (cell?.paymentState.toUpperCase() === 'SCHEDULED') { 
                if (this.isWithdrawalDateToday(cell?.withdrawalDate, cell?.audit?.createdOn)) {
                    return 'Pending'
                } else {
                    return 'Scheduled'
                } 
             } else if (cell?.paymentState.toUpperCase() === 'PROCESS_PENDING' || cell?.paymentState.toUpperCase() === 'CCSALE' || cell?.paymentState.toUpperCase() === 'CCREJECTED' || cell?.paymentState.toUpperCase() === 'PROTEST_PENDING' || cell?.paymentState.toUpperCase() === 'FAILED_EWS_PENDING'){
                return overviewBillPayAem.auth_in_process_text
            } else {
                return 'No status available'
            }

        }
    }

    pendingTabCancelContent = {
        header: overviewBillPayAem.auth_pending_tab_cancel_modal_header,
        desc: overviewBillPayAem.auth_pending_tab_cancel_modal_desc,
        btn_txt: overviewBillPayAem.auth_pending_tab_cancel_modal_cancel_btn,
        btn_txt1: overviewBillPayAem.auth_pending_tab_cancel_modal_do_not_cancel_btn,
        planNum: this.props.planNum,
        } 
    pendingTabCancelModal = (index)=> {
        return <PaymentModals
                modalContent={this.pendingTabCancelContent}
                modalType='pendingTabCancel'
                cancelbtn={overviewBillPayAem.auth_cancel_pmt}
                pendingData = {this.props.data}
                printDownload = {this.props.printDownload}
                index = {index}
                superUser = {this.props.superUser}
                handleSuperUserErr={this.props.handleSuperUserErr}
                />
    } 
    
    //Get total remaining if bill isn't paid off
    getTotalRemaining(totalRemaining) {
        if (this.props.tabName === 'Payment') {
            return(
                <tr>
                    <td>{overviewBillPayAem.auth_total_amounts_txt}</td>
                    <td>${convertToCurrency(totalRemaining)}</td>
                    {this.props.productType === 'GOVT' && <td/>}
                    {this.props.paymentTab === 'Processed' ? (
                        <td/>
                    ) : null}
                </tr>
            )
        } else {
            return(
                <tr>
                    <td><Typography.Paragraph>{overviewBillPayAem.auth_total_amounts_txt}</Typography.Paragraph></td>
                    <td/>
                    <td></td>
                    <td><Typography.Paragraph>${convertToCurrency(totalRemaining)}</Typography.Paragraph></td>
                </tr>
            )
        }
    }

    editUpdateKey = (event) => {
        let shiftFirstLinkTable = document.getElementById('historyTable_' + this.props.planNum)?.getElementsByTagName('a')[0]
        if (event.shiftKey && event.key === "Tab" && shiftFirstLinkTable) {
            if(this.props.pendingTab && event.target.id === shiftFirstLinkTable.id){
                event.preventDefault();
                document.getElementById('paymentTermID_0').focus()
            } 
        }
    }
 //Set action column for pending payments based on payment state.
    setPendingPayAction(payState, index) {
        if (payState === 'SCHEDULED') {
            return (
                <Fragment>
                    <div>
                        {/* eslint-disable-next-line no-script-url*/}
                        <Link href="javascript:" onKeyDown={this.editUpdateKey} aria-describedby={!this.props.printDownload ? 'penStat_' + index + ' payMtd_' + index : null} id={!this.props.printDownload ? 'editUpdt_' + index : null} noIcon onClick={() => this.goToEditPayment(index)}>{overviewBillPayAem.auth_edit_pmt}</Link>
                    </div>
                    
                    <div className='editCancelDivider'></div>
                    <div>
                        {this.pendingTabCancelModal(index)}
                    </div>
                </Fragment>
            )
        } else if (payState === 'PROCESS_PENDING') {
            return <div> {this.pendingTabCancelModal(index)} </div>
        } else {
           return <Typography.Paragraph>{overviewBillPayAem.auth_action_not_available_txt}</Typography.Paragraph>
        }
    }

    checkAccountTypeProcess(cell, isbTenderTypes) {

        if (cell.bankAccountType) {
            if (this.props.plan.productType === 'GOVT') {
                if (cell.bankAccountType.toUpperCase() === 'EFT' || cell.bankAccountType.toUpperCase()=== 'SSA' ) {
                    return cell.bankAccountType.toUpperCase() 
                } else if (cell.bankAccountType.toUpperCase() === 'SAVINGS') {
                    if (cell.accountNumber !== null && cell.accountNumber !== '') {
                        return "EFT-Savings***" + cell.accountNumber.slice(-4)
                    } else {
                        return "EFT-" + camelCase(cell.bankAccountType, {pascalCase: true})
                    }
                } else if (cell.bankAccountType.toUpperCase()=== 'CHECKING' ) {
                    if (cell.accountNumber !== null && cell.accountNumber !== '') {
                        return "EFT-Checking***" + cell.accountNumber.slice(-4)
                    } else {
                        return "EFT-" + camelCase(cell.bankAccountType, {pascalCase: true})
                    }
                } else {
                    return camelCase(cell.bankAccountType, {pascalCase: true}) 
                }
            } else if (this.props.plan.productType === 'SHIP') {
                    
                        if (isbTenderTypes.has(cell.bankAccountType.toLowerCase())) {
                            let acctCCNum = ((isbTenderTypes.get(cell.bankAccountType.toLowerCase()).indexOf('EFT') < 0) || cell.accountNumber === null || cell.accountNumber === undefined) ? '' : cell.accountNumber.slice(-4)
                            if (isbTenderTypes.get(cell.bankAccountType.toLowerCase()).indexOf('EFT') > -1) {
                                return isbTenderTypes.get(cell.bankAccountType.toLowerCase()) + acctCCNum
                            } else {
                                return isbTenderTypes.get(cell.bankAccountType.toLowerCase())
                            }
                        } else {
                            return cell.bankAccountType
                        }      
                } else {
                    return camelCase(cell.bankAccountType, {pascalCase: true}) 
            }
        } else if (cell.cardType) {
            return "Card***"+cell.cardNumberLastFour
        } else {
            return "Not available"
        }        
    }

    checkAccountTypePending(cell, isbTenderTypes) {
        if(this.props.plan.productType === 'GOVT'){
        if (cell.bankAccount) {
            if (cell.bankAccount?.bankAccountType.toUpperCase()=== 'EFT' || cell.bankAccount?.bankAccountType.toUpperCase() === 'SSA') {
                return cell.bankAccount?.bankAccountType.toUpperCase() 
            }
            else if (cell.bankAccount?.bankAccountType.toUpperCase() === 'SAVINGS') {
                if ((cell.bankAccount?.accountNumber !== '' && cell.bankAccount?.accountNumber !== null)) {
                    return "EFT-Savings***" + cell.bankAccount?.accountNumber.slice(-4)
                } else {
                    return "EFT-" + camelCase(cell.bankAccount?.bankAccountType, {pascalCase: true})
                }
            } else if (cell.bankAccount?.bankAccountType.toUpperCase()=== 'CHECKING') {
                if ((cell.bankAccount?.accountNumber !== '' && cell.bankAccount?.accountNumber !== null)) {
                    return "EFT-Checking***" + cell.bankAccount.accountNumber.slice(-4)
                } else {
                    return "EFT-" + camelCase(cell.bankAccount?.bankAccountType, {pascalCase: true})
                } 
            } else if (cell.bankAccount?.bankAccountType) {
                return 'EFT-' + camelCase(cell.bankAccount?.bankAccountType, {pascalCase: true}) 
            } else {
                return overviewBillPayAem.auth_pending_not_available;
            } 
        } else if (cell.creditCard?.cardType) {
            return "Card***"+cell.creditCard?.cardNumberLastFour 
        } else {    
            return overviewBillPayAem.auth_pending_not_available  
        }  
    }

        if(this.props.plan.productType === 'SHIP') {
            if(isbTenderTypes?.has(cell.paymentMethod?.toLowerCase()) && (cell.accountNumber !== null && cell.accountNumber !== '')){
                return cell.paymentMethod + '***' + cell.accountNumber.slice(-4)
            } else if(isbTenderTypes?.has(cell.paymentMethod?.toLowerCase())){
                return isbTenderTypes.get(cell.paymentMethod.toLowerCase())
            } else {
                return overviewBillPayAem.auth_pending_not_available;
            }
        }
    }

    //Get current status of a bill
    getBillingStatus(cell, productType) {
        let isPaid = false
        if (productType === 'SHIP') {
            isPaid = cell.status === 'PAID' ? true : false ;
        } else {
            isPaid = (cell.balanceDue === 0 || cell.balanceDue < 0) ? true : false
        }
        return isPaid ? <span style={{ color: "rgb(0, 112, 0)" }}>{overviewBillPayAem.auth_paid_text}</span> : <span style={{ color: 'rgb(111, 111, 111)' }}>{overviewBillPayAem.auth_unpaid_text}</span>
    }

    //Hydrate mobile pending payments table
    getMobilePendingCells = (cell, index, productType, isbTenderTypes) => {
        if (cell) {
            return (
                <div className="mobileHistoryDataContainer" key={index}>
                    <div className="mobileHistoryDataHeaders">{overviewBillPayAem.auth_paydate_table_head}</div>
                    <div className="mobileHistoryDataCell">
                        <Typography.Paragraph>
                        {productType === 'SHIP' ?
                            <Typography.Paragraph>{cell.paymentDate? moment(cell.paymentDate).format('MM/DD/YYYY') : 'No date available'}</Typography.Paragraph>
                            :
                            <Typography.Paragraph>
                            {(cell.withdrawalDate)
                                ?
                                moment(cell.withdrawalDate).format('MM/DD/YYYY')
                                :
                                'No date available'
                            }
                            </Typography.Paragraph>
                        }
                        </Typography.Paragraph>
                    </div>
                    <div className="mobileHistoryDataHeaders">{overviewBillPayAem.auth_amt_table_head}</div>
                    <div className="mobileHistoryDataCell">
                    {productType === 'SHIP' ?
                        <Typography.Paragraph>{'$' + convertToCurrency(cell.paymentAmount)}</Typography.Paragraph>
                        :
                        <Typography.Paragraph>{convertToCurrency( 
                        cell.amount) < 0 ? 
                        '-$' + convertToCurrency(
                        cell.amount * -1) : 
                        '$' + convertToCurrency(
                        cell.amount)}
                        </Typography.Paragraph>
                    }
                    </div>  
                    <div className="mobileHistoryDataHeaders">{overviewBillPayAem.auth_status_table_head}</div>
                    <div className="mobileHistoryDataCell">
                    {this.props.plan.productType === 'SHIP' ?
                        <Typography.Paragraph><span style={{ color: 'rgb(90, 90, 90)' }}>{overviewBillPayAem.auth_pending_tab_txt}</span></Typography.Paragraph>
                        :
                        <Typography.Paragraph id={this.props.pendingTab ? 'penStat_' + index : null}><span style={{ color: 'rgb(90, 90, 90)' }}>{this.getPendingPaymentStatus(cell)} </span></Typography.Paragraph>
                    } 
                    </div>
                    <div className="mobileHistoryDataHeaders">{overviewBillPayAem.auth_pay_mthd_table_head}</div>
                    <div className="mobileHistoryDataCell">  
                        <Typography.Paragraph>
                        {this.checkAccountTypePending(cell, isbTenderTypes)}
                        </Typography.Paragraph>
                    </div> 
                    { this.props.plan.productType === 'GOVT' && 
                    (this.props.pendingTab && !this.props.printHistory) &&
                    <Fragment>
                        <div className="mobileHistoryDataHeaders">Action</div>
                        <div className="mobileHistoryDataCell">  
                        <div className={cell.paymentState === 'PROCESS_PENDING' ? 'pendingTable action cancelOnly' : 'pendingTable action'}>
                            { !this.props.printHistory && this.setPendingPayAction(cell.paymentState, index)}
                        </div>
                        </div>
                    </Fragment>
                    }
                </div>
            )
        }
    }

    //Hydrate pending payments table
    getPendingCells = (cell, index, productType, isbTenderTypes) => {
        if (cell) {
            return (
                <tr key={index}>
                    <td>
                        { productType === 'SHIP' ?
                            <Typography.Paragraph>{cell.paymentDate? moment(cell.paymentDate).format('MM/DD/YYYY') : 'No date available'}</Typography.Paragraph>
                            :
                            <Typography.Paragraph>
                            {(cell.withdrawalDate)
                                ?
                                moment(cell.withdrawalDate).format('MM/DD/YYYY')
                                :
                                'No date available'
                            }
                            </Typography.Paragraph>
                        }
                        
                    </td>
                    <td>
                    {productType === 'SHIP' ?
                        <Typography.Paragraph>{'$' + convertToCurrency(cell.paymentAmount)}</Typography.Paragraph>
                        :
                        <Typography.Paragraph>{convertToCurrency(productType === 'SHIP' ? 
                        cell.paymentAmount : 
                        cell.amount) < 0 ? 
                        '-$' + convertToCurrency(productType === 'SHIP' ? 
                        cell.paymentAmount : 
                        cell.amount * -1) : 
                        '$' + convertToCurrency(productType === 'SHIP' ? 
                        cell.paymentAmount : 
                        cell.amount)}
                        </Typography.Paragraph>
                    }   
                       
                    </td>
                    <td>
                    {this.props.plan.productType === 'SHIP' ?
                        <Typography.Paragraph><span style={{ color: 'rgb(90, 90, 90)' }}>{overviewBillPayAem.auth_pending_tab_txt}</span></Typography.Paragraph>
                        :
                        <Typography.Paragraph id={this.props.pendingTab ? 'penStat_' + index : null}><span style={{ color: 'rgb(90, 90, 90)' }}>{this.getPendingPaymentStatus(cell)} </span></Typography.Paragraph>
                    } 
                    </td>
                    <td className={this.props.pendingTab ? 'pendingTable mop' : ''}>
                        { !this.props.pendingTab ?
                        <Typography.Paragraph>{this.checkAccountType(cell)}</Typography.Paragraph>
                       :
                            <div >
                                <div>
                                    <Typography.Paragraph id={'payMtd_' + index}>
                                        {this.checkAccountTypePending(cell, isbTenderTypes)}
                                        </Typography.Paragraph>
                                </div>
                            </div>
                        }
                    </td>
                    { this.props.plan.productType === 'GOVT' ?
                     (this.props.pendingTab && !this.props.printHistory) &&
                        <td className={cell.paymentState === 'PROCESS_PENDING' ? 'pendingTable action cancelOnly' : 'pendingTable action'}>
                            <div>
                                { this.setPendingPayAction(cell.paymentState, index)}
                            </div>
                        </td>
                        :
                    null
                    }
                </tr>
            )
        }
    }

    //Hydrate mobile payment history table
    getMobilePaymentCells = (cell, index, productType, isbTenderTypes) => {
        if (cell) {
            return (
                <div className="mobileHistoryDataContainer" key={index}>
                    <div className="mobileHistoryDataHeaders">{overviewBillPayAem.auth_paydate_table_head}</div>
                    <div className="mobileHistoryDataCell">
                        <Typography.Paragraph>
                            {(cell.withdrawalDate)
                                ?
                                moment(cell.withdrawalDate).format('MM/DD/YYYY')
                                :
                                'No date available'
                            }
                        </Typography.Paragraph>
                    </div>
                    <div className="mobileHistoryDataHeaders">
                        {overviewBillPayAem.auth_amt_table_head}
                    </div>
                    <div className="mobileHistoryDataCell">
                        <Typography.Paragraph>{cell.amount < 0 ? '-$' + convertToCurrency(cell.amount * -1) : '$' + convertToCurrency(cell.amount)}</Typography.Paragraph>
                    </div>  
                    <div className="mobileHistoryDataHeaders">{overviewBillPayAem.auth_status_table_head}</div>
                    <div className="mobileHistoryDataCell">
                        <Typography.Paragraph>{this.props.plan.productType === 'SHIP' ? <span style={(cell.paymentState.toUpperCase() === 'FROZEN' || cell.paymentState.toUpperCase() === 'PROCESSED') ? { color: 'rgb(0, 112, 0)' } : { color: 'rgb(196, 0, 0)'}}>{(cell.paymentState.toUpperCase() === 'FROZEN' || cell.paymentState.toUpperCase() === 'PROCESSED') ? overviewBillPayAem.auth_processed : overviewBillPayAem.auth_failed }</span>: <span style={cell.paymentState.toUpperCase()  === 'PROCESSED' ? { color: 'rgb(0, 112, 0)' } : { color: 'rgb(196, 0, 0)'}}>{camelCase(cell.paymentState, {pascalCase: true})}</span>}</Typography.Paragraph>
                    </div>
                    <div className="mobileHistoryDataHeaders">{overviewBillPayAem.auth_pay_mthd_table_head}</div>
                    <div className="mobileHistoryDataCell">  
                        <Typography.Paragraph>{this.checkAccountTypeProcess(cell, isbTenderTypes)}</Typography.Paragraph>
                    </div> 
                      
                        <Fragment>
                                <div className="mobileHistoryDataHeaders">{overviewBillPayAem.auth_confirmation_header}</div>
                                <div className="mobileHistoryDataCell">
                                    <Typography.Paragraph>{cell.referenceNumber && cell.referenceNumber !== 0 ? cell.referenceNumber : (this.props.plan.productType === 'SHIP' ? overviewBillPayAem.auth_not_available_pay : overviewBillPayAem.auth_not_applicable_pay)}</Typography.Paragraph>
                                </div>
                        </Fragment>
                       
                </div>
            )
        }
    }

    //Hydrate payment history table
    getPaymentCells = (cell, index, productType, isbTenderTypes) => {
        if (cell) {
            return (
                <tr key={index}>
                    
                    <td >
                        <Typography.Paragraph>
                            {(cell.withdrawalDate)
                                ?
                                moment(cell.withdrawalDate).format('MM/DD/YYYY')
                                :
                                'No date available'
                            }
                        </Typography.Paragraph>
                    </td>
                    <td>
                        <Typography.Paragraph>{cell.amount < 0 ? '-$' + convertToCurrency(cell.amount * -1) : '$' + convertToCurrency(cell.amount)}</Typography.Paragraph>
                    </td>
                    <td>
                        {this.props.plan.productType === 'SHIP' ?
                        <Typography.Paragraph>{<span style={(cell.paymentState.toUpperCase() === 'FROZEN' || cell.paymentState.toUpperCase() === 'PROCESSED') ? {color: 'rgb(0, 112, 0)'} : { color: 'rgb(196, 0, 0)'}}>{(cell.paymentState.toUpperCase() === 'FROZEN' || cell.paymentState.toUpperCase() === 'PROCESSED') ? overviewBillPayAem.auth_processed : overviewBillPayAem.auth_failed }</span>}</Typography.Paragraph>
                        :
                        <Typography.Paragraph>{<span style={cell.paymentState?.toUpperCase()  === 'PROCESSED' || cell.paymentStatus?.toUpperCase() === 'PROCESSED' ? { color: 'rgb(0, 112, 0)' } : { color: 'rgb(196, 0, 0)'}}>{camelCase(cell.paymentState, {pascalCase: true})}</span>}</Typography.Paragraph>
                        }
                        </td>
                    <td>
                        <Typography.Paragraph>{this.checkAccountTypeProcess(cell, isbTenderTypes)}</Typography.Paragraph>
                    </td>
                    
                    <td>
                        <Typography.Paragraph>{cell.referenceNumber && cell.referenceNumber !== 0 ? cell.referenceNumber : (this.props.plan.productType === 'SHIP' ? overviewBillPayAem.auth_not_available_pay : overviewBillPayAem.auth_not_applicable_pay)}</Typography.Paragraph>
                    </td>
                    
                </tr>
            )
        }
    }

    splitDocId = (docId) => {
        if (/^\d+$/.test(docId)) {
            const chunks = docId.match(/.{1,4}/g);
            const joinDocId = chunks.join(' ');
            return joinDocId;
        }
    }

    downloadInvoicePDF = (pdfURL) => {
        let dtmPDFParams = { pdfName:'download-billinghistory'};
        triggerEvent('pdf',dtmPDFParams);
        window.open('/medicare'+pdfURL);
    };  
    

    //Hydrate mobile billing history table
    getMobileBillingCells = (cell, index) => {
        if (cell) {
            return (
                <div className="mobileHistoryDataContainer" key={index}>
                    <div className="mobileHistoryDataHeaders"><Typography.Paragraph>{overviewBillPayAem.auth_bill_duedate_table_head}</Typography.Paragraph></div>
                    <div className="mobileHistoryDataCell">
                        <Typography.Paragraph>
                                {(cell.dueDate)
                                    ?
                                    moment(cell.dueDate).format('MM/DD/YYYY')
                                    :
                                    'No date available'
                                }
                        </Typography.Paragraph>
                    </div>
                    <div className="mobileHistoryDataHeaders"><Typography.Paragraph>{overviewBillPayAem.auth_bill_amt_table_head}</Typography.Paragraph></div>
                    <div className="mobileHistoryDataCell">
                        <Typography.Paragraph>{cell.billedAmount < 0 ? '-$' + convertToCurrency(cell.billedAmount * -1) : '$' + convertToCurrency(cell.billedAmount)}</Typography.Paragraph>
                    </div>  
                    <div className="mobileHistoryDataHeaders"><Typography.Paragraph>{overviewBillPayAem.auth_status_table_head}</Typography.Paragraph></div>
                    <div className="mobileHistoryDataCell">
                        <Typography.Paragraph>{this.getBillingStatus(cell)}</Typography.Paragraph>
                    </div>
                    <div className="mobileHistoryDataHeaders"><Typography.Paragraph>{overviewBillPayAem.auth_remain_amt_table_head}</Typography.Paragraph></div>
                    <div className="mobileHistoryDataCell">  
                        <Typography.Paragraph style={{fontSize: '16px'}}>{(cell.balanceDue != null) ? '$' + convertToCurrency(cell.balanceDue) : 'Invalid Data' }</Typography.Paragraph>
                    </div> 
                        {this.props.plan.productType === 'GOVT' ? (
                            cell.invoiceLink ? (
                                <Fragment>
                                    <div className="mobileHistoryDataHeaders"><Typography.Paragraph>{overviewBillPayAem.auth_billing_statement_header}</Typography.Paragraph></div>
                                    <div className="mobileHistoryDataCell">
                                    <button className="tds-link tds-link--medium" role="link" onClick={() => this.downloadInvoicePDF(cell.invoiceLink)}><img src={pdficon} alt="" aria-hidden="true"/> <span className="download-csv-btn">{overviewBillPayAem.auth_download_pdf_txt}</span> </button>
                                    </div>
                                </Fragment>
                            ) : <Fragment>
                                    <div className="mobileHistoryDataHeaders"><Typography.Paragraph>{overviewBillPayAem.auth_billing_statement_header}</Typography.Paragraph></div>
                                    <div className="mobileHistoryDataCell"><Typography.Paragraph>{overviewBillPayAem.auth_bill_statement_not_available}</Typography.Paragraph></div>
                                </Fragment>
                        ) : null}
                </div>
            )
        }
    }
    //Hydrate billing history table
    getBillingCells = (cell, index, productType) => {
        if (cell) {
            return (
                <Fragment key={'row ' + index}>
                    <tr>
                        <td key={index}>
                            <Typography.Paragraph>{(cell.dueDate)
                                ?
                                moment(cell.dueDate).format('MM/DD/YYYY')
                                :
                                'No date available'
                            }</Typography.Paragraph>
                        </td>
                        <td>
                            <Typography.Paragraph>{this.props.plan.productType === 'SHIP' ? '$' + convertToCurrency(cell.amount) : cell.billedAmount < 0 ? '-$' + convertToCurrency(cell.billedAmount * -1) : '$' + convertToCurrency(cell.billedAmount)}</Typography.Paragraph>
                        </td>
                        <td>
                            <Typography.Paragraph>
                                { 
                                cell.invoiceDate != null ?
                                moment(cell.invoiceDate).format('MM/DD/YYYY')
                                :
                                'Not available'
                                }
                            </Typography.Paragraph>
                        </td>
                        <td>
                            <Typography.Paragraph>
                                {
                                    cell.invoiceNumber != null ?
                                    this.splitDocId(cell.invoiceNumber)
                                    : 
                                    'Not available'
                            
                                }
                            </Typography.Paragraph>
                            
                        </td>
                        {(!this.props.printHistory) ? (
                            <Fragment>
                                <td className={cell.invoiceLink ? null : 'statement-unavailable'}>
                                    {cell.invoiceLink ? (
                                        <div className="exportLabel" style={{ justifyContent: 'flex-start' }}>
                                            <Link noIcon className="tds-typography__link pdfLink" role="link" onClick={() => this.downloadInvoicePDF(cell.invoiceLink)}><img src={pdficon} alt="" aria-hidden="true"/> <span className="download-csv-btn">{overviewBillPayAem.auth_download_pdf_txt}</span> </Link>
                                        </div>
                                    ) : overviewBillPayAem.auth_bill_statement_not_available}            
                                </td>
                            </Fragment>
                        ) : null}

                    </tr>
                </Fragment>
            )
        }
    }

    //Load table headers into history table
    getTableHeaders = (productType) => {
        if (this.props.history === 'Payment') {
            return (
                <tr>
                    <th scope="col"> 
                        {overviewBillPayAem.auth_paydate_table_head}
                    </th>
                    <th scope="col" className="amtTooltip">
                        <div ref={this.amountToolTipRef} id={'paymentdue_' + this.props.planNum}>
                        {overviewBillPayAem.auth_amt_table_head}
                        {(!this.props.pendingTab && !this.props.printHistory && this.props.plan.productType === 'SHIP' && this.isbToolTipAnalytics()) &&
                        <PopupTip
                           icon="QuestionMark"
                           iconColor="primary"
                           placement="bottom"
                           className="ml-0"
                           contentId={'paymentdue_' + this.props.planNum}
                           title={overviewBillPayAem.auth_amt_tooltip_header}
                                            >
                           <Markup content={this.props.plan.productType === 'SHIP' ? overviewBillPayAem.auth_amt_tooltip_content_isb :  overviewBillPayAem.auth_amt_tooltip_content}/>
                        </PopupTip>
                        }
                        </div>
                    </th>
                    <th scope="col">
                        {overviewBillPayAem.auth_status_table_head}
                    </th>
                    <th scope="col" className={this.props.pendingTab ? 'pendingTableHead mop' : ''}>
                        {
                            !this.props.pendingTab ?
                            <div>
                                {overviewBillPayAem.auth_pay_mthd_table_head}
                            </div>
                             :
                             <div>
                                 <div>
                                     {overviewBillPayAem.auth_pay_mthd_table_head}
                                 </div>
                             </div> 
                         }
                    </th>
                    { this.props.plan.productType === 'GOVT' ?
                        (this.props.pendingTab && !this.props.printHistory) &&
                            <th scope="col" className='pendingTableHead action'>
                                <div>
                                    {overviewBillPayAem.auth_pending_pay_action_header}
                                </div>
                            </th>
                        :
                        null
                    }
                    {!this.props.pendingTab  ? (
                        <th scope="col">
                            {overviewBillPayAem.auth_confirmation_header}
                        </th>
                    ) : null}
                </tr>
            )
        } else {
            return (
                <tr>
                    <th scope="col">
                        {overviewBillPayAem.auth_isb_due_date}
                    </th>
                    <th scope="col" className="amtTooltip">
                        {(this.props.plan.productType === 'SHIP') ? 
                        <>
                            {overviewBillPayAem.auth_isb_total_amt_due}
                            {(!this.props.printHistory) ?
                                <PopupTip
                                icon="QuestionMark"
                                iconColor="primary"
                                placement="bottom"
                                className="ml-0"
                                contentId={'totalAmtdue_' + this.props.planNum}
                                title={overviewBillPayAem.auth_total_amt_due_tooltip_header}
                                                 >
                                <Markup content={this.props.plan.productType === 'SHIP' ? overviewBillPayAem.auth_total_amt_due_tooltip_content : overviewBillPayAem.auth_total_amt_due_tooltip_fed}/>
                             </PopupTip> 
                             : null
                            }
                            
                         </>
                            : 
                            overviewBillPayAem.auth_bill_amt_table_head
                            
                        }
                    </th>
                    <th scope="col">
                        {overviewBillPayAem.auth_isb_bill_statement_date}
                    </th>
                    <th scope="col">
                        { overviewBillPayAem.auth_isb_doc_id}
                    </th>
                    {(this.props.plan.productType === 'SHIP' && !this.props.printHistory || productType === 'GOVT' && !this.props.pendingTab && !this.props.printHistory) ? (
                        <th scope="col">
                            {overviewBillPayAem.auth_isb_bill_pdf}
                        </th>
                    ) : null}
                </tr>
            )
        }
    }

    //Build table after initial checks
    buildTable(data, productType) {
        // eslint-disable-next-line no-unused-vars
        let totalRemaining = 0;
        let totalAmount = 0;
        let isbTenderTypes = new Map();
            overviewSummAem.auth_isb_tender_types.split(',').forEach(el=> {
                isbTenderTypes.set(el.split(':')[0].trim().toLowerCase(), el.split(':')[1].trim())
            })
        
        return (
            <table 
                   className={
                    window.innerWidth < 769 ? 
                    'mobileHistoryData' : 
                   ((this.props.processedTab || this.props.history !== 'Payment') && this.props.plan.productType === 'GOVT')  ?  'historyData fivecol' : this.props.payHistoryTable && !this.props.pendingTab  ? 'historyData fivecol' : 'historyData'  } 
                   id={this.props.payHistoryTable ? 
                   'historyTables_' + this.props.planNum : 
                   'billingTables_' + this.props.planNum}
                   width = {(this.props.printHistory && window.innerWidth < 769) ? '75%' : undefined}
                   cellSpacing = "0"
                   cellPadding = "0"
                   >
                       {/* <caption className="sr-only">{this.props.pendingTab ? 'Pending payments' : 'Processed payments'}</caption> */}
                <thead >
                    {(this.props.desktopView || this.props.printHistory) && this.getTableHeaders(this.props.plan.productType)}
                </thead>
                <tbody>
                    {data.map((cell, index) => {
                        if (this.props.history === 'Billing') {
                            totalRemaining += cell.balanceDue;
                        }
                       
                        totalAmount += this.props.pendingTab ? parseFloat(cell.paymentAmount) : this.props.plan.productType === 'SHIP' ? parseFloat(cell.amount) : cell.amount
                        
                        return (
                            this.props.history === 'Payment' ?
                                this.props.pendingTab ?
                                window.innerWidth > 450 || this.props.printDownload ? this.getPendingCells(cell, index, productType, isbTenderTypes) : this.getMobilePendingCells(cell, index, productType, isbTenderTypes)
                                :
                                window.innerWidth > 450 || this.props.printDownload ? this.getPaymentCells(cell, index, productType, isbTenderTypes) : this.getMobilePaymentCells(cell, index, productType, isbTenderTypes)
                                :
                                window.innerWidth > 450 || this.props.printDownload ? this.getBillingCells(cell, index, productType) : this.getMobileBillingCells(cell, index, productType)
                        )
                    })
                }

                {(this.props.printHistory) ?
                  this.props.plan.productType === 'SHIP' && this.props.tabName !== "Billing" &&
                  this.getTotalRemaining(totalAmount)
                  :
                  null
                }
                </tbody>
            </table>
        )
    } 
    
    render() {
        return (
            <>
                {this.props.loading ? <GridSpinner /> : null}
                <div style={{ filter: this.props.loading ? 'blur(4px)' : '' }}>
                    {this.props.data && this.props.data.length > 0 ?
                        this.buildTable(this.props.data, this.props.plan.productType)
                        :
                        null
                    }
                </div>
            </>
        )
    }
}

export default Table
