import axios from '../../server/api/ucpPayments-api';
import appConfigs from '../app.config';
import { getDecStr} from '../../server/util/encDec';

export const getWalletServiceAPI = async (accountIdAlt) => {
    var getWalletServiceRequest = {
            "householdId": accountIdAlt
    };

    let accNumber = null;
    let routingNumber = null;

	const resp = axios.post(appConfigs.get_wallet_service_path , 
        getWalletServiceRequest).then(response =>{

            if (response.data.currentItem !=null && response.data.currentItem.bankAccount !=null){
                accNumber = getDecStr(response.data.currentItem.bankAccount.accountNumber);
                routingNumber = getDecStr(response.data.currentItem.bankAccount.routingNumber);

                response.data.currentItem.bankAccount.accountNumber = accNumber;
                response.data.currentItem.bankAccount.routingNumber = routingNumber;
            }

            if (response.data.futureItem !=null && response.data.futureItem.bankAccount !=null){
                accNumber = getDecStr(response.data.futureItem.bankAccount.accountNumber);
                routingNumber = getDecStr(response.data.futureItem.bankAccount.routingNumber);

                response.data.futureItem.bankAccount.accountNumber = accNumber;
                response.data.futureItem.bankAccount.routingNumber = routingNumber;
            }

            if(response.data.gpGetWalletResponse.data.items != null){

                response.data.gpGetWalletResponse.data.items.map( elt => {
                    if(elt.bankAccount != null){
                        accNumber = getDecStr(elt.bankAccount.accountNumber)
                        routingNumber= getDecStr(elt.bankAccount.routingNumber)
                     elt.bankAccount.accountNumber = accNumber;
                     elt.bankAccount.routingNumber= routingNumber;
                    }
                     return elt
                });
            }

            return response; 
		})
		.catch((error) => {
            if(error?.response?.status == 502) {
                return {data : {success : false, statusCode : 500}}
            }
            return error?.response
		});
        if (resp) {
            return resp
        }

};