import React, { Component } from 'react';
import commonConstants from '../../../server/util/commonConstants'
import Spinner from '../../../src/UI/Spinner/Spinner'

class CCRedirect extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
    if (window.location && window.location.href) {
      let queryParams = window.location.href.split('?')[1]
      if (window.location.href.indexOf(commonConstants.TOKENIZATION_RESPONSE_TYPE_TOKENIZATION) > -1) {
        let ccpathname= window.location.pathname.replace("/cc-iframe","");
        let pathNameLastChar = (ccpathname && ccpathname.length > 0 ? ccpathname.charAt(ccpathname.length - 1) : "");
        window.top.location.assign(window.location.origin + ccpathname  + '#/success-payments?' + queryParams)
      }
    }
  }

  render() {
    return (
      <div>
        <Spinner />
      </div>
    )
  }
}

export default CCRedirect
