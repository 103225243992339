import axios from './ucpPayments-api';
import appConfigs from '../app.config';
import { getEncStr,getDecStr} from '../../server/util/encDec';
export const getBillingPayments = async (customerId, currentDate, fromDate) => {
    var billingPaymentsRequest = {
        "BillingPaymentRequest": {
          "billingCustomerId": "",
          "customerId": customerId ? customerId : '',
          "customerType": "COMPAS",
          "fromDate": fromDate ? fromDate : '',
          "toDate": currentDate
        }
      };

    let accNumber = null;
    let routingNumber = null;

	return axios.post(appConfigs.billing_payments_service_path, billingPaymentsRequest)
            .then(response => {
                //Decryption area when decryption is setup for ISB
                    if(response?.data?.BillingPaymentResponse?.PaymentHistory?.paymentList && response?.data?.BillingPaymentResponse?.PaymentHistory?.paymentList?.length > 0)  {
                      response?.data?.BillingPaymentResponse?.PaymentHistory?.paymentList.map( elt => {
                                accNumber = elt.bankAccountNumber ? getDecStr(elt.bankAccountNumber): '';
                                routingNumber= elt.bankRoutingNumber ? getDecStr(elt.bankRoutingNumber): '';
                                elt.bankAccountNumber = accNumber;
                                elt.bankRoutingNumber = routingNumber;
                             return elt
                        });   
                    }
                    if(response?.data?.BillingPaymentResponse?.PaymentMethod?.paymentMethodList && response?.data?.BillingPaymentResponse?.PaymentMethod?.paymentMethodList?.length > 0)  {
                      response?.data?.BillingPaymentResponse?.PaymentMethod?.paymentMethodList.map( elt => {
                            accNumber = elt.bankAccountNumber ? getDecStr(elt.bankAccountNumber):'';
                            routingNumber= elt.bankRoutingNumber ? getDecStr(elt.bankRoutingNumber):'';
                         elt.bankAccountNumber = accNumber;
                         elt.bankRoutingNumber = routingNumber;
                         return elt
                        }); 
                    }
    
                return response.data;
            }) 
        
		.catch((error) => {
            console.log(error.message);
            return {
                callFailed: true
            };
		});

};


export default getBillingPayments;