import appConfigs from '../../server/app.config';
const CryptoJS = require("crypto-js");

export const getEncStr  = (str) => {
    
    //console.log("enc_key_str  "+appConfigs.encKeyStr);
    // Encrypt
      var enc_str = CryptoJS.AES.encrypt(
        CryptoJS.enc.Utf8.parse(str),
        CryptoJS.enc.Base64.parse(appConfigs.encKeyStr),
        {mode:CryptoJS.mode.ECB,padding: CryptoJS.pad.Pkcs7}
      ).toString();

     // console.log("enc_str "+enc_str);
    return enc_str;
};

export const getDecStr  = (str) => {
    
   // console.log("enc_key_str  "+appConfigs.encKeyStr);
    var decrypt = CryptoJS.AES.decrypt(str,CryptoJS.enc.Base64.parse(appConfigs.encKeyStr),
    {mode:CryptoJS.mode.ECB,padding: CryptoJS.pad.Pkcs7});
    var dec_str = CryptoJS.enc.Utf8.stringify(decrypt).toString();

    return dec_str;
};