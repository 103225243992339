import React, { Fragment } from "react";
import { useState, useEffect } from 'react';
import checkingaccimg from '../../assets/images/checkingAcc-Image.png';
import accNumImg from '../../assets/images/accNumber-Image.png';
import { Typography, Button, Modal, Link, FormGroup, Checkbox,ErrorMessage } from "@uhc-tempo/components";
import { Markup } from 'interweave';
import {getOverviewPageLink, getOneTimePaymentFlag, getRecurringPaymentFlag, isManageRecurring, isEditPayment, getPlanInformation, getMemberFullName} from '../../../server/util/localStorageRepo';
import { CircularProgress } from '@material-ui/core';
import { convertToCurrency } from '../../../server/util/util';
import { trackFormSubmit, trackModalWindowClick, trackLinkClick, trackError } from '../../../server/util/analytics';
import commonConstants from '../../../server/util/commonConstants'
import {removeItemFromWallet, statusFromUpdateWalletCall} from '../../../server/api/addUpdateWalletService-api'
import cancelGPSPaymentAPI from "../../../server/api/cancelGPSPaymentService-api";
import cloneDeep from 'lodash/cloneDeep';
import {cancelPaymentErrSubject, cancelSSAErrSubject} from '../../../server/observableService';
import cancelSSARecurringAPI from "../../../server/api/cancelSSARecurring-api";
import ServiceError from "../errors/ServiceError";
import secureLocalStorage from "react-secure-storage";

const PaymentModals = (props) => {
  const [open, setOpen] = useState(false);
  const [checkedBox, setCheckedBox] = useState(false);
  const [checkBoxErr, setCheckBoxErr] = useState('default');
  const [toOverview, setToOverview] = useState(false);
  const [cancelPaymentClicked, setCancelPaymentClicked] = useState(false);
  const [cancelSSAClicked, setCancelSSAClicked] = useState(false);
  const removeFlag = null;

  useEffect(()=> {
    if(open && (props.modalType === 'learnwaystopay')) {
    let imgAlt = document.getElementsByClassName('tds-modal__outer')[0].getElementsByTagName('img')
    for(let el of imgAlt){
      el.setAttribute('alt','')
    }
    let aTags = document.getElementsByClassName('tds-modal__outer')[0].getElementsByTagName('a')
    for(let el of aTags) {
    el.innerHTML = el.innerHTML + "<img src='/medicare/content/dam/image-icons/launch_icon.svg' alt=''><span class='open-new-window'>Opens in a new window</span>"
    }
    }
    if(open && (props.modalType === 'pendingTabCancel')) {
      let cancelLinkTableRole = document.getElementById('historyTables_0')
      if(cancelLinkTableRole){
        cancelLinkTableRole.setAttribute('role','presentation')
      }
    }

    },[open, props.modalType])

  const goToOverviewClicked = ()=> {
    setToOverview(true);
    trackLinkClick( isEditPayment() ? props.modalContent.header+':'+props.modalContent.cancelConfirmModal.cancelpay_txt : 'Cancel payment');
    setTimeout(function () {
      window.location.assign(getOverviewPageLink());
    }, props.modalType === 'nachazero' ? 100 : 3000)
  }

  const goToWallet = () => {
    window.location.reload();
    return false;
  }

  const gotToSetUpRecurring = () => {
    closeHandler();
    props.goToSetupRec(false)
  }

  const goToUpdateRecurring = () => {
      closeHandler(true);
      props.editRecurringWalletItem(false)
  }

  const goToOneTime = () => {
    closeHandler();
    props.goToSetupRec(true)
  }

  const getDiscountLabel = (label)=> {
    if(props.modalContent.discountMap.has(label.toLowerCase())) {
      return props.modalContent.discountMap.get(label.toLowerCase())
    }
    return label
  }
 

  const goToCancelPayment = ()=> {
    trackLinkClick(props.modalContent.header+':'+props.modalContent.btn_txt)
    setCancelPaymentClicked(true)
    let payment = cloneDeep(props.pendingData?.[props.index])
    payment.paymentState = "CANCEL"

    cancelGPSPaymentAPI(payment).then(res=> {
      if(res.success) {
        secureLocalStorage.setItem("cancelPaymentObj", JSON.stringify({success : true, amount : payment.amount, date: payment.withdrawalDate}))
        window.location.assign(getOverviewPageLink())
      } else {
        console.log(res)
        cancelPaymentErrSubject.next('cancelPaymentError')
        closeHandler(true)
        setTimeout(()=> {
          setCancelPaymentClicked(false)
          window.scroll(0,0)
        },500)
      }
    })
    .catch(err=> {
      console.log(err)
      cancelPaymentErrSubject.next('cancelPaymentError')
      closeHandler(true)
      setTimeout(()=> {
        setCancelPaymentClicked(false)
        window.scroll(0,0)
      },500)
    })
  }
const cancelCMSRecurring = () =>{
    trackLinkClick(props.modalContent.header+':'+props.modalContent.btn_txt)
    console.log(props.modalContent.header+':'+props.modalContent.btn_txt)
      setCancelSSAClicked(true)
      secureLocalStorage.setItem("planInfomation", JSON.stringify(props.planInformation))
      cancelSSARecurringAPI(props.planInformation?.accountId).then(res=> {
        if(res && !res.callFailed) {
          secureLocalStorage.setItem("cancelCmsRecObj", JSON.stringify({success : true, date : res.stopDate}))
          window.location.assign(getOverviewPageLink())
        } else if(res && res.callFailed) {
          cancelSSAErrSubject.next('cancelCmsError')
          closeHandler(true)
          setTimeout(()=> {
            setCancelSSAClicked(false)
            window.scroll(0,0)
          },500)
        } else {
          closeHandler(true)
          setTimeout(()=> {
            setCancelSSAClicked(false)
            window.scroll(0,0)
          },500)
        }
        
      })
  
}
  const removeCardFromWallet = (items, itemId,updateWalletData) => {
    closeHandler(true)
    props.modalContent.setLoading(true)
    let deletedItem = items.filter(el => {
      return  el.itemId === itemId
     })
     console.log(deletedItem)
    removeItemFromWallet(items, itemId,updateWalletData).then(res=> {
      if(res.success) {
        console.log('inside removeItemFromWallet')
        sessionStorage.setItem('notificationCount', 0)
        props.modalContent.getWalletDetails();
        if(document.getElementById('alert_Notificaiton')) {
          document.getElementById('alert_Notificaiton').focus()
        }
        trackFormSubmit(' manage my wallet', props.modalType==='ccitemRemove' ? 'manage my wallet - remove saved credit card/debit card payment method': props.modalType==='itemRemove' ? 'manage my wallet - remove saved EFT checking/savings payment method': null, 'PII Information');             
        } else {
      console.log(res)
      if(res.statusCode >= 400 || statusFromUpdateWalletCall >= 400) {
        
        props.removeApiErr(res)
        trackError(props.modalType==='ccitemRemove' ? 'manage my wallet - credit card/debit card - remove payment method': props.modalType==='itemRemove' ? 'manage my wallet - EFT checking/savings - remove payment method': null, 'manage my wallet', 'form validation error', (res.statusCode ? res.statusCode : statusFromUpdateWalletCall)); 
      }
      props.modalContent.setLoading(false)
    }
    }).catch(error=> {
      console.log(error)
      props.modalContent.setLoading(false)
    })
  }

  const getSuperUserMsg = (ccIndex, eftIndex) => {
    props.showSuperUserError(ccIndex, eftIndex)
  }

  const openHandler = () => {
    if (props.superUser && (props.modalType === 'pendingTabCancel' || props.modalType === 'cmsCancelPayment')) {
      setOpen(false);
      props.handleSuperUserErr();
    } else {
      if(props.modalType === 'itemRemove' || props.modalType === 'ccitemRemove' || props.modalType === 'recItemRemove' || props.modalType === 'recCCItemRemove') {
        props.modalContent.resetAllFormsAndErrors()
      }

      setOpen(true);
      document.body.className = 'body_modal';
    let currPage= secureLocalStorage.getItem('currPage');
      if(currPage && currPage !== ('overview') && currPage !== ('wallet') ) {
        
        trackModalWindowClick(currPage,props.modalType ==='ssaDeductExpl' ? props.modalContent.header : props.modalType ==='paymentamtexplained' ? 'Amounts Explained' : props.modalType === 'paymentRoutingNumber'? 'Routing Number' :
                    props.modalType === 'paymentAccNumber'? 'Account Number': (props.modalType === 'cancelPayment' || props.modalType === 'cancelSSAReq')? props.modalContent.header : props.modalType === 'earlyWarning' ?
                    'early warning':props.modalType === 'paymentAuth'? props.modalContent.header : props.modalType === 'ssb'?'ssb': null);

        trackLinkClick(props.modalType ==='ssaDeductExpl' ? props.modalContent.header: props.modalType ==='paymentamtexplained' ? 'Amounts Explained' : props.modalType === 'paymentRoutingNumber'? 'Routing Number' :
                props.modalType === 'paymentAccNumber'? 'Account Number': (props.modalType === 'cancelPayment' || props.modalType === 'cancelSSAReq')? props.modalContent.header : props.modalType === 'earlyWarning' ?
                'early warning':props.modalType === 'paymentAuth'?props.submit_btn: props.modalType === 'ssb'?'ssb': null);
          } else if(currPage === 'overview'){
            trackModalWindowClick(currPage,props.modalType ==='learnwaystopay' ? 'learn more ways to pay' : props.modalType==='paymentterms' ? 'understand payment definitions': props.modalType==='billingterms' ? 'understand billing definitions': props.modalType==='pendingTabCancel' ? props.modalContent.header :
            props.modalType==='cmsCancelPayment' ? props.modalContent.header : props.modalType ==='shipPremBrkDown' ? props.modalContent.header : props.modalType === 'isbViewDetails' ? props.modalContent.header : null);
            trackLinkClick(props.modalType ==='learnwaystopay' ? 'learn more ways to pay' : props.modalType==='paymentterms' ? 'understand payment definitions': props.modalType==='billingterms' ? 'understand billing definitions': props.modalType==='pendingTabCancel' ? props.modalContent.header : 
            props.modalType==='cmsCancelPayment' ? props.modalContent.header : props.modalType ==='shipPremBrkDown' ? 'premium breakdown' : props.modalType === 'isbViewDetails' ? 'new! combined household payments can no longer be processed:banner:view details' : null);
        } else if(currPage === 'wallet'){
          trackModalWindowClick(currPage,props.modalType ==='learnAboutWallet' ? 'learn about your wallet' : props.modalType==='ccitemRemove' ? 'manage my wallet - credit card/debit card - remove payment method': props.modalType==='itemRemove' ? 'manage my wallet - EFT checking/savings - remove payment method': props.modalContent.header);
          trackLinkClick(props.modalType ==='learnAboutWallet' ? 'learn about your wallet' : props.modalType==='ccitemRemove' ? 'manage my wallet - credit card/debit card - remove payment method': props.modalType==='itemRemove' ? 'manage my wallet - EFT checking/savings - remove payment method': props.modalContent.header);
        }  
    }
   }
  const closeHandler = (removeFlag , close)=> {
    document.body.removeAttribute('class');
    document.body.removeAttribute('style');
    if(props.modalType === 'nachazero') {
      return
    }
    if(close === 'icon') {
      trackLinkClick((props.modalType === 'paymentAuth'? props.modalContent.header +':'+'closed':props.modalType ==='ssaDeductExpl' ? props.modalContent.header +':'+props.modalContent.btn_txt: props.modalType ==='shipPremBrkDown' ? props.modalContent.header +':'+props.modalContent.btn_txt : props.modalType === 'cmsCancelPayment' ? props.modalContent.header +':'+props.modalContent.btn_txt1 : props.modalType === 'pendingTabCancel' ? props.modalContent.header +':'+props.modalContent.btn_txt1 : (props.modalType === 'cancelPayment' || props.modalType === 'cancelSSAReq') ? props.modalContent.header +':'+props.modalContent.btn_txt1 : (props.modalType === 'ccitemRemove' || 'itemRemove || recCCItemRemove || recItemRemove ') ? removeFlag ? props.modalContent.header +':'+ props.modalContent.btn_txt: props.modalContent.btn_txtCancel ? props.modalContent.header +':'+ props.modalContent.btn_txtCancel : props.modalContent.header +':'+ props.modalContent.btn_txt : props.modalContent.header +':'+ props.modalContent.btn_txt) + ' icon');
      console.log((props.modalType === 'paymentAuth'? props.modalContent.header +':'+'closed':props.modalType ==='ssaDeductExpl' ? props.modalContent.header +':'+props.modalContent.btn_txt: props.modalType ==='shipPremBrkDown' ? props.modalContent.header +':'+props.modalContent.btn_txt : props.modalType === 'cmsCancelPayment' ? props.modalContent.header +':'+props.modalContent.btn_txt1 : props.modalType === 'pendingTabCancel' ? props.modalContent.header +':'+props.modalContent.btn_txt1 : (props.modalType === 'cancelPayment' || props.modalType === 'cancelSSAReq') ? props.modalContent.header +':'+props.modalContent.btn_txt1 : (props.modalType === 'ccitemRemove' || 'itemRemove || recCCItemRemove || recItemRemove ') ? removeFlag ? props.modalContent.header +':'+ props.modalContent.btn_txt: props.modalContent.btn_txtCancel ? props.modalContent.header +':'+ props.modalContent.btn_txtCancel : props.modalContent.header +':'+ props.modalContent.btn_txt : props.modalContent.header +':'+ props.modalContent.btn_txt) + ' icon');
    } else {
      trackLinkClick(props.modalType === 'paymentAuth'? props.modalContent.header +':'+'closed':props.modalType ==='ssaDeductExpl' ? props.modalContent.header +':'+props.modalContent.btn_txt: props.modalType ==='shipPremBrkDown' ? props.modalContent.header +':'+props.modalContent.btn_txt : props.modalType === 'cmsCancelPayment' ? props.modalContent.header +':'+props.modalContent.btn_txt1 : props.modalType === 'pendingTabCancel' ? props.modalContent.header +':'+props.modalContent.btn_txt1 : (props.modalType === 'cancelPayment' || props.modalType === 'cancelSSAReq') ? props.modalContent.header +':'+props.modalContent.btn_txt1 : (props.modalType === 'ccitemRemove' || 'itemRemove || recCCItemRemove || recItemRemove ') ? removeFlag ? props.modalContent.header +':'+ props.modalContent.btn_txt: props.modalContent.btn_txtCancel ? props.modalContent.header +':'+ props.modalContent.btn_txtCancel : props.modalContent.header +':'+ props.modalContent.btn_txt : props.modalContent.header +':'+ props.modalContent.btn_txt);
      console.log(props.modalType === 'paymentAuth'? props.modalContent.header +':'+'closed': props.modalType ==='ssaDeductExpl' ? props.modalContent.header +':'+props.modalContent.btn_txt: props.modalType ==='shipPremBrkDown' ? props.modalContent.header +':'+props.modalContent.btn_txt :props.modalType === 'cmsCancelPayment' ? props.modalContent.header +':'+props.modalContent.btn_txt1 : props.modalType === 'pendingTabCancel' ? props.modalContent.header +':'+props.modalContent.btn_txt1 : props.modalType === 'cancelPayment' ? props.modalContent.header +':'+props.modalContent.btn_txt1 : (props.modalType === 'ccitemRemove' || 'itemRemove || recCCItemRemove || recItemRemove ') ? removeFlag ? props.modalContent.header +':'+ props.modalContent.btn_txt: props.modalContent.btn_txtCancel ? props.modalContent.header +':'+ props.modalContent.btn_txtCancel : props.modalContent.header +':'+ props.modalContent.btn_txt : props.modalContent.header +':'+ props.modalContent.btn_txt)
    }
      if((props.modalType === 'pendingTabCancel' && cancelPaymentClicked) || (props.modalType === 'cmsCancelPayment' && cancelSSAClicked)) {
      return
    }
    setOpen(false);
    if(!toOverview) {
        setOpen(false);
    }
    if(props.modalType === 'paymentAuth') {
      setCheckedBox(false);
      setCheckBoxErr('default');
      setOpen(false);
    }
    if(props.modalType == 'pendingTabCancel') {
      let cancelLinkTableRole = document.getElementById('historyTables_0')
      if(cancelLinkTableRole){
        cancelLinkTableRole.removeAttribute('role')
      }
    }
  }

  const acceptHandler = ()=> {
    
    if(checkedBox) {
      trackLinkClick(props.modalType === 'paymentAuth' ? props.modalContent.header+':'+props.modalContent.accept_btn: null);
      console.log(props.modalType === 'paymentAuth' ? props.modalContent.header+':'+props.modalContent.accept_btn: null)
      document.body.removeAttribute('class');
      document.body.removeAttribute('style');
      setCheckBoxErr('default');
      props.confirmAndPayHandler();
    } else {
      setCheckBoxErr('error');
      trackLinkClick(props.modalType === 'paymentAuth' ? props.modalContent.header+':'+props.modalContent.accept_btn+':'+props.modalContent.elec_pay_modal_error: null);
      
      document.getElementById('payauth').focus();
    }

  }

  const reviewModalCheckBoxHandler = ()=> {
    if(!checkedBox) {
      setCheckBoxErr('default');
    }
  }

  const paymentCncl = {
    header: 'Transaction canceled',
  }

    return (

      <React.Fragment>

                  {props.modalType === 'paymentRoutingNumber' || props.modalType === 'paymentAccNumber' ? (
                     <div >
                          <button className="fieldTooltip" type="button" role="link" aria-label={props.modalType === 'paymentAccNumber' ? 'Account Number Help' : 'Routing Number Help'}
                                 onClick={()=> openHandler()}>
                               <span className="helpText">{props.help_label_txt}</span> <span className="badge" aria-hidden="true">?</span>
                          </button>
                    </div>
                 ) : null }
                 {props.modalType === 'learnwaystopay' ? (
                   <>
                   <button className="tds-link tds-link--medium" type="button" role="link"
                   onClick={()=> openHandler()}>
                    <span className="link-text">{props.learnwayslinktitle}</span>
                    <span className="tds-link__icon-wrapper">⁠<svg className="MuiSvgIcon-root tds-utility-icon tds-link__icon tds-link__icon--internal" focusable="false" viewBox="0 0 24 24" color="#196ecf" aria-hidden="true" role="img"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path></svg></span>
                   </button>
                   </>
                 ): null}
                 {props.modalType === 'shipPremBrkDown' ? (
                   <>
                   <button className="tds-link tds-link--medium shipPremBrkDwn" type="button" role="link"  onClick={()=> openHandler()}>
                    <span className="link-text">{props.shipPremBrkDownLink}</span>
                    <span className="tds-link__icon-wrapper">⁠<svg className="MuiSvgIcon-root tds-utility-icon tds-link__icon tds-link__icon--internal" focusable="false" viewBox="0 0 24 24" color="#196ecf" aria-hidden="true" role="img"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path></svg></span>
                   </button>
                   </>
                 ): null}
                 {props.modalType === 'creditBalanceDetails' ? (
                   <>
                   <button className="tds-link tds-link--medium creditBalanceLink" type="button" role="link"  onClick={()=> openHandler()}>
                    <span className="link-text">{props.creditBalanceLink}</span>
                    <span className="tds-link__icon-wrapper">⁠<svg className="MuiSvgIcon-root tds-utility-icon tds-link__icon tds-link__icon--internal" focusable="false" viewBox="0 0 24 24" color="#196ecf" aria-hidden="true" role="img"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path></svg></span>
                   </button>
                   </>
                 ): null}
                 {props.modalType === 'isbViewDetails' ? (
                   <>
                   <button className="tds-link tds-link--medium creditBalanceLink" type="button" role="link"  onClick={()=> openHandler()}>
                    <span className="link-text">{props.isbViewDetailsLink}</span>
                    <span className="tds-link__icon-wrapper">⁠<svg className="MuiSvgIcon-root tds-utility-icon tds-link__icon tds-link__icon--internal" focusable="false" viewBox="0 0 24 24" color="#196ecf" aria-hidden="true" role="img"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path></svg></span>
                   </button>
                   </>
                 ): null}
                 {props.modalType === 'learnAboutWallet' ? (
                   <>
                   <button className="tds-link tds-link--medium" type="button" role="link"
                   onClick={()=> openHandler()}>
                    <span className="link-text">{props.learnwayslinktitle}</span>
                    <span className="tds-link__icon-wrapper">⁠<svg className="MuiSvgIcon-root tds-utility-icon tds-link__icon tds-link__icon--internal" focusable="false" viewBox="0 0 24 24" color="#196ecf" aria-hidden="true" role="img"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path></svg></span>
                   </button>
                   </>
                 ): null}

                  {props.modalType === 'itemRemove' ? (
                   <>
                   <button 
                   role="link"
                   className="tds-link tds-link--medium"
                   id={'eft_remove_'+props.modalContent.itemIndex}  
                   aria-describedby={props.item === props.scheduledBankItemId ? ('eft_acc_details_'+props.modalContent.itemIndex + ' ' + 'rec_pay_sch_'+props.modalContent.itemIndex) : 
                   props.item === props.recBankItemId ? ('eft_acc_details_'+props.modalContent.itemIndex + ' ' + 'rec_pay_on_'+props.modalContent.itemIndex)  : 
                   'eft_acc_details_'+props.modalContent.itemIndex }
                   onClick={()=> props.superUser ? getSuperUserMsg(null, props.modalContent.itemIndex) : openHandler()}>{props.removeLinkTxt}</button>
                  </>
                 ): null}

                  {props.modalType === 'ccitemRemove' ? (
                   <>
                   <button 
                   role="link"
                   id={'cc_remove_'+props.modalContent.itemIndex}  
                   className="tds-link tds-link--medium"  
                   aria-describedby={props.item === props.scheduledCCItemId ? ('cc_acc_details_'+props.modalContent.itemIndex + ' ' + 'cc_rec_pay_sch_'+props.modalContent.itemIndex) : 
                   props.item === props.recBankItemId ? ('cc_acc_details_'+props.modalContent.itemIndex + ' ' + 'cc_rec_pay_on_'+props.modalContent.itemIndex)  : 
                   'cc_acc_details_'+props.modalContent.itemIndex}
                   onClick={()=> props.superUser ? getSuperUserMsg(props.modalContent.itemIndex, null) : openHandler()}>{props.removeLinkTxt}</button>
                   </>
                 ): null} 

                  {props.modalType === 'recItemRemove' ? (
                   <>
                   <button
                   role="link" 
                   id={'eft_rec_remove_'+props.modalContent.itemIndex}  
                   className="tds-link tds-link--medium"  
                   aria-describedby={props.item === props.scheduledBankItemId ? ('eft_acc_details_'+props.modalContent.itemIndex + ' ' + 'rec_pay_sch_'+props.modalContent.itemIndex) : props.item === props.recBankItemId ? ('eft_acc_details_'+props.modalContent.itemIndex + ' ' + 'rec_pay_on_'+props.modalContent.itemIndex)  : 'eft_acc_details_'+props.modalContent.itemIndex}
                   onClick={()=> props.superUser ? getSuperUserMsg(null, props.modalContent.itemIndex) : openHandler()}>{props.removeLinkTxt}</button>
                   </>
                 ): null}

                  {props.modalType === 'recCCItemRemove' ? (
                   <>
                   <button
                   role="link" 
                   id={'cc_rec_remove_'+props.modalContent.itemIndex}  
                   className="tds-link tds-link--medium"  
                   aria-describedby={props.item === props.scheduledCCItemId ? ('cc_acc_details_'+props.modalContent.itemIndex + ' ' + 'cc_rec_pay_sch_'+props.modalContent.itemIndex) : 
                   props.item === props.recBankItemId ? ('cc_acc_details_'+props.modalContent.itemIndex + ' ' + 'cc_rec_pay_on_'+props.modalContent.itemIndex)  : 
                   'cc_acc_details_'+props.modalContent.itemIndex}
                   onClick={()=> props.superUser ? getSuperUserMsg(props.modalContent.itemIndex, null) : openHandler()}>{props.removeLinkTxt}</button>
                   </>
                 ): null}

                  {props.modalType === 'paymentDueAmt' ? (
                   <>
                   <button className="tds-link tds-link--medium payDueAmt" type="button" role="link"
                   onClick={()=> openHandler()}>
                    <span className="link-text">{props.paymentdueAmtLink}</span>
                    <svg width="16" height="16" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><g fill="none" fillRule="evenodd"><path d="M9 0C4.032 0 0 4.032 0 9s4.032 9 9 9 9-4.032 9-9-4.032-9-9-9zm.9 15.3H8.1v-1.8h1.8v1.8zm1.863-6.975l-.81.828C10.305 9.81 9.9 10.35 9.9 11.7H8.1v-.45c0-.99.405-1.89 1.053-2.547l1.116-1.134A1.76 1.76 0 0010.8 6.3c0-.99-.81-1.8-1.8-1.8s-1.8.81-1.8 1.8H5.4c0-1.989 1.611-3.6 3.6-3.6s3.6 1.611 3.6 3.6c0 .792-.324 1.512-.837 2.025z" opacity="1" fill="#002677" fillRule="evenodd"></path></g></svg>
                   </button>
                   </>
                 ): null}

                 {props.modalType === 'ssaDeductExpl' &&
                  <div><Link onClick={() => openHandler()} href="javascript:">{props.deductLink} </Link></div>
                 }

                {props.modalType === 'shipDiscount' &&
                    <button className="tds-link tds-link--medium smallTxt" id="shipDiscountId" onClick={() => openHandler()} >
                      <span>{props.linkName}</span>  
                  </button>
                }
                {props.modalType === 'nofurtherpay' || props.modalType === 'fedonepay'? (
                   <>
                    <Button id={'makeOneTime_' + props.modalContent.planNum} onClick={()=> openHandler()}>{props.makepaybtn}</Button>
                   </>
                 ): null}

                {props.modalType === 'secondrec' ? (
                   <>
                   <Button className="ctaRecurring" id={'recurrinBtn_' + props.modalContent.planNum} buttonType="secondary-one" onClick={()=> openHandler()}>{props.managepaybtn}</Button>
                   </>
                 ): null}
                 {props.modalType === 'nomanagepay' ? (
                   <>
                   <button style={{marginTop:"5px"}} aria-describedby="rec_sch_id" className="tds-link tds-link--medium" role="link" onKeyDown={props.onKeyDownAction} id={'recurrinBtn_' + props.modalContent.planNum} onClick={() => openHandler()}>
                   {props.managepaybtn}<span style={{marginLeft:"-4px"}} className="tds-link__icon-wrapper">⁠<svg style={{paddingBottom:"2px"}} className="MuiSvgIcon-root tds-utility-icon tds-link__icon tds-link__icon--internal" focusable="false" viewBox="0 0 24 24" color="#196ecf" aria-hidden="true" role="img"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path></svg></span>
                  </button>
                   </>
                 ): null}

                {props.modalType === 'noPaymentCmsSSA' ? (
                   <>
                    <Button id={'makeOneTime_' + props.modalContent.planNum} onClick={()=> openHandler()}>{props.makepaybtn}</Button>
                   </>
                 ): null}

                  {props.modalType === 'paymentterms' ? (
                   <>
                   <button id={'paymentTermID_' + props.planNum} onKeyDown={props.paymentTermLink} tabIndex="-1" className="tds-link tds-link--medium" type="button" role="link"
                   onClick={()=> openHandler()}>
                    <span> {props.paymenttermslinktitle} </span>
                    <span className="tds-link__icon-wrapper">⁠<svg className="MuiSvgIcon-root tds-utility-icon tds-link__icon tds-link__icon--internal" focusable="false" viewBox="0 0 24 24" color="#196ecf" aria-hidden="true" role="img"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path></svg></span>
                   </button>
                   </>
                 ): null} 

                  {props.modalType === 'billingterms' ? (
                   <>
                   <button id={'billingTermID_' + props.planNum} onKeyDown={props.paymentTermLink} className="tds-link tds-link--medium"  tabIndex="-1" type="button" role="link"
                   onClick={()=> openHandler()}>
                    <span> {props.billingtermslinktitle} </span>
                    <span className="tds-link__icon-wrapper">⁠<svg className="MuiSvgIcon-root tds-utility-icon tds-link__icon tds-link__icon--internal" focusable="false" viewBox="0 0 24 24" color="#196ecf" aria-hidden="true" role="img"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path></svg></span>
                   </button>
                   </>
                 ): null} 

                 {(props.modalType === 'cancelPayment' || props.modalType === 'cancelSSAReq') ? (
                    <button
                    name="next"
                    aria-disabled="false"
                    
                    id="cancelBtn"
                    className="tds-button tds-button--secondary-one ml-4"
                    onClick={()=> openHandler()}
                  >
                    {props.cancel_btn_txt}
                  </button>
                 ): null}
                 {props.modalType === 'cmsCancelPayment' ? (
                    <Link
                    name="next"
                    aria-disabled="false"
                    href="javascript:"
                    id="cancelCmsBtn"
                    className="cmsCancelPaymentLink"
                    onClick={()=> openHandler()}
                  >
                    {props.cancel_cms_btn_txt}
                  </Link>
                 ): null}
                 {props.modalType === 'pendingTabCancel' ? (
                    <Link noIcon
                    name="next"
                    aria-disabled="false"
                    href="javascript:"
                    id={'cancelBtn_' + props.index}
                    aria-describedby={!props.printDownload ? 'penStat_' + props.index + ' ' + 'payMtd_' + props.index : null}
                    className=""
                    onClick={()=> openHandler()}
                  >
                    {props.cancelbtn}
                  </Link>
                 ): null}

                    {props.modalType === 'mfaLogout' ? (
                    <button
                    name="next"
                    aria-disabled="false"
                    id='mfaLgou'
                    className="tds-button tds-button--secondary-one ml-4"
                    onClick={()=> openHandler()}
                  >
                    {props.btn_text}
                  </button>
                 ): null}

                {props.modalType === 'nachazero' ? (
                    <button
                    name="next"
                    aria-disabled="false"
                    id='nachazerobtn'
                    className="tds-button tds-button--secondary-one ml-4"
                    onClick={()=> openHandler()}
                  >
                    {props.btn_text}
                  </button>
                 ): null}

                {props.modalType === 'ssb' ? (
                  <div>
                  <span>{props.setup_footer_note}</span>
                    <button
                    role="link"
                    aria-disabled="false"
                    id='ssb_link'
                    className="tds-button tds-button--primary-one"
                    onClick={()=> openHandler()}
                  >
                    {props.ssb_link_text}
                  </button>
                  </div>
                 ): null}

                {props.modalType === 'earlyWarning' ? (
                  <Fragment>
                    <br />
                    <span>
                      <Markup content= {props.eftSection.eft_error_sec4} noWrap='true'/>
                          <button
                          role="link"
                          type="button"
                          syle={{noWrap:'true'}}
                          className="tds-link tds-link--medium linkBtn error-href"
                          rel=""
                          onClick={()=> openHandler()}
                        >
                        {' ' + props.eftSection.eft_early_warning_button_txt.trim() }

                        </button>
                        <Markup content= {props.eftSection.eft_error_sec3.replace("<p>","").replace("</p>","")} noWrap='true'/>
                      </span>
                    </Fragment>
                 ) : null }

                 {props.modalType === 'shipDiscount' && open &&
                  <Modal handleClose={() => closeHandler()} className="mt-5 shipDiscountModal" id={props.modalType === 'shipDiscount' && 'shipDiscount'}>
                    <Modal.Header className="shipDiscountModalHeader">
                      <Modal.Title>{props.modalContent?.header}</Modal.Title>
                    </Modal.Header>
                    <Modal.Content>
                      <div className="shipDiscountModalContent">
                          <Markup content={props.modalContent?.desc} />
                        {/* <Typography.Paragraph>
                          {props.modalContent?.footnote}
                        </Typography.Paragraph> */}
                        <Button 
                          buttonType="primary-one"
                          className="tds-margin-xsm-horizontal mr-2"
                          disabledBorder={false}
                          loading={false}
                          onClick={()=>gotToSetUpRecurring()}
                          size="md"
                        >
                          {props.managepaybtn}
                        </Button>
                        <Button
                            buttonType="secondary-one"
                            className="tds-margin-xsm-horizontal"
                            disabledBorder={false}
                            loading={false}
                            onClick={()=> closeHandler()}
                            size="md"
                            >
                              {props.modalContent?.btn_txt}
                            </Button>
                      </div>
                    </Modal.Content>
                  </Modal>
                 }

                 {props.modalType === 'noPaymentCmsSSA' && open &&
                  <Modal handleClose={() => closeHandler()} className="mt-5 shipDiscountModal" id={props.modalType === 'shipDiscount' && 'shipDiscount'}>
                    <Modal.Header className="shipDiscountModalHeader">
                      <Modal.Title>{props.modalContent?.header}</Modal.Title>
                    </Modal.Header>
                    <Modal.Content>
                      <div className="shipDiscountModalContent">
                          <Markup content={props.modalContent?.desc} />
                        <Button 
                          buttonType="primary-one"
                          className="tds-margin-xsm-horizontal mr-2"
                          disabledBorder={false}
                          loading={false}
                          onClick={()=>closeHandler()}
                          size="md"
                        >
                          {props.modalContent?.btn_txt}
                        </Button>
                      </div>
                    </Modal.Content>
                  </Modal>
                 }

                 {props.modalType === 'paymentamtexplained' ? (
                                  <div className="payAmtExp">
                                  <button className="uhc-question-icon" id="question-icon" role="link" onClick={()=> openHandler()}><span className="pr-1">{props.modalContent.link_label}</span> <span className="badge" aria-hidden="true">?</span></button>
                              </div>
                 ) : null }
                 {props.modalType === 'paymentAuth' ? (
                                  <button aria-disabled="false"
                                  aria-describedby="electronicText"
                                  onClick={()=> openHandler()}
                                  id="confirmBtn"
                                  className="tds-button tds-button--primary-one tds-margin-xsm-horizontal uhc-confirm-btn">
                                    {props.submit_btn}
                                 </button>
                 ) : null }
                  {open && (
                  <div>
                  {props.modalType === 'paymentRoutingNumber' || props.modalType === 'mfaLogout' || props.modalType === 'creditBalanceDetails' || props.modalType === 'isbViewDetails' || props.modalType === 'shipPremBrkDown' || props.modalType === 'nofurtherpay' || props.modalType === 'nomanagepay' || props.modalType === 'secondrec' || props.modalType === 'paymentDueAmt' || props.modalType === 'ssb' || 
                  props.modalType ==='cmsCancelPayment' || props.modalType === 'cancelPayment' || props.modalType === 'cancelSSAReq' || props.modalType === 'pendingTabCancel' || props.modalType === 'paymentAuth' || props.modalType === 'paymentAccNumber' || props.modalType === 'paymentamtexplained' || props.modalType === 'toOverview' || props.modalType === 'fedonepay' || props.modalType === 'nachazero' || props.modalType === 'itemRemove' || props.modalType === 'ccitemRemove' || props.modalType === 'recItemRemove' || props.modalType === 'recCCItemRemove' || props.modalType === 'ssaDeductExpl' ? (
                    <Modal handleClose={() => closeHandler(removeFlag , 'icon')} className={ props.modalType==="ssaDeductExpl" ? "mt-5 ssaDeduct" : "mt-5"} id={props.modalType === 'cancelPayment' ? 'cancelpay' : props.modalType === 'cancelSSAReq' ? 'cancelSSARequest': null}>
                    <Modal.Header>
                            <Modal.Title>
                            {toOverview ? props.modalContent.cancelConfirmModal.header :props.modalContent.header}
                            </Modal.Title>

                    </Modal.Header>
                    <Modal.Content>
                        <div className="tds-typography__paragraph" >
                        {<Markup content= {props.modalContent.desc}/>}
                        {props.modalType === 'paymentAuth' ? (
                            <div> 
                              {props.payMethod === 'CC' ||  props.payWithSavedCC  ? (
                                <div className="uhc-content_height"><Markup content={props.modalContent.credit_desc} /></div> 
                                ): getOneTimePaymentFlag() || isEditPayment()  ? (
                                  <div className="uhc-content_height"><Markup content= {props.modalContent.desc_content_onetime}/></div>
                                ): getRecurringPaymentFlag() || isManageRecurring() ? (
                                  <div className="uhc-content_height"><Markup content= {props.modalContent.desc_content_setup}/></div>
                                ):null}

                            </div>
                        ): null}
                        {(props.modalType === 'cancelPayment' && isEditPayment() && !toOverview) ? props.modalContent.cancelpay_txt : ''}
                        {props.modalType === 'cmsCancelPayment' && props.modalContent.cmsDesc ? 
                        <Typography.Paragraph style={{paddingBottom : '15px'}}>{props.modalContent.cmsDesc}</Typography.Paragraph> :null}
                        {(props.modalType === 'cancelPayment' || props.modalType === 'cancelSSAReq') && toOverview ? (
                          <div className="bckTxt">
                            <Typography.Paragraph>{props.modalContent.cancelConfirmModal.bckTxt}</Typography.Paragraph>
                            <div className='center'>
                              <CircularProgress style={{marginTop : '10px'}} disableShrink />
                            </div>

                          </div>
                        ) : null }


                        {props.modalType === 'paymentamtexplained' ? (
                            <div className="payExpContent">
                              <Markup content= {props.modalContent.section_1}/>
                            </div>

                          ) : null }
                          {props.modalType === 'nachazero' ? (
                            <Fragment>
                              <div>
                                <Markup content= {props.modalContent.page  ? props.modalContent.wallet_ews : props.modalContent.model_msg} />
                              </div>
                              {props.modalContent.page && <p></p>}
                              <div>
                                <Markup content = {props.modalContent.ews_contact} />
                              </div>
                            </Fragment>
                            ) : null}

                        </div>
                        {props.modalType === 'paymentRoutingNumber' ?
                        <Typography.Paragraph className="mt-4">
                            <img src={checkingaccimg} alt="" style={{ width: '100%', height: 'auto' }}/>
                        </Typography.Paragraph>: null }
                        {props.modalType === 'paymentAccNumber' ? 
                        <Typography.Paragraph className="mt-4">
                           <img src={accNumImg} alt="" style={{ width: '100%', height: 'auto' }}/>
                        </Typography.Paragraph> : null }

                      {props.modalType === 'shipPremBrkDown' ?
                        props.isbPremiumDetailsError ? 
                        <div className="isbOverviewContainer" id='isbErrorMessage'>
                          <div className="isbPlanContainer">
                            <ServiceError isbPremiumDetailsError={props.isbPremiumDetailsError}/>
                          </div>
                        </div> 
                        :
                        <>
                          {/* <Markup content={props.modalContent.householdContent} /> */}
                          
                          {props.premiumBreakDownResults && props.premiumBreakDownResults.response !== null && props.premiumBreakDownResults.response.planList 
                              && props.premiumBreakDownResults.response.planList.length > 0 ? 
                          <table className="householddata" cellPadding="0" cellSpacing="0" id="userBreakdown">
                            <caption className="householdatacaption">{getMemberFullName().trimEnd().toLowerCase()}<span style={{textTransform: 'lowercase', paddingLeft: '0px'}}>'s</span> {' '} <span style={{textTransform: 'lowercase', paddingLeft: '0px'}}>{props.modalContent.breakdownheadertext}</span></caption>
                            <thead>
                            <tr>
                              <th>{props.modalContent.coveragetablehead}</th>
                              <th>{props.modalContent.amounttablehead}</th>
                            </tr>
                            </thead>
                              
                              <tbody>
                              {props.premiumBreakDownResults.response.planList.map((premiumDetails, index) => (
                              <>
                              <tr>
                                {props.shipPlanNamesList && props.shipPlanNamesList.length >0 && props.shipPlanNamesList.map(
                                 (PlanNamesList) => {
                                 if(premiumDetails.planCode === PlanNamesList.planCode){
                                  return (
                                    <>
                                    <td>{PlanNamesList.planName}</td>
                                    {premiumDetails.chargeList && premiumDetails.chargeList.length > 0 && premiumDetails.chargeList.map(
                                   (charges) => {
                                     if(charges.type.indexOf('Base Rate') > -1) {
                                      return (
                                        <td className="premBrkDwnAmt">{charges.amount.indexOf('-') > -1 ? convertToCurrency(charges.amount).replace('-','-$') : '$'+convertToCurrency(charges.amount)}</td>
                                      );
                                     }
                                   }
                                 )}
                                  </>
                                  )
                                 }}
                                 )}
                              </tr>
                              {premiumDetails.chargeList && premiumDetails.chargeList.length > 0 &&
                              premiumDetails.chargeList.map((adDetails, index) =>(
                                <tr>
                                {adDetails.type.indexOf('Base Rate') == -1 && adDetails.type.indexOf('Surcharge') == -1 &&
                                <td className="brkdwnsubtext"><span>{getDiscountLabel(adDetails.type)}</span></td>}
                                {adDetails.type.indexOf('Base Rate') == -1 && adDetails.type.indexOf('Surcharge') == -1 && (adDetails.amount !== null ?
                                <td className="premBrkDwnAmt">{adDetails.amount.indexOf('-') > -1 ? convertToCurrency(adDetails.amount).replace('-','-$') : 
                                '$'+convertToCurrency(adDetails.amount)}</td> :<td className="brkdwnsubtext">Not available</td>)}
                              </tr>
                              ))}
                              </>
                              ))}
                              </tbody>
                             
                          </table> :null}
                          <div className="premBrkDwnBlueBg" aria-describedby="userBreakdown">
                              <span className="householdtotaltext">{props.modalContent.isb_total_label}</span>
                              {props.premiumBreakDownResults && props.premiumBreakDownResults.response !== null ? 
                                props.premiumBreakDownResults.response?.customerDetails && props.premiumBreakDownResults.response?.customerDetails?.totalPremiumAmount >= 0 ?
                              <span className="householdtotalamt">${convertToCurrency(props.premiumBreakDownResults.response?.customerDetails?.totalPremiumAmount)}</span> :<span style={{fontSize:"16px"}}>{props.premiumBreakDownResults.response?.customerDetails?.totalPremiumAmount}</span>
                            :'Not Available'}
                          </div>
                          
                          <div className="mt-3">
                            <Markup content={props.modalContent.brkdwninstext_isb} />
                          </div>
                          </>
                        :null}

                    </Modal.Content>
                    <Modal.Footer>
                    {props.modalType == 'nachazero' ? (
                    <Button
                        buttonType="primary-one"
                        className="tds-margin-xsm-horizontal"
                        disabledBorder={false}
                        loading={false}
                        onClick={()=> props.modalContent.page === 'wallet' ? goToWallet() : goToOverviewClicked()}
                        size="md"
                    >
                        {props.modalContent.btn_text}
                    </Button>
                    ) : null }
                    {props.modalType == 'mfaModal' ? (
                      <>
                    <Button
                        buttonType="primary-one"
                        className="tds-margin-xsm-horizontal"
                        disabledBorder={false}
                        loading={false}
                        onClick={()=> props.modalContent.page === 'wallet' ? goToWallet() : goToOverviewClicked()}
                        size="md"
                    >
                        {props.modalContent.btn_text}
                    </Button>

                    <Button
                        buttonType="secondary-one"
                        className="tds-margin-xsm-horizontal"
                        disabledBorder={false}
                        loading={false}
                        onClick={()=> props.modalContent.page === 'wallet' ? goToWallet() : goToOverviewClicked()}
                        size="md"
                    >
                        {props.modalContent.btn_text1}
                    </Button>
                    </>
                    ) : null }
                    {(props.modalType === 'cancelPayment' || props.modalType === 'cancelSSAReq') && !toOverview ? (
                      <div className={props.modalType === 'cancelSSAReq' ? 'ssa-cancel-btn_grp' : ''}>
                            <Button
                            buttonType="primary-one"
                            className="tds-margin-xsm-horizontal mr-2"
                            disabledBorder={false}
                            loading={false}
                            onClick={()=> goToOverviewClicked()}
                            size="md"
                            >
                                {/* {props.modalContent.btn_txt} */}
                                {props.modalContent.cancelConfirmModal.cancelpay_txt}
                            </Button>

                            <Button
                            buttonType="secondary-one"
                            className="tds-margin-xsm-horizontal"
                            disabledBorder={false}
                            loading={false}
                            onClick={()=> closeHandler()}
                            size="md"
                            >
                              {props.modalContent.btn_txt1}
                            </Button>


                          </div>

                        ) : null }

                      {props.modalType === 'itemRemove' || props.modalType === 'ccitemRemove' ? (
                      <div>
                            <Button
                            buttonType="primary-one"
                            className="tds-margin-xsm-horizontal mr-2"
                            disabledBorder={false}
                            loading={false}
                            onClick={()=> removeCardFromWallet(props.modalContent.items, props.modalContent.itemId,props.modalContent.updateWalletData)}
                            size="md"
                            >
                                {props.modalContent.btn_txt}
                            </Button>

                            <Button
                            buttonType="secondary-one"
                            className="tds-margin-xsm-horizontal"
                            disabledBorder={false}
                            loading={false}
                            onClick={()=> closeHandler()}
                            size="md"
                            >
                              {props.modalContent.btn_txtCancel}
                            </Button>


                          </div>

                        ) : null }

                    {props.modalType === 'recItemRemove' || props.modalType === 'recCCItemRemove' ? (
                      <div>
                            <Button
                            buttonType="primary-one"
                            className="tds-margin-xsm-horizontal mr-2"
                            disabledBorder={false}
                            loading={false}
                            onClick={()=> goToUpdateRecurring()}
                            size="md"
                            >
                                {props.modalContent.btn_txt}
                            </Button>

                            <Button
                            buttonType="secondary-one"
                            className="tds-margin-xsm-horizontal"
                            disabledBorder={false}
                            loading={false}
                            onClick={()=> closeHandler()}
                            size="md"
                            >
                              {props.modalContent.btn_txtCancel}
                            </Button>


                          </div>

                        ) : null }


                        {props.modalType === 'pendingTabCancel' && !cancelPaymentClicked ? (
                        <div>
                            <Button
                            buttonType="primary-one"
                            className="tds-margin-xsm-horizontal mr-2 mb-2"
                            disabledBorder={false}
                            loading={false}
                            onClick={()=> goToCancelPayment()}
                            size="md"
                            >
                                {props.modalContent.btn_txt}
                            </Button>

                            <Button
                            buttonType="secondary-one"
                            className="tds-margin-xsm-horizontal"
                            disabledBorder={false}
                            loading={false}
                            onClick={()=> closeHandler()}
                            size="md"
                            >
                              {props.modalContent.btn_txt1}
                            </Button>


                          </div>

                        ) : null }
                        {props.modalType === 'cmsCancelPayment' && !cancelSSAClicked ? (
                        <div className="cmsCancel-mobile">
                            <Button
                            buttonType="primary-one"
                            className="tds-margin-xsm-horizontal mr-2 mb-2"
                            disabledBorder={false}
                            loading={false}
                            onClick={()=> cancelCMSRecurring()}
                            size="md"
                            >
                                {props.modalContent.btn_txt}
                            </Button>

                            <Button
                            buttonType="secondary-one"
                            className="tds-margin-xsm-horizontal"
                            disabledBorder={false}
                            loading={false}
                            onClick={()=> closeHandler()}
                            size="md"
                            >
                              {props.modalContent.btn_txt1}
                            </Button>


                          </div>

                        ) : null }
                        {((props.modalType === 'pendingTabCancel' && cancelPaymentClicked) || 
                        (props.modalType === 'cmsCancelPayment' && cancelSSAClicked)) && 
                          <div className='cancel-spinner'>
                              <CircularProgress disableShrink />
                          </div> } 

                        {props.modalType === 'ssb' ? (
                          <div className="ssb-mobile">
                            <a className='tds-button tds-button--primary-one mr-2 ssbBtn'
                                role='button'
                                href={commonConstants.PREM_DEDUCT}
                                target='_blank' rel="noreferrer">
                                {props.modalContent.ssb_btn_txt1}  
                            </a>
                            
                            <a className='tds-button tds-button--secondary-one mr-2 ssbBtn'
                                role='button'
                                href={commonConstants.CONTACT_US}
                                target='_blank' rel="noreferrer">
                                {props.modalContent.ssb_btn_txt2}  
                            </a>
                            </div>
                        ) : null}

                          {props.modalType === 'paymentAuth' ? (

                            <div>
                              <div>
                              <FormGroup
                            alignment="vertical"
                            aria-labelledby="colors"
                            groupName="color"
                            hideInstruction={false}
                            state={checkBoxErr}
                            className="mt-2"
                          >
                            <Checkbox
                              id="payauth"
                              label={props.modalContent.elec_pay_modal_checkbox_content}
                              value={props.modalContent.elec_pay_modal_checkbox_content}
                              onClick= {()=> setCheckedBox(!checkedBox)}
                              onChange= {()=> reviewModalCheckBoxHandler()}
                              className="uhc-lineheight-22"
                            />
                            <ErrorMessage state="error">
                            {props.modalContent.elec_pay_modal_error} </ErrorMessage>
                          </FormGroup>
                          </div>
                          <div className="uhc-button-center mt-3">
                                  <Button
                                  buttonType="primary-one"
                                  onClick={()=> acceptHandler()}
                                  size="md"
                                  id="agreement-accept-btn"
                                  className="tds-button tds-button--primary-one tds-margin-xsm-horizontal">
                                    {props.modalContent.accept_btn}
                                 </Button>
                                 </div>
                                 </div>
                            ):null}

                    {props.modalType === 'paymentRoutingNumber' || props.modalType === 'creditBalanceDetails' || props.modalType === 'isbViewDetails' || props.modalType === 'nofurtherpay' || props.modalType === 'nomanagepay' || props.modalType === 'secondrec' || props.modalType === 'paymentDueAmt' || 
                    props.modalType === 'paymentAccNumber' || props.modalType === 'paymentamtexplained' || props.modalType === 'fedonepay' || props.modalType === 'ssaDeductExpl' ? (
                    <Button
                        buttonType="primary-one"
                        className="tds-margin-xsm-horizontal"
                        disabledBorder={false}
                        loading={false}
                        onClick={()=> closeHandler() }
                        size="md"
                    >
                        {props.modalContent.btn_txt}
                    </Button>
                    
                    ) : null }
                    </Modal.Footer>

                  
                </Modal>
                  ) :
                  <>
                    {props.modalType === 'earlyWarning' || props.modalType === 'learnwaystopay' || props.modalType === 'learnAboutWallet'  || props.modalType === 'paymentterms' || props.modalType === 'billingterms' ? (
                          <Modal handleClose={() => closeHandler()} className="right">
                          <Modal.Header>
                                  <Modal.Title>
                                    {props.modalContent.header}
                                  </Modal.Title>
      
                          </Modal.Header>
                          <Modal.Content>
                              
                            <Typography.Paragraph>
                                {props.modalType === 'earlyWarning' ? (
                                  <div>
                                    <Markup content= {props.modalContent.model_msg}/>
                                 </div>
                                ) : null}

                                {props.modalType === 'learnwaystopay' ? (
                                  <div>
                                      <Markup content= {props.modalContent.desc}/>
                                     <Button
                                        buttonType="primary-one"
                                        className="tds-margin-xsm-horizontal mt-3"
                                        disabledBorder={false}
                                        loading={false}
                                        onClick={()=> closeHandler() }
                                        size="md"
                                    >
                                        {props.modalContent.btn_txt}
                                    </Button>
                             </div>
                                ) : null} 
                                {props.modalType === 'learnAboutWallet' ? (
                                  <div>
                                      <Markup content= {props.modalContent.desc}/>
                                     <Button
                                        buttonType="primary-one"
                                        className="tds-margin-xsm-horizontal mt-3"
                                        disabledBorder={false}
                                        loading={false}
                                        onClick={()=> closeHandler() }
                                        size="md"
                                    >
                                        {props.modalContent.btn_txt}
                                    </Button>
                             </div>
                                ) : null}

                                {props.modalType === 'paymentterms' || props.modalType === 'billingterms' ? (
                                  <div>
                                    <Markup content= {props.modalContent.desc}/>
                                    <Button
                                      buttonType="primary-one"
                                      className="tds-margin-xsm-horizontal mt-3"
                                      disabledBorder={false}
                                      loading={false}
                                      onClick={()=> closeHandler() }
                                      size="md"
                                  >
                                      {props.modalContent.btn_txt}
                                  </Button>
                                 </div>
      
                                ) : null}

                           </Typography.Paragraph>
      
                          </Modal.Content>
                          <Modal.Footer>

                          </Modal.Footer>
      
      
                      </Modal>
                    ):null}
                  </>
                  

                  }
                      </div>
                )}

                        </React.Fragment>
    );
}
export default PaymentModals
