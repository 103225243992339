import React from "react";
import ModalPopup from "../modalPopUps/modalPopup";
import {getOverviewPageLink, getOneTimePaymentFlag, getOneTimePageLink, getRecurringPageLink} from '../../../server/util/localStorageRepo';
import secureLocalStorage from "react-secure-storage";

const SessionExpireModal = ({
    showSessionModal = false
  }) => {
    const actionNotAllowedMsg = 'Sorry, this action is not allowed.';
    const actionNotAllowedRedirectionMsg = 'Please use the buttons inside this webpage to navigate';
    const path = secureLocalStorage.getItem('currPage') === 'review' ?  getOneTimePaymentFlag() ? getOneTimePageLink() : getRecurringPageLink() : getOverviewPageLink();
    setTimeout(function () {
      window.location.assign(path);
    }, 3000)

    return (<ModalPopup
        isOpen={showSessionModal}
        arialabelled="SessionExpiredModal"
      >
        <div>
         <h2>{actionNotAllowedMsg}</h2> 
        </div>
        <div>
         <h3>{actionNotAllowedRedirectionMsg}</h3>
        </div>
      </ModalPopup>)
}
export default SessionExpireModal