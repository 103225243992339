import React from 'react';

const IconCheckmark2CCirRGB = () => {
	return (
		<svg
			width={84}
			height={84}
			aria-hidden="true"
			focusable="false"
		>
			
			<g id="Desktop-View-10" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Confirm-EFT" transform="translate(-366.000000, -328.000000)" fillRule="nonzero">
					<g id="Icon_CheckMrk_2C_Cir_RGB" transform="translate(366.000000, 328.000000)">
						<circle id="Oval" fill="#002677" cx="42" cy="42" r="42" />
						<g id="Group" transform="translate(15.250000, 24.750000)">
							<polygon
								id="Rectangle"
								fill="#00BED5"
								transform="translate(9.592470, 20.971151) rotate(45.000000) translate(-9.592470, -20.971151) "
								points="1.81379435 15.4149543 17.3711452 15.4149543 17.3711452 26.5273477 1.81379435 26.5273477"
							/>
							<polygon
								id="Path"
								fill="#FFFFFF"
								points="41.0125 0.555 11.1575 30.3925 19.015 38.25 56.71 0.555"
							/>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default IconCheckmark2CCirRGB;
