export const capitalizeVal  = (value) => {
    let capitalizeText = ''
    if (typeof value !== 'string') return capitalizeText;
    
    if(value !== null && value.length > 0) {
        value = value.trim().toLowerCase();
        if(value.length > 0) {
            let values = value.split(" ");
            if(values.length > 0) {
                values.map((data) => {
                    let capitalizeValue = capitalize(data);
                    capitalizeText = (capitalizeText.length > 0 ? capitalizeText + ' ' + capitalizeValue : capitalizeValue);
                });
            }
        }
    }
    return capitalizeText;
};

const capitalize = (val) => {
    if (typeof val !== 'string') return ''
    return val.charAt(0).toUpperCase() + val.slice(1)
}

export const isStringObject = (val) => {
    return (Object.prototype.toString.call(val) === "[object String]");
}

export const isOfflineMedicare = () => {
    var environment = window.location.origin;
    return environment.indexOf('offline.medicare') > -1 || environment.indexOf('medicare.uat');
}

export const convertJsonToString = (val) => {
    let jsonText = JSON.stringify(val);
    if(jsonText) {
        jsonText = jsonText.replace(/\"/g, "'");
    }
    return jsonText;
}

export const isDesktop = ()=> {
    return window.innerWidth > 768 ? true : false
}

export const convertToCurrency = (amount) => {
    amount = amount ? amount : 0.00
    return parseFloat(amount).toLocaleString('en-US', {
      style: 'decimal',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })
  }
