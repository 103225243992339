import config from './config';

const host = window.location.hostname;
const uriPath = window.location.pathname;
const environment = window.location.origin;
const approvedHosts = ['localhost', 'team-ct-medicare.dev.werally.in', 'team-mnr-medicare.dev.werally.in', 'team-perf-medicare.dev.werally.in', 'stage-medicare', 'medicare.int.werally.in', 'member.int.uhc.com', 'member.uat.uhc.com', 'member.uhc.com'];
let api_base_url = undefined;
let profile_preferences_base_url = undefined;
let user_management_api_base_url = undefined;
let member_auth_api_base_url = undefined;
let aem_base_url = undefined;
let upg_redirection_url = undefined;
let isProdEnvi = false;

let encStr = window.env? window.env.REACT_APP_CONV_STR : undefined;
if(encStr !== undefined){
localStorage.setItem('encStr',encStr);
}

if (approvedHosts.includes(host)) {
	if (environment.indexOf('localhost') > -1) {
		api_base_url = config.REACT_APP_LOCAL_UCP_PAYMENTS_ROOT;
		profile_preferences_base_url = config.REACT_APP_LOCAL_UCP_PROFILE_PREFERENCES_ROOT;
		user_management_api_base_url = config.REACT_APP_LOCAL_UCP_USER_MANAGEMENT_ROOT;
		member_auth_api_base_url = config.REACT_APP_LOCAL_UCP_MEMBER_AUTH_ROOT;
		aem_base_url = config.REACT_APP_LOCAL_AEM_ROOT;
		upg_redirection_url = config.UPG_STAGE_PAYMENT_REDIRECTION_URL;
	} else if (environment.indexOf('team-ct') > -1) {
		api_base_url = config.REACT_APP_TEST_UCP_PAYMENTS_ROOT;
		profile_preferences_base_url = config.REACT_APP_TEST_UCP_PROFILE_PREFERENCES_ROOT;
		user_management_api_base_url = config.REACT_APP_TEST_UCP_USER_MANAGEMENT_ROOT;
		member_auth_api_base_url = config.REACT_APP_TEST_UCP_MEMBER_AUTH_ROOT;
		aem_base_url = config.REACT_APP_TEST_AEM_ROOT;
		upg_redirection_url = config.UPG_STAGE_PAYMENT_REDIRECTION_URL;
	} else if (environment.indexOf('team-mnr') > -1) {
		api_base_url = config.REACT_APP_MNR_UCP_PAYMENTS_ROOT;
		profile_preferences_base_url = config.REACT_APP_MNR_UCP_PROFILE_PREFERENCES_ROOT;
		user_management_api_base_url = config.REACT_APP_MNR_UCP_USER_MANAGEMENT_ROOT;
		member_auth_api_base_url = config.REACT_APP_MNR_UCP_MEMBER_AUTH_ROOT;
		aem_base_url = config.REACT_APP_MNR_AEM_ROOT;
		upg_redirection_url = config.UPG_STAGE_PAYMENT_REDIRECTION_URL;
	}	else if (environment.indexOf('team-perf') > -1) {
		api_base_url = config.REACT_APP_PERF_UCP_PAYMENTS_ROOT;
		profile_preferences_base_url = config.REACT_APP_PERF_UCP_PROFILE_PREFERENCES_ROOT;
		user_management_api_base_url = config.REACT_APP_PERF_UCP_USER_MANAGEMENT_ROOT;
		member_auth_api_base_url = config.REACT_APP_PERF_UCP_MEMBER_AUTH_ROOT;
		aem_base_url = config.REACT_APP_PERF_AEM_ROOT;
		upg_redirection_url = config.UPG_STAGE_PAYMENT_REDIRECTION_URL;	
	} else if (environment.indexOf('offline-stage') > -1) {
		api_base_url = config.REACT_APP_OFFLINE_STAGE_UCP_PAYMENTS_ROOT;
		profile_preferences_base_url = config.REACT_APP_OFFLINE_STAGE_UCP_PROFILE_PREFERENCES_ROOT;
		user_management_api_base_url = config.REACT_APP_OFFLINE_STAGE_UCP_USER_MANAGEMENT_ROOT;
		member_auth_api_base_url = config.REACT_APP_OFFLINE_STAGE_UCP_MEMBER_AUTH_ROOT;
		aem_base_url = config.REACT_APP_OFFLINE_STAGE_AEM_ROOT;
		upg_redirection_url = config.UPG_STAGE_PAYMENT_REDIRECTION_URL;
	} else if (environment.indexOf('stage-medicare') > -1) {
		api_base_url = config.REACT_APP_STAGE_UCP_PAYMENTS_ROOT;
		profile_preferences_base_url = config.REACT_APP_STAGE_UCP_PROFILE_PREFERENCES_ROOT;
		user_management_api_base_url = config.REACT_APP_STAGE_UCP_USER_MANAGEMENT_ROOT;
		member_auth_api_base_url = config.REACT_APP_STAGE_UCP_MEMBER_AUTH_ROOT;
		aem_base_url = config.REACT_APP_STAGE_AEM_ROOT;
		upg_redirection_url = config.UPG_STAGE_PAYMENT_REDIRECTION_URL;
	} else if (environment.indexOf('medicare.int.werally.in') > -1) {
		api_base_url = config.REACT_APP_INT_UCP_PAYMENTS_ROOT;
		profile_preferences_base_url = config.REACT_APP_INT_UCP_PROFILE_PREFERENCES_ROOT;
		user_management_api_base_url = config.REACT_APP_INT_UCP_USER_MANAGEMENT_ROOT;
		member_auth_api_base_url = config.REACT_APP_INT_UCP_MEMBER_AUTH_ROOT;
		aem_base_url = config.REACT_APP_INT_AEM_ROOT;
		upg_redirection_url = config.UPG_STAGE_PAYMENT_REDIRECTION_URL;
	} else if ((approvedHosts.includes(host) && RegExp(/^https?:\/\/member\.int\.uhc\.com\//).test(environment+'/') && uriPath.indexOf('medicare-dev') > -1)) {
        api_base_url = config.REACT_APP_MEMBER_INT_UCP_PAYMENTS_ROOT;
        profile_preferences_base_url = config.REACT_APP_MEMBER_INT_UCP_PROFILE_PREFERENCES_ROOT;
        user_management_api_base_url = config.REACT_APP_MEMBER_INT_UCP_USER_MANAGEMENT_ROOT;
        member_auth_api_base_url = config.REACT_APP_MEMBER_INT_UCP_MEMBER_AUTH_ROOT;
        aem_base_url = config.REACT_APP_MEMBER_INT_AEM_PREVIEW_ROOT;
        upg_redirection_url = config.UPG_STAGE_PAYMENT_REDIRECTION_URL;
    } else if ((approvedHosts.includes(host) && RegExp(/^https?:\/\/member\.int\.uhc\.com\//).test(environment+'/')  && uriPath.indexOf('medicare-green') > -1)) {
        api_base_url = config.REACT_APP_MEMBER_INT_UCP_PAYMENTS_ROOT;
        profile_preferences_base_url = config.REACT_APP_MEMBER_INT_UCP_PROFILE_PREFERENCES_ROOT;
        user_management_api_base_url = config.REACT_APP_MEMBER_INT_UCP_USER_MANAGEMENT_ROOT;
        member_auth_api_base_url = config.REACT_APP_MEMBER_INT_UCP_MEMBER_AUTH_ROOT;
        aem_base_url = config.REACT_APP_MEMBER_INT_AEM_GREEN_ROOT;
        upg_redirection_url = config.UPG_STAGE_PAYMENT_REDIRECTION_URL;
    } else if ((approvedHosts.includes(host) && RegExp(/^https?:\/\/member\.int\.uhc\.com\//).test(environment+'/'))) {
		api_base_url = config.REACT_APP_MEMBER_INT_UCP_PAYMENTS_ROOT;
		profile_preferences_base_url = config.REACT_APP_MEMBER_INT_UCP_PROFILE_PREFERENCES_ROOT;
		user_management_api_base_url = config.REACT_APP_MEMBER_INT_UCP_USER_MANAGEMENT_ROOT;
		member_auth_api_base_url = config.REACT_APP_MEMBER_INT_UCP_MEMBER_AUTH_ROOT;
		aem_base_url = config.REACT_APP_MEMBER_INT_AEM_ROOT;
		upg_redirection_url = config.UPG_STAGE_PAYMENT_REDIRECTION_URL;
	}
	else if (environment.indexOf('offline.medicare') > -1) {
		api_base_url = config.REACT_APP_OFFLINE_PROD_UCP_PAYMENTS_ROOT;
		profile_preferences_base_url = config.REACT_APP_OFFLINE_PROD_UCP_PROFILE_PREFERENCES_ROOT;
		user_management_api_base_url = config.REACT_APP_OFFLINE_PROD_UCP_USER_MANAGEMENT_ROOT;
		member_auth_api_base_url = config.REACT_APP_OFFLINE_PROD_UCP_MEMBER_AUTH_ROOT;
		aem_base_url = config.REACT_APP_OFFLINE_PROD_AEM_ROOT;
		upg_redirection_url = config.UPG_PROD_PAYMENT_REDIRECTION_URL;
		isProdEnvi = true;
	} 
	else if (environment.indexOf('medicare.uat') > -1) {
		api_base_url = config.REACT_APP_UAT_UCP_PAYMENTS_ROOT;
		profile_preferences_base_url = config.REACT_APP_UAT_UCP_PROFILE_PREFERENCES_ROOT;
		user_management_api_base_url = config.REACT_APP_UAT_UCP_USER_MANAGEMENT_ROOT;
		member_auth_api_base_url = config.REACT_APP_UAT_UCP_MEMBER_AUTH_ROOT;
		aem_base_url = config.REACT_APP_UAT_AEM_ROOT;
		upg_redirection_url = config.UPG_PROD_PAYMENT_REDIRECTION_URL;
		isProdEnvi = true;
	} 
    else if ((approvedHosts.includes(host) && RegExp(/^https?:\/\/member\.uat\.uhc\.com\//).test(environment+'/') && uriPath.indexOf('medicare-preview') > -1)) {
        api_base_url = config.REACT_APP_MEMBER_UAT_UCP_PAYMENTS_ROOT;
        profile_preferences_base_url = config.REACT_APP_MEMBER_UAT_UCP_PROFILE_PREFERENCES_ROOT;
        user_management_api_base_url = config.REACT_APP_MEMBER_UAT_UCP_USER_MANAGEMENT_ROOT;
        member_auth_api_base_url = config.REACT_APP_MEMBER_UAT_UCP_MEMBER_AUTH_ROOT;
        aem_base_url = config.REACT_APP_MEMBER_UAT_AEM_PREVIEW_ROOT;
        upg_redirection_url = config.UPG_PROD_PAYMENT_REDIRECTION_URL;
        isProdEnvi = true;
    }
    else if ((approvedHosts.includes(host) && RegExp(/^https?:\/\/member\.uat\.uhc\.com\//).test(environment+'/') && uriPath.indexOf('medicare-green') > -1)) {
        api_base_url = config.REACT_APP_MEMBER_UAT_UCP_PAYMENTS_ROOT;
        profile_preferences_base_url = config.REACT_APP_MEMBER_UAT_UCP_PROFILE_PREFERENCES_ROOT;
        user_management_api_base_url = config.REACT_APP_MEMBER_UAT_UCP_USER_MANAGEMENT_ROOT;
        member_auth_api_base_url = config.REACT_APP_MEMBER_UAT_UCP_MEMBER_AUTH_ROOT;
        aem_base_url = config.REACT_APP_MEMBER_UAT_AEM_GREEN_ROOT;
        upg_redirection_url = config.UPG_PROD_PAYMENT_REDIRECTION_URL;
        isProdEnvi = true;
    } else if ((approvedHosts.includes(host) && RegExp(/^https?:\/\/member\.uat\.uhc\.com\//).test(environment+'/'))) {
		api_base_url = config.REACT_APP_MEMBER_UAT_UCP_PAYMENTS_ROOT;
		profile_preferences_base_url = config.REACT_APP_MEMBER_UAT_UCP_PROFILE_PREFERENCES_ROOT;
		user_management_api_base_url = config.REACT_APP_MEMBER_UAT_UCP_USER_MANAGEMENT_ROOT;
		member_auth_api_base_url = config.REACT_APP_MEMBER_UAT_UCP_MEMBER_AUTH_ROOT;
		aem_base_url = config.REACT_APP_MEMBER_UAT_AEM_ROOT;
		upg_redirection_url = config.UPG_PROD_PAYMENT_REDIRECTION_URL;
		isProdEnvi = true;
	} 
	else if (environment.indexOf('medicare.prod') > -1){
		api_base_url = config.REACT_APP_RALLY_PROD_UCP_PAYMENTS_ROOT;
		profile_preferences_base_url = config.REACT_APP_RALLY_PROD_UCP_PROFILE_PREFERENCES_ROOT;
		user_management_api_base_url = config.REACT_APP_RALLY_PROD_UCP_USER_MANAGEMENT_ROOT;
		member_auth_api_base_url = config.REACT_APP_RALLY_PROD_UCP_MEMBER_AUTH_ROOT;
		aem_base_url = config.REACT_APP_RALLY_PROD_AEM_ROOT;
		upg_redirection_url = config.UPG_PROD_PAYMENT_REDIRECTION_URL;
		isProdEnvi = true;
	}
    else if ((approvedHosts.includes(host) && RegExp(/^https?:\/\/member\.uhc\.com\//).test(environment+'/') && uriPath.indexOf('medicare-preview') > -1)) {
        api_base_url = config.REACT_APP_RALLY_MEMBER_PROD_UCP_PAYMENTS_ROOT;
        profile_preferences_base_url = config.REACT_APP_RALLY_MEMBER_PROD_UCP_PROFILE_PREFERENCES_ROOT;
        user_management_api_base_url = config.REACT_APP_RALLY_MEMBER_PROD_UCP_USER_MANAGEMENT_ROOT;
        member_auth_api_base_url = config.REACT_APP_RALLY_MEMBER_PROD_UCP_MEMBER_AUTH_ROOT;
        aem_base_url = config.REACT_APP_RALLY_MEMBER_PROD_AEM_PREVIEW_ROOT;
        upg_redirection_url = config.UPG_PROD_PAYMENT_REDIRECTION_URL;
        isProdEnvi = true;
    }
    else if ((approvedHosts.includes(host) && RegExp(/^https?:\/\/member\.uhc\.com\//).test(environment+'/') && uriPath.indexOf('medicare-green') > -1)) {
        api_base_url = config.REACT_APP_RALLY_MEMBER_PROD_UCP_PAYMENTS_ROOT;
        profile_preferences_base_url = config.REACT_APP_RALLY_MEMBER_PROD_UCP_PROFILE_PREFERENCES_ROOT;
        user_management_api_base_url = config.REACT_APP_RALLY_MEMBER_PROD_UCP_USER_MANAGEMENT_ROOT;
        member_auth_api_base_url = config.REACT_APP_RALLY_MEMBER_PROD_UCP_MEMBER_AUTH_ROOT;
        aem_base_url = config.REACT_APP_RALLY_MEMBER_PROD_AEM_GREEN_ROOT;
        upg_redirection_url = config.UPG_PROD_PAYMENT_REDIRECTION_URL;
        isProdEnvi = true;
    } else if ((approvedHosts.includes(host) && RegExp(/^https?:\/\/member\.uhc\.com\//).test(environment+'/'))) {
		api_base_url = config.REACT_APP_RALLY_MEMBER_PROD_UCP_PAYMENTS_ROOT;
		profile_preferences_base_url = config.REACT_APP_RALLY_MEMBER_PROD_UCP_PROFILE_PREFERENCES_ROOT;
		user_management_api_base_url = config.REACT_APP_RALLY_MEMBER_PROD_UCP_USER_MANAGEMENT_ROOT;
		member_auth_api_base_url = config.REACT_APP_RALLY_MEMBER_PROD_UCP_MEMBER_AUTH_ROOT;
		aem_base_url = config.REACT_APP_RALLY_MEMBER_PROD_AEM_ROOT;
		upg_redirection_url = config.UPG_PROD_PAYMENT_REDIRECTION_URL;
		isProdEnvi = true;
	}
	else {
		api_base_url = config.REACT_APP_PROD_UCP_PAYMENTS_ROOT;
		profile_preferences_base_url = config.REACT_APP_PROD_UCP_PROFILE_PREFERENCES_ROOT;
		user_management_api_base_url = config.REACT_APP_PROD_UCP_UCP_USER_MANAGEMENT_ROOT;
		member_auth_api_base_url = config.REACT_APP_PROD_UCP_UCP_MEMBER_AUTH_ROOT;
		aem_base_url = config.REACT_APP_PROD_AEM_ROOT;
		upg_redirection_url = config.UPG_PROD_PAYMENT_REDIRECTION_URL;
		isProdEnvi = true;
	}
}
console.log(host);
console.log(api_base_url);
// api end point, aem end point, api end point suffixes.
let appConfigs = {
	encKeyStr: localStorage.getItem('encStr')? localStorage.getItem('encStr'): encStr,
	upg_non_prod_accountId: '37019',
	upg_prod_accountId: '35005',
	isProdEnvi: isProdEnvi,
	api_base_url: api_base_url,
	aem_base_url: aem_base_url,
	upg_redirection_url: upg_redirection_url,
	save_or_update_token: '/saveAndUpdateToken',
	get_tokenization: '/member/getTokenization',
	find_payment_details: '/V1/findPostPaymentDetails',
	cc_post_payment_results: '/member/payment-methods/v2/credit-card',
	search_payment_history_path: api_base_url + '/member/searchPaymentHistory/v1',
	server_time: '/time/getSystemTime',
	account_validation_wrapper_url: '/member/v1/account-validation-wrapper',
	govt_eft_payment_submission: '/member/payment-methods/v1/govt-eft',
	ship_or_govt_cancel_payments: '/member/payment-methods/v2/cancel-recurring',
	bank_account_validation_wrapper_url: '/member/v1/account-validation-wrapper',
	search_payment_method_results_path: api_base_url + '/member/SearchPaymentMethodResults',
	search_payment_wallet_results_path: api_base_url + '/member/getWalletandPayments/WalletandPaymentsResults',
	read_pending_payments_results_path: api_base_url + '/member/readPendingPayments',
	get_wallet_service_path : api_base_url + '/member/WalletServices/get/wallet',
	update_wallet_service_path: api_base_url + '/member/WalletServices/update/wallet?enc_accountId=',
	cc_create_payment_service_path: api_base_url + '/member/createPayment/creditCardDetails/v1',
	eft_create_payment_service_path: api_base_url + '/member/createPayment/bankAccountDetails/v1',
	retrieve_payment_with_paymentId_service_path: api_base_url +'/member/retrievePaymentsWithPaymentId/v1',
	update_payment_service_path: api_base_url +'/member/updatePayment/v1',
	cancel_payment_service_path: api_base_url +'/member/cancelPayment/v1',
	upg_delete_token_service_path : api_base_url + '/MnrDeleteToken/V1',
	upg_untokenization_success_payment_path: api_base_url + '/unTokenizationwithTokenNumberAndAccountId',
	premium_break_down_service_path: api_base_url +'/member/premiumbreakdown/v1',
	sale_transaction_service_path:api_base_url + '/member/v1/upgSaleService',
	lep_eligibility_service_path: profile_preferences_base_url + '/member/getGPSTrackerDetails?accountId=',
	accessToken_url : member_auth_api_base_url + '/userInfoWithAccessToken?accessTokenenc=',
    heimdall_url : user_management_api_base_url + '/getheimdallsessionresponse',
    get_consumer_information_url : user_management_api_base_url +'/member/getConsumerInformation',
    get_rsmu_cookie : user_management_api_base_url + '/get_Rs_MU_Cookie',
	current_time_service : user_management_api_base_url + '/time/getSystemTime',
	sso_inbound_url : user_management_api_base_url + '/member/MnRUUIDFromHSID/',
    next_year_service : user_management_api_base_url + '/medicare/content/medicare/membersettings/jcr:content/overview/membersettingscomponent.json',
	customer_account_summary_service_path: api_base_url + '/member/customerAccountSummary/v1',
	premium_details_service_path : api_base_url + '/member/getPremiumDetails/v1',
	billing_payments_service_path : api_base_url + '/member/getIsbBillingPayments/v1',
	invoice_list_service_path: api_base_url + '/member/getIsbInvoiceList/v1',
	ship_isb_onetime_payment_submission : api_base_url + '/member/oneTimePayment/v1',
	ship_isb_update_payment_submission : api_base_url + '/member/updatePaymentMethod/v1',
	hsid_mfa_aatoken_encrypt_service_path: api_base_url + '/member/hrt/hsid/mfa/aatoken/encrypt/v1',
	hsid_mfa_aatoken_decrypt_service_path: api_base_url + '/member/hrt/hsid/mfa/aatoken/decrypt/v1',
	mfa_logging_info_service_path: api_base_url + '/member/mfaLoggingService',
    m3p_service_path: api_base_url + '/m3p/GPS/getM3PPaymentPlan',
};

export default appConfigs;
