import { Typography, Link, Notification } from '@uhc-tempo/components'
// import moment from 'moment';
import React, { Component } from 'react';
import CheckingIcon from '../../tokens/icons/Checking.svg';
import SavingsIcon from '../../tokens/icons/Savings.svg';
import PaymentModals from '../modals/PaymentModals';
import {items} from '../Wallet/ManageWallet'
import EftForm from '../payment-details/EftForm';
import { manageWalletAem,updateWalletData, manageWalletFormAem } from '../Wallet/ManageWallet'
import cloneDeep from 'lodash/cloneDeep';
export class BankAccountList extends Component {
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props)
    }
     
    //Modal Content
    itemRemove = {
        header: manageWalletAem.auth_remove_pay_method_modal_title,
        desc: manageWalletAem.auth_remove_pay_method_modal_content,
        btn_txt: manageWalletAem.auth_mw_remove_btn_txt,
        btn_txtCancel: manageWalletAem.auth_mw_cancel_btn_txt,
        items: cloneDeep(items),
        updateWalletData: cloneDeep(updateWalletData),
        itemId: this.props.item.itemId,
        getWalletDetails: this.props.getWalletDetails,
        setLoading: this.props.setLoading,
        getWalletItemNum: this.props.getWalletItemNum,
        resetAllFormsAndErrors: this.props.resetAllFormsAndErrors,
        itemIndex: this.props.bankListIndex
    }

    recItemRemove = {
        header: manageWalletAem.auth_pay_method_recurring_modal_heading,
        desc: manageWalletAem.auth_pay_method_recurring_modal_content,
        btn_txt: manageWalletAem.auth_edit_recurring_pay_btn_txt,
        btn_txtCancel: manageWalletAem.auth_mw_cancel_btn_txt,
        itemIndex: this.props.bankListIndex,
        resetAllFormsAndErrors: this.props.resetAllFormsAndErrors,
    }

    bankAccount = {
        fName : this.props.item.bankAccount.firstName,
        mName : this.props.item.bankAccount.middleName,
        lName : this.props.item.bankAccount.lastName,
        nickName : this.props.item.nickname,
        rNumber : this.props.item.bankAccount.routingNumber,
        aNumber : this.props.item.bankAccount.accountNumber,
        type : this.props.item.bankAccount.bankAccountType,
        authInd : this.props.item.bankAccount.authorizationInd
    }

    handleEditLinkKey = (event) => {
        if(this.props.isEditOpen){
            if (!event.shiftKey && event.key === "Tab") {
                event.preventDefault();
                document.getElementById('bankHolFirstName').focus();
                if(document.getElementById('eft_remove_'+this.props.bankListIndex)){
                    document.getElementById('eft_remove_'+this.props.bankListIndex).setAttribute('tabindex','-1')
                } else{
                    document.getElementById('eft_rec_remove_'+this.props.bankListIndex).setAttribute('tabindex','-1')
                }
              }
            if (event.shiftKey && event.key === "Tab") {
                event.preventDefault();
                document.getElementById('cancelBtn').focus();
             }
        }
    }

    handleCancelBtnKey = (event) => {
        if(this.props.isEditOpen){
            if (!event.shiftKey && event.key === "Tab") {
                event.preventDefault();
                document.getElementById('bankEditLink_'+this.props.bankListIndex).focus();
            }
        }
    }

    isMobile() {
        let screenWidth = window.screen.width;
        if (screenWidth < 768) {
            return true;
        }
        return false;
    }

    render() {
        const bankAccount = this.props.item.bankAccount;
        const bankItemAddedUpdated = JSON.parse(sessionStorage.getItem('walletItemAddedUpdated'));
        const newSession = sessionStorage.getItem('newSession')
        return (
            <div>
               <div className="walletPaymentMethod">
                    <div className="walletAccount">
                        <Typography.Paragraph
                        id={'eft_acc_details_'+this.props.bankListIndex}><span className="sr-only">Checking and savings accounts</span>{this.props.item.nickname === '' || this.props.item.nickname == null ? 
                        this.props.formatCCEFT(bankAccount.bankAccountType) : this.props.item.nickname }{' ***' + bankAccount.accountNumber.slice(-4)}</Typography.Paragraph>
                        <div className="accountIcon">
                            {bankAccount.bankAccountType === 'CHECKING' ? <img src={CheckingIcon} alt="" /> : <img src={SavingsIcon} alt="" />}
                        </div>
                        { newSession === 'false' ?
                        (bankItemAddedUpdated?.bankAccount && (bankItemAddedUpdated?.audit?.createdOn === bankItemAddedUpdated?.audit?.modifiedOn)) ?
                        bankAccount?.accountNumber === bankItemAddedUpdated?.bankAccount.accountNumber && bankAccount?.routingNumber === bankItemAddedUpdated?.bankAccount?.routingNumber &&
                        <div className='newIcon'>
                            <Typography.Paragraph>{manageWalletAem.auth_mw_new_text}</Typography.Paragraph>
                        </div>
                        :
                        (bankAccount?.accountNumber === bankItemAddedUpdated?.bankAccount?.accountNumber && bankAccount?.routingNumber === bankItemAddedUpdated?.bankAccount?.routingNumber && sessionStorage.getItem('updateRecurring') !== 'true') ? 
                        <div className='updateIcon'>
                            <Typography.Paragraph>{manageWalletAem.auth_mw_updated_text}</Typography.Paragraph>
                        </div>
                        :
                        null
                        :
                        null
                    }
                    { this.isMobile() &&
                    <div className={bankAccount?.accountNumber === bankItemAddedUpdated?.bankAccount?.accountNumber 
                        && bankAccount?.routingNumber === bankItemAddedUpdated?.bankAccount?.routingNumber 
                         ? "editRemoveLinks" : "editRemoveLinks noIcon"}>
                        <div className="editLink">
                            <Link id={'bankEditLink_'+this.props.bankListIndex} noIcon onClick={()=> this.props.onEditBtnClick(this.props.bankListIndex)}>{manageWalletAem.auth_mw_edit_link_text}</Link>
                        </div>
                        { ((this.props.item.itemId === this.props.recBankItemId) || this.props.item.itemId === this.props.scheduledBankItemId) ?
                            <div className="removeLink">
                             <PaymentModals
                                  superUser={this.props.superUser}
                                  showSuperUserError={this.props.showSuperUserError}
                                  superUserError={this.props.superUserError}
                                  modalContent={this.recItemRemove}
                                  modalType="recItemRemove"
                                  removeLinkTxt={manageWalletAem.auth_mw_remove_link_text}
                                  paymentMethod='EFT'
                                  editRecurringWalletItem = {this.props.editRecurringWalletItem}
                                  item={this.props.item.itemId}
                                  scheduledBankItemId={this.props.scheduledBankItemId}
                                  recBankItemId={this.props.recBankItemId}
                            />
                            
                        </div>
                        :
                        <div className="removeLink">
                            <PaymentModals
                                  superUser={this.props.superUser}
                                  showSuperUserError={this.props.showSuperUserError}
                                  superUserError={this.props.superUserError}
                                  modalContent={this.itemRemove}
                                  modalType="itemRemove"
                                  removeLinkTxt={manageWalletAem.auth_mw_remove_link_text}
                                  item={this.props.item.itemId}
                                  scheduledBankItemId={this.props.scheduledBankItemId}
                                  recBankItemId={this.props.recBankItemId}
                                  removeApiErr = {this.props.removeApiErr}
                            />
                        </div>
                        }
                    </div>
                    }
                    </div>
                    { !this.isMobile() &&
                    <div className={bankAccount?.accountNumber === bankItemAddedUpdated?.bankAccount?.accountNumber 
                        && bankAccount?.routingNumber === bankItemAddedUpdated?.bankAccount?.routingNumber 
                         ? "editRemoveLinks" : "editRemoveLinks noIcon"}>
                        <div className="editLink">
                        <button 
                            role="link" 
                            id={'bankEditLink_'+this.props.bankListIndex} 
                            className="tds-link tds-link--medium"
                            onKeyDown={this.handleEditLinkKey}
                            aria-describedby={this.props.item.itemId === this.props.scheduledBankItemId ? ('eft_acc_details_'+this.props.bankListIndex + ' ' + 'rec_pay_sch_'+this.props.bankListIndex) : this.props.item.itemId === this.props.recBankItemId ? ('eft_acc_details_'+this.props.bankListIndex + ' ' + 'rec_pay_on_'+this.props.bankListIndex)  : 'eft_acc_details_'+this.props.bankListIndex}
                            aria-expanded="false"
                            aria-controls={'edit_eft_details_'+this.props.bankListIndex} 
                            onClick={()=> this.props.onEditBtnClick(this.props.bankListIndex)}>
                                {manageWalletAem.auth_mw_edit_link_text}
                            </button>
                        </div>
                        { ((this.props.item.itemId === this.props.recBankItemId) || this.props.item.itemId === this.props.scheduledBankItemId) ?
                            <div className="removeLink">
                             <PaymentModals
                                superUser={this.props.superUser}
                                showSuperUserError={this.props.showSuperUserError}
                                superUserError={this.props.superUserError}
                                  modalContent={this.recItemRemove}
                                  modalType="recItemRemove"
                                  removeLinkTxt={manageWalletAem.auth_mw_remove_link_text}
                                  item={this.props.item.itemId}
                                  editRecurringWalletItem = {this.props.editRecurringWalletItem}
                                  paymentMethod='EFT'
                                  scheduledBankItemId={this.props.scheduledBankItemId}
                                  recBankItemId={this.props.recBankItemId}
                            />
                            
                        </div>
                        :
                        <div className="removeLink">
                            <PaymentModals
                                superUser={this.props.superUser}
                                showSuperUserError={this.props.showSuperUserError}
                                superUserError={this.props.superUserError}
                                  modalContent={this.itemRemove}
                                  modalType="itemRemove"
                                  removeLinkTxt={manageWalletAem.auth_mw_remove_link_text}
                                  item={this.props.item.itemId}
                                  scheduledBankItemId={this.props.scheduledBankItemId}
                                  recBankItemId={this.props.recBankItemId}
                                  removeApiErr = {this.props.removeApiErr}
                            />
                        </div>
                        }
                    </div>
                    }
                    <div className="recurringLabel">
                    { this.props.item.itemId === this.props.recBankItemId &&
                        <Typography.Paragraph id={'rec_pay_on_'+this.props.bankListIndex}>{manageWalletAem.auth_mw_recurring_payments_text} <span style={{color: 'green', fontWeight: 'bold'}}>{manageWalletAem.auth_mw_on_text}</span></Typography.Paragraph>
                    }
                    {this.props.item.itemId === this.props.scheduledBankItemId &&
                        <Typography.Paragraph id={'rec_pay_sch_'+this.props.bankListIndex}>{manageWalletAem.auth_mw_recurring_scheduled_txt}</Typography.Paragraph>
                    }
                    </div>
                </div>
                {this.props.isEditOpen && <EftForm
                    isEditForm = {true}
                    item = {this.props.item}
                    bankListIndex = {this.props.bankListIndex}
                    paymentMethod = {this.bankAccount.type.toLowerCase()}
                    cancleBtnOnClickEditEFT = {this.props.cancleBtnOnClickEditEFT}
                    bankAccount = {this.bankAccount}
                    ref = {this.props.eftRef}
                    eftSubmitBtnClickErrorSet = {this.props.eftSubmitBtnClickErrorSet} 
                    setLoading = {this.props.setLoading} 
                    getWalletDetails = {this.props.getWalletDetails}
                    getWalletItemNum={this.props.getWalletItemNum}
                    handleCancelBtnKey={this.handleCancelBtnKey}
                    resetAllFormsAndErrors={this.props.resetAllFormsAndErrors}
                    showSuperUserError={this.props.showSuperUserError}
                    superUser={this.props.superUser}
                    superUserError={this.props.superUserError}
                    recBankItemId={this.props.recBankItemId}
                    scheduledBankItemId={this.props.scheduledBankItemId} />}
                    { 
                        (this.props.superUserError && (this.props.bankListIndex === this.props.accountItemNum)) ?
                        <div className='walletSuperUserError' id={'walletSuperUserNotification_' + this.props.bankListIndex}><Notification  notificationType='error'><span style={{fontWeight: 'bold'}}>{manageWalletFormAem.auth_wallet_memberauth_not_allowed + ' ' }</span> {manageWalletFormAem.auth_wallet_memberauth_error_msg}  </Notification></div> 
                        :
                        null
                    }
                   
            </div>
        )
    }
}

export default BankAccountList