import React from 'react';
import './OBreadcrumbs.css';
import  { trackLinkClick } from '../../../server/util/analytics';
import secureLocalStorage from 'react-secure-storage';


const OBreadcrumbs = (props)=> {
const analyticsTrack=(el)=>{
  let currPage= secureLocalStorage.getItem('currPage');
  trackLinkClick('breadcrumbs:'+currPage+':'+el.title)
  console.log('breadcrumbs:'+currPage+':'+el.title)
}
    return(
      props.links ? (
      <nav className="tds-breadcrumbs" aria-label="Breadcrumb">
  <ol className="tds-breadcrumbs__list uhc-breadcrumb-desktop" id="breadcrumb-1">
    { props.links.map((el,i)=>
      el.link === 'toOverview' ?
      <li key={i} className="tds-breadcrumbs__listitem">
      <a href="javascript:" style={{cursor: 'pointer'}} onClick={() => {analyticsTrack(el);el.click()}} className="tds-breadcrumbs__link"><span className="tds-breadcrumbs__link-text">{el.title}</span></a>
      <svg
        className="MuiSvgIcon-root tds-utility-icon tds-breadcrumbs__chevron MuiSvgIcon-fontSizeSmall"
        focusable="false"
        viewBox="0 0 24 24"
        color="#196ecf"
        aria-hidden="true"
        role="img"
      >
        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
      </svg>
    </li> :
      el.link !== '' ?
    <li key={i} className="tds-breadcrumbs__listitem">
      <a href={el.link} onClick={() => {analyticsTrack(el);el.click()}} className="tds-breadcrumbs__link"><span className="tds-breadcrumbs__link-text">{el.title}</span></a>
      <svg
        className="MuiSvgIcon-root tds-utility-icon tds-breadcrumbs__chevron MuiSvgIcon-fontSizeSmall"
        focusable="false"
        viewBox="0 0 24 24"
        color="#196ecf"
        aria-hidden="true"
        role="img"
      >
        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
      </svg>
    </li> : 
    <li key={i} className="tds-breadcrumbs__listitem tds-breadcrumbs__active">{el.title}</li> ) }
  </ol>
  
</nav>) : null
    );
}; 

export default OBreadcrumbs;