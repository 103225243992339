// About the Component Currency Input :

//  > Entering the digits 3412 will display 34.12
//  > Component is a simple HTML input element with a keydown event handler
//  > Component uses "toLocaleString" method to display the value as a currency
//  > attribute has below propes which can be use for customization,
//         > className, max, onValueChange, disabled, style, value


import React from "react";

const VALID_FIRST = /^[1-9]{1}$/;
const VALID_NEXT = /^[0-9]{1}$/;
const DELETE_KEY_CODE = 8;


export const CurrencyInput = ({
    className = "",
    disabled,
    max = Number.MAX_SAFE_INTEGER,
    onValueChange,
    onValueBluer,
    style = {},
    value,
    inputRef,
    describedby,
    labelledby,
    tabindex,
    id
}) => {
    const valueAbsTrunc = Math.trunc(Math.abs(value));
    if (
        value !== valueAbsTrunc ||
        !Number.isFinite(value) ||
        Number.isNaN(value)
    ) {
    }
    const handleKeyDown =
        e => {
            let validKey;
            const { key, keyCode, data } = e;
            validKey = key ? key : data;
            if (
                (value === 0 && !VALID_FIRST.test(validKey)) ||
                (value !== 0 && !VALID_NEXT.test(validKey) && keyCode !== DELETE_KEY_CODE)
            ) {
                return;
            }
            const valueString = value.toString();
            let nextValue;
            if (keyCode !== DELETE_KEY_CODE) {
                const nextValueString = value === 0 ? validKey : `${valueString}${validKey}`;
                nextValue = Number.parseInt(nextValueString, 10);
            } else {
                const nextValueString = valueString.slice(0, -1);
                nextValue =
                    nextValueString === "" ? 0 : Number.parseInt(nextValueString, 10);
            }
            if (nextValue > 9999999) {
                return;
            }
            onValueChange(nextValue, false)
        };
    const handleChange = () => {
        // DUMMY TO AVOID REACT WARNING
    };
    const valueDisplay = (value / 100).toLocaleString("en-US", {
        style: "currency",
        currency: "USD"
    });

    return (
        <input
           className={className}
            onChange={handleChange}
            disabled = {disabled}
            onKeyDown={handleKeyDown}
            onBlur={onValueBluer}
            style={style}
            value={valueDisplay}
            ref={inputRef}
            id={id}
            aria-labelledby={labelledby}
            aria-describedby={describedby}
            aria-required="true"
            tabIndex={tabindex}
        />
    );
};

export default CurrencyInput;
