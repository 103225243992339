import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter  } from 'react-router-dom';
// import { BrowserRouter  } from 'react-router-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { initializeDatadog } from './util/datadog';

initializeDatadog();
const app = (
	<React.StrictMode>
		 <HashRouter> 
		{/*<BrowserRouter >*/}
			<App />
		{/*</BrowserRouter>*/}
		 </HashRouter> 
	</React.StrictMode>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
