import React from "react";
import "./modalPopup.css";
import '../modals/modalTemplate.css'

import Modal from "react-modal";

Modal.setAppElement("#root");

const MfaModalPopup = ({ 
  isOpen = false,
  className = "mymodalsession mymodalsession-min",
  contentLabel = "",
  onRequestClose,
  overlayClassName="myoverlay",
  closeTimeoutMS = 500,
  children,
  arialabelled,
  ref,
  shouldCloseOnOverlayClick = true,
  clsName
})=> {

  return (
    <div className="App">
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentLabel={contentLabel}
        className= {className + ' ' + clsName}
        overlayClassName={overlayClassName}
        closeTimeoutMS={closeTimeoutMS}
        contentRef={ref}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        shouldCloseOnEsc={shouldCloseOnOverlayClick}
        aria={{
          labelledby: arialabelled,
          modal: 'true'
        }}
      
      >
        <div>{children}</div>
      </Modal>
    </div>
  );
}
export default MfaModalPopup;