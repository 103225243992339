import axios from 'axios';
import { logger } from '../logger';
import appConfigs from '../app.config';
import { getEncStr} from '../../server/util/encDec';
const accountValidationService = axios.create({
	baseURL: appConfigs.api_base_url
});

export const accountValidationWrapper = async (memberNumber, bankRoutingNumber, bankAccountNumber, productType) => {

	var bankaccountValidationWrapperRequest = {
		"meta": {
			"memberId": "",
			"memberPlan": ""
		},
		"data": {
			"validationChannel": "CUSTOMER PRESENT FOR TRANSACTION",
			"bankRoutingNumber": "",
			"bankAccountNumber": ""
		}
    }
    bankaccountValidationWrapperRequest.meta.memberId=memberNumber;
    bankaccountValidationWrapperRequest.meta.memberPlan=productType;
    bankaccountValidationWrapperRequest.data.bankRoutingNumber= getEncStr(bankRoutingNumber);
    bankaccountValidationWrapperRequest.data.bankAccountNumber= getEncStr(bankAccountNumber);

return accountValidationService.post(appConfigs.bank_account_validation_wrapper_url, bankaccountValidationWrapperRequest)
.catch(error => {
logger.error(error.message);
throw error;
})  
}

export default accountValidationService;
