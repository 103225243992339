
import React, { useEffect, useState } from 'react';
import SessionValidation from './SessionValidation';
import axios from '../server/api/aem-api';
import appConfigs from "../server/app.config";
import getRsmu from '../src/api/getRsmuCookieApi';
import CurrentTime from '../src/api/currentTimeService';
import secureLocalStorage from 'react-secure-storage';
//import NextYear from '../src/api/nextYearApi';


const App  = () => {
const [statusMessage, setStatusMessage] = useState(null);

  useEffect(()=>{
    var consumerDet = localStorage.getItem("consumerDetails");
    var superUserFlag = localStorage.getItem("superUser") === 'true' ? true : false;
    var currentTimeFlag = secureLocalStorage.getItem("currentTimeInMillis");
   // var nextYearFlag = localStorage.getItem("nextYear");

    if (consumerDet === null || consumerDet === "" || consumerDet === undefined) {
      getConsumerInformation();
    } else {
      setStatusMessage("Consumer information is available.");
    }

    if ((superUserFlag === null || superUserFlag === "" || superUserFlag === undefined) && !(window.location.host.includes("localhost"))) {
      const rsmuCookie = getRsmu()
      rsmuCookie.then((res) => {
        if (res && res.data) {
          secureLocalStorage.setItem("RS_MU", res.data);
          var decodedRsmu = Buffer.from(res.data, 'base64').toString('ascii')
          if(!(decodedRsmu === "" || decodedRsmu == null || decodedRsmu === undefined)){
            if(decodedRsmu.includes("adminId")){
              localStorage.setItem("superUser", true);
            } else {
              localStorage.setItem("superUser", false);
            }
          }
        } else if (res.callFailed) {
          console.log("error response");
        }
      }).catch((error) => {
        console.log("error from getRsmuCookieApi " + error);
      });
    }

    if ((currentTimeFlag === null || currentTimeFlag === "" || currentTimeFlag === undefined) ) {
      const currentTime = CurrentTime();
      currentTime.then((res) => { 
        if (res && res.data && res.data.systemtimeinmillis) {
          secureLocalStorage.setItem("currentTimeInMillis", res.data.systemtimeinmillis)
        }
      }).catch((error) => {
        console.log("error from currentTimeServiceApi " + error);
      });
    }
  },[])

  const getConsumerInformation = async () => {
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
        }
      };
  
    return axios.get(appConfigs.get_consumer_information_url, 
          {}, axiosConfig).then(response =>{
            if(response.data){
              localStorage.setItem("consumerDetails", JSON.stringify(response.data));
            }
            setStatusMessage("Consumer information is updated.");
          return response.data;
          
      })
      .catch((error) => {
        setStatusMessage("Consumer information is not updated.");
        console.log(error.message);
        return {
          callFailed: true
        };
      });
  };

 
    return (    
      <div>  
        {
        statusMessage !== null &&
          <SessionValidation />
          
        }
      </div> 
    );
  }

export default App;
