// import axios from '../../server/api/ucpPayments-api';
// import appConfigs from '../../server/app.config';
import searchPaymentHistory from '../../server/api/searchPaymentHistory-api'
import Moment from 'moment';
import { serverCurrentTime } from '../../server/api/serverTime-api';
import {getProductType, isManageRecurring, getRecurringPaymentFlag, getOneTimePaymentFlag} from '../../server/util/localStorageRepo';
import secureLocalStorage from 'react-secure-storage';



const getSearchPaymentHistory = async (memberNumber,dateOfBirth,plan,totalPendingAmount) => {
	function resetLocalStorage(){

		secureLocalStorage.setItem('firstDayOfMonth', '');
		secureLocalStorage.setItem('pastAmountDue', '');
		secureLocalStorage.setItem('cMSSSAFound', '');
		secureLocalStorage.setItem('pastAmountDueFound', '');
		secureLocalStorage.setItem('currentCharges', '');
		secureLocalStorage.setItem('currentChargesFound', '');
		secureLocalStorage.setItem('currentPaymentDate', '');
		secureLocalStorage.setItem('pastAmountAndCurentCharges', '');
		secureLocalStorage.setItem('pastAmountAndCurentChargesFound', '');
		secureLocalStorage.setItem('onlyCurrentChargesFound', '');
		secureLocalStorage.setItem('estimatedAnnualPremium', '');
		}

	return searchPaymentHistory(memberNumber, dateOfBirth, plan)
		.then((response) => {
			var pastAmountDue;
			var pastAmountAndCurentCharges;
			var onlyCurrentChargesFound = false; 
			var currentCharges;
			var pastAmountDueFound = false;
			var pastAmountAndCurentChargesFound = false;
			var currentChargesFound = false;
			var currentPaymentDate;
			var firstDayOfMonth;
			let estimatedAnnualPremium = 0;
			var cMSSSAFound = false;
			var nextPremiumAmount;
			var paidInFull = false;
	
			if (response.status === 200 && response.data !== null && response.data.readBillingAndTransactionDetailResult !== null) {
				var annualPremium = response.data.readBillingAndTransactionDetailResult.estimatedAnnualPremium;
				if(annualPremium != null) {
					if(getProductType() === 'SHIP')
						estimatedAnnualPremium = parseFloat(annualPremium);
					else if(getProductType() === 'GOVT')
						estimatedAnnualPremium = parseFloat(annualPremium) > parseFloat(totalPendingAmount) ? parseFloat(annualPremium - totalPendingAmount) : 0
				}
				nextPremiumAmount = response.data.readBillingAndTransactionDetailResult.nextPremiumAmount
				paidInFull = response.data.readBillingAndTransactionDetailResult.paidInFull
				firstDayOfMonth = response.data.firstDayOfMonth;
				if(getProductType() === 'SHIP'){
					pastAmountDue = response.data.readBillingAndTransactionDetailResult.totalAmountDue ?
					(parseFloat(response.data.readBillingAndTransactionDetailResult.totalAmountDue) > response.data.pendingAmount ? parseFloat(response.data.readBillingAndTransactionDetailResult.totalAmountDue - response.data.pendingAmount): 0 ): 0
					}else if(getProductType() === 'GOVT') {
						pastAmountDue = response.data.readBillingAndTransactionDetailResult.totalAmountDue ? (parseFloat(response.data.readBillingAndTransactionDetailResult.totalAmountDue) > parseFloat(totalPendingAmount) ?
										parseFloat(response.data.readBillingAndTransactionDetailResult.totalAmountDue - totalPendingAmount): 0 ): 0
					}
					
				
				var outStandingDirectBillBalance = parseFloat(response.data.readBillingAndTransactionDetailResult.outStandingDirectBillBalance);
				if(null !== response.data.readBillingAndTransactionDetailResult.paymentMethod) {
				var paymentMethod= response.data.readBillingAndTransactionDetailResult.paymentMethod.toUpperCase();

				// var cMSSSAFound = false;

				if (paymentMethod !=null && (String(paymentMethod).includes("CMS") || String(paymentMethod).includes("SSA"))) {
					//pastAmountDue = outStandingDirectBillBalance;
					cMSSSAFound = true;	
					currentCharges = parseFloat(response.data.readBillingAndTransactionDetailResult.cmsCurrentCharges);
					if (currentCharges > 0) {
				    currentChargesFound = true;	
					}
					currentPaymentDate = Moment(response.data.readBillingAndTransactionDetailResult.paymentDueDate).format('MM/DD/YYYY');
				}
												
			}
				 if (pastAmountDue > 0) {
					pastAmountDueFound = true;
					
				 }

				 if(null != response.data.readBillingAndTransactionDetailResult.premium && 
					!(!getOneTimePaymentFlag() && getRecurringPaymentFlag() && isManageRecurring())) {
					var currenttime;	
					if(secureLocalStorage.getItem('currSysTimeInMs')){
						currenttime = Number.parseInt(secureLocalStorage.getItem('currSysTimeInMs'), 10);		
					} else{
					serverCurrentTime().then((time) => {
						currenttime = Number.parseInt(time.systemtimeinmillis, 10);	
						secureLocalStorage.setItem('currSysTimeInMs',time.systemtimeinmillis);									
				    }); 
				   }
				  var target = 1;
				  var today =  Moment(currenttime).date(target).calendar('MM/DD/YYYY');
				  var currentFormateDate = Moment(currenttime).format('MM/DD/YYYY');
				 
				  if(cMSSSAFound && firstDayOfMonth === null){
					firstDayOfMonth = today;
				  }
				 
					if (null != response.data.readBillingAndTransactionDetailResult && getProductType() === 'SHIP'){
						
						if(getProductType() === 'SHIP') {
							currentChargesFound = nextPremiumAmount != null && nextPremiumAmount !== '' && parseFloat(nextPremiumAmount) > 0 ? true : false
							if(currentChargesFound) {
								currentPaymentDate = response.data.readBillingAndTransactionDetailResult.nextBillDueDate != null ? Moment(response.data.readBillingAndTransactionDetailResult.nextBillDueDate).format('MM/DD/YYYY') : null;
								currentCharges = parseFloat(nextPremiumAmount)
							}
							
						} 
						
						if (pastAmountDueFound && currentChargesFound) {
							pastAmountAndCurentCharges = pastAmountDue + currentCharges;
							pastAmountAndCurentChargesFound = true;
							
						} else if (!pastAmountDueFound && currentChargesFound) {
							onlyCurrentChargesFound = true;	
						}
						if(getProductType() === 'SHIP' && nextPremiumAmount == null && !paidInFull) {
								pastAmountAndCurentChargesFound = false;
								onlyCurrentChargesFound = false;
						} else if(getProductType() === 'GOVT' && !currentChargesFound && (estimatedAnnualPremium > 0 || annualPremium == null)) {
							pastAmountAndCurentChargesFound = false;
							onlyCurrentChargesFound = false;
					}
					}
			}
		}

			resetLocalStorage()

			secureLocalStorage.setItem('firstDayOfMonth', firstDayOfMonth);
			secureLocalStorage.setItem('pastAmountDue', pastAmountDue);
			secureLocalStorage.setItem('cMSSSAFound', cMSSSAFound);
			secureLocalStorage.setItem('pastAmountDueFound', pastAmountDueFound);
			secureLocalStorage.setItem('currentChargesFound', currentChargesFound);
			secureLocalStorage.setItem('currentPaymentDate', currentPaymentDate);
			secureLocalStorage.setItem('pastAmountAndCurentCharges', pastAmountAndCurentCharges);
            secureLocalStorage.setItem('pastAmountAndCurentChargesFound', pastAmountAndCurentChargesFound);
			secureLocalStorage.setItem('onlyCurrentChargesFound', onlyCurrentChargesFound);
			secureLocalStorage.setItem('estimatedAnnualPremium', parseFloat(estimatedAnnualPremium).toFixed(2));

			return response;
		});

		
};

export default getSearchPaymentHistory;

