import React, { Component } from 'react'
import { FormGroup, RadioButton } from '@uhc-tempo/components'
import CheckingIcon from '../../tokens/icons/Checking.svg';
import SavingsIcon from '../../tokens/icons/Savings.svg';
import VISA from '../../tokens/icons/credit-visa.svg';
import MC from '../../tokens/icons/credit-mastercard.svg';
import AMEX from '../../tokens/icons/credit-american-express.svg';
import Discover from '../../tokens/icons/credit-discover.svg';
import { getRecurringPaymentFlag } from '../../../server/util/localStorageRepo';

export class FedPayMethods extends Component {
    constructor(props) {
        super(props);

        this.state = {
            account: null
        }
    }

    formatCCEFT(paymentType) {
        switch(paymentType?.toLowerCase()) {
          case 'checking':
            paymentType ='EFT-Checking'
            break;
          case 'savings':
            paymentType = 'EFT-Savings'
            break;
          case 'visa':
            paymentType = 'Visa'
            break;
          case 'mastercard':
            paymentType = 'Mastercard'
            break;
          case 'amex':
            paymentType = 'Amex'
            break;
          default:
            paymentType = 'Discover'
            break;
        }
        return paymentType;
    }

    walletPayChangeHandler(method) {
        this.props.handlePaymentMethodWalletSelection(method)
    }

    validateBankAccountInfo(paymentMethod) {
        if (paymentMethod.bankAccount !== null && 
            ((paymentMethod.bankAccount?.accountNumber === null || paymentMethod.bankAccount?.accountNumber === '') || 
            (paymentMethod.bankAccount?.routingNumber === null || paymentMethod.bankAccount?.routingNumber === '')))
        {
            return false;
        }
        return true
    }

    generateAccount(paymentMethod, index) {
        if (paymentMethod?.creditCard === null) {
            if (paymentMethod?.bankAccount?.accountNumber !== '' && paymentMethod?.bankAccount?.routingNumber !== '') {
                return (
                    <div>
                        <RadioButton 
                        data-paymentmethod = {JSON.stringify(paymentMethod)}
                        data-paymentmethod-type = {'EFT'}
                        name='payRadios'
                        id={'payradio_' + index}
                        data-index = {index}
                        checked = {index === parseInt(this.props.selectedPayIndex)}
                        label={(paymentMethod.nickname) !== null ? paymentMethod.nickname + ' ***' + paymentMethod.bankAccount?.accountNumber.slice(-4) : this.formatCCEFT(paymentMethod.bankAccount.bankAccountType) + ' ***' + paymentMethod.bankAccount?.accountNumber.slice(-4)}
                        value={paymentMethod}
                        onChange={this.props.handlePaymentMethodWalletSelection}
                        />
                        <div className="accountIcon">
                            <img src={paymentMethod.bankAccount?.bankAccountType.toLowerCase() === 'checking' ? CheckingIcon : SavingsIcon} alt="" />
                        </div>
                </div>
                )
            }
        } else if (paymentMethod?.bankAccount === null) {
            return (
                <div>
                    <RadioButton 
                    data-paymentmethod = {JSON.stringify(paymentMethod)}
                    data-paymentmethod-type = {'CC'}
                    data-index = {index}
                    name='payRadios'
                    id={'payradio_' + index}
                    checked = {index ===  parseInt(this.props.selectedPayIndex)}
                    label={(paymentMethod.nickname) !== null ? paymentMethod.nickname + ' ***' + paymentMethod.creditCard?.cardNumberLastFour : this.formatCCEFT(paymentMethod.creditCard.cardType) + ' ***' + paymentMethod.creditCard?.cardNumberLastFour}
                    value={paymentMethod}
                    onChange={this.props.handlePaymentMethodWalletSelection}
                    />
                    <div className="accountIcon">
                        <img src={paymentMethod.creditCard.cardType === 'VISA' ? VISA : paymentMethod.creditCard.cardType === 'MASTERCARD' ? MC : paymentMethod.creditCard.cardType === 'AMEX' ? AMEX : Discover} alt="" />
                    </div>
                </div>
            )
        }
    }

    render() {
        return (
            this.props.paymentMethods?.slice(this.props.startingIndex,this.props.endingIndex).map((paymentMethod, index) => (
                ( (this.validateBankAccountInfo(paymentMethod)) &&
                <div className="walletPaymentMethod" key={'walletItem_' + index}>
                    <div className={getRecurringPaymentFlag() ? 'walletAccountRec' : 'walletAccount'}>
                        <FormGroup groupName='walletItems' contentid='1002'>
                            {this.generateAccount(paymentMethod, parseInt(this.props.startingIndex)+index)}
                        </FormGroup>

                    </div>
                </div>
                )
            ))
        )
    }
}

export default FedPayMethods
