import axios from './ucpPayments-api';
import appConfigs from '../app.config';
import { getEncStr} from '../../server/util/encDec';

export const updateGPSPaymentAPI = async (payment) => {

     if(payment.bankAccount != null ){

      payment.bankAccount.accountNumber = getEncStr(payment.bankAccount.accountNumber);
      payment.bankAccount.routingNumber = getEncStr(payment.bankAccount.routingNumber);
      
     }
	return axios.post(appConfigs.update_payment_service_path,
        payment).then(response =>{
				return response.data;
		})
		.catch((error) => {
            console.log(error.message);
            return {
                callFailed: true
            };
		});

};


export default updateGPSPaymentAPI;