import axios from './ucpPayments-api';
import appConfigs from '../app.config';
import { getDecStr} from '../../server/util/encDec';
export const retrievePaymentDetailsAPI = async (paymentId) => {
    var retrievePaymentRequest = {
        "paymentId": paymentId
    };

    let accNumber = null;
    let routingNumber = null;

	return axios.post(appConfigs.retrieve_payment_with_paymentId_service_path , 
        retrievePaymentRequest).then(response =>{
             if(response.data.gpResponsePayment.data.bankAccount != null){            
                    accNumber = getDecStr(response.data.gpResponsePayment.data.bankAccount.accountNumber);
                    routingNumber = getDecStr(response.data.gpResponsePayment.data.bankAccount.routingNumber);
                    response.data.gpResponsePayment.data.bankAccount.accountNumber = accNumber;
                    response.data.gpResponsePayment.data.bankAccount.routingNumber = routingNumber;
             }
				return response.data;
		})
		.catch((error) => {
            console.log(error.message);
            return {
                callFailed: true
            };
		});

};


export default retrievePaymentDetailsAPI;