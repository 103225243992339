import React, { Component } from 'react';
import {findPostPaymentDetails } from '../../../server/api/ccPayments-api';
import { addCreditCardInWallet, statusFromUpdateWalletCall } from '../../../server/api/addUpdateWalletService-api';
import Spinner from '../../UI/Spinner/Spinner'
import {getOverviewPageLink, getProductType} from '../../../server/util/localStorageRepo'
import { trackFormSubmit ,trackError, trackPageLoad} from '../../../server/util/analytics'
import secureLocalStorage from 'react-secure-storage';


class CCTokenSuccess extends Component {
    state = {
        cardType: "",
        cardTypeId: "0",
        cardNumber: "",
        token: "",
        transactionDate: "",
        cardHolderName: "",
        expirationDate: "",
        upgAccountId: "",
        transactionId: "",
        redirectionPath: "/manage-wallet",
        loaded: false,
        mfaValidation:false,
        isMFA: sessionStorage.getItem('isMFA') ? JSON.parse(sessionStorage.getItem('isMFA')) : false,
        productType: getProductType(),
        isHSIDSSO: false
    };
    /* componentWillUnmount() {
        document.body.removeAttribute('class');
    } */

    componentDidMount() {
        
        let params = window.location.href;
        console.log("Params: " + params);
        if (window.location && window.location.href && window.location.href.indexOf("?") > -1) {
            console.log("Redirect URL: " + window.location.href);
            var requestUrl = window.location.href;
            let transactionId = "", accountId = "";
            for (var i = 0; i < requestUrl.split('?')[1].split('&').length; i++) {
                if (requestUrl.split('?')[1].split('&')[i].indexOf("transactionId") > -1) {
                    transactionId = requestUrl.split('?')[1].split('&')[i].split('=')[1]

                } else if (requestUrl.split('?')[1].split('&')[i].indexOf("accountId") > -1) {
                    accountId = requestUrl.split('?')[1].split('&')[i].split('=')[1];
                }
            }

            findPostPaymentDetails(
                transactionId,
                accountId
            )
                .then((response) => {
                    if (response.status === 200 && response.data && response.data.success && response.data.data) {
                        let responseData = response.data.data, transDate = "", cType = "", cTypeId = "0", token = '';
                        
                        if (responseData.token) {
                            token = responseData.token
                            let cardtype = responseData.token.charAt(0);
                            switch (cardtype) {
                                case 'V':
                                    cType = "VISA";
                                    cTypeId = "1";
                                    break;
                                case 'M':
                                    cType = "MASTERCARD";
                                    cTypeId = "2";
                                    break;
                                case 'D':
                                    cType = "DISCOVER";
                                    cTypeId = "3";
                                    break;
                                default:
                                    cType = "UNKNOWN";
                                    cTypeId = "0";
                            }
                        }

                        if (responseData.transactionDate) {
                            transDate = responseData.transactionDate;
                        }
                        if (secureLocalStorage.getItem('ccAdded')) {
                            secureLocalStorage.removeItem('ccAdded');
                        }
                        console.log('call gps wallet update here')
                        let ccRequest = {
                          billingCode : token.substring(token.length-10,token.length-4),
                          cardNumberLastFour : responseData.accountNumberMasked.slice(-4),
                          cardType : cType,
                          token : token,
                          expiration : responseData.accountAccessory,
                          nameOnCard : decodeURIComponent(responseData.holderName.split('+').join(" ").trim()),
                          nickName : responseData._ud_input1 ? decodeURIComponent(responseData._ud_input1.split('+').join(" ").trim()) : '',
                          autopayTimelines : null
                        }
                        try {
                            let items = JSON.parse(secureLocalStorage.getItem("items"))
                            let updateDataPojo = JSON.parse(secureLocalStorage.getItem("updateWalletData"))
                            let existItem = updateDataPojo.items.filter(item => (item.active.toLowerCase() === 'true' && item.creditCard && item.creditCard.cardType === ccRequest.cardType && item.creditCard.cardNumberLastFour === ccRequest.cardNumberLastFour
                                && item.creditCard.expiration === ccRequest.expiration ));
                            console.log(existItem)
                            if(existItem.length>0) {
                              secureLocalStorage.setItem('ccAdded', 'exist-'+ccRequest.cardType +'***'+ccRequest.cardNumberLastFour)
                              sessionStorage.setItem('notificationCount', 1)
                              sessionStorage.setItem('walletCCFlow', true)
                              window.top.location.assign(getOverviewPageLink()+'#/manage-wallet')
                            } else {
                              addCreditCardInWallet(items, ccRequest, updateDataPojo).then(res=> {
                                console.log(res)
                                secureLocalStorage.setItem('ccAddAPI','true')
                                trackPageLoad('manage my wallet');
                                if(res.success) {
                                    console.log('cc added success')
                                    trackFormSubmit(' manage my wallet', 'manage my wallet - credit card/debit card - add new payment method', 'PII Information');
                                    sessionStorage.setItem('notificationCount', 0)
                                    sessionStorage.setItem('newSession', false);
                                    sessionStorage.setItem('updateRecurring', false)
                                } else if(res.statusCode == 500) {
                                    let errors = res.errors ? res.errors.map(elt=> elt.detail) : []
                                    console.log(errors)
                                    trackError('manage my wallet', errors, 'credit card service error', 'service error');
                                    secureLocalStorage.setItem('ccAdded', 'error')
                                    secureLocalStorage.setItem('ccAddErrorCode',res.statusCode);
                                    sessionStorage.setItem('notificationCount', 1)
                                } else if(statusFromUpdateWalletCall == "403") {
                                    let errors = res?.errors?.map(elt=> elt.detail)
                                    console.log(errors)
                                    trackError('manage my wallet', errors, 'credit card service error', 'service error');
                                    secureLocalStorage.setItem('ccAdded', 'error')
                                    secureLocalStorage.setItem('ccAddErrorCode','403')
                                    sessionStorage.setItem('notificationCount', 1)
                                } else if(res.statusCode == 400) {
                                    let errors = res.errors ? res.errors.map(elt=> elt.detail) : []
                                    console.log(errors)
                                    trackError('manage my wallet', errors, 'credit card service error', 'service error');
                                    secureLocalStorage.setItem('ccAdded', 'error')
                                    secureLocalStorage.setItem('ccAddErrorCode',res.statusCode);
                                    sessionStorage.setItem('notificationCount', 1)
                                } else {
                                    let errors = res?.errors?.map(elt=> elt.detail)
                                    console.log(errors)
                                    trackError('manage my wallet', errors, 'credit card service error', 'service error');
                                    secureLocalStorage.setItem('ccAdded', 'error')
                                    secureLocalStorage.setItem('ccError', JSON.stringify(errors))
                                    sessionStorage.setItem('notificationCount', 1)
                                }
                                if (sessionStorage.getItem('isMFAAuthenticated')) {
                                    sessionStorage.setItem('walletCCFlow', true)
                                }
                                window.top.location.assign(getOverviewPageLink()+'#/manage-wallet')
                              }).catch(err=> {
                                secureLocalStorage.setItem('ccAdded', 'error')
                                sessionStorage.setItem('notificationCount', 1)
                                console.log(err)
                                if (sessionStorage.getItem('isMFAAuthenticated')) {
                                    sessionStorage.setItem('walletCCFlow', true)
                                }
                                window.top.location.assign(getOverviewPageLink()+'#/manage-wallet')
                              })
                            }
                        } catch (error) {
                            secureLocalStorage.setItem('ccAdded', 'error')
                          sessionStorage.setItem('notificationCount', 1)
                          console.log(error)
                          if (sessionStorage.getItem('isMFAAuthenticated')) {
                            sessionStorage.setItem('walletCCFlow', true)
                        }
                          window.top.location.assign(getOverviewPageLink()+'#/manage-wallet')
                        }
                      
                    } else {
                      window.top.location.assign(getOverviewPageLink()+'#/service-error')
                    }
                }).catch(error => {
                  console.log(error)
                  window.top.location.assign(getOverviewPageLink()+'#/service-error')
                });
        }
        
    }

    render(props) {
        return (<Spinner />)
    }
}

export default CCTokenSuccess;
