import React, { Component, Fragment } from 'react'
import { overviewBillPayAem } from '../../paymentOverview/paymentOverview';
import GridSpinner from '../../../UI/Spinner/GridSpinner';
import { Typography } from '@uhc-tempo/components';
import moment from 'moment';
import pdficon from '../../../tokens/icons/pdf-icon.png';
import { triggerEvent } from '../../../../server/util/analytics';
import { Markup } from 'interweave';
import { convertToCurrency, isDesktop } from '../../../../server/util/util';

export class MobileHistoryTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
        }

        this.getPaymentCells = this.getPaymentCells.bind(this);
        this.getBillingCells = this.getBillingCells.bind(this);
    }

    //Get the billing status of bill whether paid or not.
    getBillingStatus(cell) {
        let isPaid = false
        if (this.props.plan.productType === 'SHIP') {
            isPaid = cell.paymentStatus === 'PAID' ? true : false
        } else {
            isPaid = (cell.balanceDue === 0 || cell.balanceDue < 0) ? true : false
        }
        return isPaid ? <span style={{ color: "rgb(0, 112, 0)" }}>{overviewBillPayAem.auth_paid_text}</span> : <span style={{ color: 'rgb(111, 111, 111)' }}>{overviewBillPayAem.auth_unpaid_text}</span>
    }

    downloadInvoicePDF = (pdfURL) => {
        let dtmPDFParams = { pdfName:'download-billinghistory'};
        triggerEvent('pdf',dtmPDFParams);
        window.open('/medicare'+pdfURL);
    };  

    //Hydrating pending payment table
    getPendingCells = (cell, index) => {
        if (cell) {
            return (
                <div className="mobileHistoryDataContainer" key={index}>
                    <div className="mobileHistoryDataHeaders">{overviewBillPayAem.auth_paydate_table_head}</div>
                    <div className="mobileHistoryDataCell">
                    <Typography.Paragraph>
                            {(this.props.plan.productType === 'SHIP' ? cell.paymentDate : cell.startDate)
                                ?
                                moment(this.props.plan.productType === 'SHIP' ? cell.paymentDate : cell.startDate).format('MM/DD/YYYY')
                                :
                                'No date available'
                            }
                        </Typography.Paragraph>
                    </div>
                    <div className="mobileHistoryDataHeaders">{overviewBillPayAem.auth_amt_table_head}</div>
                    <div className="mobileHistoryDataCell">
                    <Typography.Paragraph>{convertToCurrency(this.props.plan.productType === 'SHIP' ? 
                            cell.paymentAmount : 
                            cell.oneTimeAmount) < 0 ? 
                            '-$' + convertToCurrency(this.props.plan.productType === 'SHIP' ? 
                            cell.paymentAmount : 
                            cell.oneTimeAmount * -1) : 
                            '$' + convertToCurrency(this.props.plan.productType === 'SHIP' ? 
                            cell.paymentAmount : 
                            cell.oneTimeAmount)}
                        </Typography.Paragraph>
                    </div>  
                    <div className="mobileHistoryDataHeaders">{overviewBillPayAem.auth_status_table_head}</div>
                    <div className="mobileHistoryDataCell">
                    <Typography.Paragraph><span style={{ color: 'rgb(90, 90, 90)', fontStyle: 'italic' }}>{overviewBillPayAem.auth_status_pending}</span></Typography.Paragraph>
                    </div>
                    <div className="mobileHistoryDataHeaders">{overviewBillPayAem.auth_pay_mthd_table_head}</div>
                    <div className="mobileHistoryDataCell">  
                        <Typography.Paragraph>
                            {overviewBillPayAem.auth_pending_not_available} 
                        </Typography.Paragraph>
                    </div> 
                </div>
            )
        }
    }

    //Hydrating payment history table
    getPaymentCells = (cell, index) => {
        if (cell) {
            return (
                <div className="mobileHistoryDataContainer" key={index}>
                    <div className="mobileHistoryDataHeaders">{overviewBillPayAem.auth_paydate_table_head}</div>
                    <div className="mobileHistoryDataCell">
                        <Typography.Paragraph>
                                {(cell.paymentDate)
                                    ?
                                    moment(cell.paymentDate).format('MM/DD/YYYY')
                                    :
                                    'No date available'
                                }
                        </Typography.Paragraph>
                    </div>
                    <div className="mobileHistoryDataHeaders">{overviewBillPayAem.auth_amt_table_head}</div>
                    <div className="mobileHistoryDataCell">
                        <Typography.Paragraph>{cell.paymentAmount < 0 ? '-$' + convertToCurrency(cell.paymentAmount * -1) : '$' + convertToCurrency(cell.paymentAmount)}</Typography.Paragraph>
                    </div>  
                    <div className="mobileHistoryDataHeaders">{overviewBillPayAem.auth_status_table_head}</div>
                    <div className="mobileHistoryDataCell">
                        <Typography.Paragraph><span style={{ color: 'rgb(0, 112, 0)' }}>{overviewBillPayAem.auth_processed}</span></Typography.Paragraph>
                    </div>
                    <div className="mobileHistoryDataHeaders">{overviewBillPayAem.auth_pay_mthd_table_head}</div>
                    <div className="mobileHistoryDataCell">  
                        <Typography.Paragraph>
                        {this.props.plan.productType === 'SHIP' ? (
                            <>
                           <span style={{fontSize: '16px'}}>{cell.paymentMethod}</span>
                            </>
                        ) : 
                            <>
                            {cell.paymentMethod} 
                            </>
                        }
                            </Typography.Paragraph>
                    </div> 
                        {this.props.plan.productType === 'GOVT' ? (
                            <Fragment>
                                <div className="mobileHistoryDataHeaders">{overviewBillPayAem.auth_confirmation_header}</div>
                            <div className="mobileHistoryDataCell">
                                    <Typography.Paragraph>{cell.referenceNumber && cell.referenceNumber !== 0 ? cell.referenceNumber : overviewBillPayAem.auth_not_applicable_pay}</Typography.Paragraph>
                                </div>
                            </Fragment>
                        ) : null}
                </div>
            )
        }
    }

    splitDocId = (docId) => {
        if (/^\d+$/.test(docId)) {
            const chunks = docId.match(/.{1,4}/g);
            const joinDocId = chunks.join(' ');
            return joinDocId;
        }
    }

    //Hydrating billing history table
    getBillingCells = (cell, index) => {
        if (cell) {
            return (
                <div className="mobileHistoryDataContainer" key={index}>
                    <div className="mobileHistoryDataHeaders"><Typography.Paragraph>{overviewBillPayAem.auth_isb_due_date}</Typography.Paragraph></div>
                    <div className="mobileHistoryDataCell">
                        <Typography.Paragraph>
                                {(cell.dueDate)
                                    ?
                                    moment(cell.dueDate).format('MM/DD/YYYY')
                                    :
                                    'No date available'
                                }
                        </Typography.Paragraph>
                    </div>
                    <div className="mobileHistoryDataHeaders"><Typography.Paragraph>{this.props.plan.productType === 'SHIP' ? overviewBillPayAem.auth_isb_total_amt_due : overviewBillPayAem.auth_bill_amt_table_head}</Typography.Paragraph></div>
                    <div className="mobileHistoryDataCell">
                        <Typography.Paragraph>{this.props.plan.productType === 'SHIP' ? '$' + convertToCurrency(cell.amount) : cell.billedAmount < 0 ? '-$' + convertToCurrency(cell.billedAmount * -1) : '$' + convertToCurrency(cell.billedAmount)}</Typography.Paragraph>
                    </div>  
                    <div className="mobileHistoryDataHeaders"><Typography.Paragraph>{overviewBillPayAem.auth_isb_bill_statement_date}</Typography.Paragraph></div>
                    <div className="mobileHistoryDataCell">
                             <Typography.Paragraph>
                             {
                                cell.invoiceDate != null ?
                                moment(cell.invoiceDate).format('MM/DD/YYYY')
                                :
                                'Not available'
                                }
                            </Typography.Paragraph>
                    </div>
                    <div className="mobileHistoryDataHeaders"><Typography.Paragraph>{overviewBillPayAem.auth_isb_doc_id}</Typography.Paragraph></div>
                    <div className="mobileHistoryDataCell">  
                        <Typography.Paragraph style={{fontSize: '16px'}}>
                            { 
                                    cell.invoiceNumber != null ?
                                    this.splitDocId(cell.invoiceNumber)
                                    : 
                                    'Not available'
                                }</Typography.Paragraph>
                    </div> 
                        { 
                            cell.invoiceLink ? (
                                <Fragment>
                                    <div className="mobileHistoryDataHeaders"><Typography.Paragraph>{overviewBillPayAem.auth_billing_statement_header}</Typography.Paragraph></div>
                                    <div className="mobileHistoryDataCell">
                                    <button className="tds-link tds-link--medium" role="link" onClick={() => this.downloadInvoicePDF(cell.invoiceLink)}><img src={pdficon} alt="" aria-hidden="true"/> <span className="download-csv-btn">{overviewBillPayAem.auth_download_pdf_txt}</span> </button>
                                    </div>
                                </Fragment>
                            ) : <Fragment>
                                    <div className="mobileHistoryDataHeaders"><Typography.Paragraph>{overviewBillPayAem.auth_billing_statement_header}</Typography.Paragraph></div>
                                    <div className="mobileHistoryDataCell"><Typography.Paragraph>{overviewBillPayAem.auth_bill_statement_not_available}</Typography.Paragraph></div>
                                </Fragment>
                        }
                </div>
            )
        }
    }

    //Build history tables after some checks
    buildTable(data) {
        let totalRemaining = 0;
        return (
            <div className="mobileHistoryData">
                {data.map((cell, index) => {
                    totalRemaining += cell.balanceDue;
                    return (
                        this.props.history === 'Payment' ?
                        this.props.pendingTab ?
                            this.getPendingCells(cell, index)
                            :
                            this.getPaymentCells(cell, index)
                        :
                        this.getBillingCells(cell, index)
                    )
                })
                }
            </div>
        )
    }

    render() {
        return (
            <>
                {this.props.loading ? <GridSpinner /> : null}
                <div style={{ filter: this.props.loading ? 'blur(4px)' : '' }} className={this.props.data.length === 0 ? 'noDataHistory' : undefined}>
                    {this.props.data && this.props.data.length > 0 ?
                        this.buildTable(this.props.data)
                        :
                        null
                    }
                </div>
            </>
        )
    }
}

export default MobileHistoryTable
