/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { serverCurrentTime } from '../../../server/api/serverTime-api';
import aemAxios, { content_path } from '../../../server/api/aem-api';
import './PaymentReview.css';
import { Redirect } from 'react-router-dom';
import Moment from 'moment';
import { Typography, Checkbox, FormGroup, Notification } from '@uhc-tempo/components';
import Spinner from '../../UI/Spinner/Spinner';
import styled from 'styled-components';
import { capitalizeVal } from '../../../server/util/util';
import { trackPageLoad, trackModalWindowClick, trackLinkClick, trackError } from '../../../server/util/analytics'
import { Markup } from 'interweave';
import {getPlanInformation, getSSOValuefromHSID, getOneTimePaymentFlag, getRecurringPaymentFlag,
   getMemberFirstName, getMemberLastName, getMemberFullName, isManageRecurring, isCurrentRecurring, isEditPayment, setFeatureFlags, getPaymentMethod } from '../../../server/util/localStorageRepo';
import PageHeader from '../page-header/PageHeader';
import PaymentModals from '../modals/PaymentModals'
import Edit from '../../../src/tokens/icons/icon-edit.png';
import { convertToCurrency } from '../../../server/util/util';
import {eftCreatePaymentsubmissionResults} from '../../../server/api/eftCreatePaymentSubmissionResults-api';
import {creditCardCreatePaymentSubmissionResults} from '../../../server/api/ccCreatePaymentSubmissionResults-api';
import {getDecryptAATokenService} from '../../../src/api/decAAtokenService-api';
import commonConstants from '../../../server/util/commonConstants';
import {retrievePaymentDetailsAPI} from '../../../server/api/retrievePaymentService-api.js';
import { getWalletServiceAPI } from '../../../server/api/getWalletService-api'
import {addBankAccountInWallet, addCreditCardInWallet,addUpdateWalletServiceAPI} from '../../../server/api/addUpdateWalletService-api';
import { deleteTokenAPI } from '../../../server/api/ccDeleteToken-api'
import cloneDeep from 'lodash/cloneDeep';
import PageError from '../payment-details/PageError';
import updateGPSPaymentAPI from '../../../server/api/updateGPSPaymentService-api';
import HsidMfaModal from '../shared-components/HsidMfaModal';
import MfaLogOutModal from '../modals/MfaLogOutModal';
import { mfaLoggerInfo } from   '../../../server/api/mfaLoggingService-api';
import secureLocalStorage from 'react-secure-storage';
import appConfigs from '../../../server/app.config.js';
export let getWalletResponse = null;
export let items = [];
export let updateWalletData = null;
export let filteredWalletData = null;
export let featureFlagsAem = null;

class FedPaymentReview extends Component {
  constructor(props) {
    super(props)
    let payAmountPlaceHolder = '$__.__'
    this.modalRef = React.createRef()

    this.state = {
      defaultPaidAmoutPlaceHolder: payAmountPlaceHolder,
      firstName: '',
      lastName: '',
      fullName:'',
      memberId: '',
      planName: '',
      payAmount: payAmountPlaceHolder,
      totalAmount: '',
      serverDate: '',
      otherAmountError: '',
      otherAmount: '',
      paymentMethod: '',
      requestId: '',
      pastamountField: '',
      pastAmountAndCurentChargesField: '',
      onlyCurrentChargesField: '',
      bankHolderName: '',
      bankHolderFirstName: '',
      bankHolderMiddleName: '',
      bankHolderLastName: '',
      routingNumber: '',
      accountNumber: '',
      bankHolderNameError: false,
      routingNumberError: false,
      accountNumberError: false,
      formValidated: false,
      onload: true,
      errorMessage: '',
      isOpen: false,
      formErrorDisplay: 'Internal Server Error',
      formError: false,
      formSuccess: false,
      planTypeCode: '',
      planCategory: '',
      planId: '',
      sourcePortal: '',
      accountId: '',
      accountNumberFormated: '',
      redirect: false,
      fromPage: 'review',
      otherAmountChecked: false,
      pastAmountDueChecked: false,
      pastAmountAndCurentChecked: false,
      onlyCurrentChargesChecked: false,
      payInFullChecked : false,
      cardType: "",
      cardTypeId: "0",
      cardNumber: "",
      token: "",
      transactionDate: "",
      cardHolderName: "",
      expirationDate: "",
      upgAccountId: "",
      transactionId: "",
      paymentDt: "",
      redirectForm: 'payment-confirmed',
      callTriggered : false,
      elec_pay_modal : {},
      focusOnModal: false,
      pageLoadViaFlow: false,
      eftDataError:"",
      errorFields: null,
      apiErrors: false,
      accountStatusWrapper: "",
      individualId: "",
      userName: JSON.parse(localStorage.getItem('consumerDetails')).userName,
      hLinks : [],
      cancelRecChecked : false,
      newEftRecChecked : false,
      currentDate: "",
      submitAutomaticFailure: false,
      submitFilureFlag: false,
      submitOneTimeFailure: false,
      planType: "",
      spinnerLoading: true,
      ccFlow : false,
      saveCreditCard : false,
      payWithSavedCC : false,
      superUserError:false,
      savePayMethodChecked : false,
      nextMonthStartDt : "",
      withdrawalDate : "",
      isPayMethodSaved : false,
      isDuplicateItem : false,
      isAddCreditCardSuccess: false,
      serviceError: false,
      mfaValidation: false,
      isMFAInvalid :false,
      isMFA: false,
      isInboundSsoUser: false,
      mfaUrl: '',
      isMFAAuthenticated: false,
      mfaCallBackURL: '',
    }

  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.focusOnModal) {
      this.modalFocus()

      this.setState({
        focusOnModal: false,
      })
     }
  }

  
  backAction = () => {
    this.redirectHandler();
  }

  mfaCloseHandler = () => {
    this.setState({ mfaValidation: false, callTriggered: false })
  }

  checkPostMfa = () => {
    if (window.location.href.indexOf("post-mfa") > -1) {
      let queryParams = window.location.href.split('?')[1];
      console.log(queryParams)
      let postMfaState = queryParams.split('=')[1];
      if (postMfaState === 'true') {
        return true;
      }
    }
    return false;
  }
displayErrorMessage =() =>{
  this.setState({ formError: true,callTriggered : false, submitAutomaticFailure: true,formErrorDisplay: (getOneTimePaymentFlag() && this.state.savePayMethodChecked === true) ? `${this.state.mfa_service_failure_text}`: `${this.state.generic_system_error}`});
}
  componentDidMount() {
    let linksOneTime = [{title: 'Payment Overview',link: 'toOverview', click : this.openCancelModal},
    // eslint-disable-next-line no-script-url
    {title: 'Make a One-Time Payment', link: 'javascript:void(0)', click : this.redirectHandler},
    {title: 'Review and Submit', link: ''}];

    let linksRecurring = [{title: 'Payment Overview', link: 'toOverview', click : this.openCancelModal},
    // eslint-disable-next-line no-script-url
    {title: isManageRecurring() ? 'Manage Automatic Payments' : 'Setup Automatic Payments',link: 'javascript:void(0)', click : this.redirectHandler},
    {title: 'Review and Submit', link: ''}];

    this.setState({ hLinks: isManageRecurring() ? linksRecurring : linksOneTime})
    
    let isPageReloaded = true;
    if (window.performance) {
      if (performance.navigation.type === 1) {
        let paymentBy= secureLocalStorage.getItem('paymentFormData')?.paymentBy
        if(!paymentBy){
          secureLocalStorage.setItem('paymentFormData',null)
        }
        this.setState({spinnerLoading : false})
      }
    }
    //END  mfa logout modal testing 

    // mfa callback  to this page - start
   
let paymentType={
  "onetimePayment":getOneTimePaymentFlag(),
  "updateRecurring":getRecurringPaymentFlag()
}
this.setState({paymentType:paymentType})
    if (window.location && window.location.href) {
      if (window.location.href.indexOf("post-mfa") > -1) {
        let queryParams = window.location.href.split('?')[1];
        let postMfaState = queryParams.split('=')[1];
        if(postMfaState === 'true'){
          mfaLoggerInfo('MFA callback hit ',{"function":"MFA Callback Hit","status": "success",
          "paymentTypeUrl":secureLocalStorage.getItem("paymentTypeUrl"),
          "mfaCallBackURL": secureLocalStorage.getItem("mfaCallBackURL")});
          this.setState({spinnerLoading: true},() => {
            getDecryptAATokenService().then((response) => {
              if(response.data.userid === this.state.userName ){
              this.setState({isMFAAuthenticated: response.data.authStatus==="AUTHENTICATED" && response.data.aaUserStatus==="VERIFIED", isMFAInvalid: false,  mfaValidation: true, spinnerLoading: true }, () => {
                if(!this.state.isMFAAuthenticated){
                  mfaLoggerInfo("MFA encryption service AA token validation is failed ",{
                    "service": "HSID decrypt AA Token Validation",
                    "function":"MFA Callback unAuthenticated",
                    "status": "failed",
                    "paymentTypeUrl":secureLocalStorage.getItem("paymentTypeUrl"),
                    "mfaCallBackURL": secureLocalStorage.getItem("mfaCallBackURL")
                    });
                  trackModalWindowClick(secureLocalStorage.getItem('currPage') , 'invalid entry');
                }else{
                  mfaLoggerInfo("MFA decryption AA token service is success ",
                  {"function":"MFA Callback Authenticated","service":"HSID Encryption AA Token API ","status": "success",
                  "authStatus": response.data.authStatus,
                  "paymentTypeUrl":secureLocalStorage.getItem("paymentTypeUrl"),
                  "mfaCallBackURL": secureLocalStorage.getItem("mfaCallBackURL")
                  });
                }
                this.confirmAndPayHandler();
              });
              }else{
                mfaLoggerInfo("MFA decryption  AA token service user validation is failed ",{
                  "service": "HSID decrypt AA Token user Validation",
                  "function":"MFA Callback User uuid mismatch",
                  "status": "failed",
                  "paymentTypeUrl":secureLocalStorage.getItem("paymentTypeUrl"),
                  "mfaCallBackURL": secureLocalStorage.getItem("mfaCallBackURL")
                  });
                trackModalWindowClick(secureLocalStorage.getItem('currPage') , 'invalid entry');
                window.location.assign(window.location.origin +'/medicare/content/medicare/member/logout.html');
              } 
              
            }).catch(error => {
              mfaLoggerInfo("MFA decryption AA token service is failed ",{
                "function":"MFA Callback Hit System Issue",
                "service": "HSID Decypt AA Token API",
                "error": error ? (error.message ? error.message :" "):"",
                "status": "failed",
                "paymentTypeUrl":secureLocalStorage.getItem("paymentTypeUrl"),
                "mfaCallBackURL": secureLocalStorage.getItem("mfaCallBackURL")
                });
              if(error?.response?.status && error?.response?.status === 403){
                return  window.location.assign(window.location.origin +'/medicare/content/medicare/member/logout.html');
              }else {
                this.setState({ isMFAInvalid: true})
                this.setState({ formError: true,callTriggered : false, submitAutomaticFailure: true,formErrorDisplay:`${this.state.mfa_service_failure_text}`, spinnerLoading: false});
                window.scrollTo(0, 0);
              }
            });
          }) 
      }

    }
  }
    
    // mfa callback  to this page - end
    let payMethod = null ;
    secureLocalStorage.setItem('isPaymentRedesignPage',true);
    secureLocalStorage.setItem('currPage', 'review');
    let paymentformData=JSON.parse(secureLocalStorage.getItem('paymentFormData'));
    if (paymentformData != null && paymentformData !== "") {
      this.setState({
        payAmount: paymentformData.payAmount,
        paymentMethod : paymentformData.ccFlow ? 'CC' : paymentformData.paymentMethod,
        lastUsedEft: paymentformData.lastUsedEft,
        otherAmount: paymentformData.otherAmount,
        pastAmountDueChecked: paymentformData.pastAmountDueChecked,
        pastAmountAndCurentChecked: paymentformData.pastAmountAndCurentChecked,
        onlyCurrentChargesChecked: paymentformData.onlyCurrentChargesChecked,
        payInFullChecked: paymentformData.payInFullChecked,
        otherAmountChecked: paymentformData.otherAmountChecked,
        cardType: paymentformData.cardType,
        cardTypeId: paymentformData.cardTypeId,
        cardNumber: paymentformData.cardNumberLastFour,
        token: paymentformData.creditCardToken,
        billingCode : paymentformData.billingCode,
        nameOnCard : paymentformData.nameOnCard,
        transactionDate: paymentformData.transactionDate,
        expirationDate: paymentformData.expiration,
        upgAccountId: paymentformData.upgAccountId,
        transactionId: paymentformData.transactionId,
        bankHolderFirstName: paymentformData.bankHolderFirstName,
        bankHolderMiddleName: paymentformData.bankHolderMiddleName,
        bankHolderLastName: paymentformData.bankHolderLastName,
        routingNumber: paymentformData.routingNumber,
        accountNumber: paymentformData.accountNumber,
        authorizationInd : paymentformData.authorizationInd,
        bankAccountType : paymentformData.bankAccountType,
        eftBankType : paymentformData.eftBankType,
        paymentBy : paymentformData.paymentBy,
        totalAmount: secureLocalStorage.getItem('totalAmount'),
        cancelRecChecked : paymentformData.cancelRecChecked === undefined ? false : paymentformData.cancelRecChecked,
        ccFlow : paymentformData.ccFlow ?  true : false,
        payWithSavedCC : paymentformData.payWithSavedCC,
        superUser: localStorage.getItem("superUser") === "true" ? true : false,
        paymentDate : paymentformData.paymentDate,
        nickName : paymentformData.nickName,
        itemId : paymentformData.itemId,
        activeWalletItems : paymentformData.activeWalletItems,
        selectedPayIndex : paymentformData.selectedPayIndex,
        fullMOPList : paymentformData.fullMOPList,
        isAddPayMethodOpen : paymentformData.isAddPayMethodOpen,
        scheduleChoice : paymentformData.scheduleChoice,
        paymentToEdit : paymentformData.paymentToEdit,
        recScheduleChoice : paymentformData.recScheduleChoice,
        savePayMethodChecked :paymentformData.savePayMethodChecked ? paymentformData.savePayMethodChecked : false,
        totalAmountDue: paymentformData.totalAmountDue,
      }, ()=> {
        if(!this.state.ccFlow) {
          window.history.pushState({page: 'review'}, "reviewPage", window.location.href);
          window.onpopstate = this.backAction;
        }else{
          let decodedNameOnCard= this.state.nameOnCard ? decodeURIComponent(this.state.nameOnCard) : ""
          let decodedNickName=this.state.nickName ? decodeURIComponent(this.state.nickName) : ""
          this.setState({
            nameOnCard : decodedNameOnCard ,
            nickName : decodedNickName
          })
        }
      });
      if (paymentformData.pageLoadViaFlow) {
        isPageReloaded = false;
        this.setState({
          pageLoadViaFlow: true
        })
      }
    }
     if(isPageReloaded) {
      this.setState({
        showSessionModal: true
      })
      return
    } 

    window.scrollTo(0,0);
    trackPageLoad(isEditPayment() ? 'Update one time payment:review and submit' : getOneTimePaymentFlag() ? 'make a one time payment:review and submit' : isManageRecurring()  ?  'manage recurring payment:review and submit':
                'set up recurring payment:review and submit')
    var meta = document.createElement('meta');
    meta.name = "robots";
    meta.content = "noindex, nofollow";
    document.getElementsByTagName('head')[0].appendChild(meta);
    let date = null;
    if(secureLocalStorage.getItem('currSysTimeInMs')){
     date = new Date(parseInt(secureLocalStorage.getItem('currSysTimeInMs')))
     this.setState(
      {
        paymentDt: Moment(date).format('MM/DD/YYYY HH:mm:ss'),
        serverDate: Moment(date).format('MM/DD/YYYY') + ' (Today)',
        currentDate: Moment(date).format('YYYY-MM-DD'),
      }
    )
    }else{
    serverCurrentTime().then((response) => {
      if (response) {
      date = new Date(parseInt(response.systemtimeinmillis))
      this.setState(
        {
          paymentDt: Moment(date).format('MM/DD/YYYY HH:mm:ss'),
          serverDate: Moment(date).format('MM/DD/YYYY') + ' (Today)',
          currentDate: Moment(date).format('YYYY-MM-DD'),
        }
      )
      secureLocalStorage.setItem('currSysTimeInMs',response.systemtimeinmillis);	       
      }
    })
  }



    if(payMethod === "CC") {
      let pAmount =  secureLocalStorage.getItem("payAmount");
      this.setState({
        paidAmount: convertToCurrency(pAmount.replace('$', ''))
      })
    } else {
      if (secureLocalStorage.getItem('guestAccountNumber') != null) {
        let accountNum = secureLocalStorage.getItem('guestAccountNumber');
        let formatedAccountNum = "";
        if (accountNum) {
          formatedAccountNum = accountNum.replace(/\d{4}(?= \d{4})/g, '****');
        }
        this.setState({
          accountNumber: accountNum,
          paidAmount: parseFloat(secureLocalStorage.getItem('guestPaidAmount').replace('$', '')),
          routingNumber: secureLocalStorage.getItem('guestRoutingNumber'),
          bankHolderName: secureLocalStorage.getItem('guestBankHolderName'),
          accountNumberFormated: formatedAccountNum
        })
      }
    }

    if (getMemberFirstName()) {
      this.setState({ firstName: getMemberFirstName() })
    }
    if (getMemberLastName()) {
      this.setState({ lastName: getMemberLastName() })
    }
    if (getMemberFullName()) {
      this.setState({ fullName: getMemberFullName() })
    }
    this.setState({
      memberId: getPlanInformation().memberNumber
    })

    if (getPlanInformation() != null && getPlanInformation() != null && getPlanInformation().planName != null) {
      this.setState({ planName: getPlanInformation().planName })
    }

    if (getPlanInformation() != null && getPlanInformation() != null && getPlanInformation().planType != null) {
      this.setState({ planType: getPlanInformation().planType})
    }
    if (secureLocalStorage.getItem('guestAccountId') != null) {
      this.setState({ accountId: secureLocalStorage.getItem('guestAccountId') })
      this.setState({ planCategory: secureLocalStorage.getItem('guestPlanCategory') })
      this.setState({ planId: secureLocalStorage.getItem('guestPlanId') })
      this.setState({ planTypeCode: secureLocalStorage.getItem('guestplanTypeCode') })
    }

    aemAxios
    .get(content_path + '/feature-flags/memberpay-featureflags/jcr:content/data/master.json?timestamp=' + new Date().getTime())
    .then((response) => {
      featureFlagsAem = response.data
      this.setState({
        isMFA: featureFlagsAem.auth_mfa_enable
      },() => {
        // HSID SSO Check
     if(this.state.isMFA){
      getSSOValuefromHSID().then((res) => {
        this.setState({isInboundSsoUser : res})
      })
    }
      })
      setFeatureFlags(featureFlagsAem);
    })
    .catch((error) => {
        if(sessionStorage.getItem('isMFA')) {
          this.setState({ isMFA : JSON.parse(sessionStorage.getItem('isMFA'))});
        } else {
          this.setState({ isMFA : false})
        }
    })

     

    aemAxios
      .get(content_path + '/payment-review-new/payment-review-content-new/jcr:content/data/master.json?timestamp=' + new Date().getTime())
      .then((response) => {
        let elec_pay_modal = {
          label: response.data.elec_pay_modal_label,
          header: response.data.elec_pay_modal_title,
          subheader:response.data.elec_pay_modal_subheader,
          desc_content_onetime: response.data.auth_fed_eft_otp_agreement,
          desc_content_setup: response.data.auth_fed_setup_agreement,
          credit_desc: getOneTimePaymentFlag() || isEditPayment() ? response.data.elec_pay_credit_modal_desc: getRecurringPaymentFlag() ? response.data.elec_pay_credit_modal_desc_recurring: '',
          payMethod: payMethod,
          btn_txt: response.data.elec_pay_modal_btn,
          elec_pay_modal_error: response.data.elec_pay_modal_error,
          elec_pay_modal_checkbox_content:response.data.elec_pay_modal_checkbox_content,
          accept_btn: response.data.auth_mdl_accept_btn
        }
        let cancelPaymentModal = {
          header:  isEditPayment() ? response.data.payment_cancel_update_header : response.data.auth_review_cancelmodal_title,
          cancelpay_txt: isEditPayment() ? response.data.payment_cancel_update_txt : '',
          btn_txt: isEditPayment() ? response.data.payment_update_cancel_btn :  response.data.auth_review_takingback_content,
          btn_txt1: response.data.auth_review_stayonpage_btn,
          cancelConfirmModal : {
            header: response.data.auth_review_pay_cancel_title,
            bckTxt:response.data.auth_review_takingback_content,
            cancelpay_txt: isEditPayment() ? response.data.payment_update_cancel_btn :  response.data.auth_review_cancelpay_btn,
          }
        }

        let mfaContent = {
          header : response.data.auth_mfa_header_title,
          model_msg : response.data.auth_mfa_modal_content,
          btn_text : response.data.auth_mfa_co_trans_btn,
          btn_text1 : response.data.auth_mfa_cancel_btn_txt,
          closeHandler : this.mfaCloseHandler
       }

        this.state.hLinks[0].title = response.data.auth_bc_pay_ov_label;
        this.state.hLinks[2].title = response.data.auth_bc_review_submit_label;

        if (getOneTimePaymentFlag()){
          this.state.hLinks[1].title = response.data.auth_bc_make_one_tp_label;
         } else if(isEditPayment()){
          this.state.hLinks[1].title = response.data.auth_bc_update_one_tp_label;
         } else if(getRecurringPaymentFlag() && !isManageRecurring()) {
          this.state.hLinks[1].title = response.data.auth_bc_setup_auto_pay_label;
        } else if(isManageRecurring()) {
          this.state.hLinks[1].title = response.data.auth_bc_update_set_label;
        }
        this.setState({
          banner_text: window.innerWidth < 770 ? response.data.auth_banner_text_mobile : response.data.banner_text,
          remember_pay_method: response.data.auth_remember_pay_method,
          back_btn:  response.data.auth_back_review_link_text,
          header_sub_section: response.data.header_sub_section,
          pymt_dtls_lbl: response.data.pymt_dtls_lbl,
          change_details_lbl: response.data.auth_change_details_lbl,
          card_header_lbl: response.data.card_header_lbl,
          plan_label: response.data.plan_label,
          name_label: response.data.name_label,
          id_label: response.data.id_label,
          details_label: response.data.details_label,
          auth_setup_pay_text: response.data.auth_setup_pay_text,
          auth_update_pay_text: response.data.auth_update_pay_text,
          auth_onetime_pay_text: response.data.auth_onetime_pay_text,
          auth_update_onetime_pay_text: response.data.auth_update_onetime_pay_text,
          auth_manage_recurring_text: response.data.auth_manage_recurring_text,
          auth_cancel_recurring_text: response.data.auth_cancel_recurring_text,
          auth_new_text: response.data.auth_new_text,
          total_label: response.data.total_label,
          total_label_note: response.data.total_label_note,
          cr_total_label_note: response.data.cr_total_label_note,
          pay_agreement: response.data.pay_agreement,
          button_label: response.data.button_label,
          button_label_managerecurring: response.data.button_label_managerecurring,
          button_label_setuprecurring: response.data.button_label_setuprecurring,
          button_label_cancelrecurring: response.data.button_label_cancelrecurring,
          elec_pay_modal: elec_pay_modal,
          cancelPaymentModal:cancelPaymentModal,
          mfaContent: mfaContent,
          eftDataError: response.data.efterror,
          auth_payment_effective_date: response.data.auth_payment_effective_date,
          auth_acknowledge_header: response.data.auth_acknowledge_header,
          auth_total_note: response.data.auth_total_note,
          auth_total_desc_setup_pay: response.data.auth_total_desc_setup_pay,
          auth_total_desc_setup_pay_fed: response.data.auth_total_desc_setup_pay_fed,
          auth_setup_pay_total_note: response.data.auth_setup_pay_total_note,
          auth_new_eft_check_label: response.data.auth_new_eft_check_label,
          auth_new_eft_savings_label: response.data.auth_new_eft_savings_label,
          auth_eft_checking_pay_label: response.data.auth_eft_checking_pay_label,
          auth_eft_savings_pay_label: response.data.auth_eft_savings_pay_label,
          cancel_btn_text: response.data.auth_cancel_btn_text,
          onetime_agreement: response.data.auth_agreement_onetime_fed,
          auth_acknowledge_header_setuprecurring: response.data.auth_acknowledge_header_setuprecurring,
          auth_acknowledge_header_cancelrecurring:  response.data.auth_acknowledge_header_cancelrecurring,
          auth_acknowledge_header_managerecurring: response.data.auth_acknowledge_header_managerecurring,
          setuprecurring_agreement: response.data.auth_agreement_setuprecurring_fed,
          managerecurring_agreement: response.data.auth_agreement_managerecurring_fed,
          cancelrecurring_agreement: response.data.auth_agreement_cancelrecurring_fed,
          bc_payment_overview: response.data.auth_bc_pay_ov_label,
          bc_make_one_time: response.data.auth_bc_make_one_tp_label,
          bc_update_one_time: response.data.auth_bc_update_one_tp_label,
          bc_setup_auto_pay: response.data.auth_bc_setup_auto_pay_label,
          bc_manage_auto_pay: response.data.auth_bc_manage_set_label,
          bc_review_submit: response.data.auth_bc_review_submit_label,
          audit_start_date:response.data.audit_start_date,

          //Error messages
          generic_system_error: response.data.auth_mw_system_err_title_msg,
          error_required_param: response.data.error_required_param,
          error_householdId_not_active: response.data.error_householdId_not_active,
          error_unknown_data: response.data.error_unknown_data,
          mfa_service_failure_text : response.data.auth_mfa_service_failure_text,
          error_sytem_error: response.data.error_sytem_error,
          error_system_error_title: response.data.error_system_error_title,
          error_eft_resubmission: response.data.error_eft_resubmission,
          error_eft_webservice: response.data.error_eft_webservice_submission,
          error_fed_resubmission : response.data.auth_error_fed_resubmission,
          formErrorDisplay : (!this.state.isMFAAuthenticated && getOneTimePaymentFlag() && this.state.savePayMethodChecked === true) ? response.data.auth_mfa_service_failure_text : response.data.auth_mw_system_err_title_msg,
          superUserErrorMsg: response.data.superUserErrorMsg,
          error_eft_eapr: response.data.error_eft_eapr,
          error_cc_nameoncard : response.data.error_cc_nameoncard,
          error_cc_card_expired_title: response.data.error_cc_card_expired_title,
          error_cc_card_expired : response.data.error_cc_card_expired,
          error_cc_invalid_nameoncard : response.data.error_cc_invalid_nameoncard,
          error_cc_system_error : response.data.auth_error_cc_system_error,
          cc_invalid_nick_name : response.data.auth_error_cc_invalid_nick_name,
          error_cc_nickname_length : response.data.auth_error_cc_nickname_length,
          error_cc_duplicate : response.data.auth_error_cc_duplicate,
          error_cc_duplicate_title : response.data.auth_error_cc_duplicate_title,
          error_bank_all_fields_required : response.data.auth_error_bank_all_fields_required,
          error_bank_duplicate_acc : response.data.auth_error_bank_duplicate_acc, 
        }, () => {
          this.setState({ aemRendered: true}, () => {
            if (this.state.isMFA && this.state.aemRendered) {
              setTimeout(()=> {
                this.setState({spinnerLoading : false})
              }, 3000)
            } else if (this.checkPostMfa() && this.state.isMFA) {
              this.setState({ spinnerLoading: true})
            }
              else {
                setTimeout(()=> {
                  this.setState({spinnerLoading : false})
                }, 3000)
            }
          })
        });
      })
      .catch((error) => {
        this.setState({
          loadError: true,
          loading: false
        });
      });
  }

  modalFocus = () => {
    trackModalWindowClick( 'Electronic Payment Authorization');
    trackLinkClick('Electronic Payment Authorization');
    if(null != this.modalRef.current) {
      this.modalRef.current.focus()
    }
  }

  openCancelModal = () => {
    document.getElementById('cancelBtn').click();
  }
  redirectHandler = () => {
    this.setState({ redirect: true }, ()=> this.renderRedirect())
  }
  renderRedirect = () => {
    if (this.state.redirect) {
      secureLocalStorage.setItem('isPaymentRedesignPage',false);
     let paymentInfo=JSON.parse(secureLocalStorage.getItem('paymentFormData'));
      return (
        <Redirect
          to={{
            pathname: isEditPayment()? '/federal-update-payment' : getOneTimePaymentFlag() ? '/federal-make-payment' : '/federal-recurring',
            state: {
              bankHolderName: this.state.bankHolderName,
              routingNumber: this.state.paymentBy === 'wallet' ? this.state.routingNumber : '',
              accountNumber: this.state.paymentBy === 'wallet' ? this.state.accountNumber : '',
              bankHolderFirstName: this.state.bankHolderFirstName,
              bankHolderMiddleName: this.state.bankHolderMiddleName,
              bankHolderLastName: this.state.bankHolderLastName,
              nickName: this.state.nickName,
              paymentMethod: this.state.paymentMethod,
              lastUsedEft: this.state.lastUsedEft,
              payAmount: this.state.payAmount.replace(',', ''),
              paidAmount: this.state.payAmount.replace(',', ''),
              fromPage: this.state.fromPage,
              pastAmountDueChecked: this.state.pastAmountDueChecked,
              pastAmountAndCurentChecked: this.state.pastAmountAndCurentChecked,
              onlyCurrentChargesChecked: this.state.onlyCurrentChargesChecked,
              payInFullChecked: this.state.payInFullChecked,
              otherAmountChecked: this.state.otherAmountChecked,
              pageLoadViaFlow: true,
              cancelRecChecked : this.state.cancelRecChecked,
              paymentDate : this.state.paymentDate,
              ccFlow : this.state.ccFlow,
              activeWalletItems : this.state.activeWalletItems,
              selectedPayIndex : this.state.selectedPayIndex,
              fullMOPList : this.state.fullMOPList,
              paymentBy: this.state.paymentBy,
              eftBankType : this.state.eftBankType,
              bankAccountType : this.state.bankAccountType,
              isAddPayMethodOpen : this.state.isAddPayMethodOpen,
              scheduleChoice : this.state.scheduleChoice,
              cardType: this.state.cardType,
              cardNumber: this.state.cardNumber,
              token: this.state.token,
              billingCode : this.state.billingCode,
              nameOnCard : this.state.nameOnCard,
              transactionDate: paymentInfo.transactionDate,
              cardHolderName: paymentInfo.cardHolderName,
              expirationDate: paymentInfo.expiration,
              upgAccountId: paymentInfo.upgAccountId,
              transactionId: paymentInfo.transactionId,
              itemId : this.state.itemId,
              authorizationInd :this.state.authorizationInd,
              paymentToEdit : this.state.paymentToEdit,
              recScheduleChoice : this.state.recScheduleChoice,
              ssaDates: paymentInfo.ssaDates,
              paymentsMethodValue: this.props.location?.state?.paymentsMethodValue ? this.props.location?.state?.paymentsMethodValue : secureLocalStorage.getItem("paymentsMethodValue"),
              pastAmountDue: this.props.location?.state?.pastAmountDue ? this.props.location?.state?.pastAmountDue : secureLocalStorage.getItem('pastAmountDue'),
              pastAmountAndCurentCharges: this.props.location?.state?.pastAmountAndCurentCharges ? this.props.location?.state?.pastAmountAndCurentCharges 
                                        : secureLocalStorage.getItem('pastAmountAndCurentCharges'),
              currentCharges: this.props.location?.state?.currentCharges ? this.props.location?.state?.currentCharges : secureLocalStorage.getItem('currentCharges'),
              pastAmountDueFound: this.props.location?.state?.pastAmountDueFound ? this.props.location?.state?.pastAmountDueFound : secureLocalStorage.getItem('pastAmountDueFound'),
              pastAmountAndCurentChargesFound: this.props.location?.state?.pastAmountAndCurentChargesFound ? this.props.location?.state?.pastAmountAndCurentChargesFound : secureLocalStorage.getItem('pastAmountAndCurentChargesFound'),
              onlyCurrentChargesFound: this.props.location?.state?.onlyCurrentChargesFound ? this.props.location?.state?.onlyCurrentChargesFound : secureLocalStorage.getItem('onlyCurrentChargesFound'),
              totalAmountDue: this.props.location?.state?.totalAmountDue ? this.props.location?.state?.totalAmountDue : secureLocalStorage.getItem('totalAmountDue'),
              eftNumber: this.props.location?.state?.eftNumber ? this.props.location?.state?.eftNumber : secureLocalStorage.getItem('eftNumber'),
            },
          }}
        />
      )
    }
  }
  savePMchecked = (savePayMethodChecked) => {
    let paymentData=JSON.parse( secureLocalStorage.getItem('paymentFormData'));
    paymentData.savePayMethodChecked =savePayMethodChecked;
    paymentData.totalAmountDue = this.props.location.state.totalAmountDue;
    secureLocalStorage.setItem("paymentFormData", JSON.stringify(paymentData));
  }
  confirmAndPayHandler = () => {
    if(!this.state.isMFA) {
      this.setState({spinnerLoading : true});
    }
    let planInformation  = getPlanInformation();
    if (this.state.superUser) {
           this.setState ({superUserError:true});
    } else if (planInformation.productType === 'GOVT' && isEditPayment()) {
      this.setState({ callTriggered: true });
      window.scrollTo(0,0);
      this.updateGPSPayment(this.state.paymentToEdit);
    } else {
     

      this.setState({ callTriggered: true });
      window.scrollTo(0,0);
      serverCurrentTime().then((response) => {
          if (response) {
            let date = new Date(parseInt(response.systemtimeinmillis))
            //cut off 10pm CST logic for create payment 
            let CSTDate=date.toLocaleString("en-US", {timeZone: "America/Chicago"})
            console.log("CST date : "+CSTDate);  
            let formattedTodayDate= Moment(CSTDate).format('YYYY-MM-DD')
            let withdrawalDate = this.state.scheduleChoice == "payNow" ? formattedTodayDate : Moment(this.state.paymentDate, "MM-DD-YYYY").format('YYYY-MM-DD')
            this.setState({
                withdrawalDate : withdrawalDate,
                currentDate : formattedTodayDate
              }) 
              let todayTime= Moment(CSTDate).format('HH:mm')
                if(withdrawalDate === formattedTodayDate ){
                  if( todayTime >= "21:55"){  
                    let currentDateNextDay = Moment(CSTDate).add(1, 'days') 
                        this.setState({withdrawalDate :  Moment(currentDateNextDay).format('YYYY-MM-DD')
                      })   
                    }
                } 
                this.setState({
                nextMonthStartDt : Moment(date).add(1, 'months').startOf('month').format('YYYY-MM-DD') 
              })     
        }
        const commonRequest={
          payAmount: this.state.payAmount ? parseFloat(this.state.payAmount.replace('$','')).toFixed(2) : '00.00',
          currentDate :this.state.currentDate,
          walletItemId :this.state.itemId,
          withdrawalDate: this.state.withdrawalDate,
          memberFirstname: this.state.firstName,
          memberId:this.state.memberId,
          memberLastname:this.state.lastName,
          memberMiddlename : '',
          paymentDate : this.state.withdrawalDate,
          planCategory : planInformation.planCategory,
          sourcePortal : planInformation.brand,
          userName : this.state.userName,
          merchantAccount : secureLocalStorage.getItem('upgAccountId'),
          enc_householdId : planInformation.accountId
        }
        let autopayTimelines = { 
        startDate: Moment(this.state.paymentDate).format('YYYY-MM-DD'),
        stopDate: null }
        let mfaCallBackURL = window.location.origin + '/medicare/UCPPayments/hrt/hsid/mfa/callback/v1?source=fedPayReview';
        if(planInformation.productType === 'GOVT' && this.state.ccFlow && getOneTimePaymentFlag() && this.state.savePayMethodChecked === true ) {
          if((this.state.isMFAAuthenticated) || this.state.isMFA === false || (this.state.isInboundSsoUser === true && this.state.isMFA === true)) {
            this.addToWalletAndMakeOneTimePayment(commonRequest);
          }else if (this.state.isInboundSsoUser === false && this.state.isMFA && this.state.mfaValidation === false && (this.checkPostMfa() === false || this.state.isMFAInvalid)) {   
              this.setState({ mfaValidation: true, spinnerLoading: false, mfaCallBackURL: mfaCallBackURL})       
          }  
        } else if(planInformation.productType === 'GOVT' && this.state.ccFlow && getOneTimePaymentFlag() && this.state.savePayMethodChecked === false) {
          this.ccCreatePaymentsubmission(commonRequest);
        } else if(planInformation.productType === 'GOVT' && this.state.paymentMethod === 'EFT' && getOneTimePaymentFlag() && this.state.savePayMethodChecked === true) { 
          if((this.state.isMFAAuthenticated) || this.state.isMFA === false || (this.state.isInboundSsoUser === true && this.state.isMFA === true)){
            this.addToWalletAndMakeOneTimePayment(commonRequest);
          }else if (this.state.isInboundSsoUser === false && this.state.isMFA && this.state.mfaValidation === false && (this.checkPostMfa() === false || this.state.isMFAInvalid)) {
            this.setState({ mfaValidation: true, spinnerLoading: false ,mfaCallBackURL: mfaCallBackURL})     
          } 
        } else if(planInformation.productType === 'GOVT' && this.state.paymentMethod === 'EFT' && getOneTimePaymentFlag() && this.state.savePayMethodChecked === false) { 
          this.eftCreatePaymentsubmission(commonRequest);
        }  else if(planInformation.productType === 'GOVT' && this.state.paymentMethod === 'CC' && getOneTimePaymentFlag()) {
          this.ccCreatePaymentsubmission(commonRequest);
        } else if(planInformation.productType === 'GOVT' && (this.state.paymentMethod === 'EFT' || this.state.paymentMethod === 'CC') && getRecurringPaymentFlag() && this.state.cancelRecChecked === false) { 
          let paymentData = JSON.parse(secureLocalStorage.getItem('paymentFormData'));
          console.log(paymentData);
          console.log(this.props.location?.state?.totalAmountDue);
          // paymentData.totalAmountDue = this.props.location?.state?.totalAmountDue;
          secureLocalStorage.setItem("paymentFormData", JSON.stringify(paymentData));
          if((this.state.isMFAAuthenticated) || this.state.paymentBy === 'wallet' || this.state.isMFA === false || (this.state.isInboundSsoUser === true && this.state.isMFA === true)){
            this.updateRecurringSubmission();
          }else if (this.state.isInboundSsoUser === false && this.state.isMFA && this.state.mfaValidation === false && (this.checkPostMfa() === false || this.state.isMFAInvalid)) {
            console.log('setting mfa validation to true')         
            this.setState({ mfaValidation: true, spinnerLoading: false,mfaCallBackURL: mfaCallBackURL})     
          }  
        } else if (planInformation.productType === 'GOVT' && this.state.cancelRecChecked === true) {
            this.setupOrUpdateOrCancelRecurringSubmission(autopayTimelines); 
        }
      })
           
   }
  }

  getBankAccountType = ()=> {
    if(this.state.paymentBy) {
      if(this.state.paymentBy.indexOf('wallet') > -1) {
        return this.state.bankAccountType
      }
      if(this.state.paymentBy.indexOf('addEFT') > -1) {
        return this.state.eftBankType
      }
      return ''
    }
  }
  getConfirmationNumber = async (paymentId)=>{
    const resData =  await retrievePaymentDetailsAPI(paymentId);
    secureLocalStorage.setItem("confirmationNumber", 'Not available' );
    if (resData.success) {
      secureLocalStorage.setItem("confirmationNumber", resData.gpResponsePayment.data.confirmationNumber? resData.gpResponsePayment.data.confirmationNumber : 'Not available' );
    } 
  }

  eftCreatePaymentsubmission = (commonRequest) => {
    if ((this.state.accountNumber === '' || this.state.accountNumber === null || isNaN(this.state.accountNumber)) || (this.state.routingNumber === '' || this.state.routingNumber === null) || isNaN(this.state.routingNumber)) {
      this.setState({ serviceError: true, callTriggered: false });
    } else {
     this.state.isMFA && this.setState({ spinnerLoading: true })
      const paymentRequest={
        accountNumber : this.state.accountNumber,
        authorizationInd : this.state.authorizationInd,
        bankAccountType : this.getBankAccountType(),
        accountFirstname:this.state.bankHolderFirstName,
        accountLastname:this.state.bankHolderLastName,
        accountMiddlename:this.state.bankHolderMiddleName,
        routingNumber:this.state.routingNumber
      }
      eftCreatePaymentsubmissionResults(commonRequest,paymentRequest)
        .then((resp) => {
          this.setState({ formError: false });
          if (resp.data.success) {
            this.setState({
              formSuccess: true,
              formErrorDisplay: commonConstants.PAYMENT_SUBMITTED,
              formValidated: true,
              submitFilureFlag: false
            })
            if(resp.data.createPaymentResponse.gpResponse.data.paymentId!== null){       
            this.getConfirmationNumber(resp.data.createPaymentResponse.gpResponse.data.paymentId);
            }
          }
        }).catch(error => {
          if (error.response?.data.error.errorMsg && error.response?.data.error.errorMsg.length > 0) {
            this.setState({ formError: true,callTriggered : false, spinnerLoading: false, submitAutomaticFailure: true,formErrorDisplay:`<b>${this.state.error_system_error_title}</b> ${this.state.error_sytem_error}`});
            window.scrollTo(0, 0);
          }else if(error?.response?.data.error){
            this.setState({ formError: true,callTriggered : false, spinnerLoading: false, submitAutomaticFailure: true,formErrorDisplay:`<b>${this.state.error_system_error_title}</b> ${this.state.error_sytem_error}`});
            window.scrollTo(0, 0);
          }else {
            this.setState({ formError: true,callTriggered : false, spinnerLoading: false, submitAutomaticFailure: true,formErrorDisplay:`<b>${this.state.error_system_error_title}</b> ${this.state.error_sytem_error}`});
            window.scrollTo(0, 0);
          }
        });
    }
  }
  ccCreatePaymentsubmission = async (commonRequest) =>{
    this.state.isMFA && this.setState({ spinnerLoading: true })
    const paymentRequest={
      billingCode: this.state.billingCode,
      numberLastFour: this.state.cardNumber,
      cardType: this.state.cardType,
      creditCardToken: this.state.token,
      expiration: this.state.expirationDate,
      nameOnCard: this.state.nameOnCard
    } 
    return creditCardCreatePaymentSubmissionResults(commonRequest,paymentRequest)
    .then((resp) => {
      if (resp.data.success) {
        this.setState({
          formSuccess: true,
          formErrorDisplay: commonConstants.PAYMENT_SUBMITTED,
          formValidated: true
        })
        if(resp.data.createPaymentResponse.gpResponse.data.paymentId!== null){
          this.getConfirmationNumber(resp.data.createPaymentResponse.gpResponse.data.paymentId);
        }
      } else {
        trackError(getOneTimePaymentFlag() ? 'make a one time payment - review and submit' : isManageRecurring() ? 'manage recurring  payment - review and submit' :
            'set up recurring payment - review and submit', 'Error on Credit Payment Submission','form field validation','invalid');
        this.setState({ formValidated: true , spinnerLoading : false});
      }
      return resp;
    }).catch(error => {
      if (error?.response?.data?.error?.errorMsg && error.response.data.error.errorMsg.indexOf('Invalid characters provided for NAMEONCARD')>-1) {
          this.setState({ formError: true,callTriggered : false, spinnerLoading: false, submitAutomaticFailure: true,formErrorDisplay:this.state.error_cc_invalid_nameoncard});
          window.scrollTo(0, 0);
          trackError(getOneTimePaymentFlag() ? 'make a one time payment - review and submit' : isManageRecurring() ? 'manage recurring  payment - review and submit' :
              'set up recurring payment - review and submit', 'Submit','Credit card input error','Invalid characters provided for NAMEONCARD');
      }else if (error?.response?.data.error.errorMsg && error.response.data.error.errorMsg.indexOf('NAMEONCARD cannot have a length greater than 60')>-1) {
          this.setState({ formError: true,callTriggered : false, spinnerLoading: false, submitAutomaticFailure: true,formErrorDisplay:this.state.error_cc_nameoncard});
          window.scrollTo(0, 0);
          trackError(getOneTimePaymentFlag() ? 'make a one time payment - review and submit' : isManageRecurring() ? 'manage recurring  payment - review and submit' :
              'set up recurring payment - review and submit', 'Submit','Credit card input error','NAMEONCARD cannot have a length greater than 60 characters');
      }
      else if (error?.response?.data?.error?.errorMsg && error.response.data.error.errorMsg.indexOf('The payment WITHDRAWALDATE cannot be after the credit card EXPIRATIONDATE')>-1) {
          this.setState({ formError: true,callTriggered : false, spinnerLoading: false, submitAutomaticFailure: true,formErrorDisplay:`<b>${this.state.error_cc_card_expired_title}</b> ${this.state.error_cc_card_expired}`});
          window.scrollTo(0, 0);
          trackError(getOneTimePaymentFlag() ? 'make a one time payment - review and submit' : isManageRecurring() ? 'manage recurring  payment - review and submit' :
              'set up recurring payment - review and submit', 'Submit','Credit card input error','The payment WITHDRAWALDATE cannot be after the credit card EXPIRATIONDATE.');
      }else if (error?.response?.data?.error?.errorMsg && error.response.data.error.errorMsg.length > 0) {
         let errorSystemError = `<b>${this.state.error_system_error_title}</b> ${this.state.error_sytem_error}`;
          if(this.state.paymentMethod === 'CC' && this.state.savePayMethodChecked && this.state.isAddCreditCardSuccess){
            errorSystemError = this.state.error_cc_system_error + "<a href=" +window.location.origin+window.location.pathname+ "#/federal-make-payment className='tds-link'><span> Make a payment</span></a>"
           }
          this.setState({ formError: true,callTriggered : false, spinnerLoading: false, submitAutomaticFailure: true,formErrorDisplay:errorSystemError});
          window.scrollTo(0, 0);
          trackError(getOneTimePaymentFlag() ? 'make a one time payment - review and submit' : isManageRecurring() ? 'manage recurring  payment - review and submit' :
              'set up recurring payment - review and submit', 'Submit','Webservice Failure error','Webservice Failure');
      }else if(error?.response?.data?.error){
        //todo create api failure
        let errorSystemError = `<b>${this.state.error_system_error_title}</b> ${this.state.error_sytem_error}`;
          if(this.state.paymentMethod === 'CC' && this.state.savePayMethodChecked && this.state.isAddCreditCardSuccess){
            errorSystemError = this.state.error_cc_system_error + "<a href=" +window.location.origin+window.location.pathname+ "#/federal-make-payment className='tds-link'><span> Make a payment</span></a>"
          }
        this.setState({ formError: true,callTriggered : false, spinnerLoading: false, submitAutomaticFailure: true,formErrorDisplay:errorSystemError});
        window.scrollTo(0, 0);
        trackError(getOneTimePaymentFlag() ? 'make a one time payment - review and submit' : isManageRecurring() ? 'manage recurring  payment - review and submit' :
            'set up recurring payment - review and submit', 'Submit','Webservice Failure error','Webservice Failure');
       }else{
          let errorSystemError = `<b>${this.state.error_system_error_title}</b> ${this.state.error_sytem_error}`;
          if(this.state.paymentMethod === 'CC' && this.state.savePayMethodChecked && this.state.isAddCreditCardSuccess){
            errorSystemError = this.state.error_cc_system_error + "<a href=" +window.location.origin+window.location.pathname+ "#/federal-make-payment className='tds-link'><span> Make a payment</span></a>"
          }
          this.setState({ formError: true,callTriggered : false, spinnerLoading: false, submitAutomaticFailure: true,formErrorDisplay:errorSystemError});
          window.scrollTo(0, 0);
          trackError(getOneTimePaymentFlag() ? 'make a one time payment - review and submit' : isManageRecurring() ? 'manage recurring  payment - review and submit' :
              'set up recurring payment - review and submit', 'Submit','Webservice Failure error','Webservice Failure');
          }
    return error?.response;
    });

  }

  clearFutureAutoPayTimelines =() =>{
    let today = Moment().format('YYYY-MM-DD')
    let endDateMnth = Moment(today).endOf('month').format('YYYY-MM-DD')
    filteredWalletData = cloneDeep(updateWalletData)
    filteredWalletData.items.map(elt=> {
    // remove all future time line.
    let timelines = elt.autopayTimelines.filter(el=> Moment(el.startDate) < Moment(endDateMnth))
    // put end date on curr item if present.
    if(getWalletResponse.currentItem && getWalletResponse.currentItem.itemId === elt.itemId) {
    timelines.map(tl=> {
    if((Moment(tl.startDate) < Moment(endDateMnth))  && (tl.stopDate == null || (tl.stopDate !== null && Moment(tl.stopDate) > Moment(endDateMnth)))) {
    tl.stopDate = endDateMnth
    }
    return tl
    })
    }
    elt.autopayTimelines = timelines
    return elt
    })
    console.log(filteredWalletData)
  }

  cancelAllFutureRecurring = (walletData)=> {
    let today = Moment().format('YYYY-MM-DD')
    let endDateMnth = Moment(today).endOf('month').format('YYYY-MM-DD')
    walletData.items.map(elt=> {
    // remove all future time line.
    let timelines = elt.autopayTimelines.filter(el=> Moment(el.startDate) < Moment(endDateMnth))
    elt.autopayTimelines = timelines
    return elt
    })

    return walletData
  }
  isInactiveEFTAdd = (walletData)=> {
    if(this.state.paymentMethod === 'EFT') {
      let existItem= walletData.items.filter(el=> el.creditCard == null).filter(item => (item.bankAccount.accountNumber === this.state.accountNumber && 
        item.bankAccount.routingNumber === this.state.routingNumber && item.active.toLowerCase() === 'false'));
      if(existItem.length>0){
        existItem[0].active = "TRUE"
        return true
      }
    }
    return false
}

  getWalletDetailsUpdateRec = async ()=> {
    const response = await getWalletServiceAPI(getPlanInformation().accountId);
    getWalletResponse = response.data;
    if(getWalletResponse.statusCode === 200) {
      updateWalletData = getWalletResponse.gpGetWalletResponse.data;
      let isInacToAct = this.isInactiveEFTAdd(updateWalletData)
      if(isInacToAct) {
        const res = await addUpdateWalletServiceAPI(updateWalletData)
          if(res && res.success) {
            getWalletResponse.gpGetWalletResponse.data = updateWalletData
          } else {
            this.setState({ formError: true,callTriggered : false, submitAutomaticFailure: true,formErrorDisplay:`<b>${this.state.error_system_error_title}</b> ${this.state.error_sytem_error}`});
            getWalletResponse.statusCode = res.statusCode
            window.scrollTo(0, 0);
          }
      }
      items = getWalletResponse.gpGetWalletResponse.data.items; 
      getWalletResponse.gpGetWalletResponse.data.householdId="";
    }
    return getWalletResponse;
  }

  getWalletDetails = async ()=> {
    const response = await getWalletServiceAPI(getPlanInformation().accountId);
    getWalletResponse = response?.data;
    console.log("Get-Wallet response : status " + getWalletResponse?.statusCode)
    if(getWalletResponse?.statusCode === 200) {
      items = getWalletResponse.gpGetWalletResponse.data.items; 
      getWalletResponse.gpGetWalletResponse.data.householdId="";
      updateWalletData=getWalletResponse.gpGetWalletResponse.data;
    }
  return getWalletResponse;
}
deleteUpgToken = async (upgRequest)=> {
  const response = await deleteTokenAPI(upgRequest);
return response;
}

updateWallet = (updatedWalletData) => {
  addUpdateWalletServiceAPI(updatedWalletData).then(res => {
    secureLocalStorage.setItem("confirmationNumber","Not available");
    console.log(res)
  if(res.success) {
    secureLocalStorage.setItem("confirmationNumber",'Not applicable for this payment method');
    this.setState({
      formValidated: true
    })
      console.log('inside')
  } else {
    let errors = res.errors.map(elt=> elt.detail)
    console.log(errors)
    if(res.statusCode >= 400) {
    }
    this.setState({ formError: true,callTriggered : false, spinnerLoading: false, submitAutomaticFailure: true,formErrorDisplay:`<b>${this.state.error_system_error_title}</b> ${this.state.error_sytem_error}`});
    window.scrollTo(0, 0);
  }
  }).catch(error=> {
    console.log(error)
    this.setState({ formError: true,callTriggered : false, spinnerLoading: false, submitAutomaticFailure: true,formErrorDisplay:`<b>${this.state.error_system_error_title}</b> ${this.state.error_sytem_error}`});
    window.scrollTo(0, 0); 
  })
}

isSameItemRecurring = ()=> {
  if(this.state.paymentMethod === 'EFT' && getWalletResponse.currentItem.bankAccount) {
    return getWalletResponse.currentItem.bankAccount.accountNumber === this.state.accountNumber && getWalletResponse.currentItem.bankAccount.routingNumber === this.state.routingNumber
  }

  if(this.state.paymentMethod === 'CC' && getWalletResponse.currentItem.creditCard) {
    return getWalletResponse.currentItem.creditCard.cardNumberLastFour === this.state.cardNumber && getWalletResponse.currentItem.creditCard.cardType === this.state.cardType
      && getWalletResponse.currentItem.creditCard.expiration === this.state.expirationDate
  }
  return false

}

removeItemFromWalletIfExist = (walletData)=> {
  if(this.state.paymentMethod === 'EFT') {
    let existItem= walletData.items.filter(el=> el.creditCard == null).filter(item => (item.bankAccount.accountNumber === this.state.accountNumber && 
      item.bankAccount.routingNumber === this.state.routingNumber));
    if(existItem.length>0){
      existItem[0].active = "TRUE"
      return {filterItems : walletData.items.filter(el=> el.itemId !== existItem[0].itemId), existItem : existItem[0]}
    } 
  }

  if(this.state.paymentMethod === 'CC'){
    let ccExistItem = walletData.items.filter(item => (item.active.toLowerCase() === 'true' && item.creditCard && item.creditCard.cardType === this.state.cardType && item.creditCard.cardNumberLastFour === this.state.cardNumber && item.creditCard.expiration === this.state.expirationDate))
      if(ccExistItem.length>0){
        return {filterItems : walletData.items.filter(el=> el.itemId !== ccExistItem[0].itemId), existItem : ccExistItem[0]}
      } 
  }
  return null
}

createCCRequest = (timeline)=> {
    var creditCardRequest = {
      active: "TRUE",
      authorizationMedium: "WEB",
      autopayTimelines: [timeline],
      bankAccount: null,
      creditCard: {
      billingCode: this.state.billingCode,
      cardNumberLastFour: this.state.cardNumber,
      cardType: this.state.cardType,
      creditCardId: "",
      creditCardToken: this.state.token,
      declineCount: "",
      declineCountLastUpdated: "",
      encryptedTrack: "",
      expiration: this.state.expirationDate,
      nameOnCard: this.state.nameOnCard,
      unstored: "FALSE"
    },
      inputSourceSystem: "PORTAL",
      itemId: "",
      nickname: this.state.nickName
  };

  return creditCardRequest
}

createBankRequest = (timeline)=> {
  var bankAccountRequest = {
    active: "TRUE",
    authorizationMedium: "WEB",
    autopayTimelines: [timeline],
    bankAccount: {
      accountNumber: this.state.accountNumber,
      authImageNumber: null,
      authorizationInd: this.props.authIndicator,
      bankAccountId: "",
      bankAccountType: this.getBankAccountType().toUpperCase(),
      bankName: "",
      firstName: this.state.bankHolderFirstName,
      lastName: this.state.bankHolderLastName,
      middleName: this.state.bankHolderMiddleName,
      routingNumber: this.state.routingNumber
    },
    creditCard: null,
    inputSourceSystem: "PORTAL",
    itemId: "",
    nickname: this.state.nickName
  };

  return bankAccountRequest
}

callWalletUpdateServiceAPI = (updateData)=> {
    addUpdateWalletServiceAPI(updateData).then(response=> {
      secureLocalStorage.setItem("confirmationNumber","Not available");
      if(response && response.success) {
        secureLocalStorage.setItem("confirmationNumber","Not applicable for this payment method");
        this.setState({
          formValidated: true
        })
      } else {
        this.setState({ formError: true,callTriggered : false, spinnerLoading: false, submitAutomaticFailure: true,formErrorDisplay:`<b>${this.state.error_system_error_title}</b> ${this.state.error_sytem_error}`});
         window.scrollTo(0, 0);
      }

    }).catch((error) => {
      this.setState({ formError: true,callTriggered : false, spinnerLoading: false, submitAutomaticFailure: true,formErrorDisplay:`<b>${this.state.error_system_error_title}</b> ${this.state.error_sytem_error}`});
         window.scrollTo(0, 0);
  })
}

callWalletUpdateService = (walletData)=> {
  // Item exist in wallet
  let obj = this.removeItemFromWalletIfExist(walletData)
  if(obj) {
    let item = obj.existItem
    let filterItems = obj.filterItems
    item.autopayTimelines.push({startDate : Moment(this.state.paymentDate, "MM-DD-YYYY").format('YYYY-MM-DD'),  stopDate: null})
    if(this.state.paymentBy.indexOf('wallet') === -1) {
      item.nickname = this.state.nickName
    }
    // add again if item was present
    filterItems.push(item)
    walletData.items = filterItems
    this.callWalletUpdateServiceAPI(walletData)

  // Item not exist in wallet  
  } else {
    if(this.state.paymentMethod === 'CC') {
      let ccItem = this.createCCRequest({startDate : Moment(this.state.paymentDate, "MM-DD-YYYY").format('YYYY-MM-DD'),  stopDate: null})
      walletData.items.push(ccItem)
      this.callWalletUpdateServiceAPI(walletData)
    } else if(this.state.paymentMethod === 'EFT') {
      let bankItem = this.createBankRequest({startDate : Moment(this.state.paymentDate, "MM-DD-YYYY").format('YYYY-MM-DD'),  stopDate: null})
      walletData.items.push(bankItem)
      this.callWalletUpdateServiceAPI(walletData)
    }

  }
}

setNewStopDateInCurrentItem = (walletData, currItemId)=> {
  walletData.items.map(el=> {
    if(el.itemId === currItemId) {
      el.autopayTimelines.map(t=> {
        if(!t.stopDate || Moment(t.stopDate) >= Moment(Moment().format('YYYY-MM-DD'))) {
          if(t.stopDate && Moment(t.stopDate).format('YYYY-MM-DD') !== Moment().endOf('month').format('YYYY-MM-DD')) {
            t.stopDate = Moment(this.state.paymentDate, "MM-DD-YYYY").add(-1,'days').format('YYYY-MM-DD')
          }
          if(!t.stopDate) {
            t.stopDate = Moment(this.state.paymentDate, "MM-DD-YYYY").add(-1,'days').format('YYYY-MM-DD')
          }
          return t
        }
        return el
      })
    }
  })
  return walletData
}

setNewStopDateInSameCurrentItem = (walletData, currItemId)=> {

  let cancelled = false

  walletData.items.map(el=> {
    if(el.itemId === currItemId) {
      el.autopayTimelines.map(t=> {
        if(!t.stopDate || Moment(t.stopDate) >= Moment(Moment().format('YYYY-MM-DD'))) {
          if(t.stopDate && Moment(t.stopDate).format('YYYY-MM-DD') === Moment().endOf('month').format('YYYY-MM-DD')) {
            cancelled = true
          } else {
            t.stopDate = null
          }
          return t
        }
        return el
      })
    }
  })

  if(cancelled) {
    walletData.items.map(el=> {
      if(el.itemId === currItemId) {
        el.autopayTimelines.push({startDate : Moment(this.state.paymentDate, "MM-DD-YYYY").format('YYYY-MM-DD'),  stopDate: null})
      }
      return el
    })
  }
  return walletData
}

changeStopDateOnSameRecurringItem = (walletData, currItemId)=> {
    walletData.items.map(elt=> {
    // find current timeline
    if(elt.itemId === currItemId) {
      elt.nickname = this.state.nickName
      elt.autopayTimelines.map(t=> {
        if(t.stopDate && Moment(t.stopDate) >= Moment(Moment().format('YYYY-MM-DD'))) {
          t.stopDate = null
        }
        return t
      })
    }
    return elt
    })
    return walletData
}

updateRecurringSubmission = ()=> {
  let walletData = null
  this.state.isMFA && this.setState({ spinnerLoading: true })
  try {
    this.getWalletDetails().then((response)=> {
      if(response?.statusCode === 200) {
        walletData = cloneDeep(updateWalletData)
        // Direct pay -- Recurring scheduled in future
        if(!getWalletResponse.currentItem && getWalletResponse.futureItem) {
          let afterFutureRemove = this.cancelAllFutureRecurring(walletData)
          this.callWalletUpdateService(afterFutureRemove)
          // Direct pay
        } else if(!getWalletResponse.currentItem && !getWalletResponse.futureItem) {
          this.callWalletUpdateService(walletData)
          // Recurring ON
        } else if(getWalletResponse.currentItem) {
          //Recurring ON -- Recurring scheduled in future
          if(getWalletResponse.futureItem) {
            let afterFutureRemoveWallet = this.cancelAllFutureRecurring(walletData)
            let afterSetNewDateOnCurrentItem = this.setNewStopDateInCurrentItem(afterFutureRemoveWallet,getWalletResponse.currentItem.itemId)
            this.callWalletUpdateService(afterSetNewDateOnCurrentItem)
            //Recurring ON -- No Recurring scheduled in future
          } else {
            // Different Item recurring
            if(!this.isSameItemRecurring()) {
              this.callWalletUpdateService(this.setNewStopDateInCurrentItem(walletData,getWalletResponse.currentItem.itemId))
              // Same item recurring again
            } else {
              // recurring start date is next month with same item
              if(Moment(this.state.paymentDate, "MM-DD-YYYY").format('YYYY-MM-DD') === Moment().endOf('month').add(1, 'days').format('YYYY-MM-DD')) {
                this.callWalletUpdateServiceAPI(this.changeStopDateOnSameRecurringItem(walletData,getWalletResponse.currentItem.itemId))
              } else {
                this.callWalletUpdateServiceAPI(this.setNewStopDateInSameCurrentItem(walletData,getWalletResponse.currentItem.itemId))
              }
            }
          }
        }
      } else{
        this.setState({ formError: true,callTriggered : false, spinnerLoading: false, submitAutomaticFailure: true,formErrorDisplay:`<b>${this.state.error_system_error_title}</b> ${this.state.error_sytem_error}`});
         window.scrollTo(0, 0);
      }
    })
  } catch (error) {
   console.log("error occurred")
   this.setState({ formError: true,callTriggered : false, spinnerLoading: false, submitAutomaticFailure: true,formErrorDisplay:`<b>${this.state.error_system_error_title}</b> ${this.state.error_sytem_error}`});
   window.scrollTo(0, 0);
  }
}

setupOrUpdateOrCancelRecurringSubmission =(autoPayTimelines) =>{
  let errors = [];
  let count = 0;
  try {
    this.getWalletDetails().then((response)=> {
      if(response?.statusCode === 200) {
        this.clearFutureAutoPayTimelines();
        if (isManageRecurring()) {
          sessionStorage.setItem('updateRecurring', true)
        }
      if(this.state.paymentMethod === 'CC'){
       if(this.state.paymentBy.indexOf('wallet') === -1){
        this.addCreditCard(autoPayTimelines,filteredWalletData.items,filteredWalletData).then((res) =>{
          console.log(res)
          secureLocalStorage.setItem("confirmationNumber","Not available");
          if(res && res.success) {
            secureLocalStorage.setItem("confirmationNumber","Not applicable for this payment method");
            this.setState({
              formValidated: true
            })
              console.log('cc added success')
          }  else {
              res.errors && res.errors.forEach(elt => 
                {
                  count++;
                  console.log(elt);
                  if(elt.code === 'GPW-WI-1012'){
                    errors.push({error: true, label: this.state.error_cc_nickname_length, name: 'charLength'})
                  }
                  if(elt.code === 'GPW-WI-1013'){
                    errors.push({error: true, label: this.state.error_cc_invalid_nameoncard, name: 'charLength'})
                  }
                  if (elt.code === 'GPW-CC-1018') {
                    errors.push({error: true, label: this.state.error_cc_nameoncard, name: 'charLength'})
                  }
                  if (elt.code === 'GPW-WI-1009') {
                    errors.push({error: true, label: this.state.cc_invalid_nick_name, name: 'invalidNickName'})
                  }
                  if (elt.code === 'GPW-BA-1000') {
                    errors.push({error: true, label: this.state.error_bank_all_fields_required, name: 'invalidNickName'})
                  }
                  if(elt.detail.indexOf('Duplicate credit card') > -1){
                    errors.push({error: true, label: this.state.error_cc_duplicate, name: 'duplicateCard'})
                  }
                })
              console.log(errors)
              if(errors.length === 0){
                errors.push({error: true, label: `<b>${this.state.error_system_error_title}</b> ${this.state.error_sytem_error}`, name: 'generalSystemError'})
              } 
              let errorObj = {type: 'api', fields: errors, count: count }
              this.setState({ apiErrors: true,callTriggered : false, spinnerLoading: false , submitAutomaticFailure: true,formErrorDisplay:errors, errorFields: errorObj});
              window.scrollTo(0, 0);
          }
        })
       } else{
        let existItem = filteredWalletData.items.filter(item => (item.active.toLowerCase() === 'true' && item.creditCard && item.creditCard.cardType === this.state.cardType && item.creditCard.cardNumberLastFour === this.state.cardNumber
        && item.creditCard.expiration === this.state.expirationDate));

          filteredWalletData.items.map(elt=> {           
            if(existItem[0].itemId === elt.itemId) {
             elt.autopayTimelines.push(autoPayTimelines)
            }
            return elt
            })

          this.updateWallet(filteredWalletData);
       }
       } else if(this.state.paymentMethod === 'EFT'){
        if(this.state.paymentBy.indexOf('wallet') === -1){
        let existItems= filteredWalletData.items.filter(el=> el.creditCard == null).filter(item => (item.bankAccount.accountNumber === this.state.accountNumber && 
            item.bankAccount.routingNumber === this.state.routingNumber));
          if(existItems.length>0 && existItems[0].active.toLowerCase() === "true"){
            this.setState({ formError: true,callTriggered : false, spinnerLoading: false, submitAutomaticFailure: true,formErrorDisplay: this.state.error_bank_duplicate_acc});
            window.scrollTo(0, 0);
          }else {
              filteredWalletData.items.map(elt=> {           
                if(existItems.length>0 && existItems[0].itemId === elt.itemId && existItems[0].active.toLowerCase() === "false") {
                 elt.autopayTimelines.push(autoPayTimelines)
                  this.setState({ isDuplicateItem : true })
                }
                return elt
                })
                
            this.addBankAccount(autoPayTimelines,filteredWalletData.items,filteredWalletData).then((res)=>{
              secureLocalStorage.setItem("confirmationNumber","Not available");
              if(res.success) {
                secureLocalStorage.setItem("confirmationNumber","Not applicable for this payment method");
                this.setState({
                  formValidated: true
                })
              } else {
                let genericError = res.errors ? false : this.state.error_sytem_error
                genericError && this.setState({ formError: true,callTriggered : false, spinnerLoading: false , submitAutomaticFailure: true,formErrorDisplay: genericError});
                let errors = !genericError && res.errors && res.errors.map(elt => 
                  {if(elt.detail.indexOf('The following fields are required for a bank account') > -1){
                    this.setState({ formError: true,callTriggered : false, spinnerLoading: false, submitAutomaticFailure: true,formErrorDisplay: this.state.error_bank_all_fields_required });
                  } else if(elt.detail.indexOf('Duplicate bank account.') > -1){
                    this.setState({ formError: true,callTriggered : false, spinnerLoading: false, submitAutomaticFailure: true,formErrorDisplay: this.state.error_bank_duplicate_acc});
                  } else{
                  this.setState({ formError: true,callTriggered : false, spinnerLoading: false, submitAutomaticFailure: true,formErrorDisplay: `<b>${this.state.error_system_error_title}</b> ${this.state.error_sytem_error}`});
                  }
                })
                console.log(errors)
                window.scrollTo(0, 0);
              }

            })
         }
        } else{
          let existItem= filteredWalletData.items.filter(el=> el.creditCard == null).filter(item => (item.bankAccount.accountNumber === this.state.accountNumber && 
            item.bankAccount.routingNumber === this.state.routingNumber));

            filteredWalletData.items.map(elt=> {           
              if(existItem[0].itemId === elt.itemId) {
               elt.autopayTimelines.push(autoPayTimelines)
              }
              return elt
              })

              this.updateWallet(filteredWalletData);
        }
       } else if(this.state.cancelRecChecked === true) {
              this.updateWallet(filteredWalletData);
       }
      } else{
        this.setState({ formError: true,callTriggered : false, spinnerLoading: false, submitAutomaticFailure: true,formErrorDisplay:`<b>${this.state.error_system_error_title}</b> ${this.state.error_sytem_error}`});
         window.scrollTo(0, 0);
      }
    })
  } catch (error) {
   console.log("error occurred")
   this.setState({ formError: true,callTriggered : false, spinnerLoading: false, submitAutomaticFailure: true,formErrorDisplay:`<b>${this.state.error_system_error_title}</b> ${this.state.error_sytem_error}`});
   window.scrollTo(0, 0);
  }
}
addToWalletAndMakeOneTimePayment = (commonRequest) =>{
  this.state.isMFA && this.setState({ spinnerLoading : true})
  try {
    this.getWalletDetails().then((response)=> {
      if(response.statusCode === 200) {
       let autoPayTimelines =[];
      if(this.state.paymentMethod === 'CC'){
        this.addCreditCard(autoPayTimelines,items,updateWalletData).then((res) =>{
          if(res.success) {
            this.setState({isAddCreditCardSuccess:true})
            sessionStorage.setItem('notificationCount', 1)
            const upgRequest={
              token : this.state.token,
              transactionId : this.state.transactionId,
              upgAccountId : this.state.upgAccountId ? this.state.upgAccountId : secureLocalStorage.getItem('upgAccountId')
            }
            this.getWalletDetails().then((resp)=> {
              if(resp.statusCode === 200) {
              let existItem = items.filter(item => (item.active.toLowerCase() === 'true' && item.creditCard && item.creditCard.cardType === this.state.cardType && item.creditCard.cardNumberLastFour === this.state.cardNumber
              && item.creditCard.expiration === this.state.expirationDate));
              if(existItem.length>0){
                commonRequest.walletItemId = existItem[0].itemId ;
                this.setState({isPayMethodSaved : true});
              }
              this.ccCreatePaymentsubmission(commonRequest).then((res)=>{
                if(res && res.status !== 201){
                  this.deleteUpgToken(upgRequest).then((res)=>{
                    if(res.success) 
                      console.log("upg token deleted successfully from database ");
                    else
                      console.log("upg token unable to delete from database ");
                  })
                }
              });
            } else {
              this.deleteUpgToken(upgRequest).then((res)=>{
                if(res.success) 
                  console.log("upg token deleted successfully from database ");
                else
                  console.log("upg token unable to delete from database ");
              })
              let errorSystemError = `<b>${this.state.error_system_error_title}</b> ${this.state.error_sytem_error}`
              if(this.state.savePayMethodChecked){
               errorSystemError = this.state.error_cc_system_error + "<a href=" +window.location.origin+window.location.pathname+ "#/federal-make-payment className='tds-link'><span> Make a payment</span></a>"
              } 
              this.setState({ formError: true,callTriggered : false, spinnerLoading: false, submitAutomaticFailure: true,formErrorDisplay: errorSystemError});
              window.scrollTo(0, 0);
            }
            })
          } else{
            this.ccCreatePaymentsubmission(commonRequest);
          }
        })
       } else if(this.state.paymentMethod === 'EFT'){
        let existItem= items.filter(el=> el.creditCard == null).filter(item => (item.bankAccount.accountNumber === this.state.accountNumber && 
          item.bankAccount.routingNumber === this.state.routingNumber));
        if(existItem.length>0 && existItem[0].active.toLowerCase() === "true"){
          commonRequest.walletItemId = existItem[0].itemId ;
          this.setState({isPayMethodSaved : true});
          this.eftCreatePaymentsubmission(commonRequest);
        }else{
          this.addBankAccount(autoPayTimelines,items,updateWalletData).then((res) =>{
            if(res.success) {
              this.getWalletDetails().then((resp)=> {
                if(resp.statusCode === 200) {
                  sessionStorage.setItem('notificationCount', 1)
                  let existItems= items.filter(el=> el.creditCard == null).filter(item => (item.bankAccount.accountNumber === this.state.accountNumber && 
                    item.bankAccount.routingNumber === this.state.routingNumber));
                if(existItems.length>0){
                  commonRequest.walletItemId = existItems[0].itemId ;
                  this.setState({isPayMethodSaved : true});
                }
                this.eftCreatePaymentsubmission(commonRequest);
              } else{
              this.setState({ formError: true,callTriggered : false, spinnerLoading: false, submitAutomaticFailure: true,formErrorDisplay:`<b>${this.state.error_system_error_title}</b> ${this.state.error_sytem_error}`});
              window.scrollTo(0, 0);
              }
              })
            } else{
              this.eftCreatePaymentsubmission(commonRequest);
            }
          })
        }
        } 
      } else{
        if(this.state.paymentMethod === 'CC'){
          this.ccCreatePaymentsubmission(commonRequest);
        } else if(this.state.paymentMethod === 'EFT'){
          this.eftCreatePaymentsubmission(commonRequest);
        }
      }
    })
  } catch (error) {
   console.log("error occurred")
   this.setState({ formError: true,callTriggered : false,spinnerLoading : false, submitAutomaticFailure: true,formErrorDisplay:`<b>${this.state.error_system_error_title}</b> ${this.state.error_sytem_error}`});
   window.scrollTo(0, 0);
  }

}
  
  updateGPSPayment = async (paymentReq)=> {
    let payment = cloneDeep(paymentReq)
    payment.amount = parseFloat(this.state.payAmount.replace('$','')).toFixed(2)
    payment.withdrawalDate = Moment(this.state.paymentDate, 'MM/DD/YYYY').format("yyyy-MM-DD")
    payment.paymentState = "MODIFY"
    updateGPSPaymentAPI(payment).then(res=> {
      if(res.success) {
        this.setState({formValidated : true, callTriggered : false })
        secureLocalStorage.setItem("confirmationNumber", payment.confirmationNumber);
      } else {
        console.log(res)
        this.setState({formError: true, callTriggered : false, spinnerLoading: false, formErrorDisplay:`<b>${this.state.error_system_error_title}</b> ${this.state.error_sytem_error}`})
        window.scroll(0,0)
      }
    })
    .catch(err=> {
      console.log(err)
      this.setState({formError: true, callTriggered : false, spinnerLoading: false, formErrorDisplay:`<b>${this.state.error_system_error_title}</b> ${this.state.error_sytem_error}`})
      window.scroll(0,0)
    })
  }

addBankAccount = async (autoPayTimelines,walletItems,modifiedWalletData)=> {
  let bankRequest = {
    type : this.getBankAccountType().toUpperCase(),
    firstName : this.state.bankHolderFirstName,
    middleName : this.state.bankHolderMiddleName,
    lastName : this.state.bankHolderLastName,
    routingNumber : this.state.routingNumber,
    accountNumber : this.state.accountNumber,
    authIndicator : this.props.authIndicator,
    nickName : this.state.nickName,
    itemId : '',
    bankAccountId : '',
    autopayTimelines : getOneTimePaymentFlag() ? autoPayTimelines : [autoPayTimelines]
  }
  // console.log("walletItems"+items);
  try {  
    let isUpdate = (getOneTimePaymentFlag() || this.state.isDuplicateItem) ? false : true
      return addBankAccountInWallet(walletItems,bankRequest,isUpdate,modifiedWalletData)
  } catch (error) {
      console.log(error)
      this.setState({ formError: true,callTriggered : false,spinnerLoading : false, submitAutomaticFailure: true,formErrorDisplay:`<b>${this.state.error_system_error_title}</b> ${this.state.error_sytem_error}`});
      window.scrollTo(0, 0);
  }
  
}

addCreditCard = async (autoPayTimelines,walletItems,modifiedWalletData)=> {

  let ccRequest = {
    billingCode : this.state.billingCode,
    cardNumberLastFour : this.state.cardNumber,
    cardType : this.state.cardType,
    token : this.state.token,
    expiration : this.state.expirationDate,
    nameOnCard : this.state.nameOnCard,
    nickName : this.state.nickName,
    autopayTimelines : getOneTimePaymentFlag() ? autoPayTimelines : [autoPayTimelines]
  }
try {
let existItem = walletItems.filter(item => (item.active.toLowerCase() === 'true' && item.creditCard && item.creditCard.cardType === ccRequest.cardType && item.creditCard.cardNumberLastFour === ccRequest.cardNumberLastFour
  && item.creditCard.expiration === ccRequest.expiration));
console.log(existItem)
if(existItem.length>0) {
  console.log("card exist in wallet");
  this.setState({ formError: true,callTriggered : false,spinnerLoading : false, submitAutomaticFailure: true,formErrorDisplay:`<b>${this.state.error_cc_duplicate_title}</b> ${this.state.error_cc_duplicate}`});
  window.scrollTo(0, 0);
} else{
return addCreditCardInWallet(walletItems, ccRequest,modifiedWalletData);
}
} catch (error) {
  this.setState({ formError: true,callTriggered : false,spinnerLoading : false, submitAutomaticFailure: true,formErrorDisplay:`<b>${this.state.error_system_error_title}</b> ${this.state.error_sytem_error}`});
  window.scrollTo(0, 0);
console.log(error)
}
}
  toggleModal = () => {
    this.setState({ isOpen: !this.state.isOpen, focusOnModal: true })
  }

  displayErrorAlertOnSmallScreen = () => {
    return this.state.formError ? (
      <div className="xs-show d-print-none" id="TopAlertError" role="alert">
          <Notification notificationType="error" className="errorBanner">
          {this.state.formErrorDisplay && this.state.formErrorDisplay.indexOf("Invalid characters") > -1 ? this.state.formErrorDisplay : <Markup content={this.state.formErrorDisplay} />}
          </Notification>      
      </div>
    ) : null
  }

  changeAccountTypeCase = (accountType) => {
    return accountType.charAt(0) + accountType.slice(1).toLowerCase().toLowerCase()
  }

  currentMop = () => {
    let modifiedAccountType = this.state.paymentToEdit?.creditCard === null ? 'EFT-' + this.changeAccountTypeCase(this.state.paymentToEdit?.bankAccount?.bankAccountType) : this.changeAccountTypeCase(this.state.paymentToEdit?.creditCard?.cardType);
    let lastFour = this.state.paymentToEdit?.creditCard ? this.state.paymentToEdit.creditCard?.cardNumberLastFour : this.state.paymentToEdit?.bankAccount.accountNumber.slice(-4)
    
    return (
    <p className="uhc-sans-medium token-color-gray-dark-base upd-mop">
      {modifiedAccountType + ' ***' + lastFour}  
    </p>
    )
  }

  isOnlyCancel = ()=> {
    return getRecurringPaymentFlag() && this.state.cancelRecChecked === true && !this.state.paymentBy
  }

  render(props) {

    let paymentMethod = null;
    let paymentDetails = null;
    let paymentAmountSection = null;


    if(getRecurringPaymentFlag() && !isManageRecurring() && this.state.cancelRecChecked === false) {
      paymentMethod = this.state.auth_setup_pay_text;
    }

    /* if(getRecurringPaymentFlag() && isManageRecurring() && this.state.cancelRecChecked === true && this.state.paymentBy) {
      paymentMethod = this.state.auth_update_pay_text;
    } */

    if(getRecurringPaymentFlag() && isManageRecurring() && this.state.paymentBy) {
      paymentMethod = this.state.auth_update_pay_text;
    }

    if (getOneTimePaymentFlag() && this.state.cancelRecChecked === false) {
      paymentMethod = <div>{this.state.auth_onetime_pay_text}</div>;
    }

    if(this.state.cancelRecChecked === true && !this.state.paymentBy) {
      paymentMethod = this.state.auth_cancel_recurring_text
    }

    if ((getOneTimePaymentFlag() || getRecurringPaymentFlag() || isEditPayment()) && this.state.paymentMethod === "CC") {
      paymentDetails = <div>{isEditPayment() ? this.currentMop() : this.state.cardType + ' ***' + this.state.cardNumber}</div>
    }

    if(isEditPayment()){
      paymentMethod = <div>{this.state.auth_update_onetime_pay_text}</div>;
    }
    
    if(this.state.paymentMethod === 'EFT' && getRecurringPaymentFlag() && isManageRecurring()) {
      paymentDetails =  <>
        <p className="pb-3 token-color-gray-dark-text-bold uhc-capitalize">{this.state.bankHolderFirstName}{' '}{this.state.bankHolderMiddleName}{' '}{this.state.bankHolderLastName}</p>
       <p className="pb-3 token-color-gray-dark-text-bold"> {this.getBankAccountType()?.toLowerCase().indexOf('checking') > -1 ? this.state.auth_eft_checking_pay_label : this.state.auth_eft_savings_pay_label}{this.state.accountNumber.substring(this.state.accountNumber.length-4)} </p>
      </>
    }

    if(this.state.cancelRecChecked === false && this.state.paymentMethod === 'EFT' && (getOneTimePaymentFlag() || isEditPayment() || (getRecurringPaymentFlag() && (isCurrentRecurring() || !isManageRecurring())))) {
      paymentDetails = <>
        <p className="pb-3 token-color-gray-dark-text-bold uhc-capitalize">{this.state.bankHolderFirstName}{' '}{this.state.bankHolderMiddleName}{' '}{this.state.bankHolderLastName}</p>
        <p className="token-color-gray-dark-text-bold">{isEditPayment() ? this.currentMop() : this.getBankAccountType()?.toLowerCase().indexOf('checking') > -1 ? this.state.auth_eft_checking_pay_label : this.state.auth_eft_savings_pay_label}{!isEditPayment() ? this.state.accountNumber.substring(this.state.accountNumber.length-4) : null}</p>
      </>
    }


// one time
    if((getOneTimePaymentFlag() && !getRecurringPaymentFlag()) || isEditPayment()) {

      paymentAmountSection = <div aria-live="polite" role="status">
      <ul className="row mt-5" >
      <li className="col-3">
        <span className="token-font-family-heading-three m-0 totalLabel">{this.state.total_label}</span>
      </li>
      <li className="col-9">
        <span className="paymentTotalAmt fedtotal"> ${this.state.payAmount ? convertToCurrency(this.state.payAmount.replace('$','')) : '00.00'}</span>
      </li>
    </ul>
    </div>
    }

    // recurring but not only cancel
    if(getRecurringPaymentFlag()) {
      let monthlyPaymentPremium = this.props.location?.state?.monthlyPaymentPremium ? this.props.location.state.monthlyPaymentPremium : secureLocalStorage.getItem('monthlyPaymentPremium');
      monthlyPaymentPremium = monthlyPaymentPremium !== null && monthlyPaymentPremium !== '' && monthlyPaymentPremium !== 'null' ? '$'+ convertToCurrency(monthlyPaymentPremium)+'/mo' : null
      paymentAmountSection = <div aria-live="polite" role="status">
      <ul className="row mt-5">
        <li className="col-3 fedRecTotalLbl">
        <span className="token-font-family-heading-three m-0 totalLabel">{this.state.total_label}</span>
        </li>
        {monthlyPaymentPremium === null ? (
          <li className="col-9 total-padding fedRecTotal">
          <span className="tds-header__h3" style={{ fontSize: '28px' }}> {this.state.auth_total_desc_setup_pay_fed}</span>
        </li>
        ) : (<li className="col-9">
        <span className="paymentTotalAmt fedtotal"> {monthlyPaymentPremium} </span>
        </li>)
        }
    </ul>
    </div>
    }
    if(getRecurringPaymentFlag() && isManageRecurring() && !this.state.paymentBy && this.state.cancelRecChecked === true) {
      paymentAmountSection = null
    }


    let body = (
  <div>
    {!(this.state.callTriggered && !this.state.mfaValidation && !this.state.formValidated) ? this.state.spinnerLoading ? <Spinner /> : null : null}
  <div className={(this.state.spinnerLoading ? ' text-blur' : '') }>
      <PageHeader links={this.state.hLinks} back_btn={this.state.back_btn} title={this.state.banner_text}
            plan={getPlanInformation().planType}
            />
       {this.state.isMFA && this.state.mfaValidation && !this.state.isMFAAuthenticated && this.checkPostMfa() && !this.state.isMFAInvalid ? <MfaLogOutModal isMFAInvalid={!this.state.isMFAAuthenticated} /> : null}
      {this.state.isMFA &&
        (this.state.mfaValidation && 
        !this.state.spinnerLoading &&
        (!this.checkPostMfa() || this.state.isMFAInvalid)) && 
        <HsidMfaModal 
          savePayMethodChecked = {this.state.savePayMethodChecked} 
          mfaCallBackURL={this.state.mfaCallBackURL} 
          paymentType = {this.state.paymentType} 
          modalContent={this.state.mfaContent} 
          displayErrorMessage={this.displayErrorMessage}
          paymentsMethodValue={this.props.location.state.paymentsMethodValue}
        /> 
      }
      {this.displayErrorAlertOnSmallScreen()}
      <div className={'container'} id="reviewpage">
            <div className="row justify-content-center">
            <div className="col-sm-9">
                  <PageError errorFields={this.state.errorFields} page='review' />
                {this.state.formError && (
                  <div id="TopAlertError" className="xs-hide" role="alert">
                  <Notification notificationType="error" className="errorBanner system-error">
                  {this.state.formErrorDisplay && this.state.formErrorDisplay.indexOf("Invalid characters") > -1 ? this.state.formErrorDisplay : <Markup content={this.state.formErrorDisplay} />}
                  </Notification>
               </div>
                )}

                <div className="uhc-tile-brd-wdgt-payment">
                  <div className={this.state.formError ? "tds-card tds-card--primary tds-card--border tds-bradius-bbtmnone mt-4" : "tds-card tds-card--primary tds-card--border tds-bradius-bbtmnone mt-6"}>
                    <div className="tds-card__content tds-card__content--border pb-0">
                      <div className="row justify-content-between payment-detail-card-header">
                        <div className="col-md-12 pl-0">
                        <Typography.H3 className="tds-header__h3 pt-2">
                        {this.state.card_header_lbl}
                        </Typography.H3>
                        </div>
                      </div>
                       <Typography.Paragraph className="pb-4">{this.state.header_sub_section}</Typography.Paragraph>
                    </div>
                  </div>

                  <div className="tds-card tds-card--primary tds-card--border">
                    <div className="tds-card__content tds-card__content--border payment-details-card mb-5" >
                    <div className="change-pay-mobile">
                        <button role="link" id= "change-details-icon-mobile" onClick={()=> {this.redirectHandler();trackLinkClick(isEditPayment() ? 'update-onetime-payment : review-and-submit : change payment details' : this.isOnlyCancel() ? this.state.change_details_lbl : this.state.pymt_dtls_lbl)}}   className="tds-link tds-link--medium linkBtn" style= {{ display: 'flex'}}>
                          <span><img src={Edit} alt=""/></span> <span className="change-details-icon-span"> {this.isOnlyCancel() ? this.state.change_details_lbl : this.state.pymt_dtls_lbl}</span></button>
                           {this.renderRedirect()}
                    </div>
                    <div className="uhc-review-labels">
                      <ul className="row">
                        <li className="col-3">
                          <span>{this.state.plan_label}</span>
                        </li>
                        <li className="col-5 uhc-plan-mobile-row">
                          <span className="token-color-gray-dark-text-bold">{this.state.planType}</span>
                        </li>
                        <li className="col-4 uhc-item-right-align">
                        <button role="link"  id= "change-details-icon" onClick={()=> {this.redirectHandler();trackLinkClick(isEditPayment() ? 'update-onetime-payment : review-and-submit : change payment details' : 'Change card details')}}   className="tds-link tds-link--medium linkBtn" style= {{ display: 'flex'}}>
                          <span><img src={Edit} alt=""/></span> <span className="change-details-icon-span"> {this.isOnlyCancel() ? this.state.change_details_lbl : this.state.pymt_dtls_lbl}</span></button>
                           {this.renderRedirect()}
                       </li>
                      </ul>
                      <ul className="row mt-4">
                        <li className="col-3">
                          <span>
                          {this.state.name_label}
                          </span>
                        </li>
                        <li className="col-9">
                          <span className="token-color-gray-dark-text-bold">
                          {capitalizeVal(this.state.fullName)}
                          </span>
                        </li>
                      </ul>
                      <ul className="row mt-4">
                        <li className="col-3">
                          <span>
                            {this.state.id_label}
                          </span>
                        </li>
                        <li className="col-9">
                          <span className="token-color-gray-dark-text-bold">{this.state.memberId}</span>
                        </li>
                      </ul>
                      <ul className="row mt-4">
                        <li className="col-3">
                          <span>
                           {this.state.details_label}
                          </span>
                        </li>
                        <li className="col-9 paydetailsContent">
                          
                              <span className="token-color-gray-dark-text-bold">{paymentMethod}</span>
                          
                          <div className={"pt-3 token-color-gray-dark-text-bold"}>
                          {paymentDetails}
                          </div>
                          {getOneTimePaymentFlag() || isEditPayment() ? <span className="pt-3 token-color-gray-dark-text-bold" >{Moment(this.state.paymentDate,"MM-DD-YYYY").format('MM/DD/YYYY')} </span>  :  !this.isOnlyCancel() && getRecurringPaymentFlag() ? <span className="pt-3 token-color-gray-dark-text-bold" >{this.state.auth_payment_effective_date} </span> : null}
                        </li>
                      </ul>
                      {!this.isOnlyCancel() && getRecurringPaymentFlag() ?
                      <ul className="row mt-4">
                        <li className="col-3">
                          <span>
                          {this.state.audit_start_date}
                          </span>
                        </li>
                        <li className="col-9">
                          <span className="token-color-gray-dark-text-bold">{Moment(this.state.paymentDate,"MM-DD-YYYY").format('MM/DD/YYYY')}</span>
                        </li>
                      </ul>:null}
                      </div>
                      {this.state.paymentBy && this.state.paymentBy.indexOf('wallet') === -1 && getOneTimePaymentFlag() ? (
                            <FormGroup
                            alignment="vertical"
                            aria-labelledby="saveCCCheckBox"
                            groupName="remembercard"
                            hideInstruction={false}
                            state="default"
                            className="mt-2 fedSavePayment"
                          >
                            <Checkbox
                            id="saveCCCheckBox"
                            label= {this.state.remember_pay_method}
                            value= {this.state.remember_pay_method}
                            checked = {this.state.savePayMethodChecked}
                            className="uhc-lineheight-22 uhc-flex"
                            onChange={()=> {this.setState({savePayMethodChecked : !this.state.savePayMethodChecked},() => {this.savePMchecked(this.state.savePayMethodChecked);trackLinkClick(this.state.savePayMethodChecked ? 'Save this payment method to my wallet : Checked' : 'Save this payment method to my wallet : Unchecked')});}}
                          />
                          </FormGroup>
                          ) : null }
                      {paymentAmountSection}
                    </div>
                  </div>
                  <div className="tds-card tds-card--secondary tds-card--border tds-tradius-topbordernone">
                    <div className="tds-card__content">
                      <div className="row">
                        <div id="electronicText">
                        <Typography.H4 className="mt-3">
                          {getOneTimePaymentFlag() || isEditPayment() ? this.state.auth_acknowledge_header : !this.state.cancelRecChecked && !isManageRecurring()?  this.state.auth_acknowledge_header_setuprecurring : isManageRecurring() && !this.state.cancelRecChecked ?
                             this.state.auth_acknowledge_header_managerecurring : this.state.auth_acknowledge_header_cancelrecurring}
                       </Typography.H4>
                              {getOneTimePaymentFlag() || isEditPayment() ? <Markup content={this.state.onetime_agreement} /> : !this.state.cancelRecChecked && !isManageRecurring() ? <Markup content={this.state.setuprecurring_agreement} />   :
                                  isManageRecurring() && !this.state.cancelRecChecked ? <Markup content={this.state.managerecurring_agreement} /> : <Markup content={this.state.cancelrecurring_agreement} />
                              }
                          </div>
                  <div className="row mt-4 mb-5 manage-buttons">
                        {this.state.cancelRecChecked ? (
                          <button
                          name="next"
                          aria-disabled="false"
                          describedby=""
                          className="tds-button tds-button--primary-one tds-margin-xsm-horizontal"
                          onClick={this.confirmAndPayHandler}
                        >
                          {this.state.button_label_cancelrecurring}
                        </button>
                      ) : !this.state.superUser ? (
                          <PaymentModals
                          modalContent={this.state.elec_pay_modal}
                          modalType='paymentAuth'
                          payMethod={this.state.paymentMethod}
                          payWithSavedCC={this.state.payWithSavedCC}
                          confirmAndPayHandler={this.confirmAndPayHandler}
                          submit_btn={getOneTimePaymentFlag() ? this.state.button_label : isEditPayment() ? this.state.button_label_managerecurring : !this.state.cancelRecChecked && !isManageRecurring() ?  this.state.button_label_setuprecurring : isManageRecurring() ?
                                 this.state.button_label_managerecurring: this.state.cancelRecChecked ? this.state.button_label_cancelrecurring : null}
                          />):  (
                            <button
                            name="next"
                            aria-disabled="false"
                            describedby=""
                            className="tds-button tds-button--primary-one tds-margin-xsm-horizontal"
                            onClick={this.confirmAndPayHandler}
                          >
                            {getOneTimePaymentFlag() ? this.state.button_label : isEditPayment() ? this.state.button_label_managerecurring : !this.state.cancelRecChecked && !isManageRecurring() ?  this.state.button_label_setuprecurring : isManageRecurring() ?
                                   this.state.button_label_managerecurring: this.state.cancelRecChecked ? this.state.button_label_cancelrecurring : null}
                          </button>
                        ) }

                        <PaymentModals
                          modalContent={this.state.cancelPaymentModal}
                          modalType='cancelPayment'
                          cancel_btn_txt={this.state.cancel_btn_text}
                        />
                      </div>
                      </div>
                    </div>
                  </div>
                  {this.state.superUserError &&
                    <div className='superUserError cc'>
                      <Notification notificationType="error" >
                        <Markup content={this.state.superUserErrorMsg}/>
                      </Notification>
                    </div>
                  }
                </div>
              </div>
            </div>
            {this.state.showSessionModal ?
                     <Redirect to="/service-error" push={true} />
                    : null}
          </div>
      </div> </div>
        );
        if (this.state.callTriggered && !this.state.mfaValidation && !this.state.formValidated) {
              body = (
                <React.Fragment>
                <Spinner />
                  {/* <Loader /> */}
                  <BodyWrapperBlurry>
                    {this.displayErrorAlertOnSmallScreen()}
                                  {body}
                  </BodyWrapperBlurry>
                </React.Fragment>
              );
            } else if (!this.state.formValidated && this.state.serviceError) {
              body = (<Redirect to="/service-error" push={true} />)
            } else if(this.state.formValidated){
                 body = ( <Redirect
                              to={{
                                pathname: this.state.redirectForm,
                                state: {
                                  bankHolderName: this.state.bankHolderName,
                                  routingNumber: this.state.routingNumber,
                                  accountNumber: this.state.accountNumber,
                                  paymentMethod: this.state.paymentMethod,
                                  payAmount: this.state.payAmount?.replace(',', ''),
                                  cardType: this.state.cardType,
                                  cardNumber: this.state.cardNumber,
                                  pageLoadViaFlow: this.state.pageLoadViaFlow,
                                  cancelRecChecked: this.state.cancelRecChecked,
                                  payWithSavedCC: this.state.payWithSavedCC,
                                  saveCreditCard: this.state.saveCreditCard,
                                  bankHolderFirstName : this.state.bankHolderFirstName,
                                  bankHolderMiddleName: this.state.bankHolderMiddleName,
                                  bankHolderLastName: this.state.bankHolderLastName,
                                  bankAccountType : this.getBankAccountType(),
                                  nickName: this.state.nickName,
                                  savePayMethodChecked : this.state.savePayMethodChecked,
                                  paymentDate : this.state.paymentDate,
                                  nameOnCard : this.state.nameOnCard,
                                  expirationDate : this.state.expirationDate,
                                  token : this.state.token,
                                  billingCode : this.state.billingCode,
                                  isPayMethodSaved : this.state.isPayMethodSaved,
                                  paymentToEdit : this.state.paymentToEdit,
                                  currentMop : isEditPayment() ? this.currentMop() :'',
                                  paymentsMethodValue: this.props.location?.state?.paymentsMethodValue ? this.props.location?.state?.paymentsMethodValue : getPaymentMethod(),
                                  monthlyPaymentPremium: this.props.location?.state?.monthlyPaymentPremium ? 
                                                        this.props.location?.state?.monthlyPaymentPremium : secureLocalStorage.getItem('monthlyPaymentPremium'),
                                  totalAmountDue: (JSON.parse(secureLocalStorage.getItem('paymentFormData')).savePayMethodChecked || getRecurringPaymentFlag()) ? JSON.parse(secureLocalStorage.getItem('paymentFormData')).totalAmountDue : this.props.location?.state?.totalAmountDue,
                                  withdrawalDate: this.state.withdrawalDate
                                },
                              }}

                />);
              }
              else{
                body =(
                  <React.Fragment>
                  {body}
                  </React.Fragment>
                );
              }
    return (
        <React.Fragment>
          {body}
      </React.Fragment>

      );
  }
}
const BodyWrapperBlurry = styled.div`
  filter: blur(8px);
  -webkit-filter: blur(8px);
`;
export default FedPaymentReview;


