import axios from './ucpPayments-api';
import appConfigs from '../app.config';
import { getDecStr} from '../../server/util/encDec';
export const searchPaymentWalletResultsAPI = async (accountId) => {
    var searchPaymentWalletRequest = {
        "householdId": accountId
    };

    let accNumber = null;
    let routingNumber = null;
	return axios.post(appConfigs.search_payment_wallet_results_path , 
        searchPaymentWalletRequest).then(response =>{

            if(response.data.activeItems != null){
                response.data.activeItems.map(elt=> {
                    if(elt.bankAccount != null){
                        if (elt?.bankAccount.accountNumber !== null && elt?.bankAccount.routingNumber !== null) {
                            accNumber = getDecStr(elt.bankAccount.accountNumber)
                            routingNumber= getDecStr(elt.bankAccount.routingNumber)
                            elt.bankAccount.accountNumber = accNumber;
                            elt.bankAccount.routingNumber= routingNumber;
                        }
                    }
                    return elt
                });
            }
    
            if(response.data.notProcessedPayments !== null && response.data.notProcessedPayments.length > 0){
                response.data.notProcessedPayments.map(elt2=> {
                    if(elt2.bankAccount != null){
                        if (elt2?.bankAccount.accountNumber !== null && elt2?.bankAccount.routingNumber !== null) {
                            accNumber =  getDecStr(elt2.bankAccount.accountNumber)
                            routingNumber= getDecStr(elt2.bankAccount.routingNumber)
                            elt2.bankAccount.accountNumber = accNumber;
                            elt2.bankAccount.routingNumber= routingNumber;
                        }
                    }
                    return elt2
                });
            }

            if(response.data.wrapperPojo != null){
                response.data.wrapperPojo.map(elt1=> {
                    if (elt1?.accNumber !== null && elt1.routingNumber !== null) {
                        accNumber = getDecStr(elt1.accountNumber)
                        routingNumber= getDecStr(elt1.routingNumber)
                        elt1.accountNumber = accNumber;
                        elt1.routingNumber= routingNumber;
                    }
        
                    return elt1
                });
            }
 
				return response.data;
		})
		.catch((error) => {
            return {
                callFailed: true
            };
		});

};


export default searchPaymentWalletResultsAPI;