export const isAndroid = () => Boolean(navigator.userAgent.match(/Android/i));
export const isIos = () => Boolean(navigator.userAgent.match(/iPhone|iPad|iPod/i));
export const isOpera = () => Boolean(navigator.userAgent.match(/Opera Mini/i));
export const isWindows = () => Boolean(navigator.userAgent.match(/IEMobile/i));
export const isSSR = () => Boolean(navigator.userAgent.match(/SSR/i));

export const isMobile = () => Boolean(isAndroid() || isIos() || isOpera() || isWindows() || (window.screen.width < 768) || (window.innerWidth < 768));
export const isDesktop = () => Boolean(!isMobile() && !isSSR());

const DetectDevice = () => {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
};

export default DetectDevice;