const dotenv = require('dotenv');
dotenv.config();

const config = {
	PORT: process.env.PORT || 3006,
	//REACT_APP_RUN_MODE: process.env.REACT_APP_RUN_MODE || 'stage',
	REACT_APP_LOCAL_AEM_ROOT: process.env.REACT_APP_LOCAL_AEM_ROOT || 'https://team-ct-medicare.dev.werally.in',
	REACT_APP_LOCAL_UCP_PAYMENTS_ROOT:
		process.env.REACT_APP_LOCAL_UCP_PAYMENTS_ROOT || 'https://team-ct-medicare.dev.werally.in/UCPPayments',
	REACT_APP_LOCAL_UCP_PROFILE_PREFERENCES_ROOT:
		process.env.REACT_APP_LOCAL_UCP_PROFILE_PREFERENCES_ROOT || 'https://team-ct-medicare.dev.werally.in/UCPProfileAndPreferences',
	REACT_APP_LOCAL_UCP_USER_MANAGEMENT_ROOT:
		process.env.REACT_APP_LOCAL_UCP_USER_MANAGEMENT_ROOT || 'https://team-ct-medicare.dev.werally.in/UCPUserManagement',	
	REACT_APP_LOCAL_UCP_MEMBER_AUTH_ROOT:
		process.env.REACT_APP_LOCAL_UCP_MEMBER_AUTH_ROOT || 'https://team-ct-medicare.dev.werally.in/UCPSSOMemberAuth',
	REACT_APP_TEST_AEM_ROOT:
		process.env.REACT_APP_TEST_AEM_ROOT || 'https://team-ct-medicare.dev.werally.in',
	REACT_APP_TEST_UCP_PAYMENTS_ROOT:
		process.env.REACT_APP_TEST_UCP_PAYMENTS_ROOT ||  'https://team-ct-medicare.dev.werally.in/UCPPayments',
	REACT_APP_TEST_UCP_PROFILE_PREFERENCES_ROOT:
		process.env.REACT_APP_TEST_UCP_PROFILE_PREFERENCES_ROOT || 'https://team-ct-medicare.dev.werally.in/UCPProfileAndPreferences',
	REACT_APP_TEST_UCP_USER_MANAGEMENT_ROOT:
		process.env.REACT_APP_TEST_UCP_USER_MANAGEMENT_ROOT || 'https://team-ct-medicare.dev.werally.in/UCPUserManagement',	
	REACT_APP_TEST_UCP_MEMBER_AUTH_ROOT:
		process.env.REACT_APP_TEST_UCP_MEMBER_AUTH_ROOT || 'https://team-ct-medicare.dev.werally.in/UCPSSOMemberAuth',	
	REACT_APP_MNR_AEM_ROOT:
		process.env.REACT_APP_MNR_AEM_ROOT || 'https://team-mnr-medicare.dev.werally.in',
	REACT_APP_MNR_UCP_PAYMENTS_ROOT:
		process.env.REACT_APP_MNR_UCP_PAYMENTS_ROOT ||  'https://team-mnr-medicare.dev.werally.in/UCPPayments',
	REACT_APP_MNR_UCP_PROFILE_PREFERENCES_ROOT:
		process.env.REACT_APP_MNR_UCP_PROFILE_PREFERENCES_ROOT || 'https://team-mnr-medicare.dev.werally.in/UCPProfileAndPreferences',
	REACT_APP_MNR_UCP_USER_MANAGEMENT_ROOT:
		process.env.REACT_APP_MNR_UCP_USER_MANAGEMENT_ROOT || 'https://team-mnr-medicare.dev.werally.in/UCPUserManagement',
	REACT_APP_MNR_UCP_MEMBER_AUTH_ROOT:
		process.env.REACT_APP_MNR_UCP_MEMBER_AUTH_ROOT || 'https://team-mnr-medicare.dev.werally.in/medicare/UCPSSOMemberAuth',
	REACT_APP_PERF_AEM_ROOT:
		process.env.REACT_APP_TEST_AEM_ROOT || 'https://team-perf-medicare.dev.werally.in',
	REACT_APP_PERF_UCP_PAYMENTS_ROOT:
		process.env.REACT_APP_PERF_UCP_PAYMENTS_ROOT ||  'https://team-perf-medicare.dev.werally.in/UCPPayments',
	REACT_APP_PERF_UCP_PROFILE_PREFERENCES_ROOT:
		process.env.REACT_APP_PERF_UCP_PROFILE_PREFERENCES_ROOT || 'https://team-perf-medicare.dev.werally.in/UCPProfileAndPreferences',
	REACT_APP_PERF_UCP_USER_MANAGEMENT_ROOT:
		process.env.REACT_APP_PERF_UCP_USER_MANAGEMENT_ROOT || 'https://team-perf-medicare.dev.werally.in/UCPUserManagement',
	REACT_APP_PERF_UCP_MEMBER_AUTH_ROOT:
		process.env.REACT_APP_PERF_UCP_MEMBER_AUTH_ROOT || 'https://team-perf-medicare.dev.werally.in/medicare/UCPSSOMemberAuth',		
	REACT_APP_CONTENT_PATH:
		process.env.REACT_APP_CONTENT_PATH || '/medicare/content/dam/premium-payments/content-fragments',
	REACT_APP_GPP_ROOT_URL_PATH: 
		process.env.REACT_APP_GPP_ROOT_URL_PATH || '/medicare/payments/libs',
	REACT_APP_SHOULD_PROXY_AEM: 
		process.env.REACT_APP_SHOULD_PROXY_AEM || false,
	REACT_APP_INT_AEM_ROOT: 
		process.env.REACT_APP_INT_AEM_ROOT || 'https://medicare.int.werally.in',
	REACT_APP_INT_UCP_PAYMENTS_ROOT:
		process.env.REACT_APP_INT_UCP_PAYMENTS_ROOT || 'https://medicare.int.werally.in/UCPPayments',
	REACT_APP_INT_UCP_PROFILE_PREFERENCES_ROOT:
		process.env.REACT_APP_INT_UCP_PROFILE_PREFERENCES_ROOT || 'https://medicare.int.werally.in/UCPProfileAndPreferences',
	REACT_APP_INT_UCP_USER_MANAGEMENT_ROOT:
		process.env.REACT_APP_INT_UCP_USER_MANAGEMENT_ROOT || 'https://medicare.int.werally.in/UCPUserManagement',
	REACT_APP_INT_UCP_MEMBER_AUTH_ROOT:
		process.env.REACT_APP_INT_UCP_MEMBER_AUTH_ROOT || 'https://medicare.int.werally.in/medicare/UCPSSOMemberAuth',
	REACT_APP_MEMBER_INT_AEM_ROOT: 
		process.env.REACT_APP_MEMBER_INT_AEM_ROOT || 'https://member.int.uhc.com',
	REACT_APP_MEMBER_INT_AEM_PREVIEW_ROOT: 
		process.env.REACT_APP_MEMBER_INT_AEM_PREVIEW_ROOT || 'https://member.int.uhc.com/medicare-dev',
	REACT_APP_MEMBER_INT_AEM_GREEN_ROOT: 
		process.env.REACT_APP_MEMBER_INT_AEM_GREEN_ROOT || 'https://member.int.uhc.com/medicare-green',
	REACT_APP_MEMBER_INT_UCP_PAYMENTS_ROOT:
		process.env.REACT_APP_MEMBER_INT_UCP_PAYMENTS_ROOT || 'https://member.int.uhc.com/medicare/UCPPayments',
	REACT_APP_MEMBER_INT_UCP_PROFILE_PREFERENCES_ROOT:
		process.env.REACT_APP_MEMBER_INT_UCP_PROFILE_PREFERENCES_ROOT || 'https://member.int.uhc.com/medicare/UCPProfileAndPreferences',
	REACT_APP_MEMBER_INT_UCP_USER_MANAGEMENT_ROOT:
		process.env.REACT_APP_MEMBER_INT_UCP_USER_MANAGEMENT_ROOT || 'https://member.int.uhc.com/medicare/UCPUserManagement',
	REACT_APP_MEMBER_INT_UCP_MEMBER_AUTH_ROOT:
		process.env.REACT_APP_MEMBER_INT_UCP_MEMBER_AUTH_ROOT || 'https://member.int.uhc.com/medicare/medicare/UCPSSOMemberAuth',
	REACT_APP_STAGE_AEM_ROOT: process.env.REACT_APP_STAGE_AEM_ROOT || 'https://stage-medicare.uhc.com',
	REACT_APP_STAGE_UCP_PAYMENTS_ROOT:
		process.env.REACT_APP_STAGE_UCP_PAYMENTS_ROOT || 'https://stage-medicare.uhc.com/UCPPayments',
	REACT_APP_STAGE_UCP_PROFILE_PREFERENCES_ROOT:
		process.env.REACT_APP_STAGE_UCP_PROFILE_PREFERENCES_ROOT || 'https://stage-medicare.uhc.com/UCPProfileAndPreferences',
	REACT_APP_STAGE_UCP_USER_MANAGEMENT_ROOT:
		process.env.REACT_APP_STAGE_UCP_USER_MANAGEMENT_ROOT || 'https://stage-medicare.uhc.com/UCPUserManagement',
	REACT_APP_STAGE_UCP_MEMBER_AUTH_ROOT:
		process.env.REACT_APP_STAGE_UCP_MEMBER_AUTH_ROOT || 'https://stage-medicare.uhc.com/medicare/UCPSSOMemberAuth',
	REACT_APP_OFFLINE_STAGE_AEM_ROOT: process.env.REACT_APP_OFFLINE_STAGE_AEM_ROOT || 'https://offline-stage-medicare.uhc.com',
	REACT_APP_OFFLINE_STAGE_UCP_PAYMENTS_ROOT:
		process.env.REACT_APP_OFFLINE_STAGE_UCP_PAYMENTS_ROOT || 'https://offline-stage-medicare.uhc.com/medicare/UCPPayments',
	REACT_APP_OFFLINE_STAGE_UCP_PROFILE_PREFERENCES_ROOT:
		process.env.REACT_APP_OFFLINE_STAGE_UCP_PROFILE_PREFERENCES_ROOT || 'https://offline-stage-medicare.uhc.com/medicare/UCPProfileAndPreferences',
	REACT_APP_OFFLINE_STAGE_UCP_USER_MANAGEMENT_ROOT:
		process.env.REACT_APP_OFFLINE_STAGE_UCP_USER_MANAGEMENT_ROOT || 'https://offline-stage-medicare.uhc.com/medicare/UCPUserManagement',
	REACT_APP_OFFLINE_STAGE_UCP_MEMBER_AUTH_ROOT:
		process.env.REACT_APP_OFFLINE_STAGE_UCP_MEMBER_AUTH_ROOT || 'https://offline-stage-medicare.uhc.com/medicare/medicare/UCPSSOMemberAuth',	
	REACT_APP_PROD_AEM_ROOT: process.env.REACT_APP_PROD_AEM_ROOT || 'https://www.medicare.uhc.com',
	REACT_APP_PROD_UCP_PAYMENTS_ROOT:
		process.env.REACT_APP_PROD_UCP_PAYMENTS_ROOT || 'https://www.medicare.uhc.com/UCPPayments',
	REACT_APP_PROD_UCP_PROFILE_PREFERENCES_ROOT:
		process.env.REACT_APP_PROD_UCP_PROFILE_PREFERENCES_ROOT || 'https://www.medicare.uhc.com/UCPProfileAndPreferences',
	REACT_APP_PROD_UCP_UCP_USER_MANAGEMENT_ROOT:
		process.env.REACT_APP_PROD_UCP_UCP_USER_MANAGEMENT_ROOT || 'https://www.medicare.uhc.com/UCPUserManagement',
	REACT_APP_PROD_UCP_UCP_MEMBER_AUTH_ROOT:
		process.env.REACT_APP_PROD_UCP_MEMBER_AUTH_ROOT || 'https://www.medicare.uhc.com/medicare/UCPSSOMemberAuth',
	REACT_APP_RALLY_PROD_AEM_ROOT: 
		process.env.REACT_APP_RALLY_PROD_AEM_ROOT || 'https://medicare.prod.werally.in',
	REACT_APP_RALLY_PROD_UCP_PAYMENTS_ROOT:
		process.env.REACT_APP_RALLY_PROD_UCP_PAYMENTS_ROOT || 'https://medicare.prod.werally.in/UCPPayments',
	REACT_APP_RALLY_PROD_UCP_PROFILE_PREFERENCES_ROOT:
		process.env.REACT_APP_RALLY_PROD_UCP_PROFILE_PREFERENCES_ROOT || 'https://medicare.prod.werally.in/UCPProfileAndPreferences',
	REACT_APP_RALLY_PROD_UCP_USER_MANAGEMENT_ROOT:
		process.env.REACT_APP_RALLY_PROD_UCP_USER_MANAGEMENT_ROOT || 'https://medicare.prod.werally.in/UCPUserManagement',
	REACT_APP_RALLY_PROD_UCP_MEMBER_AUTH_ROOT:
		process.env.REACT_APP_RALLY_PROD_UCP_MEMBER_AUTH_ROOT || 'https://medicare.prod.werally.in/medicare/UCPSSOMemberAuth',
	REACT_APP_RALLY_MEMBER_PROD_AEM_ROOT: 
		process.env.REACT_APP_RALLY_MEMBER_PROD_AEM_ROOT || 'https://member.uhc.com',
	REACT_APP_RALLY_MEMBER_PROD_AEM_PREVIEW_ROOT: 
		process.env.REACT_APP_RALLY_MEMBER_PROD_AEM_PREVIEW_ROOT || 'https://member.uhc.com/medicare-preview',
	REACT_APP_RALLY_MEMBER_PROD_AEM_GREEN_ROOT: 
		process.env.REACT_APP_RALLY_MEMBER_PROD_AEM_GREEN_ROOT || 'https://member.uhc.com/medicare-green',
	REACT_APP_RALLY_MEMBER_PROD_UCP_PAYMENTS_ROOT:
		process.env.REACT_APP_RALLY_MEMBER_PROD_UCP_PAYMENTS_ROOT || 'https://member.uhc.com/medicare/UCPPayments',
	REACT_APP_RALLY_MEMBER_PROD_UCP_PROFILE_PREFERENCES_ROOT:
		process.env.REACT_APP_RALLY_MEMBER_PROD_UCP_PROFILE_PREFERENCES_ROOT || 'https://member.uhc.com/medicare/UCPProfileAndPreferences',
	REACT_APP_RALLY_MEMBER_PROD_UCP_USER_MANAGEMENT_ROOT:
		process.env.REACT_APP_RALLY_MEMBER_PROD_UCP_USER_MANAGEMENT_ROOT || 'https://member.uhc.com/medicare/UCPUserManagement',
	REACT_APP_RALLY_MEMBER_PROD_UCP_MEMBER_AUTH_ROOT:
		process.env.REACT_APP_RALLY_MEMBER_PROD_UCP_MEMBER_AUTH_ROOT || 'https://member.uhc.com/medicare/medicare/UCPSSOMemberAuth',
	REACT_APP_OFFLINE_PROD_AEM_ROOT: 
		process.env.REACT_APP_OFFLINE_PROD_AEM_ROOT || 'https://offline.medicare.uhc.com',
	REACT_APP_OFFLINE_PROD_UCP_PAYMENTS_ROOT:
		process.env.REACT_APP_OFFLINE_PROD_UCP_PAYMENTS_ROOT || 'https://offline.medicare.uhc.com/UCPPayments',
	REACT_APP_OFFLINE_PROD_UCP_PROFILE_PREFERENCES_ROOT:
		process.env.REACT_APP_OFFLINE_PROD_UCP_PROFILE_PREFERENCES_ROOT || 'https://offline.medicare.uhc.com/UCPProfileAndPreferences',
	REACT_APP_OFFLINE_PROD_UCP_USER_MANAGEMENT_ROOT:
		process.env.REACT_APP_OFFLINE_PROD_UCP_USER_MANAGEMENT_ROOT || 'https://offline.medicare.uhc.com/UCPUserManagement',
	REACT_APP_OFFLINE_PROD_UCP_MEMBER_AUTH_ROOT:
		process.env.REACT_APP_OFFLINE_PROD_UCP_MEMBER_AUTH_ROOT || 'https://offline.medicare.uhc.com/medicare/UCPSSOMemberAuth',
	REACT_APP_UAT_AEM_ROOT: process.env.REACT_APP_UAT_AEM_ROOT || 'https://medicare.uat.werally.in',
	REACT_APP_UAT_UCP_PAYMENTS_ROOT:
		process.env.REACT_APP_UAT_UCP_PAYMENTS_ROOT || 'https://medicare.uat.werally.in/UCPPayments',
	REACT_APP_UAT_UCP_PROFILE_PREFERENCES_ROOT:
		process.env.REACT_APP_UAT_UCP_PROFILE_PREFERENCES_ROOT || 'https://medicare.uat.werally.in/UCPProfileAndPreferences',
	REACT_APP_UAT_UCP_USER_MANAGEMENT_ROOT:
		process.env.REACT_APP_UAT_UCP_USER_MANAGEMENT_ROOT || 'https://medicare.uat.werally.in/UCPUserManagement',
	REACT_APP_UAT_UCP_MEMBER_AUTH_ROOT:
		process.env.REACT_APP_UAT_UCP_MEMBER_AUTH_ROOT || 'https://medicare.uat.werally.in/medicare/UCPSSOMemberAuth',
	REACT_APP_MEMBER_UAT_AEM_ROOT: process.env.REACT_APP_MEMBER_UAT_AEM_ROOT || 'https://member.uat.uhc.com',
	REACT_APP_MEMBER_UAT_AEM_PREVIEW_ROOT: process.env.REACT_APP_MEMBER_UAT_AEM_PREVIEW_ROOT || 'https://member.uat.uhc.com/medicare-preview',
	REACT_APP_MEMBER_UAT_AEM_GREEN_ROOT: process.env.REACT_APP_MEMBER_UAT_AEM_GREEN_ROOT || 'https://member.uat.uhc.com/medicare-green',
	REACT_APP_MEMBER_UAT_UCP_PAYMENTS_ROOT:
		process.env.REACT_APP_MEMBER_UAT_UCP_PAYMENTS_ROOT || 'https://member.uat.uhc.com/medicare/UCPPayments',
	REACT_APP_MEMBER_UAT_UCP_PROFILE_PREFERENCES_ROOT:
		process.env.REACT_APP_MEMBER_UAT_UCP_PROFILE_PREFERENCES_ROOT || 'https://member.uat.uhc.com/medicare/UCPProfileAndPreferences',
	REACT_APP_MEMBER_UAT_UCP_USER_MANAGEMENT_ROOT:
		process.env.REACT_APP_MEMBER_UAT_UCP_USER_MANAGEMENT_ROOT || 'https://member.uat.uhc.com/medicare/UCPUserManagement',
	REACT_APP_MEMBER_UAT_UCP_MEMBER_AUTH_ROOT:
		process.env.REACT_APP_MEMBER_UAT_UCP_MEMBER_AUTH_ROOT || 'https://member.uat.uhc.com/medicare/medicare/UCPSSOMemberAuth',
	UPG_STAGE_PAYMENT_REDIRECTION_URL:
		process.env.UPG_STAGE_PAYMENT_REDIRECTION_URL || 'https://uhgpghpp-stg.optum.com/gates/redirects/',
	UPG_PROD_PAYMENT_REDIRECTION_URL:
		process.env.UPG_PROD_PAYMENT_REDIRECTION_URL || 'https://uhgpghpp.optum.com/gates/redirects/',	
	CANARY_STATUS: process.env.CANARY_STATUS || "always",
	VERSION_PATH: process.env.VERSION_PATH || '/usr/src/app/',
};

const errors = Object.entries(config)
	.filter(([, value]) => value === null || value === undefined)
	.map(([key]) => key);

if (errors.length) {
	throw new Error(`exiting as required environment variable based config keys are missing: [${errors.join(', ')}]`);
}

module.exports = config;
