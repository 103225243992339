
import axios from './ucpPayments-api';
import appConfigs from '../app.config';

export const mfaLoggerInfo = async (message,obj) => {
    var mfaLoggingRequest = {
        "message": message,
        "logObject" :obj,
    };

	return axios.post(appConfigs.mfa_logging_info_service_path , 
        mfaLoggingRequest).then(response =>{
				return response;
		})
		.catch((error) => {
            console.log(error.message);
            return {
                callFailed: true
            };
		});

};