import React, { useEffect } from "react";
import HeimdallApi from "../src/api/heimdallApi"
import AccessTokenApi from "../src/api/accessTokenApi";
import PaymentApp from "./PaymentApp";


const SessionValidation = () => {
    const hostName = window.location.origin;
    const consumerDetails = JSON.parse(localStorage.getItem('consumerDetails'));

    if(consumerDetails !== null && consumerDetails.displayBrandName === "AARP"){
        document.title = "AARP Medicare Plans from UnitedHealthCare - Premium Payments";
    }

    useEffect(() => {
       
        if (hostName.includes("member.") || hostName.includes("medicare.") || hostName.includes("mymedicareaccount.")) {
            let oidcSessionCookieVal = getCookie("OIDCSESSION");

            function getCookie(cname) {
                let decodedCookie = document.cookie.split('; ').find(row => row.startsWith(cname));
                let decodedCookieValue = (decodedCookie == null || undefined) ? "" : document.cookie.split('; ').find(row => row.startsWith(cname)).split('=')[1];
                if (decodedCookie === "" || decodedCookie === null || decodedCookie === undefined || decodedCookie === 'null') {
                    return "";
                }
                if (decodedCookieValue === "" || decodedCookieValue === null || decodedCookieValue === undefined || decodedCookieValue === 'null') {
                    return "";
                } else {
                    return decodedCookieValue;
                }
            }

            if (oidcSessionCookieVal === "" || oidcSessionCookieVal == null || oidcSessionCookieVal === undefined || oidcSessionCookieVal === 'null' || oidcSessionCookieVal === '""') {
                if (!(hostName.includes("/member/logout"))) {
                    window.location.href = "/medicare/content/medicare/member/logout.html";
                }
            }

            let accessToken = localStorage.getItem("accessToken");
            function isNotNull(varname) {
                return (varname !== null && varname !== undefined && varname !== "" && varname !== 'null');
            }
            if (!isNotNull(accessToken)) {
                accessToken = getCookie("ValidatingSession");
            }


            if (!(hostName.includes("/member/logout"))) {
                const heimdall = HeimdallApi()
                heimdall.then((res) => {
                    if (res.data && (res.data.active === true)) {
                        console.log('heimdall session is available. Skipping accessToken Call');
                    } else if(isNotNull(accessToken)){
                        const accessTokenApi = AccessTokenApi(accessToken)
                        accessTokenApi.then((res) => {
                            if (consumerDetails.userName !== res.data.sub) {
                                if (!(hostName.includes("/member/logout") > -1)) {
                                    window.location.href = "/medicare/content/medicare/member/logout.html";
                                }
                            }
                        }).catch((error) => {
                            if (!(hostName.includes("/member/logout"))) {
                                window.location.href = "/medicare/content/medicare/member/logout.html";
                            }
                        });
                    } else {
                        if (!(hostName.includes("/member/logout"))) {
                            window.location.href = "/medicare/content/medicare/member/logout.html";
                        } 
                    }
                }).catch((error) => {
                    console.log('heimdall error.Calling AccessToken API');
                    const accessTokenApi = AccessTokenApi(accessToken)
                    accessTokenApi.then((res) => {
                        if (consumerDetails.userName !== res.data.sub) {

                            if (!(hostName.includes("/member/logout"))) {
                                window.location.href = "/medicare/content/medicare/member/logout.html";
                            }
                        }
                    }).catch((error) => {
                        if (!(hostName.includes("/member/logout"))) {
                            window.location.href = "/medicare/content/medicare/member/logout.html";
                        }
                    });
                });
            }
        }
    }, [consumerDetails.userName, hostName]);
    return (
        <>
            {/* <div id="global-header"></div> */}
                <PaymentApp></PaymentApp>
            {/* <div id="global-footer"></div> */}
        </>

    );
};

export default SessionValidation;