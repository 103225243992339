import React, { Component } from 'react'
import './PaymentForm.css'
import { Typography, RadioButton, FormGroup } from '@uhc-tempo/components'
import { manageWalletFormAem } from '../Wallet/ManageWallet'

class FedRadioBtn extends Component {
  render() {
    return (
      <>
      <div className="eftPaymentMethod">
        <Typography.H4 id="new_method">{manageWalletFormAem.auth_mw_choose_new_method}</Typography.H4>
        <FormGroup alignment="vertical" className="radioBtnGroup" aria-labelledby="new_method" groupName="paymentMethod" hideInstruction={false} state="default" >
                <legend className="sr-only">{manageWalletFormAem.auth_mw_choose_new_method}</legend>
                            <RadioButton
                                //defaultChecked
                                id="eftCheckingId"
                                value="checking"
                                checked={this.props.paymentMethod === 'checking'}
                                onChange={this.props.handlePaymentMethodSelection}
                                label={manageWalletFormAem.auth_mw_eft_checking_radio_lbl}
                            />
                            <RadioButton
                                id="eftSavingsId"
                                value="savings"
                                checked={this.props.paymentMethod === 'savings'}
                                onChange={this.props.handlePaymentMethodSelection}
                                label={manageWalletFormAem.auth_mw_eft_savings_radio_lbl}
                            />
                            <RadioButton
                                id="ccAmountId"
                                value="CC"
                                label={manageWalletFormAem.auth_mw_cr_debit_radio_lbl}
                                checked={this.props.paymentMethod === 'CC'}
                                onChange={this.props.handlePaymentMethodSelection}
                            />
            </FormGroup>
        </div>
        
      </>
    )
  }
}

export default FedRadioBtn
