import React, { Component } from 'react';
import { Button, BrandIcon } from '@uhc-tempo/components'
import { withRouter  } from 'react-router-dom';
import { trackPageLoad, trackLinkClick } from '../../../server/util/analytics'
import { getOneTimePaymentFlag, getOneTimePageLink, getRecurringPageLink, getOverviewPageLink } from '../../../server/util/localStorageRepo';
import { Typography } from '@uhc-tempo/components';
import { IconAlert } from "@uhc-tempo/icons";
import secureLocalStorage from 'react-secure-storage';
import {overviewSummAem} from '../paymentOverview/paymentOverview';


class ServiceError extends Component {
  constructor(props) {
    super(props)
    this.state = {
      description: '',
      buttonText: '',
    }
  }
  currentPage='';
  redirectHandler = () => {
   this.renderRedirect()
  }

  renderRedirect = () => {
    trackLinkClick('close window');
    window.open("about:blank", "_self");
    window.close();
  }

  redirectHomeHandler = () => {
    if(this.currentPage === 'form' || this.currentPage === 'fedForm'){
      trackLinkClick('Return To payment form');
      this.renderHomeRedirect();
    } else if(this.currentPage === 'wallet') {
      if(secureLocalStorage.getItem('walletPageError')) {
        let errorCode = secureLocalStorage.getItem('walletPageError')
        if(errorCode == '500') {
          window.top.location.assign(getOverviewPageLink()+'/#/manage-wallet')
        } else {
          window.top.location.assign(getOverviewPageLink())
        }

      }
    }
    else{
      trackLinkClick('Return To Home');
      window.location.assign(getOverviewPageLink())
    }
  }

   renderHomeRedirect = () => {
    window.location.assign(getOneTimePaymentFlag() ? getOneTimePageLink() : getRecurringPageLink());
  }

  componentDidUpdate(prevProps) {
    if (this.props.isbCustomerNotFoundError !== prevProps.isbCustomerNotFoundError) {
        this.setState({
          description: this.props.isbCustomerNotFoundError ? overviewSummAem.isbCustomerNotFoundErrorMessage : "Sorry, we're experiencing some technical difficulties. Please refresh now or try again later."
        });
    }
}

  componentDidMount() {
    trackPageLoad('service error')
    if (!this.props.insidePayHistTable){
    window.scrollTo(0, 0);
    }
    this.currentPage=secureLocalStorage.getItem('currPage');
    this.setState({
      description: this.currentPage === 'overview' ? "Sorry, we're experiencing some technical difficulties. Please refresh now or try again later." :
      (this.currentPage === 'review' || this.currentPage === 'confirm') ? "Sorry, the page you requested is not available." : "Sorry, we're experiencing some technical difficulties." ,
      buttonText: (this.currentPage === 'overview' || this.currentPage === 'fedForm') ? "Refresh" : (this.currentPage === 'review' || this.currentPage === 'confirm') ? "Go to payments overview": "Try Again"
    })
    if(this.currentPage === 'wallet') {
      if(secureLocalStorage.getItem('walletPageError')) {
        let errorCode = secureLocalStorage.getItem('walletPageError')
        this.setState({buttonText : errorCode == '500' ? "Refresh" : "Go to payments overview"})
        if(errorCode == '404') {
          this.setState({
            description: "No wallet available. For help, call Customer Service at the number listed on the Contact Us web page."})
        }
      }
    }

  }

  render() {
    return (
      
      <div>
        {this.currentPage === 'review' || this.currentPage === 'confirm' ?
       <div className="overviewcontainer">
          <div className="serviceErrorPageHeader">
          <Typography.H1>Premium Payments</Typography.H1>
          </div>
        </div> :null}
       <div className={this.currentPage === 'review' || this.currentPage === 'confirm' ? "overviewcontainer planContainer": "error-page"} id="service_Error" tabIndex="0"> 
       <div className={this.currentPage === 'review' || this.currentPage === 'confirm' ? "serviceErrorSubContainer": ""}>
       <div className={(this.props.isbBillingError || this.props.searchPaymentHistoryError || this.props.isbInvoiceError || this.props.isbPremiumDetailsError) ? "isbContainer" : "container"}>
         <div className="row justify-content-center">
           <div className="text-center">
             <div className="content-center">
             <BrandIcon
              icon={IconAlert}
              size="SMALL"
              styleVariation="TWO_TONE_LIGHT_CIRCLE"
            />
              <Typography.H2 className="tds-header__h1 tds--highimpact ml-3">Uh oh!</Typography.H2>
             </div>
              <Typography.Paragraph>{this.state.description}</Typography.Paragraph>
           </div>
         </div>
       </div>
       <div className={(this.props.isbBillingError || this.props.searchPaymentHistoryError || this.props.isbInvoiceError || this.props.isbPremiumDetailsError) ? "isbContainer" : "container mt-5"} style={{ textAlign: 'center' }}>
         <div className="row" style={{ marginBottom: '20px' }}>
           <div className="col-12 justify-content-center align-items-center">
              {!this.props.isbCustomerNotFoundError && localStorage.getItem('currPage') === 'overview' ? (
                <button onClick={this.redirectHomeHandler} id="serviceError_0" onKeyDown={this.props.serviceErrorKey} className="tds-button tds-button--primary-one" aria-disabled="false" style={{display: 'flex', margin: '0px auto'}}>
                <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" focusable="false" aria-hidden="true">
                 <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                 <g id="Full-icon-library-(all-24px)/Navigation/ic_refresh" fill="#FFFFFF">
                 <path d="M17.65,6.35 C16.2,4.9 14.21,4 12,4 C7.58,4 4.01,7.58 4.01,12 C4.01,16.42 7.58,20 12,20 C15.73,20 18.84,17.45 19.73,14 L17.65,14 C16.83,16.33 14.61,18 12,18 C8.69,18 6,15.31 6,12 C6,8.69 8.69,6 12,6 C13.66,6 15.14,6.69 16.22,7.78 L13,11 L20,11 L20,4 L17.65,6.35 L17.65,6.35 Z" id="🎨Icon-Color"></path>
                 </g>
                 </g>
                 </svg>
                 <span>{this.state.buttonText}</span>
   
                </button>
             ): !this.props.isbCustomerNotFoundError ? (
                <Button
                text={this.state.buttonText}
                buttonType="primary-one"
                onClick={this.redirectHomeHandler}
                className="tds-margin-xsm-horizontal tds-button tds-button--primary-one tds-margin-xsm-horizontal"
              />
             ) : null}
              {/* {this.renderHomeRedirect} */}
           </div>
         </div>
       </div>
       </div>
      </div>
      </div>
   )
  }
}

export default withRouter(ServiceError)
