import React, { Component } from 'react';
import commonConstants from '../../../server/util/commonConstants'
import Spinner from '../../UI/Spinner/Spinner'
import { getOverviewPageLink,getOneTimePaymentFlag, getRecurringPaymentFlag } from '../../../server/util/localStorageRepo';
import secureLocalStorage from 'react-secure-storage';


class CCRedirectGPS extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
    if (window.location && window.location.href) {
      let queryParams = window.location.href.split('?')[1]
      if (window.location.href.indexOf(commonConstants.TOKENIZATION_RESPONSE_TYPE_TOKENIZATION) > -1) {
        let ccpathname= window.location.pathname.replace("/cc-iframe","");
        let pathNameLastChar = (ccpathname && ccpathname.length > 0 ? ccpathname.charAt(ccpathname.length - 1) : "");
        window.top.location.assign(window.location.origin + ccpathname + (pathNameLastChar === "/" ? "" : "") + ((secureLocalStorage.getItem('currPage') &&  secureLocalStorage.getItem('currPage') == 'wallet') ? '#/cc-success?' : '#/success-payments?') + queryParams)
      } else {
        let page = secureLocalStorage.getItem("currPage") === 'wallet' ? "#/manage-wallet" : (secureLocalStorage.getItem("currPage") === 'fedForm' && getOneTimePaymentFlag()) ? "#/federal-make-payment" : (secureLocalStorage.getItem("currPage") === 'form' && getOneTimePaymentFlag()) ? "#/make-payment" :
                    (secureLocalStorage.getItem("currPage") === 'fedForm' && getRecurringPaymentFlag()) ? "#/federal-recurring" : ""
        window.top.location.assign(getOverviewPageLink()+ page)
      }
    }
  }

  render() {
    return (<Spinner/>)
  }
}

export default CCRedirectGPS
