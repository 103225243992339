/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from 'react'
import Input from '../../UI/Input/Input'
import { serverCurrentTime } from '../../../server/api/serverTime-api'
import Moment from 'moment'
import { Typography, RadioButton, FormGroup, Notification, Form } from '@uhc-tempo/components'
import { Grid, Form as EFTForm} from '@uhc/pattern-library'
import { Redirect, withRouter } from 'react-router-dom'
import './PaymentForm.css'
import PaymentModals from '../modals/PaymentModals'
import ModalPopup from '../modalPopUps/modalPopup'
import commonConstants from '../../../server/util/commonConstants'
import { Markup } from 'interweave'
import { isAndroid } from '../shared-components/detectDevice'
import { trackPageLoad, trackFormSubmit, trackModalWindowClick, trackModalWindowEarlyWarnings, trackLinkClickEarlyWarnings, trackLinkClick, trackError, trackImpression } from '../../../server/util/analytics'
import axios, { content_path } from '../../../server/api/aem-api'
import SessionExpireModal from "../modals/SessionExpireModal";
import { accountValidationWrapper } from '../../../server/api/bank-account-validation-wrapper-api';
import EarlyWarning from '../modals/EarlyWarning'
import EarlyWarningZero from '../modals/EarlyWarningZero'
import Spinner from '../../../src/UI/Spinner/Spinner';
import PageHeader from '../page-header/PageHeader'
import PaymentSummary from '../summary/PaymentSummary.js';
import PaymentAmt from './paymentAmount';
import {
  getPlanInformation, getOneTimePaymentFlag, getRecurringPaymentFlag, getMemberID,
  getOverviewPageLink, isManageRecurring, getProductType, getSearchPaymentMethodResult, getPaymentMethod, getIsbPLanInformation
} from '../../../server/util/localStorageRepo';
import { isDesktop } from '../../../server/util/util'
import { convertToCurrency } from '../../../server/util/util'
import moment from 'moment'
import CreditCardGPS from '../CreditCard/CreditCardGPS'
import appConfigs from '../../../server/app.config'
import PageError from './PageError'
import { getCustomerAccountSummaryAPI } from '../../api/customerAccountSummary.js'
import secureLocalStorage from 'react-secure-storage'



class PaymentForm extends Component {
  constructor(props) {
    super(props)
    let payAmountPlaceHolder = '$__.__'
    this.firstNameRef = React.createRef()
    this.midNameRef = React.createRef()
    this.lastNameRef = React.createRef()
    this.accountNumberRef = React.createRef()
    this.routingNumberRef = React.createRef()
    this.otherAmountRef = React.createRef()
    this.topErrorRef = React.createRef()
    this.modalRef = React.createRef()
    this.setAuthIndicator = this.setAuthIndicator.bind(this);

    this.isDesktop = isDesktop();

    let eftSection = {
      header: "",
      fst_nme_lbl: "",
      fst_nme_inst_txt: "",
      mdl_nme_lbl: "",
      mdl_nme_inst_txt: "",
      lst_nme_lbl: "",
      lst_nme_inst_txt: "",
      routing_lbl: "",
      acct_lbl: "",
      notes: "",
      button: "",
      help_label_txt: "",
      cancel_btn_txt: "",
      eft_text_label: "",
    }

    let routingModal = {
      header: "",
      desc: ""
    }



    this.state = {
      memberState: JSON.parse(localStorage.getItem('consumerDetails'))?.profileAddressStateCode,
      isServiceError: false,
      defaultPaidAmoutPlaceHolder: payAmountPlaceHolder,
      firstName: '',
      lastName: '',
      fullName: '',
      memberId: '',
      planName: '',
      payAmount: payAmountPlaceHolder,
      serverDate: '',
      otherAmount: '',
      otherAmountDisplay: '',
      paymentMethod: '',
      requestId: '',
      bankHolderFirstName: '',
      bankHolderMiddleName: '',
      bankHolderLastName: '',
      routingNumber: '',
      accountNumber: '',
      header_section_1: '',
      header_section_2: '',
      currentModal: '',
      pastAmountDue: '',
      pastAmountAndCurentCharges: '',
      currentCharges: '',
      header_section_2a: '',
      currentPaymentDate: '',
      firstDayOfMonth: '',
      amountsExplainedModal: {},
      elecTransfertModal: {},
      earlyWarningModal: {},
      messages: {},
      oldAccountNumber: "",
      oldRoutingNumber: "",
      attemptCounter: commonConstants.ATTEMPT_COUNTER,
      previousEftAccountDetails: [],
      eftSection: eftSection,
      routingModal: routingModal,
      productType: getProductType(),
      hTitle: "",
      hLinks: [{}],
      noOptnManageRecErr: '',
      recurringCancelledErr: '',
      errFields: [],
      // page error data below
      otherAmountError: '',
      bankHolderFirstNameError: false,
      bankHolderMiddleNameError: false,
      bankHolderLastNameError: false,
      routingNumberError: false,
      accountNumberError: false,
      errorMessage: '',
      amtselectcss: 'default',
      // conditional flag based
      formValidated: false,
      eftDivDisabled: true,
      onload: true,
      isOpen: false,
      shouldCloseOnOverlayClick: true,
      pastAmountDueChecked: false,
      pastAmountAndCurentChecked: false,
      onlyCurrentChargesChecked: false,
      otherAmountChecked: false,
      payInFullChecked: false,
      newEftRecChecked: false,
      newCCRecChecked: false,
      cancelRecChecked: false,
      updatecalled: false,
      loading: true,
      cMSSSAFound: false,
      alert: false,
      focusOnModal: false,
      pageLoadViaFlow: false,
      etfPaymentError: false,
      eftButtonHide: false,
      isDefaultEftPaymentSelectionAvailable: false,
      isEarlyWarningZeroModal: false,
      showFrameClass: '',
      pastAmountDueFound: '',
      pastAmountAndCurentChargesFound: '',
      onlyCurrentChargesFound: '',
      oneTimePaymentMethods: null,
      lastUsedEft: null,
      showChoosePaymentOptn: false,
      show_header_section_2a: false,
      otherAmountErrorDisp: false,
      changeCC: false,
      changeCCClicked: false,
      payWithSavedCC: false,
      paymentHistoryCallCompleted: false,
      showErrorBanner: true,
      eftNumber: (this.props.location.state && this.props.location.state.fromPage === 'overview') ? this.props.location.state.eftNumber : '',
      paymentsMethodValue: (this.props.location.state && this.props.location.state.fromPage === 'overview') ? this.props.location.state.paymentsMethodValue : getPaymentMethod(),
      wrapperPojo: (this.props.location.state && this.props.location.state.fromPage === 'overview') ? this.props.location.state.wrapperPojo : null,
      monthlyPaymentPremium: (this.props.location.state && this.props.location.state.fromPage === 'overview') ? this.props.location.state.monthlyPaymentPremium : '',
      totalAmountDue: (this.props.location.state) ? this.props.location.state.totalAmountDue : '',
      authIndicator : this.props.isEditForm ? this.props.bankAccount.authInd : '',
    }
  }

  // write Modal code here START
  configureModal = () => {
    return (
    <div>
      <ModalPopup
        isOpen={this.state.isOpen}
        onRequestClose={this.modalClick}
        arialabelled="ModalId"
        shouldCloseOnOverlayClick={this.state.shouldCloseOnOverlayClick}
        nachaErr={true}
      >
        <div>
          {{
            'EarlyWarning': <EarlyWarning click={this.modalClick} content={this.state.earlyWarningModal} />,
            'EarlyWarningZero': <EarlyWarningZero earlyWarningAnotherPaymentClick={this.earlyWarningAnotherPaymentClick} earlyWarningCancelClick={this.earlyWarningCancelClick} content={this.state.earlyWarningModal} cancelPaymentModal={this.state.cancelPaymentModal} />
          }[this.state.currentModal]}

        </div>
      </ModalPopup>
    </div>)
  }

  displayModal = (modalToShow) => {
    trackModalWindowClick(getOneTimePaymentFlag() ? 'make a one time payment' : isManageRecurring() ? 'manage recurring  payment' :
      'set up recurring payment', 'Amounts Explained');
    trackLinkClick('Amounts Explained');
    this.setState({
      currentModal: modalToShow,
      isOpen: true,
      focusOnModal: true,
      shouldCloseOnOverlayClick: true
    })
  }

  displayEftModal = (modalToShow) => {
    //  this.firstNameRef.focus();
    this.scrollTo(this.firstNameRef, 'bank holders first name');
    trackModalWindowEarlyWarnings(getOneTimePaymentFlag() ? 'make a one time payment' : isManageRecurring() ? 'manage recurring  payment' :
      'set up recurring payment', 'early warning error');
    trackLinkClickEarlyWarnings('Early Warning');

    this.setState({
      currentModal: modalToShow,
      isOpen: true,
      focusOnModal: true,
      eftButtonHide: modalToShow === 'EarlyWarningZero' ? true : false,
      shouldCloseOnOverlayClick: modalToShow === 'EarlyWarningZero' ? false : true
    })
  }
  modalClick = () => {
    this.setState({ isOpen: !this.state.isOpen, shouldCloseOnOverlayClick: true })
  }
  earlyWarningAnotherPaymentClick = () => {
    this.scrollTo(this.firstNameRef, 'bank holders first name');
    trackLinkClick('Early Warning Another Payment');
    this.setState({
      isOpen: !this.state.isOpen,
      bankHolderFirstNameError: false,
      bankHolderMiddleNameError: false,
      bankHolderLastNameError: false,
      accountNumberError: false,
      routingNumberError: false,
      bankHolderFirstName: '',
      bankHolderMiddleName: '',
      bankHolderLastName: '',
      routingNumber: '',
      accountNumber: '',
      errorMessage: '',
      loading: false,
      //showFrameClass: 'show',
      etfPaymentError: false,
      attemptCounter: commonConstants.ATTEMPT_COUNTER,
      eftButtonHide: false,
      oldAccountNumber: "",
      oldRoutingNumber: "",
      shouldCloseOnOverlayClick: true,
      isEarlyWarningZeroModal: false
    })
  }

  earlyWarningCancelClick = () => {
    trackLinkClick('Early Warning Cancel Payment');
    this.setState({ isOpen: !this.state.isOpen, shouldCloseOnOverlayClick: true });
    window.location.assign(getOverviewPageLink());
  }

  modalFocus = () => {
    if (null != this.modalRef.current) {
      this.modalRef.current.focus()
    }
  }
  // write Modal code here END

  setAuthIndicator = (authIndicator) => {
    this.setState({ authIndicator: authIndicator });
  }

  checkLastUsedEFT = () => {
    let foundLastUsedEft = null;
    let lastYearDate = moment().subtract(1, 'year');
    let isbLastUsedEftPayments=secureLocalStorage.getItem('isbLastUsedEftPayments')? JSON.parse(secureLocalStorage.getItem('isbLastUsedEftPayments')): '';
    //Filter payment methods that are within a year old
    if (this.props.location?.state && this.props.location?.state?.fromPage !== 'overview') {
      foundLastUsedEft = this.props.location?.state?.lastUsedEft;
      console.log(foundLastUsedEft);
    } else if(this.state.productType === 'SHIP' && getOneTimePaymentFlag() && isbLastUsedEftPayments && (this.props.location?.state?.fromPage === 'overview' || !this.props.location?.state)){
      foundLastUsedEft=isbLastUsedEftPayments;
    }else if(this.state.oneTimePaymentMethods && (this.props.location?.state?.fromPage === 'overview' || !this.props.location?.state)) {
        foundLastUsedEft = this.state.oneTimePaymentMethods?.filter((payment) => {
            if (moment(payment.paymentDate).isAfter(lastYearDate)) {
              return payment;
            }
            console.log(foundLastUsedEft);
          })
      }
      //If last used EFT exists, sort by most recent to oldest
      if ((foundLastUsedEft && foundLastUsedEft.length > 0 && (this.props.location?.state?.fromPage === 'overview' || !this.props.location?.state)) || (this.props.location?.state?.fromPage !== 'overview' && foundLastUsedEft)) {
        console.log('last used found')
        console.log('payments-method:EFT Checking') 
        trackImpression('payments-method:EFT Checking');
        if(this.state.productType === 'SHIP'){
          (this.props.location?.state?.fromPage === 'overview' || !this.props.location?.state) && foundLastUsedEft.sort((a,b) => moment(b.receivedDate) - moment(a.receivedDate))

          this.setState({ 
            lastUsedEft: (this.props.location?.state?.fromPage === 'overview' || !this.props.location?.state) ? foundLastUsedEft[0] : foundLastUsedEft,
      
          }, () => {
              this.setState({ 
                showChoosePaymentOptn: true, 
                show_header_section_2a: true,
                header_section_2a:(getOneTimePaymentFlag() && foundLastUsedEft) && '2. ' + this.state.choose_payment_method_title,
                paymentMethod: this.props.location?.state && this.props.location?.state?.fromPage !== 'overview'? this.props.location?.state?.paymentMethod : 'EFT-lastUsed',
                accountNumber: this.props.location?.state && this.props.location?.state?.fromPage !== 'overview' ? this.props.location?.state?.accountNumber : this.state.lastUsedEft?.bankAccountNumber,
                routingNumber: this.props.location?.state && this.props.location?.state?.fromPage !== 'overview' ? this.props.location?.state?.routingNumber : this.state.lastUsedEft?.bankRoutingNumber,
              });
          })
        }
      } else if (!getOneTimePaymentFlag() && getRecurringPaymentFlag() && isManageRecurring()) { // manage recurring
        if (this.state.productType === 'SHIP') {
            this.setState({showChoosePaymentOptn: false, show_header_section_2a: false });
        } 
      }else {
        if(!this.state.newEftRecChecked && !this.state.cancelRecChecked){
          this.setState({ paymentMethod: (this.props.location && this.props.location.state && this.props.location?.state?.paymentMethod && this.props.location?.state?.fromPage !== 'overview') ? this.props.location?.state?.paymentMethod : 'EFT', showChoosePaymentOptn: true, show_header_section_2a: true })
        } 
          console.log('payments-method:New EFT Checking') 
          trackImpression('payments-method:New EFT Checking');
      }
  }

  hydrateStateWithLocalStorage = () => {
    let isRedirect = this.props.location?.state && this.props.location?.state?.fromPage !== 'overview'? true : false
    this.setState({
      pastAmountDueFound: secureLocalStorage.getItem('pastAmountDueFound'),
      pastAmountAndCurentChargesFound: secureLocalStorage.getItem('pastAmountAndCurentChargesFound'),
      onlyCurrentChargesFound: secureLocalStorage.getItem('onlyCurrentChargesFound'),
      pastAmountDue: secureLocalStorage.getItem('pastAmountDue'),
      pastAmountAndCurentCharges: secureLocalStorage.getItem('pastAmountAndCurentCharges'),
      currentCharges: secureLocalStorage.getItem('currentCharges'),
      currentPaymentDate: secureLocalStorage.getItem('currentPaymentDate'),
      firstDayOfMonth: secureLocalStorage.getItem('firstDayOfMonth'),
      superUser: localStorage.getItem("superUser") === "true" ? true : false,
    }, () => {
      if (!isRedirect) {
        if (this.state.pastAmountDueFound) {
          this.setState({ pastAmountDueChecked: true, payAmount: '$' + convertToCurrency(this.state.pastAmountDue)})
          secureLocalStorage.setItem('payAmount', '$' + convertToCurrency(this.state.pastAmountDue))
          secureLocalStorage.setItem('pastAmountDueChecked', true)
        } else if (this.state.pastAmountAndCurentChargesFound) {
          this.setState({ pastAmountAndCurentChecked: true, payAmount: '$' + convertToCurrency(this.state.pastAmountAndCurentCharges)})
          secureLocalStorage.setItem('payAmount', '$' + convertToCurrency(this.state.pastAmountAndCurentCharges))
          secureLocalStorage.setItem('pastAmountAndCurentChecked', true)
        } else if (this.state.onlyCurrentChargesFound) {
          this.setState({ onlyCurrentChargesChecked: true, payAmount: '$' + convertToCurrency(this.state.currentCharges)})
          secureLocalStorage.setItem('payAmount', '$' + convertToCurrency(this.state.currentCharges))
          secureLocalStorage.setItem('onlyCurrentChargesChecked', true)
        } else if (secureLocalStorage.getItem('estimatedAnnualPremium') > 0) {
          this.setState({ payInFullChecked: true, payAmount: '$' + convertToCurrency(secureLocalStorage.getItem('estimatedAnnualPremium'))})
          secureLocalStorage.setItem('payAmount', '$' + convertToCurrency(secureLocalStorage.getItem('estimatedAnnualPremium')))
          secureLocalStorage.setItem('payInFullChecked', true)
        } else if (getOneTimePaymentFlag()) {
          this.setState({ otherAmountChecked: true }, () => {
          if(this.state.otherAmountChecked){
            this.scrollTo(this.otherAmountRef.current, 'Other Amount')
          }})
          secureLocalStorage.setItem('otherAmountChecked', true)
        }
      }
    })
    if (secureLocalStorage.getItem('estimatedAnnualPremium') != null) {
      this.setState({ estimatedAnnualPremium: parseFloat(secureLocalStorage.getItem('estimatedAnnualPremium')) });
    }
    if (!getOneTimePaymentFlag() && getRecurringPaymentFlag() && !isManageRecurring()) { // setup recurring
      if (this.state.productType === 'SHIP') {
          this.setState({ paymentMethod: 'EFT', showChoosePaymentOptn: false, show_header_section_2a: true });
      }
    } else if (!getOneTimePaymentFlag() && getRecurringPaymentFlag() && isManageRecurring()) { // manage recurring
      if (this.state.productType === 'SHIP') {
          this.setState({ paymentMethod: isRedirect ? this.props.location.state.paymentMethod : '', showChoosePaymentOptn: false, show_header_section_2a: false });
      }
    } else if (getOneTimePaymentFlag() && !getRecurringPaymentFlag()) { // one time
      if (this.state.productType === 'SHIP') {
        console.log('Checking last used eft in hydrate')
        this.checkLastUsedEFT();
      }
    }
  }

  componentDidUpdate() {
    if (this.state.alert) {
      this.changeFocus()

      this.setState({
        alert: false,
      })
    }
    if (this.state.focusOnModal) {
      this.modalFocus()

      this.setState({
        focusOnModal: false,
      })
    }
  }

  callPaymentHistory() {
    if (getProductType() === 'SHIP' && getRecurringPaymentFlag() && !isManageRecurring()) {
      this.setState({ paymentHistoryCallCompleted: true, loading: false });
      this.hydrateStateWithLocalStorage();
    } else if(getIsbPLanInformation().productType === 'SHIP') {
        getCustomerAccountSummaryAPI(getIsbPLanInformation().individualId).then((response) => {

      if (!response.callFailed) {
          this.setState({ paymentHistoryCallCompleted: true, loading: false });
          this.hydrateStateWithLocalStorage();
      }else {
        this.setState({ isServiceError: true });
      }
    })
  }}

  componentDidMount() {
    // eslint-disable-next-line no-unused-vars
    let isPageReloaded = true;
    
    secureLocalStorage.setItem('isPaymentRedesignPage', false);
    secureLocalStorage.setItem('currPage', 'form');
    window.scrollTo(0, 0)
    if ((this.props && this.props.location && this.props.location.state?.fromPage === 'overview') || !this.props.location?.state) { 
      this.callPaymentHistory();
    } else {
      this.hydrateStateWithLocalStorage()
    }

    if (this.props.location?.state?.fromPage === 'overview' || !this.props.location?.state) {
      console.log('First time load')
      this.checkLastUsedEFT();
    }

    //start aem calls
    if (this.props.location?.state && this.props.location?.state?.fromPage !== 'overview') {
      if (this.props.location.state.isDefaultEftPaymentSelectionAvailable) {
        this.setState({
          isDefaultEftPaymentSelectionAvailable: true
        })
      } else {
        this.setState({
          paymentMethod: this.props.location.state.paymentMethod,
          lastUsedEft: this.props.location.state.lastUsedEft,
          payAmount: this.props.location.state.paidAmount ? this.props.location.state.payAmount : this.state.defaultPaidAmoutPlaceHolder,
          pastAmountDueChecked: this.props.location.state.pastAmountDueChecked,
          pastAmountAndCurentChecked: this.props.location.state.pastAmountAndCurentChecked,
          onlyCurrentChargesChecked: this.props.location.state.onlyCurrentChargesChecked,
          payInFullChecked: this.props.location.state.payInFullChecked,
          otherAmountChecked: this.props.location.state.otherAmountChecked,
          bankHolderFirstName: this.props.location.state.bankHolderFirstName,
          bankHolderMiddleName: this.props.location.state.bankHolderMiddleName,
          bankHolderLastName: this.props.location.state.bankHolderLastName,
          routingNumber: this.props.location.state.routingNumber,
          accountNumber: this.props.location.state.accountNumber,
          cancelRecChecked: this.props.location.state.cancelRecChecked,
          newEftRecChecked: this.props.location.state.newEftRecChecked,
          ccFlow: this.props.location.state.ccFlow,
          payWithSavedCC: this.props.location.state.payWithSavedCC
        })
        if (this.props.location.state.otherAmountChecked && this.props.location.state.payAmount) {
          let amount = !isAndroid() ? this.props.location.state.payAmount.replace(/\$/g, '') * 100 : this.props.location?.state?.payAmount;
          this.setState({ otherAmountDisplay: amount })
        }
      }
      if (this.props.location.state.pageLoadViaFlow) {
        isPageReloaded = false;
        this.setState({
          pageLoadViaFlow: true
        })
      }
    }
    trackPageLoad(getOneTimePaymentFlag() ? 'make a one time payment' : isManageRecurring() ? 'manage recurring  payment' :
      'set up recurring payment')
      console.log(getOneTimePaymentFlag() ? 'make a one time payment' : isManageRecurring() ? 'manage recurring  payment' :
      'set up recurring payment')
    if (!(!getOneTimePaymentFlag() && getRecurringPaymentFlag() && isManageRecurring())) {
      let date = null;
      if(secureLocalStorage.getItem('currSysTimeInMs')){
        date = new Date(parseInt(secureLocalStorage.getItem('currSysTimeInMs')))
        this.setState({ serverDate: Moment(date).format('MM/DD/YYYY') })
      }else{
      serverCurrentTime().then((response) => {
        if (response) {
         date = new Date(parseInt(response.systemtimeinmillis))
         this.setState({ serverDate: Moment(date).format('MM/DD/YYYY') })
         secureLocalStorage.setItem('currSysTimeInMs',response.systemtimeinmillis);	
        }
      })
    }
   
    }


    //Select Payment, Payment Amount Explained Modal and Payment Summary Content
    axios
      .get(content_path + '/select-amt-and-summary/select-amt-and-summary-content/jcr:content/data/master.json?timestamp=' + new Date().getTime())
      .then((response) => {
        let amountsExplainedModal = {
          link_label: response.data.auth_amt_exp_link_label,
          header: response.data.auth_amt_exp_header,
          section_1: getProductType() === 'SHIP' ? response.data.auth_amt_exp_modal_sec1_isb : getProductType() === 'GOVT' && getPaymentMethod().indexOf('CMS-SSA/RRB') > -1 ? response.data.auth_amt_exp_modal_sec1_fed_without_payinfull : response.data.auth_amt_exp_modal_sec1_fed,
          btn_txt: response.data.auth_modal_btn_close_txt
        }
        let ssbModal = {
          desc: response.data.auth_ssb_modal_content,
          header: response.data.auth_ssb_modal_title,
          ssb_btn_txt1: response.data.auth_ssb_modal_button_pdf,
          ssb_btn_txt2: response.data.auth_ssb_modal_button_contact
        }
        let linksOneTime = [{ title: response.data.auth_bc_parent_title, link: getOverviewPageLink() },
        { title: response.data.auth_bc_sub_title1, link: '' }];

        let linksRecurring = [{ title: response.data.auth_bc_parent_title, link: getOverviewPageLink() },
        { title: response.data.auth_bc_sub_title2, link: '' }];

        let linksManagePaymentMethod = [{ title: response.data.auth_bc_parent_title, link: getOverviewPageLink() },
        { title: response.data.auth_bc_sub_title3, link: '' }];

        this.setState({
          hTitle: getOneTimePaymentFlag() ? response.data.auth_motp_title : isManageRecurring() ? response.data.auth_mpm_title : response.data.auth_sr_title,
          hLinks: getOneTimePaymentFlag() ? linksOneTime : isManageRecurring() ? linksManagePaymentMethod : linksRecurring,
          header_section_2a: getOneTimePaymentFlag() ? '2. ' + response.data.auth_choose_pay_method_title : (getRecurringPaymentFlag && !isManageRecurring()) ? response.data.auth_ecad_section_title : response.data.auth_choose_pay_method_title,
        })

        this.setState({
          auth_spa_header_section_1: response.data.auth_spa_header_section_1,
          auth_payamt_sec_note: getProductType() === 'SHIP' ? response.data.auth_payamt_sec_note : response.data.auth_payamt_sec_note_fed,
          auth_pay_sec_note_pending_msg: response.data.auth_pay_sec_note_pending_msg,
          back_btn: response.data.auth_bc_back_link,
          cc_change_button: response.data.auth_cc_change_button,
          cc_cancel_button: response.data.auth_cc_cancel_button,
          cc_header: response.data.auth_ship_add_card_details_txt,
          auth_spa_radio_1: response.data.auth_spa_radio_1,
          auth_spa_radio_2: response.data.auth_spa_radio_2,
          auth_spa_radio_3: response.data.auth_spa_radio_3,
          auth_upcoming_charges_label: response.data.auth_upcoming_charges_label,
          auth_due_label: response.data.auth_due_label,
          auth_enteramt_label: response.data.auth_enteramt_label,
          summary_label: response.data.auth_pay_summary_title,
          plan_label: response.data.auth_plan_label,
          membername_label: response.data.auth_name_label,
          id_label: response.data.auth_id_label,
          details_label: response.data.auth_details_label,
          total_label_txt: response.data.auth_total_label,
          currentpaymentmethod_label: response.data.auth_pay_method_label,
          enrolltext: response.data.auth_enroll_text,
          onetimepayment_label: response.data.auth_otp_label,
          choose_payment_method_title: response.data.auth_choose_pay_method_title,
          checking_account_details: response.data.auth_ecad_section_title,
          checking_account_details_asterisk: response.data.auth_ecad_section_title_asterisk,
          eftchecking_label: getProductType() === 'SHIP' ? response.data.auth_eft_checking_label : response.data.auth_eft_checking_label_fed,
          eftsaving_label: response.data.auth_eft_saving_label,
          auth_eft_last_used_lbl: response.data.auth_eft_last_used_lbl,
          choose_pay_method_title_asterisk: getProductType() === 'SHIP' ? response.data.auth_choose_pay_method_title_asterisk : response.data.auth_choose_pay_method_title,
          auth_ship_cc_msg: response.data.auth_ship_cc_msg,
          setupautopay_label: response.data.auth_sap_label,
          setupautopaynote: getProductType() === 'SHIP' ? response.data.auth_sap_note_isb : response.data.auth_sap_note_fed,
          managepay_sec_header: response.data.auth_mpm_sec_header,
          managepay_sec_note: getProductType() === 'SHIP' ? response.data.auth_mpm_sec_note : response.data.auth_mpm_sec_note_fed,
          managepay_radio_header1: response.data.auth_mpm_radio_header1,
          managepay_radio_header2: response.data.auth_mpm_radio_header2,
          managepay_radio_label1: response.data.auth_cancelautopay_label,
          managepay_radio_label2: response.data.auth_new_eft_label,
          cancel_autopay_label: response.data.auth_cancelautopay_label,
          new_eft_label: response.data.auth_new_eft_label,
          new_eft_note: response.data.auth_new_eft_note,
          setup_rec_fed_note: response.data.auth_setup_recurring_note_fed,
          eft_check_radio_label: getProductType() === 'SHIP' ? response.data.auth_eft_check_radio_label : response.data.auth_eft_checking_label_fed,
          credit_debit_radio_label: getRecurringPaymentFlag() && isManageRecurring() ? response.data.auth_new_cred_deb_radio_label : response.data.auth_cred_deb_radio_label,
          ssb_link_text: response.data.auth_social_link_txt,
          setup_footer_note: response.data.auth_setup_footer_note,
          section_1_note_2: response.data.section_1_note_2, //for cMSSSAFound todo
          amountsExplainedModal: amountsExplainedModal,
          ssbModal: ssbModal,
          auth_pay_in_full: response.data.auth_pay_in_full_label,
          saved_cc_api_error: response.data.auth_saved_cc_api_error,
        })
      })
      .catch((error) => {
        this.props.history.push('/service-error');
      });

    //EFT Form and Modals Content

    axios
      .get(content_path + '/eft-form-checking-account/eft-form-checking-acc-details/jcr:content/data/master.json?timestamp=' + new Date().getTime())
      .then((response) => {
        let eftSection = {
          subheader: response.data.auth_eft_sub_title,
          required: response.data.auth_req_label,
          fst_nme_lbl: response.data.auth_eft_first_name,
          mdl_nme_lbl: response.data.auth_eft_mdl_name_label,
          lst_nme_lbl: response.data.auth_eft_lst_name_lbl,
          routing_lbl: response.data.auth_eft_routing_label,
          acct_lbl: response.data.auth_eft_acct_label,
          help_txt: response.data.auth_help_txt,
          notes: response.data.auth_eft_section_notes,
          eft_agreement: response.data.auth_eft_agree_txt,
          review_button: response.data.auth_review_button_txt,
          cancel_button: response.data.auth_cancel_btn_txt,
          eft_payment_error: response.data.auth_eft_payment_error,
          eft_error_sec1: response.data.auth_eft_error_sec1,
          eft_error_sec2: response.data.auth_eft_error_sec2,
          eft_error_sec3: response.data.auth_eft_error_sec3,
          eft_error_sec4: response.data.auth_eft_error_sec4,
          eft_error_button_link: response.data.eft_error_button_link,
          eft_early_warning_button_txt: response.data.auth_eft_early_warning_button_txt,
          eft_model_msg: response.data.auth_eft_model_msg,
          eft_model_ews_title: response.data.auth_early_modal_title,
          help_label_txt: response.data.auth_help_txt,
          cancel_btn_txt: response.data.auth_cancel_btn_txt,
          eft_text_label: response.data.auth_eft_link_txt,
          modal_try_btn: response.data.auth_eft_zero_try_button,
          cancelpay_txt: response.data.auth_cancelpay_btn,
          zeroattempt_header: response.data.auth_eft_zero_title,
          select_amt_error_label: response.data.auth_sel_amt_error_label,
          bankholder_fn_error_label: response.data.auth_first_name_error_label,
          bankholder_mn_error_label: response.data.auth_middle_name_error_label,
          bankholder_ln_error_label: response.data.auth_last_name_error_label,
          acc_no_error_label: response.data.auth_acc_number_error_label,
          routing_no_error_label: response.data.auth_routing_error_label,
          one_error_text: response.data.auth_one_error_msg_label,
          error_text_lbl: response.data.auth_error_txt_label,
          beenfound_text_lbl: response.data.auth_been_found_text,
          oneormore_text_lbl: response.data.auth_oneormore_text,
          errorshave_text_lbl: response.data.auth_errors_have_text,
          errorshas_text_lbl: response.data.auth_error_has_text,
          amt_exceed_error_lbl: response.data.auth_amt_exceed_error_lbl,
          enter_amount_error: response.data.auth_enter_amt_error,
          enter_atleast_one_error: response.data.auth_sel_one_option_error,
          sel_mop_option: response.data.auth_sel_mop_option,
          eaip_error: response.data.auth_eaip_error,
          superUserErrorMsg: response.data.superUserErrorMsg,
          recurring_already_cancelled_error: response.data.auth_recurringCancelledErrorMsg
        }
        let routingModal = {
          header: response.data.auth_routing_modal_header,
          desc: response.data.auth_routing_modal_desc,
          btn_txt: response.data.auth_close_btn_eft
        }
        let acctModal = {
          header: response.data.auth_account_modal_header,
          help_label: response.data.auth_help_txt,
          desc: response.data.auth_account_modal_desc,
          btn_txt: response.data.auth_close_btn_eft
        }
        let cancelPaymentModal = {
          header: response.data.auth_cancelmodal_title,
          btn_txt1: response.data.auth_stayonpage_btn,
          backto_overview: response.data.auth_back_pay_overview_btn,
          cancelConfirmModal: {
            header: response.data.auth_pay_cancel_text,
            bckTxt: response.data.auth_takingback_content,
            cancelpay_txt: response.data.auth_cancelpay_btn,
            backto_overview: response.data.auth_back_pay_overview_btn
          }
        }

        let earlyWarningModal = {
          model_msg: this.state.eft_payment_error,
          cancelpay_txt: response.data.auth_cancelpay_btn,
          modal_try_btn: response.data.auth_eft_zero_try_button
        }
        this.setState(prevState => {
          let newState = {
            header_section_2: response.data.header_section_2,
            eftSection: eftSection,
            routingModal: routingModal,
            acctModal: acctModal,
            cancelPaymentModal: cancelPaymentModal,
            earlyWarningModal: earlyWarningModal
          };
        
          if (!this.props.location?.state || this.props.location?.state?.fromPage === 'overview') {
            newState.showChoosePaymentOptn = (getRecurringPaymentFlag() && !isManageRecurring()) ? false : true;
            newState.paymentMethod = getProductType() === 'SHIP' ? (prevState.lastUsedEft && getOneTimePaymentFlag()) ? 'EFT-lastUsed' : (getRecurringPaymentFlag() && !isManageRecurring()) ? 'EFT' :'' : '';
            newState.show_header_section_2a = true;
          }
        
          return newState;
        }, this.checkLastUsedEFT);
      })
      .catch((error) => {
        this.props.history.push('/service-error');
      });

    setTimeout(() => {
      this.setState({ loading: false })
    }, 4000)

  }

  //write navigation code here START

  navigateTo = () => {
    if (!this.state.lastUsedEft) {
      document.getElementById('bankHolFirstName').removeAttribute('aria-describedby')
      document.getElementById('bankHolMiddleName').removeAttribute('aria-describedby')
      document.getElementById('bankHolLastName').removeAttribute('aria-describedby')
      document.getElementById('routNumber').removeAttribute('aria-describedby')
      document.getElementById('accountNum').removeAttribute('aria-describedby')
    }
    this.setState({ test: true });
    if (getOneTimePaymentFlag() && !(this.state.otherAmountChecked || this.state.pastAmountDueChecked || this.state.pastAmountAndCurentChecked || this.state.onlyCurrentChargesChecked || this.state.payInFullChecked)) {
      this.setState({ amtselectcss: 'error' })
    }
    if (this.state.otherAmountError) {
      this.setState({ amtselectcss: 'default' })
    }
    this.setState({
      bankHolderFirstNameError: false,
      bankHolderMiddleNameError: false,
      bankHolderLastNameError: false,
      accountNumberError: false,
      routingNumberError: false,
      etfPaymentError: false,
      onload: false,
      superUserError: false,

    });
    if (this.validateEft()) {
      secureLocalStorage.setItem('accountHolderFirstName', this.state.bankHolderFirstName)
      secureLocalStorage.setItem('accountHolderMiddleName', this.state.bankHolderMiddleName)
      secureLocalStorage.setItem('accountHolderLastName', this.state.bankHolderLastName)
      secureLocalStorage.setItem('accountPaidAmount', this.state.payAmount);

      if (this.state.superUser) {
        this.setState({ superUserError: true });
      }
      else {

        // Check EFT account number and rounting number validation
        var isOldValues = false;
        if (this.state.oldAccountNumber === this.state.accountNumber.trim() &&
          this.state.oldRoutingNumber === this.state.routingNumber.trim()) {
          isOldValues = true;
        }
        this.setState({
          oldAccountNumber: this.state.accountNumber.trim(),
          oldRoutingNumber: this.state.routingNumber.trim()
        })
        if (!isOldValues) {
          this.setState({ loading: true });
          window.scrollTo(0, 0);
          //check valid account details are present in previous trasactions or not (wrapperPojo saved in local storage)
          var isValidDetails = this.isValidAccountNumberAndRoutingNumber(this.state.accountNumber, this.state.routingNumber, this.state.wrapperPojo);
          if (this.state.paymentMethod === 'EFT' && !isValidDetails) {
            // call account validation wrapper and check accountStatus
            accountValidationWrapper(getMemberID(), this.state.routingNumber, this.state.accountNumber, this.state.productType).then((response) => {
              if (response.data != null) {
                let accountStatus = response.data.accountStatus;
                if (accountStatus != null && accountStatus.length > 0 && (accountStatus.trim().toLowerCase() === "n" || accountStatus.trim().toLowerCase() === "no")) {
                  // Show attempts message and set attempt counter
                  this.setState({ nachaErr: true });
                  this.showAttemptErrorMessage();
                } else {
                  // navigate to review and submit page
                  secureLocalStorage.setItem("accountStatus", accountStatus);
                  this.setState({ loading: false });
                  this.navigateToReviewAndSubmitPage();
                }

              }
              else {
                secureLocalStorage.setItem("accountStatus", 'U');
                this.setState({ loading: false });
                this.navigateToReviewAndSubmitPage();
              }
            }).catch(error => {
              let accountStatus = error?.response?.data?.accountStatus;
              if(accountStatus !== undefined && accountStatus !== null && accountStatus !== ''){
                if (accountStatus === 'U' || accountStatus === 'Y') {
                  secureLocalStorage.setItem("accountStatus", accountStatus);
                  this.setState({ loading: false });
                  this.navigateToReviewAndSubmitPage();
                }
              }else{
                secureLocalStorage.setItem("accountStatus", 'U');
                  this.setState({ loading: false });
                  this.navigateToReviewAndSubmitPage();
              }
            });
          } else {
            // setting accountStatus to Y if wrapper service is not called
            secureLocalStorage.setItem("accountStatus", 'Y');
            this.navigateToReviewAndSubmitPage();
          }
        } else {
          // Show attempts message and set attempt counter
          this.setState({ nachaErr: true });
          this.showAttemptErrorMessage();
        }
      }
    } else {
      setTimeout(() => window.scrollTo(0, 0))
      this.setState({
        alert: true,
        loading: false
      }, () => !this.state.errFields.fields && document.getElementById('alertbody_desktop').focus())
    }
  }

  navigateToReviewAndSubmitPage = () => {
    // navigate to review and submit page
    this.setState({ formValidated: true });
    window.scrollTo(0, 0)
    trackLinkClick('“Review and Submit');
    trackFormSubmit(getOneTimePaymentFlag() ? ' make a one time payment' : isManageRecurring() ? 'manage recurring  payment' :
      'set up recurring payment', getOneTimePaymentFlag() ? 'make a one time payment' : isManageRecurring() ? 'manage recurring payment' :
        'recurring payment', this.state.pastAmountDueChecked && this.state.paymentMethod === 'EFT' ? 'Due Today | EFT ' : this.state.pastAmountAndCurentChecked && this.state.paymentMethod === 'EFT' ?
          'Due today + Upcoming charges | EFT ' : this.state.otherAmountChecked && this.state.paymentMethod === 'EFT' ? 'Other Amount | EFT' : this.state.onlyCurrentChargesChecked && this.state.paymentMethod === 'EFT' ? 'Upcoming charges | EFT' : this.state.payInFullChecked && this.state.paymentMethod === 'EFT' ? 'Pay In Full | EFT' : this.state.cancelRecChecked && this.state.paymentMethod === 'EFT' ? 'Cancel payment' :
            this.state.changeCC && this.state.pastAmountDueChecked ? 'Due Today | Saved Card' : this.state.changeCC && this.state.pastAmountAndCurentChecked ? 'Due today + Upcoming charges | Saved Card' : this.state.changeCC && this.state.otherAmountChecked ? 'Other Amount | Saved Card' :
              this.state.changeCC && this.state.onlyCurrentChargesChecked ? 'Upcoming charges | Saved card' : this.state.changeCC && this.state.payInFullChecked ? 'Pay In Full | Saved card' : '');
  }

  scrollTo = (ele, fieldName) => {
    trackLinkClick(fieldName);
    ele.focus()
    ele.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })
  }

  changeFocus = (event) => {
    if (null != this.topErrorRef.current) {
      this.topErrorRef.current.focus()
    }
  }

  nonLink = (e) => {
    e.preventDefault()
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
  }
  navigateToCancelRec = () => {
    if (!this.state.cancelRecChecked && !this.state.newEftRecChecked) {
      this.setState({ noOptnManageRecErr: this.state.eftSection.enter_atleast_one_error }, () => document.getElementById('alertbody_desktop').focus());
      window.scrollTo(0, 0);
    } else if(this.state.cancelRecChecked && JSON.parse(secureLocalStorage.getItem('recurringCancelled'))) {
      this.setState({ recurringCancelledErr: this.state.eftSection.recurring_already_cancelled_error}, () => document.getElementById('alertbody_desktop').focus());
      window.scrollTo(0, 0);
    } else if (this.state.cancelRecChecked) {
      this.navigateToReviewAndSubmitPage();
    }
  }

  govtSelectPayOptnHandler = () => {
    if (this.state.cancelRecChecked) {
      this.navigateToReviewAndSubmitPage();
    } else if (!this.paymentMethod) {
      if (getSearchPaymentMethodResult() && getSearchPaymentMethodResult().keepCardOnFileInd === 'true' && this.state.changeCC) {
        this.setState({ payWithSavedCC: true });
        if (this.ValidateAmountCC()) {
          secureLocalStorage.setItem('payWithSavedCC', 'true')
          this.navigateToReviewAndSubmitPage();
        }
        window.scrollTo(0, 0)
      } else {
        this.setState({ noOptnManageRecErr: this.state.eftSection.enter_atleast_one_error }, () => document.getElementById('alertbody_desktop').focus());
        window.scrollTo(0, 0);
      }
    }
  }

  //write navigation and UI focus code here END

  // write validation code here START
  ValidateAmountCC = () => {
    let valid = true
    let errFields = []
    if (this.state.payAmount.indexOf('_') > -1 && !this.state.otherAmountDisplay) {
      if (getOneTimePaymentFlag()) {
        if (this.state.otherAmountChecked) {
          valid = false
          this.setState({
            otherAmountErrorDisp: true,
            otherAmountError: (
              <p className="invalid-feedback" id="enter-amount-error-cc" style={{ display: 'block' }} aria-live="assertive">
                <span className="sr-only">Error:</span> {this.state.eftSection.enter_amount_error}
              </p>
            ),
            otherAmountErrorClass: 'is-invalid',
            payAmount: this.state.defaultPaidAmoutPlaceHolder,
          })
        } else {
          valid = false
          errFields.push({ fieldLabel: this.state.eftSection.select_amt_error_label, fieldName: 'noAmount' })
        }
      }
    }

    if (this.state.otherAmountError) {
      valid = false
      this.setState({ otherAmountErrorDisp: true })
      errFields.push({ fieldLabel: 'Other amount', fieldName: 'otherAmount'})
    }
    let errorFields = {errFields, count: errFields.length, type: 'field', src: 'paymentForm'}
    this.setState({ errFields: errorFields })
    return valid
  }

  validateEft = () => {
    let valid = true
    let errFields = []
    let count = 0;
    if (this.state.payAmount.indexOf('_') > -1 && !this.state.otherAmountDisplay) {
      if (getOneTimePaymentFlag()) {
        if (this.state.otherAmountChecked) {
          valid = false
          this.setState({
            otherAmountErrorDisp: true,
            otherAmountError: (
              <p className="invalid-feedback" id="enter-amt-error-eft" style={{ display: 'block' }} aria-live="assertive">
                <span className="sr-only">Error:</span> {this.state.eftSection.enter_amount_error}
              </p>
            ),
            otherAmountErrorClass: 'is-invalid',
            payAmount: this.state.defaultPaidAmoutPlaceHolder,
          })
          count++;
          errFields.push({ fieldLabel: this.state.eftSection.select_amt_error_label, fieldName: 'noAmount', error: this.state.otherAmountError })
        } else {
          valid = false
          count++;
          errFields.push({ fieldLabel: this.state.eftSection.select_amt_error_label, fieldName: 'noAmount', error: this.state.otherAmountError })
        }
      }
    }

    if (this.state.otherAmountError) {
      this.setState({ otherAmountErrorDisp: true })
    }

    if (this.state.otherAmount !== 0) { 
      if ((this.state.otherAmount < 1) && this.state.otherAmount !== '') {
        count++
        errFields.push({ fieldLabel: 'Other amount', fieldName: 'amtExceed', error: this.state.otherAmountError})
      }

      if (this.state.estimatedAnnualPremium !== 0) {
        if ((this.state.estimatedAnnualPremium < this.state.otherAmount) && this.state.otherAmount !== '') {
          count++
          errFields.push({ fieldLabel: 'Other amount', fieldName: 'eaip-error', error: this.state.otherAmountError })
        }
      }
    }

    if (
      (this.state.bankHolderFirstName === null ||
      this.state.bankHolderFirstName === '' ||
      this.state.bankHolderFirstNameError)
      && (this.state.paymentMethod === 'EFT' || (this.state.productType === 'SHIP' && this.state.paymentMethod === 'EFT-lastUsed')) ) {
      valid = false
      count++
      this.setState({ bankHolderFirstNameError: true }, () => {
        errFields.push({ fieldLabel: this.state.eftSection.bankholder_fn_error_label, fieldName: 'firstName', error: this.state.bankHolderFirstNameError })
      })
    }

    if (this.state.bankHolderMiddleNameError) {
      valid = false
      count++
      this.setState({ bankHolderMiddleNameError: true }, () => {
        errFields.push({ fieldLabel: this.state.eftSection.bankholder_mn_error_label, fieldName: 'midName', error: this.state.bankHolderMiddleNameError })
      })
    }

    if (
      (this.state.bankHolderLastName === null ||
      this.state.bankHolderLastName === '' ||
      this.state.bankHolderLastNameError)
      && (this.state.paymentMethod === 'EFT' || (this.state.productType === 'SHIP' && this.state.paymentMethod === 'EFT-lastUsed')))  {
      valid = false
      count++
      this.setState({ bankHolderLastNameError: true }, () => {
        errFields.push({ fieldLabel: this.state.eftSection.bankholder_ln_error_label, fieldName: 'lastName', error: this.state.bankHolderLastNameError })
      })
    }

    if (this.state.routingNumber === null || this.state.routingNumber === '' || this.state.routingNumberError) {
      valid = false
      count++
      this.setState({ routingNumberError: true }, () => {
        errFields.push({ fieldLabel: this.state.eftSection.routing_no_error_label, fieldName: 'routingNumber', error: this.state.routingNumberError })
      })
    }

    if (this.state.accountNumber === null || this.state.accountNumber === '' || this.state.accountNumberError) {
      valid = false
      count++
      this.setState({ accountNumberError: true }, () => {
        errFields.push({ fieldLabel: this.state.eftSection.acc_no_error_label, fieldName: 'accountNumber' , error: this.state.accountNumberError})
      })
    }
    let errorFields = {fields: errFields, count, type: 'field', src: 'paymentForm'}
    this.setState({ errFields: errorFields })
    if (!valid) {
      let errFieldsTxt = '';
      let errCodes = '';
      if (errFields != null && errFields.length) {
        for (const [key, value] of errFields.entries()) {
          let keyVal = key + 1;
          if (keyVal === 1) {
            errFieldsTxt = value.fieldLabel
            errCodes = 'blank';
          } else {
            errFieldsTxt = errFieldsTxt + '|' + value.fieldLabel;
            errCodes = errCodes + '|blank';
          }
        }
      }
      trackError(getOneTimePaymentFlag() ? 'make a one time payment' : isManageRecurring() ? 'manage recurring  payment' :
        'set up recurring payment', errFieldsTxt, 'field validation error', errCodes);
    }
    if (this.state.otherAmountError) {
      valid = false
    }

    return valid
  }

  routingNUmberValidator = (routingNumber) => {
    var l = routingNumber != null ? routingNumber.length : 0
    var rv = routingNumber
    var i,
      t = ''

    // Check the length, it should be nine digits.
    if (l === 9) {
      for (i = 0; i < l; i++) {
        var c = parseInt(rv.charAt(i), 10)
        if (c >= 0 && c <= 9) {
          t = t + c
        }
      }
      var n = 0
      // Now run through each digit and calculate the total.
      for (i = 0; i < l; i += 3) {
        n += parseInt(t.charAt(i), 10) * 3 + parseInt(t.charAt(i + 1), 10) * 7 + parseInt(t.charAt(i + 2), 10)
      }

      // If the resulting sum is an even multiple of ten (but not zero),
      // the currrent routing number is good.
      if (n !== 0 && n % 10 === 0) {
        return false
      }
    }
    return true
  }

  isValidAccountNumberAndRoutingNumber = (accountNumber, RoutingNumber, wrapperPojo) => {
    var isValidEftDetails = false;
    var previousEftAccountDetails = wrapperPojo;
    if (previousEftAccountDetails != null && previousEftAccountDetails.length > 0) {
      previousEftAccountDetails.forEach((accountDetails) => {
        if (accountDetails.accountNumber === accountNumber.trim() &&
          accountDetails.routingNumber === RoutingNumber.trim()) {
          isValidEftDetails = true;
        }
      });
    }
    return isValidEftDetails;
  }

  handleEmptyOtherAmount = () => {
    let value = this.state.otherAmountDisplay;
    this.handleOtherAmountSelection(value, false);
  }
  validateOtherAmountValue = () => {
    let value = this.state.otherAmountDisplay;
    this.handleOtherAmountSelection(value, true);
  }

  // write validation code here END


  // write change handler code here START

  handleAmountSelection = (event) => {
    let selectedAmount = ''
    let otherAmt = ''
    let payAmountdueCheck = false
    let pastAmountAndCurentChecked = false
    let onlyCurrentChargesChecked = false
    let payInFullChecked = false
    let otherAmountChecked = false
    // eslint-disable-next-line no-unused-vars
    let paymentType = "";
    let eftDefaultStatus = false;
    secureLocalStorage.setItem('pastAmountDueChecked', false)
    secureLocalStorage.setItem('pastAmountAndCurentChecked', false)
    secureLocalStorage.setItem('onlyCurrentChargesChecked', false)
    secureLocalStorage.setItem('payInFullChecked', false)
    secureLocalStorage.setItem('otherAmountChecked', false)
    if (event.target.id === 'pastAmountDueFound') {
      payAmountdueCheck = true
      trackLinkClick('mr.premium-payments.radio-button.due-today');
      secureLocalStorage.setItem('pastAmountDueChecked', true)
      this.setState({ payAmount: document.getElementById('duetodayamt').innerText.replace(',', '') }, () => secureLocalStorage.setItem('payAmount', this.state.payAmount))
    } else if (event.target.id === 'pastAmountAndCurentChargesFound') {
      pastAmountAndCurentChecked = true
      trackLinkClick('mr.premium-payments.radio-button.due-today.current-charges');
      secureLocalStorage.setItem('pastAmountAndCurentChecked', true)
      this.setState({ payAmount: document.getElementById('duetodayincomingamt').innerText.replace(',', '') }, () => secureLocalStorage.setItem('payAmount', this.state.payAmount))
    } else if (event.target.id === 'onlyCurrentChargesFound') {
      onlyCurrentChargesChecked = true
      trackLinkClick('mr.premium-payments.radio-button.current-charges');
      secureLocalStorage.setItem('onlyCurrentChargesChecked', true)
      this.setState({ payAmount: document.getElementById('upcomingchargesamt').innerText.replace(',', '') }, () => secureLocalStorage.setItem('payAmount', this.state.payAmount))
    } else if (event.target.id === 'payInFull') {
      payInFullChecked = true
      trackLinkClick('mr.premium-payments.radio-button.pay-in-full');
      secureLocalStorage.setItem('payInFullChecked', true)
      this.setState({ payAmount: document.getElementById('payinfullamt').innerText.replace(',', '') }, () => secureLocalStorage.setItem('payAmount', this.state.payAmount))
    } else {
      otherAmountChecked = true
      trackLinkClick('mr.premium-payments.radio-button.other-amount');
      document.getElementById('otherAmountInput').focus();
      secureLocalStorage.setItem('otherAmountChecked', true)
      document.getElementById('otherAmountInput').removeAttribute('tabindex');
    }
    if (!otherAmountChecked) {
      document.getElementById('otherAmountInput').setAttribute('tabindex', '-1');
    }
    if (event.target.id === 'otherAmount') {
      this.setState({ payAmount: this.state.defaultPaidAmoutPlaceHolder })
      paymentType = this.state.paymentMethod;
      eftDefaultStatus = this.state.isDefaultEftPaymentSelectionAvailable;
    } else {
      paymentType = (this.state.isDefaultEftPaymentSelectionAvailable ? "EFT" : this.state.paymentMethod);
    }

    if (payAmountdueCheck || pastAmountAndCurentChecked || onlyCurrentChargesChecked || payInFullChecked) {
      this.setState({
        otherAmountErrorDisp: false,
        otherAmountError: '',
        otherAmountErrorClass: '',
        amtselectcss: 'default'
      })
      let errField = this.state.errFields?.fields?.filter(elt => elt.fieldName !== 'noAmount' && elt.fieldName !== 'otherAmount')
      let errorFields = {fields: errField, count: errField?.length, type: 'field', src: 'paymentForm'}
      this.setState({ errFields: errorFields , eftButtonHide: false } , () => document.getElementById('alertbody_desktop')?.focus())

      if(this.state.paymentMethod === '') {
        this.setState({ eftButtonHide: true });
      }
    }

    this.setState({
      otherAmount: otherAmt,
      otherAmountDisplay: '',
      checkedPayAmount: selectedAmount,
      pastAmountDueChecked: payAmountdueCheck,
      pastAmountAndCurentChecked: pastAmountAndCurentChecked,
      onlyCurrentChargesChecked: onlyCurrentChargesChecked,
      payInFullChecked: payInFullChecked,
      otherAmountChecked: otherAmountChecked,
      isDefaultEftPaymentSelectionAvailable: eftDefaultStatus
    })
  }

  //If value = 1 then displayed value would be 0.01
  handleOtherAmountSelection = (value, isOnBlurAction) => {
    if (this.state.otherAmountChecked) {
      this.setState({ amtselectcss: 'default' })
    }
    const amount = (value !== undefined) ? !isAndroid() ? value / 100 : value : '';
    let isError = false
    let disableRadio = false;
    let paymentType = "";
    let eftDefaultStatus = false;
    let errorFields = [];
    const regex = /^[0-9]*\.?[0-9]*$/
    if (amount === '' || regex.test(amount)) {
      if (isOnBlurAction && this.state.otherAmountChecked && (amount === 0 || amount === '')) {
        if(this.state.paymentMethod === 'CC') {
          this.setState({ eftButtonHide: true });
        }
        this.setState({
          otherAmountError: (
            <p className="invalid-feedback" id="enter-amt-error" style={{ display: 'block' }} aria-live="assertive">
              <span className="sr-only">Error:</span> {this.state.eftSection.enter_amount_error}
            </p>
          ),
          otherAmountErrorClass: 'is-invalid',
          payAmount: this.state.defaultPaidAmoutPlaceHolder,
          showFrameClass: 'hide',
          paymentMethod: this.state.paymentMethod === 'CC' ? '' : this.state.paymentMethod,
          changeCCClicked: false,
          changeCC: false
        }, () => document.getElementById('otherAmountInput').setAttribute('aria-describedby', 'enter-amt-error'));
        return
      }
      if (amount > 0) {
        this.setState({
          payAmount: isNaN(amount) ? this.state.defaultPaidAmoutPlaceHolder : '$' + !isAndroid() ? convertToCurrency(amount) : amount.toFixed(2),
        })
        if ((isNaN(amount) || amount < 1) && isOnBlurAction) {
          isError = true;
          eftDefaultStatus = this.state.isDefaultEftPaymentSelectionAvailable;
          trackError(getOneTimePaymentFlag() ? 'make a one time payment' : isManageRecurring() ? 'manage recurring  payment' :
            'set up recurring payment', 'Other Amount', 'field validation error', 'invalid');
          this.setState({
            otherAmountError: (
              <p className="invalid-feedback" id="amt-exceed-error" style={{ display: 'block' }} aria-live="assertive">
                <span className="sr-only">Error:</span> {this.state.eftSection.amt_exceed_error_lbl}
              </p>
            ),
            otherAmountErrorClass: 'is-invalid',
            payAmount: this.state.defaultPaidAmoutPlaceHolder,
            showFrameClass: 'hide',
            paymentMethod: this.state.paymentMethod === 'CC' ? '' : this.state.paymentMethod,
            changeCCClicked: false,
            changeCC: false,
            
          }, () => document.getElementById('otherAmountInput').setAttribute('aria-describedby', 'amt-exceed-error'))
          errorFields = this.state.errFields?.fields ? this.state.errFields?.fields?.filter(elt => elt.fieldName !== 'noAmount') : []
          this.setState({
            errFields: {fields: errorFields, count: errorFields?.length, type: 'field', src: 'paymentForm'}
          });

        } else {
          if (isOnBlurAction) {
            let errorFields = this.state.errFields?.fields ? this.state.errFields?.fields?.filter(elt => elt.fieldName !== 'noAmount') : []
            this.setState({
              otherAmountErrorDisp: false,
              errFields: {fields: errorFields, count: errorFields?.length, type: 'field', src: 'paymentForm'}
            })
          }
          this.setState({
            otherAmountError: null,
            otherAmountErrorClass: '',
          }, () => document.getElementById('otherAmountInput').removeAttribute('aria-describedby'))
        }
      } else {
        this.setState({
          otherAmountError: null,
          otherAmountErrorClass: '',
          payAmount: this.state.defaultPaidAmoutPlaceHolder,
        })
      }
      this.setState({
        otherAmountDisplay: value,
        otherAmount: amount,
        otherAmountChecked: true,
        pastAmountDueChecked: false,
        pastAmountAndCurentChecked: false,
        onlyCurrentChargesChecked: false,
        payInFullChecked: false,
        errorMessage: isError ? 'Other Amount' : '',
        isDefaultEftPaymentSelectionAvailable: eftDefaultStatus
      }, () => {
        if (amount === '' || regex.test(amount)) {
          if (isOnBlurAction && this.state.otherAmountChecked && amount === 0) {
            if(this.state.paymentMethod === 'CC') {
              this.setState({ eftButtonHide: true });
            }
            this.setState({
              otherAmountError: (
                <p className="invalid-feedback" id="enter-amt-error" style={{ display: 'block' }} aria-live="assertive">
                 <span className="sr-only">Error:</span> {this.state.eftSection.enter_amount_error}
                </p>
              ),
              otherAmountErrorClass: 'is-invalid',
              payAmount: this.state.defaultPaidAmoutPlaceHolder,
              showFrameClass: 'hide',
              paymentMethod: this.state.paymentMethod === 'CC' ? '' : this.state.paymentMethod,
              changeCCClicked: false,
              changeCC: false
            }, () => document.getElementById('otherAmountInput').setAttribute('aria-describedby', 'enter-amt-error'));
            return
          }
        }
        if (amount !== '' && regex.test(amount) && isOnBlurAction) {
          secureLocalStorage.setItem('payAmount', '$' + isAndroid() ? convertToCurrency(amount) : amount.toFixed(2))
          secureLocalStorage.setItem('pastAmountDueChecked', false)
          secureLocalStorage.setItem('pastAmountAndCurentChecked', false)
          secureLocalStorage.setItem('onlyCurrentChargesChecked', false)
          secureLocalStorage.setItem('payInFullChecked', false)
          secureLocalStorage.setItem('otherAmountChecked', true)
        }
      })
    }
  }

  handleMngRecRad = (event) => {
    let selectRad = event.target.value
    this.setState({ noOptnManageRecErr: '', recurringCancelledErr: '', errFields: [], superUserError: false });
    if (selectRad === 'createRadRec') {
      this.setState({ newEftRecChecked: true, cancelRecChecked: false, newCCRecChecked: false, showFrameClass: 'hide', paymentMethod: 'EFT' });
    } else if (selectRad === 'cancelRadRec') {
      this.setState({ cancelRecChecked: true, newEftRecChecked: false, newCCRecChecked: false, showFrameClass: 'hide', paymentMethod: '' });
      this.setState({
        bankHolderFirstNameError: false,
        bankHolderMiddleNameError: false,
        bankHolderLastNameError: false,
        accountNumberError: false,
        routingNumberError: false,
        bankHolderFirstName: '',
        bankHolderMiddleName: '',
        bankHolderLastName: '',
        routingNumber: '',
        accountNumber: '',
        errorMessage: '',
      })
    } else if (selectRad === 'createRadRecCC') {
      this.setState({ newCCRecChecked: true, newEftRecChecked: false, cancelRecChecked: false, paymentMethod: 'CC' });
      this.setState({
        bankHolderFirstNameError: false,
        bankHolderMiddleNameError: false,
        bankHolderLastNameError: false,
        accountNumberError: false,
        routingNumberError: false,
        bankHolderFirstName: '',
        bankHolderMiddleName: '',
        bankHolderLastName: '',
        routingNumber: '',
        accountNumber: '',
        errorMessage: '',
        loading: false,
        showFrameClass: 'show',
      })
    }
  }

  ccChangeHandler = () => {
    if (this.state.otherAmountErrorClass) {
      this.scrollTo(this.otherAmountRef.current, 'Other Amount')
      return
    }
    this.setState({ changeCCClicked: true, paymentMethod: 'CC', changeCC: false, showFrameClass: 'show' })
    secureLocalStorage.setItem('changeCCClicked', true);
  }

  ccChangeCancelHandler = () => {
    this.setState({ changeCCClicked: false, paymentMethod: '', changeCC: true, showFrameClass: 'hide' })
    secureLocalStorage.setItem('changeCCClicked', false);
  }

  handlePaymentMethodSelection = (event) => {
    let selectmethod = event.target.value
    let errFields = [];
    this.setState({ errFields: [], noOptnManageRecErr: '' , recurringCancelledErr: ''})
    if (this.state.superUserError) {
      this.setState({ superUserError: !this.state.superUserError})
    }
    if (selectmethod === 'CC') {
      if (this.state.otherAmountErrorClass) {
        this.setState({ otherAmountErrorDisp: true , eftButtonHide : true })
        errFields.push({ fieldLabel: 'Other amount', fieldName: 'noAmount', error: this.state.otherAmountError });
        let errorFields = {fields: errFields, count : errFields?.length, type: 'field', src: 'paymentForm'}
        this.setState({ errFields: errorFields , paymentMethod : "" } , () => document.getElementById('alertbody_desktop')?.focus());
        return
      }

      this.setState({
        bankHolderFirstNameError: false,
        bankHolderMiddleNameError: false,
        bankHolderLastNameError: false,
        accountNumberError: false,
        routingNumberError: false,
        bankHolderFirstName: '',
        bankHolderMiddleName: '',
        bankHolderLastName: '',
        routingNumber: '',
        accountNumber: '',
        errorMessage: '',
        loading: false,
        showErrorBanner: true
      })
      if (getOneTimePaymentFlag()) {
        this.setState({ paymentMethod: 'CC', showFrameClass: 'show' })
        secureLocalStorage.setItem('changeCCClicked', false);
      }
      if (!getOneTimePaymentFlag() && getRecurringPaymentFlag() && !isManageRecurring()) {
        this.setState({ paymentMethod: 'CC', showFrameClass: 'show' })
        secureLocalStorage.setItem('changeCCClicked', false);
      }

    } else if (selectmethod === 'EFT') {
      this.setState({
        paymentMethod: 'EFT',
        changeCC: false,
        changeCCClicked: false,
        showFrameClass: 'hide',
        routingNumber: '',
        accountNumber: '',
        bankHolderFirstName: '',
        bankHolderMiddleName: '',
        bankHolderLastName: '',
        bankHolderFirstNameError: false,
        bankHolderMiddleNameError: false,
        bankHolderLastNameError: false,
        showErrorBanner: !this.state.showErrorBanner
      })
    } else if (selectmethod === 'EFT-lastUsed') {
      let valid = true;
      let errFields = [];
      if (this.state.otherAmountError) {
        valid = false
        this.setState({ otherAmountErrorDisp: true })
        errFields.push({ fieldLabel: 'Other amount', fieldName: 'otherAmount', error: this.state.otherAmountError})
        this.otherAmountRef.current.focus();
        let errorFields = {fields: errFields, count : errFields?.length, type: 'field', src: 'paymentForm'}
        this.setState({ 
          errFields: errFields.length > 0 && errorFields , 
          paymentMethod: 'EFT-lastUsed' , 
          eftButtonHide: false,
          accountNumberError: false,
          routingNumberError: false,
          accountNumber: this.state.productType === 'SHIP' ? this.state.lastUsedEft?.bankAccountNumber:this.state.lastUsedEft?.accountNumber,
          routingNumber: this.state.productType === 'SHIP' ? this.state.lastUsedEft?.bankRoutingNumber:this.state.lastUsedEft?.routingNumber,
         } , () => document.getElementById('alertbody_desktop')?.focus());
        return;
      }

      this.setState({
        paymentMethod: 'EFT-lastUsed',
        changeCC: false,
        changeCCClicked: false,
        showFrameClass: 'hide',
        bankHolderFirstName: '',
        bankHolderMiddleName: '',
        bankHolderLastName: '',
        bankHolderFirstNameError: false,
        bankHolderMiddleNameError: false,
        bankHolderLastNameError: false,
        accountNumberError: false,
        routingNumberError: false,
        accountNumber: this.state.productType === 'SHIP' ? this.state.lastUsedEft?.bankAccountNumber: this.state.lastUsedEft?.accountNumber,
        routingNumber: this.state.productType === 'SHIP' ? this.state.lastUsedEft?.bankRoutingNumber: this.state.lastUsedEft?.routingNumber,
        showErrorBanner: !this.state.showErrorBanner,
        eftButtonHide: false
      })
    }
  }

  bankHolderFirstNameChangedHandler = (event) => {
    this.setState({
      bankHolderFirstName: event.target.value
    })
  }

  bankHolderFirstNameOnBlur = (event) => {
    const re = /.*[A-Za-z]+.*$/
    if (event.target.value === '') {
      this.setState({
        bankHolderFirstName: event.target.value,
        bankHolderFirstNameError: true,
      }, () => document.getElementById('bankHolFirstName').setAttribute('aria-describedby', 'bankHolFirstNameFieldError'))
    }
    else if (re.test(event.target.value)) {
      let errorFields = this.state.errFields?.fields ? this.state.errFields?.fields?.filter(elt => elt.fieldName !== 'firstName') : []
      this.setState({
        bankHolderFirstName: event.target.value,
        bankHolderFirstNameError: false,
        errFields: {fields: errorFields, count: errorFields?.length, type: 'field', src: 'paymentForm'}
      })
    } else {
      this.setState({
        bankHolderFirstName: event.target.value,
        bankHolderFirstNameError: true,

      }, () => document.getElementById('bankHolLastName').setAttribute('aria-describedby', 'bankHolFirstNameFieldError'))
    }
  }

  bankHolderMiddleNameChangedHandler = (event) => {
    this.setState({
      bankHolderMiddleName: event.target.value
    })
  }

  bankHolderMiddleNameOnBlur = (event) => {
    const re = /.*[A-Za-z]+.*$/
    if (event.target.value === '' || re.test(event.target.value)) {
     let errorFields = this.state.errFields?.fields ? this.state.errFields?.fields?.filter(elt => elt.fieldName !== 'midName') : []
      this.setState({
        bankHolderMiddleName: event.target.value,
        bankHolderMiddleNameError: false,
        errFields: {fields: errorFields, count: errorFields?.length, type: 'field', src: 'paymentForm'}
      })
    } else {
      this.setState({
        bankHolderMiddleName: event.target.value,
        bankHolderMiddleNameError: true,
      }, () => document.getElementById('bankHolMiddleName').setAttribute('aria-describedby', 'bankHolMiddleNameFieldError'))
    }
  }

  bankHolderLastNameChangedHandler = (event) => {
    this.setState({
      bankHolderLastName: event.target.value,
    })
  }

  bankHolderLastNameOnBlur = (event) => {
    const re = /.*[A-Za-z]+.*$/
    if (event.target.value === '') {
      this.setState({
        bankHolderLastName: event.target.value,
        bankHolderLastNameError: true,
      }, () => document.getElementById('bankHolLastName').setAttribute('aria-describedby', 'bankHolLastNameFieldError'))
    } else if (re.test(event.target.value)) {
      let errorFields = this.state.errFields?.fields ? this.state.errFields?.fields?.filter(elt => elt.fieldName !== 'lastName') : []
      this.setState({
        bankHolderLastName: event.target.value,
        bankHolderLastNameError: false,
        errFields: {fields: errorFields, count: errorFields?.length, type: 'field', src: 'paymentForm'}
      })
    } else {
      this.setState({
        bankHolderLastName: event.target.value,
        bankHolderLastNameError: true,
      }, () => document.getElementById('bankHolLastName').setAttribute('aria-describedby', 'bankHolLastNameFieldError'))
    }
  }

  accountNumberChangedHandler = (event) => {
    this.setState({
      accountNumber: event.target.value
    })
  }

  accountNumberOnBlur = (event) => {
    const re = /^[0-9]{3,17}$/
    if (event.target.value === '') {
      this.setState({
        accountNumber: event.target.value,
        accountNumberError: true,
        etfPaymentError: false,
      }, () => document.getElementById('accountNum').setAttribute('aria-describedby', 'accountNumFieldError'))
    } else if (re.test(event.target.value)) {
      let errorFields = this.state.errFields?.fields ? this.state.errFields?.fields?.filter(elt => elt.fieldName !== 'accountNumber') : []
      this.setState({
        accountNumber: event.target.value,
        accountNumberError: false,
        etfPaymentError: false,
        errFields: {fields: errorFields, count: errorFields?.length, type: 'field', src: 'paymentForm'}
      })
    } else {
      this.setState({
        accountNumber: event.target.value,
        accountNumberError: true,
        etfPaymentError: false,
      }, () => document.getElementById('accountNum').setAttribute('aria-describedby', 'accountNumFieldError'))
    }
  }

  rountingNumberChangedHandler = (event) => {
    this.setState({
      routingNumber: event.target.value
    })
  }

  rountingNumberOnBlur = (event) => {
    const re = /^[0-3][0-9]{8}$/
    if (event.target.value === '') {
      this.setState({
        routingNumber: event.target.value,
        routingNumberError: true,
        etfPaymentError: false,
      }, () => document.getElementById('routNumber').setAttribute('aria-describedby', 'routNumberFieldError'))
    } else if (re.test(event.target.value) && !this.routingNUmberValidator(event.target.value)) {
      let errorFields = this.state.errFields?.fields ? this.state.errFields?.fields?.filter(elt => elt.fieldName !== 'routingNumber') : []
      this.setState({
        routingNumber: event.target.value,
        routingNumberError: false,
        etfPaymentError: false,
        errFields: {fields: errorFields, count: errorFields?.length, type: 'field', src: 'paymentForm'}
      })
    } else {
      this.setState({
        routingNumber: event.target.value,
        routingNumberError: true,
        etfPaymentError: false,
      }, () => document.getElementById('routNumber').setAttribute('aria-describedby', 'routNumberFieldError'))
    }
  }

  eftCancelHandler = () => {
    window.location.assign(getOverviewPageLink());
  }
  // write change handler code here END

  // write error message code here START
  showAttemptErrorMessage = () => {
    trackError(getOneTimePaymentFlag() ? 'make a one time payment' : isManageRecurring() ? 'manage recurring  payment' :
      'set up recurring payment', 'Account number, Routing number', 'Payment error', 'invalid');
      var count = null;
      var errorTxt = null;
    if (this.state.attemptCounter <= 1) {

      // Remove review and submit button from EFT payments
      console.log("Removed submit and continue button");
      count = this.state.attemptCounter - 1;
      errorTxt = this.state.eftSection.eft_payment_error;
      let earlyWarningModal = {
        model_msg: errorTxt.replace("etfPaymentErrorCount", count),
        eft_zero_btn_1: this.state.eftSection.modal_try_btn,
        eft_zero_btn_2: this.state.eftSection.cancelpay_txt,
        zero_attempt_header: this.state.eftSection.zeroattempt_header
      }
      this.setState({
        earlyWarningModal: earlyWarningModal,
        isEarlyWarningZeroModal: true
      });
      this.displayEftModal('EarlyWarningZero');
    } else {
      let errFields = [];
      count = this.state.attemptCounter - 1;
      errorTxt = this.state.eftSection.eft_error_sec1;
      errorTxt = errorTxt.replace("etfPaymentErrorCount", count);
      errFields.push({ etfErrorMsg: errorTxt });
      let earlyWarningModal = {
        model_msg: this.state.eftSection.eft_model_msg,
        header: this.state.eftSection.eft_model_ews_title,
      }
      this.setState({
        attemptCounter: count,
        errFields: errFields,
        etfPaymentError: true,
        earlyWarningModal: earlyWarningModal
      }, () => document.getElementById('alertbody_desktop').focus());
    }
    this.setState({ loading: false });
    window.scrollTo(0, 0);
  }

  getEftError() {
    return (
      this.state.eftSection.eft_payment_error);
  }

  displayErrorAlertOnSmallScreen = (eftErrors) => {
    let errormsg = '';
    let nachaErr = this.state.nachaErr;
    if (this.state.noOptnManageRecErr !== '') {
      errormsg = this.state.noOptnManageRecErr;
    } else if ((this.state.errFields.length > 0 || this.state.otherAmountErrorDisp) && !this.state.etfPaymentError && !nachaErr) {
      errormsg = this.state.eftSection.oneormore_text_lbl;
    } else if (this.state.errFields.length > 0 && this.state.errFields.find(elt => elt.etfErrorMsg !== null)) {
      for (const [key, value] of this.state.errFields.entries()) {
        if (value.etfErrorMsg) {
          nachaErr = true
          errormsg = value.etfErrorMsg
        }
      }
    }
    if (errormsg) {
      return (
        <div
          id="TopAlertPayError"
          className="alert xs-show alert-xs"
          tabindex="-1"
          ref={this.topErrorRef}
          role="alert"
        >
          <div className="alert-body mobile" id="alertbody_mobile">
            <Notification notificationType="error">
              <div>
                {nachaErr ? (
                  <>
                  <Typography.Paragraph className="error-header">
                    {this.state.eftSection.error_text_lbl}
                  </Typography.Paragraph>
                  <div className="error-body">
                    {eftErrors}
                  </div>
                  </>
                ) :
                    this.state.noOptnManageRecErr !== '' ?
                      <Fragment>
                        <span className="sr-only">Error:</span> 
                        <Typography.Paragraph className="error-header">
                          No selection
                        </Typography.Paragraph>
                        <Typography.Paragraph className="error-body">
                          {errormsg}
                        </Typography.Paragraph>
                      </Fragment>
                    :
                    <Fragment>
                      <Typography.Paragraph className="num-errors-found">
                        {(this.state.otherAmountErrorDisp ? this.state.errFields.length : this.state.errFields.length) +
                          (this.state.errFields.length > 1 ? this.state.eftSection.errorshave_text_lbl : this.state.eftSection.errorshas_text_lbl) +
                          this.state.eftSection.beenfound_text_lbl}
                      </Typography.Paragraph>
                        <ul className="error-list">
                          {this.state.errFields.map((error) => (
                            <li className="error-label">{error.fieldLabel}</li>
                          ))}
                        </ul>
                    </Fragment>
                  }

              </div>
            </Notification>
          </div>
        </div>
      )
    }
  }

  // write error message code here END

  render(props) {
    // Redirect to service-error page
    if (this.state.isServiceError) {
      return <Redirect to="/service-error" push={true} />;
    }

    if (this.state.pageLoadViaFlow) {
      secureLocalStorage.setItem('selectedPaymentMethod', this.state.paymentMethod)
      secureLocalStorage.setItem('payAmount', this.state.payAmount)
    }
    let eftErrors = []
    if (this.state.otherAmountErrorDisp) {
      eftErrors.push(
        <button
          role="link"
          type="button"
          className="tds-link tds-link--medium linkBtn error-href"
          rel=""
          onClick={(e) => {
            this.scrollTo(this.otherAmountRef.current, 'other amount')
            document.getElementById('otherAmountInput').setAttribute('aria-describedby', 'enter-amt-error amt-exceed-error')
          }}
        >
          {' Other amount' + (this.state.errFields.length > 0 ? ', ' : '')}
        </button>
      )
    }
    if (this.state.errFields != null && this.state.errFields.length) {
      for (const [key, value] of this.state.errFields.entries()) {
        let keyVal = key + 1;
        if (value.fieldName === 'noAmount') {
          eftErrors.push(
            <button
              role="link"
              type="button"
              className="tds-link tds-link--medium linkBtn error-href"
              rel=""
              onClick={(e) => {
                if (this.state.pastAmountDueFound) {
                  document.getElementById('pastAmountDueFound').focus();
                } else if (this.state.pastAmountAndCurentChargesFound) {
                  document.getElementById('pastAmountAndCurentChargesFound').focus();
                } else {
                  this.scrollTo(this.otherAmountRef.current, 'Other Amount')
                }
              }}
            >
              {' ' + value.fieldLabel.trim() + (this.state.errFields.length === keyVal ? '' : ', ')}
            </button>
          )
        } else if (value.fieldName === 'firstName') {
          eftErrors.push(
            <button
              role="link"
              type="button"
              className="tds-link tds-link--medium linkBtn error-href"
              rel=""
              onClick={(e) => {
                this.scrollTo(this.firstNameRef, 'account holders first name');
                document.getElementById('bankHolFirstName').setAttribute('aria-describedby', 'bankHolFirstNameFieldError');
              }}
            >
              {' ' + value.fieldLabel.trim() + (this.state.errFields.length === keyVal ? '' : ', ')}
            </button>
          )
        } else if (value.fieldName === 'midName') {
          eftErrors.push(
            <button
              role="link"
              type="button"
              className="tds-link tds-link--medium linkBtn error-href"
              rel=""
              onClick={(e) => {
                this.scrollTo(this.midNameRef, 'account holders middle name');
                document.getElementById('bankHolMiddleName').setAttribute('aria-describedby', 'bankHolMiddleNameFieldError');
              }}
            >
              {' ' + value.fieldLabel.trim() + (this.state.errFields.length === keyVal ? '' : ', ')}
            </button>
          )
        } else if (value.fieldName === 'lastName') {
          eftErrors.push(
            <button
              role="link"
              type="button"
              className="tds-link tds-link--medium linkBtn error-href"
              rel=""
              onClick={(e) => {
                this.scrollTo(this.lastNameRef, 'bank holders last name');
                document.getElementById('bankHolLastName').setAttribute('aria-describedby', 'bankHolLastNameFieldError');
              }}
            >
              {' ' + value.fieldLabel.trim() + (this.state.errFields.length === keyVal ? '' : ', ')}
            </button>
          )
        } else if (value.fieldName === 'accountNumber') {
          eftErrors.push(
            <button
              role="link"
              type="button"
              className="tds-link tds-link--medium linkBtn error-href"
              rel=""
              onClick={(e) => {
                this.scrollTo(this.accountNumberRef, 'bank holders Account number');
                document.getElementById('accountNum').setAttribute('aria-describedby', 'accountNumFieldError');
              }}
            >
              {' ' + value.fieldLabel.trim() + (this.state.errFields.length === keyVal ? '' : ', ')}
            </button>
          )
        } else if (value.fieldName === 'routingNumber') {
          eftErrors.push(
            <button
              role="link"
              type="button"
              className="tds-link tds-link--medium linkBtn error-href"
              rel=""
              onClick={(e) => {
                this.scrollTo(this.routingNumberRef, 'bank holders Routing name');
                document.getElementById('routNumber').setAttribute('aria-describedby', 'routNumberFieldError');
              }}
            >
              {' ' + value.fieldLabel.trim() + (this.state.errFields.length === keyVal ? '' : ', ')}
            </button>
          )
        } else if (value.etfErrorMsg) {
          var efterrortxt = {};
          var sec2Error = this.state.eftSection.eft_error_sec2.replace("<p>", "").replace("</p><br>", "");
          efterrortxt.sec1 = <Markup content={value.etfErrorMsg} />;
          efterrortxt.sec2 = <Markup content={sec2Error} noWrap='true' />;
          efterrortxt.link = 
            <PaymentModals
              modalContent={this.state.earlyWarningModal}
              modalType='earlyWarning'
              eftSection={this.state.eftSection}
            />;
            console.log(efterrortxt.sec2);
          eftErrors.push(efterrortxt.sec1, efterrortxt.sec2, efterrortxt.link);
        }
      }
    }

    return (
      <div>
        {this.state.loading ? <Spinner /> : null}
        <div className={(this.state.loading ? ' text-blur' : '')}>
          <PageHeader links={this.state.hLinks} back_btn={this.state.back_btn} title={this.state.hTitle}
            plan={secureLocalStorage.getItem('shipPlanNamesList') && this.state.productType === 'SHIP' ? secureLocalStorage.getItem('multiShipPlanType') : getPlanInformation().planType} />

          {this.state.formValidated ? (
            <div>
              <Redirect
                to={{
                  pathname: '/payment-review-and-submit',
                  state: {
                    bankHolderFirstName: this.state.bankHolderFirstName,
                    bankHolderMiddleName: this.state.bankHolderMiddleName,
                    bankHolderLastName: this.state.bankHolderLastName,
                    routingNumber: this.state.routingNumber,
                    accountNumber: this.state.accountNumber,
                    paymentMethod: this.state.paymentMethod,
                    payAmount: this.state.payAmount.replace(',',''),
                    pastAmountDueChecked: this.state.pastAmountDueChecked,
                    pastAmountAndCurentChecked: this.state.pastAmountAndCurentChecked,
                    onlyCurrentChargesChecked: this.state.onlyCurrentChargesChecked,
                    payInFullChecked: this.state.payInFullChecked,
                    lastUsedEft: this.state.lastUsedEft,
                    otherAmountChecked: this.state.otherAmountChecked,
                    pageLoadViaFlow: true,
                    cancelRecChecked: this.state.cancelRecChecked,
                    newEftRecChecked: this.state.newEftRecChecked,
                    payWithSavedCC: this.state.payWithSavedCC,
                    monthlyPaymentPremium: this.state.monthlyPaymentPremium,
                    paymentsMethodValue: this.state.paymentsMethodValue,
                    totalAmountDue: this.state.totalAmountDue ? this.state.totalAmountDue : this.props.location.state.totalAmountDue,
                    authIndicator: this.state.authIndicator,
                  },
                }}
              />
            </div>
          ) : (

              <div className="paymentDetails">
                {/* {this.displayErrorAlertOnSmallScreen(eftErrors)} */}
                <div>
                  <Grid.Item>
                    <div className="container mobile-container">
                      
                    {(this.state.errFields?.length > 0 || this.state.noOptnManageRecErr !== '' || this.state.recurringCancelledErr !== '' ) ? (
                          <Notification notificationType="error" id="alertbody_desktop" className="payment-error-box xs-hide" tabIndex="0">
                                  <div className={this.state.nachaErr ? "alert-body nachaErr" : "alert-body"}>
                                    <div>
                                      {(this.state.noOptnManageRecErr !== '') ?
                                        <Fragment>
                                          <span className="sr-only">Error:</span> 
                                          <Typography.Paragraph>
                                            <span>No selection</span>
                                            {' ' + this.state.noOptnManageRecErr}
                                          </Typography.Paragraph>
                                        </Fragment>
                                        :
                                        (this.state.recurringCancelledErr !== '') ?
                                        <Fragment>
                                          <Typography.Paragraph>
                                            <span>Error:</span>
                                            {' ' + this.state.recurringCancelledErr}
                                          </Typography.Paragraph>
                                        </Fragment>
                                        : (<span> {this.state.etfPaymentError && (
                                          <div>
                                            <Typography.Paragraph>
                                              {this.state.eftSection.error_text_lbl}
                                            </Typography.Paragraph>
                                            <div className="error-body">
                                              {eftErrors}
                                            </div>
                                          </div>
                                        )}
                                        </span>)

                                      }
                                    </div>
                                  </div>
                          </Notification>
                        ) : (
                          ((this.state.errFields?.fields?.length > 0) ||
                            this.state.otherAmountErrorDisp) && 
                            <PageError errorFields={this.state.errFields} page='shipPayment' eftSection={this.state.eftSection} />
                        )}
                      <div className={'row'}>
                        <div className="paymentSumSort">
                      <PaymentSummary
                          payAmount={this.state.payAmount}
                          serverDate={this.state.serverDate}
                          summary={this.state.summary_label}
                          planLabel={this.state.plan_label}
                          membername={this.state.membername_label}
                          id={this.state.id_label}
                          details={this.state.details_label}
                          totallabel={this.state.total_label_txt}
                          currentpaymentmethod={this.state.currentpaymentmethod_label}
                          enrolltext={this.state.enrolltext}
                          onetimepayment={this.state.onetimepayment_label}
                          eftchecking={this.state.eftchecking_label}
                          eftsavings={this.state.eftsaving_label}
                          setupautopay={this.state.setupautopay_label}
                          eftNumber={this.state.eftNumber}
                          paymentsMethodValue={this.state.paymentsMethodValue}
                        />
                        <div className="paymentDetailsIpad col-md-7">
                          <div className="center col-md-12 float-L p-0 uhc-tile-brd-wdgt">
                            <div className="col-md-12 p-0">

                              <div className="paymentDetails">
                                <div className="align-items-center p-0 payment-mas-top mt-3">
                                  {!getOneTimePaymentFlag() && getRecurringPaymentFlag() && isManageRecurring() ?
                                    (<Grid.Item>
                                      <div className="row">
                                        <div className="col-md-12 uhc-padding-top-bottom uhc-margin-left-right">
                                          <Typography.H3 className="tds-header__h3" id="paymentMethodUpdate">{this.state.managepay_sec_header}</Typography.H3>
                                          <div className="tds-typography__paragraph mt-3 mb-2">
                                            <Markup content={this.state.managepay_sec_note} />
                                          </div>
                                          <FormGroup alignment="vertical" aria-labelledby="paymentMethodUpdate" groupName="paymentmethodupdate" hideInstruction={false} state="default" >
                                            <div className="col-md-12 payMethodSubHeading">
                                              <Typography.H4>{this.state.managepay_radio_header1}</Typography.H4>
                                            </div>
                                            <RadioButton id="cancelrecurring" label={this.state.managepay_radio_label1} name="payMethod"
                                              value="cancelRadRec" onChange={this.handleMngRecRad}
                                              checked={this.state.cancelRecChecked} />
                                            <div className="col-md-12 payMethodSubHeading">
                                              <Typography.H4>{this.state.managepay_radio_header2}</Typography.H4>
                                            </div>
                                            <div className="row mb-2">
                                              <div className="col-md-6 pad0-mobile">
                                                <RadioButton id="newEFTChecking" label={this.state.managepay_radio_label2} name="payMethod"
                                                  value="createRadRec" onChange={this.handleMngRecRad}
                                                  checked={this.state.newEftRecChecked} />

                                                {getProductType() === 'GOVT' ? (
                                                  <div className="mt-3" >
                                                    <RadioButton id="newCC" label={this.state.credit_debit_radio_label} name="payMethod"
                                                      value='createRadRecCC' onChange={this.handleMngRecRad}
                                                      checked={this.state.newCCRecChecked} />
                                                  </div>
                                                ) : null}

                                              </div>

                                              {getProductType() === 'SHIP' ? <div className="col-md-6 ">
                                                <div className="tds-typography__paragraph">
                                                  <small><Markup content={this.state.new_eft_note} /></small>
                                                </div>
                                              </div> : null}
                                            </div>
                                          </FormGroup>
                                        </div>
                                      </div>
                                      {!this.state.newEftRecChecked && this.state.productType === 'SHIP' ? (
                                        <div className={"row d-block"} style={{ margin: '1px' }}>
                                          <div
                                            id="reviewsubmit"
                                            className="col-12 tds-card manage-buttons"
                                          >
                                            <button
                                              name="next"
                                              aria-disabled="false"
                                              aria-describedby="eftNotes"
                                              className="tds-button tds-button--primary-one tds-margin-xsm-horizontal"
                                              onClick={this.navigateToCancelRec}
                                            >
                                              {this.state.eftSection.review_button}
                                            </button>
                                            <PaymentModals
                                              modalContent={this.state.cancelPaymentModal}
                                              modalType='cancelPayment'
                                              cancel_btn_txt={this.state.eftSection.cancel_btn_txt} />
                                          </div>
                                        </div>
                                      ) : null}
                                    </Grid.Item>) : null}

                                  <PaymentAmt
                                    auth_spa_header_section_1={this.state.auth_spa_header_section_1}
                                    pastAmountDueFound={this.state.pastAmountDueFound}
                                    pastAmountDue={this.state.pastAmountDue}
                                    pastAmountDueChecked={this.state.pastAmountDueChecked}
                                    firstDayOfMonth={this.state.firstDayOfMonth}
                                    pastAmountAndCurentChargesFound={this.state.pastAmountAndCurentChargesFound}
                                    pastAmountAndCurentCharges={this.state.pastAmountAndCurentCharges}
                                    pastAmountAndCurentChecked={this.state.pastAmountAndCurentChecked}
                                    currentPaymentDate={this.state.currentPaymentDate}
                                    onlyCurrentChargesFound={this.state.onlyCurrentChargesFound}
                                    otherAmountChecked={this.state.otherAmountChecked}
                                    otherAmountErrorclassName={this.state.otherAmountErrorClass}
                                    otherAmountDisplay={this.state.otherAmountDisplay}
                                    otherAmountError={this.state.otherAmountError}
                                    cMSSSAFound={this.state.cMSSSAFound}
                                    handleAmountSelection={this.handleAmountSelection}
                                    handleOtherAmountSelection={this.handleOtherAmountSelection}
                                    validateOtherAmountValue={this.validateOtherAmountValue}
                                    handleEmptyOtherAmount={this.handleEmptyOtherAmount}
                                    otherAmountRef={this.otherAmountRef}
                                    onlyCurrentChargesChecked={this.state.onlyCurrentChargesChecked}
                                    payInFullChecked={this.state.payInFullChecked}
                                    currentCharges={this.state.currentCharges}
                                    section_1_note_2={this.state.section_1_note_2}
                                    amtExplained={this.state.amountsExplainedModal}
                                    auth_payamt_sec_note={this.state.auth_payamt_sec_note}
                                    auth_spa_radio_1={this.state.auth_spa_radio_1}
                                    auth_spa_radio_2={this.state.auth_spa_radio_2}
                                    auth_spa_radio_3={this.state.auth_spa_radio_3}
                                    auth_upcoming_charges_label={this.state.auth_upcoming_charges_label}
                                    auth_due_label={this.state.auth_due_label}
                                    auth_enteramt_label={this.state.auth_enteramt_label}
                                    amtselectcss={this.state.amtselectcss}
                                    auth_pay_in_full={this.state.auth_pay_in_full}
                                    auth_pay_sec_note_pending_msg={this.state.auth_pay_sec_note_pending_msg}
                                  />


                                  {!this.state.showChoosePaymentOptn && this.state.show_header_section_2a ?
                                    (
                                      <Grid.Item>
                                        <div className={getOneTimePaymentFlag() ? 'payMethodHeading' : null}>
                                          <div className="topheaderpadding">
                                              <Typography.H3>
                                                {this.state.header_section_2a}
                                              </Typography.H3>
                                          </div>
                                          {getRecurringPaymentFlag() ? (
                                            <div className="tds-typography__paragraph uhc-note-padding">
                                              <Markup content={this.state.setupautopaynote} />
                                            </div>
                                          ) : null}
                                        </div>
                                      </Grid.Item>) : null}

                                  {this.state.showChoosePaymentOptn ?
                                    (<div>
                                      <Grid.Item>
                                        <div className={!getRecurringPaymentFlag() ? 'payMethodHeading' : null}>
                                          <div className="topheaderpadding choosepay">
                                            <Typography.H3 id="choose_pay">
                                              {this.state.header_section_2a}
                                            </Typography.H3>
                                          </div>
                                        </div>
                                         {getRecurringPaymentFlag() && !isManageRecurring() ? (
                                          <div className="tds-typography__paragraph mb-4 uhc-margin-left-right">
                                            <Markup content={this.state.setupautopaynote} />
                                          </div>
                                        ) : null} 


                                      </Grid.Item>

                                      <Grid.Item>
                                        { getOneTimePaymentFlag() &&
                                      <Fragment>
                                        <Form.Group 
                                        state={this.state.noOptnManageRecErr.includes(this.state.eftSection.enter_atleast_one_error) ? 'error':''}
                                        className="radioBtnGroup">
                                          <legend className="sr-only">{this.state.header_section_2a}</legend>
                                          {
                                          getProductType() === 'SHIP' && this.state.lastUsedEft && getOneTimePaymentFlag() &&
                                            <>
                                              <Form.RadioButton
                                                id="eftAmountLastUsedId"
                                                value="EFT-lastUsed"
                                                groupName="paymentMethod"
                                                checked={this.state.paymentMethod === 'EFT-lastUsed'}
                                                onChange={this.handlePaymentMethodSelection}
                                                label={this.state.eftchecking_label + this.state.lastUsedEft?.bankAccountNumber?.slice(-4) + " " + this.state.auth_eft_last_used_lbl}
                                                className="pb-4"
                                              /> </>}
                                            <Form.RadioButton 
                                              id="eftAmountId"
                                              value="EFT"
                                              groupName="paymentMethod"
                                              checked={this.state.paymentMethod === 'EFT'}
                                              onChange={this.handlePaymentMethodSelection}
                                              label={this.state.eft_check_radio_label}
                                              className="pb-4"
                                              />
                                              
                                              <Form.RadioButton
                                                id="ccAmountId"
                                                label={getSearchPaymentMethodResult() && getSearchPaymentMethodResult().keepCardOnFileInd === 'true' && getOneTimePaymentFlag() ?
                                                  getSearchPaymentMethodResult().type + '****' + getSearchPaymentMethodResult().numberLastFour
                                                  : this.state.credit_debit_radio_label}
                                                value="CC"
                                                groupName="paymentMethod"
                                                checked={this.state.paymentMethod === 'CC' || this.state.changeCC}
                                                onChange={this.handlePaymentMethodSelection}
                                                className=""
                                              />
                                            <Form.ErrorMessage className="choose-mop-error">{this.state.eftSection.sel_mop_option}</Form.ErrorMessage>
                                          {secureLocalStorage.getItem("searchPaymentMethodAPIFailure") && getOneTimePaymentFlag() && this.state.showErrorBanner ?
                                            <Notification notificationType="error" className="errorBanner">
                                              <Markup content={this.state.saved_cc_api_error} />
                                            </Notification>
                                          : null
                                          }
                                            </Form.Group>
                                            </Fragment>
                                        }

                                      </Grid.Item> </div>) : null}
                                  {(this.state.paymentMethod === 'EFT') || (this.state.productType === 'SHIP' && this.state.paymentMethod === 'EFT-lastUsed')? (
                                    <Grid.Item className="eftForm">
                                      <>
                                      {this.state.paymentMethod === 'EFT' ?
                                        <div className="align-items-center mt-4">
                                          <div className="row ml-0">
                                            <div>
                                              <Typography.H4 className={!getOneTimePaymentFlag() && getRecurringPaymentFlag() && !isManageRecurring() ? getProductType() === 'SHIP' ? 'uhc-margin-left-20 m-0 pad5 mt-5 uhc-medium-bold' : 'm-0 pad5 uhc-medium-bold' : 'uhc-margin-left-20 m-0 pad5 uhc-medium-bold'}>
                                                {' '}
                                                {this.state.eftSection.subheader}
                                              </Typography.H4>
                                            </div>
                                          </div>
                                        </div> :''}
                                        <fieldset>
                                          <legend className="sr-only">{this.state.eftSection.subheader}</legend>
                                          <div className="row ml-0">
                                            <div className="col-md-12 align-items-center mt-3 formBorder">
                                            <EFTForm className="pl-4 pr-4">
                                            <EFTForm.Row className="mbt-med">
                                                <div>
                                                <div className="d-flex">
                                                    <label htmlFor="bankHolFirstName" className="eft-label-desc m-0 color-black">
                                                    {this.state.eftSection.fst_nme_lbl}
                                                    </label>
                                                    <div className="ml-auto hmfm-id">
                                                    <span className="text-R uhc-sans-medium token-color-gray-dark-base uhc-font-14">
                                                        {this.state.eftSection.required}
                                                    </span>
                                                    </div>
                                                </div>
                                                <Input
                                                    type="text"
                                                    id="bankHolFirstName"
                                                    label={this.state.eftSection.fst_nme_lbl}
                                                    value={this.state.bankHolderFirstName}
                                                    changeHandler={this.bankHolderFirstNameChangedHandler}
                                                    blurHandler={this.bankHolderFirstNameOnBlur}
                                                    error={this.state.bankHolderFirstNameError}
                                                    inputRef={(el) => (this.firstNameRef = el)}
                                                    maxlength='25'
                                                    required
                                                    fieldError="bankHolFirstNameFieldError"

                                                    invalid
                                                />
                                                {/*<small id="bankHolFirstNameSubText" className="form-help-text text-muted">{this.state.eftSection.fst_nme_inst_txt}</small>*/}
                                                </div>
                                            </EFTForm.Row>
                                            <EFTForm.Row className="mbt-med">
                                                <div>
                                                <label htmlFor="bankHolMiddleName" className="eft-label-desc m-0 color-black">
                                                    {this.state.eftSection.mdl_nme_lbl}
                                                </label>
                                                <Input
                                                    type="text"
                                                    id="bankHolMiddleName"
                                                    label={this.state.eftSection.mdl_nme_lbl}
                                                    value={this.state.bankHolderMiddleName}
                                                    changeHandler={this.bankHolderMiddleNameChangedHandler}
                                                    blurHandler={this.bankHolderMiddleNameOnBlur}
                                                    inputRef={(el) => (this.midNameRef = el)}
                                                    error={this.state.bankHolderMiddleNameError}
                                                    maxlength='25'
                                                    fieldError="bankHolMiddleNameFieldError"
                                                    describe=""
                                                />
                                                {/*<small id="bankHolMiddleNameSubText" className="form-help-text text-muted">{this.state.eftSection.mdl_nme_inst_txt}</small>*/}
                                                </div>
                                            </EFTForm.Row>
                                            <EFTForm.Row className="mbt-med">
                                                <div>
                                                <label htmlFor="bankHolLastName" className="eft-label-desc m-0 color-black">
                                                    {this.state.eftSection.lst_nme_lbl}
                                                </label>
                                                <Input
                                                    type="text"
                                                    id="bankHolLastName"
                                                    label={this.state.eftSection.lst_nme_lbl}
                                                    value={this.state.bankHolderLastName}
                                                    changeHandler={this.bankHolderLastNameChangedHandler}
                                                    blurHandler={this.bankHolderLastNameOnBlur}
                                                    error={this.state.bankHolderLastNameError}
                                                    inputRef={(el) => (this.lastNameRef = el)}
                                                    maxlength='35'
                                                    required
                                                    fieldError="bankHolLastNameFieldError"
                                                    describe=""
                                                />
                                                {/*<small id="bankHolLastNameSubText" className="form-help-text text-muted">{this.state.eftSection.lst_nme_inst_txt}</small>*/}
                                                </div>
                                            </EFTForm.Row>
                                            <EFTForm.Row className="mbt-med">
                                                <div>
                                                <div className="uhc-label-help-text">
                                                    <label htmlFor="routNumber" className="eft-label-desc m-0 color-black uhx-flex-grow">
                                                    {this.state.eftSection.routing_lbl}
                                                    </label>
                                                    <PaymentModals
                                                    modalContent={this.state.routingModal}
                                                    modalType='paymentRoutingNumber'
                                                    help_label_txt={this.state.eftSection.help_label_txt}
                                                    />
                                                </div>
                                                {getOneTimePaymentFlag() && (this.state.paymentMethod === "EFT-lastUsed" && this.state.productType === 'SHIP') ?
                                                  <Input
                                                  id="routNumber"
                                                  type="text"
                                                  disabled = "true"
                                                  label={this.state.eftSection.routing_lbl}
                                                  value={"***"+this.state.routingNumber?.slice(-4)}
                                                  changeHandler={this.rountingNumberChangedHandler}
                                                  blurHandler={this.rountingNumberOnBlur}
                                                  error={this.state.routingNumberError}
                                                  inputRef={(el) => (this.routingNumberRef = el)}
                                                  required
                                                  fieldError="routNumberFieldError"
                                                  describe=""

                                              /> :
                                                
                                                <Input
                                                    id="routNumber"
                                                    type="text"
                                                    label={this.state.eftSection.routing_lbl}
                                                    value={this.state.routingNumber}
                                                    changeHandler={this.rountingNumberChangedHandler}
                                                    blurHandler={this.rountingNumberOnBlur}
                                                    error={this.state.routingNumberError}
                                                    inputRef={(el) => (this.routingNumberRef = el)}
                                                    required
                                                    fieldError="routNumberFieldError"
                                                    describe=""

                                                />}

                                                </div>
                                            </EFTForm.Row>
                                            <EFTForm.Row className="mbt-med">
                                                <div>
                                                <div className="uhc-label-help-text">
                                                    <label htmlFor="accountNum" className="eft-label-desc m-0 color-black uhx-flex-grow">
                                                    {this.state.eftSection.acct_lbl}
                                                    </label>
                                                    <PaymentModals
                                                    modalContent={this.state.acctModal}
                                                    modalType='paymentAccNumber'
                                                    help_label_txt={this.state.eftSection.help_label_txt}
                                                    />
                                                </div>
                                                {getOneTimePaymentFlag() && this.state.paymentMethod === "EFT-lastUsed" && this.state.productType === 'SHIP' ?
                                                <Input
                                                    id="accountNum"
                                                    type="text"
                                                    disabled = "true"
                                                    label={this.state.eftSection.acct_lbl}
                                                    value={"***"+this.state.accountNumber?.slice(-4)}
                                                    changeHandler={this.accountNumberChangedHandler}
                                                    blurHandler={this.accountNumberOnBlur}
                                                    error={this.state.accountNumberError}
                                                    inputRef={(el) => (this.accountNumberRef = el)}
                                                    required
                                                    fieldError="accountNumFieldError"
                                                    describe=""

                                                />:
                                                <Input
                                                    id="accountNum"
                                                    type="text"
                                                    label={this.state.eftSection.acct_lbl}
                                                    value={this.state.accountNumber}
                                                    changeHandler={this.accountNumberChangedHandler}
                                                    blurHandler={this.accountNumberOnBlur}
                                                    error={this.state.accountNumberError}
                                                    inputRef={(el) => (this.accountNumberRef = el)}
                                                    required
                                                    fieldError="accountNumFieldError"
                                                    describe=""

                                                />}

                                                </div>
                                            </EFTForm.Row>
                                            <EFTForm.Row className="mbt-lg">
                                                <div className="col-md-12 p-0">
                                                <Typography.Paragraph id="eftNotes" className="uhc-sans-medium token-color-gray-dark-base m-0" style={{ fontSize: '16px' }}>
                                                    {this.state.eftSection.notes}
                                                </Typography.Paragraph>
                                                </div>
                                            </EFTForm.Row>
                                            </EFTForm>
                                              <div className={"row" + (this.state.eftButtonHide ? 'd-none' : 'd-block')} style={{ margin: '1px' }}>
                                                <div
                                                  id="reviewsubmit"
                                                  className="col-12 p-3 tds-card tds-card--secondary m-agreebox"
                                                >

                                                  <button
                                                    name="next"
                                                    aria-disabled="false"
                                                    aria-describedby="eftNotes"
                                                    id="submitBtn"
                                                    className="tds-button tds-button--primary-one tds-margin-xsm-horizontal"
                                                    onClick={this.navigateTo}
                                                  >
                                                    {this.state.eftSection.review_button}
                                                  </button>

                                                  <PaymentModals
                                                    modalContent={this.state.cancelPaymentModal}
                                                    modalType='cancelPayment'
                                                    modalID="cancelPayBtn"
                                                    cancel_btn_txt={this.state.eftSection.cancel_btn_txt}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </fieldset>
                                      </>
                                    </Grid.Item>
                                  ) : null}
                                  
                                    {this.state.superUserError &&
                                      <Notification notificationType="error">{this.state.eftSection.superUserErrorMsg}</Notification>
                                    }
                                    {/* {getProductType() === 'SHIP' && getOneTimePaymentFlag() &&
                                      <Typography.Paragraph className={(this.state.paymentMethod === 'EFT') ? "shipCCMsg" : "shipCCMsg lastUsedEft" } id="eftNotes">
                                        {this.state.auth_ship_cc_msg}
                                      </Typography.Paragraph>
                                      
                                    } */}
                                    {((this.state.productType === 'GOVT' && this.state.paymentMethod === 'EFT-lastUsed') || (this.state.paymentMethod !== 'EFT' && this.state.paymentMethod !== 'CC' && this.state.paymentMethod !== 'EFT-lastUsed') && getOneTimePaymentFlag()) &&
                                      <div className={"row " + (this.state.eftButtonHide ? 'd-none' : 'd-block')} style={{ margin: '1px', marginTop: '1.75rem' }}>
                                      <div
                                        id="reviewsubmit"
                                        className="col-12 p-3 tds-card"
                                      >

                                        <button
                                          name="next"
                                          aria-disabled="false"
                                          id="submitBtn"
                                          className="tds-button tds-button--primary-one tds-margin-xsm-horizontal"
                                          onClick={this.navigateTo}
                                        >
                                          {this.state.eftSection.review_button}
                                        </button>

                                        <PaymentModals
                                          modalContent={this.state.cancelPaymentModal}
                                          modalType='cancelPayment'
                                          modalID="cancelPayBtn"
                                          cancel_btn_txt={this.state.eftSection.cancel_btn_txt}
                                        />
                                      </div>
                                    </div>
                                    }
                                  <Grid.Item>
                                    <div>
                                    {(this.state.paymentMethod === 'CC' || this.state.ccClicked) && 
                                    <Fragment>
                                      <CreditCardGPS 
                                      lastUsedEft={this.state.lastUsedEft}
                                      isPayment = {true} 
                                      productType={getProductType()} 
                                      paymentMethod = {this.state.paymentMethod}
                                      iframeShow={this.state.paymentMethod === 'CC' ? 'show' : 'hide'} 
                                      upg_accountId={appConfigs.isProdEnvi ? commonConstants.UPG_SHIP_PROD_ACCOUNTID : commonConstants.UPG_SHIP_NON_PROD_ACCOUNTID}
                                      addCardText={this.state.cc_header}
                                      />
                                    </Fragment>
                                    }
                                    </div>
                                  </Grid.Item>

                                  {getProductType() === 'GOVT' && getRecurringPaymentFlag() ? <div className="tds-typography__paragraph mt-5 uhc-margin-left-right">

                                    <PaymentModals
                                      modalContent={this.state.ssbModal}
                                      modalType='ssb'
                                      setup_footer_note={this.state.setup_footer_note}
                                      ssb_link_text={this.state.ssb_link_text} />
                                  </div> : null}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/*  */}
                        </div>
                        {this.configureModal()}
                        {this.state.showSessionModal ?
                          <SessionExpireModal showSessionModal={this.state.showSessionModal} />
                          : null}

                      </div>
                    </div>
                    </div>
                  </Grid.Item>
                </div>
              </div>
            )}
        </div>
      </div>
    )
  }
}

export default withRouter(PaymentForm)
