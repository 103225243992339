import { Typography } from "@uhc-tempo/components";
import React, { useState } from "react";
import { getPlanInformation, getMemberFirstName, getMemberLastName, getOneTimePaymentFlag, getRecurringPaymentFlag,
   isManageRecurring, getProductType,
   getPaymentMethod,
   getEftNumber} from '../../../server/util/localStorageRepo';
import Enroll from '../../../src/tokens/icons/icon-enroll.png';
import { convertToCurrency } from '../../../server/util/util'
import secureLocalStorage from "react-secure-storage";


const PaymentSummary = (props) => {
  secureLocalStorage.setItem('totalAmount',props.payAmount);
    const [currentState, ]= useState({
        planInfo: getPlanInformation(),        
        firstName: getMemberFirstName().toLowerCase(),
        lastName: getMemberLastName().toLowerCase()
    })
    
    return (     
        <div id="paymentsummaryID" className="col-md-4 mr-0">
        
          <div className="pl-5 pt-5 pr-5 pb-0">
            <div className="row payment-summary-t">
              <div className="col-10">
                <Typography.H3 className="payment-summary-title-txt">
                  {props.summary}
                </Typography.H3>
              </div>
            </div>
          </div>
          {!getOneTimePaymentFlag() && getRecurringPaymentFlag() && isManageRecurring() ? (
              <Typography.H4 className="enroll_Banner">
              <span>
              <img src={Enroll} alt="" aria-hidden="true"/>
              </span>
              <span>
                {props.enrolltext}
              </span>
            </Typography.H4>
          ) : null }
          

          <div className={(getOneTimePaymentFlag() || (getRecurringPaymentFlag() && !isManageRecurring())) ? "payment-summary-cont mt-5" : "payment-summary-cont"}>

          <ul className="row mt-2">
            <li className="col-md-6">
              <span className="token-font-family-heading-three token-color-gray-dark-base m-0 pad5 rightLineHgt">
                {props.planLabel}
              </span>
            </li>
            <li className="col-md-6">
              <p className="uhc-sans-medium token-color-gray-dark-base m-0 pad5 labelPaddingTop">
                {secureLocalStorage.getItem("shipPlanNamesList") && getProductType() === 'SHIP' ? secureLocalStorage.getItem('multiShipPlanType') : currentState.planInfo.planType}
              </p>
            </li>
          </ul>
          <ul className="row mt-2">
            <li className="col-md-6">
              <span className="token-font-family-heading-three token-color-gray-dark-base m-0 pad5 rightLineHgt">
               {props.membername}
              </span>
            </li>
            <li className="col-md-6">
              <p className="uhc-sans-medium token-color-gray-dark-base mt-2 uhc-bold-capitalize">
              {currentState.firstName}{" "}{currentState.lastName}
              </p>
            </li>
          </ul>
          <ul className="row mt-2">
            <li className="col-md-6">
              <span className="token-font-family-heading-three token-color-gray-dark-base m-0 pad5 rightLineHgt">
                {props.id}
              </span>
            </li>
            <li className="col-md-6">
                  <p className="uhc-sans-medium token-color-gray-dark-base m-0 pad5 labelPaddingTop">{currentState.planInfo.memberNumber}</p>   
            </li>
          </ul>
          {getRecurringPaymentFlag() && !isManageRecurring() ? (
            <ul className="row mt-2">
            <li className="col-md-6">
              <span className="token-font-family-heading-three token-color-gray-dark-base m-0 pad5 rightLineHgt">
                {props.details}
              </span>
            </li>
            <li className="col-md-6">
                  <p className="uhc-sans-medium token-color-gray-dark-base m-0 pad5 labelPaddingTop">{props.setupautopay}</p>   
            </li>
          </ul>
          ) : null}
          {getRecurringPaymentFlag() && isManageRecurring() ? (
            <ul className="row mt-2">
            <li className="col-md-6">
              <span className="token-font-family-heading-three token-color-gray-dark-base m-0 pad5 twoLineTxtLabel">
              {props.currentpaymentmethod}
              </span>
            </li>
            <li className="col-md-6">
              {getPaymentMethod() !== null && getPaymentMethod().indexOf('Credit') > -1 ? (
                <p className="uhc-sans-medium token-color-gray-dark-base m-0 pad5 labelPaddingTop">Credit Card</p>
              ) : null }
              {getPaymentMethod() !== null && getPaymentMethod().indexOf('EFT') > -1 ? (
                <p className="uhc-sans-medium token-color-gray-dark-base m-0 pad5 labelPaddingTop">{ getProductType() === 'SHIP' ? (getPaymentMethod().toLowerCase().indexOf('checking') > -1 ? props.eftchecking : props.eftsavings):props.eftchecking}{getEftNumber()}</p>
              ) : null }
              
              {getPaymentMethod() !== null && getPaymentMethod().indexOf('CMS-SSA/RRB') > -1 ? (
                <p className="uhc-sans-medium token-color-gray-dark-base m-0 pad5 labelPaddingTop">CMS-SSA/RRB (SSA Deduction)</p>
              ) : null }  
            </li>
          </ul>
          ) : null}
          {getOneTimePaymentFlag() ? (
              <ul className="row mt-2">
              <li className="col-md-6">
                <span className="token-font-family-heading-three token-color-gray-dark-base m-0 pad5 rightLineHgt">
                {props.details}
                </span>
              </li>
              <li className="col-md-6">
                <p className="uhc-sans-medium token-color-gray-dark-base m-0 pad5 labelPaddingTop">{props.onetimepayment}</p>
                
                <p className="serverdate">
                    {props.serverDate}
                </p>
                
              </li>
            </ul>
          ) :null}
          
          {getOneTimePaymentFlag() ? (
            <div aria-live="polite" role="status">
            <ul className="row mt-2"> 
              <li className="col-md-6">
                <span className="token-font-family-heading-three m-0 pad5 totalLabel">
                {props.totallabel}
                </span>
              </li>
              <li className="col-md-6">
              <p className="tds-header__h3 token-font-family-heading-three pad5">
              {`${(/\d+/g).test(props.payAmount.replace(/\$/g,'')) ? '$' + convertToCurrency(props.payAmount.replace(/\$/g,'').replace(',','')) : props.payAmount}`}</p>
              </li>
            </ul>
          </div>
            ) :null}
          

          </div>
      </div>
    );
}
export default PaymentSummary