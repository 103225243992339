import React, { useState } from "react";
import { getPlanInformation, getMemberFirstName, getMemberLastName, getOverviewPageLink,getOneTimePaymentFlag, getRecurringPaymentFlag, isManageRecurring, isEditPayment, getProductType } from '../../../server/util/localStorageRepo';
import { trackLinkClick } from '../../../server/util/analytics'
import './PaymentReceipt.css'
import { ReactComponent as PDFLOGO } from '../../tokens/icons/icon_pdf-blue.svg'
import { Button, Typography } from '@uhc-tempo/components'
import { convertToCurrency } from '../../../server/util/util';
import secureLocalStorage from "react-secure-storage";

export default React.forwardRef(function PaymentReceipt(props,ref) {
  const [currentState,]= useState({
    planInfo: getPlanInformation(),
    firstName: getMemberFirstName().toLowerCase(),
    lastName: getMemberLastName().toLowerCase(),
    productType: getPlanInformation().productType,
})

const navigateTo = () => {window.location.assign(getOverviewPageLink());trackLinkClick('Return to payment overview')}

let monthlyPaymentPremium = props.monthlyPaymentPremium
monthlyPaymentPremium = monthlyPaymentPremium !== null && monthlyPaymentPremium !== '' && monthlyPaymentPremium !== 'null' ? '$'+ convertToCurrency(monthlyPaymentPremium)+'/mo' : null

return (
  <div id={window.innerWidth < 770 ? 'payment-receipt-box' : 'payment-receipt-box-mt'} className="col-md-5 mr-0 p-0">
                        <div className="d-print-none  payment-receipt-bottom-box " style={{ margin: '1px' }}>
                            <div className="tds-card__content">
                              <Typography.Paragraph>
                                {getOneTimePaymentFlag() ? props.another_pay_due_note : props.isCmsSsa ? props.ssarrb_receipt_pay_note : !isManageRecurring() ? props.another_pay_due_note_1 : props.manage_pay_due_note}
                              </Typography.Paragraph>
                              <br/>
                              <div align ="center">
                                <Button
                                  buttonType="secondary-one"
                                  className="tds-margin-sm-top-only"
                                  disabledBorder={false}
                                  id="returnPaymentOverviewBtn"
                                  loading={false}
                                  size="md"
                                  onClick={navigateTo}
                                >
                                  {props.return_pay_overview_lbl}
                                </Button>
                              </div>
                            </div>
                          </div>
<div className="payment-receipt-bottom-box-top-line"></div>
  <div className="payment-summary-cont-confirm">
  <br/>
  <br/>
    <div className="col-12 uhc-print-link">
        <PDFLOGO/>
        <button
            style={{cursor : 'pointer'}}
            className="tds-link tds-link--medium-one linkBtn"
            rel=""
            onClick={()=> {window.print();trackLinkClick('view/print the payment confirmation')}}
            role="link"
            >
          {props.view_print_pdf}
          </button>
          {/* {<ReactToPdf targetRef={ref} filename={getOneTimePaymentFlag() ? "one-time-payment.pdf" : "auto-payment.pdf"} x={50} y={10} options={options}>
            {({ toPdf }) => (<button
            style={{cursor : 'pointer'}}
            className="tds-link tds-link--medium-one linkBtn"
            rel=""
            onClick={toPdf}
            >
          {props.view_print_pdf}
          </button>)}
         </ReactToPdf>} */}
    </div>
    <br/>
    <div className="row payment-summary-t">
      <div className="col-10">
        <Typography.H3>{props.receipt_label}</Typography.H3>
      </div>
    </div>
    <div className={(props.isCmsSsa || (getProductType() === 'GOVT' && getRecurringPaymentFlag())) ? 'uhc-receipt-label noMarginTop' : 'uhc-receipt-label'}>
    {(getOneTimePaymentFlag() || isEditPayment()) && !props.isCmsSsa ?
      <ul className="row mt-2">
      <>
        <li className="col-5">
          <span className="token-font-family-heading-three token-color-gray-dark-base m-0 pad5 rightLineHgt">
            {props.reference_label_text}
          </span>
        </li>
        <li className="col-7">
          <p className="uhc-sans-medium token-color-gray-dark-base m-0 pad5 labelPaddingTop">
            {secureLocalStorage.getItem("confirmationNumber")}
          </p>
          <br />
        </li>
        </>
      </ul>
      : null }
      {(props.isCmsSsa || (getProductType() === 'GOVT' && getRecurringPaymentFlag())) ?
      <ul className="row mt-2 norefnum">
        <li className="col-12">{props.ssa_rrb_no_ref_num_note}</li>
      </ul>
    :null}
    <ul className="row mt-2">
       <li className="col-5">
        <span className="token-font-family-heading-three token-color-gray-light-base m-0 pad5 rightLineHgt">
          {props.plan_label}
        </span>
      </li>
      <li className="col-7">
        <p className="uhc-sans-medium token-color-gray-dark-base m-0 pad5 labelPaddingTop">
          {secureLocalStorage.getItem("shipPlanNamesList") && getProductType() === 'SHIP' ? secureLocalStorage.getItem('multiShipPlanType') : currentState.planInfo.planType}
        </p>
        <br />
      </li>
    </ul>
    <ul className="row mt-2">
      <li className="col-5">
        <span className="token-font-family-heading-three token-color-gray-dark-base m-0 pad5 rightLineHgt">
         {props.name_label}
        </span>
      </li>
      <li className="col-7">
        <p className="uhc-sans-medium token-color-gray-dark-base mt-2 uhc-bold-capitalize">
        {currentState.firstName}{" "}{currentState.lastName}
        </p>
        <br />
      </li>
    </ul>
    <ul className="row mt-2">
      <li className="col-5">
        <span className="token-font-family-heading-three token-color-gray-dark-base m-0 pad5 rightLineHgt">
          {props.id_label}
        </span>
      </li>
      <li className="col-7">
      <p className="uhc-sans-medium token-color-gray-dark-base m-0 pad5 labelPaddingTop">{currentState.planInfo.memberNumber}</p>
      <br />
      </li>
    </ul>
    {props.isCmsSsa ? (
      <>
      <ul className="row mt-2">
        <li className="col-5">
            <span className="token-font-family-heading-three token-color-gray-dark-base m-0 pad5 rightLineHgt">
              {props.details_label}
            </span>
        </li>
        <li className="col-7">
            <p className="uhc-sans-medium token-color-gray-dark-base m-0 pad5 labelPaddingTop">
              {props.payment_method}
            </p>
        </li>
      </ul>
      <ul className="row mt-2 pt-2">
        <li className="col-5">
            <span className="token-font-family-heading-three token-color-gray-dark-base m-0 pad5 noLineHgt">
              {props.ssarrb_estimated_dt}
            </span>
        </li>
        <li className="col-7">
            <p className="uhc-sans-medium token-color-gray-dark-base m-0 pad5 labelPaddingTop">
              {props.ssarrb_start_date}
            </p>
        </li>
      </ul>
      </>
    ):null}
    {getOneTimePaymentFlag() || isEditPayment() ? (
    <ul className="row mt-2">
      <li className="col-5">
        <span className="token-font-family-heading-three token-color-gray-dark-base m-0 pad5 rightLineHgt">
          {props.details_label}
        </span>
      </li>
      <li className="col-7">
        <p className="uhc-sans-medium token-color-gray-dark-base m-0 pad5 labelPaddingTop">
        {props.payment_method}
          </p>
        <br />
        <p className="uhc-sans-medium token-color-gray-dark-base m-0 pad5 labelPaddingTop">
        {props.paymentDetails}
        </p>
        <br />
      </li>
      <li className="col-5">
        <span className="token-font-family-heading-three token-color-gray-dark-base m-0 pad5 rightLineHgt">
        </span>
      </li>
      <li className="col-7">
        <p className="uhc-sans-medium token-color-gray-dark-base m-0 pad5 labelPaddingTop">
       {getProductType() === 'GOVT' ? props.withdrawalDate : getProductType() === 'SHIP' && props.paymentMethodSelected === "CC" ? props.eaipTransactionDate : props.serverDate}
        </p>
        <br />
      </li>
    </ul>
  ) :null}

{getRecurringPaymentFlag()? (
     <ul className="row mt-2">
     <li className="col-5">
       <span className="token-font-family-heading-three token-color-gray-dark-base m-0 pad5 rightLineHgt">
         {props.details_label}
       </span>
     </li>
     <li className="col-7">
     {props.cancelRecChecked ? (
       null
     ) :
     <div>
     <p className="uhc-sans-medium token-color-gray-dark-base m-0 pad5 labelPaddingTop">
       {props.payment_method}
     </p>
      <br />
      </div>
     }

       <p className="uhc-sans-medium token-color-gray-dark-base m-0 pad5 labelPaddingTop">
       {props.paymentDetails}
       </p>
       <br />

       <p className="uhc-sans-medium token-color-gray-dark-base m-0 pad5 labelPaddingTop">
              {props.cancelRecChecked ? '' : props.pay_rcpt_auto_pay_note }
       </p>

       <br />
     </li>
     {(getProductType() === 'SHIP' && isManageRecurring()) || getProductType() === 'GOVT' ?
      <>
     <li className="col-5">
       <span className="token-font-family-heading-three token-color-gray-dark-base m-0 pad5 rightLineHgt">
         {!props.cancelRecChecked ? (
           props.pay_rcpt_auto_pay_start_dt_lbl
         ) : props.pay_rcpt_auto_pay_stop_dt_lbl}
       </span>
     </li>
     <li className="col-5">
       <p className="uhc-sans-medium token-color-gray-dark-base m-0 pad5 labelPaddingTop">
       {props.start_date}
       </p>
       <br />
     </li>
     </>
     : null}

   </ul>
) :null}
    </div>
    {getProductType() === 'SHIP' && !props.cancelRecChecked && getRecurringPaymentFlag() && !isManageRecurring() ? (
             <div className="uhc-receipt--total-label" aria-live="polite" role="status">
               <ul className="row mt-2">
                <li className="col-5">
                  <span className="token-font-family-heading-three m-0 pad5 totalLabel">
                  {props.total_label}
                  </span>
                </li>
                <li className="col-7">
                  <p className="tds-header__h3 token-font-family-heading-three pad5" style={{ fontSize: '22px' }}>
                      {props.setup_rec_total_text}
                  </p>
                  <br />
                </li>
             </ul>
           </div>
    ):null}

    {getOneTimePaymentFlag() || isEditPayment() ? (
      <div aria-live="polite" role="status">
        <ul className="row mt-4">
          <li className="col-5">
            <span className="token-font-family-heading-three totalLabel">
              {props.total_label}
            </span>
          </li>
          <li className="col-7">
          <p className="paymentTotalAmt fedtotal token-font-family-heading-three pad5" style={{ fontSize: '36px' }}>
          {`${Number.isNaN(props.paidAmount) ? '$' + convertToCurrency(props.paidAmount.replace('$','')) : '$' + convertToCurrency(props.paidAmount.replace('$',''))}`}</p>
          </li>
        </ul>
    </div>
    ) :null}

{getProductType() === 'GOVT' && getRecurringPaymentFlag() && monthlyPaymentPremium !== null && !props.cancelRecChecked 
&& !isManageRecurring() ? (
    <div aria-live="polite" role="status">
    <ul className="row mt-4">
      <li className="col-5">
        <span className="token-font-family-heading-three m-0 pad5 totalLabel">
          {props.total_label}
        </span>
      </li>
      <li className="col-7">
      <p className="paymentTotalAmt token-font-family-heading-three pad5" style={{ fontSize: '36px' }}>
       {monthlyPaymentPremium}</p>
      </li>
    </ul>
    </div>
    ) : null }

    {getProductType() === 'GOVT' && getRecurringPaymentFlag() && monthlyPaymentPremium === null && !props.cancelRecChecked 
    && !isManageRecurring() ? (
      <div class="uhc-receipt--total-label" aria-live="polite" role="status">
        <ul class="row">
          <li className="col-5">
            <span className="token-font-family-heading-three m-0 pad5 totalLabel">
            {props.total_label}
            </span>
          </li>
          <li className="col-7">
            <p className="tds-header__h3 token-font-family-heading-three pad5" style={{ fontSize: '22px' }}>
              {props.setup_rec_total_text}
            </p>
            <br />
          </li>
        </ul>
    </div>
    ) : null }
    <div className="d-print-none row mt-3">
                            {!props.cancelRecChecked && !props.isCmsSsa ? (
                              <p className="col-12">
                              {props.view_your_txt}{' '}
                              <button
                                  onClick={props.scrollToESignBox}
                                  id="link-btn"
                                  className="tds-link-btn"
                                  rel=""
                                  role="link"
                                >
                                {props.ele_pay_auth_sign_lnk}
                                </button>{' '} {props.below_text_label}
                            </p>
                            ):null}

      </div>
  </div>
</div>
    );
})
