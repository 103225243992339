import React, { Component } from 'react'
import { Typography, RadioButton } from '@uhc-tempo/components'
import {Grid, Typography as TempoTypo } from '@uhc/pattern-library'
import './PaymentForm.css'
import {CurrencyInput as NonAndroidCurrencyInput } from '../../UI/CurrencyInput/CurrencyInput'
import { Markup } from 'interweave'
import {getRecurringPaymentFlag, isEditPayment } from '../../../server/util/localStorageRepo'
import PaymentModals from '../modals/PaymentModals'
import { getProductType } from '../../../server/util/localStorageRepo';
import { convertToCurrency } from '../../../server/util/util';
import CurrencyInput from 'react-currency-input-field';
import { isAndroid } from '../shared-components/detectDevice'
import secureLocalStorage from 'react-secure-storage'

const FedPaymentAmount = (props) =>{
    const areChargesMoreThanEAPR = () => {
      return (parseFloat(props.currentCharges) > parseFloat(props.estimatedAnnualPremium) && parseFloat(props.pastAmountDue) > parseFloat(props.estimatedAnnualPremium) && parseFloat(props.pastAmountAndCurentCharges) > parseFloat(props.estimatedAnnualPremium));
    }

    return (
      <>
                    {!getRecurringPaymentFlag() ? (
                        <div>
                        <Grid.Item>
                              
                              <div className="uhc-padding-top-bottom uhc-flex">
                                  <Typography.H3 className="tds-header__h3 uhx-flex-grow" id="paymentAmtRadios">
                                    
                                    {isEditPayment() ? '1. ' + props.selectAmntCnt.auth_pa_header_section_1 :  '1. ' + props.selectAmntCnt.auth_spa_header_section_1}
                                  </Typography.H3>
                                 { !isEditPayment() && 
                                  <PaymentModals
                                  modalContent={props.amtExplained}
                                  modalType="paymentamtexplained"
                                  />
                                 }
                              </div>
                            
                          </Grid.Item>
                          <Grid.Item>
                        
                            <div className={props.amtselectcss === 'error' && window.innerWidth < 770 ? 'amntSelectAlert' : ''}>
                              <fieldset aria-labelledby="paymentAmtRadios">
                            <legend className="sr-only">{props.selectAmntCnt.auth_spa_header_section_1}</legend>
                            <div className="align-items-center p-0">
                            <div className="sel-amt-row">
                                {props.pastAmountDueFound === true && !isEditPayment() && (parseFloat(props.pastAmountDue) <= parseFloat(props.estimatedAnnualPremium)) ? (
                                    <div className="col-sm-4 col-md-4 col-lg-4 mb-sm-2 border-bottom-xs border-top-xs pl-0">
                                    <RadioButton 
                                        id="pastAmountDueFound" 
                                        label={props.selectAmntCnt.auth_spa_radio_1} 
                                        onChange={props.handleAmountSelection} 
                                        checked = {props.pastAmountDueChecked}
                                        value={props.selectAmntCnt.auth_spa_radio_1}
                                        aria-describedby="duetodayamt duetodaydate"
                                        name="payAmount"/>
                                    
                                    <Typography.Paragraph  className="m-0 ml-lg-4 dueAmt" id="duetodayamt" style={{ color: '#002677 !important' }}>
                                            ${convertToCurrency(props.pastAmountDue)}
                                    </Typography.Paragraph>
                                    <Typography.Paragraph  className="m-0 ml-lg-4 pt-1" id="duetodaydate" style={{ fontSize: '14px' }}>
                                      {props.selectAmntCnt.auth_due_label} {props.firstDayOfMonth}
                                    </Typography.Paragraph>
                                </div>
                                    
                                ) : null}
                                
                                {props.pastAmountAndCurentChargesFound === true && !isEditPayment() && (parseFloat(props.pastAmountAndCurentCharges) <= parseFloat(props.estimatedAnnualPremium)) ? (
                                    <div className="col-sm-5 col-md-5 col-lg-5 mb-sm-2 border-bottom-xs pl-0">
                                        <RadioButton 
                                            id="pastAmountAndCurentChargesFound" 
                                            label={props.selectAmntCnt.auth_spa_radio_2} 
                                            value={props.selectAmntCnt.auth_spa_radio_2}
                                            name="payAmount"
                                            onChange={props.handleAmountSelection}
                                            aria-describedby="duetodayincomingamt incomingduedate"
                                            checked={props.pastAmountAndCurentChecked}/>
                                        
                                        <Typography.Paragraph  id="duetodayincomingamt" className="m-0 ml-lg-4 dueAmt">
                                                ${convertToCurrency(props.pastAmountAndCurentCharges)}
                                        </Typography.Paragraph>
                                        <Typography.Paragraph  id="incomingduedate" className="m-0 ml-lg-4 pt-1" style={{ fontSize: '14px' }}>
                                        {props.selectAmntCnt.auth_due_label} {props.currentPaymentDate}
                                        </Typography.Paragraph>
                                    </div>
                                ) : null}
                                {props.onlyCurrentChargesFound === true && !isEditPayment() && (parseFloat(props.currentCharges) <= parseFloat(props.estimatedAnnualPremium))? (
                                    <div className="col-sm-5 col-md-5 col-lg-5 mb-sm-2 border-bottom-xs pl-0">
                                    <RadioButton 
                                        id="onlyCurrentChargesFound" 
                                        label={props.selectAmntCnt.auth_upcoming_charges_label}
                                        value={props.selectAmntCnt.auth_upcoming_charges_label}
                                        name="payAmount"
                                        onChange={props.handleAmountSelection}
                                        aria-describedby="upcomingchargesamt upcomingchargesduedate"
                                        checked={props.onlyCurrentChargesChecked}/>
                                    
                                    <Typography.Paragraph  id="upcomingchargesamt" className="m-0 ml-lg-4 dueAmt">
                                            ${convertToCurrency(props.currentCharges)}
                                    </Typography.Paragraph>
                                    <Typography.Paragraph  className="m-0 ml-lg-4 pt-1" id="upcomingchargesduedate" style={{ fontSize: '14px' }}>
                                    {props.selectAmntCnt.auth_due_label} {props.currentPaymentDate}
                                    </Typography.Paragraph>
                                </div>
                                ) : null}
                                {getProductType() === 'GOVT' && secureLocalStorage.getItem('estimatedAnnualPremium') > 0 && props.paymentsMethodValue?.indexOf('CMS-SSA/RRB') == -1 && !isEditPayment() ? (
                                    <div className="col-sm-5 col-md-5 col-lg-5 mb-sm-2 border-bottom-xs pl-0">
                                    <RadioButton 
                                        id="payInFull" 
                                        label={props.selectAmntCnt.auth_pay_in_full}
                                        value={props.selectAmntCnt.auth_pay_in_full}
                                        name="payAmount"
                                        onChange={props.handleAmountSelection}
                                        aria-describedby="payinfullamt"
                                        checked={props.payInFullChecked || areChargesMoreThanEAPR()}/>
                                    
                                    <Typography.Paragraph  id="payinfullamt" className="mb-3 ml-lg-4 dueAmt">
                                        ${convertToCurrency(secureLocalStorage.getItem('estimatedAnnualPremium'))}
                                    </Typography.Paragraph>
                                    
                                </div>
                                ) : null}
                                    <div className={!isEditPayment() ? "col-sm-3 col-md-3 col-lg-3 mb-sm-2 border-bottom-xs uhc-border-none pl-0" : "col-sm-3 col-md-3 col-lg-3 mb-sm-2 border-none-updamt uhc-border-none pl-0"}>
                                        {!isEditPayment() && <RadioButton 
                                            id="otherAmount" 
                                            label={props.selectAmntCnt.auth_spa_radio_3}
                                            value={props.selectAmntCnt.auth_spa_radio_3}
                                            name="payAmount"
                                            onChange={props.handleAmountSelection}
                                            checked={props.otherAmountChecked}/>}
                                        <div className={isEditPayment() ? "oa-int-wrp-updt" : "oa-int-wrp"}> 
                                        <div className={isEditPayment() ? null: "input-OtherAmt"} >
                                            {isEditPayment() ? <label htmlFor="otherAmountInput">{props.selectAmntCnt.auth_update_amount_label}</label> : <label htmlFor="otherAmountInput" className="sr-only">{props.selectAmntCnt.auth_spa_radio_3}</label>}
                                            {!isAndroid() ?
                                            <NonAndroidCurrencyInput
                                                id="otherAmountInput"
                                                max={9999999}
                                                aria-required="true"
                                                onValueChange={props.handleOtherAmountSelection}
                                                onValueBluer={props.validateOtherAmountValue}
                                                className={
                                                'oa-input-control form-control ' + props.otherAmountErrorclassName
                                                }
                                                style={{ textAlign: 'left' }}
                                                value={props.otherAmountDisplay}
                                                onFocus={props.handleEmptyOtherAmount}
                                                inputRef={props.otherAmountRef}
                                                labelledby="enterAmtInput"
                                            />
                                            :
                                            <CurrencyInput 
                                              id="otherAmountInput"
                                              placeholder='$0.00'
                                              allowDecimals
                                              decimalScale={2}
                                              value={props.otherAmountDisplay}
                                              maxLength={8}
                                              prefix='$'
                                              decimalSeparator='.'
                                              groupSeparator=','
                                              decimalsLimit={2}
                                              intlConfig={{ locale: 'en-US',  currency: 'USD'}}
                                              onValueChange={(value) => props.handleOtherAmountSelection(value, false)}
                                              onBlur={() => props.validateOtherAmountValue()}
                                              onFocus={props.handleEmptyOtherAmount}
                                              className={
                                                'oa-input-control form-control ' + props.otherAmountErrorclassName
                                                }
                                            />
                                              }
                                            </div>
                                            </div>
                                        <Typography.Paragraph  className="m-0 ml-lg-4 enterAmt" id="enterAmtInput">
                                              {props.selectAmntCnt.auth_enteramt_label}
                                        </Typography.Paragraph>
                                        <div className="ml-4">{props.otherAmountError}</div>
                                    </div>
                                </div>
                                </div>
                                </fieldset>
                            </div>
                          </Grid.Item>
                          
                          <Grid.Item>
                            <div className="uhc-note-padding mt-2 mb-5">
                                <div className="tds-typography__paragraph p-2">
                                   {isEditPayment() ? <Markup content={props.selectAmntCnt.auth_fed_updt_otp_note}/> : <Markup content={props.selectAmntCnt.auth_payamt_sec_note} />} 
                                </div>
                            </div>
                          </Grid.Item>
                          {props.cMSSSAFound === 'true' ? (
                            <Grid.Item>
                              <div className="float-L">
                                <Typography.Paragraph className="p-2">
                                  <Markup content={props.section_1_note_2} />
                                </Typography.Paragraph>
                              </div>
                            </Grid.Item>
                          ) : null}
                          </div>
                    ) : null}         
      </>
    )
}

export default FedPaymentAmount;