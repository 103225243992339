import { Link, Typography, Card, PopupTip, Notification, Button } from '@uhc-tempo/components';
import moment from 'moment';
import React, { Component, Fragment} from 'react';
import PaymentModals from '../../modals/PaymentModals'
import alerticon from '../../../assets/images/banner_alert_icon.png';
import flagicon from '../../../assets/images/overpaymentFlag_icon.png';
import successicon from '../../../assets/images/noDue_icon.png';
import { overviewSummAem } from '../../paymentOverview/paymentOverview';
import { Markup } from 'interweave';
import { isDesktop } from '../../../../server/util/util';
import './PlanHeader.css';
import { convertToCurrency } from '../../../../server/util/util';
import { trackLinkClick } from '../../../../server/util/analytics';
import secureLocalStorage from 'react-secure-storage';
import appConfigs from '../../../../server/app.config';

export class PlanHeader extends Component {

    constructor(props) {
        super(props);

        this.state = {
            pastDueShow: true,
            overPaymentShow: true,
            paidInFullShow: true,
            isPreEffectiveStatus : true,
            isbMonthlyPremium: null,
            isISBBanner : sessionStorage.getItem('isISBBanner') ? JSON.parse(sessionStorage.getItem('isISBBanner')) : false,
            m3pBannerShow: this.props.m3pBannerShow
        };

    }

    

    learnwaysContent = () => {
        switch (this.props.plan.planCategory) {
            case 'MA':
            case 'ma':
                return overviewSummAem.auth_learnways_modal_content_ma
            case 'MAPD':
            case 'mapd':
                return overviewSummAem.auth_learnways_modal_content_mapd
            case 'PDP':
            case 'pdp':
                return overviewSummAem.auth_learnways_modal_content_pdp
            default:
                return overviewSummAem.auth_learnways_modal_content_fed
        }
    }
    learnWaysModal = {
        header: overviewSummAem.auth_learn_ways_link,
        desc: this.props.plan.productType === 'SHIP' ? overviewSummAem.auth_learnways_modal_content_ship : this.learnwaysContent(),
        btn_txt: overviewSummAem.auth_close_btn_text
    }

    creditBalanceDetails = {
        header: overviewSummAem.auth_credit_bal_details_header,
        desc: overviewSummAem.auth_credit_bal_details_content,
        btn_txt: overviewSummAem.auth_close_btn_text
    }

    isbViewDetails = {
        header: overviewSummAem.auth_isb_modal_header,
        desc: overviewSummAem.auth_isb_modal_content,
        btn_txt: overviewSummAem.auth_close_btn_text
    }

    createDiscountMap = (label) => {
        let map = new Map()
        label.split(',').forEach(el=> {
            map.set(el.split(':')[0].trim().toLowerCase(), el.split(':')[1].trim())
        })
        return map
    }

    shipPremBrkDownModal = {
        header: overviewSummAem.auth_ship_prem_brkdwn__mdl_header.replace('currentMonth', moment().format('MMMM')).replace('currentYear', moment().format('YYYY')),
        householdContent: overviewSummAem.auth_ship_prem_brkdwn_hh_desc,
        householdheading: overviewSummAem.auth_household_heading,
        discounttablehead: overviewSummAem.auth_disc_table_heading,
        amounttablehead:  overviewSummAem.auth_amt_table_heading,
        householdinstext:  overviewSummAem.auth_hh_ins_note,
        householdtotallabel:  overviewSummAem.auth_hh_total_label,
        breakdownheadertext:  overviewSummAem.auth_username_brkdwn_heading,
        coveragetablehead:  overviewSummAem.auth_coverage_table_heading,
        yourportionhhtotal: overviewSummAem.auth_your_portion_hh_total_text,
        brkdwninstext: overviewSummAem.auth_your_portion_hh_note,
        btn_txt: overviewSummAem.auth_close_btn_text,
        discountMap : this.createDiscountMap(overviewSummAem.isb_auth_discount_text),
        brkdwninstext_isb: overviewSummAem.auth_isb_prem_break_modal_note,
        isb_total_label: overviewSummAem.isb_Total_Label
    }

    monthlyPremium = () => {
        if (this.props.plan.productType === 'SHIP') {
            if(!(this.props.planData?.isPreEffective) && this.props.plan.planStatus === 'ACTIVE' && this.props?.readBillingAndTransDtl?.monthlyPremium == null) {
                return "$0.00";
            }
            return this.props?.readBillingAndTransDtl?.monthlyPremium ? '$' + convertToCurrency(this.props?.readBillingAndTransDtl?.monthlyPremium) : overviewSummAem.auth_not_available_yet
        } else {
            let monthlyPremium = this.props?.readBillingAndTransDtl?.premium ? this.props?.readBillingAndTransDtl?.premium.basePremium : null
            if (this.props.planData?.isPreEffective || this.props.planData?.isFirstBillGen) {
                return overviewSummAem.auth_not_available_yet
            }
            if (monthlyPremium !== null && monthlyPremium !== '') {
                return '$' + convertToCurrency(monthlyPremium)
            } else if (monthlyPremium === null || monthlyPremium === '') {
                return overviewSummAem.auth_not_available_yet
            }
        }
    }

    goToM3pPage = () => {
        window.location.href = `${appConfigs.aem_base_url}${overviewSummAem.auth_m3p_suffix_url}`
    }
    isbMonthlyPremium = () => {
        if (this.props.plan.productType === 'SHIP') {
            let consumerDetails = JSON.parse(localStorage.getItem('consumerDetails'));
            let planEffectiveDate=moment(consumerDetails.planProfiles[0]?.planStartDate).format('YYYY-MM-DD');
            if(this.props.plan.planStatus === 'ACTIVE' && this.props.isbBillingPayments?.BillingPaymentResponse?.MonthlyPremium.premiumList == null) {
                if(this.props.isbBillingError) {
                    return overviewSummAem.auth_not_available_yet;
                }
                return "$0.00";
            }
            let sortISBMonthlyPremium = this.props.isbBillingPayments?.BillingPaymentResponse?.MonthlyPremium.premiumList
            let lastPremiumIndex = sortISBMonthlyPremium.length -1
            let newestPremium = sortISBMonthlyPremium[lastPremiumIndex];
            if(moment(newestPremium.coverageMonth).format('YYYY-MM-DD').includes('2024-05-01')){
                return overviewSummAem.auth_not_available_yet;
            }
            if(moment(newestPremium.coverageMonth).format('YYYY-MM-DD') === planEffectiveDate){
                return '$' + sortISBMonthlyPremium[lastPremiumIndex].premiumAmount
            }
            if (moment(newestPremium.coverageMonth).month() > moment().month() && moment(newestPremium.coverageMonth).year() === moment().year()) {
                return '$' + sortISBMonthlyPremium[lastPremiumIndex-1].premiumAmount
            } else if (moment(newestPremium.coverageMonth).month() === moment().month() && moment(newestPremium.coverageMonth).year() === moment().year()) {
                return '$' + sortISBMonthlyPremium[lastPremiumIndex].premiumAmount
            } else {
                return overviewSummAem.auth_not_available_yet;
            }  
        }
    }
    premiumAmountDueCal=() =>{
        if(this.props.premiumBreakDownResults && this.props.premiumBreakDownResults.individualPremiumDetails && 
            this.props.premiumBreakDownResults.individualPremiumDetails.length > 0){
                let amountdue=0.00;
                this.props.premiumBreakDownResults.individualPremiumDetails.map(premiumDetails =>{
                    if(premiumDetails.premiumDueAmount)
                        amountdue=amountdue+ parseFloat(premiumDetails.premiumDueAmount)
                })
                return amountdue;
            }
        return "Not available"
    }

    showIsbPastDue = () => {
        if (this.props.customerAccountSummary?.currentBillingStatus?.pastDueAmount > 0) {
            return (
                <Fragment>
                  <div className="pastDueBanner">
                      {isDesktop() ?
                      <Notification
                      dismissClickHandler={() => this.setState({pastDueShow: false})}
                      notificationType="warning">
                          <Markup content= {overviewSummAem.auth_payment_past_banner_note_isb}/>
                          <div className="makePaymentLink"><Link  href="javascript:" onClick={() => document.getElementById('makeOneTime_' + this.props.planNum).click()}>{overviewSummAem.auth_past_make_payment_link}</Link></div>
                      </Notification>
                          :
                      <Fragment>
                          <img src={alerticon} alt="" aria-hidden="true"/>
                          <Markup content= {overviewSummAem.auth_payment_past_banner_note_isb} />
                          
                          <Link  href="javascript:" onClick={() => document.getElementById('makeOneTime_' + this.props.planNum).click()}>{overviewSummAem.auth_past_make_payment_link}</Link>
                          <button aria-label="close payment past due banner" onClick={() => this.setState({pastDueShow: false})}>
                          <svg width="14" height="14" viewBox="0 0 14 14" focusable="false" aria-hidden="true" contentid="1"><title>Close modal</title><defs><rect id="modal-close-1" x="0" y="0" width="14" height="14" rx="8"></rect></defs><g fill="none" fillRule="evenodd"><path d="M14 1.41L12.59 0 7 5.59 1.41 0 0 1.41 5.59 7 0 12.59 1.41 14 7 8.41 12.59 14 14 12.59 8.41 7z" fill="#000"></path></g></svg>
                          </button>
                      </Fragment>
                          }
                   </div>
               </Fragment>
              )
        }
    }

    showPastDue = () => {

        if (this.props?.readBillingAndTransDtl?.totalAmountDue > 0 && (!this.props.planData?.isPreEffective || this.props.planData?.isFirstBillGen)) {
            return (
              <Fragment>
                <div className="pastDueBanner">
                    {isDesktop() ?
                    <Notification
                    dismissClickHandler={() => this.setState({pastDueShow: false})}
                    notificationType="warning">
                        <Markup content= {overviewSummAem.auth_payment_past_banner_note}/>
                        <div className="makePaymentLink"><Link  href="javascript:" onClick={() => document.getElementById('makeOneTime_' + this.props.planNum).click()}>{overviewSummAem.auth_past_make_payment_link}</Link></div>
                    </Notification>
                        :
                    <Fragment>
                        <img src={alerticon} alt="" aria-hidden="true"/>
                        <Markup content= {overviewSummAem.auth_payment_past_banner_note} />
                        
                        <Link  href="javascript:" onClick={() => document.getElementById('makeOneTime_' + this.props.planNum).click()}>{overviewSummAem.auth_past_make_payment_link}</Link>
                        <button aria-label="close payment past due banner" onClick={() => this.setState({pastDueShow: false})}>
                        <svg width="14" height="14" viewBox="0 0 14 14" focusable="false" aria-hidden="true" contentid="1"><title>Close modal</title><defs><rect id="modal-close-1" x="0" y="0" width="14" height="14" rx="8"></rect></defs><g fill="none" fillRule="evenodd"><path d="M14 1.41L12.59 0 7 5.59 1.41 0 0 1.41 5.59 7 0 12.59 1.41 14 7 8.41 12.59 14 14 12.59 8.41 7z" fill="#000"></path></g></svg>
                        </button>
                    </Fragment>
                        }
                 </div>
             </Fragment>
            )
        }
    }

    isbShowOverPayment = () => {
        let creditBalance = this.props.customerAccountSummary?.currentBillingStatus.creditOnAccount ? this.props.customerAccountSummary?.currentBillingStatus.creditOnAccount : '0.00';
        let pastDueAmount = this.props.customerAccountSummary?.currentBillingStatus.pastDueAmount ? this.props.customerAccountSummary?.currentBillingStatus.pastDueAmount : '0.00';
        let isOverPaid = false;
        isOverPaid = creditBalance > 0 && creditBalance !== null && (pastDueAmount === '0' || pastDueAmount === '0.00') ? true : false;
        if (isOverPaid) {
            return (<Fragment>
                <div className="overPaymentBanner">
                    <Notification
                        dismissClickHandler={() => this.setState({overPaymentShow: false})}
                        notificationType="info">
                        <Markup content= {overviewSummAem.auth_overpayment_banner.replace('balanceCredit', convertToCurrency(creditBalance))}/>
                    </Notification>
                </div>
            </Fragment>)
        }
    }

    showOverPayment = () => {
        let isOverPaid = false
        let creditBalance = this.props?.readBillingAndTransDtl?.creditBalance
        if (this.props.plan.productType === 'SHIP') {
            let totalAmountDue = this.props?.readBillingAndTransDtl?.totalAmountDue
            isOverPaid = creditBalance !== null && creditBalance > 0 && !this.props?.readBillingAndTransDtl?.paidInFull &&
                (totalAmountDue == '0' || totalAmountDue == '0.00') ? true : false
        }else if(this.props.searchPaymentWalletResults && this.props.searchPaymentWalletResults.currentPaymentMethod.indexOf('CMS-SSA/RRB') > -1){
            isOverPaid=false
        }else {
            let estAnnlPrm = this.props?.readBillingAndTransDtl?.estimatedAnnualPremium
            isOverPaid = estAnnlPrm != null && estAnnlPrm !== '0.00' && creditBalance !== null && creditBalance > 0 ? true : false
        }
        if (isOverPaid) {
            return (<Fragment>
                <div className="overPaymentBanner">
                    <Notification
                        dismissClickHandler={() => this.setState({overPaymentShow: false})}
                        notificationType="info">
                        <Markup content= {overviewSummAem.auth_overpayment_banner.replace('balanceCredit', convertToCurrency(creditBalance))}/>
                    </Notification>
                </div>
            </Fragment>)
        }
    }

    creditBalanceExists = () => {
        if (this.props?.readBillingAndTransDtl?.creditBalance !== null && this.props?.readBillingAndTransDtl?.creditBalance > 0) {
            return true;
        }
        return false;
    }
    
    showPaidinFull = () => {
        let isPaidInFull = false
        if(this.props.plan.productType === 'SHIP') {
            return false
        }
        if(this.props.searchPaymentWalletResults && this.props.searchPaymentWalletResults.currentPaymentMethod.indexOf('CMS-SSA/RRB') > -1){
            isPaidInFull=false
        }else {
            isPaidInFull = this.props?.readBillingAndTransDtl?.estimatedAnnualPremium !== null && this.props?.readBillingAndTransDtl?.estimatedAnnualPremium === '0.00' ? true : false
            isPaidInFull = isPaidInFull && !this.props.isPreEffective
        }
        if (isPaidInFull ) {
            return (<Fragment>
                <div className="paidInFullBanner">
                    <Notification
                        dismissClickHandler={() => this.setState({paidInFullShow: false})}
                        notificationType="success">
                        {this.creditBalanceExists() ?
                        <Fragment>
                            <Markup content= {overviewSummAem.auth_paid_in_full_banner + '<p>' + overviewSummAem.auth_credit_bal_text  +  this.props?.readBillingAndTransDtl?.creditBalance + '.</p>'}/> 
                            <PaymentModals
                                modalContent={this.creditBalanceDetails}
                                modalType='creditBalanceDetails'
                                creditBalanceLink={overviewSummAem.auth_credit_bal_details_link}
                            />
                            </Fragment> :
                        <Markup content= {overviewSummAem.auth_paid_in_full_banner}/>
                        }
                    </Notification>
                </div>
            </Fragment>)
        }
    }



    showISBBanner() {
       return(
           <Fragment>
            {this.state.isISBBanner && this.props.hideISBBanner === false &&
                <div className="isbBanner">
                <Notification
                dismissClickHandler={() => {trackLinkClick(overviewSummAem.auth_isb_banner_heading.toLowerCase().replace('.' , '') + ':banner:close icon'); this.props.closeISBBanner()}}
                notificationType="info">
                <Fragment>
                <Typography.H3>{overviewSummAem.auth_isb_banner_heading}</Typography.H3>
                <div className="isbBannerContent">
                     <Markup content= {overviewSummAem.auth_ISB_Banner_Content}/> 
                 </div>
                 {/* <div className="IsbBannerModal">
                 <PaymentModals
                    modalContent={this.isbViewDetails}
                    modalType='isbViewDetails'
                    isbViewDetailsLink={overviewSummAem.auth_isb_view_details_link}
                />
                </div> */}
                </Fragment>
            </Notification>
            </div>
            }
            </Fragment>
       )
    }
    
    showPreEffectiveBanner = () =>{
        let eap = this.props?.readBillingAndTransDtl?.estimatedAnnualPremium ? parseFloat(this.props?.readBillingAndTransDtl?.estimatedAnnualPremium) : 0
         if(this.props.plan.productType === 'GOVT' && ((this.props.planData?.isPreEffective && eap === 0) || this.props.planData?.isPreEffective && !this.props.planData?.isFirstBillGen)) {
            return (
                <Fragment>
                <div className="preEffectiveBanner">
                    <Notification
                        dismissClickHandler={() => this.setState({isPreEffectiveStatus: false})}
                        notificationType="success">
                        <Markup content= {overviewSummAem.auth_preeffective_status_banner_txt}/>
                    </Notification>
                </div>
            </Fragment>
            )
        }
      }
    
    showM3pBanner = () => {
        if (this.props.m3pBannerShow) {
            return (
                <Fragment>
                    <div className="m3pBanner">
                        <Notification
                            dismissClickHandler={() => this.props.closeM3PBanner()}
                            notificationType="info">
                            <div className='m3p-banner-content'>
                                <Typography.H1>{overviewSummAem.auth_m3p_banner_header}</Typography.H1>
                                <Markup content= {overviewSummAem.auth_m3p_banner_text}/>
                            </div>
                            <Button onClick={() => this.goToM3pPage()}>{overviewSummAem.auth_m3p_banner_button_text}</Button>
                        </Notification>
                    </div>
                </Fragment>
            )
        }
    }

    componentDidMount() {
        let el = document.getElementById('planName_' + this.props.planNum)
        let planNameID = el.getElementsByTagName('h2')[0]
        if(planNameID) {
            planNameID.setAttribute('id','planNameID_' + this.props.planNum)
        }
        if(this.props.shipPlanNamesList.length > 1 && this.props.plan.productType === 'SHIP') {
           secureLocalStorage.setItem("multiShipPlanType", overviewSummAem.auth_ship_header_title)
        }
    }

    getPlanNameUI() {
        return (
            <div className={ this.props.plan?.productType === 'SHIP' ? "planName ship" : "planName" } id={'planName_' + this.props.planNum}>
                    {this.props.fedShipPlanCount > 1 ? (
                        <>
                        <Typography.Paragraph>{overviewSummAem.auth_plan_text} {this.props.planNum + 1} {overviewSummAem.auth_of_text} {this.props.fedShipPlanCount} </Typography.Paragraph>
                        <br></br>
                        </>
                    ) : null}

                    <Typography.H2 className="tds-card__header tds-margin-none">{this.props.shipPlanNamesList.length > 1 && this.props.plan.productType === 'SHIP' ? overviewSummAem.auth_ship_header_title : this.props.plan.planType}</Typography.H2>
                    <Typography.H3 className="tds-text__paragraph tds-margin-sm-top-only planTypeNames">{this.props.shipPlanNamesList.length > 1 && this.props.plan.productType === 'SHIP' ? this.props.shipPlanNamesList.map(name => name.planName).join(', ') : this.props.plan.planName}</Typography.H3>
                </div>
        )
    }

    getModalAndMonthlyPremUI() {
        return (
            <div className={ this.props.plan?.productType === 'SHIP' ? "payInfo ship" : "payInfo" }>
                    <PaymentModals
                        modalContent={this.learnWaysModal}
                        modalType='learnwaystopay'
                        learnwayslinktitle={overviewSummAem.auth_learn_ways_link}
                        learnwayscontent={this.props.plan.productType === 'SHIP' ? overviewSummAem.auth_learnways_modal_content_ship : overviewSummAem.auth_learnways_modal_content_fed}
                    />
                    
                    <div className="monthlyPremium" id={'monthlyPremium_' + this.props.planNum}>
                        <h3 className="monthlyPremiumAmt">{this.props.plan.productType === 'SHIP' ? this.isbMonthlyPremium() + ' ' : this.monthlyPremium() + ' '}/</h3>
                        <span> {overviewSummAem.auth_mo_text}</span>
                        <PopupTip
                            icon="QuestionMark"
                            iconColor="primary"
                            placement="bottom"
                            contentId={'monthly_' + this.props.planNum}
                            title={overviewSummAem.auth_monthly_tooltip_heading}
                        >
                                {this.props.plan.productType === 'SHIP' ? (
                                    <>
                                        <Markup content={overviewSummAem.auth_monthly_tooltip_content_ship_isb}/>
                                    </>
                                ) : <> <Markup content={overviewSummAem.auth_monthly_tooltip_content_fed}/> </>}
                        </PopupTip>
                        
                    </div>
                </div>
        )        
    }

    render() {
        return (
            <div className="title-header">
                <Fragment>
                    {this.getPlanNameUI()}
                    {this.getModalAndMonthlyPremUI()}
                    {this.props.plan.productType === 'SHIP' && this.showISBBanner()}
                </Fragment>
                {this.state.pastDueShow ? this.props.plan.productType === 'SHIP' ? this.showIsbPastDue() : this.showPastDue() : null }
                {this.state.overPaymentShow ? this.props.plan.productType === 'SHIP' ? this.isbShowOverPayment() : this.showOverPayment() : null }
                {this.state.paidInFullShow ? this.showPaidinFull() : null } 
                {this.state.isPreEffectiveStatus ? this.showPreEffectiveBanner() : null }
                {this.props.m3pBannerShow ? this.showM3pBanner() : null }
            </div>
        )
    }
}

export default PlanHeader
