import axios from './ucpPayments-api';
import appConfigs from '../app.config';
import { getDecStr} from '../../server/util/encDec';
export const getCustomerAccountSummary = async (customerId) => {
    var customerAccountRequest = {
        "customerAccountSummary": {
            "request": {
              "customerId": customerId ? customerId : '',
              "customerType": "COMPAS"
            }
          }
    };

    let accNumber = null;
    let routingNumber = null;

	return axios.post(appConfigs.customer_account_summary_service_path, customerAccountRequest)
              .then(response => {
               if(response?.data?.response?.currentPaymentMethodList !=null && response?.data?.response?.currentPaymentMethodList.length > 0)  {
                  response.data.response.currentPaymentMethodList.map(payment => {
                    if ((payment.bankAccountNumber !== null || payment.bankAccountNumber !== '' ) && (payment.bankRoutingNumber !== null || payment.bankRoutingNumber !== '')) {
                         accNumber = getDecStr(payment.bankAccountNumber);
                         routingNumber = getDecStr(payment.bankRoutingNumber)
                         payment.bankAccountNumber = accNumber;
                        payment.bankRoutingNumber = routingNumber
                     }
                     return payment;
                 })
                }
                return response.data;
            }) 
		.catch((error) => {
            console.log(error.message);
            return {
                callFailed: true,
                errorResponse: error?.response?.data
            };
		});

};


export default getCustomerAccountSummary;