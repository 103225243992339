import React, { Component, Fragment } from 'react'
import { Select, Typography, Button, Pagination} from '@uhc-tempo/components';
import { overviewBillPayAem } from '../paymentOverview/paymentOverview';
import { overviewSummAem } from '../paymentOverview/paymentOverview';
import PaymentModals from '../modals/PaymentModals';
import LocalPrintshop from '../../tokens/icons/local_printshop';
import DownloadIcon from '../../tokens/icons/icon_download';
import PrintHistory from '../PrintHistory/PrintHistory';
import commonConstants from '../../../server/util/commonConstants';
import { isDesktop } from '../../../server/util/util';
import { Markup } from 'interweave';
import { trackLinkClick, trackExitLinkClick} from '../../../server/util/analytics';
import { isAndroid } from '../shared-components/detectDevice';
import FedPaymentHistory from './FedPaymentHistory';
import ShipPaymentHistory from './ShipPaymentHistory';
import { isEditPayment } from '../../../server/util/localStorageRepo';
import moment from 'moment';
import secureLocalStorage from 'react-secure-storage';

let rhmpbtnid = null;

export class PaymentHistory extends Component {

    
    constructor(props) {
        super(props);
        this.scrollTimeOut = null;
        this.state = {
            selectedValue: this.props.selectedValue,
            isOpen: false,
            lastPostIndex: 0,
            firstPostIndex: 0,
            currentPosts: [],
            processedCurrentPage: this.props.currPage,
            pendingCurrentPage: this.props.currPage,
            pendingTabFocus: false,
            processedTabFocus: true
        }

        this.refreshHistoryInfo = this.refreshHistoryInfo.bind(this);
        this.refreshIsbHistoryInfo = this.refreshIsbHistoryInfo.bind(this);
        this.getDateSelectorUI = this.getDateSelectorUI.bind(this);
        this.toggleDropDown = this.toggleDropDown.bind(this);
        this.pagination = this.pagination.bind(this);
        this.closeDropDown = this.closeDropDown.bind(this);
        this.printEvent = this.printEvent.bind(this);
        this.changePage = this.changePage.bind(this);
    }

    componentWillUnmount() {
        this.props.setCurrentPage(this.state.currentPage, 'Payment')
    }

    toggleDropDown(e) {
        this.setState(prev => ({
            isOpen: !prev.isOpen
        }))
    }

    closeDropDown() {
        if (this.state.isOpen) {
            this.setState({ isOpen: false })
        }
    }

    updateCurrentRecords() {
        if (this.state.processedTabFocus) {
            let currentPosts = this.props.paymentHistoryData?.slice(this.state.firstPostIndex, this.state.lastPostIndex);
            this.setState({currentPosts: currentPosts });
        } else if (this.state.pendingTabFocus) {
            let currentPosts = this.props.pendingPaymentData?.data?.slice(this.state.firstPostIndex, this.state.lastPostIndex);
            this.setState({currentPosts: currentPosts });
        }
    }

    getFirstPost() {
        this.setState(
            {
                firstPostIndex: this.state.lastPostIndex - (isDesktop() ? commonConstants.DESKTOP_PAGINATION_RECORDS : commonConstants.MOBILE_PAGINATION_RECORDS)
            }, () => this.updateCurrentRecords())
    }

    getLastPost() {
        this.setState({
            lastPostIndex: (this.state.processedTabFocus ? this.state.processedCurrentPage : this.state.pendingCurrentPage) * (isDesktop() ? commonConstants.DESKTOP_PAGINATION_RECORDS : commonConstants.MOBILE_PAGINATION_RECORDS)
        }, () => this.getFirstPost())
    }

    paginationScroll() {
        if (isDesktop()) {
            if (this.props.tabName === 'Payment') {
                setTimeout(() => {
                    this.scrollTimeOut = document.getElementById('accordionContent_' + this.props.planNum).scrollIntoView({ behavior: 'smooth', block: 'start', inline:'start' });
                }, 250)
            }
        } else {
            if (isAndroid()) {
                setTimeout(() => {
                    this.scrollTimeOut = document.getElementById('tabPayment_' + this.props.planNum).scrollIntoView({behavior: 'smooth', block: 'start'});
                }, 250)  
            } else {
                setTimeout(() => {
                    this.scrollTimeOut = document.getElementById('tabPayment_' + this.props.planNum).scrollIntoView(true);
                }, 250)
            }
        }
    }

    changePage(e, page) {
        this.setState({
            processedCurrentPage: this.state.processedTabFocus ? page : this.state.processedCurrentPage,
            pendingCurrentPage: this.state.pendingTabFocus ? page : this.state.pendingCurrentPage 
        }, () => {
            this.closeDropDown()
            this.paginationScroll();
            this.getLastPost();
        })
    }

    refreshIsbHistoryInfo(e) {
        let fromDate = ''
        let toDate = moment().format('YYYY-MM-DD')
        if (e.target.value === 'payment.history.search.ninety.days') {
            fromDate = moment().subtract(90, 'days').format('YYYY-MM-DD')
        } else if (e.target.value === 'payment.history.search.six.months') {
            fromDate = moment().subtract(6, 'months').format('YYYY-MM-DD')
        } else if (e.target.value === 'payment.history.search.twelve.months') {
            fromDate = ''
        } else if (e.target.value === 'payment.history.search.twentyfour.months') {
            fromDate = ''
        } else if (e.target.value === 'payment.history.search.previous.year') {
            let previousYear = moment().subtract(1, 'years');
            toDate = previousYear.endOf('year').format('YYYY-MM-DD');
            fromDate = '';
        }
        trackLinkClick(e.target.value === 'payment.history.search.ninety.days' ? overviewBillPayAem.auth_last_90_days :
            e.target.value === 'payment.history.search.six.months' ? overviewBillPayAem.auth_last_6_months :
                e.target.value === 'payment.history.search.twelve.months' ? overviewBillPayAem.auth_last_12_months :
                    e.target.value === 'payment.history.search.twentyfour.months' ? overviewBillPayAem.auth_last_24_months :
                        e.target.value === 'payment.history.search.previous.year' ? overviewBillPayAem.auth_prev_cal_year : null);
        this.props.changeDateRange(true, 'Payment', 'historyTable', e.target.value);
        if(this.props.plan.productType === 'SHIP' && this.props.shipPlanDetails && this.props.shipPlanDetails.length > 0) {
            this.props.getIsbBillingPayments(this.props.shipPlanDetails[0]?.individualId, toDate, fromDate, e.target.value);
        }
        this.setState({ selectedValue: e.target.value })
        this.getLastPost();
    }

    refreshHistoryInfo(e) {
        console.log(e); 
        trackLinkClick(e.target.value === 'payment.history.search.ninety.days' ? overviewBillPayAem.auth_last_90_days :
            e.target.value === 'payment.history.search.six.months' ? overviewBillPayAem.auth_last_6_months :
                e.target.value === 'payment.history.search.twelve.months' ? overviewBillPayAem.auth_last_12_months :
                    e.target.value === 'payment.history.search.twentyfour.months' ? overviewBillPayAem.auth_last_24_months :
                        e.target.value === 'payment.history.search.previous.year' ? overviewBillPayAem.auth_prev_cal_year : null);
        this.props.changeDateRange(true, 'Payment', 'historyTable', e.target.value);
        this.props.callHistoryApi(e.target.value, true);
        this.setState({ selectedValue: e.target.value })
        this.getLastPost();
    }

    paymentterms = {
        header: overviewBillPayAem.auth_pay_understand_modal_header,
        desc: this.props.plan.productType === 'SHIP' ? overviewBillPayAem.auth_pay_understand_modal_content_ship_isb : overviewBillPayAem.auth_pay_understand_modal_content,
        btn_txt: overviewSummAem.auth_close_btn_text
    }   
    pagination() {
        const historyType = this.state.processedTabFocus ? this.props.paymentHistoryData?.length : this.props.pendingPaymentData?.data?.length
        const maxRecords = Math.ceil(historyType/commonConstants.DESKTOP_PAGINATION_RECORDS)
        if (historyType > commonConstants.DESKTOP_PAGINATION_RECORDS) {
            return (
                <div className={isDesktop() ? "paginate-contact-row" : "mobile-paginate-contact-row"} id="pagination">
                    <Pagination 
                        page={this.state.processedTabFocus ? this.state.processedCurrentPage : this.state.pendingCurrentPage}
                        maxPage={maxRecords}
                        onPageChange={this.changePage}
                    />
                </div>
            )

        } else {
            return (
                <Fragment>
                    <div className={isDesktop() ? 
                            "paginate-contact-row-empty" 
                            : ((this.state.processedTabFocus && (this.props.paymentHistoryData === null || this.props.paymentHistoryData?.length === 0)))? 
                            "mobile-paginate-contact-row noProcessed" 
                            : 
                            (this.props.pendingPaymentData?.data === null && this.state.pendingTabFocus) ?
                            "mobile-paginate-contact-row noPending"
                            :
                            "mobile-paginate-contact-row"
                            } >
                        {((this.state.processedTabFocus && this.props.paymentHistoryData) || (this.state.pendingTabFocus && this.props.pendingPaymentData?.data?.length > 0)) &&
                            <div className="pagination">
                                <Typography.Paragraph>Page 1 of 1</Typography.Paragraph>
                            </div>
                        }
                    </div>
                </Fragment>
            )
        }
    }

    //Check if previous payment history data changed after date range selection
    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.paymentHistoryData !== this.props.paymentHistoryData) ){
            this.setState({ currentPosts: [], processedCurrentPage: 1, firstPostIndex: 0, lastPostIndex: 0 }, () => {
                clearTimeout(this.scrollTimeOut);
                this.getLastPost();
            })
        }
    }

    

    componentDidMount() {
        if (isEditPayment()) {
            secureLocalStorage.setItem('editPayment', false)
        }
        this.setState({ pendingCurrentPage: 1, processedCurrentPage: 1 })
        this.getLastPost();
        
        if(isDesktop() && document.getElementById('php_link_id')){
            document.getElementById('php_link_id').onclick=function(){
                trackExitLinkClick("view my people's health plans payment history")
                console.log("view my people's health health plans payment history")
                }
        }
    }

    printEvent() {
        trackLinkClick('print payment history');
        let mywindow = window.open('', 'PRINT', 'height=800,width=1200');
        let titleText = '<title>Print: My Payment History Details</title>'
        var styleCSS = '<style>p{font-size:14px !important; padding-bottom:0px !important}table{border-spacing: 0px;border-collapse: collapse;}table td, table th {padding: 10px; border: 1px solid #e0e0e0; max-width: 230px;}.hidden-lg {display: none!important;}table.table-responsive tr:not(:first-child) td { border-top: none;}table.table-responsive tr:not(:first-child):nth-child(2n) {background-color: #f5f5f5; -webkit-print-color-adjust: exact; color-adjust: exact;}table.table-responsive tr:not(:first-child):nth-child(2n+1) {background-color: #fff;} .para-space {line-height:0.5;}</style>'
        let content = '<?doctype><html><head>' + titleText + styleCSS + '</head><body>' + document.getElementById('printPayHistoryTable_' + this.props.planNum).innerHTML + '</body></html>'
        mywindow.document.write(content)
        setTimeout(function () {
            mywindow.document.close();
            mywindow.focus();
            mywindow.print();
        }, 5000);

        setTimeout(function () {
            mywindow.close();
        }, 5200);
    }

    makePaymentHistoryXls() {
        trackLinkClick('download payment history');
        let titleText = '<title>Print: My Payment History Details</title>'
        let styleCSS = '<style>b{font-weight: lighter !important;}table{border-spacing: 0px;border-collapse: collapse;}table td, table th {padding: 15px; border: 1px solid #e0e0e0; word-break: break-word; max-width: 230px;mso-number-format:"\@";}.hidden-lg {display: none!important;}table.table-responsive tr:not(:first-child) td { border-top: none;}table.table-responsive tr:not(:first-child):nth-child(2n) {background-color: #f5f5f5;-webkit-print-color-adjust: exact; color-adjust: exact;}table.table-responsive tr:not(:first-child):nth-child(2n+1) {background-color: #fff;} .para-space {line-height:0.5;}</style>'
        let content = '<?doctype><html><head>' + titleText + styleCSS + '</head><body>' + document.getElementById('printPayHistoryTable_' + this.props.planNum).innerHTML + '</body></html>'
        let fileName = this.props.plan.productType === 'SHIP' ? 'MedSuppPaymentHistory.xls' : 'FedPaymentHistory.xls'
        var csvData = new Blob([content]);
        var csvURL = null;
        if (navigator.msSaveBlob) {
            csvURL = navigator.msSaveBlob(csvData, 'download.xls');
        } else {
            csvURL = window.URL.createObjectURL(csvData);
        }
        var tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', fileName);
        tempLink.click();
    }



    getPrintDownloadUI() {
        if (((this.props.pendingPaymentData?.data !== null && this.props.pendingPaymentData?.data?.length > 0) && this.state.pendingTabFocus && (!this.props.isbBillingError && !this.props.searchPaymentHistoryError)) ||
             (this.props.paymentHistoryData !== null && this.state.processedTabFocus) && !this.props.planData?.isFirstBillGen) {
            return (
                <div className="exportLinks">
                    <div className="exportLabel exportPrintLabel" id={'exportPrintLabel_' + this.props.planNum}>
                        <button role="link" id={'exportPrintLabelBtn_' + this.props.planNum} onClick={() => this.printEvent()} className="tds-link tds-link--medium tds-typography__link"><LocalPrintshop /> <span>{overviewBillPayAem.auth_print_link}</span> </button>
                    </div>

                    <div className="exportLabel downloa_CSV" id={'exportDownloadLabel_' + this.props.planNum}>
                        <button role="link" onKeyDown={this.handleKeyDownLink} className="tds-link tds-link--medium tds-typography__link" id={"download_CSV_" + this.props.planNum} onClick={() => this.makePaymentHistoryXls()} > <DownloadIcon /><span className="download-csv-btn">{overviewBillPayAem.auth_download_csv}</span></button>
                    </div>
                </div>
            )
        }
    }

    getHistoryHeadingUI() {
        return (
            <Fragment>
                <Typography.H2>{overviewBillPayAem.auth_my_pay_hist_head}</Typography.H2>
                {this.props.plan.productType === 'SHIP' ? (
                    <Markup content={overviewBillPayAem.auth_my_pay_hist_notes} />
                ) : this.props.plan?.productType === 'GOVT' ? this.props.planData?.paymentMethod?.indexOf('CMS-SSA') > -1 ? <Markup className="historyNotes" content={overviewBillPayAem.auth_my_bill_hist_notes_fed_cms_ssa.replace('billing', 'payment')}/> :  <Markup className="historyNotes" content={overviewBillPayAem.auth_my_pay_hist_notes_fed} /> : <Markup className="historyNotes" content={overviewBillPayAem.auth_my_pay_hist_notes_fed} />}
            </Fragment>
        )
    }

    getDateSelectorUI() {
        return (
          <Select
            onChange={this.props.plan.productType === 'SHIP' ? this.refreshIsbHistoryInfo : this.refreshHistoryInfo}
            aria-labelledby={'paymentSelect_' + this.props?.planNum}
            onKeyDown={this.handleKeySelLink}
            id={'paymentSelect_' + this.props?.planNum}
            value={this.state?.selectedValue}
          >
            <Select.Option value="payment.history.search.ninety.days">
              {overviewBillPayAem.auth_last_90_days}
            </Select.Option>
            <Select.Option value="payment.history.search.six.months">
              {overviewBillPayAem.auth_last_6_months}
            </Select.Option>
            <Select.Option value="payment.history.search.twelve.months">
              {overviewBillPayAem.auth_last_12_months}
            </Select.Option>
            <Select.Option value="payment.history.search.twentyfour.months">
              {overviewBillPayAem.auth_last_24_months}
            </Select.Option>
            <Select.Option value="payment.history.search.previous.year">
              {overviewBillPayAem.auth_prev_cal_year}
            </Select.Option>
          </Select>
        )
    }

    getPaymentsTermUI() {
            return (
                <Fragment>
                    <div className={this.state.pendingTabFocus ? "pending_processed":"pending_processed processed"}>
                        <div className="pendingProcessed_tabs_wrapper">
                            <div className="pendingProcessed_tabs" 
                                    role="tablist" 
                                    aria-label="pending & processed tabs"
                                    style={{filter: this.props.loadingHistTable === true ? 'blur(4px)':'',
                                            pointerEvents: this.props.loadingHistTable === true ? 'none' : ''}}>
                                    <Button 
                                        buttonType={this.state.processedTabFocus ? 'primary-one' : 'primary-two'}
                                        className={this.state.processedTabFocus ? 'processedTab active':'processedTab'} 
                                        role="tab" 
                                        onKeyDown={this.handleProcessedKeyPress}
                                        aria-controls={'processed_history_data_' + this.props.planNum} 
                                        aria-selected="false"
                                        tabIndex={this.getPrintDownloadUI() ? '-1' : '0'}
                                        id={'processedBtn_' + this.props.planNum} 
                                        onClick={e => this.choosePendingProcessedTab(e)}>
                                        {overviewBillPayAem.auth_processed_tab_txt}
                                    </Button>
                                    <Button
                                        buttonType={this.state.pendingTabFocus ? 'primary-one' : 'primary-two'}
                                        aria-controls={!this.props.pendingPaymentAPIError ? 'pending_history_data_' + this.props.planNum : 'service_Error'} 
                                        role="tab" 
                                        tabIndex="-1" 
                                        aria-selected="true" 
                                        onKeyDown={this.handlePendingKeyPress}
                                        className={this.state.pendingTabFocus ? 'pendingTab active':'pendingTab'}
                                        id={'pendingBtn_' + this.props.planNum} 
                                        onClick={e => this.choosePendingProcessedTab(e)}>
                                        {overviewBillPayAem.auth_pending_tab_txt}
                                    </Button>
                                </div>
                            </div>
                            <div className="pendingProcessed_header_wrapper">
                                {!isDesktop() && this.state.pendingTabFocus &&
                                        <div className="pendingHeader mobile">
                                            <Typography.H2>{this.state.pendingTabFocus && (this.props.pendingPaymentData?.data?.length > 0 && !this.props.pendingPaymentAPIError) ? overviewBillPayAem.auth_pay_pending_header : overviewBillPayAem.auth_pay_pending_header.replace(/\*/g, '')}</Typography.H2>
                                        </div>
                                }
                                {!isDesktop() && 
                                this.state.processedTabFocus &&
                                    <div className="processedHeader mobile">
                                    <Typography.H2>{overviewBillPayAem.auth_pay_processed_header}</Typography.H2>
                                </div>
                                }

                                <div className={this.state.processedTabFocus ? "paymentterm":" paymentterm pending"}>
                                    <PaymentModals
                                        modalContent={this.paymentterms}
                                        modalType='paymentterms'
                                        planNum={this.props.planNum}
                                        paymentTermLink={this.handleKeyTermLink}
                                        paymenttermslinktitle={overviewBillPayAem.auth_understand_pay_terms_link}
                                    />
                                </div>
                            </div>
                    </div>
                </Fragment>
            )
    }

    

    choosePendingProcessedTab(e) {
        const { id } = e.target;
        if (id === 'pendingBtn_' + this.props.planNum) {
            if (this.props.pendingPaymentData === null) {
            }
            if (this.state.pendingTabFocus !== true) {
                this.setState({
                    pendingTabFocus: true,
                    processedTabFocus: false
                }, () => {
                    this.changePage(e, this.state.pendingCurrentPage)
                })
                trackLinkClick('mr.premium-payments.toggle-pending')
            }
            document.getElementById('pendingBtn_' + this.props.planNum).setAttribute('aria-selected','true');
            document.getElementById('processedBtn_' + this.props.planNum).setAttribute('aria-selected','false');
            document.getElementById('pendingBtn_' + this.props.planNum).setAttribute('tabindex','0');
            document.getElementById('processedBtn_' + this.props.planNum).setAttribute('tabindex','-1');

            
        } else if (id === 'processedBtn_' + this.props.planNum) {
            if (this.state.processedTabFocus !== true) {
                this.setState({
                    pendingTabFocus: false,
                    processedTabFocus: true
                }, () => {
                    this.changePage(e, this.state.processedCurrentPage)
                })
                trackLinkClick('mr.premium-payments.toggle-processed')
            }
            document.getElementById('pendingBtn_' + this.props.planNum).setAttribute('aria-selected','false');
            document.getElementById('processedBtn_' + this.props.planNum).setAttribute('aria-selected','true');
            document.getElementById('pendingBtn_' + this.props.planNum).setAttribute('tabindex','-1');
            document.getElementById('processedBtn_' + this.props.planNum).setAttribute('tabindex','0');
        }
    }
    
    getDesktopTableHeadUI() {
        return (
            <Fragment>
                    <div className="paymentHistoryHeader">
                        <div className="historyHeading">
                        
                            {this.getHistoryHeadingUI()}
                            {this.getPaymentsTermUI()}
                        </div>
                        { ((this.state.processedTabFocus) || (this.state.pendingTabFocus))&&
                        <div className="printDownload">
                            { (this.state.processedTabFocus && this.props.paymentHistoryData?.length > 0) || (this.state.pendingTabFocus && this.props.pendingPaymentData?.data?.length > 0) ?
                            <>{this.getPrintDownloadUI()}</>:null}
                                {(this.props.paymentHistoryData?.length > 0) || (this.props.pendingPaymentData?.data?.length > 0) ?
                                <div className={ (this.state.pendingTabFocus) ||
                                                ((this.props.paymentHistoryData?.length < 1 || this.props.paymentHistoryData === null) && this.state.processedTabFocus) || this.props.planData?.isFirstBillGen ? 'noDateSelector' : 'dateSelector'}>
                                    <label htmlFor={'paymentSelect_' + this.props.planNum} className="dateSelectorLabel">{overviewBillPayAem.auth_show_label}</label> 
                                    {this.getDateSelectorUI()}
                                </div>:null}
                        </div>
                        }
                    </div>
            </Fragment>
        )
    }

    handlePendingKeyPress = (event) => {
        if(event.key === 'ArrowRight'){
            document.getElementById('processedBtn_' + this.props.planNum).click();
            document.getElementById('processedBtn_' + this.props.planNum).focus();
        } else if(event.key === 'ArrowLeft') {
            document.getElementById('processedBtn_' + this.props.planNum).click();
            document.getElementById('processedBtn_' + this.props.planNum).focus();
        }

        if (!event.shiftKey && event.key === "Tab") {
            event.preventDefault();
            document.getElementById('paymentTermID_' + this.props.planNum).focus();
        }

        if (event.shiftKey && event.key === "Tab") {
            event.preventDefault();
            if(document.getElementById('download_CSV_' + this.props.planNum)){
                document.getElementById('download_CSV_' + this.props.planNum).focus();
            } else {
                document.getElementById('tabPayment_' + this.props.planNum).focus();
            }
        } 
        
    }

    handleProcessedKeyPress = (event) => {
        if(event.key === 'ArrowRight'){
            document.getElementById('pendingBtn_' + this.props.planNum).click();
            document.getElementById('pendingBtn_' + this.props.planNum).focus();
        } else if(event.key === 'ArrowLeft') {
            document.getElementById('pendingBtn_' + this.props.planNum).click();
            document.getElementById('pendingBtn_' + this.props.planNum).focus();
        }

        if (!event.shiftKey && event.key === "Tab") {
            event.preventDefault();
            document.getElementById('paymentTermID_' + this.props.planNum).focus();
            document.getElementById('processedBtn_' + this.props.planNum).setAttribute('tabindex', '-1');
        }

        if (event.shiftKey && event.key === "Tab") {
            event.preventDefault();
            if(this.props.plan.productType === 'GOVT' ){
                if(this.state.processedTabFocus === true && rhmpbtnid){
                    rhmpbtnid.focus();
                    document.getElementById('pendingBtn_' + this.props.planNum).setAttribute('tabindex', '-1');
                } else if(this.state.processedTabFocus === true && document.getElementById('download_CSV_' + this.props.planNum)){
                    document.getElementById('download_CSV_' + this.props.planNum).focus();
                    document.getElementById('pendingBtn_' + this.props.planNum).setAttribute('tabindex', '-1');
                } else {
                    let tabPayment = document.getElementById('historyTabs_' + this.props.planNum);
                    let paymentTabId = tabPayment.children[0].children[0].children[0].children[0].id
                    let paymentTab = document.getElementById(paymentTabId);
                    paymentTab.focus();
                }
            }

            if(this.props.plan.productType === 'SHIP'){
                if(this.state.processedTabFocus === true && document.getElementById('download_CSV_' + this.props.planNum)){
                    document.getElementById('download_CSV_' + this.props.planNum).focus();
                    document.getElementById('pendingBtn_' + this.props.planNum).setAttribute('tabindex', '-1');
                } else {
                    let tabPayment = document.getElementById('historyTabs_' + this.props.planNum);
                    let paymentTabId = tabPayment.children[0].children[0].children[0].children[0].id
                    let paymentTab = document.getElementById(paymentTabId);
                    paymentTab.focus();
                }
            } 
        }

        
    }

    handleKeyDownLink = (event) => {
        if (!event.shiftKey && event.key === "Tab") {
            event.preventDefault();
            if(this.props.plan.productType === 'GOVT' && document.getElementById('php_link_id')){
                if(document.getElementById('php_link_id')){
                    document.getElementById('php_link_id').focus(); 
                } 
            }
            if ((this.props.plan.productType === 'GOVT' && !document.getElementById('php_link_id')) || this.props.plan.productType === 'SHIP'){
                if (this.state.pendingTabFocus === true) {
                    document.getElementById('pendingBtn_' + this.props.planNum).focus();
                    document.getElementById('pendingBtn_' + this.props.planNum).setAttribute('tabindex', '0');
                    document.getElementById('processedBtn_' + this.props.planNum).setAttribute('tabindex', '-1');
                } else {
                    document.getElementById('processedBtn_' + this.props.planNum).focus();
                    document.getElementById('pendingBtn_' + this.props.planNum).setAttribute('tabindex', '-1');
                    document.getElementById('processedBtn_' + this.props.planNum).setAttribute('tabindex', '0');
                }
            }
            
            
        }

        if (event.shiftKey && event.key === "Tab") {
            if(this.props.plan.productType === 'GOVT' || this.props.plan.productType === 'SHIP'){
                if (this.state.pendingTabFocus === true) {
                    document.getElementById('pendingBtn_' + this.props.planNum).setAttribute('tabindex', '-1');
                } else {
                    document.getElementById('processedBtn_' + this.props.planNum).setAttribute('tabindex', '-1');
                }
            }
            
        }
    }

    rhmpKeyEvent = (event) => {
        if (!event.shiftKey && event.key === "Tab"){
            event.preventDefault();
            if(this.state.processedTabFocus === true && document.getElementById('processedBtn_' + this.props.planNum)){
                document.getElementById('processedBtn_' + this.props.planNum).focus();
            }
        }
        
    }
    tooltipAnalytics =() =>{
        trackLinkClick('why am i seeing this? - tool tip')
        console.log('why am i seeing this? - tool tip')
    }

    handleKeyTermLink = (event) => {
        if(document.getElementById('pagination')){
            let pagiSelect = document.getElementById('pagination')
            let pagiId = pagiSelect.children[0].children[1].children[1].id
            let pagiselId = document.getElementById(pagiId)
            if(pagiselId){
                pagiselId.removeAttribute('aria-labelledby')
                pagiselId.setAttribute('aria-describedby','section-helper-text')
            }
        }
        if (!event.shiftKey && event.key === "Tab"){
            event.preventDefault();
            if(this.props.plan.productType === 'GOVT' || this.props.plan.productType === 'SHIP'){
                if (this.state.pendingTabFocus === true && document.getElementById('serviceError_0')){
                    document.getElementById('serviceError_0').focus();
                    document.getElementById('paymentTermID_' + this.props.planNum).setAttribute('tabindex', '0');
                } else if (this.state.pendingTabFocus === true && document.getElementById('serviceError_0')){
                    document.getElementById('serviceError_0').focus();
                    document.getElementById('paymentTermID_' + this.props.planNum).setAttribute('tabindex', '0');
                } else if (this.state.pendingTabFocus === true && document.getElementById('historyTable_' + this.props.planNum)?.getElementsByTagName('a')[0]){
                    let firstLinkTable = document.getElementById('historyTable_' + this.props.planNum)?.getElementsByTagName('a')[0]
                    if(firstLinkTable){
                        firstLinkTable.focus();
                    }
                    document.getElementById('paymentTermID_' + this.props.planNum).removeAttribute('tabIndex');
                } else if(this.state.processedTabFocus === true && document.getElementById('noPaymentData_' + this.props.planNum)){
                    document.getElementById('noPaymentData_' + this.props.planNum).focus();
                    document.getElementById('paymentTermID_' + this.props.planNum).setAttribute('tabindex', '0');
                 } else if(this.state.processedTabFocus === true && document.getElementById('paymentSelect_' + this.props.planNum)) {
                    document.getElementById('paymentSelect_' + this.props.planNum).focus();
                } else {
                    document.getElementById('accordionid_' + this.props.planNum).focus();
                }
            }

            
            
        }

        if (event.shiftKey && event.key === "Tab") {
            event.preventDefault();
            if(this.props.plan.productType === 'GOVT' || this.props.plan.productType === 'SHIP'){
                if (this.state.pendingTabFocus === true) {
                    document.getElementById('pendingBtn_' + this.props.planNum).focus();
                    document.getElementById('pendingBtn_' + this.props.planNum).setAttribute('tabindex', '0');
                    document.getElementById('processedBtn_' + this.props.planNum).setAttribute('tabindex', '-1');
                    document.getElementById('paymentTermID_' + this.props.planNum).setAttribute('tabindex', '-1');
                } else {
                    document.getElementById('processedBtn_' + this.props.planNum).focus();
                    document.getElementById('pendingBtn_' + this.props.planNum).setAttribute('tabindex', '-1');
                    document.getElementById('processedBtn_' + this.props.planNum).setAttribute('tabindex', '0');
                    document.getElementById('paymentTermID_' + this.props.planNum).setAttribute('tabindex', '-1');
                }
            }

            
         }

    }

    serviceErrorKey = (event) => {
        if (event.shiftKey && event.key === "Tab") {
            event.preventDefault();
            if (this.state.pendingTabFocus === true) {
                document.getElementById('paymentTermID_' + this.props.planNum).focus();
            }   
        }
    }

    handleKeySelLink = (event) => {
        if (event.shiftKey && event.key === "Tab" && document.getElementById('paymentTermID_' + this.props.planNum)) {
            event.preventDefault();
            document.getElementById('paymentTermID_' + this.props.planNum).focus();
            document.getElementById('paymentTermID_' + this.props.planNum).setAttribute('tabindex', '-1');

        }

        if (event.shiftKey && event.key === "Tab" && document.getElementById('noPaymentData_' + this.props.planNum)) {
            document.getElementById('noPaymentData_' + this.props.planNum).setAttribute('tabindex', '0');
        }

    }


    getMobileTableHeadUI() {
        return (
            <Fragment>
                <div tabIndex={this.props.paymentHistoryData == null ? '-1' : '0'} role="tabpanel" aria-labelledby={'tabPayment_' + this.props.planNum} id={'paymentData_' + this.props.planNum}>
                        <div className={this.state.pendingTabFocus ? "paymentHistoryHeader pendingProcessed" : "paymentHistoryHeader pendingProcessed processed"}>
                            <div className={(this.props.paymentHistoryData?.length > 0 && this.state.processedTabFocus) || 
                                            (this.props.pendingPaymentData && this.props.pendingPaymentData.data !== null && this.state.pendingTabFocus) ? 
                                            "mobilePrintDownload" : 
                                            "mobilePrintDownload noData"
                                }>
                                {this.getPrintDownloadUI()}
                            </div>
                            <div className={this.state.pendingTabFocus ? "mobileHistoryHeading":"mobileHistoryHeading processed"}>
                                {this.getHistoryHeadingUI()}
                            </div>
                            { (this.state.processedTabFocus && !this.props.planData?.isFirstBillGen ) && 
                                <div className="mobileDateSelector">
                                    <label htmlFor={'paymentSelect_' + this.props.planNum} className="dateSelectorLabel">{overviewBillPayAem.auth_show_label}</label>
                                    {this.getDateSelectorUI()}
                                </div>
                            }

                            <div className="mobilePaymentterm" id={"mobilePaymentterm" + this.props.planNum}>
                                {this.getPaymentsTermUI()}
                            </div>
                        </div>
                </div>
            </Fragment>
        )
    }

    render() {
        return (
            <Fragment>
                <div aria-labelledby={'tabPayment_' + this.props.planNum} id={'paymentData_' + this.props.planNum}>
                    {isDesktop() ? this.getDesktopTableHeadUI() : this.getMobileTableHeadUI()}

                    { this.props.plan.productType === 'GOVT' ? 
                        <FedPaymentHistory 
                        changePage={this.changePage}
                        overviewBillPayAem={overviewBillPayAem}
                        planNum={this.props.planNum}
                        paymentHistoryData = {this.props.paymentHistoryData} 
                        pendingPaymentData = {this.props.pendingPaymentData}
                        processedTabFocus={this.state.processedTabFocus}
                        pendingTabFocus={this.state.pendingTabFocus}
                        currentPosts={this.state.currentPosts}
                        plan={this.props.plan}
                        planData={this.props.planData}
                        getDateSelectorUI={this.getDateSelectorUI}
                        loadingHistTable={this.props.loadingHistTable}
                        selectedValue={this.state.selectedValue.paymentHistory}
                        tabName={this.props.tabName}
                        paymentPrintStartDate = {this.props.paymentPrintStartDate}
                        getCreditBalance = {this.props.getCreditBalance}
                        pendingPaymentAPIError = {this.props.pendingPaymentAPIError}
                        estimatedAnnualPremium={this.props.estimatedAnnualPremium}
                        superUser={this.props.superUser}
                        superUserErr={this.props.superUserErr}
                        handleSuperUserErr={this.props.handleSuperUserErr}
                        /> 
                        : 
                        <ShipPaymentHistory 
                        changePage={this.changePage}
                        overviewBillPayAem={overviewBillPayAem}
                        planNum={this.props.planNum}
                        paymentHistoryData = {this.props.paymentHistoryData} 
                        pendingPaymentData = {this.props.pendingPaymentData}
                        processedTabFocus={this.state.processedTabFocus}
                        pendingTabFocus={this.state.pendingTabFocus}
                        currentPosts={this.state.currentPosts}
                        plan={this.props.plan}
                        planData={this.props.planData}
                        getDateSelectorUI={this.getDateSelectorUI}
                        loadingHistTable={this.props.loadingHistTable}
                        selectedValue={this.state.selectedValue}
                        tabName={this.props.tabName}
                        paymentPrintStartDate = {this.props.paymentPrintStartDate}
                        IsbPaymentPrintStartDate={this.props.IsbPaymentPrintStartDate}
                        getCreditBalance = {this.props.getCreditBalance}
                        pendingPaymentAPIError = {this.props.pendingPaymentAPIError}
                        isbBillingError={this.props.isbBillingError}
                        searchPaymentHistoryError={this.props.searchPaymentHistoryError}
                        />}
                        {this.state.pendingTabFocus && (this.props.pendingPaymentData?.data?.length > 0 && !this.props.pendingPaymentAPIError) && (!this.props.isbBillingError && !this.props.searchPaymentHistoryError) &&                      
                            <div className={this.props.plan.productType === 'SHIP' ? 'cancelPendingMsg' : 'cancelPendingMsg fed'} id={'cancelPendingMsg_' + this.props.planNum}>
                                <div><Typography.Paragraph>{overviewBillPayAem.auth_pay_pending_subheader}</Typography.Paragraph></div>
                                {this.state.pendingTabFocus && this.props.pendingPaymentData?.data !== null && this.props.plan?.productType === 'SHIP' &&
                                <>
                               <span style={{paddingRight: '5px'}} id="cancel_txt_desc">{overviewBillPayAem.auth_pending_payment_cancel} </span> <Markup content={overviewBillPayAem.auth_pending_payment_cancel_link} />
                               </>
                                }
                            </div>
                        }
                    {!this.props.loadingHistTable && ((this.props.paymentHistoryData?.length > 0 && this.state.processedTabFocus) || (this.props.pendingPaymentData?.data?.length > 0 && this.state.pendingTabFocus)) && (!this.props.isbBillingError && !this.props.searchPaymentHistoryError) && this.pagination()}
                    <div id={'printPayHistoryTable_' + this.props.planNum}>
                        <div className="printHistoryTable">
                            <PrintHistory 
                                tabName='Payment'
                                paymentTab={this.state.pendingTabFocus ? "Pending" : "Processed"} 
                                data={this.state.pendingTabFocus && this.props.pendingPaymentData && this.props.pendingPaymentData.data ?  this.props.pendingPaymentData.data : this.props.paymentHistoryData} 
                                plan={this.props.plan} 
                                selectedValue={this.state.selectedValue} 
                                paymentPrintStartDate={this.props.paymentPrintStartDate}
                                IsbPaymentPrintStartDate={this.props.IsbPaymentPrintStartDate}
                                getCreditBalance={this.props.getCreditBalance} 
                                />
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default PaymentHistory