import axios from '../../server/api/ucpPayments-api';
import appConfigs from '../app.config';
import Moment from 'moment';
import { serverCurrentTime } from '../../server/api/serverTime-api';
import { getDecStr} from '../../server/util/encDec';
import secureLocalStorage from 'react-secure-storage';
const searchPaymentHistory = async (memberNumber,dateOfBirth,plan) => {

	var currenttime;
	if(secureLocalStorage.getItem('currSysTimeInMs')){
		currenttime = Number.parseInt(secureLocalStorage.getItem('currSysTimeInMs'), 10);
	}else{
	serverCurrentTime().then((time) => {
		 currenttime = Number.parseInt(time.systemtimeinmillis, 10);
		 secureLocalStorage.setItem('currSysTimeInMs',time.systemtimeinmillis);										
	  }); 
	}
	var currentFormateDate = Moment(currenttime).format('MM/DD/YYYY');
	let axiosConfig = {
		headers: {
			'Content-Type': 'application/json;charset=UTF-8',
			"Access-Control-Allow-Origin": "*",
			}
	  };
	var SearchPaymentHistoryRequest = { "day": '7',
						            "govtAccountId": plan.accountId,
						            "paymentHistoryStartDate": currentFormateDate,
						            "paymentHistoryStopDate": currentFormateDate,
						            "paymentSearchType": 'payment.history.search.ninety.days',
						            "planCategory": plan.planCategory,
						            "policyPlanStartDate": plan.planStartDate,
						            "policyPlanStatus": 'ACTIVE',
						            "productType": plan.productType,
						            "shipIndividualId": plan.productType === 'GOVT' ? null : plan.individualId,
						            "strPaidUnpaid": 'ALL'				
				};

		let accNumber = null;
		let routingNumber = null;

	return axios.post(appConfigs.search_payment_history_path , 
        SearchPaymentHistoryRequest).then(response =>{

			if(response.data.readBillingAndTransactionDetailResult.wrapperPojo != null){
				response.data.readBillingAndTransactionDetailResult.wrapperPojo.map(elt=> {
					accNumber = getDecStr(elt.accountNumber)
					routingNumber= getDecStr(elt.routingNumber)
				 elt.accountNumber = accNumber;
				 elt.routingNumber= routingNumber;
				 return elt
				});
				}
	
				if(response.data.readBillingAndTransactionDetailResult.onetimePaymentMethods != null){
				response.data.readBillingAndTransactionDetailResult.onetimePaymentMethods.map(elt1=> {
					accNumber = getDecStr(elt1.accountNumber)
					routingNumber= getDecStr(elt1.routingNumber)
				 elt1.accountNumber = accNumber;
				 elt1.routingNumber= routingNumber;
				 return elt1
				});
				 }


				return response;
		//	}	
		})
		.catch((error) => {
			console.log(error.message);
			return {
				callFailed: true
			};
		});
};

export const searchPaymentHistoryAPI = async (plan, day, searchType, planStatus, paidUnpaid) => {
	
		var requestPayload = { "day": day,
							"govtAccountId": plan.accountId,
							"paymentHistoryStartDate": "",
							"paymentHistoryStopDate": "",
							"paymentSearchType": searchType,
							"planCategory": plan.planCategory,
							"policyPlanStartDate": plan.planStartDate,
							"policyPlanStatus": planStatus,
							"productType": plan.productType,
							"shipIndividualId": plan.productType === 'GOVT' ? null : plan.individualId,
							"strPaidUnpaid": paidUnpaid			
					};

		let accNumber = null;
		let routingNumber = null;

		return axios.post(appConfigs.search_payment_history_path , 
			requestPayload).then(response =>{

				if(response.data.readBillingAndTransactionDetailResult.wrapperPojo != null){
					response.data.readBillingAndTransactionDetailResult.wrapperPojo.map(elt=> {
						accNumber = getDecStr(elt.accountNumber)
						routingNumber= getDecStr(elt.routingNumber)
					 elt.accountNumber = accNumber;
					 elt.routingNumber= routingNumber;
					 return elt
					});
					}
		
					if(response.data.readBillingAndTransactionDetailResult.onetimePaymentMethods != null){
					response.data.readBillingAndTransactionDetailResult.onetimePaymentMethods.map(elt1=> {
						accNumber = getDecStr(elt1.accountNumber)
						routingNumber= getDecStr(elt1.routingNumber)
					 elt1.accountNumber = accNumber;
					 elt1.routingNumber= routingNumber;
					 return elt1
					});
					 }
				//console.log(response);
					return response;	
			})
			.catch((error) => {
				console.log(error.message);
				return {
					callFailed: true
				};
			});
	};

export default searchPaymentHistory;
