import axios from 'axios';
import Moment from 'moment';
import { logger } from '../logger';
import appConfigs from '../app.config';

const ucpPayments = axios.create({
	baseURL: appConfigs.api_base_url
});

export default ucpPayments;
