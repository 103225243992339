import React from "react";
import close_icon from '../../tokens/icons/close-icon.png';
import { Markup } from 'interweave';

const EarlyWarning = (props) => {
    return (
        <div className="row justify-content-center">
          <div className="col-md-12 col-lg-12">
            {/* header */}
            <div class="modal-header-container">
              <div class="modal-header">
                <h1 id="ModalId" class="header-title">
                    About Early Warning
                </h1>
                <button onClick={props.click} class="header-close-circle-btn" aria-label="Close">
                  <img src={close_icon} alt="" />
                </button>
              </div>
            </div>
            {/* content */}
            <div role="region" tabindex="0" aria-labelledby="containerID" class="modal-content-container">
             
            <p class="col-md-12">
            <Markup content= {props.content.model_msg}/>              
            </p> 
            </div>
            {/* footer */}
            <div class="modal-footer-container">
            <button style = {{marginLeft : '15px', minWidth: '100px'}} onClick={props.click} className="tds-button tds-button--secondary-one tds-margin-xsm-horizontal mb-20">
                    Close
            </button>
            </div>
          </div>
        </div>
      );
    }
    export default EarlyWarning