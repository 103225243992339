import React, { Component } from 'react';
import { Typography, Link, Notification } from '@uhc-tempo/components';
import VISA from '../../tokens/icons/credit-visa.svg';
import MC from '../../tokens/icons/credit-mastercard.svg';
import AMEX from '../../tokens/icons/credit-american-express.svg';
import Discover from '../../tokens/icons/credit-discover.svg';
import PaymentModals from '../modals/PaymentModals';
import {items} from '../Wallet/ManageWallet'
import EditCreditCard from './EditCreditCard';
import cloneDeep from 'lodash/cloneDeep';
import { manageWalletAem, updateWalletData, manageWalletFormAem } from '../Wallet/ManageWallet'

export class CreditCardList extends Component {
    constructor(props) {
        super(props);

        this.setAccordionToggle = this.setAccordionToggle.bind(this);
    }
     //Modal Content
     itemRemove = {
        header: manageWalletAem.auth_remove_pay_method_modal_title,
        desc: manageWalletAem.auth_remove_pay_method_modal_content,
        btn_txt: manageWalletAem.auth_mw_remove_btn_txt,
        btn_txtCancel: manageWalletAem.auth_mw_cancel_btn_txt,
        items: cloneDeep(items),
        updateWalletData: cloneDeep(updateWalletData),
        itemId: this.props.item.itemId,
        getWalletDetails: this.props.getWalletDetails,
        setLoading: this.props.setLoading,
        getWalletItemNum: this.props.getWalletItemNum,
        resetAllFormsAndErrors: this.props.resetAllFormsAndErrors,
        itemIndex: this.props.index
    }

    recItemRemove = {
        header: manageWalletAem.auth_pay_method_recurring_modal_heading,
        desc: manageWalletAem.auth_pay_method_recurring_modal_content,
        btn_txt: manageWalletAem.auth_edit_recurring_pay_btn_txt,
        btn_txtCancel: manageWalletAem.auth_mw_cancel_btn_txt,
        itemIndex: this.props.index,
        resetAllFormsAndErrors: this.props.resetAllFormsAndErrors,
    }

    setAccordionToggle(cancelBtn) {
        this.props.accordionToggle(this.props.index, cancelBtn);
    }

    handleCCEditLinkKey = (event) => {
        if(this.props.isEditOpen){
            if (!event.shiftKey && event.key === "Tab") {
                event.preventDefault();
                document.getElementById('nameOnCard').focus();
                if(document.getElementById('cc_remove_'+this.props.index)){
                    document.getElementById('cc_remove_'+this.props.index).setAttribute('tabindex','-1')
                }else{
                    document.getElementById('cc_rec_remove_'+this.props.index).setAttribute('tabindex','-1')
                }
            }
            if (event.shiftKey && event.key === "Tab") {
                event.preventDefault();
                document.getElementById('cc_cancelBtn').focus();
             }
        }
    }

    isMobile() {
        let screenWidth = window.screen.width;
        if (screenWidth < 768) {
            return true;
        }
        return false;
    }

    render() {
        const creditCard = this.props.item.creditCard;
        const ccItemAddedUpdated = JSON.parse(sessionStorage.getItem('walletItemAddedUpdated'))
        const newSession = JSON.parse(sessionStorage.getItem('newSession'));
        return (
            <div>
               <div className="walletPaymentMethod">
                    <div className="walletAccount">
                        <Typography.Paragraph
                        id={'cc_acc_details_'+this.props.index}>
                            <span className="sr-only">Credit / Debit cards</span>
                            {(this.props.item.nickname === '' || !this.props.item.nickname) ?
                             this.props.formatCCEFT(creditCard.cardType) 
                             : 
                             this.props.item.nickname}{' ***' + creditCard.cardNumberLastFour}
                        </Typography.Paragraph>
                        <div className="accountIcon">
                           <img src={creditCard.cardType === 'VISA' ? VISA : creditCard.cardType === 'MASTERCARD' ? MC : creditCard.cardType === 'AMEX' ? AMEX : Discover} alt="" />
                        </div>
                        { newSession === false ?
                        (ccItemAddedUpdated?.creditCard && (ccItemAddedUpdated?.audit?.createdOn === ccItemAddedUpdated?.audit?.modifiedOn)) ?
                        creditCard?.creditCardToken === ccItemAddedUpdated?.creditCard?.creditCardToken &&
                        <div className='newIcon'>
                            <Typography.Paragraph>{manageWalletAem.auth_mw_new_text}</Typography.Paragraph>
                        </div>
                        :
                        (creditCard?.creditCardToken === ccItemAddedUpdated?.creditCard?.creditCardToken && sessionStorage.getItem('updateRecurring') !== 'true') ?
                        <div className='updateIcon'>
                            <Typography.Paragraph>{manageWalletAem.auth_mw_updated_text}</Typography.Paragraph>
                        </div>
                        :
                        null
                        :
                        null
                        }
                        { this.isMobile() &&
                            <div className={creditCard?.creditCardToken === ccItemAddedUpdated?.creditCard?.creditCardToken ? "editRemoveLinks" : "editRemoveLinks noIcon"}>
                            <div className="editLink">
                                <Link id={"ccEditLink_"+this.props.index} noIcon onClick={() => this.props.onCCEditClick(this.props.index)}>{manageWalletAem.auth_mw_edit_link_text}</Link>
                            </div>
                            { ((this.props.item.itemId === this.props.recCCItemId) || (this.props.item.itemId === this.props.scheduledCCItemId)) ?
                            <div className="removeLink">
                                <PaymentModals
                                      modalContent={this.recItemRemove}
                                      modalType="recCCItemRemove"
                                      removeLinkTxt={manageWalletAem.auth_mw_remove_link_text}
                                      item={this.props.item.itemId}
                                      scheduledCCItemId={this.props.scheduledCCItemId}
                                      recCCItemId={this.props.recCCItemId}
                                      editRecurringWalletItem = {this.props.editRecurringWalletItem}
                                      paymentMethod='CC'
                                />
                            </div>
                            :
                            <div className="removeLink">
                                 <PaymentModals
                                      modalContent={this.itemRemove}
                                      modalType="ccitemRemove"
                                      removeLinkTxt={manageWalletAem.auth_mw_remove_link_text}
                                      item={this.props.item.itemId}
                                      scheduledCCItemId={this.props.scheduledCCItemId}
                                      recCCItemId={this.props.recCCItemId}
                                />
                                
                            </div>
                            }
    
                        </div>
                        }
                    </div>
                   { !this.isMobile() &&
                    <div className={creditCard?.cardNumberLastFour === ccItemAddedUpdated?.creditCard?.cardNumberLastFour ? "editRemoveLinks" : "editRemoveLinks noIcon"}>
                        <div className="editLink">
                        <button 
                            role="link"
                            className="tds-link tds-link--medium" 
                            id={"ccEditLink_"+this.props.index}
                            onKeyDown={this.handleCCEditLinkKey}
                            aria-describedby={this.props.item.itemId === this.props.scheduledCCItemId ? ('cc_acc_details_'+this.props.index + ' ' + 'cc_rec_pay_sch_'+this.props.index) : this.props.item.itemId === this.props.recCCItemId ? ('cc_acc_details_'+this.props.index + ' ' + 'cc_rec_pay_on_'+this.props.index)  : 'cc_acc_details_'+this.props.index}
                            aria-expanded="false"
                            aria-controls={'edit_cc_details_'+this.props.index}  
                            onClick={() => this.props.onCCEditClick(this.props.index)}>
                                {manageWalletAem.auth_mw_edit_link_text}
                            </button>
                        </div>
                        { ((this.props.item.itemId === this.props.recCCItemId) || this.props.item.itemId === this.props.scheduledCCItemId) ?
                        <div className="removeLink">
                            <PaymentModals
                                superUser={this.props.superUser}
                                showSuperUserError={this.props.showSuperUserError}
                                superUserError={this.props.superUserError}
                                  modalContent={this.recItemRemove}
                                  modalType="recCCItemRemove"
                                  removeLinkTxt={manageWalletAem.auth_mw_remove_link_text}
                                  item={this.props.item.itemId}
                                  scheduledCCItemId={this.props.scheduledCCItemId}
                                  recCCItemId={this.props.recCCItemId}
                                  paymentMethod='Credit'
                                  editRecurringWalletItem = {this.props.editRecurringWalletItem}
                            />
                        </div>
                        :
                        <div className="removeLink">
                             <PaymentModals
                                superUser={this.props.superUser}
                                showSuperUserError={this.props.showSuperUserError}
                                superUserError={this.props.superUserError}
                                  modalContent={this.itemRemove}
                                  modalType="ccitemRemove"
                                  removeLinkTxt={manageWalletAem.auth_mw_remove_link_text}
                                  item={this.props.item.itemId}
                                  scheduledCCItemId={this.props.scheduledCCItemId}
                                  recCCItemId={this.props.recCCItemId}
                                  removeApiErr = {this.props.removeApiErr}
                            />
                            
                        </div>
                        }

                    </div>
                    }
                    <div className="recurringLabel">
                    { this.props.item.itemId === this.props.recCCItemId &&
                        <Typography.Paragraph id={'cc_rec_pay_on_'+this.props.index}>{manageWalletAem.auth_mw_recurring_payments_text} <span style={{color: 'green', fontWeight: 'bold'}}>{manageWalletAem.auth_mw_on_text}</span></Typography.Paragraph>
                    }
                    {this.props.item.itemId === this.props.scheduledCCItemId &&
                        <Typography.Paragraph id={'cc_rec_pay_sch_'+this.props.index}>{manageWalletAem.auth_mw_recurring_scheduled_txt}</Typography.Paragraph>
                    }
                    </div>
                </div>
                { this.props.isEditOpen && <EditCreditCard  
                    id={"editForm_" + this.props.index}
                    className={"accordionHide"} 
                    index={this.props.index} 
                    priorItem={cloneDeep(this.props.item)}
                    item={cloneDeep(this.props.item)}
                    getWalletDetails= {this.props.getWalletDetails}
                    onCCCancelClick = {this.props.onCCCancelClick}
                    onCCCancelTrackLinkEvent={this.props.onCCCancelTrackLinkEvent}
                    editCCOnSubmitBtnClick = {this.props.editCCOnSubmitBtnClick}
                    getWalletItemNum={this.props.getWalletItemNum}
                    isEditOpen={this.props.isEditOpen}
                    resetAllFormsAndErrors={this.props.resetAllFormsAndErrors}
                    showSuperUserError={this.props.showSuperUserError}
                    superUser={this.props.superUser}
                    superUserError={this.props.superUserError} 
                    recCCItemId={this.props.recCCItemId}
                    scheduledCCItemId={this.props.scheduledCCItemId}
                    />
                }
                { 
                    (this.props.superUserError && (this.props.index === this.props.accountItemNum)) ?
                        <div className='walletSuperUserError' id={'walletSuperUserNotification_' + this.props.index}><Notification  notificationType='error'><span style={{fontWeight: 'bold'}}>{manageWalletFormAem.auth_wallet_memberauth_not_allowed + ' ' }</span> {manageWalletFormAem.auth_wallet_memberauth_error_msg}  </Notification></div> 
                        :
                        null
                }
                </div>
        )
    }
}

export default CreditCardList