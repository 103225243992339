import axios from 'axios';
import config from '../config';
import appConfigs from '../app.config';

const aem_instance = axios.create({
	baseURL: appConfigs.aem_base_url,
});


export const content_path = config.REACT_APP_CONTENT_PATH;

export default aem_instance;
