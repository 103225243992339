import { PopupTip } from '@uhc-tempo/components';
import React, { Component, Fragment } from 'react';
import PlanHeader from '../PlanHeader/PlanHeader';
import { Typography, Link, Button } from '@uhc-tempo/components';
import './PlanSummary.css';
import {expAccordionState} from '../../HistoryTabs/HistoryTabs';
import PaymentModals from '../../modals/PaymentModals'
import { overviewSummAem } from '../../paymentOverview/paymentOverview';
import recurringFlagIcon from '../../../tokens/icons/recurringFlag_icon.png';
import { Markup } from 'interweave';
import Moment from 'moment';
import recurringPayStartIcon from '../../../tokens/icons/recurring_pay_start_grey_24dp.svg';
import { isDesktop } from '../../../../server/util/util';
import { triggerEvent, trackModalWindowClick, trackLinkClick, trackImpression} from '../../../../server/util/analytics'
import { isIos } from '../../shared-components/detectDevice';
import { convertToCurrency } from '../../../../server/util/util';
import { isEditPayment, isCancelPayment, getSSOValuefromHSID } from '../../../../server/util/localStorageRepo';
import {editPaymentBtnClickSubject} from '../../../../server/observableService';
import HsidMfaModal from '../../shared-components/HsidMfaModal'
import MfaLogOutModal from '../../modals/MfaLogOutModal'
import moment from 'moment';
import appConfigs from '../../../../server/app.config';
import {getDecryptAATokenService} from '../../../../src/api/decAAtokenService-api';
import Spinner from '../../../UI/Spinner/Spinner';
import { mfaLoggerInfo } from   '../../../../server/api/mfaLoggingService-api';
import secureLocalStorage from 'react-secure-storage';
import { getM3PService } from '../../../../server/api/m3pCheck-api';

export class PlanSummary extends Component {
    constructor(props) {
        super(props);

        this.state = {
          mfaValidation: false,
          mfaCallBackURL: '',
          isMFAAuthenticated: false,
          hasMFADecryptChecked: false,
          userName: JSON.parse(localStorage.getItem('consumerDetails')).userName,
          isMFA: sessionStorage.getItem('isMFA') ? JSON.parse(sessionStorage.getItem('isMFA')) : this.props.isMFA,
          isbCurrentPaymentMethodLabel: null,
          isMFAInvalid: this.props.isMFAInvalid,
          futurePaymentMethod: null,
          futurePaymentMethodStartDate: null,
          paymentMethod:
            this.props.plan.productType === 'GOVT' && this.props.searchPaymentWalletResults
              ? this.props.searchPaymentWalletResults.currentPaymentMethod
              : '' ,
            m3pBannerShow: false,
        }
        this.closeM3PBanner = this.closeM3PBanner.bind(this);
    }

    mfaCloseHandler = () => {
       this.setState({mfaValidation:false})
    }
      
    mfaContent = {
        header : overviewSummAem.auth_mfa_header_title,
        model_msg : overviewSummAem.auth_mfa_modal_content,
        btn_text : overviewSummAem.auth_mfa_co_trans_btn,
        btn_text1 : overviewSummAem.auth_mfa_cancel_btn,
        closeHandler : this.mfaCloseHandler
    }

    // Logic for determining how their payment is happening
    isbCurrentPaymentMethod = () => {
        let currentDate=secureLocalStorage.getItem('currentDate');
        let currentPaymentMethod = []
        let currentPayMethodStartDate=overviewSummAem.auht_isbCurrentPaymentMethodStartDate ? moment(overviewSummAem.auht_isbCurrentPaymentMethodStartDate).format('YYYY-MM-DD'):'';
        let currentPayMethodStoptDate=overviewSummAem.auth_isbCurrentPaymentMethodStopDate ? moment(overviewSummAem.auth_isbCurrentPaymentMethodStopDate).format('YYYY-MM-DD'):'';
        let  futurePayMethodStoptDate=overviewSummAem.auth_isbFuturePaymentMethodStopDate ? moment(overviewSummAem.auth_isbFuturePaymentMethodStopDate).format('YYYY-MM-DD'):'';
        // start logic for current payment method should be displayed if the current date is between the start(05/04) and stop(05/31) date 
        if(currentDate >= currentPayMethodStartDate && currentDate <= currentPayMethodStoptDate){

            if (this.props.customerAccountSummary && this.props.customerAccountSummary.currentPaymentMethodList) {
                currentPaymentMethod = this.props.customerAccountSummary?.currentPaymentMethodList?.filter(mop => {
                    return mop.paymentMethod === 'EFT' &&  mop.paymentMethodStopDate >= currentDate;
                })
            }
        // end logic for current payment method should be displayed if the current date is between the start(05/04) and stop(05/31) date 
        }else{
            if (this.props.customerAccountSummary && this.props.customerAccountSummary.currentPaymentMethodList) {
                currentPaymentMethod = this.props.customerAccountSummary?.currentPaymentMethodList?.filter(mop => {
                    return mop.paymentMethod === 'EFT' && (currentDate >= mop.paymentMethodStartDate && currentDate <= mop.paymentMethodStopDate);
                })
            }
        }
        if (currentPaymentMethod && currentPaymentMethod.length > 0 && currentPaymentMethod[0]?.paymentMethod === 'EFT' && currentPaymentMethod[0]?.bankAccountType.toLowerCase().indexOf('checking') > -1) {
            this.setState({isbCurrentPaymentMethodLabel: overviewSummAem.auth_eft_checking_text + currentPaymentMethod[0]?.bankAccountNumber.slice(-4)});
        }else if (currentPaymentMethod && currentPaymentMethod.length > 0 && currentPaymentMethod[0]?.paymentMethod === 'EFT' && currentPaymentMethod[0]?.bankAccountType.toLowerCase().indexOf('saving') > -1) {
            this.setState({isbCurrentPaymentMethodLabel: overviewSummAem.auth_eft_saving_text + currentPaymentMethod[0]?.bankAccountNumber.slice(-4)});
        } else {
            this.setState({isbCurrentPaymentMethodLabel: overviewSummAem.auth_isb_pay_coupon_lbl});
        }
    }

    shipFuturePaymentMethod =() =>{
        let currentDate=secureLocalStorage.getItem('currentDate');
        let futurePaymentMethod = []

        if (this.props.customerAccountSummary && this.props.customerAccountSummary.currentPaymentMethodList) {
            futurePaymentMethod = this.props.customerAccountSummary?.currentPaymentMethodList?.filter(mop => {
                return mop.paymentMethod === 'EFT' && (mop.paymentMethodStartDate >= currentDate);
            })
        }
  
        if (futurePaymentMethod && futurePaymentMethod.length > 0 && futurePaymentMethod[0]?.paymentMethod === 'EFT' ) {
            this.setState({futurePaymentMethod: "EFT***" + futurePaymentMethod[0]?.bankAccountNumber.slice(-4)});
            this.setState({futurePaymentMethodStartDate: moment(futurePaymentMethod[0]?.paymentMethodStartDate).format('MM/DD/YYYY') });
        }
    }

    currentPaymentMethod = () => {
        let paymentMethod = this.state.paymentMethod
            if (paymentMethod.indexOf('Coupon') > -1) {
                return overviewSummAem.auth_coupon_book_text
            } else if (paymentMethod.indexOf('EFT') > -1) {
                return this.props.plan.productType === 'SHIP' ? overviewSummAem.auth_eft_checking_text + this.props?.readBillingAndTransDtl?.eftNumber : paymentMethod.indexOf('CHECKING') > -1 ? overviewSummAem.auth_eft_checking_text + this.props.fedEftBankNumber : overviewSummAem.auth_eft_saving_text + this.props.fedEftBankNumber
            } else if (paymentMethod.indexOf('Credit') > -1) {
                return overviewSummAem.auth_card_ending_in + (this.props.lastFourDigits ? this.props.lastFourDigits : '')
            } else if (paymentMethod.indexOf('Direct') > -1) {
                return overviewSummAem.auth_monthly_bill_text
            } else if (paymentMethod.indexOf('CMS-SSA/RRB') > -1) {
                return overviewSummAem.auth_cms_ssa_text
            } else {
                return ''
            }
        

    }

    //Logic for determinig if there is past due amount
    isbPastDueDate() {
        if (this.props.customerAccountSummary?.currentBillingStatus.pastDueAmount > 0) {
            return (
                <Fragment>
                    <Fragment>
                    <li className="curPayVerticalLine" ></li>
                    <li className="amountPastDueDate">
                        <Typography.Paragraph>{overviewSummAem.auth_due_date_label}</Typography.Paragraph>
                        <Typography.Paragraph>{moment().startOf('month').format('MM/DD/YYYY') }</Typography.Paragraph>
                    </li>
                    </Fragment>
                </Fragment>
            )
        }
    }

    pastDueDate = () => {
        if (this.props.firstDayOfMonth) {
            return (
                <Fragment>
                    {!this.props.planData?.isPreEffective || (this.props.planData?.isFirstBillGen && this.props.planData?.isPreEffective) ?
                    <Fragment>
                    <li className="curPayVerticalLine" ></li>
                    <li className="amountPastDueDate">
                        <Typography.Paragraph>{overviewSummAem.auth_due_date_label}</Typography.Paragraph>
                        <Typography.Paragraph>{this.props.firstDayOfMonth}</Typography.Paragraph>
                    </li>
                    </Fragment>
                    : null}
                </Fragment>
            )
        }
    }

    isbUpcomingDue = () => {
        let upcomingAmount =  this.props?.upcomingAmount ? this.props.upcomingAmount : '0.00';

        const currentDate = moment().format('MM/DD/YYYY');
        const firstDayOfNextMonth = moment().add(1, 'months').startOf('month');  

        // ACTIVE PLAN
        let nextBillGen = (() => {  
            const filteredInvoices = this.props?.isbInvoiceResponse?.response?.invoiceList?.filter(bill =>   
                moment(bill.dueDate).isSame(firstDayOfNextMonth)  
            ) || [];  
            return filteredInvoices.length > 0 ? filteredInvoices : null;  
        })();  

        // PRE-EFFECTIVE PLAN
        let isFirstBillGenerated = (() => {  
            const filteredInvoices = this.props?.isbInvoiceResponse?.response?.invoiceList?.filter(bill =>   
                moment(bill.dueDate).isAfter(currentDate)  
            ) || [];  
            return filteredInvoices.length > 0 ? filteredInvoices : null;
        })();  

        if (nextBillGen || (this.props.planData?.isPreEffective && isFirstBillGenerated)) {
            if (upcomingAmount === '0.00') {
                return '$' + upcomingAmount;
            } else {
                return '$' + upcomingAmount.toFixed(2);
            }
        } else {
            return overviewSummAem.auth_bill_not_available;
        }
    }

    upcomingDue = () => {
        let trans = this.props?.readBillingAndTransDtl
        let paymentMethod = this.state.paymentMethod
        if (trans) {
            if (this.props.plan.productType === 'SHIP') {
                let upcomingCharges = [];
                if (this.props.planData?.isPreEffective && !this.props.planData?.isFirstBillGen) {
                    return overviewSummAem.auth_preeffective_no_pay_txt
                } else if (this.props.planData?.isFirstBillGen) {
                    upcomingCharges = trans.paymentHistory?.filter(bill => { return moment(bill?.dueDate).isSame(moment(this.props.plan?.planStartDate))})
                    if (upcomingCharges) {
                        return '$' + convertToCurrency(upcomingCharges[0].balanceDue)
                    } else {
                        return overviewSummAem.auth_bill_not_available;
                    }
                }
                if (trans.nextPremiumAmount) {
                    return '$' + convertToCurrency(trans.nextPremiumAmount)
                }
                if (trans.paidInFull) {
                    return overviewSummAem.auth_no_further_txt
                }
                if (!trans.paidInFull && trans.nextPremiumAmount === null) {
                    return overviewSummAem.auth_bill_not_available
                }
            }
            if (this.props.plan.productType === 'GOVT') {
                let upcomingCharges = this.getGovtUpcomingChargeAndDate(trans).upcomingCharges
                if (this.props.planData?.isPreEffective && !this.props.planData?.isFirstBillGen) {
                    return overviewSummAem.auth_preeffective_no_pay_txt
                } else if (this.props.planData?.isFirstBillGen) {
                    upcomingCharges = trans.paymentHistory?.filter(bill => { return moment(bill?.dueDate).isSame(moment(this.props.plan?.planStartDate))})
                    if (upcomingCharges) {
                        return '$' + convertToCurrency(upcomingCharges[0].balanceDue)
                    } else {
                        return overviewSummAem.auth_bill_not_available;
                    }
                }
                if (trans.estimatedAnnualPremium != null && trans.estimatedAnnualPremium != 0.00 && upcomingCharges > -1 && paymentMethod.indexOf('Direct') > -1) {
                    return '$' + convertToCurrency(upcomingCharges)
                } else if (trans.estimatedAnnualPremium != 0.00 && upcomingCharges > -1 && (paymentMethod.indexOf('EFT') > -1 || paymentMethod.indexOf('Credit') > -1)) {
                    return '$' + convertToCurrency(upcomingCharges)
                }else if(upcomingCharges > -1 && paymentMethod.indexOf('CMS-SSA/RRB') > -1 ){
                    return '$' + convertToCurrency(upcomingCharges)
                }else if (paymentMethod.indexOf('CMS-SSA/RRB') > -1 && !trans.futurePaymentMethod) {
                    return overviewSummAem.auth_bill_not_available
                }
                if (trans.estimatedAnnualPremium !=null && trans.estimatedAnnualPremium == 0.00) {
                    return overviewSummAem.auth_no_further_txt
                }
                if (!trans.futurePaymentMethod) {
                    return overviewSummAem.auth_bill_not_available
                }
            }

        }
    }

    getGovtUpcomingChargeAndDate(trans) {
        let currDate = Moment(new Date()).format('MM/DD/YYYY');
        let upcomingCharges = -1
        let upcomingPaymentDate = null
        if (trans.paymentHistory) {
            trans.paymentHistory.forEach((hist) => {
                let dueDate = Moment(hist.dueDate).format('MM/DD/YYYY');
                if (Moment(dueDate) > Moment(currDate)) {
                    upcomingCharges = hist.balanceDue;
                    upcomingPaymentDate = dueDate;

                }
            });
        }
        return { upcomingCharges: upcomingCharges, upcomingPaymentDate: upcomingPaymentDate }
    }
    // Logic for determining how to render the due date state
    isbUpcomingDueDate = () => {
        const currentDate = moment().format('MM/DD/YYYY');
        let firstDayOfNextMonth = moment().add(1, 'months').startOf('month'); 

        // ACTIVE PLAN 
        let nextBillGen = (() => {  
            const filteredInvoices = this.props?.isbInvoiceResponse?.response?.invoiceList?.filter(bill =>   
                moment(bill.dueDate).isSame(firstDayOfNextMonth)  
            ) || [];  
            return filteredInvoices.length > 0 ? filteredInvoices : null;  
        })();  

        // PRE-EFFECTIVE PLAN
        let isFirstBillGenerated = (() => {  
            const filteredInvoices = this.props?.isbInvoiceResponse?.response?.invoiceList?.filter(bill =>   
                moment(bill.dueDate).isAfter(currentDate)  
            ) || [];  
            return filteredInvoices.length > 0 ? filteredInvoices : null;
        })();  

        let upcomingDueDate = null;
        let upcomingPayText='';

        if(this.props.planData?.isPreEffective && isFirstBillGenerated == null) {
            upcomingDueDate = null;
        } else if(this.props.planData?.isPreEffective && isFirstBillGenerated && this.state.isbCurrentPaymentMethodLabel && this.state.isbCurrentPaymentMethodLabel.includes('EFT')) {
            upcomingPayText = overviewSummAem.auth_scheduled_lbl;
            upcomingDueDate = overviewSummAem.isbscheduledDateLabel;
        } else if(this.props.planData?.isPreEffective && isFirstBillGenerated) {
            upcomingPayText = overviewSummAem.auth_recurring_due_date;
            upcomingDueDate = moment(isFirstBillGenerated[0].dueDate).format('MM/DD/YYYY');
        } else if(nextBillGen == null) {
            upcomingDueDate = null;
        } else if(nextBillGen && this.state.isbCurrentPaymentMethodLabel && this.state.isbCurrentPaymentMethodLabel.includes('EFT')) {
            upcomingPayText = overviewSummAem.auth_scheduled_lbl;
            upcomingDueDate = overviewSummAem.isbscheduledDateLabel;
        } else {
            upcomingPayText = overviewSummAem.auth_recurring_due_date;
            upcomingDueDate = moment(nextBillGen[0].dueDate).format('MM/DD/YYYY');
        }
        if (upcomingDueDate) {
            return (
                <>
                    <li className="dueVerticalLine"></li>
                    <li className="dueDateIsb">
                        <Typography.Paragraph>{upcomingPayText}</Typography.Paragraph>
                        <p className="tds-header__h3">{upcomingDueDate}</p>
                    </li>
                </>
            );
        }
    }

    upcomingDueDate = () => {
        let trans = this.props?.readBillingAndTransDtl
        let paymentMethod = this.state.paymentMethod
        let upcomingDueDate = null
        let upcomingPaymentDate = this.props.plan?.productType === 'GOVT' && this.getGovtUpcomingChargeAndDate(trans).upcomingPaymentDate
        if (this.props.planData?.isPreEffective && !this.props.planData?.isFirstBillGen) {
            upcomingDueDate = null;
        } else if (this.props.planData?.isFirstBillGen) {
            upcomingDueDate = trans.paymentHistory?.filter(bill => { return moment(bill?.dueDate).isSame(moment(this.props.plan?.planStartDate))})
            upcomingDueDate = moment(upcomingDueDate[0].dueDate).format('MM/DD/YYYY')
        }
        else if (trans.estimatedAnnualPremium != null && trans.estimatedAnnualPremium != 0.00 && upcomingPaymentDate && paymentMethod.indexOf('Direct') > -1) {
            upcomingDueDate = upcomingPaymentDate
        } else if (trans.estimatedAnnualPremium != 0.00 && upcomingPaymentDate && (paymentMethod.indexOf('EFT') > -1 || paymentMethod.indexOf('CMS-SSA/RRB') > -1 || paymentMethod.indexOf('Credit') > -1)) {
            upcomingDueDate = upcomingPaymentDate
        } else if (upcomingPaymentDate && paymentMethod.indexOf('CMS-SSA/RRB') > -1) {
            upcomingDueDate = upcomingPaymentDate
        }else {
            upcomingDueDate = this.props?.readBillingAndTransDtl?.nextBillDueDate
        }

        if (upcomingDueDate) {
            return (
                <>
                    <li className="dueVerticalLine"></li>
                    <li className="dueDate">
                        <Typography.Paragraph>{this.isScheduleLable(upcomingDueDate) ? overviewSummAem.auth_scheduled_lbl : overviewSummAem.auth_recurring_due_date}</Typography.Paragraph>
                        <p className="tds-header__h3">{upcomingDueDate}</p>
                    </li>
                </>
            )
        }
    }

    displayFuturePay() {
        if (this.props.futureFedPayMethod.indexOf('Credit') > -1) {
           return `Credit Card ***${this.props.futureFedPayMethodNumber}`
        } else {
            if (this.props.futureFedPayMethod.indexOf('CHECKING') > -1) {
                return overviewSummAem.auth_eft_checking_text + this.props.futureFedPayMethodNumber.slice(-4)
            }else {
                return overviewSummAem.auth_eft_saving_text + this.props.futureFedPayMethodNumber.slice(-4)
            }
        }
    }

    isCardExpiredInTwoMonths = (ccExp)=> {
        return Moment(ccExp).diff(Moment(),'days') <= 60
    }
  isSSASuppressed()
  {
    if(this.props.plan.employerGroupIndicator === "true" && this.props.plan.premium?.employerSubsidy > 0 && (this.props.plan.premium?.subsidyAdjustmentUnit == "$" || this.props.plan.premium?.subsidyAdjustmentUnit == "%"))
    return true
  }
    handleKeyRecBtn = (event) => {
        let accordionState = null;
        if(this.props.fedShipPlanCount > 1){
            accordionState = expAccordionState[this.props.planNum]
        } else {
            accordionState = expAccordionState[0]
        }

        if(!accordionState && !event.shiftKey && event.key === "Tab"){
            event.preventDefault();
            document.getElementById('accordionid_' + this.props.planNum).focus();
        }
    }

    //Modal Content
    nomanage = {
        header: overviewSummAem.auth_no_manage_pay_header,
        desc: overviewSummAem.auth_no_manage_pay_content,
        btn_txt: overviewSummAem.auth_close_btn_text,
        planNum: this.props.planNum,
    }

    noManagePayModal = <PaymentModals
                        modalContent={this.nomanage}
                        modalType='nomanagepay'
                        onKeyDownAction = {this.handleKeyRecBtn}
                        managepaybtn={overviewSummAem.auth_wallet_edit_link} />  

    // Determining if recurring payments are happening or not
    recurringFlagUI = () => {
        let currentDate=secureLocalStorage.getItem('currentDate');
        let nextMonth = moment().add(1, 'M');
        let firstOfNextMonth = nextMonth.startOf('month');
        let upcomingDueDate = firstOfNextMonth.format('MM/DD/YYYY')
        secureLocalStorage.setItem('recurringCancelled', false);
         let setupEditLink = <button aria-describedby="rec_sch_id" className=" ctaEditLink tds-link tds-link--medium" role="link" onKeyDown={this.handleKeyRecBtn} id={'recurrinBtn_' + this.props.planNum} onClick={() => this.goToMakePayment(false)}>
         <span className="edit_txt_lbl" aria-hidden={(this.props.recurringOn || this.props.recurringFuture) ? 'true' : null}>{(this.props.recurringOn || this.props.recurringFuture) ? overviewSummAem.auth_wallet_edit_link : overviewSummAem.auth_setup_recurring_btn}</span>
         <span className="tds-link__icon-wrapper">⁠<svg className="MuiSvgIcon-root tds-utility-icon tds-link__icon tds-link__icon--internal" focusable="false" viewBox="0 0 24 24" color="#196ecf" aria-hidden="true" role="img"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path></svg></span>
         {(this.props.recurringOn || this.props.recurringFuture) ?
          <span className="sr-only">Edit this recurring payment</span> 
           : null
          }
        </button>
        let paymentMethod = this.state.paymentMethod ? this.state.paymentMethod : ''
        // Direct 
        if(this.props.plan.productType === 'GOVT' && paymentMethod.indexOf('Direct') > -1){
            return (<div>{this.directBillView(setupEditLink)}</div>)
        // eft to direct
        } //Eft or credit with cms request
        else if (this.props.plan.productType === 'GOVT' && (paymentMethod.indexOf('EFT') > -1 || paymentMethod.indexOf('Credit') > -1 || paymentMethod.indexOf('CMS') > -1)) {
            return (<div>
                {this.recurringFlowView(setupEditLink)}
            </div>)
        } else if(this.props.plan.productType === 'SHIP' && this.props?.paymentMethodISBStopDate == currentDate) {
            secureLocalStorage.setItem('recurringCancelled', true);
            return <h3 id="rec_sch_id" className="tds-text__paragraph recurringPaymentText" style={{display: 'flex'}}><img src={recurringPayStartIcon} alt="" aria-hidden="true" /> <span style={{ fontWeight: 'bold'}}>{overviewSummAem.auth_isb_pay_coupon_lbl}</span> <span style={{ color: '#5a5a5a', fontStyle: 'italic' }}>{overviewSummAem.auth_rec_pay_start_text_2}</span> <span style={{ fontWeight: 'bold', fontStyle: 'italic', marginRight : '5px'}}> {upcomingDueDate}</span>{setupEditLink}</h3>;    
        } else if (paymentMethod.indexOf('EFT') > -1 || paymentMethod.indexOf('Credit') > -1) {
            return <Typography.H3 id="rec_sch_id" className="recurringH3" style={{ color: 'rgb(90, 90, 90)', fontWeight: 'bold' }}><span style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>{overviewSummAem.auth_recurring_payment_title}</span> <span style={{ color: 'green', fontWeight: 'bold' }}>{overviewSummAem.auth_on_text}</span> {setupEditLink}</Typography.H3>
        } else if( this.props.plan.productType === 'SHIP' && this.state.isbCurrentPaymentMethodLabel && this.state.isbCurrentPaymentMethodLabel.includes('EFT')) {
            return <Typography.H3 id="rec_sch_id" className="recurringH3" style={{ color: 'rgb(90, 90, 90)', fontWeight: 'bold' }}><span style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>{overviewSummAem.auth_recurring_payment_title}</span> <span style={{ color: 'green', fontWeight: 'bold' }}>{overviewSummAem.auth_on_text}</span> {setupEditLink}</Typography.H3>
        } else if( this.props.plan.productType === 'SHIP' && this.state.futurePaymentMethod && this.state.futurePaymentMethod.includes('EFT')){
            return <h3 id="rec_sch_id" className="tds-text__paragraph recurringPaymentText" style={{display: 'flex'}}><img src={recurringPayStartIcon} alt="" aria-hidden="true" /> <span style={{ fontWeight: 'bold'}}>{overviewSummAem.auth_rec_pay_start_text_1+" "+this.state.futurePaymentMethod }</span> <span style={{ color: '#5a5a5a', fontStyle: 'italic' }}>{overviewSummAem.auth_rec_pay_start_text_2}</span> <span style={{ fontWeight: 'bold', fontStyle: 'italic', marginRight : '5px'}}> {this.state.futurePaymentMethodStartDate}</span>{setupEditLink}</h3>;
         } else if (this.props.plan.productType === 'SHIP' && !paymentMethod.indexOf('EFT') > -1) {
            return (
                <div className="recDiscountFlag">
                    <img src={recurringFlagIcon} alt="" aria-hidden="true" /> 
                    <Typography.H4><b>{overviewSummAem.auth_get_discount_banner_text}</b></Typography.H4>
                    <Typography.Paragraph>{overviewSummAem.auth_ship_setup_recurring_lbl}</Typography.Paragraph>
                    <div>
                        {this.shipRecurringModal}
                    </div>
                </div>
            );
            
        }
    }

    //FED only one payment Modal Content
    onlyOnePayModalCont = {
        header: overviewSummAem.auth_only_one_pay_header,
        desc: overviewSummAem.auth_only_one_pay_content,
        btn_txt: overviewSummAem.auth_close_btn_text,
        planNum: this.props.planNum
    }

    onlyOnePayModal = <PaymentModals
                        modalContent={this.onlyOnePayModalCont}
                        modalType='fedonepay'
                        makepaybtn={overviewSummAem.auth_make_payment_btn} />

    //Modal Content
    paymentDueModal = {
        header: overviewSummAem.auth_paydue_modal_header,
        desc: overviewSummAem.auth_paydue_modal_content_fed,
        btn_txt: overviewSummAem.auth_close_btn_text
    }

    //Modal Content
    nofurtherpay = {
        header: JSON.parse(localStorage.getItem('consumerDetails')).preEffective ? overviewSummAem.auth_no_payments_due_heading : overviewSummAem.auth_no_further_pay_heading,
        desc: JSON.parse(localStorage.getItem('consumerDetails')).preEffective ? overviewSummAem.auth_no_payments_due_content : overviewSummAem.auth_no_further_pay_content,
        btn_txt: overviewSummAem.auth_close_btn_text,
        planNum: this.props.planNum
    }

     //Modal Content
     noPaymentCmsSSA = {
        header: overviewSummAem.auth_no_pay_cms_ssa_header,
        desc: overviewSummAem.auth_no_pay_cms_ssa_content,
        btn_txt: overviewSummAem.auth_close_btn_text,
        planNum: this.props.planNum
    }                

//FED one recurring request Modal Content
    secondrecurring = {
        header: overviewSummAem.auth_second_recurring_header,
        desc: overviewSummAem.auth_second_recurring_content,
        btn_txt: overviewSummAem.auth_close_btn_text,
        planNum: this.props.planNum
    }
                    
    secondrecurringModal = <PaymentModals
                        modalContent={this.secondrecurring}
                        modalType='secondrec'
                        managepaybtn={overviewSummAem.auth_setup_recurring_btn} />

    //Ship Recurring Modal
    shipRecurringModalContent = {
        header: overviewSummAem.auth_get_discount_banner_text,
        desc: overviewSummAem.auth_ship_recurring_modal_content_isb,
        btn_txt: overviewSummAem.auth_close_btn_text,
        planNum: this.props.planNum
    }

//FED one recurring request Modal Content
    secondrecurring = {
        header: overviewSummAem.auth_second_recurring_header,
        desc: overviewSummAem.auth_second_recurring_content,
        btn_txt: overviewSummAem.auth_close_btn_text,
        planNum: this.props.planNum
    }
                    
    secondrecurringModal = <PaymentModals
                        modalContent={this.secondrecurring}
                        modalType='secondrec'
                        managepaybtn={overviewSummAem.auth_setup_recurring_btn} />

    paymentHistoryJump() {
        return (
            <Fragment>
                <div><span>See</span> <Link className="paymentHistoryJump" href="javascript:" id={'jumpLink' + this.props.planNum} noIcon onClick={e => this.paymentHistoryScroll()}> payment history</Link> <span>for details.</span></div>
            </Fragment>
        )
    }

    //Function to scroll to billing and payment history
    paymentHistoryScroll() {
        try {
            let accordionid = document.getElementById('accordionid_' + this.props.planNum);
            if (accordionid.getAttribute('aria-expanded') !== "true") {
                accordionid.click();
            }
            let tabPayment = document.getElementById('historyTabs_' + this.props.planNum);
            let paymentTabId = tabPayment.children[0].children[0].children[0].children[0].id
            let paymentTab = document.getElementById(paymentTabId);
            paymentTab.focus();
            paymentTab.click();
            if (tabPayment) {
                tabPayment.scrollIntoView(!isIos() && { behavior: 'smooth'})
            }
        } catch (error) {
            console.log(error);
         }
    }

    getMakeOneTimeView() {
        let makeOneTimeFlow = true
        let eap = this.props?.readBillingAndTransDtl?.estimatedAnnualPremium ? parseFloat(this.props?.readBillingAndTransDtl?.estimatedAnnualPremium) : 0
        let oneTimeModal = null
        let nofurtherpayModal = <PaymentModals
                                modalContent={this.nofurtherpay}
                                modalType='nofurtherpay'
                                makepaybtn={overviewSummAem.auth_make_payment_btn} />

        if(this.props.plan.productType == 'GOVT' && (eap == 0) && this.props.searchPaymentWalletResults.currentPaymentMethod.indexOf('CMS-SSA/RRB') == -1) {
            makeOneTimeFlow = false
            oneTimeModal = nofurtherpayModal
        }else if(this.props.plan.productType == 'GOVT' && this.props.searchPaymentWalletResults.currentPaymentMethod.indexOf('CMS-SSA/RRB') > -1 && (eap == 0)){
                makeOneTimeFlow = false
                oneTimeModal = this.noPaymentCmsSSAModal;
        }
        
        if(makeOneTimeFlow) {
            return isDesktop() ?
                <Fragment>
                    <Button id={'makeOneTime_' + this.props.planNum} onClick={() => this.goToMakePayment(true)}>{overviewSummAem.auth_make_payment_btn}</Button>
                    {this.props.plan.productType === 'SHIP' && 
                        <>
                        <small><span>{overviewSummAem.auth_payment_due_note}</span>
                                           <PopupTip
                                                icon="QuestionMark"
                                                iconColor="primary"
                                                placement="bottom"
                                                className="ml-0"
                                                contentId={'paymentdue_' + this.props.planNum}
                                                title={overviewSummAem.auth_recent_pay_tooltip_title}
                                            >
                                                <Markup content={overviewSummAem.auth_recent_payments_tooltip_content}/>
                                            </PopupTip>     
                                            {this.paymentHistoryJump()}
                        </small>
                        </>
                    }
                </Fragment> : 
                <Fragment>
                <div className="paymentLabel">
                {this.props.plan.productType === 'SHIP' && 
                    <small><Markup content={overviewSummAem.auth_payment_due_note} />
                     <PopupTip
                                                icon="QuestionMark"
                                                iconColor="primary"
                                                placement="bottom"
                                                className="ml-0"
                                                contentId={'paymentdue_' + this.props.planNum}
                                                title={overviewSummAem.auth_recent_pay_tooltip_title}
                                            >
                                                <Markup content={overviewSummAem.auth_recent_payments_tooltip_content}/>
                                            </PopupTip>     
                        {this.paymentHistoryJump()}
                    </small>
                }
                </div>
                <div className="ctaMakePayment">
                    <Button id={'makeOneTime_' + this.props.planNum} onClick={() => this.goToMakePayment(true)}>{overviewSummAem.auth_make_payment_btn}</Button>
                </div>
            </Fragment>
        } else {
        return(
            isDesktop() ?
            <Fragment>
                {oneTimeModal}
                {this.props.plan.productType === 'SHIP' && <small><Markup content={overviewSummAem.auth_payment_due_note} />{this.props.plan.productType === 'GOVT' ? this.paymentHistoryJump() : null}</small>}
            </Fragment> : 
            <Fragment>
                <div className="paymentLabel">
                {this.props.plan.productType === 'SHIP' && <small><Markup content={overviewSummAem.auth_payment_due_note} />{this.props.plan.productType === 'GOVT' ? this.paymentHistoryJump() : null}</small>}
                </div>
                <div className="ctaMakePayment">
                    {oneTimeModal}
                </div>
        </Fragment>
            )
        }
    }


    isRecPaymentMethod() { 
        if (this.state.paymentMethod.indexOf('EFT') > -1 || this.state.paymentMethod.indexOf('CMS-SSA/RRB') > -1 || this.state.paymentMethod.indexOf('Credit') > -1) {
            return true
        }
        return false
    }

    isSSAScheduleLable = (upcomingDueDate)=> {
        upcomingDueDate = Moment(upcomingDueDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
        let upcomingDueDateMinusOneDay = Moment(upcomingDueDate, 'YYYY-MM-DD').add(-1, 'days').format('YYYY-MM-DD')
        let paymentMethod = this.state.paymentMethod;

        if(this.props.searchPaymentWalletResults?.ssaDates?.ssaStartDate && (upcomingDueDate == this.props.searchPaymentWalletResults?.ssaDates?.ssaStartDate)) {
            return true
        } else if(paymentMethod.includes('CMS') && this.props.searchPaymentWalletResults?.ssaDates?.ssaStopDate && this.props.searchPaymentWalletResults?.futurePaymentMethodStartDate && (Moment(this.props.searchPaymentWalletResults?.futurePaymentMethodStartDate, 'YYYY-MM-DD') <= Moment(upcomingDueDate, 'YYYY-MM-DD'))) {
            return true
        } else if(paymentMethod.includes('CMS') && this.props.searchPaymentWalletResults?.ssaDates?.ssaStopDate && (this.props.searchPaymentWalletResults?.ssaDates?.ssaStopDate == upcomingDueDateMinusOneDay)) {
            return false
        } else if(paymentMethod.includes('CMS')) {
            return true
        } else if((paymentMethod.includes('EFT') || paymentMethod.includes('Credit'))) {
            if(this.props.searchPaymentWalletResults?.currentPaymentMethodStopDate && ((Moment(this.props.searchPaymentWalletResults?.currentPaymentMethodStopDate, 'YYYY-MM-DD') > Moment(upcomingDueDate, 'YYYY-MM-DD'))
                        || (this.props.searchPaymentWalletResults?.futurePaymentMethodStartDate && !(Moment(this.props.searchPaymentWalletResults?.futurePaymentMethodStartDate, 'YYYY-MM-DD') < Moment(upcomingDueDate, 'YYYY-MM-DD'))))) {
                return true
            }
        }
        return false
    }

    isScheduleLable(upcomingDueDate){
        if (this.state.paymentMethod) {
            let paymentMethod = this.state.paymentMethod;
            let isGapInRecurringDates=(this.props.searchPaymentWalletResults?.currentPaymentMethodStopDate && this.props.searchPaymentWalletResults?.futurePaymentMethodStartDate) ? 
                        Moment(this.props.searchPaymentWalletResults?.futurePaymentMethodStartDate).isAfter(Moment(this.props.searchPaymentWalletResults?.currentPaymentMethodStopDate).add(1,'days')) : false
            if(this.props.plan.productType === 'GOVT' && (paymentMethod.includes('CMS') || this.props.searchPaymentWalletResults?.ssaDates?.ssaStopDate || this.props.searchPaymentWalletResults?.ssaDates?.ssaStartDate)) {
                return this.isSSAScheduleLable(upcomingDueDate)
            }else if (this.props.plan.productType === 'SHIP' && this.props?.readBillingAndTransDtl && this.props?.readBillingAndTransDtl?.futurePaymentMethod && this.props?.readBillingAndTransDtl?.futurePaymentMethod.indexOf('EFT') > -1 && !paymentMethod.indexOf('EFT') > -1) {
                return true
            }else if(this.props.plan.productType === 'GOVT' && paymentMethod.indexOf('Direct') > -1 && this.props.futureFedPayMethod && this.props.futureFedPayMethodStartDate && (this.props.futureFedPayMethodStartDate!==upcomingDueDate)){
                return false
             }else if(this.props.plan.productType === 'GOVT' && paymentMethod.indexOf('Direct') > -1 && this.props.futureFedPayMethod && this.props.futureFedPayMethodStartDate && (this.props.futureFedPayMethodStartDate===upcomingDueDate)){
                return true
             }else if(this.props.plan.productType === 'GOVT' && this.props.futureFedPayMethod && this.props.futureFedPayMethod.indexOf('DirectPay') > -1 && this.props.futureFedPayMethodStartDate && (Moment(upcomingDueDate) >= Moment(this.props.futureFedPayMethodStartDate))){
                return false;
             }else if(this.props.plan.productType === 'GOVT' && this.props.futureFedPayMethod && (this.props.futureFedPayMethod.indexOf('EFT') > -1 || this.props.futureFedPayMethod.indexOf('Credit') > -1) && this.props.futureFedPayMethodStartDate && isGapInRecurringDates){
                return false;
             }else if(this.props.plan.productType === 'SHIP' && this.props?.readBillingAndTransDtl && this.props?.readBillingAndTransDtl?.futurePaymentMethod && this.props?.readBillingAndTransDtl?.futurePaymentMethod.indexOf('Coupon Book') > -1){
                return false;
            }else if (paymentMethod.indexOf('EFT') > -1 || paymentMethod.indexOf('CMS-SSA/RRB') > -1 || paymentMethod.indexOf('Credit') > -1) {
                return true
            }
        }
        return false

    }
    getPlanCardFlag () {
        let planCardFlag ='';
        if (this.props?.readBillingAndTransDtl) {
			if (this.props?.readBillingAndTransDtl?.totalAmountDue && this.props?.readBillingAndTransDtl?.totalAmountDue > 0 &&  this.props?.readBillingAndTransDtl?.paymentMethod.indexOf('CMS-SSA/RRB') === -1) {
				planCardFlag = "OverDue";
            } 
            
            if (this.props.plan.productType === 'SHIP' && this.props?.readBillingAndTransDtl?.paidInFull) {
                planCardFlag = "Paid in Full";
            } else if (this.props?.readBillingAndTransDtl?.estimatedAnnualPremium != null
					&& this.props?.readBillingAndTransDtl?.estimatedAnnualPremium == 0.00) {
				planCardFlag = "Paid in Full";
            } 
            
            if ( this.props.plan.productType === 'SHIP' && this.props?.readBillingAndTransDtl?.creditBalance != null && this.props?.readBillingAndTransDtl?.creditBalance > 0
					&& !this.props?.readBillingAndTransDtl?.paidInFull
					&& (this.props?.readBillingAndTransDtl?.totalAmountDue == '0' || this.props?.readBillingAndTransDtl?.totalAmountDue == '0.00')) {
				planCardFlag = "Overpayment Credit";
			} else if (this.props?.readBillingAndTransDtl?.estimatedAnnualPremium != null && this.props?.readBillingAndTransDtl?.estimatedAnnualPremium != 0.00 && this.props?.readBillingAndTransDtl?.creditBalance != null && this.props?.readBillingAndTransDtl?.creditBalance > 0) {
                planCardFlag = "Overpayment Credit";
            }
        }
       return planCardFlag;
    }

    goToMakePayment = (isOneTime) => {
        if (localStorage.getItem('encStr') === null || localStorage.getItem('encStr') === '' || localStorage.getItem('encStr' === 'null')) {
            if (appConfigs.encKeyStr !== undefined) {
                localStorage.setItem('encStr', appConfigs.encKeyStr)
            } else {
                this.props.handleServiceError(true);
            }
        }
        let routeTo = this.props.plan.productType === 'SHIP' ? isOneTime ? '/make-payment' : '/recurring' : isOneTime ? '/federal-make-payment' : '/federal-recurring'
        let lnkName = isOneTime ? overviewSummAem.auth_make_payment_btn : (this.props.recurringOn || this.props.recurringFuture) ? 'edit:recurring payments' : secureLocalStorage.getItem('IsFromquicklinks') ? 'set up recurring payments' :'summary:set up recurring payments'
        let dtmPayParams = {linkName: lnkName , planCardFlag: this.getPlanCardFlag()} ;
        let isPastAmountDue = "false";
        let eftNumber = '';
        let totalAmountDue = '';
        let paymentsMethodValue = '';
        let monthlyPaymentPremium = '';
        let shipwrapperPojo = null;
        if(!isEditPayment())
            triggerEvent ('linkClick', dtmPayParams);
        secureLocalStorage.setItem("recurringPaymentFlag", !isOneTime)
        secureLocalStorage.setItem("oneTimePaymentFlag", isOneTime)
        secureLocalStorage.setItem('paymentsMethodValue', this.state.paymentMethod)
        if(this.props.plan.productType === 'SHIP' && this.props.shipPlanDetails && this.props.shipPlanDetails.length > 0) {
            secureLocalStorage.setItem("isbPlanInformation" , JSON.stringify(this.props.shipPlanDetails[0]));
        }
        secureLocalStorage.setItem("planInfomation", JSON.stringify(this.props.plan))
        
        if(this.props.plan.productType === 'SHIP'){
            let currentPaymentMethod = []
            if (this.props.customerAccountSummary && this.props.customerAccountSummary.currentPaymentMethodList) {
                currentPaymentMethod = this.props.customerAccountSummary?.currentPaymentMethodList?.filter(mop => {
                    return mop.paymentMethod === 'EFT' 
                })
            }
            if (currentPaymentMethod && currentPaymentMethod.length > 0 && currentPaymentMethod[0]?.paymentMethod === 'EFT') {
                let currPayMethod=currentPaymentMethod[0]?.paymentMethod +' '+ currentPaymentMethod[0]?.bankAccountType
                paymentsMethodValue = currPayMethod
                eftNumber = currentPaymentMethod[0]?.bankAccountNumber.slice(-4);
                secureLocalStorage.setItem('paymentsMethodValue', paymentsMethodValue);
            }
            totalAmountDue = this.props.customerAccountSummary?.currentBillingStatus?.pastDueAmount
            if (this.props.customerAccountSummary?.currentBillingStatus?.pastDueAmount && this.props.customerAccountSummary?.currentBillingStatus?.pastDueAmount > 0 ) {
                isPastAmountDue = "true";
            }
        }else{
            paymentsMethodValue = this.state.paymentMethod;
            eftNumber = this.props.plan.productType === 'GOVT' ? this.props.fedEftBankNumber : this.props?.readBillingAndTransDtl?.eftNumber;
            totalAmountDue = this.props?.readBillingAndTransDtl?.totalAmountDue;

            if (this.props?.readBillingAndTransDtl?.totalAmountDue && this.props?.readBillingAndTransDtl?.totalAmountDue > 0 &&  this.state.paymentMethod.indexOf('CMS-SSA/RRB') === -1) {
                isPastAmountDue = "true";
            } 
        }
        secureLocalStorage.setItem("isPastAmountDue", isPastAmountDue)
        if (this.props.plan.productType === 'GOVT' && this.props?.readBillingAndTransDtl?.premium) {
            monthlyPaymentPremium = this.props?.readBillingAndTransDtl?.premium.basePremium;
            secureLocalStorage.setItem('monthlyPaymentPremium', monthlyPaymentPremium);
        }
        if (this.props.plan.productType === 'GOVT') {
            secureLocalStorage.setItem("searchPaymentMethodResult", this.props.searchPaymentMethodResult)
            secureLocalStorage.setItem("searchPaymentMethodAPIFailure", this.props.searchPaymentMethodAPIFailure)
            secureLocalStorage.setItem('futurePaymentMethod', this.props.futureFedPayMethod ? this.props.futureFedPayMethod : '')
        }
        if (this.props.plan.productType === 'GOVT' && (moment().isBefore(moment(this.props.searchPaymentWalletResults?.ssaDates?.ssaStopDate)))) {
            secureLocalStorage.setItem('ssaStopDate', this.props.searchPaymentWalletResults?.ssaDates?.ssaStopDate )
        }
        if (this.props.plan.productType === 'SHIP' && this.props?.readBillingAndTransDtl) {
            shipwrapperPojo = this.props?.readBillingAndTransDtl?.wrapperPojo
        }
        if(isEditPayment()){
            secureLocalStorage.setItem("recurringPaymentFlag", false)
            secureLocalStorage.setItem("oneTimePaymentFlag", false)
            routeTo = '/federal-update-payment'
        }
        if(isCancelPayment()) {
            secureLocalStorage.setItem("recurringPaymentFlag", false)
            secureLocalStorage.setItem("oneTimePaymentFlag", false)
            routeTo = '/federal-payment-review-and-submit'
            this.props.history.push({pathname:routeTo, state: {pageLoadViaFlow : true, paymentToEdit : secureLocalStorage.getItem('paymentToEdit') && JSON.parse(secureLocalStorage.getItem('paymentToEdit'))}})
        }
        secureLocalStorage.setItem('eftNumber', eftNumber);
        secureLocalStorage.setItem('totalAmountDue', totalAmountDue);
        this.props.history.push({
            pathname: routeTo,
            state: {
                eftNumber: eftNumber,
                totalAmountDue: totalAmountDue,
                monthlyPaymentPremium: monthlyPaymentPremium,
                fromPage: 'overview',
                paymentsMethodValue: paymentsMethodValue,
                shipwrapperPojo: (this.props.plan.productType === 'SHIP' && this.props?.readBillingAndTransDtl) ? shipwrapperPojo : undefined,
                isbPendingAmount: (this.props.plan.productType === 'SHIP') ? this.props?.isbPendingAmount : undefined,
            }
        })
    }

    goToWallet = ()=> {
        secureLocalStorage.setItem('planInfomation', JSON.stringify(this.props.plan));
       this.setState({mfaCallBackURL: window.location.origin + '/medicare/UCPPayments/hrt/hsid/mfa/callback/v1?source=wallet'})
        if(this.state.isMFA){
            if (this.props.superUser) {
                this.goToManageWallet();
            } else {
                getSSOValuefromHSID().then((res) => {
                    if(res){
                        this.setState({mfaValidation:false})
                        this.goToManageWallet() 
                    }else if(res === false){
                        this.setState({mfaValidation:true})
                    }
                    })
                }
        } else {
            this.goToManageWallet() 
        }
    }
    goToManageWallet = () => {
        let routeTo = '/manage-wallet'
        let eftNumber = '';
        let readBillingAndTransactionDtl = null;
        if(this.props.plan.productType === 'GOVT') {
            if (localStorage.getItem('encStr') === null || localStorage.getItem('encStr') === '' || localStorage.getItem('encStr' === 'null')) {
                if (appConfigs.encKeyStr !== undefined) {
                    localStorage.setItem('encStr', appConfigs.encKeyStr)
                } else {
                    this.props.handleServiceError(true);
                }
            }
            secureLocalStorage.setItem("planInfomation", JSON.stringify(this.props.plan))
            readBillingAndTransactionDtl = this.props?.readBillingAndTransDtl;
            secureLocalStorage.setItem("searchPaymentMethodResult", this.props.searchPaymentMethodResult)
            secureLocalStorage.setItem("searchPaymentMethodAPIFailure", this.props.searchPaymentMethodAPIFailure)
            secureLocalStorage.setItem('futurePaymentMethod', this.props.futureFedPayMethod ? this.props.futureFedPayMethod : '')
            eftNumber = this.props.plan.productType === 'GOVT' ? this.props.fedEftBankNumber : this.props.readBillingAndTransactionDtl.eftNumber;

      
              this.props.history.push({
                pathname: routeTo,
                state: {
                    isMFAAuthenticated: (sessionStorage.getItem('getUUIDfromHSID') || this.state.isMFAAuthenticated || this.props.superUser) ? true : false,
                    isMFA: this.state.isMFA,
                    eftNumber: eftNumber,
                    readBillingAndTransactionDtl: readBillingAndTransactionDtl,
                },
              })
        }
        trackLinkClick('manage my wallet');
        console.log('manage my wallet');
    }

goToCmsPage =() =>{
        trackLinkClick('summary:request ssa/rrb deduction');
        console.log('summary:request ssa/rrb deduction');
         if (this.props?.readBillingAndTransDtl?.totalAmountDue && this.props?.readBillingAndTransDtl?.totalAmountDue > 0) {
            let isPastAmountDue = "true";
            secureLocalStorage.setItem("isPastAmountDue", isPastAmountDue)
        } 
        secureLocalStorage.setItem("cmsNextAvailableStartDate", this.props.searchPaymentWalletResults.ssaDates.ssaNextAvailableStartDate)
        secureLocalStorage.setItem("recurringPaymentFlag", false)
        secureLocalStorage.setItem("oneTimePaymentFlag", false)
        secureLocalStorage.setItem("planInfomation", JSON.stringify(this.props.plan))
        this.props.history.push(
            { 
                pathname: '/cms-ssa', 
                state: { 
                    fromPage: 'overview', 
                    paymentsMethodValue: this.state.paymentMethod,
                    totalAmountDue: this.props?.readBillingAndTransDtl?.totalAmountDue,
                } });
    }
isCmsRequested(){
           return this.props.searchPaymentWalletResults?.ssaDates?.ssaStartDate!=null
    }
isCmsWithInGracePeriod(){
        return this.props.plan.productType === 'GOVT' && this.isCmsRequested() && this.props.searchPaymentWalletResults?.ssaDates?.ssaTimelineTerminatableBeforeActive
}
cmsPaymentMethodState(){
        if(this.state.paymentMethod.includes('CMS') && this.props.searchPaymentWalletResults?.ssaDates?.ssaStopDate && secureLocalStorage.getItem('currentDate') &&
            secureLocalStorage.getItem('currentDate') === this.props.searchPaymentWalletResults.ssaDates.currSsaModifiedOn.split(" ")[0]) {
            return null
        } 
        return (
            <span className="setup_SSARRB_links">
                 {this.isSSASuppressed() ? null:
                    <button className={!this.isCmsRequested() ? "ctaEditLink tds-link tds-link--medium" : "cancelCtaEditLink tds-link tds-link--medium"} role="link" id={'SSARRB_Btn_' + this.props.planNum} onClick={() => this.goToCmsPage(false)}>
                        <span className={!this.isCmsRequested() ? "edit_txt_lbl" : 'cancel_txt_lbl'}>{(this.isCmsRequested() || this.isCmsRecurringActive()) ? overviewSummAem.auth_ssa_rrb_rec_cancel_link : overviewSummAem.auth_request_ssa_rrb_deduction_link }</span>
                        <span className="tds-link__icon-wrapper">⁠<svg className="MuiSvgIcon-root tds-utility-icon tds-link__icon tds-link__icon--internal" focusable="false" viewBox="0 0 24 24" color="#196ecf" aria-hidden="true" role="img"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path></svg></span>
                    </button>}
            </span>
        )
    }
isCmsRecurringActive(){
        return (this.state.paymentMethod.indexOf('CMS') > -1) && !this.props.searchPaymentWalletResults.ssaDates.ssaStopDate && !this.props.futureFedPayMethod
}
cmsCancelModelContent = {
    header: overviewSummAem.ssa_cancel_modal_header,
    cmsDesc: this.isCmsWithInGracePeriod() ? '' : `${overviewSummAem.ssa_cancel_modal_desc} ${moment(this.props.searchPaymentWalletResults?.ssaDates?.ssaNextAvailableStopDate).format('MM/DD/YYYY')}.`,
    btn_txt: overviewSummAem.ssa_cancel_modal_cancelBtn,
    btn_txt1: overviewSummAem.ssa_cancel_modal_stayBtn
}

cmsCancelModel = <PaymentModals
                    modalContent={this.cmsCancelModelContent}
                    modalType='cmsCancelPayment'
                    planInformation={this.props.plan}
                    cancel_cms_btn_txt={overviewSummAem.ssa_cancel_btn} 
                    superUser = {this.props.superUser}
                    handleSuperUserErr = {this.props.handleSuperUserErr}
                    />

directBillView(setupEditLink) {
    let ssaStartDate=this.props.searchPaymentWalletResults.ssaDates.ssaStartDate ? Moment(this.props.searchPaymentWalletResults.ssaDates.ssaStartDate).format('MM/DD/YYYY') : null
    if((this.props.futureFedPayMethod && this.props.futureFedPayMethodStartDate) && this.isCmsRequested()){
        if(this.isCmsWithInGracePeriod()){
           return ( 
                    <h3 id="rec_sch_id" className="tds-text__paragraph recurringPaymentText"><img src={recurringPayStartIcon} alt="" aria-hidden="true" /><span style={{fontWeight: 'bold', textTransform: 'capitalize'  }}>{overviewSummAem.auth_rec_pay_start_text_1}</span> <span style={{color: 'rgb(90, 90, 90)', fontWeight: 'bold'}}>{this.displayFuturePay()}
                    </span> <span style={{ color: '#5a5a5a', fontStyle: 'italic', fontWeight: 'normal' }}>{overviewSummAem.auth_rec_pay_start_text_2}</span>
                    <span style={{fontWeight: 'bold', color: 'rgb(90, 90, 90)', fontStyle: 'italic' }}>{this.props.futureFedPayMethodStartDate}</span><span style={{ color: '#5a5a5a', fontWeight: 'normal' }}>|</span> 
                    <span style={{color: 'rgb(90, 90, 90)', fontWeight: 'bold'}}> {overviewSummAem.auth_ssa_rrb_rec_title}</span> <span style={{ color: '#5a5a5a', fontStyle: 'italic', fontWeight: 'normal' }}>{overviewSummAem.auth_rec_pay_start_text_2}</span><span style={{fontWeight: 'bold', fontStyle: 'italic', marginRight : '5px', color: 'rgb(90, 90, 90)' }}> {' ' + ssaStartDate}</span> {this.cmsCancelModel}</h3>
                  )    
    }else{
            return <h3 id="rec_sch_id" className="tds-text__paragraph recurringPaymentText" style={{display: 'flex'}}><img src={recurringPayStartIcon} alt="" aria-hidden="true" /><span style={{fontWeight: 'bold', textTransform: 'capitalize'  }}>{overviewSummAem.auth_rec_pay_start_text_1}</span> <span style={{fontWeight: 'bold'}}>{this.displayFuturePay()}
                    </span> <span style={{ color: '#5a5a5a', fontStyle: 'italic', fontWeight: 'normal' }}>{overviewSummAem.auth_rec_pay_start_text_2}</span>
                    <span style={{fontWeight: 'bold', fontStyle: 'italic'}}>{this.props.futureFedPayMethodStartDate}</span>
                    <span style={{fontWeight:'normal'}}>|</span>
                    <span style={{color: 'rgb(90, 90, 90)', fontWeight: 'bold'}}>{overviewSummAem.auth_ssa_rrb_rec_title}</span> <span style={{ color: '#5a5a5a', fontStyle: 'italic', fontWeight: 'normal' }}>{overviewSummAem.auth_rec_pay_start_text_2}</span><span style={{fontWeight: 'bold', color: 'rgb(90, 90, 90)', fontStyle: 'italic', marginRight : '5px' }}> {' ' + ssaStartDate}</span></h3>
        }
    }else if(this.isCmsRequested()){
            if(this.isCmsWithInGracePeriod()){
                return (
                    <>
                    <div className="ssaAlignItems recCancelLink recFlexLink">
                        <h3 id="rec_sch_id" className="tds-text__paragraph recurringPaymentText"><img src={recurringPayStartIcon} alt="" aria-hidden="true" /><span style={{color: 'rgb(90, 90, 90)', fontWeight: 'bold'}}>{overviewSummAem.auth_ssa_rrb_rec_title}</span> <span style={{ color: '#5a5a5a', fontStyle: 'italic', fontWeight: 'normal' }}>{overviewSummAem.auth_rec_pay_start_text_2}</span><span style={{fontWeight: 'bold', color: 'rgb(90, 90, 90)', fontStyle: 'italic', marginRight : '10px' }}> {' ' + ssaStartDate}</span></h3>
                        {this.cmsCancelModel}
                    </div>
                </>
                )
            }else{
                return <h3 id="rec_sch_id" className="tds-text__paragraph recurringPaymentText"><img src={recurringPayStartIcon} alt="" aria-hidden="true" /><span style={{color: 'rgb(90, 90, 90)', fontWeight: 'bold'}}>{overviewSummAem.auth_ssa_rrb_rec_title}</span> <span style={{ color: '#5a5a5a', fontStyle: 'italic', fontWeight: 'normal' }}>{overviewSummAem.auth_rec_pay_start_text_2}</span><span style={{fontWeight: 'bold', color: 'rgb(90, 90, 90)', fontStyle: 'italic', marginRight : '5px' }}> {' ' + ssaStartDate}</span></h3> 
            }
            
    }else if(this.props.futureFedPayMethod && this.props.futureFedPayMethodStartDate){
        return <h3 id="rec_sch_id" className="tds-text__paragraph recurringPaymentText reqEditSSA"><img src={recurringPayStartIcon} alt="" aria-hidden="true" /><span style={{fontWeight: 'bold', textTransform: 'capitalize'  }}>{overviewSummAem.auth_rec_pay_start_text_1}</span> <span style={{fontWeight: 'bold'}}>{this.displayFuturePay()}</span> <span style={{ color: '#5a5a5a', fontStyle: 'italic', fontWeight: 'normal' }}>{overviewSummAem.auth_rec_pay_start_text_2}</span><span style={{fontWeight: 'bold', fontStyle: 'italic', marginRight : '5px' }}>{this.props.futureFedPayMethodStartDate}</span> {setupEditLink} {this.cmsPaymentMethodState()}</h3>
    } else{
        return (
            <div style={{background: 'rgb(244, 244, 244)'}}>
            <h3 id="rec_sch_id" className="tds-text__paragraph recDiscountFlag" style={{display: 'grid'}}>
                <span style={{display: 'flex'}}><img src={recurringFlagIcon} alt="" aria-hidden="true" /><span className="restEasy_txt">{overviewSummAem.auth_rest_easy_knowing_banner_cnt}</span></span>
            </h3>
            <ul className="setupCmsLinks">
                <li>{setupEditLink}</li>
                { this.isSSASuppressed() 
                ?null:<li>{this.cmsPaymentMethodState()}</li>}       
            </ul>
            
            </div>) 
    }  
}
    recurringFlowView(setupEditLink){
        let ssaStartDate=this.props.searchPaymentWalletResults.ssaDates.ssaStartDate ? Moment(this.props.searchPaymentWalletResults.ssaDates.ssaStartDate).format('MM/DD/YYYY') : null
        let isGapInRecurringDates=(this.props.searchPaymentWalletResults?.currentPaymentMethodStopDate && this.props.searchPaymentWalletResults?.futurePaymentMethodStartDate) ? 
                        Moment(this.props.searchPaymentWalletResults?.futurePaymentMethodStartDate).isAfter(Moment(this.props.searchPaymentWalletResults?.currentPaymentMethodStopDate).add(1,'days')) : false
        let isGapInSSARecurringDates=(this.props.searchPaymentWalletResults?.currentPaymentMethodStopDate && this.props.searchPaymentWalletResults.ssaDates.ssaStartDate) ?
                            Moment(this.props.searchPaymentWalletResults.ssaDates.ssaStartDate).isAfter(Moment(this.props.searchPaymentWalletResults?.currentPaymentMethodStopDate).add(1, 'days')) :false  
        let isGapInSSADates=(this.props.searchPaymentWalletResults?.ssaDates?.ssaStopDate && this.props.searchPaymentWalletResults?.futurePaymentMethodStartDate) ?
        Moment(this.props.searchPaymentWalletResults?.futurePaymentMethodStartDate).isAfter(Moment(this.props.searchPaymentWalletResults?.ssaDates?.ssaStopDate).add(1, 'days')) :false 
        if(this.state.paymentMethod.indexOf('CMS') == -1 && this.props.futureFedPayMethod && (this.props.futureFedPayMethod.indexOf('EFT') > -1 || this.props.futureFedPayMethod.indexOf('Credit') > -1) && this.props.futureFedPayMethodNumber 
        && isGapInRecurringDates){  
                return <h3 id="rec_sch_id" className="tds-text__paragraph recurringH3" style={{ color: '#5A5A5A', fontWeight: 'bold', paddingTop: '10px' }}><img src={recurringPayStartIcon} alt="" aria-hidden="true" /> <span style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>{overviewSummAem.auth_recurring_payment_title}</span> <span style={{ color: 'green', fontWeight: 'bold'}}>{overviewSummAem.auth_on_text}</span> <span style={{fontWeight:'normal'}}>|</span> <span style={{ color: '#5a5a5a', fontWeight:'bold', fontStyle:'italic'}}>{overviewSummAem.auth_monthly_bill_text}
                <span style={{fontStyle: 'italic', fontWeight:'normal'}}> {overviewSummAem.auth_rec_pay_start_text_2}</span><span style={{fontWeight:'bold',fontStyle: 'italic'}}>{Moment(this.props.searchPaymentWalletResults?.currentPaymentMethodStopDate).add(1,'days').format('MM/DD/YYYY')}</span>
                </span><span className="recSeperator">|</span> 
                <div className="recItemNextLine">
                <span style={{ color: '#5a5a5a', fontWeight:'bold'}}>{this.displayFuturePay()}<span style={{fontStyle: 'italic', fontWeight: 'normal'}}> {overviewSummAem.auth_rec_pay_start_text_2}</span>
                <span style={{fontWeight:'bold',fontStyle: 'italic', marginRight : '10px'}}>{this.props.futureFedPayMethodStartDate}</span></span>{setupEditLink}{this.cmsPaymentMethodState()}</div></h3>
                
        }else if(this.state.paymentMethod.indexOf('CMS') === -1 && this.props.futureFedPayMethod && (this.props.futureFedPayMethod.indexOf('EFT') > -1 || this.props.futureFedPayMethod.indexOf('Credit') > -1) && this.props.futureFedPayMethodNumber){  
            if(this.isCmsRequested()){
                if(this.isCmsWithInGracePeriod()){
                    return (
                    <>
                    <Typography.H3 id="rec_sch_id" className="recurringH3 recEftSsaCombine"><img src={recurringPayStartIcon} alt="" aria-hidden="true" />  <span style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>{overviewSummAem.auth_recurring_payment_title}</span> <span style={{ color: 'green', fontWeight: 'bold' }}>{overviewSummAem.auth_on_text}</span>
                    <span style={{fontWeight:'normal'}}> |</span><span style={{ color: '#5a5a5a', fontWeight:'bold',fontStyle: 'italic'}}>  {this.displayFuturePay()}<span style={{fontStyle: 'italic', fontWeight: 'normal'}}> {overviewSummAem.auth_rec_pay_start_text_2} </span>
                    <span style={{fontWeight:'bold',fontStyle: 'italic'}}>{this.props.futureFedPayMethodStartDate}</span></span>
                    <span className="recSeperator">|</span>
                   
                    </Typography.H3>
                    <div className="recOnSsaReq">
                    <span style={{color: 'rgb(90, 90, 90)',fontWeight: 'bold',fontStyle: 'italic'}}>{overviewSummAem.auth_ssa_rrb_rec_title}</span> <span style={{ color: '#5a5a5a', fontStyle: 'italic', fontWeight: 'normal' }}>{overviewSummAem.auth_rec_pay_start_text_2}</span><span style={{fontWeight: 'bold', fontStyle: 'italic', color: 'rgb(90, 90, 90)', marginRight : '15px' }}>{' ' + ssaStartDate} </span>
                    {this.cmsCancelModel}
                    </div>  
                    </>
                    )
                }else{
                    return <Typography.H3 id="rec_sch_id" className="recurringH3" style={{ color: '#5A5A5A', fontWeight: 'bold', marginTop: '6px' }}><img src={recurringPayStartIcon} alt="" aria-hidden="true" /> <span style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>{overviewSummAem.auth_recurring_payment_title}</span> <span style={{ color: 'green', fontWeight: 'bold' }}>{overviewSummAem.auth_on_text}</span>
                    <span style={{fontWeight:'normal'}}> |</span><span style={{ color: '#5a5a5a', fontWeight:'bold'}}>  {this.displayFuturePay()}<span style={{fontStyle: 'italic', fontWeight: 'normal'}}> {overviewSummAem.auth_rec_pay_start_text_2} </span>
                    <span style={{fontWeight:'bold',fontStyle: 'italic'}}>{this.props.futureFedPayMethodStartDate}</span></span>
                    <span style={{fontWeight:'normal'}}>|</span>
                    <span style={{fontWeight: 'bold',fontStyle: 'italic'}}>{overviewSummAem.auth_ssa_rrb_rec_title}</span> <span style={{ color: '#5a5a5a', fontStyle: 'italic', fontWeight: 'normal' }}>{overviewSummAem.auth_rec_pay_start_text_2}</span><span style={{fontWeight: 'bold', fontStyle: 'italic', marginRight : '5px' }}>{' ' + ssaStartDate}</span>
                    </Typography.H3>
                }   
            }else{
                return <h3 id="rec_sch_id" className="tds-text__paragraph recurringH3" style={{ color: '#5A5A5A', fontWeight: 'bold', paddingTop: '10px' }}><img src={recurringPayStartIcon} alt="" aria-hidden="true" /> <span style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>{overviewSummAem.auth_recurring_payment_title}</span> <span style={{ color: 'green', fontWeight: 'bold'}}>{overviewSummAem.auth_on_text}</span> <span style={{fontWeight:'normal'}}>|</span><span style={{ color: '#5a5a5a', fontWeight:'bold'}}>  {this.displayFuturePay()}<span style={{fontStyle: 'italic', fontWeight: 'normal'}}> {overviewSummAem.auth_rec_pay_start_text_2}</span>
                <span style={{fontWeight:'bold',fontStyle: 'italic', marginRight : '10px'}}>{this.props.futureFedPayMethodStartDate}</span></span>{setupEditLink}<div>{this.cmsPaymentMethodState()}</div></h3>
            }
        }else if((this.state.paymentMethod.indexOf('CMS') > -1) && isGapInSSADates){
            
            return <h3 id="rec_sch_id" className="tds-text__paragraph recurringH3" style={{ color: 'rgb(90, 90, 90)', fontWeight: 'bold', padding: '10px 30px 0px'}}><img src={recurringPayStartIcon} alt="" aria-hidden="true"/> <span style={{color: 'rgb(90, 90, 90)', fontWeight: 'bold', textTransform: 'capitalize' }}>{overviewSummAem.auth_ssa_rrb_rec_title}</span> <span style={{ color: 'green', fontWeight: 'bold'}}>{overviewSummAem.auth_on_text}</span>
            <span style={{fontWeight: 'normal',paddingLeft:'5px',paddingRight:'0px'}}>|</span><span style={{color: 'rgb(90, 90, 90)', fontWeight: 'bold', textTransform: 'capitalize' }}></span> <span style={{ color: '#5a5a5a', fontWeight:'bold'}}>{overviewSummAem.auth_monthly_bill_text}<span style={{fontStyle: 'italic', fontWeight: 'normal' }}> {overviewSummAem.auth_rec_pay_start_text_2} </span><span style={{fontWeight:'bold',fontStyle: 'italic'}}>{Moment(this.props.searchPaymentWalletResults?.ssaDates.ssaStopDate).add(1,'days').format('MM/DD/YYYY')}</span></span>
            <span style={{fontWeight:'normal',paddingLeft:'0px'}}>|</span><div className="cms_Gap_recurring"><span style={{ color: '#5a5a5a', fontWeight:'bold'}}>{this.displayFuturePay()}<span style={{fontStyle: 'italic', fontWeight: 'normal', paddingRight: '0px'}}> {overviewSummAem.auth_rec_pay_start_text_2} </span>
            <span style={{fontWeight:'bold',fontStyle: 'italic', marginRight : '15px'}}>{this.props.futureFedPayMethodStartDate}</span></span>{setupEditLink}</div>
            </h3>
        }else if((this.state.paymentMethod.indexOf('CMS') > -1) && this.props.searchPaymentWalletResults.ssaDates.ssaStopDate && this.props.futureFedPayMethod && this.props.futureFedPayMethodStartDate){
            
            return <h3 id="rec_sch_id" className="tds-text__paragraph recurringH3" style={{ color: 'rgb(90, 90, 90)', fontWeight: 'bold'}}><img src={recurringPayStartIcon} alt="" aria-hidden="true"/> <span style={{color: 'rgb(90, 90, 90)', fontWeight: 'bold', textTransform: 'capitalize' }}>{overviewSummAem.auth_ssa_rrb_rec_title}</span> <span style={{ color: 'green', fontWeight: 'bold'}}>{overviewSummAem.auth_on_text}</span>
            <span style={{fontWeight:'normal'}}>|</span><span style={{ color: '#5a5a5a', fontWeight:'bold'}}>{this.displayFuturePay()}<span style={{fontStyle: 'italic', fontWeight: 'normal', paddingRight: '0px'}}> {overviewSummAem.auth_rec_pay_start_text_2} </span>
            <span style={{fontWeight:'bold',fontStyle: 'italic', marginRight : '15px'}}>{this.props.futureFedPayMethodStartDate}</span></span>
            {setupEditLink}</h3>
        }else if((this.state.paymentMethod.indexOf('CMS') > -1) && this.props.searchPaymentWalletResults.ssaDates.ssaStopDate && !this.props.futureFedPayMethod && !this.isCmsRequested()){
            return(
                <>
                <div className="ssaTwoRowsItems">
                <Typography.H3 id="rec_sch_id" className="recurringH3" style={{ color: 'rgb(90, 90, 90)', padding: '10px 30px 0px', fontWeight: 'bold'}}><img src={recurringPayStartIcon} alt="" aria-hidden="true" /> <span style={{color: 'rgb(90, 90, 90)', fontWeight: 'bold', textTransform: 'capitalize' }}>{overviewSummAem.auth_ssa_rrb_rec_title}</span> <span style={{ color: 'green', fontWeight: 'bold', paddingRight: '5px'}}>{overviewSummAem.auth_on_text}</span> <span style={{fontWeight: 'normal', paddingRight:'0px'}}>|</span><span style={{color: 'rgb(90, 90, 90)', fontWeight: 'bold', textTransform: 'capitalize' }}></span> <span style={{ color: '#5a5a5a', fontWeight:'bold'}}>{overviewSummAem.auth_monthly_bill_text}<span style={{fontStyle: 'italic', fontWeight: 'normal' }}> {overviewSummAem.auth_rec_pay_start_text_2}</span><span style={{fontWeight:'bold',fontStyle: 'italic'}}>{Moment(this.props.searchPaymentWalletResults?.ssaDates.ssaStopDate).add(1,'days').format('MM/DD/YYYY')}</span></span></Typography.H3>
                <ul className="twoBtn mt-1">
                    <li>{setupEditLink}</li>
                {this.isSSASuppressed()  ? null : <li>{this.cmsPaymentMethodState()}</li>}    
                </ul>
                </div>
                </>
            ) 
         }else if(this.isCmsRecurringActive()){
            return <Typography.Paragraph id="rec_sch_id" className="recurringH3 reqSSACancelLink" style={{ color: 'rgb(90, 90, 90)', fontWeight: 'bold'}}><img src={recurringPayStartIcon} alt="" aria-hidden="true" /> <span style={{color: 'rgb(90, 90, 90)', fontWeight: 'bold', textTransform: 'capitalize' }}>{overviewSummAem.auth_ssa_rrb_rec_title}</span> <span style={{ color: 'green', fontWeight: 'bold',paddingRight:'1.5rem'}}>{overviewSummAem.auth_on_text}</span>{this.cmsCancelModel}</Typography.Paragraph>
         }else if((this.state.paymentMethod.indexOf('CMS') > -1) && this.props.searchPaymentWalletResults.ssaDates.ssaStopDate && !this.props.futureFedPayMethod){
            if(this.isCmsRequested()){
                if(this.isCmsWithInGracePeriod()){
                    return (
                        <>
                        <div className="ssaAlignItems recCancelLink recFlexLink">
                        <Typography.H3 id="rec_sch_id" className="recurringH3" style={{ color: '#5A5A5A', fontWeight: 'bold' }}><img src={recurringPayStartIcon} alt="" aria-hidden="true" />
                            <span style={{color: 'rgb(90, 90, 90)', fontWeight: 'bold',paddingLeft:'5px'}}>{overviewSummAem.auth_ssa_rrb_rec_title}</span> <span style={{ color: '#5a5a5a', fontStyle: 'italic', fontWeight: 'normal' }}>{overviewSummAem.auth_rec_pay_start_text_2}</span><span style={{fontWeight: 'bold', color: 'rgb(90, 90, 90)', fontStyle: 'italic', marginRight : '5px' }}>{ssaStartDate}</span>
                        </Typography.H3>
                        {this.cmsCancelModel}
                        </div>
                        </>
                    )
                }else{
                    return <Typography.H3 id="rec_sch_id" className="recurringH3" style={{ color: '#5A5A5A', fontWeight: 'bold' }}><img src={recurringPayStartIcon} alt="" aria-hidden="true" />
                    <span style={{color: 'rgb(90, 90, 90)', fontWeight: 'bold',paddingLeft:'5px'}}>{overviewSummAem.auth_ssa_rrb_rec_title}</span> <span style={{ color: '#5a5a5a', fontStyle: 'italic', fontWeight: 'normal' }}>{overviewSummAem.auth_rec_pay_start_text_2}</span><span style={{fontWeight: 'bold', color: 'rgb(90, 90, 90)', fontStyle: 'italic', marginRight : '5px' }}>{ssaStartDate}</span>
                </Typography.H3>
                }  
            }
         }else if((this.state.paymentMethod.indexOf('EFT') > -1) && isGapInSSARecurringDates){
                if(this.isCmsWithInGracePeriod()){
                    return (
                        <>
                        <div className="ssaAlignItems recCancelLink noSpanWidth">
                        <Typography.H3 id="rec_sch_id" className="tds-text__paragraph recurringH3" style={{ color: 'rgb(90, 90, 90)', fontWeight: 'bold'}}><img src={recurringPayStartIcon} alt="" aria-hidden="true" /> <span style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>{overviewSummAem.auth_recurring_payment_title}</span> <span style={{ color: 'green', fontWeight: 'bold'}}>{overviewSummAem.auth_on_text}</span>
                        <span style={{fontWeight:'normal'}}>|</span><span style={{ color: '#5a5a5a', fontWeight:'bold', fontStyle:'italic'}}>{overviewSummAem.auth_monthly_bill_text}<span style={{fontStyle: 'italic', fontWeight:'normal'}}> {overviewSummAem.auth_rec_pay_start_text_2} </span><span style={{fontWeight:'bold',fontStyle: 'italic'}}>{Moment(this.props.searchPaymentWalletResults?.currentPaymentMethodStopDate).add(1,'days').format('MM/DD/YYYY')}</span></span>
                        <span style={{fontWeight:'normal'}}>|</span><span style={{color: 'rgb(90, 90, 90)', fontWeight: 'bold', fontStyle:'italic'}}>{overviewSummAem.auth_ssa_rrb_rec_title}</span> <span style={{ color: '#5a5a5a', fontStyle: 'italic', fontWeight: 'normal' }}>{overviewSummAem.auth_rec_pay_start_text_2}</span> <span style={{fontWeight: 'bold', fontStyle: 'italic', marginRight : '10px' }}>{' ' + ssaStartDate}</span></Typography.H3>
                        {this.cmsCancelModel}
                    </div>
                    </>
                    ) 
               }else{
                   return <Typography.Paragraph id="rec_sch_id" className="recurringH3" style={{ color: 'rgb(90, 90, 90)', fontWeight: 'bold'}}><img src={recurringPayStartIcon} alt="" aria-hidden="true" /> <span style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>{overviewSummAem.auth_recurring_payment_title}</span> <span style={{ color: 'green', fontWeight: 'bold', paddingLeft: '5px'}}>{overviewSummAem.auth_on_text}</span> 
                   <span style={{fontWeight:'normal'}}> | </span><span style={{ color: '#5a5a5a', fontWeight:'bold', fontStyle:'italic'}}>{overviewSummAem.auth_monthly_bill_text}<span style={{fontStyle: 'italic', fontWeight:'normal'}}> {overviewSummAem.auth_rec_pay_start_text_2} </span><span style={{fontWeight:'bold',fontStyle: 'italic'}}>{Moment(this.props.searchPaymentWalletResults?.currentPaymentMethodStopDate).add(1,'days').format('MM/DD/YYYY')}</span></span>
                   <span style={{fontWeight:'normal'}}> | </span>
                   <span style={{color: 'rgb(90, 90, 90)', fontWeight: 'bold'}}>{overviewSummAem.auth_ssa_rrb_rec_title}</span> <span style={{ color: '#5a5a5a', fontStyle: 'italic', fontWeight: 'normal' }}>{overviewSummAem.auth_rec_pay_start_text_2}</span><span style={{fontWeight: 'bold', fontStyle: 'italic', marginRight : '5px' }}>{' ' + ssaStartDate}</span></Typography.Paragraph>
                } 
       }
         else if((this.state.paymentMethod.indexOf('EFT') > -1) && this.props.searchPaymentWalletResults?.currentPaymentMethodStopDate){
             if(this.isCmsRequested()){
                 if(this.isCmsWithInGracePeriod()){
                 return (
                     <>
                     <div className="ssaAlignItems recCancelLink">
                        <Typography.H3 id="rec_sch_id" className="tds-text__paragraph recurringH3" style={{ color: 'rgb(90, 90, 90)', fontWeight: 'bold'}}><img src={recurringPayStartIcon} alt="" aria-hidden="true" /> <span style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>{overviewSummAem.auth_recurring_payment_title}</span> <span style={{ color: 'green', fontWeight: 'bold', paddingRight: '5px'}}>{overviewSummAem.auth_on_text}</span>
                        <span style={{fontWeight:'normal'}}>|</span><span style={{color: 'rgb(90, 90, 90)', fontWeight: 'bold'}}>{overviewSummAem.auth_ssa_rrb_rec_title}</span> <span style={{ color: '#5a5a5a', fontStyle: 'italic', fontWeight: 'normal' }}>{overviewSummAem.auth_rec_pay_start_text_2}</span> <span style={{fontWeight: 'bold', fontStyle: 'italic', marginRight : '10px' }}>{' ' + ssaStartDate}</span></Typography.H3>
                        {this.cmsCancelModel}
                    </div>
                 </>
                 ) 
                }else{
                    return <Typography.Paragraph id="rec_sch_id" className="recurringH3" style={{ color: 'rgb(90, 90, 90)', fontWeight: 'bold'}}><img src={recurringPayStartIcon} alt="" aria-hidden="true" /> <span style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>{overviewSummAem.auth_recurring_payment_title}</span> <span style={{ color: 'green', fontWeight: 'bold', paddingLeft: '5px'}}>{overviewSummAem.auth_on_text}</span> 
                    <span style={{fontWeight:'normal'}}> | </span>
                    <span style={{color: 'rgb(90, 90, 90)', fontWeight: 'bold'}}>{overviewSummAem.auth_ssa_rrb_rec_title}</span> <span style={{ color: '#5a5a5a', fontStyle: 'italic', fontWeight: 'normal' }}>{overviewSummAem.auth_rec_pay_start_text_2}</span><span style={{fontWeight: 'bold', fontStyle: 'italic', marginRight : '5px' }}>{' ' + ssaStartDate}</span></Typography.Paragraph>
                 }
             }else{
                return <h3 id="rec_sch_id" className="recurringH3" style={{ fontWeight: 'bold', paddingTop: '10px'}}><img src={recurringPayStartIcon} alt="" aria-hidden="true" /> <span style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>{overviewSummAem.auth_recurring_payment_title}</span> <span style={{ color: 'green', fontWeight: 'bold', paddingRight: '5px'}}>{overviewSummAem.auth_on_text}</span> <span style={{fontWeight:'normal'}}>|</span> <span style={{ color: '#5a5a5a', fontWeight:'bold'}}>{overviewSummAem.auth_monthly_bill_text}<span style={{fontStyle: 'italic', fontWeight:'normal'}}> {overviewSummAem.auth_rec_pay_start_text_2} </span><span style={{fontWeight:'bold',fontStyle: 'italic', marginRight : '15px'}}>{Moment(this.props.searchPaymentWalletResults?.currentPaymentMethodStopDate).add(1,'days').format('MM/DD/YYYY')}</span></span>{setupEditLink}<div className="reqSSABtn iPadAlignLink">{this.cmsPaymentMethodState()}</div></h3>
             }   
        } else if((this.state.paymentMethod.indexOf('Credit') > -1) && this.props.searchPaymentWalletResults?.currentPaymentMethodStopDate && this.isCardExpiredInTwoMonths(this.props.searchPaymentWalletResults?.currentPaymentMethodStopDate) && isGapInSSARecurringDates){
            
                if(this.isCmsWithInGracePeriod()){
                return (
                <>
                <div className="ssaAlignItems recCancelLink anchorMarginTop">
                <Typography.Paragraph id="rec_sch_id" className="recurringH3" style={{ color: 'rgb(90, 90, 90)', fontWeight: 'bold'}}><img src={recurringPayStartIcon} alt="" aria-hidden="true" /> <span style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>{overviewSummAem.auth_recurring_payment_title}</span> <span style={{ color: 'green', fontWeight: 'bold',paddingLeft: '5px'}}>{overviewSummAem.auth_on_text}</span> 
                    <span style={{fontWeight:'normal'}}> | </span>
                    <span style={{ color: '#5a5a5a', fontWeight:'bold',fontStyle: 'italic'}}>{overviewSummAem.auth_monthly_bill_text}<span style={{fontStyle: 'italic', fontWeight:'normal'}}> {overviewSummAem.auth_rec_pay_start_text_2} </span><span style={{fontWeight:'bold',fontStyle: 'italic'}}>{Moment(this.props.searchPaymentWalletResults?.currentPaymentMethodStopDate).add(1,'days').format('MM/DD/YYYY')}</span></span>
                    <span style={{fontWeight:'normal'}}> | </span>
                    <span style={{color: 'rgb(90, 90, 90)', fontWeight: 'bold'}}>{overviewSummAem.auth_ssa_rrb_rec_title}</span> <span style={{ color: '#5a5a5a', fontStyle: 'italic', fontWeight: 'normal' }}>{overviewSummAem.auth_rec_pay_start_text_2}</span><span style={{fontWeight: 'bold', color: 'rgb(90, 90, 90)', fontStyle: 'italic', marginRight : '10px' }}>{' ' + ssaStartDate}</span></Typography.Paragraph>
                {this.cmsCancelModel}
                </div>
                </>
                )    
            }else{
                   return <Typography.Paragraph id="rec_sch_id" className="recurringH3" style={{ color: 'rgb(90, 90, 90)', fontWeight: 'bold'}}><img src={recurringPayStartIcon} alt="" aria-hidden="true" /> <span style={{ fontWeight: 'bold', textTransform: 'capitalize',paddingRight:'5px' }}>{overviewSummAem.auth_recurring_payment_title}</span> <span style={{ color: 'green', fontWeight: 'bold'}}>{overviewSummAem.auth_on_text}</span> 
                   <span style={{fontWeight:'normal'}}> | </span>
                   <span style={{ color: '#5a5a5a', fontWeight:'bold',fontStyle: 'italic'}}>{overviewSummAem.auth_monthly_bill_text}<span style={{fontStyle: 'italic', fontWeight:'normal'}}> {overviewSummAem.auth_rec_pay_start_text_2} </span><span style={{fontWeight:'bold',fontStyle: 'italic'}}>{Moment(this.props.searchPaymentWalletResults?.currentPaymentMethodStopDate).add(1,'days').format('MM/DD/YYYY')}</span></span>
                   <span style={{fontWeight:'normal'}}> | </span>
                   <span style={{color: 'rgb(90, 90, 90)', fontWeight: 'bold', fontStyle:'italic'}}>{overviewSummAem.auth_ssa_rrb_rec_title}</span> <span style={{ color: '#5a5a5a', fontStyle: 'italic', fontWeight: 'normal' }}>{overviewSummAem.auth_rec_pay_start_text_2}</span> <span style={{fontWeight: 'bold', color: 'rgb(90, 90, 90)', fontStyle: 'italic', marginRight : '5px' }}>{' ' + ssaStartDate}</span></Typography.Paragraph>
                }
             
        } else if((this.state.paymentMethod.indexOf('Credit') > -1) && this.props.searchPaymentWalletResults?.currentPaymentMethodStopDate && this.isCardExpiredInTwoMonths(this.props.searchPaymentWalletResults?.currentPaymentMethodStopDate)){
            if(this.isCmsRequested()){
                if(this.isCmsWithInGracePeriod()){
                return (
                <>
                <div className="ssaAlignItems recCancelLink anchorMarginTop">
                <Typography.Paragraph id="rec_sch_id" className="recurringH3" style={{ color: 'rgb(90, 90, 90)', fontWeight: 'bold'}}><img src={recurringPayStartIcon} alt="" aria-hidden="true" /> <span style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>{overviewSummAem.auth_recurring_payment_title}</span> <span style={{ color: 'green', fontWeight: 'bold',paddingLeft: '5px'}}>{overviewSummAem.auth_on_text}</span> 
                <span style={{fontWeight:'normal'}}> | </span>
                <span style={{color: 'rgb(90, 90, 90)', fontWeight: 'bold'}}>{overviewSummAem.auth_ssa_rrb_rec_title}</span> <span style={{ color: '#5a5a5a', fontStyle: 'italic', fontWeight: 'normal' }}>{overviewSummAem.auth_rec_pay_start_text_2}</span><span style={{fontWeight: 'bold', color: 'rgb(90, 90, 90)', fontStyle: 'italic', marginRight : '10px' }}>{' ' + ssaStartDate}</span></Typography.Paragraph>
                {this.cmsCancelModel}
                </div>
                </>
                )    
            }else{
                   return <Typography.Paragraph id="rec_sch_id" className="recurringH3" style={{ color: 'rgb(90, 90, 90)', fontWeight: 'bold'}}><img src={recurringPayStartIcon} alt="" aria-hidden="true" /> <span style={{ fontWeight: 'bold', textTransform: 'capitalize',paddingRight:'5px' }}>{overviewSummAem.auth_recurring_payment_title}</span> <span style={{ color: 'green', fontWeight: 'bold'}}>{overviewSummAem.auth_on_text}</span> 
                   <span style={{fontWeight:'normal'}}> | </span>
                <span style={{color: 'rgb(90, 90, 90)', fontWeight: 'bold'}}>{overviewSummAem.auth_ssa_rrb_rec_title}</span> <span style={{ color: '#5a5a5a', fontStyle: 'italic', fontWeight: 'normal' }}>{overviewSummAem.auth_rec_pay_start_text_2}</span> <span style={{fontWeight: 'bold', color: 'rgb(90, 90, 90)', fontStyle: 'italic', marginRight : '5px' }}>{' ' + ssaStartDate}</span></Typography.Paragraph>
                }
            }else{
               return <Typography.Paragraph id="rec_sch_id" className="recurringH3" style={{ color: 'rgb(90, 90, 90)', fontWeight: 'bold'}}><img src={recurringPayStartIcon} alt="" aria-hidden="true" /><span style={{ fontWeight: 'bold', textTransform: 'capitalize' ,paddingLeft:'5px'}}>{overviewSummAem.auth_recurring_payment_title}</span> <span style={{ color: 'green', fontWeight: 'bold'}}>{overviewSummAem.auth_on_text}</span><span style={{fontWeight:'normal'}}> | </span> <span style={{ color: '#5a5a5a', fontWeight:'bold',fontStyle: 'italic'}}>{overviewSummAem.auth_monthly_bill_text}<span style={{fontStyle: 'italic', fontWeight:'normal'}}> {overviewSummAem.auth_rec_pay_start_text_2} </span><span style={{fontWeight:'bold',fontStyle: 'italic', marginRight : '20px'}}>{Moment(this.props.searchPaymentWalletResults?.currentPaymentMethodStopDate).add(1,'days').format('MM/DD/YYYY')}</span></span>{setupEditLink}<div className="reqSSABtn iPadAlignLink">{this.cmsPaymentMethodState()}</div></Typography.Paragraph>
            }   
        }else if(this.isCmsRequested()){
            if(this.isCmsWithInGracePeriod()){
                return ( 
                <>
                <div className="ssaAlignItems recCancelLink">
                <Typography.H3 id="rec_sch_id" className="recurringH3" style={{ color: '#5A5A5A', fontWeight: 'bold' }}><img src={recurringPayStartIcon} alt="" aria-hidden="true" /><span style={{ fontWeight: 'bold',  marginLeft: '5px', textTransform: 'capitalize' }}>{overviewSummAem.auth_recurring_payment_title}</span> <span style={{ color: 'green', fontWeight: 'bold' }}>{overviewSummAem.auth_on_text}</span>
                 <span style={{paddingLeft:'5px', fontWeight:'normal', color: '#5a5a5a'}}>|</span><span style={{color: 'rgb(90, 90, 90)', fontWeight: 'bold',paddingLeft:'5px'}}>{overviewSummAem.auth_ssa_rrb_rec_title}</span> <span style={{ color: '#5a5a5a', fontStyle: 'italic', fontWeight: 'normal' }}>{overviewSummAem.auth_rec_pay_start_text_2}</span><span style={{fontWeight: 'bold', color: 'rgb(90, 90, 90)', fontStyle: 'italic', paddingLeft: '5px', marginRight : '10px' }}>{ssaStartDate}</span>
                </Typography.H3>
                {this.cmsCancelModel}
                </div>
                </>
                )
            }else{
                return <Typography.H3 id="rec_sch_id" className="recurringH3" style={{ color: '#5A5A5A', fontWeight: 'bold' }}><img src={recurringPayStartIcon} alt="" aria-hidden="true" /><span style={{ fontWeight: 'bold', marginLeft: '5px', textTransform: 'capitalize' }}>{overviewSummAem.auth_recurring_payment_title}</span> <span style={{ color: 'green', fontWeight: 'bold' }}>{overviewSummAem.auth_on_text}</span>
                <span style={{paddingLeft:'5px', fontWeight:'normal', color: '#5a5a5a'}}>|</span><span style={{color: 'rgb(90, 90, 90)', fontWeight: 'bold',paddingLeft:'5px'}}>{overviewSummAem.auth_ssa_rrb_rec_title}</span> <span style={{ color: '#5a5a5a', fontStyle: 'italic', fontWeight: 'normal' }}>{overviewSummAem.auth_rec_pay_start_text_2}</span><span style={{fontWeight: 'bold', color: 'rgb(90, 90, 90)', fontStyle: 'italic', paddingLeft: '5px', marginRight : '5px' }}>{ssaStartDate}</span>
                </Typography.H3>
            }   
        }else{
            return <Typography.H3 id="rec_sch_id" className="recurringH3" style={{ color: '#5A5A5A', fontWeight: 'bold' }}><img src={recurringPayStartIcon} alt="" aria-hidden="true" /><span style={{ fontWeight: 'bold', marginLeft: '5px', textTransform: 'capitalize' }}>{overviewSummAem.auth_recurring_payment_title}</span> <span style={{ color: 'green', fontWeight: 'bold', marginRight:'10px' }}>{overviewSummAem.auth_on_text}</span> {setupEditLink}

            {this.isSSASuppressed() ?null: <div className="reqSSABtn">{this.cmsPaymentMethodState()}</div>} 
                </Typography.H3>
        }
        
    }

    checkM3P = async () => {
            const response = await getM3PService(this.props.plan.accountId);
            if (response?.optInStatus) {
                this.setState({ m3pBannerShow: true})
                sessionStorage.setItem('m3pBannerShow', true);
            }
    }

    closeM3PBanner = () => {
        this.setState({ m3pBannerShow: false})
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.customerAccountSummary !== this.props.customerAccountSummary) {
            this.isbCurrentPaymentMethod();
            this.shipFuturePaymentMethod();
        }
    }
      
    componentDidMount() {
        let paymentMethod = this.state.paymentMethod    
        trackImpression('current payment method:'+ (paymentMethod.indexOf('Coupon') > -1 ? 'coupon book' :
        paymentMethod.indexOf('EFT') > -1 ? this.props.plan.productType === 'SHIP' ? 'eft-checking' : paymentMethod.indexOf('CHECKING') > -1 ? 'eft-checking' : 'eft-savings'
        : paymentMethod.indexOf('Credit') > -1 ? 'credit/debit card':paymentMethod.indexOf('Direct') > -1 ? 'direct billing': paymentMethod.indexOf('CMS-SSA/RRB') > -1 ? 'cms-ssa/rrb':''));
        trackImpression('payment option:'+(this.isCmsRequested() ? 'ssa/rrb requested': (this.props.recurringFuture || (paymentMethod.indexOf('CMS-SSA/RRB') > -1 && this.props.futureFedPayMethod)) ? 'recurring payments scheduled':
        (paymentMethod.indexOf('CMS-SSA/RRB') > -1 && !this.props.futureFedPayMethod) ? 'SSA/RRB on':'Recurring on'))
        secureLocalStorage.removeItem('cmsNextAvailableStartDate');
        secureLocalStorage.removeItem('IsFromquicklinks');
        let monthlyID = document.getElementById('monthlyPremium_' + this.props.planNum);
        let monthlybtnID = monthlyID.children[2].children[0].children[0].id
        let monthlytipID = document.getElementById(monthlybtnID)
            if(monthlytipID){
                monthlytipID.setAttribute('aria-label', 'Monthly Premium Popup Tip');
            }
        let paymentAmtID = document.getElementById('"paymentAmt_' + this.props.planNum);
        let paymentbtnID = paymentAmtID.children[0].children[1].children[0].children[0].id
        let paymenttipID = document.getElementById(paymentbtnID)
        if(this.props.plan.productType === 'SHIP'){
            if(paymenttipID){
                paymenttipID.setAttribute('aria-label', 'Payment Due Popup Tip');
            }
        }
        let upcomingID = document.getElementById('upcomingPayment_' + this.props.planNum);
        let upcomingbtnID = upcomingID.children[0].children[1].children[0].children[0].id
        let upcomingtipID = document.getElementById(upcomingbtnID)
            if(upcomingtipID){
                upcomingtipID.setAttribute('aria-label', 'Upcoming Payment Popup Tip');
            }

        this.editPaymentBtnClickSubscription = editPaymentBtnClickSubject.subscribe(data=> {
            if(data === 'editPayment') {
                this.goToMakePayment(true)
            }
        })  
        if(this.props.plan.productType === 'SHIP' && this.props.shipPlanDetails && this.props.shipPlanDetails.length > 0) {
            this.isbCurrentPaymentMethod();
            this.shipFuturePaymentMethod();
        }
        if (localStorage.getItem('isM3P') === 'true') {
            if (this.props.plan.productType === 'GOVT' && this.props.isPreEffective === false && 
                (this.props.plan.planCategory === 'mapd' || this.props.plan.planCategory === 'pdp')) {
                if (sessionStorage.getItem('m3pBannerShow') && sessionStorage.getItem('m3pBannerShow') === 'true') {
                    this.setState({ m3pBannerShow: true})
                } else {
                    this.checkM3P();
                }
        }
    }

        if (window.location && window.location.href) {
            let startTime = new Date();
            if (window.location.href.indexOf("post-mfa") > -1 && this.props.plan.productType === 'GOVT' && !this.state.hasMFADecryptChecked) {
              let queryParams = window.location.href.split('?')[1];
              let postMfaState = queryParams.split('=')[1];
              if(postMfaState === 'true'){
                mfaLoggerInfo('MFA callback hit ',
                {"function":"MFA Callback Hit",
                "status": "success",
                "paymentTypeUrl":secureLocalStorage.getItem("paymentTypeUrl"),
                "mfaCallBackURL": secureLocalStorage.getItem("mfaCallBackURL")});
                this.props.isMfaRedirecting(true, this.checkPostMfa())
                getDecryptAATokenService().then((response) => {
                    this.setState({hasMFADecryptChecked: true});
                if(response.data.userid === this.state.userName ){
                    if(response.data.authStatus==="AUTHENTICATED" && response.data.aaUserStatus==="VERIFIED") {
                        mfaLoggerInfo("MFA decryption service is success ",
                            {"function":"MFA Callback Authenticated",
                            "service":"HSID Encryption AA Token API ",
                            "status": "success",
                            "authStatus": response.data.authStatus,
                            "paymentTypeUrl":secureLocalStorage.getItem("paymentTypeUrl"),
                            "mfaCallBackURL": secureLocalStorage.getItem("mfaCallBackURL")
                            });
                        this.setState({ isMFAAuthenticated: true})     
                        this.goToManageWallet();
                    } else{
                        mfaLoggerInfo("MFA encryption service AA token validation is failed ",{
                            "service": "HSID decrypt AA Token Validation",
                            "function":"MFA Callback unAuthenticated",
                            "status": "failed",
                            "paymentTypeUrl":secureLocalStorage.getItem("paymentTypeUrl"),
                            "mfaCallBackURL": secureLocalStorage.getItem("mfaCallBackURL")
                            });
                        trackModalWindowClick(secureLocalStorage.getItem('currPage') , 'invalid entry');
                        this.setState({ isMFAInvalid: true })     
                    }
                }else{
                    mfaLoggerInfo("MFA decryption AA token service AA token user validation is failed ",{
                        "service": "HSID decrypt AA Token user Validation",
                        "function":"MFA Callback User uuid mismatch",
                        "status": "failed",
                        "paymentTypeUrl":secureLocalStorage.getItem("paymentTypeUrl"),
                        "mfaCallBackURL": secureLocalStorage.getItem("mfaCallBackURL")
                        });
                    return window.location.assign(window.location.origin +'/medicare/content/medicare/member/logout.html');
                }
                }).catch(error => {
                    mfaLoggerInfo("MFA decryption AA token service is failed ",{
                        "function":"MFA Callback Hit System Issue",
                        "service": "HSID Decypt AA Token API",
                        "error": error ? (error.message ? error.message :" "):"",
                        "status": "failed",
                        "paymentTypeUrl":secureLocalStorage.getItem("paymentTypeUrl"),
                        "mfaCallBackURL": secureLocalStorage.getItem("mfaCallBackURL")
                        });
                    if(error?.response?.status && error?.response?.status === 403){
                        return  window.location.assign(window.location.origin +'/medicare/content/medicare/member/logout.html');
                    }else {   
                        this.props.isMfaRedirecting(false, this.checkPostMfa());
                        this.props.displayErrorMessage();
                    window.scrollTo(0, 0);
                    }
                });
            }
      
          }
        }
     }

     isbPastDueAmount() {
        if (parseFloat(this.props.customerAccountSummary?.currentBillingStatus?.pastDueAmount) > 0 && this.props.customerAccountSummary?.currentBillingStatus?.pastDueAmount != null) {
            return convertToCurrency(this.props.customerAccountSummary?.currentBillingStatus?.pastDueAmount);
        } else {
            return '0.00'
        }
     }
     
     pastDueAmount() {
        if (this.props.planData?.isPreEffective) {
            if (this.props.planData?.isFirstBillGen && this.props?.readBillingAndTransDtl?.totalAmountDue === 0) {
                return '0.00'
            } else if (this.props.planData?.isFirstBillGen && this.props?.readBillingAndTransDtl?.totalAmountDue > 0) {
                return convertToCurrency(this.props?.readBillingAndTransDtl?.totalAmountDue) 
            } else {
                return '0.00'
            }
        } else {
            return convertToCurrency(this.props?.readBillingAndTransDtl?.totalAmountDue)
        }
     }
     
     componentWillUnmount() {
        this.editPaymentBtnClickSubscription.unsubscribe()
     }
    
    
    noPaymentCmsSSAModal = <PaymentModals
                        modalContent={this.noPaymentCmsSSA}
                        modalType='noPaymentCmsSSA'
                        makepaybtn={overviewSummAem.auth_make_payment_btn}
                        goToSetupRec={this.goToMakePayment}
                         />  
                         
    shipRecurringModal = <PaymentModals
                        modalContent={this.shipRecurringModalContent}
                        modalType='shipDiscount'
                        managepaybtn={overviewSummAem.auth_setup_recurring_btn} 
                        linkName = {overviewSummAem.auth_view_details_pay_banner_link}
                        goToSetupRec={this.goToMakePayment}
                        />                               
     
     checkPostMfa = () => {
        if (window.location.href.indexOf("post-mfa") > -1) {
          let queryParams = window.location.href.split('?')[1];
          console.log(queryParams)
          let postMfaState = queryParams.split('=')[1];
          if (postMfaState === 'true') {
            return true;
          }
        }
        return false;
      }

    render() {
        return (
            <>
            {(this.state.isMFA && this.state.isMFAInvalid && this.checkPostMfa()) && <MfaLogOutModal isMFAInvalid={this.state.isMFAInvalid} />}
            {this.state.isMFA && (this.state.mfaValidation && this.props.plan.productType === 'GOVT' ) && <HsidMfaModal mfaCallBackURL={this.state.mfaCallBackURL} modalContent={this.mfaContent}
            displayErrorMessage={this.props.displayErrorMessage}/>}
            {(this.state.isMFA && this.checkPostMfa() && this.state.loading) && <Spinner />}
                <div>
                    <PlanHeader 
                        {...this.props}
                        hideISBBanner={this.props.hideISBBanner}  
                        closeISBBanner={this.props.closeISBBanner} 
                        billingPayments={this.props.billingPayments} 
                        isbBillingPayments={this.props.isbBillingPayments} 
                        isbPremiumDetailsError={this.props.isbPremiumDetailsError} 
                        isbBillingError={this.props.isbBillingError}
                        closeM3PBanner={this.closeM3PBanner}
                        m3pBannerShow={this.state.m3pBannerShow}    
                    />
                </div>
                <div className="planDetails">
                    <div className={this.props.plan.productType === 'GOVT' ? 'paymentgovt' : 'payment'}>
                    
                        <Typography.H3>{overviewSummAem.auth_payment_due_title}</Typography.H3>
                        <ul className={this.props.planData?.isFirstBillGen && this.props?.readBillingAndTransDtl?.totalAmountDue > 0 ? 'paymentInfo pdue mt-4' : this.props.planData?.isPreEffective && !this.props.planData?.isFirstBillGen  ? 'paymentInfo pdue mt-4 margBtm' : 'paymentInfo pdue mt-4'}>
                            <Fragment>
                                <li className="paymentAmount" id={'"paymentAmt_' + this.props.planNum}>
                                    {this.props.plan.productType === 'SHIP' ? (
                                        <div className="tds-typography__paragraph uhc-question-icon">
                                            <span>{overviewSummAem.auth_amt_label}</span>
                                            <PopupTip
                                                icon="QuestionMark"
                                                iconColor="primary"
                                                placement="bottom"
                                                className="ml-0"
                                                contentId={'paymentdue_' + this.props.planNum}
                                                title={overviewSummAem.auth_paymentdue_tooltip_title}
                                            >
                                                <Markup content={overviewSummAem.auth_paydue_tooltip_content_ship_isb}/>
                                            </PopupTip>

                                        </div>
                                    ) :
                                        <PaymentModals
                                            modalContent={this.paymentDueModal}
                                            modalType='paymentDueAmt'
                                            paymentdueAmtLink={overviewSummAem.auth_amt_label}
                                        />
                                    }
                                    <p className="tds-header__h3">${
                                        this.props.plan.productType === 'SHIP' ? 
                                        this.isbPastDueAmount()
                                        :
                                        this.pastDueAmount()
                                    }</p>
                                </li>
                                {this.props.plan.productType === 'SHIP' ? this.isbPastDueDate() : this.pastDueDate()}
                                {!this.props.planData?.isPreEffective || (this.props.planData?.isFirstBillGen && this.props.planData?.isPreEffective) ?
                                <li className="makepaymentBtn btnWrap">
                                    {this.getMakeOneTimeView()}
                                </li>
                                : null}
                            </Fragment>
                        </ul>
                        
                    </div>
                    <div className={this.props.plan.productType === 'SHIP' && (!this.props.planData?.isPreEffective) ? 'paymentMethodRecurring' : 'paymentMethod'}>
                        <div className="paymentMethodGrid">
                            {this.recurringFlagUI()}
                            <ul className={this.recurringFlagUI() && ((!this.props.planData?.isPreEffective) || (this.props.planData?.isFirstBillGen)) ? 'paymentInfo mt-4 recWrap' : 'paymentInfo isPreEff'}>
                                {isDesktop() ?
                                    <Fragment>
                                        <li className="paymentMethodHeader itemOrder" style={this.state.paymentMethod.indexOf('CMS-SSA/RRB') > -1 ? {width : "40%"} : {}}>
                                            <Typography.Paragraph>{overviewSummAem.auth_current_payment_method}</Typography.Paragraph>
                                            <p className="paymentDueText">{this.props.plan.productType === 'SHIP' ? this.state.isbCurrentPaymentMethodLabel : this.currentPaymentMethod()}
                                            {this.state.paymentMethod.indexOf('CMS-SSA/RRB') > -1 ?
                                            <div className="uhc-question-icon  iscms">
                                                <PopupTip
                                                    icon="QuestionMark"
                                                    iconColor="primary"
                                                    placement="bottom"
                                                    contentId={'cmsPaymentMethod_' + this.props.planNum}
                                                    title={overviewSummAem.auth_cms_tooltip_title}>
                                                    <>
                                                     <Markup content={overviewSummAem.auth_cms_tooltip_content} />
                                                    </>      
                                                </PopupTip>
                                            </div> : null}
                                            </p>
                                        </li>
                                        <li className="curPayVerticalLine"></li>
                                    </Fragment>
                                    : null}
                                <li className="upcomingPayment" id={'upcomingPayment_' + this.props.planNum}>
                                    <div className="uhc-question-icon">
                                        <span>{overviewSummAem.auth_upcoming_payment}</span>
                                        <PopupTip
                                            icon="QuestionMark"
                                            iconColor="primary"
                                            placement="bottom"
                                            contentId={'upcoming_' + this.props.planNum}
                                            title={overviewSummAem.auth_upcoming_tooltip_title}
                                        >
                                                {this.props.plan.productType === 'SHIP' ? (
                                                    <>
                                                        <Markup content={overviewSummAem.auth_upcoming_tooltip_content_ship_isb} />
                                                    </>
                                                ) : <>
                                                        <Markup content={overviewSummAem.auth_upcoming_tooltip_content_fed} />
                                                    </>
                                                }
                                        </PopupTip>
                                    </div>
                                    <p className="tds-header__h3">
                                        {this.props.plan.productType === 'SHIP' ? this.isbUpcomingDue() : this.upcomingDue()}
                                    </p>
                                </li>
                                {this.props.plan.productType === 'SHIP' ? this.isbUpcomingDueDate() : this.upcomingDueDate()}
                                {!isDesktop() ?
                                    <li className="paymentMethodHeader itemOrder">
                                        <Typography.Paragraph>{overviewSummAem.auth_current_payment_method}</Typography.Paragraph>
                                        <Typography.Paragraph className="paymentDueText">{this.props.plan.productType === 'SHIP' ? this.state.isbCurrentPaymentMethodLabel : this.currentPaymentMethod()}
                                        {this.state.paymentMethod.indexOf('CMS-SSA/RRB') > -1 ?
                                            <div className="uhc-question-icon">
                                                <PopupTip
                                                    icon="QuestionMark"
                                                    iconColor="primary"
                                                    placement="bottom"
                                                    contentId={'cmsPaymentMethod_' + this.props.planNum}
                                                    title={overviewSummAem.auth_cms_tooltip_title}>
                                                    <>
                                                     <Markup content={overviewSummAem.auth_cms_tooltip_content} />
                                                    </>      
                                                </PopupTip>
                                            </div> : null}
                                            </Typography.Paragraph>
                                    </li>
                                    : null}
                                <li className="recurringButton recBtnWrap">
                                {((this.props.plan.productType === 'SHIP' && this.props.fedShipPlanCount > 1) && <span className="wallet_ship_txt">{overviewSummAem.auth_fed_wallet_supp_plans}</span>)}
                                {this.props.plan.productType === 'GOVT' && 
                                    <>
                                    <div className="ctaManageWalet" id="manageWalletBtn"><Button id={'mmw_' + this.props.planNum} aria-describedby="manage_wallet_note_id" buttonType="secondary-one" onClick={() => this.goToWallet()}>{overviewSummAem.auth_manage_my_wallet_btn}</Button></div>
                                    </>
                                }
                                </li>
                                
                            </ul>
                            
                            
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default PlanSummary
