import React from 'react';

const DownloadIcon = () => {
	return (
		<svg 
			width={24} 
			height={24} 
			aria-hidden="true"
			focusable="false"
			>
                                   
         <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g fill="#196ECF">
                     <path d="M15,9 L15,3 L9,3 L9,9 L5,9 L12,16 L19,9 L15,9 Z M5,20 L19,20 L19,18 L5,18 L5,20 Z"></path>
                </g>
          </g>
        </svg>
		
	);
};

export default DownloadIcon;
