import axios from 'axios';
import { logger } from '../logger';
import commonConstants from '../../server/util/commonConstants';
import appConfigs from '../app.config';

const ccPayments = axios.create({
	baseURL: appConfigs.api_base_url
});


export const saleTransactionCCPostPaymentResults = async (ccRequest) => {
    let saleTransactionRequest = {
        "requestType": commonConstants.SALE_TRANSACTION_REQUEST_TYPE,
        "accountId": appConfigs.isProdEnvi ? commonConstants.UPG_SHIP_PROD_ACCOUNTID : commonConstants.UPG_SHIP_NON_PROD_ACCOUNTID,
        "accountType": commonConstants.SALE_TRANSACTION_ACCOUNT_TYPE,
        "token":ccRequest.token,
        "amount": ccRequest.amount,
        "transactionIndustryType":commonConstants.SALE_TRANSACTION_INDUSTRY_TYPE,
        "transactionOriginCode":commonConstants.SALE_TRANSACTION_ORIGIN_CODE,
        "customerAccountCode": ccRequest.customerAccountCode,
        "customerAccountInternalCode":ccRequest.customerAccountInternalCode,
        "sourcePortal": commonConstants.INPUT_SOURCE_SYSTEM,
        "individualId":ccRequest.individualId
    };
	return ccPayments.post(appConfigs.sale_transaction_service_path, saleTransactionRequest).then(response =>{
            return response.data;
    }).catch((error) => {
        return {
            success: false
        };
    });
};