import React, { Component, Fragment } from 'react';
import { Button, Form, FormGroup, Typography} from '@uhc-tempo/components'
import { Typography as TempoTypo, Form as CCForm} from '@uhc/pattern-library'
import moment from 'moment';
import Input from '../../UI/Input/Input'
import {updateCreditCardInWallet, statusFromUpdateWalletCall} from '../../../server/api/addUpdateWalletService-api'
import {items,updateWalletData} from '../Wallet/ManageWallet'
import Spinner from '../../UI/Spinner/Spinner'
import { manageWalletFormAem } from '../Wallet/ManageWallet'
import cloneDeep from 'lodash/cloneDeep';
import { trackFormSubmit, trackError} from '../../../server/util/analytics'

class EditCreditCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nameOnCard: this.props.item.creditCard.nameOnCard,
      nickName: this.props.item.nickname,
      cardNumber : this.props.item.creditCard.cardNumberLastFour,
      expMonth: '',
      expYear: 0,
      nameOnCardError : false,
      nickNameError : false,
      editCardErrMsg: '',
      expirationInPastError : false,
      expValidationWithCardState : 'default',
      loading : false,
      formValidated: true,
    }
  }

  resetState = ()=> {
    this.setState({
      nameOnCard: this.props.item.creditCard.nameOnCard,
      nickName: this.props.item.nickname,
      cardNumber : this.props.item.creditCard.cardNumberLastFour,
      expMonth: '',
      expYear: 0,
      nameOnCardError : false,
      nickNameError : false,
      expirationInPastError : false,
      expValidationWithCardState : 'default',
      loading : false
    })
  }
  mapMonth() {
    let months = [];
    for(let i=1; i <= 12; i++) {
      months.push(<Form.Select.Option key={i} value={i.toString()}>{i.toString().length < 2 ? '0' + i : i}</Form.Select.Option>)
    }
    return months;
  }

  mapYears() {
    let years = [];
    let dateStart = moment();
    let dateEnd = moment().add(10, 'y');
    while (dateEnd.diff(dateStart, 'years') >= 0) {
      years.push(<Form.Select.Option key={dateStart.format('YYYY')} value={dateStart.format('YYYY')}>{dateStart.format('YYYY')}</Form.Select.Option>)
      dateStart.add(1, 'year')
    }
    return years;
  }

  getCCExp() {
    let fullYear = moment().format('YYYY')
    let expMonth = this.props.item?.creditCard?.expiration?.substring(0,1) === "0" ? this.props.item.creditCard.expiration.substring(1,2) : this.props.item.creditCard.expiration.substring(0,2);
    let expYear = fullYear.substring(0,2) + this.props.item.creditCard.expiration.substring(2,4);
    this.setState({ expMonth: expMonth, expYear: expYear})
  }

  nameOnCardChangedHandler = (event) => {
    const re =  /^[A-Za-z]+[0-9A-Za-z.,'\s-]*$/
      if (event.target.value === '' || re.test(event.target.value)){
      this.setState({
        nameOnCard: event.target.value
      })
    }
  }

  nameOnCardOnBlur = (event) => {
    const re = /^[A-Za-z]+[0-9A-Za-z.,'\s-]*$/
    let error = true
    let value = event.target.value
    let priorErrState = this.state.nameOnCardError
    if (value === '') {
        document.getElementById('nameOnCard').setAttribute('aria-describedby', 'nameOnCardError') 
    } else if (re.test(value)) {
        error = false
    } 
      this.setState({
        nameOnCard: value,
        nameOnCardError: error,
      }, ()=> {
        if(priorErrState && !error) {
          this.setErrorMessage()
        }
      })
  }

  nickNameChangedHandler = (event) => {
    const re = /[a-zA-Z0-9\s.,'-]+$/
      if (event.target.value === '' || re.test(event.target.value)){
      this.setState({
        nickName: event.target.value
      })
    }
  }

  nickNameOnBlur = (event) => {
    const re = /[a-zA-Z0-9\s.,'-]+$/
    let error = true
    let value = event.target.value
    let priorErrState = this.state.nickNameError
    if (value === '') {
        error = false
        document.getElementById('nickName').setAttribute('aria-describedby', 'nickNameError') 
    } else if (re.test(value)) {
        error = false
    } 
      this.setState({
        nickName: value,
        nickNameError: error,
      }, ()=> {
        if(priorErrState && !error) {
          this.setErrorMessage()
        }
      })
  }

  monthChangedHandler = (event) => {
    let value = event.target.value
    let error = false
    let selectState = 'default'
    let priorMonth = this.props.priorItem.creditCard.expiration.substring(0,2)
    if(this.isExpirationDateInPast(value,this.state.expYear)) {
      error = true
      selectState = 'error'
    }

    this.setState({expMonth : value, expirationInPastError : error, expValidationWithCardState : selectState})
  }

  yearChangedHandler = (event) => {
    let value = event.target.value
    let error = false
    let selectState = 'default'
    if(this.isExpirationDateInPast(this.state.expMonth, value)) {
      error = true
      selectState = 'error'
    }
    this.setState({expYear : value, expirationInPastError : error, expValidationWithCardState: selectState})

  }

  isExpirationDateInPast = (month,year)=> {
    if(Number.parseInt(year) === moment().year() && (Number.parseInt(month) <= (moment().month()+1))) {
      return true
    }
    return false
  }

  setErrorMessage = ()=> {
    let errMsg = 'Please see error(s) below.'
    this.setState({editCardErrMsg : errMsg}, () => document.getElementById('ccErrMsg').focus())
  }

  updateCreditCard = ()=> {
    if (this.props.superUser) {
      this.props.showSuperUserError(this.props.index, null);
    } else if(this.state.nameOnCardError || this.state.nickNameError || this.state.expirationInPastError) {
      let analyticErrorMsg='';
      let invalidTxt=''
      if(this.state.nameOnCardError){
        analyticErrorMsg = 'Name on Card';
        invalidTxt='invalid';
      }else{
        analyticErrorMsg = analyticErrorMsg; invalidTxt=invalidTxt;
      }
      if(this.state.nickNameError){
        if(analyticErrorMsg !==''){
          analyticErrorMsg = analyticErrorMsg+'|NickName';
          invalidTxt !='' ? invalidTxt = invalidTxt+'|invalid': invalidTxt = invalidTxt;
        }else{
          analyticErrorMsg = analyticErrorMsg+'NickName'; invalidTxt=invalidTxt;
        }
      }
      if(this.state.expirationInPastError){
        if(analyticErrorMsg !==''){
          analyticErrorMsg = analyticErrorMsg+'|Expiration Date';
          invalidTxt !='' ? invalidTxt = invalidTxt+'|invalid': invalidTxt = invalidTxt;
        }else{
          analyticErrorMsg = analyticErrorMsg+'Expiration Date'; invalidTxt=invalidTxt;
        }

      }
      trackError('manage my wallet', analyticErrorMsg, 'form validation error', invalidTxt);
      console.log(invalidTxt)
      this.setErrorMessage()
      analyticErrorMsg=''
      invalidTxt=''
      if(this.state.nameOnCardError){
        analyticErrorMsg = 'Name on Card';
        invalidTxt='invalid';
      }else{
        analyticErrorMsg = analyticErrorMsg; invalidTxt=invalidTxt;
      }
      if(this.state.nickNameError){
        if(analyticErrorMsg !==''){
          analyticErrorMsg = analyticErrorMsg+'|NickName';
          invalidTxt !='' ? invalidTxt = invalidTxt+'|invalid': invalidTxt = invalidTxt;
        }else{
          analyticErrorMsg = analyticErrorMsg+'NickName'; invalidTxt=invalidTxt;
        }
      }
      if(this.state.expirationInPastError){
        if(analyticErrorMsg !==''){
          analyticErrorMsg = analyticErrorMsg+'|Expiration Date';
          invalidTxt !='' ? invalidTxt = invalidTxt+'|invalid': invalidTxt = invalidTxt;
        }else{
          analyticErrorMsg = analyticErrorMsg+'Expiration Date'; invalidTxt=invalidTxt;
        }

      }
      trackError('manage my wallet', analyticErrorMsg, 'form validation error', invalidTxt);
      console.log(invalidTxt)
    } else {
      let expMonth = this.state.expMonth < 10 ? '0'+this.state.expMonth : ''+this.state.expMonth
      let expYear = this.state.expYear.substring(2,4)
      let cardItem = this.props.item
      
      if(this.props.item.creditCard.nameOnCard != this.state.nameOnCard || this.props.item.creditCard.expiration != (expMonth+expYear)
        || this.props.item.nickname != this.state.nickName) {
          
          cardItem.creditCard.nameOnCard = this.state.nameOnCard
          cardItem.creditCard.expiration = expMonth+expYear
          cardItem.nickname = this.state.nickName
          this.setState({loading : true})

          updateCreditCardInWallet(cloneDeep(items),cardItem,cloneDeep(updateWalletData)).then(res=> {
            console.log(res)
            if(res.success) {
                let ccCardCnt=this.props.recCCItemId === cardItem.itemId ? 'manage my wallet - credit card/debit card - edit existing payment method|recurring payments ON' : 
                        this.props.scheduledCCItemId === cardItem.itemId ? 'manage my wallet - credit card/debit card - edit existing payment method|recurring payemnts scheduled' :'manage my wallet - credit card/debit card - edit existing payment method'
                        trackFormSubmit(' manage my wallet',ccCardCnt)
                sessionStorage.setItem('notificationCount', 0)
                sessionStorage.setItem('newSession', false);
                sessionStorage.setItem('updateRecurring', false)
                this.props.getWalletDetails();
                this.props.onCCCancelClick(this.props.index);

            } else {
              let errors = res.errors?.map(elt=> elt.detail)
              console.log(errors)
              this.setState({loading : false})
              this.props.resetAllFormsAndErrors()
              if(res.statusCode >= 400 || statusFromUpdateWalletCall >= 400) { 
                this.props.editCCOnSubmitBtnClick(
                  {fields : [{error : true , label : `<b>${manageWalletFormAem.auth_mw_system_err_title}</b> ${manageWalletFormAem.auth_mw_system_err_txt}`}],
                  count : 1,
                  type : 'api'
                  }
                )
              }
              window.scrollTo(0, 0)
              document.getElementById('alertbody_desktop').focus()
            }
            }).catch(error=> {
              this.setState({loading : false})
              console.log(error)
            })
      } else {
        this.props.onCCCancelClick(this.props.index)
      }
    }
  }

  handleCCCancelBtnKey = (event) => {
    if(this.props.isEditOpen){
        if (!event.shiftKey && event.key === "Tab") {
            event.preventDefault();
            document.getElementById('ccEditLink_'+this.props.index).focus();
        }
    }
}

  componentDidUpdate(prevState, prevProps) {
    if (this.state.expirationInPastError === false && this.state.nameOnCardError === false && prevProps.nickNameError === false && this.state.formValidated && this.state.editCardErrMsg !== '') {
      this.setState({ editCardErrMsg: '' })
    }
  }
  componentDidMount() {
    this.getCCExp();
  }

  render() {
    return (
      <Fragment>
        {this.state.loading ? <Spinner /> : null}
        <div className={(this.state.loading ? ' text-blur' : 'editCCWallet')} id={'edit_cc_details_'+this.props.index} role="region" aria-labelledby={'edit_cc_title_'+this.props.index}>
          <Typography.H6 id={'edit_cc_title_'+this.props.index} style={{color: 'rgb(51, 51, 51)', fontSize: '16px'}}>{manageWalletFormAem.auth_mw_edit_card_txt}</Typography.H6>
          <fieldset style={{padding: '0px'}}>
          <legend className="sr-only">{manageWalletFormAem.auth_cc_dt_inst_txt}</legend>
          
        <div className={"editWalet"} id={this.props.id}>
          <div className="col-md-12 align-items-center formBorder">
            <FormGroup>
              {this.state.editCardErrMsg && <div className="ccErrMsg" id="ccErrMsg" tabIndex="0">
                {this.state.editCardErrMsg}
              </div>}
              <div className="tds-form__label creditcard_desc">
                <p>{manageWalletFormAem.auth_cc_dt_inst_txt}</p>
                {/* <div className="fl_R">Indicates required fields*</div> */}
              </div>
              
              <CCForm.Row className="mbt-med">
                <div>
                <label htmlFor="nameOnCard" className="eft-label-desc m-0 color-black">
                    {manageWalletFormAem.auth_mw_name_card_lbl}
                </label>
                <Input
                  type="text"
                  id="nameOnCard"
                  label={manageWalletFormAem.auth_mw_name_card_lbl}
                  value={this.state.nameOnCard}
                  changeHandler={this.nameOnCardChangedHandler}
                  blurHandler={this.nameOnCardOnBlur}
                  error={this.state.nameOnCardError}
                  maxlength='60'
                  required
                  fieldError="nameOnCardError"
                  invalid
                />
                </div>
            </CCForm.Row>

            <CCForm.Row className="mbt-med">
                <div>
                <label htmlFor="cardNumber" className="eft-label-desc m-0 color-black">
                    {manageWalletFormAem.auth_mw_card_num_lbl}
                </label>
                <Input
                  type="text"
                  id="cardNumber"
                  blurHandler={() => {}}
                  label={manageWalletFormAem.auth_mw_card_num_lbl}
                  value={'***' + this.props.item.creditCard.cardNumberLastFour}
                  maxlength='16'
                  /* disabled = {true} */
                  readonly = {true}
                  required
                />
                </div>
            </CCForm.Row>
              <div style={{display: 'flex', marginTop: '1.5rem'}}>
                <Form.Group className="editExpDateGroup" state={this.state.expValidationWithCardState}>
                  <Form.Label className="eft-label-desc editExpItem m-0 color-black">{manageWalletFormAem.auth_mw_exp_dt_lbl}<span className="sr-only">Select Month</span></Form.Label>
                    <Form.Select className="tds-form__input editExpItem" id="expMonth" required aria-invalid="false" type="text" value={this.state.expMonth} selected onChange={this.monthChangedHandler}>
                      {this.props.isEditOpen && this.mapMonth()}
                    </Form.Select>
                  <Form.ErrorMessage state= {this.state.expirationInPastError}>{manageWalletFormAem.auth_mw_invalid_exp_dt_err_msg}</Form.ErrorMessage>
                </Form.Group>
                <Form.Group className="editExpDateGroup" state={this.state.expValidationWithCardState}>
                <Form.Label className="sr-only expSelYear">Select Year</Form.Label>
                  <Form.Select className="tds-form__input editExpItem" id="expYear" required type="text" aria-invalid="false" value={this.state.expYear} selected onChange={this.yearChangedHandler}>
                    {this.props.isEditOpen && this.mapYears()}
                  </Form.Select>
                </Form.Group>
              </div>
              <CCForm.Row className="mbt-med">
                <div>
                <label htmlFor="nickName" className="eft-label-desc m-0 color-black">
                    {manageWalletFormAem.auth_mw_edit_nickname_lbl}
                </label>
                <Input
                  type="text"
                  id="nickName"
                  label={manageWalletFormAem.auth_mw_edit_nickname_lbl}
                  value={this.state.nickName}
                  changeHandler={this.nickNameChangedHandler}
                  blurHandler={this.nickNameOnBlur}
                  maxlength='25'
                  error={this.state.nickNameError}
                  fieldError="nickNameError"
                  invalid
                />
                </div>
            </CCForm.Row>
            </FormGroup>
            <div style={{padding: '3px', marginBottom:'25px'}}>
            <div id="reviewsubmit" className="col-12 tds-card manage-buttons">
            <Button
                text={manageWalletFormAem.auth_save_pay_method_btn_txt}
                buttonType="primary-one"
                className="tds-button tds-button--primary-one"
                onClick = {this.updateCreditCard}
              />
              <Button
                text={manageWalletFormAem.auth_mw_cancel_btn_txt}
                buttonType="secondary-one"
                id="cc_cancelBtn"
                onKeyDown={this.handleCCCancelBtnKey}
                className="tds-button tds-button--primary-one ml-3"
                onClick={() => {this.props.onCCCancelClick(this.props.index);this.props.onCCCancelTrackLinkEvent()}}
              />
              </div>  
            </div>
            </div>
          </div>
          </fieldset>
        </div>
        
        </Fragment>
   )
  }

}

export default EditCreditCard