import React, { Component, Fragment } from 'react';
import { Button} from '@uhc-tempo/components'
import { Breadcrumbs, Typography, AccordionGroup, Accordion, Notification} from '@uhc-tempo/components';
import BankAccountList from './BankAccountList';
import CreditCardList from './CreditCardList';
import { getWalletServiceAPI } from '../../../server/api/getWalletService-api'
import EftForm from '../payment-details/EftForm';
import PageError from '../payment-details/PageError';
import '../payment-details/PaymentForm.css';
import Spinner from '../../UI/Spinner/Spinner';
import { getPlanInformation, getOverviewPageLink, getProductType} from '../../../server/util/localStorageRepo';
import CreditCardGPS from '../CreditCard/CreditCardGPS';
import FedRadioBtn from '../payment-details/FedRadioBtn';
import { Markup } from 'interweave'
import { withRouter } from 'react-router-dom'
import axios from '../../../server/api/aem-api';
import appConfigs from '../../../server/app.config';
import config from '../../../server/config';
import PaymentModals from '../modals/PaymentModals';
import { trackLinkClick, trackPageLoad, trackError,trackImpression } from '../../../server/util/analytics'
import moment from 'moment';
import { statusFromUpdateWalletCall } from '../../../server/api/addUpdateWalletService-api';
import secureLocalStorage from 'react-secure-storage';
export let featureFlagsAem = null;
export let manageWalletAem = null;
export let manageWalletFormAem = null;
export let getWalletResponse = null;
export let items = [];
export let updateWalletData = null;
class ManageWallet extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeWalletItems : [],
      activeBankItems : [],
      activeCCItems : [],
      toggleAccordion: false,
      walletAccordionId: null,
      prevEditForm: null,
      loading : true,
      walletItemAdded: '',
      walletAction: '',
      bankEditOpenUiIndex : [],
      ccEditOpenUiIndex: [],
      isAddEftFormOpen : false,
      paymentMethod : 'checking',
      recBankItemId : '',
      recCCItemId : '',
      scheduledBankItemId: '',
      scheduledCCItemId: '',
      ccAddedToken: '',
      isMFA: this.props.history.location.state?.isMFA ? this.props.history.location.state?.isMFA : sessionStorage.getItem('isMFA') === 'true' ? true : false,
      isHSIDSSO: false,
      manageWalletAemRendered: false,
      manageWalletEftFormAemRendered: false,
      superUser: localStorage.getItem('superUser') === 'true' ? true : false,
      accountItemNum: null,
      isMFAAuthenticated: this.props.history.location.state?.isMFAAuthenticated ? this.props.history.location.state?.isMFAAuthenticated : (sessionStorage.getItem('walletCCFlow') && sessionStorage.getItem('isMFAAuthenticated')) ? true : false
    }
    this.eftRef = React.createRef()
    this.showSuperUserError = this.showSuperUserError.bind(this);
    this.editRecurringWalletItem  = this.editRecurringWalletItem.bind(this);
 }
//580039158700
checkSession() {

  if (!sessionStorage.getItem('walletItemAddedUpdated')) {
    sessionStorage.setItem('newSession', true);
  }
}

 componentDidMount() {
  if(!this.state.isMFAAuthenticated && this.state.isMFA){
   this.returnPaymentOverview();
  }

  if (this.state.isMFAAuthenticated && sessionStorage.getItem('walletCCFlow') && this.state.isMFA) {
    sessionStorage.removeItem('walletCCFlow');
    sessionStorage.removeItem('isMFAAuthenticated');
  }
  this.getWalletDetails();
  this.checkSession();
  this.callWalletAemAPISummary();
  this.callWalletFormAemAPISummary();
  setTimeout(()=>{
    if(secureLocalStorage.getItem('ccAddAPI')){
      secureLocalStorage.removeItem('ccAddAPI')
    }else{
      trackPageLoad('manage my wallet');
    }  
    trackImpression((this.state.activeBankItems.length > 0 ? 'EFT checking/savings - payments-method:saved' : 'EFT checking/savings - payments-method:none saved') +'|'+ (this.state.activeCCItems.length > 0 ? 'EFT credit/debit card - payments-method:saved' : 'EFT credit/debit card - payments-method:none saved'));
  },1000)
  secureLocalStorage.setItem('currPage', 'wallet')
  if (sessionStorage.getItem('newSession') === 'true') {
    sessionStorage.setItem('updateRecurring', false)
  }
   this.resetAllFormsAndErrors()   
 }

 // Content for Manage Wallet Items Section
 callWalletAemAPISummary() {
  try {
      axios.get(appConfigs.aem_base_url + config.REACT_APP_CONTENT_PATH + '/manage-wallet-content/manage-wallet-content/jcr:content/data/master.json' + '?timestamp=' + new Date().getTime())
          .then(response => {
              manageWalletAem = response.data
              if (manageWalletAem) {
                  this.setState({ manageWalletAemRendered: true })
              }
              console.log(manageWalletAem);
          })
  } catch (error) {
      // need to write error handing
  }
}

callWalletFormAemAPISummary() {
  try {
      axios.get(appConfigs.aem_base_url + config.REACT_APP_CONTENT_PATH + '/manage-wallet-eft-form-content/manage-wallet-eft-form-content/jcr:content/data/master.json?timestamp=' + new Date().getTime())
          .then(response => {
              manageWalletFormAem = response.data
              if (manageWalletFormAem) {
                  this.setState({ manageWalletEftFormAemRendered: true }, ()=> this.onLoadApiErrors())
              }
          })
  } catch (error) {
      // need to write error handing
  }
}

 getWalletDetails = async ()=> {
  const response = await getWalletServiceAPI(getPlanInformation().accountId);
  setTimeout(() => {
  getWalletResponse = response.data;
  console.log("Get-Wallet response : status " + getWalletResponse.statusCode)
  let currBankItemId = ''
  let currCCItemId = ''
  let futureBankItemId = '';
  let futureCCItemId = '';
  if(getWalletResponse.statusCode === 200) {
    items = getWalletResponse.gpGetWalletResponse.data.items; 
    getWalletResponse.gpGetWalletResponse.data.householdId="";
    updateWalletData=getWalletResponse.gpGetWalletResponse.data;
    currBankItemId = getWalletResponse.currentItem ? getWalletResponse.currentItem.bankAccount ? getWalletResponse.currentItem.itemId : '' : ''
    currCCItemId = getWalletResponse.currentItem ? getWalletResponse.currentItem.creditCard ? getWalletResponse.currentItem.itemId : '' : ''
    futureBankItemId = getWalletResponse.futureItem?.bankAccount ? getWalletResponse.futureItem.itemId : ''
    futureCCItemId = getWalletResponse.futureItem?.creditCard ? getWalletResponse.futureItem.itemId : ''
    secureLocalStorage.setItem('upgAccountId',getWalletResponse.gpGetWalletResponse.data.options.masterCustId)
  } else {
    if(getWalletResponse.statusCode == 500) {
      // 500 There was an issue retrieving the wallet for householdID
      secureLocalStorage.setItem('walletPageError','500')
      this.props.history.push('/service-error')
      trackError(' manage my wallet', 'manage my wallet', 'wallet service error', getWalletResponse.statusCode);                    
    } else if(getWalletResponse.statusCode == 404 && getWalletResponse.errors && getWalletResponse.errors.filter(elt=> elt.detail.indexOf('Wallet not found') > -1).length > 0) {
      // 404 Wallet not found for householdID
      secureLocalStorage.setItem('walletPageError','404')
      this.props.history.push('/service-error')
      trackError(' manage my wallet', 'manage my wallet', 'form validation error', getWalletResponse.statusCode); 
    }
  }
  if(items.length > 0) {
    this.setState({activeWalletItems : items.filter(el=> el.active.toLowerCase() === 'true'),
                  activeBankItems : items.filter(el=> el.creditCard == null && el.active.toLowerCase() === 'true'),
                  activeCCItems : items.filter(el=> el.bankAccount == null && el.active.toLowerCase() === 'true'),
                  allItems : items,
                  recBankItemId : currBankItemId, recCCItemId : currCCItemId, scheduledBankItemId: futureBankItemId, scheduledCCItemId: futureCCItemId},
                  () => {

                    this.state.activeWalletItems.sort((a,b) => moment(a.audit?.modifiedOn).isBefore(b.audit?.modifiedOn) ? 1 : -1)
                    this.state.activeBankItems.sort((a,b) => moment(a.audit?.modifiedOn).isBefore(b.audit?.modifiedOn) ? 1 : -1)
                    this.state.activeCCItems.sort((a,b) => moment(a.audit?.modifiedOn).isBefore(b.audit?.modifiedOn) ? 1 : -1)
                    this.state.allItems.sort((a,b) => moment(a.audit?.modifiedOn).isBefore(b.audit?.modifiedOn) ? 1 : -1)
                    if (sessionStorage.getItem('notificationCount') === '0') {
                    let ccAddedUpdated = this.state.activeCCItems?.filter(cc => (cc.active.toLowerCase() === "true" && (cc.creditCard?.creditCardToken === this.state.activeWalletItems?.[0].creditCard?.creditCardToken) || cc.creditCard?.creditCardId === this.state.activeWalletItems?.[0].creditCard?.creditCardId))
                    let bankAddedUpdated = this.state.activeBankItems?.filter(account => account.active.toLowerCase() === "true" && ((account.bankAccount?.routingNumber === this.state.activeWalletItems?.[0].bankAccount?.routingNumber && this.state.activeWalletItems?.[0].bankAccount?.accountNumber === account.bankAccount?.accountNumber) || account.bankAccount?.bankAccountId === this.state.activeWalletItems?.[0].bankAccount?.bankAccountId))
                    this.walletItemSession(this.state.allItems?.[0].active === 'FALSE' ? this.state.allItems : bankAddedUpdated.length > 0 ? bankAddedUpdated : ccAddedUpdated)

                    this.setState({
                      walletItemAdded: this.state.allItems?.[0].active === 'FALSE' ? this.state.allItems?.[0] : this.state.activeWalletItems?.[0],
                      bankEditOpenUiIndex : new Array(this.state.activeBankItems.length).fill(false),
                      ccEditOpenUiIndex: new Array(this.state.activeCCItems.length).fill(false)
                    }) 
                  }
  })
  window.scrollTo(0,0)
  this.setState({loading : false}, () => {
    sessionStorage.setItem('notificationCount', 1)
  })
} else {
  this.setState({ loading: false })
}},0)
console.log('End of get wallet items')

}

 walletItemSession(walletItemAddedUpdated) {
  console.log(walletItemAddedUpdated);
  sessionStorage.setItem('walletItemAddedUpdated', walletItemAddedUpdated.length > 0 ? JSON.stringify(walletItemAddedUpdated?.[0]) : null);
 }

 setLoading = (value)=> {
   console.log('calling loading')
  this.setState({loading : value})
 }

 getPlanNameUI() {
  return (
      <div className="walletPlanName" id="wallet_planName">
              <Typography.H3>{getPlanInformation().planType}</Typography.H3>
              <Typography.Paragraph>{getPlanInformation().planName}</Typography.Paragraph>
      </div>
  )
}

returnPaymentOverview = () => {
  window.location.assign(getOverviewPageLink());
  trackLinkClick('return to payment overview');
  console.log('return to payment overview')
}

eftSubmitBtnClickErrorSet = (errorFields)=> {
  console.log(errorFields)
  this.setState({errorFields : errorFields, walletAction: '', walletItemAdded: null, ccItemAddedUpdated: null, bankItemAddedUpdated: null})
  if(errorFields){   
    var analyticErrorMsg='' ;
    let index=0;
    let invalidTxt=''
      if(errorFields.fields && errorFields.fields.length>0){
        errorFields.fields.map (function (field) {
          if(index === 0 && field.error){
            index = index+1;
            analyticErrorMsg=field.label
            invalidTxt='invalid'
          }else if(field.error){
            analyticErrorMsg=analyticErrorMsg+'|'+field.label
            invalidTxt=invalidTxt+'|'+'invalid'
          }
        })
        trackError('manage my wallet', analyticErrorMsg, 'form validation error', invalidTxt);
        console.log('manage my wallet', analyticErrorMsg, 'form validation error', invalidTxt)
      }
    }  
}

editCCOnSubmitBtnClick = (errorFields)=> {
  console.log(errorFields)
  this.setState({errorFields : errorFields, walletAction: '', walletItemAdded: null, ccItemAddedUpdated: null, bankItemAddedUpdated: null})
}

changeCCError = (label)=> {
  if(label.indexOf('Invalid characters provided for NAMEONCARD') > -1) {
    return manageWalletAem.auth_mw_nc_invalid_char_err_txt
  } else if(label.indexOf('NAMEONCARD cannot have a length greater') > -1) {
    return  manageWalletAem.auth_mw_nc_length_err_txt
  } else if(label.indexOf('Invalid characters provided for NICKNAME') > -1) {
    return `<b>${manageWalletAem.auth_mw_inv_nickname_err_title}</b> ${manageWalletAem.auth_mw_inv_nickname_err_txt}`
  } else if(label.indexOf('NICKNAME cannot have a length greater') > -1) {
    return `<b>${manageWalletAem.auth_mw_inv_nickname_err_title}</b> ${manageWalletAem.auth_mw_nickname_len_err_txt}`
  } else if(label.indexOf('The EXPIRATION cannot be in the past or the current month') > -1) {
    return `<b>${manageWalletAem.auth_mw_cc_exp_pastOrCurrent_title}</b> ${manageWalletAem.auth_mw_cc_exp_pastOrCurrent}`
  }
  return label
}

editRecurringWalletItem = (isOneTime) => {
  let routeTo = '/federal-recurring'
  let eftNumber = '';
  let totalAmountDue = '';
  let paymentsMethodValue = '';
  let monthlyPaymentPremium = '';
  let currentRecurring = this.state.activeWalletItems.filter(item => {
   return (item.itemId === this.state.recBankItemId || item.itemId === this.state.recCCItemId);

  })
  console.log(currentRecurring);
  let isPastAmountDue = "false";
  let readBillingAndTransactionDtl = this.props.location.state.readBillingAndTransactionDtl
  secureLocalStorage.setItem("recurringPaymentFlag", !isOneTime)
  secureLocalStorage.setItem("oneTimePaymentFlag", isOneTime)
  secureLocalStorage.setItem('totalAmountDue', readBillingAndTransactionDtl.totalAmountDue);
  secureLocalStorage.setItem('paymentsMethodValue', currentRecurring.length > 0 ? currentRecurring?.[0].creditCard === null ? 'EFT-' + currentRecurring?.[0].bankAccount?.bankAccountType : 'Credit' : '');
  eftNumber = currentRecurring.length > 0 ? currentRecurring?.[0].bankAccount?.accountNumber.slice(-4) : '';
  if (readBillingAndTransactionDtl.totalAmountDue && readBillingAndTransactionDtl.totalAmountDue > 0 &&  readBillingAndTransactionDtl.paymentMethod.indexOf('CMS-SSA/RRB') === -1) {
      isPastAmountDue = "true";
  } 
  secureLocalStorage.setItem("isPastAmountDue", isPastAmountDue)
  if (readBillingAndTransactionDtl.premium) {
      monthlyPaymentPremium = readBillingAndTransactionDtl.premium.basePremium;
  }
  this.props.history.push({
    pathname: routeTo,
    state: {
      eftNumber: eftNumber,
      monthlyPaymentPremium: monthlyPaymentPremium,
      fromPage: 'manageWallet',
    }
  })
}

onLoadApiErrors = ()=> {
  if(secureLocalStorage.getItem('ccAdded') && secureLocalStorage.getItem('ccError') && secureLocalStorage.getItem('ccAdded') == 'error') {
    console.log(secureLocalStorage.getItem('ccError'));
    let errors = JSON.parse(secureLocalStorage.getItem('ccError'));
    this.setState({errorFields : {fields : errors.map(elt=> {return {error : true, label: this.changeCCError(elt)}}),
    count : 1,
    type : 'api'
    }})
    secureLocalStorage.removeItem('ccError')
    
  } else if((secureLocalStorage.getItem('ccAdded')) && secureLocalStorage.getItem('ccAdded') && secureLocalStorage.getItem('ccAdded').indexOf('exist') > -1) {
    let existCC = secureLocalStorage.getItem('ccAdded').replace('exist-','')
    this.setState({errorFields : {fields : [{error : true, label: `<b>${manageWalletFormAem.auth_mw_alr_wallet_err_msg_title}</b> ${existCC} ${manageWalletFormAem.auth_mw_alr_wallet_err_msg}`}],
    count : 1,
    type : 'api'
    }})
    secureLocalStorage.removeItem('ccAdded')
  } else if((secureLocalStorage.getItem('ccAdded')) && secureLocalStorage.getItem('ccAdded') && secureLocalStorage.getItem('ccAddErrorCode') &&
  (secureLocalStorage.getItem('ccAddErrorCode').indexOf('500') > -1 || secureLocalStorage.getItem('ccAddErrorCode').indexOf('400') > -1 || secureLocalStorage.getItem('ccAddErrorCode').indexOf('403') > -1)) {
    this.setState({errorFields : {fields : [{error : true, label: `<b>${manageWalletFormAem.auth_mw_system_err_title}</b> ${manageWalletFormAem.auth_mw_system_err_txt}`}],
    count : 1,
    type : 'api'
    }})
    secureLocalStorage.removeItem('ccAdded')
    secureLocalStorage.removeItem('ccAddErrorCode')
  }
}

showSuperUserError(ccItemNum, eftItemNum) {
  this.setState({ superUserError: true, eftItemNum: !this.state.isAddEftFormOpen ? eftItemNum : null, ccItemNum: !this.state.isAddEftFormOpen ? ccItemNum : null, addEFTOpen: this.state.isAddEftFormOpen ? true : false })
}

showErrorOnItemRemove = (res)=> {
  let errors = res.errors?.map(elt=> elt.detail)
  console.log(errors)
  let errLabel = `<b>${manageWalletFormAem.auth_mw_system_err_title}</b> ${manageWalletFormAem.auth_mw_system_err_txt}`
  console.log(errLabel)
  if(errors && errors.length > 0 && errors.filter(elt=> elt.indexOf('there is a payment pending') > -1).length > 0) {
    console.log(errors)
    errLabel =  `<b>${manageWalletAem.auth_mw_wall_item_err_title}</b> ${manageWalletAem.auth_mw_wall_item_err_txt}`
  }
  this.setState({errorFields : {fields : [{error : true, label: errLabel}],
    count : 1,
    type : 'api'
    }})
  window.scrollTo(0, 0)
  document.getElementById('alertbody_desktop').focus()
}

formatCCEFT(paymentType) {
  switch(paymentType?.toLowerCase()) {
    case 'checking':
      paymentType ='EFT-Checking'
      break;
    case 'savings':
      paymentType = 'EFT-Savings'
      break;
    case 'visa':
      paymentType = 'Visa'
      break;
    case 'mastercard':
      paymentType = 'Mastercard'
      break;
    case 'amex':
      paymentType = 'Amex'
      break;
    default:
      paymentType = 'Discover'
      break;
  }
  return paymentType;
}
notificationGenerate() {
  if (this.state.walletItemAdded.active === 'FALSE') {
    if (this.state.walletItemAdded.bankAccount?.routingNumber && this.state.walletItemAdded.bankAccount?.accountNumber) {
      return <Typography.Paragraph><span style={{fontWeight: 'bold'}}>{manageWalletAem.auth_mw_pay_method_removed} </span>{manageWalletAem.auth_mw_your_text} {this.formatCCEFT(this.state.walletItemAdded.bankAccount?.bankAccountType) + ' ***' + this.state.walletItemAdded.bankAccount?.accountNumber?.slice(-4)} {manageWalletAem.auth_mw_acc_has_removed_text}</Typography.Paragraph>  
    } else if (this.state.walletItemAdded.creditCard?.creditCardToken) {
        return <Typography.Paragraph><span style={{fontWeight: 'bold'}}>{manageWalletAem.auth_mw_pay_method_removed} </span>{manageWalletAem.auth_mw_your_text} {this.formatCCEFT(this.state.walletItemAdded.creditCard?.cardType) + ' card ***' + this.state.walletItemAdded.creditCard?.cardNumberLastFour} {manageWalletAem.auth_mw_has_removed_text}</Typography.Paragraph>  
    }
  } else if ((this.state.activeWalletItems?.[0].audit?.createdOn === this.state.activeWalletItems?.[0].audit?.modifiedOn)) {
    if (this.state.activeWalletItems?.[0].bankAccount?.routingNumber && this.state.activeWalletItems?.[0].bankAccount?.accountNumber) {
        return <Typography.Paragraph><span style={{fontWeight: 'bold'}}>{manageWalletAem.auth_mw_payment_method_saved}</span> {manageWalletAem.auth_mw_your_text} {this.formatCCEFT(this.state.activeWalletItems?.[0].bankAccount?.bankAccountType) + ' ***' + this.state.activeWalletItems?.[0].bankAccount?.accountNumber?.slice(-4)} {manageWalletAem.auth_mw_acc_has_saved_text}</Typography.Paragraph>  
      } else if (this.state.activeWalletItems?.[0].creditCard?.creditCardToken) {
        return <Typography.Paragraph><span style={{fontWeight: 'bold'}}>{manageWalletAem.auth_mw_payment_method_saved}</span> {manageWalletAem.auth_mw_your_text} {this.formatCCEFT(this.state.activeWalletItems?.[0].creditCard?.cardType) + ' card ***' + this.state.activeWalletItems?.[0].creditCard?.cardNumberLastFour} {manageWalletAem.auth_mw_has_saved_text}</Typography.Paragraph>  
      }
  } else if ((this.state.activeWalletItems?.[0].audit?.createdOn !== this.state.activeWalletItems?.[0].audit?.modifiedOn)) {
      if (this.state.activeWalletItems?.[0].active === 'TRUE') {
        if ((this.state.activeWalletItems?.[0].bankAccount?.routingNumber && this.state.activeWalletItems?.[0].bankAccount?.accountNumber) || this.state.activeWalletItems?.[0].bankAccount?.bankAccountId) {
          return <Typography.Paragraph><span style={{fontWeight: 'bold'}}>{manageWalletAem.auth_mw_acc_updated_text} </span>{manageWalletAem.auth_mw_your_text} {this.formatCCEFT(this.state.activeWalletItems?.[0].bankAccount?.bankAccountType) + ' ***' + this.state.activeWalletItems?.[0].bankAccount?.accountNumber?.slice(-4)} {manageWalletAem.auth_mw_acc_has_updated_text}</Typography.Paragraph>  
        } else if (this.state.activeWalletItems?.[0].creditCard?.creditCardToken) {
            return <Typography.Paragraph><span style={{fontWeight: 'bold'}}>{manageWalletAem.auth_mw_card_updated} </span>{manageWalletAem.auth_mw_your_text} {this.formatCCEFT(this.state.activeWalletItems?.[0].creditCard?.cardType) + ' card ***' + this.state.activeWalletItems?.[0].creditCard?.cardNumberLastFour} {manageWalletAem.auth_mw_has_updated_text}</Typography.Paragraph>  
        }
        }
    }
}

scrollToElement = (el, block) => {
  el.focus()
  el.scrollIntoView({behavior: 'smooth',block: block, inline: "nearest"})
}

addEftCancelBtnClick = (isScroll)=> {
  let btn = document.getElementById('addMop').children[0].children[0].children[0].children[0].children[0]
  console.log(btn);
  if(btn) {
    btn.click()
    isScroll && this.scrollToElement(btn,"center")
    trackLinkClick('manage my wallet:cancel - add new payment method');
    console.log('manage my wallet: cancel - add new payment method'); 
  }
}


eftToggleAction = ()=> {
  !this.state.isAddEftFormOpen && this.onCCCancelClick()
  !this.state.isAddEftFormOpen && this.cancleBtnOnClickEditEFT(false)
  this.setState({isAddEftFormOpen : !this.state.isAddEftFormOpen, paymentMethod : 'checking', superUserError: null})
  if(!this.state.isAddEftFormOpen) {
    trackLinkClick('manage my wallet:accordian expand:add new payment method');
    console.log('manage my wallet:accordian expand:add new payment method');
  }else {
    trackLinkClick('manage my wallet:accordian collapse:add new payment method');
    console.log('manage my wallet:accordian collapse:add new payment method');
  }
  if (this.state.walletItemAdded === null || this.state.walletItemAdded.active === 'TRUE') {
    this.eftRef.current.resetState()
  }
  let btns = Array.from(document.getElementsByTagName('button'))
  let btn = btns && btns.length > 0 ? btns.filter(el=> el.id.match(/1001/g)) : null
  setTimeout(()=> {
     if(btn) {
        this.scrollToElement(btn[0],"start")
      }
  }, 200)
}

onCCEditClick = (index) => {
  this.state.isAddEftFormOpen && this.addEftCancelBtnClick(false)
  this.setState({ccEditOpenUiIndex : new Array(this.state.activeCCItems.length).fill(false), errorFields : null, ccItemNum: null, superUserError: null}, ()=> {
    let allIndex = this.state.ccEditOpenUiIndex
    allIndex[index] = true
    this.setState({ccEditOpenUiIndex : allIndex}, ()=> {
      if(this.state.isAddEftFormOpen) {
        this.setState({bankEditOpenUiIndex : new Array(this.state.activeBankItems.length).fill(false), isAddEftFormOpen : false, paymentMethod : 'checking'})
      }
      if(this.state.bankEditOpenUiIndex.filter(el=> el == true).length > 0) {
        this.setState({bankEditOpenUiIndex : new Array(this.state.activeBankItems.length).fill(false)})}
      })
  })
  setTimeout(()=> {
    let el = document.getElementById('ccEditLink_'+index)
    el && this.scrollToElement(el,"start")
    el.setAttribute('aria-expanded','true')
    el.setAttribute('aria-labelledby','edit_cc_title_'+index)
  },300)
  trackLinkClick('manage my wallet - credit card/debit card - edit existing payment method');
  console.log('manage my wallet - credit card/debit card - edit existing payment method');
}
onCCCancelTrackLinkEvent =() =>{
  trackLinkClick('manage my wallet - credit card/debit card - cancel edit existing payment method');
  console.log('manage my wallet - credit card/debit card - cancel edit existing payment method');
}
onCCCancelClick = (ccIndex) => {
  //WCAG Implementation
  let el = document.getElementById('ccEditLink_'+ ccIndex)
  if(this.state.ccEditOpenUiIndex[ccIndex]){
    el.setAttribute('aria-expanded','false')
    el.removeAttribute('aria-labelledby')
    if(document.getElementById('cc_remove_'+ ccIndex)){
      document.getElementById('cc_remove_'+ ccIndex).removeAttribute('tabindex')
    } else {
      document.getElementById('cc_rec_remove_'+ ccIndex).removeAttribute('tabindex')
    }
  }
  this.setState({ccEditOpenUiIndex : new Array(this.state.activeCCItems.length).fill(false), errorFields : null, ccItemNum: null, superUserError: null})
  setTimeout(()=> {
    let el = document.getElementById('ccEditLink_'+ ccIndex)
    el && this.scrollToElement(el,"center")
  },300)
}

onBankEditClick = (index)=> {
  this.onCCCancelClick()
  this.state.isAddEftFormOpen && this.addEftCancelBtnClick(false)
  this.setState({bankEditOpenUiIndex : new Array(this.state.activeBankItems.length).fill(false), eftItemNum: null, superUserError: null}, ()=> {
    let allIndex = this.state.bankEditOpenUiIndex
    allIndex[index] = true
    this.setState({bankEditOpenUiIndex : allIndex})
  })
  setTimeout(()=> {
    let el = document.getElementById('bankEditLink_'+index)
    el && this.scrollToElement(el,"start")
    el.setAttribute('aria-expanded','true')
    el.setAttribute('aria-labelledby','edit_eft_title_'+index)
  },300)
  trackLinkClick('manage my wallet - EFT checking/savings - edit existing payment method');
  console.log('manage my wallet - EFT checking/savings - edit existing payment method');
}

cancleBtnOnClickEditEFT = (bankListIndex)=> {
  this.setState({bankEditOpenUiIndex : new Array(this.state.activeBankItems.length).fill(false), eftItemNum: null, superUserError: null})
  this.setState({errorFields : null})
  setTimeout(()=> {
    let el = document.getElementById('bankEditLink_'+bankListIndex)
    el && this.scrollToElement(el,"center")
  },300)
  if(bankListIndex !== false){
  trackLinkClick('manage my wallet - EFT checking/savings - cancel existing edit payment method');
  console.log('manage my wallet - EFT checking/savings - cancel existing edit payment method');
  }
}

resetAllFormsAndErrors = ()=> {
  this.state.isAddEftFormOpen && this.addEftCancelBtnClick(false)
  this.onCCCancelClick()
  this.setState({
    bankEditOpenUiIndex : new Array(this.state.activeBankItems.length).fill(false),
    ccEditOpenUiIndex: new Array(this.state.activeCCItems.length).fill(false),
    walletItemAdded: null,
    accountItemNum: null,
    superUserError: null
  })
}
isMobile() {
  let screenWidth = window.screen.width;
  if (screenWidth < 768) {
      return true;
  }
  return false;
}

generateBreadCrumbs() {
  if (this.isMobile()) {
    return (
      <ol className="tds-breadcrumbs__list uhc-breadcrumb-mobile" id="breadcrumb-2">
          <li className="tds-breadcrumbs__listitem">
                  <svg
                  className="MuiSvgIcon-root tds-utility-icon tds-breadcrumbs__chevron MuiSvgIcon-fontSizeSmall arrow_reverse"
                  focusable="false"
                  viewBox="0 0 24 24"
                  color="#196ecf"
                  aria-hidden="true"
                  role="img"
              >
                  <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
              </svg>
              <a style={{cursor: 'pointer'}} onClick={() => window.location.assign(getOverviewPageLink())} className="tds-breadcrumbs__link"><span className="tds-breadcrumbs__link-text">{'Back'}</span></a>
              
              </li>
          </ol>
    )
  } else {
    return (
      <Breadcrumbs aria-label="breadcrumb navigation">
          <Breadcrumbs.Breadcrumb href={getOverviewPageLink()}>{manageWalletAem.auth_mw_bc_payments_overview}</Breadcrumbs.Breadcrumb>
          <Breadcrumbs.Breadcrumb >{manageWalletAem.auth_mw_bc_manage_wallet}</Breadcrumbs.Breadcrumb>
      </Breadcrumbs>
    )
  }
}

handlePaymentMethodSelection = (event)=> {
  let value = event.target.value
  this.setState({ superUserError: null})
  if(value) {
      if(this.state.paymentMethod !== value) {
        this.eftRef.current.resetState()
      }
      if(value === 'CC') {
        if (this.state.superUser & this.state.isAddEftFormOpen) {
          this.showSuperUserError()
        } 
        sessionStorage.setItem('isMFAAuthenticated', this.state.isMFAAuthenticated)
        sessionStorage.setItem('isMFA', this.state.isMFA)
        secureLocalStorage.setItem("items", JSON.stringify(items))
        secureLocalStorage.setItem("updateWalletData", JSON.stringify(updateWalletData))
        this.setState({ccClicked : true})
        if(document.getElementById('add_eft_details')){
          document.getElementById('add_eft_details').removeAttribute('aria-labelledby');
        }
        
      } else {
        if (sessionStorage.getItem('isMFAAuthenticated')) {
          sessionStorage.removeItem('isMFAAuthenticated');
        }
        secureLocalStorage.removeItem("items")
        secureLocalStorage.removeItem("updateWalletData")
        document.getElementById('add_eft_details').setAttribute('aria-labelledby','add_eft_title');   
      }
      this.setState({paymentMethod : value})
  }
}

  render() {
    return (
      <div>
         {this.state.manageWalletAemRendered && this.state.manageWalletEftFormAemRendered ?
         (
          <div className="">
          <div className="manageWallet">
          <div className="walletHeader">
            <div className="container">
              {this.generateBreadCrumbs()}
                <Typography.H1 id="globalContentIdForSkipLink">{manageWalletAem.auth_mw_manage_wallet_title}</Typography.H1>
                <Typography.H2>{getPlanInformation().planType}</Typography.H2>
            
              </div>
          </div>
          { (this.state.walletItemAdded && (sessionStorage.getItem('notificationCount') === '0')) ?
            <Notification notificationType="success" id="alert_Notificaiton" tabIndex="0" className='walletNotificationMsg' dismissClickHandler={() => { this.setState({ walletItemAdded: '', walletAction: ''}, () => { sessionStorage.setItem('notificationCount', 1)})}}>
              {this.notificationGenerate()}
            </Notification>       
            :
            null
          }
           <div className="container mobile-container">
            
               <PageError errorFields = {this.state.errorFields} page='wallet'/>

             
            {this.state.loading ? <Spinner/> :
            <div>          
            <div className="manageWallet-body" style={this.state.errorFields ? {marginTop : '40px'} : null}>
              {/* Wallet Design */}
              
              <div className="walletPlanInfo">
                {this.getPlanNameUI()}
                <div className="learnWallet">
                <PaymentModals
                        modalContent={{
                          header: manageWalletAem.auth_learn_wallet_mo_heading,
                          desc: manageWalletAem.auth_learn_wallet_mo_content,
                          btn_txt: manageWalletFormAem.auth_mw_close_btn_txt 
                        }}
                        modalType='learnAboutWallet'
                        learnwayslinktitle={manageWalletAem.auth_learn_wallet_link_text}
                    />
                </div>
              </div>
              <div className="walletPaymentMethodHeader">
                <Typography.H4>{manageWalletAem.auth_mw_payment_methods_subtitle}</Typography.H4>
              </div>
                <Fragment>
                  <div>
                    <Typography.H5 id="eft_acc_heading">{manageWalletAem.auth_mw_checking_savings_acc_heading} </Typography.H5>
                  </div>
                  <div className="walletPaymentMethods">
                    { this.state.activeBankItems.length === 0 &&
                    <div className="walletPaymentMethod">
                      <Typography.Paragraph>
                          <Markup content={manageWalletAem.auth_no_saved_acc_content}/>
                      </Typography.Paragraph>
                    </div>
                    }
                    {this.state.activeBankItems.length > 0 &&
                    this.state.activeBankItems.map((item, index) => (
                        <BankAccountList 
                              ref = {this.eftRef} 
                              key={index}
                              bankListIndex = {index}
                              isEditOpen = {this.state.bankEditOpenUiIndex[index]}
                              onEditBtnClick = {this.onBankEditClick}
                              cancleBtnOnClickEditEFT = {this.cancleBtnOnClickEditEFT}
                              item={item} 
                              index={index}
                              activeWalletItems={this.state.activeWalletItems}
                              walletItemAdded={this.state.walletItemAdded}
                              getWalletDetails= {this.getWalletDetails} 
                              setLoading= {this.setLoading}
                              eftSubmitBtnClickErrorSet = {this.eftSubmitBtnClickErrorSet}
                              resetAllFormsAndErrors = {this.resetAllFormsAndErrors}
                              recBankItemId = {this.state.recBankItemId}
                              scheduledBankItemId = {this.state.scheduledBankItemId}
                              getWalletItemNum={this.getWalletItemNum}
                              formatCCEFT={this.formatCCEFT}
                              removeApiErr = {this.showErrorOnItemRemove}
                              superUser={this.state.superUser} 
                              showSuperUserError={this.showSuperUserError}
                              superUserError={this.state.superUserError}
                              accountItemNum={this.state.eftItemNum}
                              editRecurringWalletItem = {this.editRecurringWalletItem}
        
                              />
                    ))}
                  </div>
                </Fragment>
                <Fragment>
                  <Fragment>
                    <div>
                      <Typography.H5 id="cc_debit_heading">{manageWalletAem.auth_mw_credit_debit_heading}</Typography.H5>
                    </div>
                    <div className="walletPaymentMethods">
                    { this.state.activeCCItems.length === 0 &&
                    <div className="walletPaymentMethod">
                      <Typography.Paragraph>
                        <Markup content={manageWalletAem.auth_no_saved_cards_content}/>
                      </Typography.Paragraph>
                    </div>
                    }
                    {
                    this.state.activeCCItems.length > 0 &&
                      this.state.activeCCItems.map((item, index) => (
                          <CreditCardList 
                            key={index}
                            item={item}
                            getWalletDetails= {this.getWalletDetails}
                            isEditOpen = {this.state.ccEditOpenUiIndex[index]}
                            onCCEditClick = {this.onCCEditClick} 
                            onCCCancelClick = {this.onCCCancelClick}
                            onCCCancelTrackLinkEvent={this.onCCCancelTrackLinkEvent}
                            setLoading= {this.setLoading} 
                            editCCOnSubmitBtnClick = {this.editCCOnSubmitBtnClick} 
                            index={index}
                            activeWalletItems={this.state.activeWalletItems}
                            resetAllFormsAndErrors = {this.resetAllFormsAndErrors}
                            ccItemAddedUpdated = {this.state.ccItemAddedUpdated}
                            recCCItemId = {this.state.recCCItemId}
                            scheduledCCItemId = {this.state.scheduledCCItemId}
                            getWalletItemNum={this.getWalletItemNum}
                            formatCCEFT={this.formatCCEFT}
                            removeApiErr = {this.showErrorOnItemRemove}
                            superUser={this.state.superUser} 
                            showSuperUserError={this.showSuperUserError}
                            superUserError={this.state.superUserError}
                            accountItemNum={this.state.ccItemNum}
                            editRecurringWalletItem = {this.editRecurringWalletItem}
                            />
                      ))
                      }
                    
                    </div>
                  </Fragment>
                  <Typography.Paragraph className="expiredCardLbl">{manageWalletAem.auth_mw_expired_cards_instruction}</Typography.Paragraph>
                </Fragment>
              <div className="addPaymentMethod" id="addMop">
                <div>
                {/* <Button buttonType="secondary-one" className="tds-margin-xsm-horizontal"><span className="expand">Add new payment method</span> </Button> */}
                 

                <AccordionGroup headingLevel={4} onAccordionToggle={() => this.eftToggleAction()} contentId='1001'>
                    <Accordion title={manageWalletAem.auth_add_new_pay_method_text}>
                      <FedRadioBtn handlePaymentMethodSelection = {this.handlePaymentMethodSelection}
                        paymentMethod = {this.state.paymentMethod}/>
                        <EftForm 
                          ref = {this.eftRef}
                          addEftCancelBtnClick = {this.addEftCancelBtnClick}
                          eftSubmitBtnClickErrorSet = {this.eftSubmitBtnClickErrorSet} 
                          setLoading = {this.setLoading} 
                          getWalletDetails = {this.getWalletDetails}
                          getWalletItemNum={this.getWalletItemNum} 
                          paymentMethod = {this.state.paymentMethod}
                          superUser={this.state.superUser} 
                          showSuperUserError={this.showSuperUserError}
                          page={'wallet'}
                          errorFields = {this.state.errorFields}
                          />
                     {((this.state.paymentMethod === 'CC' || this.state.ccClicked) && !this.state.superUser) ? <CreditCardGPS productType={getProductType()} addCardText = {manageWalletFormAem.auth_mw_add_card_txt} paymentMethod = {this.state.paymentMethod} iframeShow={this.state.paymentMethod === 'CC' ? 'show' : 'hide'}/> : null}
                    </Accordion>
   
                </AccordionGroup>
                {
                  
                  ((this.state.superUserError && (this.state.isAddEftFormOpen)) || this.state.superUserError && this.state.addEFTOpen) ?
                        <div className={this.state.paymentMethod !== 'CC' ? 'walletSuperUserError addMOP' : 'walletSuperUserError'} id={'walletSuperUserNotification_' + this.props.key}><Notification  notificationType='error'> <span style={{fontWeight: 'bold'}}>{manageWalletFormAem.auth_wallet_memberauth_not_allowed + ' ' }</span> {manageWalletFormAem.auth_wallet_memberauth_error_msg} </Notification></div> 
                        :
                        null
                }
                </div>
                
              </div>
            </div>
            <div className="walletPaymentsOverviewBtn">
            <Button
                text={manageWalletAem.auth_mw_return_payments_overview}
                buttonType="primary-one"
                onClick={this.returnPaymentOverview}
                className="tds-margin-xsm-horizontal tds-button tds-button--primary-one tds-margin-xsm-horizontal"
              />
              </div>
            </div>}
            </div>
          </div>
          </div>
         ) : <Spinner />}
          
      </div>
   )
  }

}

export default withRouter(ManageWallet)
