import axios from './ucpPayments-api';
import appConfigs from '../app.config';

export const deleteTokenAPI = async (request) => {
    var deleteTokenRequest = {
        "tokenNum": request.token,
        "transId" :request.transactionId,
        "tokenizationStatus" : "CREATED",
        "accountId" :request.upgAccountId,
        "uuid" :"",
        "serviceResponse" :"",
        "creationDate" :"",
        "lastModifiedDate": ""
    };

	return axios.post(appConfigs.upg_delete_token_service_path , 
        deleteTokenRequest).then(response =>{
				return response;
		})
		.catch((error) => {
            console.log(error.message);
            return {
                callFailed: true
            };
		});

};

export const unTokenizeToken = async (request, authPayment) => {
    console.log(request);
    var tokenRequest = {
        "tokenNum": !authPayment ? request.token : null,
        "transId": !authPayment ? request.transactionId : null,
        "tokenizationStatus": "CREATED",
        "accountId": request.upgAccountId,
        "uuid": authPayment ? request.uuid : null,
        "serviceResponse": "",
        "creationDate": "",
        "lastModifiedDate": "",
    }
    console.log(tokenRequest);
    return await axios.post(appConfigs.upg_untokenization_success_payment_path, tokenRequest);
    
}

export default deleteTokenAPI;