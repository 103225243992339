import { Button, Modal, Typography } from '@uhc-tempo/components'
import React from 'react'
import { Markup } from 'interweave';
import { trackLinkClick, trackModalWindowClick } from '../../../server/util/analytics';
import {getEncryptAATokenService} from '../../../src/api/encAAtokenService-api';
import {getMFARedirectDomain, getOneTimePageLink, getRecurringPageLink, getWalletPageLink} from '../../../server/util/localStorageRepo';
import { mfaLoggerInfo } from   '../../../server/api/mfaLoggingService-api';
import secureLocalStorage from 'react-secure-storage';

const HsidMfaModal = (props) => {
    trackModalWindowClick(secureLocalStorage.getItem('currPage') , props.modalContent.header);
    const redirecttoMFA = (mfaCallBackURL,paymentType) =>{
    let paymentTypeUrl="";
      if(paymentType){
        if(paymentType.onetimePayment){
          paymentTypeUrl=  getOneTimePageLink();
        }else if(paymentType.updateRecurring){
          paymentTypeUrl=  getRecurringPageLink();
        }
      }else{
        paymentTypeUrl=  getWalletPageLink();
      }
      secureLocalStorage.setItem('paymentsMethodValue', props.paymentsMethodValue);
      secureLocalStorage.setItem("paymentTypeUrl",paymentTypeUrl);
      secureLocalStorage.setItem("mfaCallBackURL",mfaCallBackURL);
      let startTime = new Date();
      
      mfaLoggerInfo('MFA call hit ',{"function":"MFA Hit","status": "success","mfaCallBackURL":mfaCallBackURL,"paymentTypeUrl":paymentTypeUrl});
        let mfaRedirectUrl=  getMFARedirectDomain() ;
        let currentLang = localStorage.getItem('MYUHC_LOCALE') ? localStorage.getItem('MYUHC_LOCALE') === 'en-US' ? 'en' : 'es' : 'en'
        getEncryptAATokenService(mfaCallBackURL,JSON.parse(localStorage.getItem('consumerDetails')).userName).then((response) => {
          if (response.data != null) {
             mfaRedirectUrl= mfaRedirectUrl + '?in=TOKEN2&out=TOKEN2&callback='+mfaCallBackURL+'&aaToken='+response.data.token+'&caller=direct&portalBrand=mnr&lang='+currentLang+'&returnOnAASysError=true&theme=hsid';

           mfaLoggerInfo("MFA encryption service is success ",
                      {"function":"MFA Hit Intializated",
                      "service":"HSID Encryption AA Token API ",
                      "status": "success",
                      "mfaCallBackURL":mfaCallBackURL,
                      "mfaRedirectUrl":mfaRedirectUrl,
                      "paymentTypeUrl":paymentTypeUrl
                      });
            window.location.assign(mfaRedirectUrl);
        
          }
        }).catch(error => {
          mfaLoggerInfo("MFA encryption service is failed ",
                {"function":"MFA Hit System Issue",
                "service": "HSID Encryption AA Token API",
                "status": "failed","mfaRedirectUrl":mfaRedirectUrl,"duration": Date.now() - startTime.getTime(),
                "error": error ? (error.message ? error.message :" "):"",
                "mfaCallBackURL": mfaCallBackURL,
                "paymentTypeUrl":paymentTypeUrl
              });
            if(error?.response?.status && error?.response?.status === 403){
              return  window.location.assign(window.location.origin +'/medicare/content/medicare/member/logout.html');
            }else {
                    props.displayErrorMessage()
                 window.scrollTo(0, 0);
            }
        });
    };
    
    return (
    <Modal className='mt-5' handleClose={() => {trackLinkClick(props.modalContent.header + ': Close Icon'); props.modalContent.closeHandler()}}>
        <Modal.Header><Typography.H2>{props.modalContent.header}</Typography.H2></Modal.Header>
        <Modal.Content>
        <Typography.Paragraph>
           <Markup content={props.modalContent.model_msg}/>
        </Typography.Paragraph>

        </Modal.Content>
        <Modal.Footer>
        <Button buttonType='primary-one' onClick={() => {trackLinkClick(props.modalContent.header + ':' + ' Verify identity'); props.modalContent.closeHandler(); redirecttoMFA(props.mfaCallBackURL,props.paymentType);}} className='tds-margin-xsm-horizontal' size='md'>{props.modalContent.btn_text}</Button>
        <Button buttonType='secondary-one' onClick={() => {trackLinkClick(props.modalContent.header + ':' + ' Cancel'); props.modalContent.closeHandler()}} className='tds-margin-xsm-horizontal' size='md'>{props.modalContent.btn_text1}</Button>
        </Modal.Footer>
    </Modal>
    )
}

export default HsidMfaModal