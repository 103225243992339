/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { serverCurrentTime } from '../../../server/api/serverTime-api';
import aemAxios, { content_path } from '../../../server/api/aem-api';
import './PaymentReview.css';
import { Redirect } from 'react-router-dom';
import Moment from 'moment';
import { Typography, Checkbox, FormGroup, Notification } from '@uhc-tempo/components';
import Spinner from '../../UI/Spinner/Spinner';
import styled from 'styled-components';
import { capitalizeVal } from '../../../server/util/util';
import { trackPageLoad, trackModalWindowClick, trackLinkClick, trackError } from '../../../server/util/analytics'
import {eftOnetimePaymentSubmission ,ccOnetimePaymentSubmission } from '../../../server/api/isbOnetimePayments-api';
import {eftUpdatePaymentSubmission ,eftCancelPaymentSubmission } from '../../../server/api/isbUpdatePaymentMethod-api';
import { logger } from '../../../server/logger';
import commonConstants from '../../../server/util/commonConstants';
import { Markup } from 'interweave';
import {getPlanInformation, getOneTimePaymentFlag, getRecurringPaymentFlag,
   getMemberFirstName, getMemberLastName, getMemberFullName, isManageRecurring, getProductType, getSearchPaymentMethodResult, setFeatureFlags, getIsbPLanInformation } from '../../../server/util/localStorageRepo';
import PageHeader from '../page-header/PageHeader';
import PaymentModals from '../modals/PaymentModals'
import Edit from '../../../src/tokens/icons/icon-edit.png';
import { convertToCurrency } from '../../../server/util/util';
import { saleTransactionCCPostPaymentResults } from '../../../server/api/saleTransactionService-api';
import deleteTokenAPI, { unTokenizeToken } from '../../../server/api/ccDeleteToken-api';
import secureLocalStorage from 'react-secure-storage';
import appConfigs from '../../../server/app.config';
export let featureFlagsAem = null;
class PaymentReview extends Component {
  constructor(props) {
    super(props)
    let payAmountPlaceHolder = '$__.__'
    this.modalRef = React.createRef()

    let linksOneTime = [{title: 'Payment Overview',link: 'toOverview', click : this.openCancelModal},
      // eslint-disable-next-line no-script-url
      {title: 'Make a One-Time Payment', link: 'javascript:void(0)', click : this.redirectHandler},
      {title: 'Review and Submit', link: ''}];

    let linksRecurring = [{title: 'Payment Overview', link: 'toOverview', click : this.openCancelModal},
    // eslint-disable-next-line no-script-url
    {title: isManageRecurring() ? 'Manage Automatic Payments' : 'Setup Automatic Payments',link: 'javascript:void(0)', click : this.redirectHandler},
    {title: 'Review and Submit', link: ''}];


    this.state = {
      defaultPaidAmoutPlaceHolder: payAmountPlaceHolder,
      firstName: '',
      lastName: '',
      fullName:'',
      memberId: '',
      planName: '',
      payAmount: payAmountPlaceHolder,
      totalAmount: '',
      serverDate: '',
      otherAmountError: '',
      otherAmount: '',
      paymentMethod: '',
      requestId: '',
      pastamountField: '',
      pastAmountAndCurentChargesField: '',
      onlyCurrentChargesField: '',
      bankHolderName: '',
      bankHolderFirstName: '',
      bankHolderMiddleName: '',
      bankHolderLastName: '',
      routingNumber: '',
      accountNumber: '',
      bankHolderNameError: false,
      routingNumberError: false,
      accountNumberError: false,
      formValidated: false,
      onload: true,
      errorMessage: '',
      isOpen: false,
      formErrorDisplay: 'Internal Server Error',
      formError: false,
      formSuccess: false,
      planTypeCode: '',
      planCategory: '',
      planId: '',
      sourcePortal: '',
      accountId: '',
      accountNumberFormated: '',
      redirect: false,
      fromPage: 'review',
      otherAmountChecked: false,
      pastAmountDueChecked: false,
      pastAmountAndCurentChecked: false,
      onlyCurrentChargesChecked: false,
      payInFullChecked : false,
      cardType: "",
      cardTypeId: "0",
      cardNumber: "",
      token: "",
      transactionDate: "",
      eaipTransactionDate: "",
      cardHolderName: "",
      expirationDate: "",
      upgAccountId: "",
      transactionId: "",
      paymentDt: "",
      redirectForm: 'payment-confirmed',
      callTriggered : false,
      elec_pay_modal : {},
      focusOnModal: false,
      pageLoadViaFlow: false,
      eftDataError:"",
      accountStatusWrapper: "",
      individualId: "",
      userName: JSON.parse(localStorage.getItem('consumerDetails')).userName,
      hLinks : getOneTimePaymentFlag() ? linksOneTime : linksRecurring,
      cancelRecChecked : false,
      newEftRecChecked : false,
      currentDate: "",
      submitAutomaticFailure: false,
      submitFilureFlag: false,
      submitOneTimeFailure: false,
      planType: "",
      spinnerLoading: true,
      ccFlow : false,
      saveCreditCard : false,
      payWithSavedCC : false,
      superUserError:false,
    }
  }

  saleCompassAPIReturnObj = {saleCalled: false, saleSuccess : false, compassCalled : false, compassSuccess : false};

  componentDidUpdate() {
    if (this.state.focusOnModal) {
      this.modalFocus()

      this.setState({
        focusOnModal: false,
      })
     }
  }

  backAction = () => {
    this.redirectHandler();
  }

  componentDidMount() {
    let isPageReloaded = true;
    let payMethod = null ;
    secureLocalStorage.setItem('isPaymentRedesignPage',true);
    secureLocalStorage.setItem('currPage', 'review');
    if (this.props && this.props.location && this.props.location.state) {
      this.setState({
        payAmount: this.props.location.state.payAmount,
        paymentMethod : this.props.location.state.ccFlow ? 'CC' : this.props.location.state.paymentMethod,
        lastUsedEft: this.props.location.state.ccFlow && secureLocalStorage.getItem('lastUsedEft') ? JSON.parse(secureLocalStorage.getItem('lastUsedEft')) : this.props.location.state.lastUsedEft,
        pastamountField: this.props.location.state.pastamountField,
        pastAmountAndCurentChargesField: this.props.location.state.pastAmountAndCurentChargesField,
        onlyCurrentChargesField: this.props.location.state.onlyCurrentChargesField,
        otherAmount: this.props.location.state.otherAmount,
        pastAmountDueChecked: this.props.location.state.pastAmountDueChecked,
        pastAmountAndCurentChecked: this.props.location.state.pastAmountAndCurentChecked,
        onlyCurrentChargesChecked: this.props.location.state.onlyCurrentChargesChecked,
        payInFullChecked: this.props.location.state.payInFullChecked,
        otherAmountChecked: this.props.location.state.otherAmountChecked,
        cardType: this.props.location.state.cardType,
        cardTypeId: this.props.location.state.cardTypeId,
        cardNumber: this.props.location.state.cardNumberLastFour,
        token: this.props.location.state.creditCardToken,
        transactionDate: this.props.location.state.transactionDate,
        cardHolderName: this.props.location.state.cardHolderName,
        expirationDate: this.props.location.state.expirationDate,
        upgAccountId: this.props.location.state.upgAccountId,
        transactionId: this.props.location.state.transactionId,
        bankHolderFirstName: this.props.location.state.bankHolderFirstName,
        bankHolderMiddleName: this.props.location.state.bankHolderMiddleName,
        bankHolderLastName: this.props.location.state.bankHolderLastName,
        routingNumber: this.props.location.state.routingNumber,
        accountNumber: this.props.location.state.accountNumber,
        totalAmount: secureLocalStorage.getItem('totalAmount'),
        cancelRecChecked : this.props.location.state.cancelRecChecked === undefined ? false : this.props.location.state.cancelRecChecked,
        newEftRecChecked : this.props.location.state.newEftRecChecked,
        ccFlow : this.props.location.state.ccFlow ?  true : false,
        payWithSavedCC : this.props.location.state.payWithSavedCC,
        superUser: localStorage.getItem("superUser") === "true" ? true : false,
      }, ()=> {
        if(!this.state.ccFlow) {
          window.history.pushState({page: 'review'}, "reviewPage", window.location.href);
          window.onpopstate = this.backAction;
        }
      });
      if (this.props.location.state.pageLoadViaFlow) {
        isPageReloaded = false;
        this.setState({
          pageLoadViaFlow: true
        })
      }
    }
    if(isPageReloaded) {
      this.setState({
        showSessionModal: true
      })
      return
    }
    window.scrollTo(0,0);
    trackPageLoad(getOneTimePaymentFlag() ? 'make a one time payment:review and submit' : isManageRecurring()  ?  'manage recurring payment:review and submit':
                'set up recurring payment:review and submit')
    var meta = document.createElement('meta');
    meta.name = "robots";
    meta.content = "noindex, nofollow";
    document.getElementsByTagName('head')[0].appendChild(meta);
    let date = null;
    if(secureLocalStorage.getItem('currSysTimeInMs')){
       date = new Date(parseInt(secureLocalStorage.getItem('currSysTimeInMs')))
       this.setState(
        {
          paymentDt: Moment(date).format('MM/DD/YYYY HH:mm:ss'),
          serverDate: Moment(date).format('MM/DD/YYYY'),
          currentDate: Moment(date).format('YYYY-MM-DD')
        }
      )
    }else{
    serverCurrentTime().then((response) => {
      if (response) {
       date = new Date(parseInt(response.systemtimeinmillis))
       this.setState(
        {
          paymentDt: Moment(date).format('MM/DD/YYYY HH:mm:ss'),
          serverDate: Moment(date).format('MM/DD/YYYY'),
          currentDate: Moment(date).format('YYYY-MM-DD')
        }
      )
       secureLocalStorage.setItem('currSysTimeInMs',response.systemtimeinmillis);
      }
    })
  }

  this.callFeatureFlags();
    if(payMethod === "CC") {
      let pAmount =  secureLocalStorage.getItem("payAmount");
      this.setState({
        paidAmount: convertToCurrency(pAmount.replace('$', ''))
      })
    } else {
      if (secureLocalStorage.getItem('guestAccountNumber') != null) {
        let accountNum = secureLocalStorage.getItem('guestAccountNumber');
        let formatedAccountNum = "";
        if (accountNum) {
          formatedAccountNum = accountNum.replace(/\d{4}(?= \d{4})/g, '****');
        }
        this.setState({
          accountNumber: accountNum,
          paidAmount: parseFloat(secureLocalStorage.getItem('guestPaidAmount').replace('$', '')),
          routingNumber: secureLocalStorage.getItem('guestRoutingNumber'),
          bankHolderName: secureLocalStorage.getItem('guestBankHolderName'),
          accountNumberFormated: formatedAccountNum
        })
      }
    }

    if (getMemberFirstName()) {
      this.setState({ firstName: getMemberFirstName() })
    }
    if (getMemberLastName()) {
      this.setState({ lastName: getMemberLastName() })
    }
    if (getMemberFullName()) {
      this.setState({ fullName: getMemberFullName() })
    }
    this.setState({
      memberId: getPlanInformation().memberNumber
    })

    if (getPlanInformation() != null && getPlanInformation().planName != null) {
      this.setState({ planName: getPlanInformation().planName })
    }

    if (getPlanInformation() != null && getPlanInformation().planType != null) {
      this.setState({ planType: getPlanInformation().planType})
    }
    if (secureLocalStorage.getItem('guestAccountId') != null) {
      this.setState({ accountId: secureLocalStorage.getItem('guestAccountId') })
      this.setState({ planCategory: secureLocalStorage.getItem('guestPlanCategory') })
      this.setState({ planId: secureLocalStorage.getItem('guestPlanId') })
      this.setState({ planTypeCode: secureLocalStorage.getItem('guestplanTypeCode') })
    }

    aemAxios
			.get(content_path + '/payment-review-new/payment-review-content-new/jcr:content/data/master.json?timestamp=' + new Date().getTime())
			.then((response) => {
        let elec_pay_modal = {
          label: response.data.elec_pay_modal_label,
          header: response.data.elec_pay_modal_title,
          subheader:response.data.elec_pay_modal_subheader,
          desc_content_onetime: getProductType() === 'SHIP' ? response.data.auth_ship_eft_otp_agreement : response.data.auth_fed_eft_otp_agreement,
          desc_content_setup: getProductType() === 'SHIP' ? response.data.auth_ship_setup_agreement_isb : response.data.auth_fed_setup_agreement,
          credit_desc: getOneTimePaymentFlag() ? (getProductType() === 'SHIP' ? response.data.elec_pay_ship_credit_modal_desc : response.data.elec_pay_credit_modal_desc) : getRecurringPaymentFlag() ? response.data.elec_pay_credit_modal_desc_recurring: '',
          payMethod: payMethod,
          btn_txt: response.data.elec_pay_modal_btn,
          elec_pay_modal_error: response.data.elec_pay_modal_error,
          elec_pay_modal_checkbox_content:response.data.elec_pay_modal_checkbox_content,
          accept_btn: response.data.auth_mdl_accept_btn
        }
        let cancelPaymentModal = {
          header: response.data.auth_review_cancelmodal_title,
          btn_txt: response.data.auth_review_takingback_content,
          btn_txt1: response.data.auth_review_stayonpage_btn,
          cancelConfirmModal : {
            header: response.data.auth_review_pay_cancel_title,
            bckTxt: response.data.auth_review_takingback_content,
            cancelpay_txt: response.data.auth_review_cancelpay_btn,
          }
        }
        this.state.hLinks[0].title = response.data.auth_bc_pay_ov_label;
        this.state.hLinks[2].title = response.data.auth_bc_review_submit_label;

        if (getOneTimePaymentFlag()){
          this.state.hLinks[1].title = response.data.auth_bc_make_one_tp_label;
         } else if(getRecurringPaymentFlag() && !isManageRecurring()){
          this.state.hLinks[1].title = response.data.auth_bc_setup_auto_pay_label;
        } else if(isManageRecurring()) {
          this.state.hLinks[1].title = response.data.auth_bc_manage_set_label;
        }
				this.setState({
          banner_text: window.innerWidth < 770 ? response.data.auth_banner_text_mobile : response.data.banner_text,
          remember_pay_method: response.data.auth_remember_pay_method,
          back_btn:  response.data.auth_back_review_link_text,
          header_sub_section: response.data.header_sub_section,
          pymt_dtls_lbl: response.data.pymt_dtls_lbl,
          change_details_lbl: response.data.auth_change_details_lbl,
          card_header_lbl: response.data.card_header_lbl,
          plan_label: response.data.plan_label,
          name_label: response.data.name_label,
          id_label: response.data.id_label,
          details_label: response.data.details_label,
          auth_setup_pay_text: response.data.auth_setup_pay_text,
          auth_manage_pay_text: response.data.auth_manage_pay_text,
          auth_onetime_pay_text: response.data.auth_onetime_pay_text,
          auth_manage_recurring_text: response.data.auth_manage_recurring_text,
          auth_cancel_recurring_text: response.data.auth_cancel_recurring_text,
          auth_new_text: response.data.auth_new_text,
          total_label: response.data.total_label,
          total_label_note: response.data.total_label_note,
          cr_total_label_note: response.data.cr_total_label_note,
          pay_agreement: response.data.pay_agreement,
          button_label: response.data.button_label,
          button_label_managerecurring: response.data.button_label_managerecurring,
          button_label_setuprecurring: response.data.button_label_setuprecurring,
          button_label_cancelrecurring: response.data.button_label_cancelrecurring,
          elec_pay_modal: elec_pay_modal,
          cancelPaymentModal:cancelPaymentModal,
          eftDataError: response.data.efterror,
          auth_payment_effective_date: response.data.auth_payment_effective_date,
          auth_acknowledge_header: response.data.auth_acknowledge_header,
          auth_total_note: response.data.auth_total_note,
          auth_total_desc_setup_pay: response.data.auth_total_desc_setup_pay,
          auth_total_desc_isb_setup_pay :response.data.auth_total_desc_isb_setup_pay,
          auth_total_desc_setup_pay_fed: response.data.auth_total_desc_setup_pay_fed,
          auth_setup_pay_total_note: response.data.auth_setup_pay_total_note,
          auth_new_eft_check_label: response.data.auth_new_eft_check_label,
          auth_eft_checking_pay_label: response.data.auth_eft_checking_pay_label,
          cancel_btn_text: response.data.auth_cancel_btn_text,
          onetime_agreement_cc: getProductType() === 'SHIP' ? response.data.auth_agreement_onetime_cc_isb : response.data.auth_agreement_onetime_cc,
          onetime_agreement: response.data.auth_agreement_onetime,
          onetime_agreement_isb: response.data.auth_agreement_onetime_isb,
          auth_acknowledge_header_setuprecurring: response.data.auth_acknowledge_header_setuprecurring,
          auth_acknowledge_header_cancelrecurring:  response.data.auth_acknowledge_header_cancelrecurring,
          auth_acknowledge_header_managerecurring: response.data.auth_acknowledge_header_managerecurring,
          setuprecurring_agreement: getProductType() === 'SHIP' ? response.data.auth_agreement_setuprecurring_isb : response.data.auth_agreement_setuprecurring_fed,
          managerecurring_agreement: getProductType() === 'SHIP' ? response.data.auth_agreement_managerecurring : response.data.auth_agreement_managerecurring_fed,
          cancelrecurring_agreement: getProductType() === 'SHIP' ? response.data.auth_agreement_cancelrecurring : response.data.auth_agreement_cancelrecurring_fed,
          bc_payment_overview: response.data.auth_bc_pay_ov_label,
          bc_make_one_time: response.data.auth_bc_make_one_tp_label,
          bc_setup_auto_pay: response.data.auth_bc_setup_auto_pay_label,
          bc_manage_auto_pay: response.data.auth_bc_manage_set_label,
          bc_review_submit: response.data.auth_bc_review_submit_label,

          //Error messages
          error_required_param: response.data.error_required_param,
          error_householdId_not_active: response.data.error_householdId_not_active,
          error_unknown_data: response.data.error_unknown_data,
          error_sytem_error: response.data.error_sytem_error,
          error_system_error_title : response.data.error_system_error_title,
          error_eft_resubmission: response.data.error_eft_resubmission,
          error_eft_webservice: response.data.error_eft_webservice_submission,
          error_fed_resubmission : response.data.auth_error_fed_resubmission,
          formErrorDisplay : response.data.error_sytem_error,
          superUserErrorMsg: response.data.superUserErrorMsg,
          error_eft_eapr: response.data.error_eft_eapr,
          insufficient_funds_lbl: response.data.auth_ship_cc_insufficient_funds_lbl_isb,
          insufficient_funds_title: response.data.auth_ship_cc_insufficient_funds_title,
          auth_ship_payment_submission_blocked_title: response.data.auth_ship_payment_submission_blocked_title,
          auth_ship_payment_submission_blocked_msg: response.data.auth_ship_payment_submission_blocked_msg,
				});
			})
			.catch((error) => {
				this.setState({
					loadError: true,
					loading: false
				});
      });

      setTimeout(()=> {
        this.setState({spinnerLoading : false})
      }, 3000)
  }
 // AEM Feature flags
 callFeatureFlags() {
  try {
    aemAxios.get(content_path + '/feature-flags/memberpay-featureflags/jcr:content/data/master.json?timestamp=' + new Date().getTime())
          .then(response => {
              featureFlagsAem = response.data
              setFeatureFlags(featureFlagsAem);
          })
          .catch((error) => {
          })
  } catch (error) {
      // need to write error handing
  }
}

  modalFocus = () => {
    trackModalWindowClick( 'Electronic Payment Authorization');
    trackLinkClick('Electronic Payment Authorization');
    if(null != this.modalRef.current) {
      this.modalRef.current.focus()
    }
  }
  openCancelModal = () => {
    document.getElementById('cancelBtn').click();
  }

  redirectHandler = () => {
    this.setState({ redirect: true }, ()=> this.renderRedirect())
  }
  renderRedirect = () => {
    if (this.state.redirect) {
      secureLocalStorage.setItem('isPaymentRedesignPage',false);
      return (
        <Redirect
          to={{
            pathname: getOneTimePaymentFlag() ? '/make-payment' : '/recurring',
            state: {
              bankHolderName: this.state.bankHolderName,
              routingNumber: this.state.paymentMethod === 'EFT-lastUsed' ? this.state.routingNumber : '',
              accountNumber: this.state.paymentMethod === 'EFT-lastUsed' ? this.state.accountNumber : '',
              bankHolderFirstName: this.state.bankHolderFirstName,
              bankHolderMiddleName: this.state.bankHolderMiddleName,
              bankHolderLastName: this.state.bankHolderLastName,
              paymentMethod: this.state.paymentMethod === 'CC' ? 'CC' : this.state.paymentMethod,
              lastUsedEft: this.state.lastUsedEft,
              payAmount: this.state.payAmount.replace(',', ''),
              paidAmount: this.state.payAmount.replace(',', ''),
              fromPage: this.state.fromPage,
              pastAmountDueChecked: this.state.pastAmountDueChecked,
              pastAmountAndCurentChecked: this.state.pastAmountAndCurentChecked,
              onlyCurrentChargesChecked: this.state.onlyCurrentChargesChecked,
              payInFullChecked: this.state.payInFullChecked,
              otherAmountChecked: this.state.otherAmountChecked,
              pageLoadViaFlow: true,
              cancelRecChecked : this.state.cancelRecChecked,
              newEftRecChecked : this.state.newEftRecChecked,
              payWithSavedCC : this.state.payWithSavedCC,
              ccFlow : this.state.ccFlow,
              paymentsMethodValue: this.props.location.state.paymentsMethodValue,
              totalAmountDue: this.props.location.state.totalAmountDue,
            },
          }}
        />
      )
    }
  }

  confirmAndPayHandler = () => {
    this.setState({spinnerLoading : true});
    if (this.state.superUser) {
           this.setState ({superUserError:true});
          }
    else {
      let planInformation  = getPlanInformation();
      this.setState({ callTriggered: true });
        window.scrollTo(0,0);
          if(planInformation.productType === 'SHIP' && this.state.cancelRecChecked === false && !this.state.ccFlow) {
            this.ShipEFTPaymentHandler();
          } else if(planInformation.productType === 'SHIP' && this.state.ccFlow) {
            this.cCPostPaymentResultsHandler();
          } else if (planInformation.productType === 'SHIP' && this.state.cancelRecChecked === true) {
            this.cancelSHIPPaymentsHandler();
          }
          
          setTimeout(() => {
            if(this.state.formErrorDisplay && !this.state.callTriggered){
            }
          },3000)
        }
  }


  cancelSHIPPaymentsHandler = () => {
    let isbPlanInformation = getIsbPLanInformation();
    trackLinkClick(this.state.cancelRecChecked ? this.state.button_label_cancelrecurring : 'Accept');
    this.setState({ callTriggered: true });
    var requestData = {
      individualId: isbPlanInformation.individualId,
      currentDate: this.state.currentDate,
      userName: this.state.userName,
      planCategory: isbPlanInformation.planCategory,
      productBrand: isbPlanInformation.brand,
      memberId: isbPlanInformation.memberNumber,
      paymentDate: this.state.paymentDt,
      memberFirstname : this.state.firstName,
      memberLastname : this.state.lastName,
      accountAutoPayId:  secureLocalStorage.getItem("accountAutoPayId")
    }
      eftCancelPaymentSubmission(requestData)
      .then((resp) => {
        this.setState({ formError: false });
      if(resp.status && resp.status === 200) {
            if (resp.data?.response?.confirmationNumber !== null) {
              this.setState({
                formSuccess: true,
                formValidated: true,
                submitFilureFlag: false
              })
            }
            trackError(getOneTimePaymentFlag() ? 'make a one time payment - review and submit' : isManageRecurring() ? 'manage recurring  payment - review and submit' :
                'set up recurring payment - review and submit', 'Modal cancel button','Modal cancel button error','User has selected "cancel" on the Edit Recurring Payment Modal');
      }
      }).catch(error => {

          if (error?.response?.data && error?.response?.data?.serverMessage?.messageText) {

              this.setState({ formError: true,callTriggered : false,spinnerLoading : false, formErrorDisplay: `<b>${this.state.error_system_error_title}</b> ${this.state.error_sytem_error}`});
              window.scrollTo(0, 0);
              trackError(getOneTimePaymentFlag() ? 'make a one time payment - review and submit' : isManageRecurring() ? 'manage recurring  payment - review and submit' :
                  'set up recurring payment - review and submit', 'Submit','Webservice Failure error','Webservice Failure');
              logger.info("cancelRecurringForSHIP error response status: "+ error?.response?.status + " and message:" + error?.response?.data?.serverMessage?.messageText); 
            } else {
              this.setState({ formError: true,callTriggered : false,spinnerLoading : false, formErrorDisplay: `<b>${this.state.error_system_error_title}</b> ${this.state.error_sytem_error}`});
              window.scrollTo(0, 0);
              trackError(getOneTimePaymentFlag() ? 'make a one time payment - review and submit' : isManageRecurring() ? 'manage recurring  payment - review and submit' :
                  'set up recurring payment - review and submit', 'Submit','Webservice Failure error','Webservice Failure');
              logger.info("cancelRecurringForSHIP error response is null: ");
            }

      });
  }
shipCCPaymentPostCompass = async (saleResp)=> {
  let planInformation  = getPlanInformation();
  let paymentInd = 'N';
  let transactionD = null;
  let transId = null;

  this.saleCompassAPIReturnObj.saleCalled = true;

  if(saleResp.success){
    if (saleResp !== null && saleResp.success && saleResp.data !== null && saleResp.data.responseCode !==null && saleResp.data.responseCode.charAt(0) === "A" && 
    saleResp.data.transactionId !=null) {
      paymentInd = 'Y';
      this.saleCompassAPIReturnObj.saleSuccess = true;
      transId = saleResp.data.transactionId;
    } else {
      this.saleCompassAPIReturnObj.saleSuccess = false;
      transId = saleResp.data.transactionId;
    }

    if(saleResp.data.transactionDate == null || saleResp.data.transactionDate === '')
    {
      transactionD = null;
    } else{
      
      transactionD= Moment(saleResp.data.transactionDate).format('YYYY-MM-DD');
      console.log("initial date inside else"+transactionD);  
      //this.state.eaipTransactionDate=transactionD;
      //updated eaipTransactionDate state value correctly
      this.setState({eaipTransactionDate:transactionD});
      console.log("EAIP date "+transactionD);    
      transactionD=transactionD+'-00.00.00';
                    
    }
  } else{
    this.saleCompassAPIReturnObj.saleSuccess = false;
  }

    trackLinkClick(this.state.cancelRecChecked ? this.state.button_label_cancelrecurring:'Accept');
     var requestData = {
      insuredPlanId: planInformation.insuredPlanId,
      transactionId: transId,
      memberId: planInformation.memberNumber,
      transactionDateTime: transactionD,
      memberFirstname: this.state.firstName,
      memberLastname: this.state.lastName,
      ccBillingId: this.state.token,
      ccNumberLastFour: this.state.cardNumber,
      ccExpiration: this.state.expirationDate,
      expiration: this.state.expirationDate,
      numberLastFour: this.state.cardNumber,
      nameOnCard: this.state.cardHolderName,
      creditCardType: this.state.cardType,
      creditCardTypeId: this.state.cardTypeId,
      individualId: planInformation.individualId,
      payAmount: this.state.payAmount ? parseFloat(this.state.payAmount.replace(/\$/g,'').replace(/,/g,'')).toFixed(2) : '00.00',
      paymentUpdateType: "",
      keepCardOnFileInd: "",
      userName: JSON.parse(localStorage.getItem('consumerDetails')).userName,
      planCategory: planInformation.planCategory,
      productBrand: planInformation.brand,
      paymentDate: this.state.paymentDt,
      oneTimeAmountOverrideInd: "O",
      currentDate: this.state.currentDate,
      successfulPaymentIndicator : paymentInd
    }


    if(getOneTimePaymentFlag()) {
      requestData.paymentFrequencyTypeId="2";
      requestData.paymentFrequencyType="ONE-TIME";
    }
  if(this.saleCompassAPIReturnObj.saleSuccess){
    ccOnetimePaymentSubmission(requestData).then(res=> {
      const upgRequest={
        token : this.state.token,
        transactionId : this.state.transactionId,
        upgAccountId : this.state.upgAccountId,
        uuid: this.state.userName,
      }
      this.saleCompassAPIReturnObj.compassCalled = true
      if(res.status &&  res.status === 200) {
        this.saleCompassAPIReturnObj.compassSuccess = true
      }
      
      if (this.saleCompassAPIReturnObj.compassSuccess)  {
        if(res && res.status && res.status === 200 && res.data.response?.confirmationNumber !== null){
          secureLocalStorage.setItem("confirmationNumber", res.data.response.confirmationNumber)
        }
        const resp = unTokenizeToken(upgRequest, true);
          if(resp)
            console.log("upg token deleted successfully from database ");
          else
            console.log("upg token unable to delete from database ");

        this.setState({
          formSuccess: true,
          formErrorDisplay: commonConstants.PAYMENT_SUBMITTED,
          formValidated: true
        })
        
      } else {
          this.setState({ formError: true,callTriggered : false, submitAutomaticFailure: false ,spinnerLoading : false,formErrorDisplay: `<b>${this.state.error_system_error_title}</b> ${this.state.error_sytem_error}`});
          window.scrollTo(0, 0);
      }
    })
    .catch(err=> {
      this.saleCompassAPIReturnObj.compassCalled = true
      if (saleResp.data?.providerResponseMessage?.indexOf('insufficient funds') > -1 && saleResp.data?.responseCode.charAt(0) !=="A") {
        this.setState({ formError: true,callTriggered : false, submitAutomaticFailure: false ,spinnerLoading : false,formErrorDisplay: `<b>${this.state.insufficient_funds_title}</b> ${this.state.insufficient_funds_lbl}`});
        window.scrollTo(0, 0);
      } else {
        this.setState({formError: true, callTriggered : false,spinnerLoading : false, formErrorDisplay:`<b>${this.state.error_system_error_title}</b> ${this.state.error_sytem_error}`})
        window.scroll(0,0)
      }
    })

  } else {
    if (saleResp.data?.providerResponseMessage?.indexOf('insufficient funds') > -1 && saleResp.data?.responseCode.charAt(0) !=="A") {
      this.setState({ formError: true,callTriggered : false, submitAutomaticFailure: false ,spinnerLoading : false,formErrorDisplay: `<b>${this.state.insufficient_funds_title}</b> ${this.state.insufficient_funds_lbl}`});
      window.scrollTo(0, 0);
    } else {
      this.setState({ formError: true,callTriggered : false, submitAutomaticFailure: false ,spinnerLoading : false,formErrorDisplay: `<b>${this.state.error_system_error_title}</b> ${this.state.error_sytem_error}`});
      window.scrollTo(0, 0);
    }
  }
}

deleteUpgToken = async (upgRequest)=> {
  const response = await deleteTokenAPI(upgRequest);
return response;
}
  isShipCCErrorOccured = ()=> {
    if(getProductType() === 'SHIP' && this.state.ccFlow && ((this.saleCompassAPIReturnObj.saleCalled && !this.saleCompassAPIReturnObj.saleSuccess) || (this.saleCompassAPIReturnObj.saleCalled && 
      this.saleCompassAPIReturnObj.compassCalled && !this.saleCompassAPIReturnObj.compassSuccess))) {
        return true
    }
    return false
  }

  cCPostPaymentResultsHandler = () => {
    if(this.isShipCCErrorOccured()) {
        this.setState({ callTriggered: false , spinnerLoading : false });
        window.scroll(0,0)
        return
    }
    let planInformation  = getPlanInformation();
    this.setState({ callTriggered: true });
    // const upgRequest={
    //   token : this.state.token,
    //   transactionId : this.state.transactionId,
    //   upgAccountId : this.state.upgAccountId
    // }
    var requestData = {
      token: this.state.token,
      amount: this.state.payAmount.replace(/\$/g,'').replace(/[.,\s]/g, ''),
      customerAccountCode:planInformation.memberNumber,
      customerAccountInternalCode:planInformation.memberNumber,
      individualId:planInformation.individualId
    }
   
  saleTransactionCCPostPaymentResults(requestData)
    .then((resp) => {
      console.log(resp);
      this.shipCCPaymentPostCompass(resp)
    })

}

  ShipEFTPaymentHandler = () => {
    trackLinkClick(this.state.cancelRecChecked ? this.state.button_label_cancelrecurring:'Accept');
    let isbPlanInformation = getIsbPLanInformation();
    var requestData = {
      accountFirstname: this.state.bankHolderFirstName,
      accountMiddlename: this.state.bankHolderMiddleName,
      accountLastname: this.state.bankHolderLastName,
      bankNumber:  this.state.accountNumber,
      routingNumber: this.state.routingNumber,
      payAmount: this.state.payAmount ? parseFloat(this.state.payAmount.replace('$','')).toFixed(2) : '00.00',
      individualId: isbPlanInformation.individualId,
      paymentFrequencyTypeId: "",
      paymentUpdateType: "",
      userName: this.state.userName,
      planCategory: isbPlanInformation.planCategory,
      productBrand: isbPlanInformation.brand,
      memberId: isbPlanInformation.memberNumber,
      paymentDate: this.state.paymentDt,
      memberFirstname : this.state.firstName,
      memberLastname : this.state.lastName,
      accountStatusWrapper: secureLocalStorage.getItem('accountStatus'),
    }
    if( secureLocalStorage.getItem('oneTimePaymentFlag')) {
      requestData.paymentFrequencyTypeId="2";
      requestData.paymentUpdateType="ONETIME EFT";
    }
     if( secureLocalStorage.getItem('recurringPaymentFlag')) {
       requestData.paymentFrequencyTypeId="1";
       requestData.paymentUpdateType="UPDATE EFT";
       requestData.payAmount = "";
     }
  if(secureLocalStorage.getItem('oneTimePaymentFlag')) {
        eftOnetimePaymentSubmission(requestData)
          .then((resp) => {
            this.setState({ formError: false });
            if (resp.status && resp.status === 200 && resp.data.response) {
              secureLocalStorage.setItem("confirmationNumber", "Not available")
                  this.setState({
                    formSuccess: true,
                    formValidated: true,
                    submitFilureFlag: false
                  })
                  
                  if(resp.data.response.confirmationNumber !== null) {
                    secureLocalStorage.setItem("confirmationNumber" , resp.data.response.confirmationNumber);
                  }
                }
          }).catch(error => {
              if (error?.response?.data && error?.response?.data?.serverMessage?.messageText) {
                  this.setState({ formError: true,callTriggered : false, submitOneTimeFailure: true,spinnerLoading : false,formErrorDisplay:`<b>${this.state.error_system_error_title}</b> ${this.state.error_sytem_error}`});
                  logger.info("eftOnetimePaymentSubmission response status: "+  error?.response?.status);
                  window.scrollTo(0, 0);
              } else {
                  this.setState({ formError: true,callTriggered : false, submitOneTimeFailure: true,spinnerLoading : false,formErrorDisplay:`<b>${this.state.error_system_error_title}</b> ${this.state.error_sytem_error}`});
                  logger.info("eftOnetimePaymentSubmission response status: "+  error?.response?.status);
                  window.scrollTo(0, 0);
              }
        });
    }else if(secureLocalStorage.getItem('recurringPaymentFlag')){
      
      eftUpdatePaymentSubmission(requestData)
          .then((resp) => {
            this.setState({ formError: false });
            if (resp.status && resp.status === 200 && resp.data.response) {
              secureLocalStorage.setItem("confirmationNumber", "Not available")
                  this.setState({
                    formSuccess: true,
                    formValidated: true,
                    submitFilureFlag: false
                  })
                  
                  if(resp.data.response.confirmationNumber !== null) {
                    secureLocalStorage.setItem("confirmationNumber" , resp.data.response.confirmationNumber);
                  }
                }
          }).catch(error => {
              if (error?.response?.data && error?.response?.data?.serverMessage?.messageText) {
                  this.setState({ formError: true,callTriggered : false, submitOneTimeFailure: true,spinnerLoading : false,formErrorDisplay:`<b>${this.state.error_system_error_title}</b> ${this.state.error_sytem_error}`});
                  logger.info("eftUpdatePaymentSubmission response status: "+  error?.response?.status);
                  window.scrollTo(0, 0);
              } else {
                  this.setState({ formError: true,callTriggered : false, submitOneTimeFailure: true,spinnerLoading : false,formErrorDisplay:`<b>${this.state.error_system_error_title}</b> ${this.state.error_sytem_error}`});
                  logger.info("eftUpdatePaymentSubmission response status: "+  error?.response?.status);
                  window.scrollTo(0, 0);  
              }
        });

    }
  }
  toggleModal = () => {
    this.setState({ isOpen: !this.state.isOpen, focusOnModal: true })
  }

  displayErrorAlertOnSmallScreen = () => {
    return this.state.formError ? (
      <div className="xs-show d-print-none" id="TopAlertError" role="alert">
          <Notification notificationType="error" className="errorBanner">
              <Markup content={this.state.formErrorDisplay} />
          </Notification>      
      </div>
    ) : null
  }

  render(props) {

    let paymentMethod = null;
    let paymentDetails = null;
    let paymentAmountSection = null;


    if(getRecurringPaymentFlag() && !isManageRecurring() && this.state.cancelRecChecked === false) {
      paymentMethod = this.state.auth_setup_pay_text;
    }

    if(getRecurringPaymentFlag() && isManageRecurring() && this.state.cancelRecChecked === false) {
      paymentMethod = this.state.auth_manage_pay_text;
    }

    if (getOneTimePaymentFlag() && this.state.cancelRecChecked === false) {
      paymentMethod = <div>{this.state.auth_onetime_pay_text}</div>;
    }

    if(this.state.cancelRecChecked === true) {
      paymentDetails = <div>{this.state.auth_cancel_recurring_text}</div>
    }

    if (this.state.paymentMethod === "CC") {
      paymentDetails = <div>{this.state.cardType + ' ***' + this.state.cardNumber}</div>
      if(getRecurringPaymentFlag() && isManageRecurring()) {
        paymentDetails = <div>{this.state.auth_new_text + ' ' + this.state.cardType + '***' + this.state.cardNumber}</div>
      }
    }

    if (this.state.payWithSavedCC) {
      paymentDetails = <div>{getSearchPaymentMethodResult().type + '****' + getSearchPaymentMethodResult().numberLastFour}</div>
    }

    if(this.state.cancelRecChecked === false && this.state.paymentMethod === 'EFT' && getRecurringPaymentFlag() && isManageRecurring()) {

      paymentDetails =  <>
        <p className="pb-3 token-color-gray-dark-text-bold uhc-capitalize">{this.state.bankHolderFirstName}{' '}{this.state.bankHolderMiddleName}{' '}{this.state.bankHolderLastName}</p>
       <p className="token-color-gray-dark-text-bold"> {this.state.auth_new_eft_check_label}{this.state.accountNumber.substring(this.state.accountNumber.length-4)} </p>
      </>
    }

    if(this.state.cancelRecChecked === false && (this.state.paymentMethod === 'EFT' && getRecurringPaymentFlag() && !isManageRecurring()) || ((this.state.paymentMethod === 'EFT'|| this.state.paymentMethod === 'EFT-lastUsed') && secureLocalStorage.getItem('oneTimePaymentFlag')) ){
      paymentDetails = <>
        <p className="pb-3 token-color-gray-dark-text-bold uhc-capitalize">{this.state.bankHolderFirstName}{' '}{this.state.bankHolderMiddleName}{' '}{this.state.bankHolderLastName}</p>
        <p className="token-color-gray-dark-text-bold">{this.state.auth_eft_checking_pay_label}{this.state.accountNumber.substring(this.state.accountNumber.length-4)}</p>
      </>
    }



    if(getOneTimePaymentFlag() && !getRecurringPaymentFlag()) {

      paymentAmountSection = <div aria-live="polite" role="status">
      <ul className="row mt-5" >
      <li className="col-3">
        <span className="token-font-family-heading-three m-0 pad5 totalLabel">{this.state.total_label}</span>
      </li>
      <li className="col-9">
        <span className="paymentTotalAmt reviewTtl" style={{ fontSize: '36px' }}> ${this.state.payAmount ? convertToCurrency(this.state.payAmount.replace(/\$/g,'').replace(/,/g,'')) : '00.00'}</span>
      </li>
    </ul>
    </div>
    }

    if(!getOneTimePaymentFlag() && !isManageRecurring() && getProductType() === 'SHIP') {

      paymentAmountSection = <div aria-live="polite" role="status">
      <ul className="row mt-5">
        <li className="col-3">
        <span className="token-font-family-heading-three m-0 pad5 totalLabel">{this.state.total_label}</span>
        </li>
        <li className="col-9 total-padding">
        <span className="tds-header__h3" style={{ fontSize: '28px' }}> {this.state.auth_total_desc_isb_setup_pay}</span>
      </li>
      </ul>
    </div>
    }

    let body = (
  <div>
    {!(this.state.callTriggered && !this.state.formValidated) ? this.state.spinnerLoading ? <Spinner /> : null : null}
	<div className={(this.state.spinnerLoading ? ' text-blur' : '') }>
      <PageHeader links={this.state.hLinks} back_btn={this.state.back_btn} title={this.state.banner_text}
            plan={secureLocalStorage.getItem("shipPlanNamesList") && getProductType() === 'SHIP' ? secureLocalStorage.getItem('multiShipPlanType') : getPlanInformation().planType}
            />
      {this.displayErrorAlertOnSmallScreen()}
      <div className={'container'} id="reviewpage">
            <div className="row justify-content-center">
              <div className={secureLocalStorage.getItem("shipPlanNamesList") && getProductType() === 'SHIP' ? "col-sm-10" : "col-sm-9"}>
                {this.state.formError ? (
                  <div id="TopAlertError" className="xs-hide" role="alert">
                  <Notification notificationType="error" className="errorBanner">
                      <Markup content={this.state.formErrorDisplay} />
                  </Notification>
               </div>
                ) : (
                    <div></div>
                  )}

                <div className="uhc-tile-brd-wdgt-payment">
                  <div className={this.state.formError ? "tds-card tds-card--primary tds-card--border tds-bradius-bbtmnone mt-4" : "tds-card tds-card--primary tds-card--border tds-bradius-bbtmnone mt-6"}>
                    <div className="tds-card__content tds-card__content--border pb-0">
                      <div className="row justify-content-between payment-detail-card-header">
                        <div className="col-md-12 pl-0">
                        <Typography.H3 className="tds-header__h3 pt-2">
                        {this.state.card_header_lbl}
                        </Typography.H3>
                        </div>
                      </div>
                       <Typography.Paragraph className="pb-4">{this.state.header_sub_section}</Typography.Paragraph>
                    </div>
                  </div>

                  <div className="tds-card tds-card--primary tds-card--border">
                    <div className="tds-card__content tds-card__content--border payment-details-card mb-5" >
                    <div className="change-pay-mobile">
                        <button role="link" id= "change-details-icon-mobile" onClick={()=> {this.redirectHandler();trackLinkClick(!this.state.cancelRecChecked ? this.state.pymt_dtls_lbl : this.state.change_details_lbl)}}   className="tds-link tds-link--medium linkBtn" style= {{ display: 'flex'}}>
                          <span><img src={Edit} alt=""/></span> <span className="change-details-icon-span"> {!this.state.cancelRecChecked ? this.state.pymt_dtls_lbl : this.state.change_details_lbl}</span></button>
                           {this.renderRedirect()}
                    </div>
                    <div className="uhc-review-labels">
                      <ul className="row">
                        <li className="col-3">
                          <span>{this.state.plan_label}</span>
                        </li>
                        <li className={secureLocalStorage.getItem("shipPlanNamesList") && getProductType() === 'SHIP' ? "col-6 uhc-plan-mobile-row" : "col-5 uhc-plan-mobile-row"}>
                          <span className="token-color-gray-dark-text-bold">{this.state.planType}</span>
                        </li>
                        <li className={secureLocalStorage.getItem("shipPlanNamesList") && getProductType() === 'SHIP' ? "col-3 uhc-item-right-align" : "col-4 uhc-item-right-align"}>
                        <button role="link"  id= "change-details-icon" onClick={()=> {this.redirectHandler();trackLinkClick('Change card details')}}   className="tds-link tds-link--medium linkBtn" style= {{ display: 'flex'}}>
                          <span><img src={Edit} alt=""/></span> <span className="change-details-icon-span"> {!this.state.cancelRecChecked ? this.state.pymt_dtls_lbl : this.state.change_details_lbl}</span></button>
                           {this.renderRedirect()}
                       </li>
                      </ul>
                      <ul className="row mt-4">
                        <li className="col-3">
                          <span>
                          {this.state.name_label}
                          </span>
                        </li>
                        <li className="col-9">
                          <span className="token-color-gray-dark-text-bold">
                          {capitalizeVal(this.state.fullName)}
                          </span>
                        </li>
                      </ul>
                      <ul className="row mt-4">
                        <li className="col-3">
                          <span>
                            {this.state.id_label}
                          </span>
                        </li>
                        <li className="col-9">
                          <span className="token-color-gray-dark-text-bold">{this.state.memberId}</span>
                        </li>
                      </ul>
                      <ul className="row mt-4">
                        <li className="col-3">
                          <span>
                           {this.state.details_label}
                          </span>
                        </li>
                        <li className="col-9 paydetailsContent">
                          {!this.state.cancelRecChecked ? (
                              <span className="token-color-gray-dark-text-bold">{paymentMethod}</span>
                          ) : null}
                          <div className={!this.state.cancelRecChecked ? "pt-3 token-color-gray-dark-text-bold" : "token-color-gray-dark-text-bold"}>
                          {paymentDetails}
                          </div>
                          {getOneTimePaymentFlag() ? <span className="pt-3 token-color-gray-dark-text-bold" >{this.state.serverDate} </span>  :  !isManageRecurring() ? <span className="pt-3 token-color-gray-dark-text-bold" >{this.state.auth_payment_effective_date} </span> : null}

                          {this.state.ccFlow && (!getProductType() === 'SHIP' && getOneTimePaymentFlag())  ? (
                            <FormGroup
                            alignment="vertical"
                            aria-labelledby="saveCCCheckBox"
                            groupName="remembercard"
                            hideInstruction={false}
                            state="default"
                            className="mt-2"
                          >
                            <Checkbox
                            id="saveCCCheckBox"
                            label= {this.state.remember_pay_method}
                            value= {this.state.remember_pay_method}
                            onClick={()=> {this.setState({saveCreditCard : !this.state.saveCreditCard});trackLinkClick('remember this payment method')}}
                            className="uhc-lineheight-22 uhc-flex"
                          />
                          </FormGroup>
                          ) : null }

                        </li>
                      </ul>
                      </div>
                      {paymentAmountSection}
                    </div>
                  </div>
                  <div className="tds-card tds-card--secondary tds-card--border tds-tradius-topbordernone">
                    <div className="tds-card__content">
                      <div className="row">
                        <div id="electronicText">
                        <Typography.H4 className="mt-3">
                          {getOneTimePaymentFlag() ? this.state.auth_acknowledge_header : !this.state.cancelRecChecked && !isManageRecurring()?  this.state.auth_acknowledge_header_setuprecurring : isManageRecurring() && !this.state.cancelRecChecked ?
                             this.state.auth_acknowledge_header_managerecurring : this.state.auth_acknowledge_header_cancelrecurring}
                       </Typography.H4>
                              {getOneTimePaymentFlag() ? ((this.state.paymentMethod === 'EFT-lastUsed' || this.state.paymentMethod === 'EFT') ? <Markup content={this.state.onetime_agreement_isb} /> : this.state.paymentMethod === 'CC' ? <Markup content={this.state.onetime_agreement_cc} /> : <Markup content={this.state.onetime_agreement_isb} />) : !this.state.cancelRecChecked && !isManageRecurring() ? <Markup content={this.state.setuprecurring_agreement} />   :
                                  isManageRecurring() && !this.state.cancelRecChecked ? <Markup content={this.state.managerecurring_agreement} /> : <Markup content={this.state.cancelrecurring_agreement} />
                              }
                          </div>
                  <div className="row mt-4 mb-5 manage-buttons">
                        {this.state.cancelRecChecked ? (
                          <button
                          name="next"
                          aria-disabled="false"
                          describedby=""
                          className="tds-button tds-button--primary-one tds-margin-xsm-horizontal"
                          onClick={this.confirmAndPayHandler}
                        >
                          {this.state.button_label_cancelrecurring}
                        </button>
                      ) : !this.state.superUser &&  !this.isShipCCErrorOccured() ? (
                          <PaymentModals
                          modalContent={this.state.elec_pay_modal}
                          modalType='paymentAuth'
                          payMethod={this.state.paymentMethod}
                          payWithSavedCC={this.state.payWithSavedCC}
                          confirmAndPayHandler={this.confirmAndPayHandler}
                          submit_btn={getOneTimePaymentFlag() ? this.state.button_label : !this.state.cancelRecChecked && !isManageRecurring() ?  this.state.button_label_setuprecurring : isManageRecurring() ?
                                 this.state.button_label_managerecurring: this.state.cancelRecChecked ? this.state.button_label_cancelrecurring : null}
                          />):  (
                            <button
                            name="next"
                            aria-disabled="false"
                            describedby=""
                            className="tds-button tds-button--primary-one tds-margin-xsm-horizontal"
                            onClick={this.confirmAndPayHandler}
                          >
                            {getOneTimePaymentFlag() ? this.state.button_label : !this.state.cancelRecChecked && !isManageRecurring() ?  this.state.button_label_setuprecurring : isManageRecurring() ?
                                   this.state.button_label_managerecurring: this.state.cancelRecChecked ? this.state.button_label_cancelrecurring : null}
                          </button>
                        ) }

                        <PaymentModals
                          modalContent={this.state.cancelPaymentModal}
                          modalType='cancelPayment'
                          cancel_btn_txt={this.state.cancel_btn_text}
                        />
                      </div>
                      </div>
                    </div>
                  </div>
                  {this.state.superUserError &&
                    <div className='superUserError cc'>
                      <Notification notificationType="error" >
                        <Markup content={this.state.superUserErrorMsg}/>
                      </Notification>
                    </div>
                  }
                </div>
              </div>
            </div>
            {this.state.showSessionModal ?
                     <Redirect to="/service-error" push={true} />
                    : null}
          </div>
		  </div> </div>
        );
        if (this.state.callTriggered && !this.state.formValidated) {
              body = (
                <React.Fragment>
  
                  <Spinner />
                  {/* <Loader /> */}
                  <BodyWrapperBlurry>
                    {this.displayErrorAlertOnSmallScreen()}
                                  {body}
                  </BodyWrapperBlurry>
                </React.Fragment>
              );
            } else if(this.state.formValidated){
                 body = ( <Redirect
                              to={{
                                pathname: this.state.redirectForm,
                                state: {
                                  bankHolderName: this.state.bankHolderName,
                                  routingNumber: this.state.routingNumber,
                                  accountNumber: this.state.accountNumber,
                                  paymentMethod: this.state.paymentMethod,
                                  payAmount: this.state.payAmount.replace(',', ''),
                                  cardType: this.state.cardType,
                                  cardNumber: this.state.cardNumber,
                                  pageLoadViaFlow: this.state.pageLoadViaFlow,
                                  cancelRecChecked: this.state.cancelRecChecked,
                                  payWithSavedCC: this.state.payWithSavedCC,
                                  saveCreditCard: this.state.saveCreditCard,
                                  eaipTransactionDate: Moment(this.state.eaipTransactionDate).format('MM/DD/YYYY'),
                                  monthlyPaymentPremium: this.props.location.state.monthlyPaymentPremium,
                                  paymentsMethodValue: this.props.location.state.paymentsMethodValue,
                                  totalAmountDue: this.props.location.state.totalAmountDue,
                                },
                              }}
                />);
              }
              else{
                body =(
                  <React.Fragment>
                  {body}
                  </React.Fragment>
                );
              }
    return (
        <React.Fragment>
          {body}
      </React.Fragment>

      );
  }
}
const BodyWrapperBlurry = styled.div`
	filter: blur(8px);
	-webkit-filter: blur(8px);
`;
export default PaymentReview;
