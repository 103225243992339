import axios from 'axios';
import appConfigs from '../app.config';
import ucpPayments from './ucpPayments-api';

export const getLepEligibility = async (accountId) => {
    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        },

    }
	return axios.get(appConfigs.lep_eligibility_service_path + accountId, {}, axiosConfig).then(response =>{
            return response.data;
    }).catch((error) => {
        return {
            success: false,
            errorMsg: error.data
        };
    });
};