
export const routingNUmberValidator = (routingNumber) => {
    var l = routingNumber != null ? routingNumber.length : 0
    var rv = routingNumber
    var i,
      t = ''

    // Check the length, it should be nine digits.
    if (l == 9) {
      for (i = 0; i < l; i++) {
        var c = parseInt(rv.charAt(i), 10)
        if (c >= 0 && c <= 9) {
          t = t + c
        }
      }
      var n = 0
      // Now run through each digit and calculate the total.
      for (i = 0; i < l; i += 3) {
        n += parseInt(t.charAt(i), 10) * 3 + parseInt(t.charAt(i + 1), 10) * 7 + parseInt(t.charAt(i + 2), 10)
      }

      // If the resulting sum is an even multiple of ten (but not zero),
      // the currrent routing number is good.
      if (n !== 0 && n % 10 === 0) {
        return false
      }
    }
    return true
  }

  export const isValidAccountNumberAndRoutingNumber = (accountNumber, RoutingNumber) => {
    var isValidEftDetails = false;
    var previousEftAccountDetails = JSON.parse(localStorage.getItem('wrapperPojo'));
    if (previousEftAccountDetails != null && previousEftAccountDetails.length > 0) {
      previousEftAccountDetails.forEach((accountDetails) => {
        if (accountDetails.accountNumber == accountNumber.trim() &&
          accountDetails.routingNumber == RoutingNumber.trim()) {
          isValidEftDetails = true;
        }
      });
    }
    return isValidEftDetails;
  }