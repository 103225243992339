import getUUIDfromHSID from '../../server/api/getUUIDfromHSID-api';
import secureLocalStorage from "react-secure-storage";

export const getEftNumber = ()=> {
    return secureLocalStorage.getItem('eftNumber') !== 'null' ? secureLocalStorage.getItem('eftNumber') : '';
};

export const getPlanInformation = ()=> {
    return JSON.parse(secureLocalStorage.getItem('planInfomation'));
};

export const getIsbPLanInformation = () => {
    return JSON.parse(secureLocalStorage.getItem('isbPlanInformation'));
}

export const getProductType = ()=> {
    return getPlanInformation().productType;
}

export const getRecurringPaymentFlag = ()=> {
    return JSON.parse(secureLocalStorage.getItem('recurringPaymentFlag'));
};

export const getOneTimePaymentFlag = ()=> {
    return JSON.parse(secureLocalStorage.getItem('oneTimePaymentFlag'));
};

export const getMemberID = ()=> {
    return getPlanInformation().memberNumber
};

export const getMemberFirstName = ()=> {
    return secureLocalStorage.getItem('firstName')
};

export const getMemberMiddleName = ()=> {
    return secureLocalStorage.getItem('middleName')
};

export const getMemberLastName = ()=> {
    return secureLocalStorage.getItem('lastName')
};

export const getMemberFullName = ()=> {
    return getMemberFirstName()+ ' ' +getMemberMiddleName()+ ' '+ getMemberLastName()
};

export const getDashboardLink = ()=> {
    return document.querySelectorAll('[id="home_1"]').length > 0 ? document.querySelectorAll('[id="home_1"]')[0].href : document.querySelectorAll('[id="home_2"]').length > 0 ? document.querySelectorAll('[id="home_2"]')[0].href : "/"
}

export const getOverviewPageLink = ()=> {
    return window.location.origin.indexOf('localhost') > -1 ? window.location.origin : window.location.origin + '/medicare/payments'
}

export const getOneTimePageLink = ()=> {
    // let pathNameLastChar = (window.location.pathname && window.location.pathname.length > 0 ? window.location.pathname.charAt(window.location.pathname.length - 1) : "");
    return window.location.origin + window.location.pathname + (getProductType() === 'GOVT' ? '#/federal-make-payment' : '#/make-payment')
}

export const getRecurringPageLink = ()=> {
    // let pathNameLastChar = (window.location.pathname && window.location.pathname.length > 0 ? window.location.pathname.charAt(window.location.pathname.length - 1) : "");
    return window.location.origin + window.location.pathname + (getProductType() === 'GOVT' ? '#/federal-recurring' :'#/recurring')
}

export const getWalletPageLink = ()=> {
    // let pathNameLastChar = (window.location.pathname && window.location.pathname.length > 0 ? window.location.pathname.charAt(window.location.pathname.length - 1) : "");
    return window.location.origin + window.location.pathname + (getProductType() === 'GOVT' && '#/manage-wallet')
}

export const getPaymentMethod = ()=> {
    return secureLocalStorage.getItem('paymentsMethodValue') ? secureLocalStorage.getItem('paymentsMethodValue') : '';
}

export const getFuturePaymentMethod = ()=> {
    return secureLocalStorage.getItem('futurePaymentMethod') ? secureLocalStorage.getItem('futurePaymentMethod') : '';
}

export const isEditPayment = () => {
    if (secureLocalStorage.getItem('editPayment')) {
        return true;
    }
    return false;
}

export const isCancelPayment = () => {
    return secureLocalStorage.getItem('cancelPayment');
}

// export const cmsCancelCommittmentPeriod = () => {
//     return (moment().isBefore(secureLocalStorage.getItem('ssaStopDate')) && secureLocalStorage.getItem('ssaStopDate')) 
// }

//check recurring flag and one time flag before use this function
export const isManageRecurring = ()=> {
    let paymentsMethodValue = getPaymentMethod();
    
    //Not (‘EFT') && Not ‘CMS-SSA/RRB' && productType =='SHIP'
    if(paymentsMethodValue.indexOf('EFT') === -1 && paymentsMethodValue.indexOf('CMS-SSA/RRB') === -1
     && getProductType() === 'SHIP') {
        return false
    }
   //(‘EFT' || 'CMS-SSA/RRB') &&  productType =='SHIP'
    if((paymentsMethodValue.indexOf('EFT') > -1 || paymentsMethodValue.indexOf('CMS-SSA/RRB') > -1)
     && getProductType() === 'SHIP') {
        return true
    }
    // Govt Direct - Eft/Credit
    if(getProductType() === 'GOVT' && paymentsMethodValue.indexOf('EFT') === -1 && paymentsMethodValue.indexOf('CMS-SSA/RRB') === -1 && paymentsMethodValue.indexOf('Credit') === -1
      && (getFuturePaymentMethod().indexOf('EFT') > -1 || getFuturePaymentMethod().indexOf('Credit') > -1)) {
         return true
    }

    //(‘EFT' || 'CMS-SSA/RRB' || 'Credit’) && productType=='GOVT'   
    if((paymentsMethodValue.indexOf('EFT') > -1 || (paymentsMethodValue.indexOf('CMS-SSA/RRB') > -1 && (getFuturePaymentMethod().indexOf('EFT') > -1 || getFuturePaymentMethod().indexOf('Credit') > -1)) || paymentsMethodValue.indexOf('Credit') > -1)
     && getProductType() === 'GOVT') {
         return true
    }

    //Not ‘EFT'  && Not ‘CMS-SSA/RRB' && Not ‘Credit' && productType =='GOVT’
    if(paymentsMethodValue.indexOf('EFT') === -1 && paymentsMethodValue.indexOf('CMS-SSA/RRB') === -1 && paymentsMethodValue.indexOf('Credit') === -1
     && getProductType() === 'GOVT') {
         return false
    }
    return false;
}

export const isCurrentRecurring = ()=> {
    let paymentsMethodValue = getPaymentMethod();
    
   //(‘EFT' || 'CMS-SSA/RRB') &&  productType =='SHIP'
    if((paymentsMethodValue.indexOf('EFT') > -1 || paymentsMethodValue.indexOf('CMS-SSA/RRB') > -1)
     && getProductType() === 'SHIP') {
        return true
    }

    //(‘EFT' || 'CMS-SSA/RRB' || 'Credit’) && productType=='GOVT'
    if((paymentsMethodValue.indexOf('EFT') > -1 || (paymentsMethodValue.indexOf('CMS-SSA/RRB') > -1 && !secureLocalStorage.getItem('ssaStopDate')) || paymentsMethodValue.indexOf('Credit') > -1)
     && getProductType() === 'GOVT') {
         return true
    }
    return false;
}

export const getSearchPaymentMethodResult = ()=> {
    return JSON.parse(secureLocalStorage.getItem('searchPaymentMethodResult'));
}

export const getShipwrapperPojo = ()=> {
    return secureLocalStorage.getItem('shipwrapperPojo')
}
//add other getter here


//sso uuid from hsid 
export const getSSOValuefromHSID = async ()=> {
    const consumerData = JSON.parse(localStorage.getItem('consumerDetails'));
    console.log(consumerData.userName)
    if(sessionStorage.getItem('getUUIDfromHSID') !== null && sessionStorage.getItem('getUUIDfromHSID') !== ''){
        return JSON.parse(sessionStorage.getItem('getUUIDfromHSID'))
    } else {
        const ssoResponse = await getUUIDfromHSID(consumerData.userName);
        if(ssoResponse?.status === 403) {
            return window.location.assign(window.location.origin +'/medicare/content/medicare/member/logout.html');
        } else if (ssoResponse?.status !== 200 || (ssoResponse?.status === 200 && ssoResponse?.data.resources?.resource[0].userIdentificationData.userName.value.indexOf('HSID_SSO') > -1)) {
           sessionStorage.setItem('getUUIDfromHSID', true)
           return true
        } else {
            sessionStorage.setItem('getUUIDfromHSID', false)
            return false
        }
    }
}

export const setFeatureFlags = (featureFlagsAem) => {
    sessionStorage.setItem('isISBBanner' , featureFlagsAem.auth_isb_banner_enable)
    sessionStorage.setItem('isMFA' , featureFlagsAem.auth_mfa_enable)
    localStorage.setItem('isM3P', featureFlagsAem.auth_fed_m3p_banner_toggle)
}

export const getMFARedirectDomain = () =>{
    const consumerData = JSON.parse(localStorage.getItem('consumerDetails'));
    let currentLang = localStorage.getItem('MYUHC_LOCALE') ? localStorage.getItem('MYUHC_LOCALE') === 'en-US' ? 'en' : 'es' : 'en';
    var environment = window.location.origin;
    const host = window.location.hostname;
    const approvedHosts = ['localhost', 'team-ct-medicare.dev.werally.in', 'team-perf-medicare.dev.werally.in', 'stage-medicare', 'medicare.int.werally.in', 'member.int.uhc.com', 'member.uat.uhc.com', 'member.uhc.com']

    if (approvedHosts.includes(host)) {

            if ("AARP".startsWith(consumerData.displayBrandName)) {
            if (environment.indexOf('stage') > -1 || environment.indexOf('team') > -1 || environment.indexOf('int.werally') > -1 || (approvedHosts.includes(host) && environment.indexOf('int.uhc.com')) > -1) {
                return "https://st1.healthsafe-id.com/rt/ensure/aarp/"+currentLang;
                }
                else{
                    return "https://www.healthsafe-id.com/rt/ensure/aarp/"+currentLang;
                }

            } else if ("RETIREE".startsWith(consumerData.displayBrandName)) {
                if (environment.indexOf('stage') > -1 || environment.indexOf('team') > -1 || environment.indexOf('int.werally') > -1 || (approvedHosts.includes(host) && environment.indexOf('int.uhc.com')) > -1) {
                    return "https://st1.healthsafe-id.com/rt/ensure/retiree/"+currentLang;
                }
                else{
                    return "https://www.healthsafe-id.com/rt/ensure/retiree/"+currentLang;
                }
            }else if ("PCP".startsWith(consumerData.displayBrandName)) {
                if (environment.indexOf('stage') > -1 || environment.indexOf('team') > -1 || environment.indexOf('int.werally') > -1 || (approvedHosts.includes(host) && environment.indexOf('int.uhc.com')) > -1) {
                    return "https://st1.healthsafe-id.com/rt/ensure/pcp/"+currentLang;
                }
                else{
                    return "https://www.healthsafe-id.com/rt/ensure/pcp/"+currentLang;
                }
            }else if ("PCN".startsWith(consumerData.displayBrandName)) {
                if (environment.indexOf('stage') > -1 || environment.indexOf('team') > -1 || environment.indexOf('int.werally') > -1 || (approvedHosts.includes(host) && environment.indexOf('int.uhc.com')) > -1) {
                    return "https://st1.healthsafe-id.com/rt/ensure/pcn/"+currentLang;
                }
                else{
                    return "https://www.healthsafe-id.com/rt/ensure/pcn/"+currentLang;
                }
            } else {
                if (environment.indexOf('stage') > -1 || environment.indexOf('team') > -1 || environment.indexOf('int.werally') > -1 || (approvedHosts.includes(host) && environment.indexOf('int.uhc.com')) > -1) {
                    return "https://st1.healthsafe-id.com/rt/ensure/uhc/"+currentLang;
                }
                else{
                    return "https://www.healthsafe-id.com/rt/ensure/uhc/"+currentLang;
                }
            }
    }

}