import React, { Component } from 'react';
import PlanCard from './planCard.js';
import { Breadcrumbs, Link, Typography, Notification, Button} from '@uhc-tempo/components';
import QuickLinks from '../QuickLinks/QuickLinks';
import getConsumerDetails from '../../api/getConsumerDetails';
import AccountWalletIcon from '../../tokens/icons/ic_account_balance_wallet.png';
import CloseIcon from '../../tokens/icons/system-close.png';
import axios from '../../../server/api/aem-api';
import appConfigs from '../../../server/app.config';
import config from '../../../server/config';
import Spinner from '../../UI/Spinner/Spinner.js';
import { convertToCurrency } from '../../../server/util/util';
import { trackPageLoad, trackImpression, trackLinkClick} from '../../../server/util/analytics'
import { isDesktop } from '../../../server/util/util';
import {cancelPaymentErrSubject, cancelSSAErrSubject} from '../../../server/observableService';
import moment from 'moment';
import { Markup } from 'interweave';
import { getOverviewPageLink , getDashboardLink, setFeatureFlags} from '../../../server/util/localStorageRepo.js';
import { getLepEligibility } from '../../../server/api/lepEligibilityCheck-api.js';
import { serverCurrentTime } from '../../../server/api/serverTime-api';
import secureLocalStorage from 'react-secure-storage';
export let overviewSummAem = null;
export let overviewBillPayAem = null;
export let featureFlagsAem = null;

class paymentOverview extends Component {

    constructor(props) {
        super(props)
        getConsumerDetails()
        this.state = {
            govtAndShipPlanInfoList: [],
            shipPlanNamesList: [],
            fedShipPlanCount: 0,
            overviewSummAemRendered: false,
            overviewBillPayAemRendered: false,
            planData: null,
            showPromoBanner: false,
            showShipBanner: false,
            hideISBBanner: false,
            superUser: localStorage.getItem("superUser") === "true" ? true : false,
            superUserErr: false,
            cancelCmsPaymentError:false,
            lep_account_id: '',
            customerAccountSummary: null,
            isMFAInvalid:false,
            isMFA: false,
            billingPayments: null,
            premiumBreakDownServiceError: false,
            mfaValidation: false,
            isMFAAuthenticated: false,
            shipPlanDetailsList:[],
        }
        this.handleSuperUserErr = this.handleSuperUserErr.bind(this);
        this.closeISBBanner = this.closeISBBanner.bind(this);
        this.displayErrorMessage =this.displayErrorMessage.bind(this);
    }

    closeISBBanner() {
        this.setState({ hideISBBanner: true}, () => {
            sessionStorage.setItem('showISBBanner', true)
        })
    }
    displayErrorMessage (){
        this.setState({mfaServiceError : true});
    }
    componentDidMount() {
        console.log("canary with always....");
        this.callFeatureFlags();
        this.callAemAPISummary();
        this.callAemAPIBillPayment();
        secureLocalStorage.setItem('paymentFormData',null);
        secureLocalStorage.setItem('currPage','overview');
        secureLocalStorage.removeItem("confirmationNumber");
        secureLocalStorage.removeItem("confirmationNumber");
        secureLocalStorage.removeItem('currSysTimeInMs');
        serverCurrentTime().then((time) => {
            secureLocalStorage.setItem('currSysTimeInMs',time.systemtimeinmillis);									
         });

         let shipISB = sessionStorage.getItem('showISBBanner')
         if(shipISB){
            this.setState({ hideISBBanner: true})
         }

        this.getConsumerPlanProfile();
        if(secureLocalStorage.getItem('cancelCmsRecObj')){
            this.setState({cancelCmsRecObj : JSON.parse(secureLocalStorage.getItem('cancelCmsRecObj'))
            }) 
            secureLocalStorage.removeItem('cancelCmsRecObj')
        }
        
        
        if(secureLocalStorage.getItem('cancelPaymentObj')) {
            this.setState({cancelPaymentObj : JSON.parse(secureLocalStorage.getItem('cancelPaymentObj'))})
            secureLocalStorage.removeItem('cancelPaymentObj')
        }
        this.cancelPaymentSubscription = cancelPaymentErrSubject.subscribe(data=> {
            console.log('cancelPaymentSubscription default')
            if(data == 'cancelPaymentError') {
                this.setState({cancelPaymentErr : true, cancelPaymentObj : null}, () => document.getElementById('alertbody_error').focus())
                console.log('error')
            }
        })

        this.cancelSSASubscription = cancelSSAErrSubject.subscribe(data=> {
            if(data === 'cancelCmsError') {
                this.setState({cancelCmsPaymentError : true, cancelCmsRecObj : null}, () => document.getElementById('alertbody_error').focus())
            }
        })
    }

    componentWillUnmount() {
        this.cancelPaymentSubscription.unsubscribe()
        this.cancelSSASubscription.unsubscribe()
    }

    // Content for Payment summary section
    callAemAPISummary() {
        try {
            axios.get(appConfigs.aem_base_url + config.REACT_APP_CONTENT_PATH + '/payments-overview-summary/payments-summary-content/jcr:content/data/master.json' + '?timestamp=' + new Date().getTime())
                .then(response => {
                    trackPageLoad('overview');
                    console.log('overview')
                    overviewSummAem = response.data
                    if (overviewSummAem) {
                        this.setState({ overviewSummAemRendered: true }, () => {
                            if (!JSON.parse(sessionStorage.getItem('shipPromoBanner')) || !JSON.parse(sessionStorage.getItem('shipSHIPBanner'))) {
                                this.getBusinessType();
                            }
                            const fedPlanFound = this.state.govtAndShipPlanInfoList.filter(plan => plan.productType === 'GOVT')
                            if ((this.state.lep_account_id !== '' && this.state.lep_account_id !== null) && (fedPlanFound?.length > 0 && fedPlanFound !== null && overviewSummAem?.auth_lep_banner_toggle)) {
                                this.checkLepEligibility()
                            }
                        })
                        trackImpression(this.state.cancelCmsRecObj && this.state.cancelCmsRecObj.success ? 
                            'confirmation:ssa/rrb deduction will be canceled':'')
                        secureLocalStorage.setItem("mfaLogOutHeader", overviewSummAem.auth_mfa_logout_header); 
                        secureLocalStorage.setItem("mfaLogOutContent", overviewSummAem.auth_mfa_logout_content);
                        secureLocalStorage.setItem("mfaLogoutTimer", overviewSummAem.auth_mfa_logout_timer)
                    }
                })
        } catch (error) {
            // need to write error handing
        }
    }


    // Content for Payment and Billing section
    callAemAPIBillPayment() {
        try {
            axios.get(appConfigs.aem_base_url + config.REACT_APP_CONTENT_PATH + '/payments-overview-history/payments-history-content/jcr:content/data/master.json' + '?timestamp=' + new Date().getTime())
                .then(response => {
                    overviewBillPayAem = response.data
                    if (overviewBillPayAem) {
                        this.setState({ overviewBillPayAemRendered: true })
                    }
                })
        } catch (error) {
            // need to write error handing
        }
    }

    // AEM Feature flags
    callFeatureFlags() {
        try {
            axios.get(appConfigs.aem_base_url + config.REACT_APP_CONTENT_PATH + '/feature-flags/memberpay-featureflags/jcr:content/data/master.json' + '?timestamp=' + new Date().getTime())
                .then(response => {
                    featureFlagsAem = response.data
                    setFeatureFlags(featureFlagsAem)
                })
                .catch((error) => {
                    
                })
            
        } catch (error) {
            // need to write error handing
        }
    }


    async checkLepEligibility() {
        console.log('Get lep eligibility service started.')
        const lepResponse = await getLepEligibility(this.state.lep_account_id);
        if (lepResponse?.statusInfo?.code === 200) {
            this.setState({ lepEligible: lepResponse?.lepEligible, showLepBanner: true })
        }
    }
    
    //Getting list of plans and plan data for member
    getConsumerPlanProfile() {
        let shipPlanCount = 0;
        let onlyShipCount = 0;
        let govtAndShipPlanInfoList = [];
        let shipPlanNamesList = [];
        let fedShipPlanCount = 0;
        let doPush = false;
        let nextYear = moment().startOf('year').add(1, 'year');
        let consumerDetails = JSON.parse(localStorage.getItem('consumerDetails'));
        if(JSON.stringify(consumerDetails).length < 10) {
            this.props.history.push('/service-error');
            return
        }
        let shipTemp = null
        let preEffective = consumerDetails.preEffective;
        let planProfiles = consumerDetails.planProfiles.sort((a, b) => a.businessType.localeCompare(b.businessType)).reverse()
        let pp = consumerDetails.planProfiles
        let shipPlanProfiles = consumerDetails.planProfiles.filter(el=> el.businessType === 'SHIP' && el.planStatus === 'ACTIVE').sort((a, b) => a.planCategory.localeCompare(b.planCategory));
        let FEDPlanFil = pp.filter(el=> el.businessType === 'GOVT' && el.planStatus === 'ACTIVE' && el.premiumPayment)
        let FEDPlans = []
        let lep_enc_accountId = '';
        let shipPlanDetailsList=[];
        if(preEffective && FEDPlanFil && FEDPlanFil.length > 0) {
            FEDPlanFil = FEDPlanFil.length > 1 ? FEDPlanFil.sort((a, b) => Date.parse(a.planStartDate) - Date.parse(b.planStartDate)) : FEDPlanFil
            if(FEDPlanFil.length > 1) {
                FEDPlans.push(FEDPlanFil[0])
                FEDPlanFil.shift()
            }
            if(FEDPlanFil && FEDPlanFil.length > 0) {
                FEDPlanFil.forEach(el=> {
                    if(FEDPlans.filter(elt=> elt.cms_Contract_Num == el.cms_Contract_Num && elt.pbp_Number == el.pbp_Number && elt.segmentId == el.segmentId).length == 0) {
                        FEDPlans.push(el) 
                    }
                })
            }
        }  
        shipPlanProfiles.forEach(planProfile => {
            if(onlyShipCount < 1) {
                onlyShipCount++;
                shipPlanDetailsList.push({"individualId": planProfile.enc_individualId,"productType": planProfile.businessType, "planCategory": planProfile.planCategory, "brand": planProfile.productBrand, "memberNumber": planProfile.memberNumber});
                console.log('shipPlanDetailsList: ', shipPlanDetailsList);
            }
        })
            planProfiles.forEach(planProfile => {
                if (planProfile.businessType === 'SHIP' && planProfile.planStatus === 'ACTIVE') {
                    shipPlanNamesList.push({ "planName": planProfile.planName,"planCode":planProfile.planCode, "planStartDate":planProfile.planStartDate});
                    if (shipPlanCount < 1) {
                        doPush = true
                        shipPlanCount++
                        if (planProfile.premiumPayment) {
                            fedShipPlanCount++
                        }
                    }
                } else if (planProfile.businessType === 'GOVT' && planProfile.planStatus === 'ACTIVE') {
                    doPush = true
                        if (planProfile.premiumPayment) {
                            fedShipPlanCount++
                        }
                    }
                if (doPush) {
                    govtAndShipPlanInfoList.push({
                        "planType": planProfile.planTypeName, "planName": planProfile.planName, "productType": planProfile.businessType,
                        "individualId": planProfile.enc_individualId, "accountId": planProfile.enc_accountId, "planCategory": planProfile.planCategory, "planStartDate": planProfile.planStartDate,
                        "planStatus": planProfile.planStatus, "memberNumber": planProfile.memberNumber, "accountIdAlt": planProfile.accountId, "premiumPayment": planProfile.premiumPayment,
                        "planCode": planProfile.planCode, "insuredPlanId": planProfile.insuredPlanId, "brand": planProfile.productBrand, "upgAccountId": planProfile.businessType === 'SHIP' ? '37007' : planProfile.upg_account_id, "planCategory": planProfile.planCategory.toLowerCase(),
                        "contractNo": planProfile.cms_Contract_Num,"pbp_Number":planProfile.pbp_Number,"segmentId":planProfile.segmentId,"premium":planProfile.premium,"employerGroupIndicator":planProfile.employerGroupIndicator
                    });
                    console.log("govtAndShipPlanInfoList: ",govtAndShipPlanInfoList);
                    doPush = false
                }
                if (planProfile?.planStatus === 'ACTIVE' && planProfile?.businessType === 'GOVT') {
                    if (planProfile.employerGroupIndicator === 'true') {
                        if (planProfile?.planCategory !== "MA" && planProfile?.planCategory !== "SSUP" && planProfile?.planCategory !== "SSP") {

                            lep_enc_accountId = planProfile?.enc_accountId;
                           
                        }
                    } else {
                        if (planProfile?.planCategory !== "MA") {

                            lep_enc_accountId = planProfile?.enc_accountId;
                           
                        }
                    }
                }
                this.setState({ lep_account_id: lep_enc_accountId })
            });
            try{
                if (preEffective && planProfiles.filter(el=> el.businessType === "GOVT" && el.planStatus === "ACTIVE").length > 1) {
                    shipTemp = govtAndShipPlanInfoList.filter(el=> el.productType === "SHIP")
                    govtAndShipPlanInfoList = [];
                    fedShipPlanCount = shipTemp && shipTemp.length > 0 ? 1 : 0;
                    if(shipTemp && shipTemp.length > 0) {
                        govtAndShipPlanInfoList.push(shipTemp[0])
                    }
                    FEDPlans.forEach(planProfile => {
                            govtAndShipPlanInfoList.push({
                                "planType": planProfile.planTypeName, "planName": planProfile.planName, "productType": planProfile.businessType,
                                "individualId": planProfile.enc_individualId, "accountId": planProfile.enc_accountId, "planCategory": planProfile.planCategory, "planStartDate": planProfile.planStartDate,
                                "planStatus": planProfile.planStatus, "memberNumber": planProfile.memberNumber, "accountIdAlt": planProfile.accountId, "premiumPayment": planProfile.premiumPayment,
                                "planCode": planProfile.planCode, "insuredPlanId": planProfile.insuredPlanId, "brand": planProfile.productBrand, "upgAccountId": planProfile.upg_account_id, "planCategory": planProfile.planCategory.toLowerCase(),
                                "contractNo": planProfile.cms_Contract_Num,"pbp_Number":planProfile.pbp_Number,"segmentId":planProfile.segmentId
                            });
                            fedShipPlanCount++;
                    })
                    
                }
            } catch(error) {
                this.props.history.push('/service-error');
                return
            }
        this.setState({
            govtAndShipPlanInfoList: govtAndShipPlanInfoList,
            shipPlanNamesList: shipPlanNamesList,
            fedShipPlanCount: fedShipPlanCount,
            shipPlanDetailsList: shipPlanDetailsList
        }, () => {
            secureLocalStorage.setItem('fedShipPlanCount', fedShipPlanCount)
            secureLocalStorage.setItem("firstName", consumerDetails.firstName ? consumerDetails.firstName : '')
            secureLocalStorage.setItem("middleName", consumerDetails.middleName ? consumerDetails.middleName : '')
            secureLocalStorage.setItem("lastName", consumerDetails.lastName ? consumerDetails.lastName : '')
            if(shipPlanNamesList.length > 1) {
                secureLocalStorage.setItem("shipPlanNamesList", JSON.stringify(shipPlanNamesList))
            }
        })
    }

    setPlanData = (val)=> {
        this.setState({planData: val})
    }
    setPremiumBreakDownServiceError =(val) =>{
        this.setState({premiumBreakDownServiceError : val})
    }
    handleSuperUserErr() {
        this.setState({ 
            superUserErr: true 
        }, () => {
            window.scrollTo(0,0)
        })
    }

    bannerActive(bannerStartDate, bannerEndDate) {
        let currentDate = moment();
        if (currentDate.isSameOrAfter(moment(bannerStartDate)) && currentDate.isSameOrBefore(moment(bannerEndDate))) {
            return true;
        }
        return false;
    }


    getBusinessType() {
        let ship, fed, combo;
        const businessTypesFound = this.state.govtAndShipPlanInfoList?.map(plan => {
            if (plan.productType === 'SHIP') {
                ship = true
            } else if (plan.productType === 'GOVT') {
                fed = true;
            } else {
                fed = false;
                ship = false;
            }
        })
        combo = ship === true && fed === true;
        if (combo) {
            if ((this.bannerActive(overviewSummAem.auth_mw_promo_banner_start, overviewSummAem.auth_mw_promo_banner_end))) {
                this.setState({ businessType: 'Fed'}, () => this.walletPromoBannerActive())
            } else if ((this.bannerActive(overviewSummAem.auth_ship_banner_start_date, overviewSummAem.auth_ship_banner_end_date))) {
                this.setState({ businessType: 'SHIP'}, () => this.shipBannerActive())
            }
        }
        else if (ship && fed !== true) { 
            if ((this.bannerActive(overviewSummAem.auth_ship_banner_start_date, overviewSummAem.auth_ship_banner_end_date) && JSON.parse(sessionStorage.getItem('showSHIPBanner') !== false))) {
            this.setState({ businessType: 'SHIP'},() => this.shipBannerActive())
            }
        }
        else if (fed) {
            if ((this.bannerActive(overviewSummAem.auth_mw_promo_banner_start, overviewSummAem.auth_mw_promo_banner_end) && JSON.parse(sessionStorage.getItem('showPromoBanner') !== false))) {
                this.setState({ businessType: 'Fed'}, () => this.walletPromoBannerActive())}
            }
    }

    displayWalletBanner() {
        return (
            isDesktop() ?
                <div className='walletPromoBanner' id="walletBannerNotification">
                    <div>
                        <img alt="" src={AccountWalletIcon} width="40" height="40" />
                    </div>
                    <div>
                        <Typography.H2>{overviewSummAem.auth_mw_promo_banner_header}</Typography.H2>
                        <Typography.Paragraph id="wallet_desc_id">{overviewSummAem.auth_mw_promo_banner}</Typography.Paragraph>
                    </div>
                    { overviewSummAem.auth_promo_banner_link && <Link href="javascript:" aria-describedby="wallet_desc_id" className="pbLink" onClick={this.focusManageWalletBtn}>{overviewSummAem.auth_mw_promo_banner_link_txt}</Link> }
                    <div class={ overviewSummAem.auth_promo_banner_link ? "pbCloseIcon linkActive" : "pbCloseIcon linkDisabled"}>
                        <Link noIcon href="javascript:" onClick={() => this.closePromoBanner()}> <img alt="close" src={CloseIcon}  className="promoBannerClose" /></Link>
                    </div>
                </div>
                :
                <div className='walletPromoBanner'>
                    <div>
                        <img alt="" src={AccountWalletIcon} width="40" height="40" />
                        <div>
                            <Typography.H2>{overviewSummAem.auth_mw_promo_banner_header}</Typography.H2>
                        </div>
                        <div>
                            <Link noIcon href="javascript:" onClick={() => this.closePromoBanner()}> <img alt="close" src={CloseIcon}  className="promoBannerClose" /></Link>
                        </div>
                    </div>
                    <div>
                    <Typography.Paragraph>{overviewSummAem.auth_mw_promo_banner}</Typography.Paragraph>
                    </div>
                    <div>
                    { overviewSummAem.auth_promo_banner_link && <Link href="javascript:" aria-describedby="wallet_desc_id" className="pbLink" onClick={this.focusManageWalletBtn}>{overviewSummAem.auth_mw_promo_banner_link_txt}</Link> }
                    </div>
                </div>
        )
    }

    displayShipBanner() {
        return (
            isDesktop() ?
                <div className={ overviewSummAem.auth_ship_banner_link_toggle ? 'shipBanner' : 'shipBanner linkDisable'} id="walletBannerNotification">
                    <div>
                        <Typography.H2>{overviewSummAem.auth_ship_banner_header}</Typography.H2>
                        <Typography.Paragraph id="wallet_desc_id">{overviewSummAem.auth_ship_banner_body}</Typography.Paragraph>
                    </div>
                    { overviewSummAem.auth_ship_banner_link_toggle && <Link href="javascript:" aria-describedby="wallet_desc_id" className="pbLink" onClick={this.focusManageWalletBtn}>{overviewSummAem.auth_ship_banner_link}</Link> }
                    <div className={ overviewSummAem.auth_ship_banner_link ? "pbCloseIcon linkActive" : "pbCloseIcon linkDisabled"}>
                        <Link noIcon href="javascript:" onClick={() => this.closeShipBanner()}> <img alt="close" src={CloseIcon}  className="promoBannerClose" /></Link>
                    </div>
                </div>
                :
                <div className={overviewSummAem.auth_ship_banner_link_toggle ? 'shipBanner' : 'shipBanner linkDisable'}>
                    <div>
                        <div>
                            <Typography.H2>{overviewSummAem.auth_ship_banner_header}</Typography.H2>
                        </div>
                        <div>
                            <Link noIcon href="javascript:" onClick={() => this.closeShipBanner()}> <img alt="close" src={CloseIcon}  className="promoBannerClose" /></Link>
                        </div>
                    </div>
                    <div>
                    <Typography.Paragraph>{overviewSummAem.auth_ship_banner_body}</Typography.Paragraph>
                    </div>
                    <div>
                    { overviewSummAem.auth_ship_banner_link_toggle && <Link href="javascript:" aria-describedby="wallet_desc_id" className="pbLink" onClick={this.focusManageWalletBtn}>{overviewSummAem.auth_ship_banner_link}</Link> }
                    </div>
                </div>
        )
    }

    walletPromoBannerActive() {
        let consumerDetails = JSON.parse(localStorage.getItem('consumerDetails'));
            if (consumerDetails.preEffective === false && JSON.parse(sessionStorage.getItem('showPromoBanner')) !== false && this.state.businessType === 'Fed') {
                    this.setState({ showPromoBanner: true, showShipBanner: false }, () => {
                        sessionStorage.setItem('showShipBanner', false);
                        sessionStorage.setItem('showPromoBanner', true)
                    })
                    if(document.getElementById('walletBannerNotification')?.getElementsByTagName('a')){
                        let walletBannerNotif1 = document.getElementById('walletBannerNotification')?.getElementsByTagName('a')[0];
                        let walletBannerNotif2 = document.getElementById('walletBannerNotification')?.getElementsByTagName('a')[1];
                        if(walletBannerNotif1){
                            walletBannerNotif1.removeAttribute('target');
                        } else if(walletBannerNotif2){
                            walletBannerNotif2.removeAttribute('target');
                        }

                     }
                    //Analytics impression call for new wallet promo banner
                     trackImpression(overviewSummAem.auth_mw_promo_banner_header) 
                     console.log(overviewSummAem.auth_mw_promo_banner_header);
                    return true;
            } else {
                this.setState({ showPromoBanner: false, showShipBanner: false }, () => {
                    sessionStorage.setItem('showShipBanner', false);
                    sessionStorage.setItem('showPromoBanner', false)
                })
            }
        return false;
    }

    shipBannerActive() {
        console.log('In ship banner')
        let consumerDetails;
        consumerDetails = JSON.parse(localStorage.getItem('consumerDetails'));
            if (this.state.businessType === 'SHIP') {
                console.log('showShipBanner')
                this.setState({ showShipBanner: true, showPromoBanner: false }, () => {
                    sessionStorage.setItem('showShipBanner', true);
                    sessionStorage.setItem('showPromoBanner', false)
                })
                return true;
            }  else {
                this.setState({ showPromoBanner: false, showShipBanner: false }, () => {
                    console.log('In error handling')
                    sessionStorage.setItem('showShipBanner', false);
                    sessionStorage.setItem('showPromoBanner', false)
                })
            }
    }

    closeShipBanner() {
        trackLinkClick(overviewSummAem.auth_mw_promo_banner_header+':close icon');
        this.setState({ showShipBanner: false}, () => {
            sessionStorage.setItem('showShipBanner', false)
        })
    }

    closePromoBanner() {
        trackLinkClick(overviewSummAem.auth_mw_promo_banner_header+':close icon');
        console.log(overviewSummAem.auth_mw_promo_banner_header+':close icon');
        this.setState({ showPromoBanner: false}, () => {
            sessionStorage.setItem('showPromoBanner', false)
        })
    }

    displayLepAttestationBanner() {
        console.log(window.location);
        return (
            <div className='bannerOverview overviewcontainer'>
                <Notification notificationType='warning' dismissClickHandler={() => this.closeLepAttestationBanner()} className='lepBanner'>
                    <div className='lepBannerBody'>
                        <Typography.Paragraph >
                            <div className='lepMessage'>
                                <div className='lepHeading'>
                                    <Markup content={overviewSummAem?.auth_lep_banner_msg_txt} />
                                </div>
                            </div>
                        </Typography.Paragraph>
                        <div>

                        </div>
                        <div className='lepBannerLink'>
                            <Button onClick={() => window.location.assign(overviewSummAem?.auth_lep_banner_url)}>
                                {overviewSummAem?.auth_attest_now_btn}
                            </Button>
                        </div>
                    </div>
                </Notification>
            </div>
        )
    }

    closeLepAttestationBanner() {
        this.setState({ showLepBanner: false })
    }

    focusManageWalletBtn = () => {
        trackLinkClick(overviewSummAem.auth_mw_promo_banner_header+':'+overviewSummAem.auth_mw_promo_banner_link_txt);
        console.log(overviewSummAem.auth_mw_promo_banner_header+':'+overviewSummAem.auth_mw_promo_banner_link_txt)
        let recSchIds = document.getElementById('rec_sch_id');
        let manageWalletBtnId = document.getElementById('mmw_0');
        if(document.getElementById('plan_0')){
            let walletBtnID = document.getElementById('plan_0');
            const y = walletBtnID.getBoundingClientRect().top + window.pageYOffset;
            if(this.state.showPromoBanner){
                if(isDesktop() && manageWalletBtnId){
                    setTimeout(function () {
                        walletBtnID.scrollIntoView({ top: y, behavior: "smooth" });
                    }, 100);
                    manageWalletBtnId.focus()
                } else{
                    setTimeout(function () {
                        recSchIds.scrollIntoView({ top: y, behavior: "smooth" });
                    }, 100);
                    manageWalletBtnId.focus()
                }
            }
        }
        
    }

    render() {

        if(this.state.overviewSummAemRendered && this.state.overviewBillPayAemRendered  && ((this.state.cancelPaymentObj && this.state.cancelPaymentObj.success) || (this.state.cancelCmsRecObj && this.state.cancelCmsRecObj.success)) ){
            if(document.getElementById('successPayCancel')){
                document.getElementById('successPayCancel').focus()
            }
        }

        return (
            <>
                {this.state.overviewSummAemRendered && this.state.overviewBillPayAemRendered ?
                    (<div id="paymentOverview" className="paymentOverview" ref={this.accordionClosedRef}>
                        {this.state.superUserErr && 
                        <div className="overviewcontainer bannerOverview" id="alertbody_error" tabIndex="0">
                            <Notification notificationType="error" id="superUserErr_Overview">
                                <Typography.Paragraph><span style={{fontWeight: 'bold'}}>{overviewSummAem.memberAuth_action_not_allowed} </span>{overviewSummAem.memberAuth_unauthorized_txt}</Typography.Paragraph>
                            </Notification>
                        </div>
                        }
                        {(this.state.lepEligible && this.state.showLepBanner  && overviewSummAem?.auth_lep_banner_toggle) && this.displayLepAttestationBanner() }
                        {this.state.cancelPaymentErr && <div className="overviewcontainer bannerOverview" id="alertbody_error" tabIndex="0">
                            <Notification
                                dismissClickHandler={() => this.setState({cancelPaymentErr: false})}
                                notificationType="error">
                                <Markup content= {overviewSummAem.auth_pay_cancel_error_text}/>
                                
                                <div className="makePaymentLink"><Button onClick={() => window.location.assign(getOverviewPageLink())}>Refresh</Button></div>
                            </Notification>
                            </div>}
                            {this.state.cancelPaymentObj && this.state.cancelPaymentObj.success && <div className="overviewcontainer bannerOverview" id="successPayCancel" tabIndex="0">
                            <Notification
                                dismissClickHandler={() => this.setState({cancelPaymentObj: null})}
                                notificationType="success">
                                <Markup content= {overviewSummAem.auth_pay_cancel_success_text.replace(/\$/g, '$'+convertToCurrency(this.state.cancelPaymentObj.amount))
                                                    .replace('date', moment(this.state.cancelPaymentObj.date, 'YYYY-MM-DD').format('MM/DD/YYYY'))}/>
                            </Notification>
                            </div>}
                            {this.state.cancelCmsPaymentError && <div className="overviewcontainer bannerOverview" id="alertbody_error" tabIndex="0">
                            <Notification
                                dismissClickHandler={() => this.setState({cancelCmsPaymentError: false})}
                                notificationType="error">
                                <Markup content= {overviewSummAem.auth_pay_cms_cancel_error_text}/>
                            </Notification>
                            </div>}
                            {this.state.mfaServiceError && <div className="overviewcontainer bannerOverview mwError" id="alertbody_error" tabIndex="0">
                            <Notification
                                notificationType="error">
                                <Markup content= {overviewSummAem.auth_mfa_error_text}/>
                            </Notification>
                            </div>}
                            {this.state.cancelCmsRecObj && this.state.cancelCmsRecObj.success && <div className="overviewcontainer bannerOverview" id="successPayCancel" tabIndex="0">
                            <Notification
                                dismissClickHandler={() => this.setState({cancelCmsRecObj: null})}
                                notificationType="success">
                                <Markup content= {(this.state.cancelCmsRecObj && this.state.cancelCmsRecObj.date) ? overviewSummAem.auth_pay_cms_active_cancel_success_text.replace('date', moment(this.state.cancelCmsRecObj.date, 'YYYY-MM-DD').format('MM/DD/YYYY'))
                                :overviewSummAem.auth_pay_cms_cancel_success_text}/>
                            </Notification>
                            </div>}
                            {this.state.premiumBreakDownServiceError && <div className="overviewcontainer bannerOverview" id="alertbody_error" tabIndex="0">
                            <Notification
                                dismissClickHandler={() => this.setState({premiumBreakDownServiceError: false})}
                                notificationType="error">
                                <Markup content= {overviewSummAem.auth_pay_premiumbreakdown_service_error_text}/>
                            </Notification>
                            </div>}
                        <div className={ this.state.showPromoBanner ? "pageOverviewHeader walletPromoBannerActive": this.state.showShipBanner ? 'pageOverviewHeader shipBannerActive' : "pageOverviewHeader"}>
                            <div className="container">
                                <Breadcrumbs aria-label="breadcrumb navigation">
                                    <Breadcrumbs.Breadcrumb id="home_gotop" href={getDashboardLink()}>{overviewSummAem.auth_bc_home_text}</Breadcrumbs.Breadcrumb>
                                    <Breadcrumbs.Breadcrumb >{overviewSummAem.auth_bc_prem_pay_text}</Breadcrumbs.Breadcrumb>
                                </Breadcrumbs>
                                <Typography.H1 id="globalContentIdForSkipLink">{overviewSummAem.auth_premium_pay_title_txt}</Typography.H1>
                            </div>

                        </div>
                        <div className="overviewcontainer">
                            { (this.state.businessType === 'Fed' && this.state.showPromoBanner) &&
                                this.displayWalletBanner()
                            }
                            {
                              (this.state.businessType === 'SHIP' && this.state.showShipBanner) &&
                              this.displayShipBanner()
                            }
                            <div className="quickLinksContainer">
                            <QuickLinks
                                {...this.props}
                                fedShipPlanCount={this.state.fedShipPlanCount}
                                govtAndShipPlanInfoList={this.state.govtAndShipPlanInfoList}
                                shipPlanNamesList={this.state.shipPlanNamesList}
                                planData={this.state.planData}>
                            </QuickLinks>
                             </div>
                        </div>
                        {this.state.govtAndShipPlanInfoList.filter(plan => plan.premiumPayment).map((plan, index) => (
                            <PlanCard
                                {...this.props}
                                key={index}
                                planNum={index}
                                shipPlanDetails={this.state.shipPlanDetailsList}
                                shipPlanNamesList={this.state.shipPlanNamesList}
                                fedShipPlanCount={this.state.fedShipPlanCount}
                                setPlanData = {this.setPlanData}
                                planData={this.state.planData}
                                plan={plan} 
                                superUser={this.state.superUser}
                                superUserErr={this.state.superUserErr}
                                handleSuperUserErr={this.handleSuperUserErr}
                                setPremiumBreakDownServiceError={this.setPremiumBreakDownServiceError}
                                lepEligible={this.state.lepEligible}
                                billingPayments={this.state.billingPayments}
                                hideISBBanner={this.state.hideISBBanner}
                                closeISBBanner={this.closeISBBanner}
                                isMFA = {this.state.isMFA}
                                isMFAInvalid = {this.state.isMFAInvalid}
                                displayErrorMessage ={this.displayErrorMessage}
                                memberFirstName={JSON.parse(localStorage.getItem('consumerDetails')).firstName}
                                memberMiddleName={JSON.parse(localStorage.getItem('consumerDetails')).middleName}
                                memberLastName={JSON.parse(localStorage.getItem('consumerDetails')).lastName}
                                />

                        ))}
                    </div>) : <Spinner />}
            </>)
    }
}

export default paymentOverview;
