import axios from './ucpPayments-api';
import appConfigs from '../app.config';
export const getInvoiceList = async (customerId, toDate, fromDate) => {
    var invoiceListRequest = {
        "invoiceList": {
          "request": {
            "billingCustomerId": "",
            "customerId": customerId ? customerId : '',
            "customerType": "COMPAS",
            "fromDate": fromDate ? fromDate : '',
            "toDate": toDate
          }
        }
      };


	return axios.post(appConfigs.invoice_list_service_path, invoiceListRequest)
            .then(response => {
                return response.data;
            }) 
        
		.catch((error) => {
            console.log(error.message);
            return {
                callFailed: true
            };
		});

};


export default getInvoiceList;