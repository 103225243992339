import React, { Component, Fragment } from 'react'
import { Typography, Select, Link, Pagination } from '@uhc-tempo/components';
import Table from '../Table/Table';
import { Markup } from 'interweave';
import { overviewBillPayAem } from '../paymentOverview/paymentOverview';
import { overviewSummAem } from '../paymentOverview/paymentOverview';
import PaymentModals from '../modals/PaymentModals';
import LocalPrintshop from '../../tokens/icons/local_printshop';
import PrintHistory from '../PrintHistory/PrintHistory';
import MobileHistoryTable from '../mobile/mobileHistoryTable/MobileHistoryTable';
import commonConstants from '../../../server/util/commonConstants';
import { isDesktop } from '../../../server/util/util';
import { trackLinkClick } from '../../../server/util/analytics'
import moment from 'moment';
import ServiceError from '../errors/ServiceError';
export class BillingHistory extends Component {

    constructor(props) {
        super(props);
        this.scrollTimeOut = null;
        this.state = {
            selectedValue: this.props.selectedValue,
            isOpen: false,
            recordsPerPage: 7,
            lastPostIndex: 0,
            firstPostIndex: 0,
            currentPosts: [],
            currentPage: this.props.currPage
        }

        this.refreshHistoryInfo = this.refreshHistoryInfo.bind(this);
        this.refreshIsbHistoryInfo = this.refreshIsbHistoryInfo.bind(this);
        this.toggleDropDown = this.toggleDropDown.bind(this);
        this.closeDropDown = this.closeDropDown.bind(this)
        this.changePage = this.changePage.bind(this);

    }

    componentWillUnmount() {
        this.props.setCurrentPage(this.state.currentPage, 'Billing')
    }

    toggleDropDown(e) {
        this.setState(prev => ({
            isOpen: !prev.isOpen
        }))
    }

    closeDropDown() {
        if (this.state.isOpen) {
            this.setState({ isOpen: false })
        }
    }

    updateCurrentRecords() {
        if (this.props.billHistoryData) {
            let currentPosts = this.props.billHistoryData.slice(this.state.firstPostIndex, this.state.lastPostIndex);
            this.setState({currentPosts: currentPosts });
        }
    }

    getFirstPost() {
        this.setState(
            {
                firstPostIndex: this.state.lastPostIndex - (isDesktop() ? commonConstants.DESKTOP_PAGINATION_RECORDS : commonConstants.MOBILE_PAGINATION_RECORDS)
            }, () => this.updateCurrentRecords())
    }

    getLastPost() {
        this.setState({
            lastPostIndex: this.state.currentPage * (isDesktop() ? commonConstants.DESKTOP_PAGINATION_RECORDS : commonConstants.MOBILE_PAGINATION_RECORDS)
        }, () => this.getFirstPost())
    }

    //Scroll after changing pages to top of table
    paginationScroll() {
        if (isDesktop()) {
            if (this.props.tabName === 'Billing') {
                setTimeout(() => {
                    this.scrollTimeOut =  document.getElementById('accordionContent_' + this.props.planNum).scrollIntoView({behavior: 'smooth', block:'start'});
                }, 250)
            }
        } else {
            setTimeout(() => {
                this.scrollTimeOut =  document.getElementById('billingTermID_' + this.props.planNum).scrollIntoView(true);
            }, 250)
        }
    }

    changePage(e, page) {
        this.setState({
            currentPage: page 
        }, () => {
            this.paginationScroll();
            this.getLastPost();
            this.closeDropDown()
        })
    }

    refreshIsbHistoryInfo(e) {
        let fromDate = ''
        let toDate = moment().format('YYYY-MM-DD')
        if (e.target.value === 'payment.history.search.ninety.days') {
            fromDate = moment().subtract(90, 'days').format('YYYY-MM-DD')
        } else if (e.target.value === 'payment.history.search.six.months') {
            fromDate = moment().subtract(6, 'months').format('YYYY-MM-DD')
        } else if (e.target.value === 'payment.history.search.twelve.months') {
            fromDate = ''
        } else if (e.target.value === 'payment.history.search.twentyfour.months') {
            fromDate = ''
        }
        trackLinkClick(e.target.value === 'payment.history.search.ninety.days' ? overviewBillPayAem.auth_last_90_days :
        e.target.value === 'payment.history.search.six.months' ? overviewBillPayAem.auth_last_6_months :
        e.target.value === 'payment.history.search.twelve.months' ? overviewBillPayAem.auth_last_12_months :
        e.target.value === 'payment.history.search.twentyfour.months' ? overviewBillPayAem.auth_last_24_months :
        e.target.value === 'payment.history.search.previous.year' ? overviewBillPayAem.auth_prev_cal_year : null);
        this.props.changeDateRange(true, 'Billing', 'historyTable', e.target.value);
        if(this.props.plan.productType === 'SHIP' && this.props.shipPlanDetails && this.props.shipPlanDetails.length > 0) {
            this.props.getIsbInvoiceList(this.props.shipPlanDetails[0]?.individualId, toDate, fromDate, e.target.value);
        }
        this.setState({ selectedValue: e.target.value })
    }

    refreshHistoryInfo(e) {
        trackLinkClick(e.target.value === 'payment.history.search.ninety.days' ? overviewBillPayAem.auth_last_90_days :
        e.target.value === 'payment.history.search.six.months' ? overviewBillPayAem.auth_last_6_months :
        e.target.value === 'payment.history.search.twelve.months' ? overviewBillPayAem.auth_last_12_months :
        e.target.value === 'payment.history.search.twentyfour.months' ? overviewBillPayAem.auth_last_24_months :
        e.target.value === 'payment.history.search.previous.year' ? overviewBillPayAem.auth_prev_cal_year : null);
        this.props.changeDateRange(true, 'Billing', 'historyTable', e.target.value);
        this.props.callHistoryApi(e.target.value, false);
        this.setState({ selectedValue: e.target.value })
    }

    billingterms = {
        header: overviewBillPayAem.auth_bill_understand_modal_header,
        desc: this.props.plan.productType === 'GOVT' ? overviewBillPayAem.auth_bill_understand_modal_content_fed_isb : overviewBillPayAem.auth_bill_understand_modal_content_ship_isb,
        btn_txt: overviewSummAem.auth_close_btn_text
    }

    pagination() {
        if (this.props.billHistoryData && (this.props.billHistoryData.length >
            (isDesktop() ? commonConstants.DESKTOP_PAGINATION_RECORDS : commonConstants.MOBILE_PAGINATION_RECORDS))) {
            return (
                <div className={isDesktop() ? "paginate-contact-row" : "mobile-paginate-contact-row"} id="pagination">
                    <Pagination
                        page={this.state.currentPage}
                        maxPage={Math.ceil(this.props.billHistoryData.length/commonConstants.DESKTOP_PAGINATION_RECORDS)}
                        onPageChange={this.changePage}
                    />
                </div>)
        } else {
            return (
                <Fragment>
                    <div className={isDesktop() ? "paginate-contact-row-empty" : "mobile-paginate-contact-row"}>
                        <div className="pagination">
                            <Typography.Paragraph>Page 1 of 1</Typography.Paragraph>
                        </div>
                    </div>
                </Fragment>
            )
        }
    }

    //Check to see if billing history data has changed after choose new date range
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.billHistoryData !== this.props.billHistoryData) {
            this.setState({ currentPosts: [], currentPage: 1, firstPostIndex: 0, lastPostIndex: 0 }, () => {
                clearTimeout(this.scrollTimeOut);
                this.getLastPost()
            })
            }
    }

    componentDidMount() {
        this.getLastPost();
    }

    printEvent() {
        trackLinkClick('print billing history');
        let mywindow = window.open('', 'PRINT', 'height=800,width=1200');
        let titleText = '<title>Print: My Billing History Details</title>'
        var styleCSS = '<style>p{font-size:14px !important; padding-bottom:0px !important}table{border-spacing: 0px;border-collapse: collapse;}table td, table th {padding: 10px; border: 1px solid #e0e0e0; max-width: 230px;}.hidden-lg {display: none!important;}table.table-responsive tr:not(:first-child) td { border-top: none;}table.table-responsive tr:not(:first-child):nth-child(2n) {background-color: #f5f5f5; -webkit-print-color-adjust: exact; color-adjust: exact;}table.table-responsive tr:not(:first-child):nth-child(2n+1) {background-color: #fff;} .para-space {line-height:0.5;}</style>'
        let content = '<?doctype><html><head>' + titleText + styleCSS + '</head><body>' + document.getElementById('printBillHistoryTable_' + this.props.planNum).innerHTML + '</body></html>'
        mywindow.document.write(content)
        setTimeout(function () {
            mywindow.document.close();
            mywindow.focus();
            mywindow.print();
        }, 5000);

        setTimeout(function () {
            mywindow.close();
        }, 5200);
    }

    getPrintDownloadUI() {
        if((this.props.plan.productType === 'SHIP') || (!this.props.searchPaymentHistoryError && !this.props.isbInvoiceError)) {
        return (
            <div className="exportLinks">
                <div className="exportLabel exportPrintLabel" id={'exportBillPrintLabel_' + this.props.planNum}>
                <button onClick={() => this.printEvent()} id={'printBilling_' + this.props.planNum} onKeyDown={this.handleKeyDownLink} className="tds-link tds-link--medium tds-typography__link" role="link"><LocalPrintshop /> <span>{overviewBillPayAem.auth_print_link}</span> </button>
                </div>
            </div>)
        }
    }

    getHistoryHeadingUI() {
        return (
            <Fragment>
                <Typography.H2>{overviewBillPayAem.auth_my_bill_hist_head}</Typography.H2>
                {this.props.plan.productType === 'SHIP' ? (
                    <Markup content={overviewBillPayAem.auth_my_bill_hist_notes} />
                ) :  this.props.currPaymentMethod?.indexOf('CMS-SSA/RRB') > -1 ? <Markup className="historyNotes" content={overviewBillPayAem.auth_my_bill_hist_notes_fed_cms_ssa}/> : <Markup className="historyNotes" content={overviewBillPayAem.auth_my_bill_hist_notes_fed}/>}
            </Fragment>)
    }

    getDateSelectorUI() {
        return (
            <Select onChange={this.props.plan.productType === 'SHIP' ? this.refreshIsbHistoryInfo : this.refreshHistoryInfo} aria-labelledby={'billingSelect_' + this.props.planNum} onKeyDown={this.handleKeySelLink} id={'billingSelect_' + this.props.planNum} value={this.state.selectedValue}>
                <Select.Option value="payment.history.search.ninety.days">{overviewBillPayAem.auth_last_90_days}</Select.Option>
                <Select.Option value="payment.history.search.six.months">{overviewBillPayAem.auth_last_6_months}</Select.Option>
                <Select.Option value="payment.history.search.twelve.months">{overviewBillPayAem.auth_last_12_months}</Select.Option>
                <Select.Option value="payment.history.search.twentyfour.months">{overviewBillPayAem.auth_last_24_months}</Select.Option>
            </Select>)
    }

    getBillingTermUI() {
        return (
            <div className="paymentterm">
                <PaymentModals
                    modalContent={this.billingterms}
                    planNum={this.props.planNum}
                    paymentTermLink={this.handleKeyTermLink}
                    modalType='billingterms'
                    billingtermslinktitle={overviewBillPayAem.auth_understand_bill_terms_link}
                />
            </div>
        )
    }
    // ---------------
    getDesktopTableHeadUI() {
        return (
            <Fragment>

                {
                    <div className="billingHistoryHeader">
                        <div className="historyHeading">
                            {this.getHistoryHeadingUI()}
                            {this.getBillingTermUI()}
                        </div>
                        <div className="printDownload">
                            {(this.props.billHistoryData && this.props.billHistoryData.length > 0) && this.getPrintDownloadUI()}
                            <div className="dateSelector">
                                {(this.props.billHistoryData && !this.props.planData?.isFirstBillGen && this.props.billHistoryData.length > 0 ) && <label htmlFor={'billingSelect_' + this.props.planNum} className="dateSelectorLabel">{overviewBillPayAem.auth_show_label}</label>}
                                {((this.props.billHistoryData && this.props.billHistoryData.length > 0) && !this.props.planData?.isFirstBillGen ) && this.getDateSelectorUI()}
                            </div>
                        </div>
                        {this.props.plan.productType === 'SHIP' ? 
                        <div className="billinghistorycontent">
                            <Markup content={overviewBillPayAem.auth_billing_history_content_isb}/>
                        </div>
                        : null
                        }
                    </div>
                }

            </Fragment>
        )
    }

    handleKeyDownLink = (event) => {
        if (!event.shiftKey && event.key === "Tab") {
            event.preventDefault();
            document.getElementById('billingTermID_' + this.props.planNum).removeAttribute('tabindex', '-1');
            document.getElementById('billingTermID_' + this.props.planNum).focus();
        }
    }

    handleKeyTermLink = (event) => {
        if (!event.shiftKey && event.key === "Tab") {
            event.preventDefault();
            document.getElementById('billingSelect_' + this.props.planNum).focus();
            document.getElementById('billingTermID_' + this.props.planNum).setAttribute('tabindex', '-1');
        }

        if (event.shiftKey && event.key === "Tab") {
            event.preventDefault();
            document.getElementById('printBilling_' + this.props.planNum).focus();
            document.getElementById('billingTermID_' + this.props.planNum).setAttribute('tabindex', '-1');
        }

    }

    handleKeySelLink = (event) => {
        if (event.shiftKey && event.key === "Tab" && document.getElementById('billingTermID_' + this.props.planNum)) {
            event.preventDefault();
            document.getElementById('billingTermID_' + this.props.planNum).focus();
            document.getElementById('billingTermID_' + this.props.planNum).setAttribute('tabindex', '-1');

        }

        if (event.shiftKey && event.key === "Tab" && document.getElementById('noBillingData_' + this.props.planNum)) {
            //event.preventDefault();
            document.getElementById('noBillingData_' + this.props.planNum).removeAttribute('tabindex', '0');
        }

    }

    getMobileTableHeadUI() {
        return (
            <Fragment>
            <div tabIndex={this.props.billHistoryData == null ? '-1' : '0'} role="tabpanel" aria-labelledby={'tabPayment_' + this.props.planNum} id={'paymentData_' + this.props.planNum}>
                <div className="billingHistoryHeader">
                    <div className="mobilePrintDownload">
                        {(this.props.billHistoryData && this.props.billHistoryData.length > 0) && this.getPrintDownloadUI()}
                    </div>
                    <div className="mobileHistoryHeading billing">
                        {this.getHistoryHeadingUI()}
                    </div>
                    {!this.props.planData?.isFirstBillGen &&
                    <div className="mobileDateSelector">
                        <label htmlFor={'paymentSelect_' + this.props.planNum} className="dateSelectorLabel">{overviewBillPayAem.auth_show_label}</label>
                        {this.getDateSelectorUI()}
                    </div>
                    }
                    <div className="mobileBillingterm" id="mobileBillingterm">
                        {this.getBillingTermUI()}
                    </div>
                    {this.props.plan.productType === 'SHIP' ? 
                        <div className="billinghistorycontent"> <Markup content={ overviewBillPayAem.auth_billing_history_content_isb}/></div> 
                        : null
                    }
                    {(this.props.plan.productType === 'SHIP') ?
                        <div className="totalAmtDueTooltipContent">
                            <Markup content={!isDesktop() ? overviewBillPayAem.auth_total_amt_due_mob_content : overviewBillPayAem.auth_total_amt_due_mob_content_fed}/>
                        </div>
                        : null
                    }
                    
                </div>
                
            </div>
            </Fragment>
        )
    }

    render() {
        return (
            <Fragment>
                <div role="tabpanel" aria-labelledby={'tabBilling_' + this.props.planNum} id={'billingData_' + this.props.planNum}>
                    {isDesktop() ? this.getDesktopTableHeadUI() : this.getMobileTableHeadUI()}
                    {this.props.billHistoryData ? isDesktop() ?
                        <Fragment>
                            <div className="historyTable">
                                <Table
                                    data={this.props.planData?.isFirstBillGen ? this.state.currentPosts.filter(bill => { return moment(bill.dueDate).isSame(moment(this.props.plan?.planStartDate))}) : this.state.currentPosts}
                                    history={'Billing'}
                                    planNum={this.props.planNum}
                                    loading={this.props.loadingHistTable}
                                    plan={this.props.plan} 
                                    desktopView={isDesktop()} 
                                    />
                            </div>
                        </Fragment>
                        :
                        <Fragment>
                            <div className="mobileHistoryTable billing" id={'mobileHistoryTable_' + this.props.planNum}>
                                <MobileHistoryTable
                                    data={this.props.planData?.isFirstBillGen ? this.state.currentPosts.filter(bill => { return moment(bill.dueDate).isSame(moment(this.props.plan?.planStartDate))}) : this.state.currentPosts}
                                    history={'Billing'}
                                    loading={this.props.loadingHistTable}
                                    plan={this.props.plan} 
                                    desktopView={isDesktop()} 
                                    />
                            </div>
                        </Fragment>
                        : 
                        isDesktop() ?
                            <Table
                                data={[]}
                                loading={this.props.loadingHistTable}
                                planNum={this.props.planNum}
                                plan={this.props.plan}
                            /> :
                            <MobileHistoryTable
                                data={[]}
                                loading={this.props.loadingHistTable}
                                plan={this.props.plan}
                            />
                    }
                    {(this.props.billHistoryData == null || (this.props.billHistoryData.length < 1))
                        ? (this.props.plan.productType === 'SHIP' && this.props.isbInvoiceError) ? 
                        <div className="isbOverviewContainer" id='isbErrorMessage'>
                            <div className="isbPlanContainer">
                                <ServiceError searchPaymentHistoryError={this.props.searchPaymentHistoryError} isbInvoiceError={this.props.isbInvoiceError}/>
                            </div>
                        </div> 
                        :
                        <div className="noDataPrintDownload" tabIndex="0" role="tabpanel" aria-labelledby={'tabBilling_' + this.props.planNum} id={'noBillingData_' + this.props.planNum}>
                                {isDesktop() &&
                                <div className={isDesktop() ? "noDataDateSelector billing" : "mobileDateSelector"}>
                                    <label htmlFor={'billingSelect_' + this.props.planNum} className="noDataDateSelectorLabel">{overviewBillPayAem.auth_show_label}</label>
                                    {this.getDateSelectorUI()}
                                </div>
                                }
                            {/* } */}
                            <div className="noHistory">
                                <Typography.Paragraph>
                                    {overviewBillPayAem.auth_no_premium_bill_avail_text}
                                </Typography.Paragraph>
                            </div>
                        </div>
                        :
                        this.pagination()
                    }
                    <div id={'printBillHistoryTable_' + this.props.planNum}>
                        <div className="printHistoryTable">
                            <PrintHistory 
                                className="printHistoryTable" 
                                tabName='Billing'
                                data={this.props.billHistoryData ? this.props.billHistoryData : []} 
                                plan={this.props.plan}
                                planNum={this.props.planNum}
                                selectedValue={this.state.selectedValue} 
                                billingPrintStartDate = {this.props.billingPrintStartDate}
                                />
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default BillingHistory
