import React, { Component, Fragment } from 'react'
import { Link, Typography } from '@uhc-tempo/components';
import './QuickLinks.css';
import { overviewSummAem } from '../paymentOverview/paymentOverview';
import { Markup } from 'interweave';
import recurringFlagIcon from '../../tokens/icons/recurringFlag_icon.png';
import paymentsWallet from '../../assets/images/paymentsWallet.png';
import umbrellaIcon from '../../assets/images/quickLinks_umbrella.png';
import moneyIcon from '../../assets/images/money_icon.png';
import historyIcon from '../../assets/images/paymentHistory_icon.png';
import downArrowIcon from '../../assets/images/downArrow_icon.png';
import { isDesktop } from '../../../server/util/util';
import { trackLinkClick } from '../../../server/util/analytics';
import { isIos } from '../shared-components/detectDevice';
import secureLocalStorage from 'react-secure-storage';

export let quickLinkHistoryButton = false;
export class QuickLinks extends Component {
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props)
    }

    scrollTo = (ele) => {
        ele.scrollIntoView(!isIos() ? {behavior: 'smooth'} : true)
        }

      //Jump link function to payment and billing history
    payment_billingScroll = () => {
        trackLinkClick('view payment and billing history')
        try {
            let accordionid = document.getElementById('accordionid_0');
            if (accordionid.getAttribute('aria-expanded') !== "true") {
                accordionid.click();
            }
            let tabPayment = document.getElementById('historyTabs_0');
            let paymentTabId = tabPayment.children[0].children[0].children[0].children[0].id
            let paymentTab = document.getElementById(paymentTabId);
            const y = tabPayment.getBoundingClientRect().top + window.pageYOffset;
            setTimeout(function () {
                tabPayment.scrollIntoView({ top: y, behavior: "smooth", block: "end", inline: "nearest"  });
            }, 100);
            quickLinkHistoryButton = true;
            paymentTab.focus();
            paymentTab.click();
            quickLinkHistoryButton = false;
        } catch (error) { }
    }

    getGovtLinkView =  () => {
        if(this.props.planData.isCMSRequested && this.props.planData.paymentMethod.indexOf('CMS') > -1) {
            return(
                <Fragment>
                        <li className="recurringStatus">
                            <img src={recurringFlagIcon} alt="" aria-hidden="true" />
                            <Typography.Paragraph><strong>{overviewSummAem.auth_ssa_rrb_requested_title}</strong></Typography.Paragraph>
                            
                        </li>
                    
                        <li>
                            <Markup content={overviewSummAem.auth_request_ssa_rrb_deduction_content}/>
                        </li>
                </Fragment>
            )
        } else if(this.props.planData.paymentMethod.indexOf('CMS') > -1 ) {
            // if CMS ON
            return(
                <Fragment>
                <li className="recurringStatus">
                    <img src={recurringFlagIcon} alt="" aria-hidden="true" />
                    <Typography.Paragraph>
                        <strong>{overviewSummAem.auth_ql_ssa_rrb_title}</strong>  
                        <span style={{ color: 'green', fontWeight: 'bold', paddingLeft: '5px' }}>
                            {overviewSummAem.auth_on_text}
                        </span> 
                    </Typography.Paragraph>
                </li>
                <li>
                    <Markup content={overviewSummAem.auth_request_ssa_rrb_deduction_content}/>
                </li>
            </Fragment>
            )
        }else if(this.props.planData.paymentMethod.indexOf('Direct') > -1 && this.props.planData.isCMSRequested) {
            //is CMS Requested but no future recurring request
            return(
                
                   <Fragment>
                        <li className="recurringStatus">
                            <img src={recurringFlagIcon} alt="" aria-hidden="true" />
                            <Typography.Paragraph><strong>{overviewSummAem.auth_ssa_rrb_requested_title}</strong></Typography.Paragraph>
                            
                        </li>
                    
                        <li>
                            <Markup content={overviewSummAem.auth_request_ssa_rrb_deduction_content}/>
                        </li>
                </Fragment>
            )
        
        } else if(this.props.planData.paymentMethod.indexOf('Direct') > -1 && !this.props.planData.isCMSRequested && !this.props.planData.isFuturePaymentMethod) {
            //Requesting SSA/RRB / Setup Recurring
            return(
                <Fragment>
                            <li className="recurringStatus">
                                <img src={recurringFlagIcon} alt="" aria-hidden="true" />
                                <Typography.Paragraph>{overviewSummAem.auth_rest_easy_knowing_banner_cnt} </Typography.Paragraph>
                                
                            </li>
                            <li>
                            <div className={isDesktop() ? 'recurringStatusSetup' : 'noMarginLeft recurringStatusSetupMob'}>
                                    <button className="tds-link tds-link--medium" role="link" onClick={() => {secureLocalStorage.setItem('IsFromquicklinks','true');document.getElementById('recurrinBtn_0').click()}}>
                                        <span>{overviewSummAem.auth_setup_recurring_pay_banner_link}</span>
                                        <span className="tds-link__icon-wrapper">⁠<svg className="MuiSvgIcon-root tds-utility-icon tds-link__icon--internal" focusable="false" viewBox="0 0 24 24" color="#196ecf" aria-hidden="true" role="img"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path></svg></span>
                                    </button>
                              {this.props.govtAndShipPlanInfoList[0].employerGroupIndicator == 'true' &&
                              this.props.govtAndShipPlanInfoList[0].premium?.employerSubsidy > 0 &&
                              (this.props.govtAndShipPlanInfoList[0].premium?.subsidyAdjustmentUnit == "%" || 
                              this.props.govtAndShipPlanInfoList[0].premium?.subsidyAdjustmentUnit == "$")
                                ? null : <button className="tds-link tds-link--medium" role="link" onClick={() => { secureLocalStorage.setItem('IsFromquicklinks', 'true'); document.getElementById('SSARRB_Btn_0').click() }}>
                                    <span>{overviewSummAem.auth_request_ssa_rrb_deduction_link}</span>
                                    <span className="tds-link__icon-wrapper">⁠<svg className="MuiSvgIcon-root tds-utility-icon tds-link__icon--internal" focusable="false" viewBox="0 0 24 24" color="#196ecf" aria-hidden="true" role="img"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path></svg></span>
                                </button>}    
                                    
                                </div>
                            </li>
                   </Fragment>
            )
        } else if((this.props.planData.paymentMethod.indexOf('EFT') > -1 || this.props.planData.paymentMethod.indexOf('Credit') > -1) && this.props.planData.isCMSRequested) {
            //is CMS Requested but no future recurring request
            return(
                
                   <Fragment>
                        <li className="recurringStatus">
                            <img src={recurringFlagIcon} alt="" aria-hidden="true" />
                            <Typography.Paragraph><strong>{overviewSummAem.auth_ssa_rrb_requested_title}</strong></Typography.Paragraph>
                            
                        </li>
                    
                        <li>
                            <Markup content={overviewSummAem.auth_request_ssa_rrb_deduction_content}/>
                        </li>
                </Fragment>
            )
        
        } 
        
    }

    planScroll = (planNum) => {
        if (this.props.shipPlanNamesList.length > 1 && planNum === 0) {
            trackLinkClick(overviewSummAem.auth_ship_header_title)
        } 
        if (planNum === 1 || planNum === 2 || (this.props.shipPlanNamesList.length === 0 && planNum === 0) || (this.props.shipPlanNamesList.length === 1 && planNum === 0)) {
            trackLinkClick('View ' + this.props.govtAndShipPlanInfoList[planNum].planType);
        }
        let planCountElt = document.getElementById('plan_' + planNum)
        if (planCountElt) {
            this.scrollTo(planCountElt)
        }
    }

    recurringSchedledOn = () => {
        if (this.props.planData.isFuturePaymentMethod || (this.props.planData.paymentMethod.indexOf('CMS') > -1 && this.props.planData.isRec)) {
            return (
                <Fragment>
                    <Typography.H3 className="tds-typography__paragraph recurringPaymentText">
                    {overviewSummAem.auth_recurring_pay_scheduled}
                    </Typography.H3>
                </Fragment>
            )
        } else if(this.props.planData.isRec){
            return (
                <Fragment>
                    <Typography.H3 className="tds-typography__paragraph recurringPaymentText">
                    <span>{overviewSummAem.auth_ql_recurring_payment_title + ': '}</span>
                    <span style={{ color: 'green', fontWeight: 'bold' }}>
                        {overviewSummAem.auth_on_text}
                    </span>
                    </Typography.H3>
                </Fragment>
            )
        } 

    }

    shipDiscountInfo = () => {
            //Ship Recurring Modal
        let shipRecurringModalContent = {
            header: overviewSummAem.auth_get_discount_banner_text,
            desc: overviewSummAem.auth_ship_recurring_modal_content_isb,
            footnote: overviewSummAem.auth_ship_recurring_state_footnote,
            btn_txt: overviewSummAem.auth_close_btn_text,
            planNum: this.props.planNum
        }

        return (
           /*  <PaymentModals
                modalContent={shipRecurringModalContent}
                modalType='shipDiscount'
                managepaybtn={overviewSummAem.auth_setup_recurring_btn} 
                linkName = {overviewSummAem.auth_setup_recurring_pay_banner_link}
                /> */
                <button className="tds-link tds-link--medium smallTxt" onClick={()=> document.getElementById("shipDiscountId").click()}>
                      <span>{overviewSummAem.auth_view_details_pay_banner_link}</span>
                  </button>
        )
    }

    comboLinks = () => {
        return (
            <Fragment>
                {this.props.govtAndShipPlanInfoList ? this.props.govtAndShipPlanInfoList.map((plan, index) => (
                    <div className={
                        this.props.fedShipPlanCount > 2 ?
                            `quickLinksJump_${index} threecol` : (plan.planType === 'Medicare Supplement Insurance Plan' && index === 0 && isDesktop()) ? `quickLinksJump_${index} supPlan` : `quickLinksJump_${index} newSupPlan`
                    }
                    key={'quickLink_' + index}
                    >
                        <div>
                            <img src={umbrellaIcon} alt="" aria-hidden="true"/>
                            <button className="tds-link tds-link--medium" role="link" onClick={e => this.planScroll(index)}>
                                <span>  {this.props.shipPlanNamesList.length > 1 && plan.productType === "SHIP" ? "View " + overviewSummAem.auth_ship_header_title : `View ${plan.planType}`} </span>
                                <img src={downArrowIcon} className="downarrow" alt="" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                )) : null}
            </Fragment>
        )
    }

    individualPlanLinks = () => {
        return (
            <Fragment>
                {!this.props.planData?.isPreEffective || (this.props.planData?.isFirstBillGen && this.props.planData?.isPreEffective) ?
                <ul className="quickLinksMakePayment">
                    <li>
                    <img src={moneyIcon} alt="" aria-hidden="true"/>
                    <button className="tds-link tds-link--medium ml-2" role="link" onClick={() => document.getElementById('makeOneTime_0').click()}>
                        <span>  {overviewSummAem.auth_make_payment} </span>
                        <span className="tds-link__icon-wrapper">⁠<svg className="MuiSvgIcon-root tds-utility-icon tds-link__icon tds-link__icon--internal" focusable="false" viewBox="0 0 24 24" color="#196ecf" aria-hidden="true" role="img"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path></svg></span>
                    </button>
                    </li>
                </ul>
                : null}
                {!this.props.planData?.isPreEffective || (this.props.planData?.isFirstBillGen && this.props.planData?.isPreEffective) ?
                    <ul className="quickLinksHistory">
                        <li className="quickLinksHistoryMob">
                            <img className="historyImgMob" src={historyIcon} alt="" aria-hidden="true"/>
                            <button className="tds-link tds-link--medium" role="link" onClick={this.payment_billingScroll}> <span> {overviewSummAem.auth_view_pay_and_bill_link} </span> <img className="downarrow" src={downArrowIcon} alt="" aria-hidden="true"/></button>
                        </li>
                    </ul>
                : null}
                {!this.props.planData?.isPreEffective || (this.props.planData?.isFirstBillGen && this.props.planData?.isPreEffective) ?
                <ul className={!this.props.planData.isRec ? 'quickLinksRecurring graybox shipDiscount' : 'quickLinksRecurring graybox '}>
                    {(this.props.planData.isRec || this.props.planData.isFuturePaymentMethod) && !this.props.planData.isCMSRequested ?
                        <Fragment>
                            <li className="recurringStatus">
                                <img src={recurringFlagIcon} alt="" aria-hidden="true" />
                                {this.recurringSchedledOn()}
                            </li>
                            <li>
                            <Typography.Paragraph>
                                {overviewSummAem.auth_your_premium_pay_paid_banner_cnt}
                            </Typography.Paragraph>
                            </li>
                        </Fragment>
                        : this.props.govtAndShipPlanInfoList[0].productType === 'GOVT' ?
                            this.getGovtLinkView()
                        : <Fragment>
                        <li className="recurringStatus">
                            <img src={recurringFlagIcon} alt="" aria-hidden="true" />
                            <Typography.H3>
                                {overviewSummAem.auth_get_discount_banner_text}
                            </Typography.H3>
                        </li>
                        <li className="getDiscountMessage">
                            <Typography.Paragraph>
                                {overviewSummAem.auth_get_discount_when_cnt_txt}

                        </Typography.Paragraph>
                        {this.shipDiscountInfo()}
                        </li>
                    </Fragment>}
                </ul>
                : null}
                
            </Fragment>
        )
    }

    render() {
        return (
            <div className={this.props.fedShipPlanCount > 2 ? "quickLinks threecol" : this.props.fedShipPlanCount === 1 ? "quickLinks threecol-oneplan" : "quickLinks"}>
                <div className="quickLinksWallet">
                    <img src={paymentsWallet} alt="" aria-hidden="true"/>
                </div>

                <div className="quickLinksHeader" >
                <Typography.H2>
                        {overviewSummAem.auth_top_banner_title}
                </Typography.H2>
                </div>
                <div className="subHeader-text">
                    <Markup content={overviewSummAem.auth_top_banner_paratext} />
                </div>

                {this.props.planData ? this.props.fedShipPlanCount > 1 ?
                    this.comboLinks()
                    :
                    this.individualPlanLinks() : null
                }

            </div>
        )
    }
}

export default QuickLinks
