import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { saveAndUpdateToken, findPostPaymentDetails } from '../../../server/api/ccPayments-api';
import Spinner from '../../UI/Spinner/Spinner'
import { getOneTimePaymentFlag, getOneTimePageLink, getRecurringPageLink, isManageRecurring, getProductType } from '../../../server/util/localStorageRepo'
import { trackFormSubmit, trackPageLoad } from '../../../server/util/analytics'
import secureLocalStorage from 'react-secure-storage';


class PaymentSucessComponent extends Component {
    state = {
        payAmount: "",
        paymentMethod: "",
        otherAmountChecked: false,
        pastAmountDueChecked: false,
        pastAmountAndCurentChecked: false,
        onlyCurrentChargesChecked: false,
        payInFullChecked: false,
        changeCCClicked: false,
        cardType: "",
        cardTypeId: "0",
        cardNumber: "",
        token: "",
        transactionDate: "",
        cardHolderName: "",
        expirationDate: "",
        upgAccountId: "",
        transactionId: "",
        redirectionPath: getProductType() === 'SHIP' ? "payment-review-and-submit" : "/federal-payment-review-and-submit",
        loaded: false
    };
    /* componentWillUnmount() {
        document.body.removeAttribute('class');
    } */

    componentDidMount() {
        if (secureLocalStorage.getItem('currPage') === 'review') {
            const path = getOneTimePaymentFlag() ? getOneTimePageLink() : getRecurringPageLink()
            window.location.assign(path);
        }

        let params = window.location.href;
        console.log("Params: " + params);
        if (secureLocalStorage.getItem('pastAmountDueChecked')) {
            this.setState({ pastAmountDueChecked: secureLocalStorage.getItem('pastAmountDueChecked')});
        }
        if (secureLocalStorage.getItem('pastAmountAndCurentChecked')) {
            this.setState({ pastAmountAndCurentChecked: secureLocalStorage.getItem('pastAmountAndCurentChecked')});
        }
        if (secureLocalStorage.getItem('onlyCurrentChargesChecked')) {
            this.setState({ onlyCurrentChargesChecked: secureLocalStorage.getItem('onlyCurrentChargesChecked')});
        }
        if (secureLocalStorage.getItem('payInFullChecked')) {
            this.setState({ payInFullChecked: secureLocalStorage.getItem('payInFullChecked')});
        }
        if (secureLocalStorage.getItem('otherAmountChecked')) {
            this.setState({ otherAmountChecked: secureLocalStorage.getItem('otherAmountChecked')});
        }
        if (secureLocalStorage.getItem('selectedPaymentMethod')) {
            this.setState({ paymentMethod: secureLocalStorage.getItem('selectedPaymentMethod') });
        }
        if (secureLocalStorage.getItem('payAmount')) {
            this.setState({ payAmount: secureLocalStorage.getItem('payAmount') });
        }
        if (secureLocalStorage.getItem('changeCCClicked')) {
            this.setState({ changeCCClicked: secureLocalStorage.getItem('changeCCClicked')});
        }

        if (window.location && window.location.href && window.location.href.indexOf("?") > -1) {
            console.log("Redirect URL: " + window.location.href);
            var requestUrl = window.location.href;
            let transactionId = "", accountId = "";
            for (var i = 0; i < requestUrl.split('?')[1].split('&').length; i++) {
                if (requestUrl.split('?')[1].split('&')[i].indexOf("transactionId") > -1) {
                    transactionId = requestUrl.split('?')[1].split('&')[i].split('=')[1];
                    secureLocalStorage.setItem("guestPaymentTransactionId", transactionId);

                } else if (requestUrl.split('?')[1].split('&')[i].indexOf("accountId") > -1) {
                    accountId = requestUrl.split('?')[1].split('&')[i].split('=')[1];
                }
            }

            findPostPaymentDetails(
                transactionId,
                accountId
            )
                .then((response) => {
                    if (response.status === 200 && response.data && response.data.success && response.data.data) {
                        let responseData = response.data.data, ccNumber = "", ccholderName = "", expDate = "", transDate = "", cType = "", cTypeId = "0";
                        if (responseData.token) {
                            let cardtype = responseData.token.charAt(0);
                            switch (cardtype) {
                                case 'V':
                                    cType = "VISA";
                                    cTypeId = "1";
                                    break;
                                case 'M':
                                    cType = "MASTERCARD";
                                    cTypeId = "2";
                                    break;
                                case 'D':
                                    cType = "DISCOVER";
                                    cTypeId = "3";
                            }

                        }
                        if (responseData.accountNumberMasked) {
                            ccNumber = responseData.accountNumberMasked.slice(-4);
                        }
                        if (responseData.accountAccessory) {
                            expDate = responseData.accountAccessory;
                        }
                        if (responseData.holderName) {
                            ccholderName = responseData.holderName.split('+').join(" ").trim();
                        }
                        if (responseData.transactionDate) {
                            transDate = responseData.transactionDate;
                        }
                        this.setState({
                            cardType: cType,
                            cardTypeId: cTypeId,
                            cardNumber: ccNumber,
                            token: responseData.token,
                            transactionDate: transDate,
                            cardHolderName: ccholderName,
                            expirationDate: expDate,
                            upgAccountId: accountId,
                            nickName : responseData._ud_input1 ? responseData._ud_input1.split('+').join(" ").trim() : '',
                            transactionId: transactionId,
                            billingCode : responseData.token ? responseData.token.substring(responseData.token.length-10,responseData.token.length-4) : '',
                        },() =>{
                            if(getProductType() === 'GOVT'){
                                let securePaymentFormData = JSON.parse(secureLocalStorage.getItem("paymentFormData"));
                                let paymentFormData={
                                    "bankHolderName": "",
                                    "routingNumber": "",
                                    "accountNumber": "",
                                    "paymentMethod": this.state.paymentMethod,
                                    "payAmount": this.state.payAmount,
                                    "pastAmountDueChecked": this.state.pastAmountDueChecked,
                                    "pastAmountAndCurentChecked": this.state.pastAmountAndCurentChecked,
                                    "onlyCurrentChargesChecked": this.state.onlyCurrentChargesChecked,
                                    "payInFullChecked": this.state.payInFullChecked,
                                    "otherAmountChecked": this.state.otherAmountChecked,
                                    "billingCode" : this.state.billingCode,
                                    "cardType": this.state.cardType,
                                    "cardTypeId": this.state.cardTypeId,
                                    "cardNumberLastFour": this.state.cardNumber,
                                    "creditCardToken": this.state.token,
                                    "transactionDate": this.state.transactionDate,
                                    "nameOnCard": this.state.cardHolderName,
                                    "expiration": this.state.expirationDate,
                                    "upgAccountId": this.state.upgAccountId,
                                    "nickName" : this.state.nickName,
                                    "transactionId": this.state.transactionId,
                                    "pageLoadViaFlow": true,
                                    "paymentDate" : secureLocalStorage.getItem('paymentDate'),
                                    "ssaDates": JSON.parse(secureLocalStorage.getItem('ssaDates')),
                                    "ccFlow": true,
                                    "paymentBy" : 'addCC',
                                    "isAddPayMethodOpen" : true,
                                    "scheduleChoice" : secureLocalStorage.getItem('scheduleChoice'),
                                    "recScheduleChoice" : secureLocalStorage.getItem('recScheduleChoice'),
                                    "totalAmountDue": securePaymentFormData?.totalAmountDue ? securePaymentFormData.totalAmountDue : 
                                    secureLocalStorage.getItem('totalAmountDue'),
                                  }
                                  secureLocalStorage.setItem("paymentFormData", JSON.stringify(paymentFormData));
                                }
                        })

                        if (getOneTimePaymentFlag()){
                        saveAndUpdateToken(
                            responseData.token,
                            transactionId,
                            accountId
                        )
                            .then((resp) => {
                                if (resp.data && (resp.data.success || (resp.data.successMessageInfo.successMsg && resp.data.successMessageInfo.successMsg.length > 0 &&
                                    resp.data.successMessageInfo.successMsg.toLowerCase().indexOf('already updated') > -1))) {
                                    trackFormSubmit(getOneTimePaymentFlag() ? ' make a one time payment' : isManageRecurring() ? 'manage recurring  payment' :
                                        'set up recurring payment', getOneTimePaymentFlag() ? 'make a one time payment' : isManageRecurring() ? 'manage recurring payment' :
                                            'recurring payment', this.state.pastAmountDueChecked && this.state.changeCCClicked ? 'Due Today | Credit/Debit Card | Change Card Details ' : this.state.pastAmountAndCurentChecked && this.state.changeCCClicked ?
                                                'Due today + Upcoming charges | Credit/Debit Card | Change Card Details' : this.state.otherAmountChecked && this.state.changeCCClicked ? 'Other Amount | Credit/Debit Card | Change Card Details' :
                                                    this.state.onlyCurrentChargesChecked && this.state.changeCCClicked ? 'Upcoming Charges | Credit/Debit Card | Change Card Details' : this.state.payInFullChecked && this.state.changeCCClicked ? 'Pay In Full | Credit/Debit Card | Change Card Details' : this.state.pastAmountDueChecked ? 'Due Today | Credit/Debit Card | Add Card Details' :
                                                        this.state.pastAmountAndCurentChecked ? 'Due today + Upcoming charges | Credit/Debit Card | Add Card Details' : this.state.otherAmountChecked ? 'Other Amount | Credit/Debit Card | Add Card Details' : this.state.onlyCurrentChargesChecked ?
                                                            'Upcoming Charges | Credit/Debit Card | Add Card Details' : this.state.payInFullChecked ?
                                                                'Pay In Full | Credit/Debit Card | Add Card Details' : '');
                                    this.setState({ redirectionPath: getProductType() === 'SHIP' ? "payment-review-and-submit" : "/federal-payment-review-and-submit", loaded: true });
                                } else {
                                    this.setState({ redirectionPath: "/service-error", loaded: true });
                                }
                            }).catch(error => {
                                this.setState({ redirectionPath: "/service-error", loaded: true });
                            });
                        } else{
                            let analyticsPage = getOneTimePaymentFlag() ? 'make a one time payment' :  isManageRecurring() ? 'update recurring payments':'set up recurring payment'
                            trackPageLoad(analyticsPage)
                            let formInField=(secureLocalStorage.getItem('recScheduleChoice') === 'startNextMonth' ? 'start next month':secureLocalStorage.getItem('recScheduleChoice') === 'scheduleFutureMonth' ? 'schedule for a future month':'start next month') +' | '+'add new payment | '+'Credit/debit'
                            trackFormSubmit(analyticsPage, analyticsPage, formInField)
                            this.setState({ redirectionPath: getProductType() === 'SHIP' ? "payment-review-and-submit" : "/federal-payment-review-and-submit", loaded: true });
                        }
                    } else {
                        this.setState({ redirectionPath: "/service-error", loaded: true });
                    }
                    
                }).catch(error => {
                    this.setState({ redirectionPath: "/service-error", loaded: true });
                });
        }
    }

    render(props) {

        return (

            <div>
                {!this.state.loaded
                    ?
                    (<Spinner />)
                    :
                    (
                        <Redirect to={{
                            pathname: this.state.redirectionPath,
                            state: {
                                bankHolderName: "",
                                routingNumber: "",
                                accountNumber: "",
                                paymentMethod: this.state.paymentMethod,
                                payAmount: this.state.payAmount,
                                pastAmountDueChecked: this.state.pastAmountDueChecked,
                                pastAmountAndCurentChecked: this.state.pastAmountAndCurentChecked,
                                onlyCurrentChargesChecked: this.state.onlyCurrentChargesChecked,
                                payInFullChecked: this.state.payInFullChecked,
                                otherAmountChecked: this.state.otherAmountChecked,
                                billingCode : this.state.billingCode,
                                cardType: this.state.cardType,
                                cardTypeId: this.state.cardTypeId,
                                cardNumberLastFour: this.state.cardNumber,
                                creditCardToken: this.state.token,
                                transactionDate: this.state.transactionDate,
                                nameOnCard: this.state.cardHolderName,
                                expiration: this.state.expirationDate,
                                upgAccountId: this.state.upgAccountId,
                                nickName : this.state.nickName,
                                transactionId: this.state.transactionId,
                                pageLoadViaFlow: true,
                                paymentDate : secureLocalStorage.getItem('paymentDate'),
                                ssaDates: JSON.parse(secureLocalStorage.getItem('ssaDates')),
                                ccFlow: true,
                                paymentBy : 'addCC',
                                isAddPayMethodOpen : true,
                                scheduleChoice : secureLocalStorage.getItem('scheduleChoice'),
                                recScheduleChoice : secureLocalStorage.getItem('recScheduleChoice'),
                                paymentsMethodValue : secureLocalStorage.getItem("paymentsMethodValue"),
                                pastAmountDue: secureLocalStorage.getItem('pastAmountDue'),
                                pastAmountDueFound: JSON.parse(secureLocalStorage.getItem('pastAmountDueFound')),
                                currentChargesFound: JSON.parse(secureLocalStorage.getItem('currentChargesFound')),
                                pastAmountAndCurentCharges:secureLocalStorage.getItem('pastAmountAndCurentCharges'),
                                pastAmountAndCurentChargesFound:JSON.parse(secureLocalStorage.getItem('pastAmountAndCurentChargesFound')),
                                onlyCurrentChargesFound:JSON.parse(secureLocalStorage.getItem('onlyCurrentChargesFound')),
                                currentCharges: secureLocalStorage.getItem('currentCharges'),
                                totalAmountDue: secureLocalStorage.getItem('totalAmountDue')
                            }
                        }} />
                    )
                }

            </div>
        );
    }
}

export default PaymentSucessComponent;
