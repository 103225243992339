import axios from 'axios';
import { logger } from '../logger';
import appConfigs from '../app.config';
import commonConstants from '../util/commonConstants';

export const creditCardCreatePaymentSubmissionResults = async (commonRequest,requestData) => {  

    let creditCardCreatePaymentRequest = {
        
            "createPaymentReq": {
              "amount": commonRequest.payAmount,
              "annualPremiumOverride": "FALSE",
              "authorizationMedium": commonConstants.POST_PAYMENT_AUTHORIZATION_MEDIUM,
              "audit": {
                "createdBy": commonRequest.memberId
              },
              "bankAccount": null,
              "creditCard": {
                "billingCode": requestData.billingCode,
                "cardNumberLastFour": requestData.numberLastFour,
                "cardType": requestData.cardType,
                "creditCardId": null,
                "creditCardToken": requestData.creditCardToken,
                "declineCount": "",
                "declineCountLastUpdated": "",
                "expiration": requestData.expiration,
                "nameOnCard": requestData.nameOnCard,
                "unstored": "FALSE"
              },
              "householdId": "",
              "inputSourceSystem": commonConstants.INPUT_SOURCE_SYSTEM,
              //"merchantAccount": commonRequest.merchantAccount,
              "paymentId": null,
              "paymentState": commonConstants.PAYMENT_STATE,
              "providerTransactionId": "",
              "requestDate": commonRequest.currentDate,
              "upgTransactionId": "",
              "walletItemId": commonRequest.walletItemId,
              "withdrawalDate": commonRequest.withdrawalDate
            },
            "enc_householdId":commonRequest.enc_householdId,
            "paymentReconciliationRequest": {
                "memberFirstname": commonRequest.memberFirstname,
                "memberId":  commonRequest.memberId,
                "memberLastname": commonRequest.memberLastname,
                "paymentDate": commonRequest.paymentDate,
                "planCategory": commonRequest.planCategory,
                "sourcePortal": commonRequest.productBrand,
                "userName": commonRequest.userName,
              }
          }
      
  return axios.post(appConfigs.cc_create_payment_service_path , 
    creditCardCreatePaymentRequest)
    .catch(error => {
        logger.error(error.message);
        throw error;
    })
  };

  export default creditCardCreatePaymentSubmissionResults;