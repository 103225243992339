import axios from 'axios';
import { logger } from '../logger';
import appConfigs from '../app.config';
import commonConstants from '../util/commonConstants';
import { getEncStr} from '../util/encDec';
const isbUpdatePayments = axios.create({
	baseURL: appConfigs.api_base_url
});
export const eftUpdatePaymentSubmission = async (requestData) => {

    let eftUpdatePaymentRequest = {
        "updatePaymentMethodRequest": {
          "request": {
            "accountAutoPayId": "",
            "accountHolderFirstName": requestData.accountFirstname,
            "accountHolderLastName": requestData.accountLastname,
            "accountHolderMiddleName": requestData.accountMiddlename,
            "accountNumber": "",
            "accountType": commonConstants.ACCOUNT_TYPE,
            "action": commonConstants.ACTION,
            "applyToRelatedHousehold": "N",
            "bankAccountNumber": requestData.bankNumber ? getEncStr(requestData.bankNumber) : requestData.bankNumber,
            "bankRoutingNumber": requestData.routingNumber ? getEncStr(requestData.routingNumber): requestData.routingNumber,
            "billingCustomerId": "",
            "customerId":  requestData.individualId,
            "customerType": commonConstants.CUSTOMER_TYPE,
            "isValidated": requestData.accountStatusWrapper,
            "source": commonConstants.SOURCE
          }
        },
          "paymentReconciliationRequest": {
            "memberFirstname": requestData.memberFirstname,
            "memberId": requestData.memberId,
            "memberLastname": requestData.memberLastname,
            "middleName": "",
            "paymentDate": requestData.paymentDate,
            "planCategory": requestData.planCategory,
            "sourcePortal": requestData.productBrand,
            "userName": requestData.userName
          }
      }
  return isbUpdatePayments.post(appConfigs.ship_isb_update_payment_submission, eftUpdatePaymentRequest)
  .catch(error => {
    logger.error(error.message);
    throw error;
  })  
}
export const eftCancelPaymentSubmission = async (requestData) => {

    let eftCancelPaymentRequest = {
        "updatePaymentMethodRequest": {
          "request": {
            "accountAutoPayId": requestData.accountAutoPayId,
            "accountHolderFirstName": "",
            "accountHolderLastName": "",
            "accountHolderMiddleName": "",
            "accountNumber": "",
            "accountType": "",
            "action": commonConstants.CANCEL_ACTION,
            "applyToRelatedHousehold": "",
            "bankAccountNumber": "",
            "bankRoutingNumber": "",
            "billingCustomerId": "",
            "customerId": requestData.individualId,
            "customerType": commonConstants.CUSTOMER_TYPE,
            "isValidated": "",
            "source": commonConstants.SOURCE
          }
        },
          "paymentReconciliationRequest": {
            "memberFirstname": requestData.memberFirstname,
            "memberId": requestData.memberId,
            "memberLastname": requestData.memberLastname,
            "middleName": "",
            "paymentDate": requestData.paymentDate,
            "planCategory": requestData.planCategory,
            "sourcePortal": requestData.productBrand,
            "userName": requestData.userName
          }
      }
  return isbUpdatePayments.post(appConfigs.ship_isb_update_payment_submission, eftCancelPaymentRequest)
  .catch(error => {
    logger.error(error.message);
    throw error;
  })  
}
export default isbUpdatePayments;