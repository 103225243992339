import axios from 'axios';
import appConfigs from "../../server/app.config";
const encAATokenService = axios.create({
	baseURL: appConfigs.api_base_url
});


let axiosConfig = {
	headers: {
		'Content-Type': 'application/json;charset=UTF-8',
		"Access-Control-Allow-Origin": "*",
		}
  };

export const getEncryptAATokenService = async (callBackUrl,userId) => {
	var encAATokenRequest = {	
			"callBackUrl": callBackUrl,
			"userid": userId
    }


return encAATokenService.post(appConfigs.hsid_mfa_aatoken_encrypt_service_path, encAATokenRequest,axiosConfig)
.then(response =>{
				return response;
		})
		.catch((error) => {
			throw error;
		});
}


export default encAATokenService;
