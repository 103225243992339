import React from "react";
import { Markup } from 'interweave';
import IcWarningRed from '../../tokens/icons/ic_warning_red'
import { Typography, Button, Modal, ModalHeader, ModalTitle, ModalContent, ModalFooter } from "@uhc-tempo/components";
import PaymentModals from './PaymentModals';

const EarlyWarningZero = (props) => {
    return (
        <div className="row justify-content-center">
          <div className="col-md-12 col-lg-12">
            {/* header */}
            <div class="modal-header-container">

              <div class="modal-header">
              {/* <div className="img-icon">
                 <IcWarningRed className="top-warning-svg"/>
              </div> */}
                <div  id="ModalId" class="early-warning-header-title">
                    <Typography.H2>{props.content.zero_attempt_header}</Typography.H2>
                </div>
                </div>
             </div>
            {/* content */}
            <div role="region" tabindex="0" aria-labelledby="containerID" class="modal-content-container">
            <p class="col-md-12">
                  <Markup content= {props.content.model_msg}/>
              </p>
            </div>
            {/* footer */}
            <div class="modal-footer-container">
              <div class="btn-group">

            <button  onClick={props.earlyWarningCancelClick} className="tds-button tds-button--primary-one tds-margin-xsm-horizontal" id="earlyWarningBacktoOverviewBtn">
                  {props.cancelPaymentModal.backto_overview}
            </button>


            </div>
            </div>
          </div>
        </div>
      );
    }
    export default EarlyWarningZero
