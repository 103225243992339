import React, { Component, Fragment } from 'react'
import Input from '../../UI/Input/Input'
import { Typography } from '@uhc-tempo/components'
import { Form as EFTForm} from '@uhc/pattern-library'
import './PaymentForm.css'
import PaymentModals from '../modals/PaymentModals'
import {routingNUmberValidator, isValidAccountNumberAndRoutingNumber} from '../../../server/util/FormUtil'
import { accountValidationWrapper } from '../../../server/api/bank-account-validation-wrapper-api';
import { getMemberID } from '../../../server/util/localStorageRepo';
import {addBankAccountInWallet, statusFromUpdateWalletCall, updateBankAccountInWallet} from '../../../server/api/addUpdateWalletService-api'
import {items,updateWalletData} from '../Wallet/ManageWallet'
import Spinner from '../../UI/Spinner/Spinner'
import { manageWalletFormAem } from '../Wallet/ManageWallet'
import cloneDeep from 'lodash/cloneDeep';
import { trackFormSubmit} from '../../../server/util/analytics'
import secureLocalStorage from 'react-secure-storage'

export default class EftForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            bankHolderFirstName : this.props.isEditForm ? this.props.bankAccount.fName : '',
            bankHolderMiddleName : this.props.isEditForm ? this.props.bankAccount.mName : '',
            bankHolderLastName : this.props.isEditForm ? this.props.bankAccount.lName : '',
            routingNumber : this.props.isEditForm ? this.props.bankAccount.rNumber : '', //122199983 nacha
            accountNumber : this.props.isEditForm ? this.props.bankAccount.aNumber: '', //61231234365 nacha
            oldRoutingNumber : '',
            oldAccountNumber : '',
            bankHolderNickName : this.props.isEditForm ? this.props.bankAccount.nickName : '',
            productType : 'GOVT',
            bankHolderFirstNameError : false,
            bankHolderMiddleNameError : false,
            bankHolderLastNameError : false,
            bankHolderNickNameError : false,
            routingNumberError : false,
            accountNumberError : false,
            authIndicator : this.props.isEditForm ? this.props.bankAccount.authInd : '',
            attemptCounter : 4,
            loading : false
        }
    }

    nachaErrorContent = {
        header: manageWalletFormAem.auth_nacha_error_heading,
        para1: manageWalletFormAem.auth_nacha_error_txt1,
        para2: this.props.page === 'wallet' ? manageWalletFormAem.auth_nacha_error_txt2_wallet : manageWalletFormAem.auth_nacha_error_txt2,

        earlyWarningModalContent: {
          header: manageWalletFormAem.auth_mw_early_warning_modal_heading,
          model_msg: manageWalletFormAem.auth_mw_early_warning_modal_content,
        },
        
        earlyWarningContent: {
          eft_error_sec4: this.props.page !== 'wallet' ? manageWalletFormAem.auth_nacha_error_txt3 : '',
          eft_early_warning_button_txt: this.props.page !== 'wallet' ? manageWalletFormAem.auth_nacha_ews_link_text : '',
          eft_error_sec3: this.props.page !== 'wallet' ? manageWalletFormAem.auth_nacha_error_txt4 : '',     
        }
    }

    nachaZeroModalContent = {
        header : manageWalletFormAem.auth_mw_zero_attempt_modal_heading,
        model_msg : manageWalletFormAem.auth_mw_zero_attempt_modal_content,
        btn_text : this.props.page === 'wallet' ? manageWalletFormAem.auth_mw_close_btn_txt : manageWalletFormAem.auth_mw_goto_pay_btn_txt,
        wallet_ews: manageWalletFormAem.auth_mw_zero_attempt_modal_content_wallet,
        ews_contact: manageWalletFormAem.auth_mw_zero_attempt_modal_ews_contact,
        page: this.props.page
        
    }

    routingModal = {
      help_label_txt: manageWalletFormAem.auth_help_link_txt,
        header: manageWalletFormAem.auth_mw_routing_modal_heading,
        desc: manageWalletFormAem.auth_mw_routing_modal_desc,
        btn_txt: manageWalletFormAem.auth_mw_close_btn_txt,
    }

    accModal = {
      help_label_txt: manageWalletFormAem.auth_help_link_txt,
      header: manageWalletFormAem.auth_mw_acc_modal_heading,
      desc: manageWalletFormAem.auth_mw_acc_modal_desc,
      btn_txt: manageWalletFormAem.auth_mw_close_btn_txt,
 }

    showNachaAttemptErrorMessage = () => {
        let count = null
        if (this.state.attemptCounter <= 1) {
           count = --(this.state.attemptCounter)
          this.setState({attemptCounter: count}, ()=> document.getElementById('nachazerobtn').click());
        } else {
           count = --(this.state.attemptCounter)
           this.setState({attemptCounter: count});
        }
       this.props.eftSubmitBtnClickErrorSet({
        type : 'nacha',
        content : this.nachaErrorContent,
        count : count,
        src : 'eftform'
      })
        window.scrollTo(0, 0);
        document.getElementById('alertbody_desktop').focus()
      }

    setErrorMessage = (isSubmit)=> {
        let fieldErrCount = [this.state.bankHolderFirstNameError, this.state.bankHolderMiddleNameError,this.state.bankHolderLastNameError,
            this.state.accountNumberError,this.state.routingNumberError, this.state.bankHolderNickNameError].filter(el=> el === true).length
        if(fieldErrCount === 0 && this.props.isEditForm && isSubmit) {
          this.updateBankAccount()
        } else if(fieldErrCount === 0 && isSubmit) {
              if(this.isEFTExistActive()) {
                this.props.eftSubmitBtnClickErrorSet(
                  {fields : [
                              {error : true, label : `<b>${manageWalletFormAem.auth_duplicate_bank_acc_title}</b> ${manageWalletFormAem.auth_item_already_exists_err_msg}`}
                            ],
                  count : 1,
                  type : 'api',
                  src : 'eftform'
                  }
                )
                window.scrollTo(0, 0)
                this.setState({loading : false}, () => document.getElementById('alertbody_desktop').focus())
              } else {
                this.nachaCheck()
              }
            } else {
                this.props.eftSubmitBtnClickErrorSet(
                    {fields : [
                                {error : this.state.bankHolderFirstNameError, label : manageWalletFormAem.auth_mw_first_name_error_label},
                                {error : this.state.bankHolderMiddleNameError, label : manageWalletFormAem.auth_mw_middle_name_error_label},
                                {error : this.state.bankHolderLastNameError, label : manageWalletFormAem.auth_mw_last_name_error_label},
                                {error : this.state.routingNumberError, label : manageWalletFormAem.auth_mw_routing_error_label},
                                {error : this.state.accountNumberError, label : manageWalletFormAem.auth_mw_acc_number_error_label},
                                {error : this.state.bankHolderNickNameError, label : manageWalletFormAem.auth_mw_nickname_err_lbl}
                              ],
                    count : fieldErrCount,
                    type : 'field',
                    src : 'eftform'
                    }
                  )     
            }

        if(fieldErrCount > 0 && isSubmit) {
          window.scrollTo(0, 0);
          this.setState({loading : false}, () => document.getElementById('alertbody_desktop').focus())
        }
      }

    bankHolderFirstNameChangedHandler = (event) => {
      const re = /[a-zA-Z0-9\s.,'-]+$/
         if (event.target.value === '' || re.test(event.target.value)){
          this.setState({
            bankHolderFirstName: event.target.value
          })
        }
      }
    
      bankHolderFirstNameOnBlur = (event) => {
        const re = /.*[A-Za-z]+.*$/
        let error = true
        let value = event.target.value
        let priorErrState = this.state.bankHolderFirstNameError
        if (value === '') {
            document.getElementById('bankHolFirstName').setAttribute('aria-describedby', 'bankHolFirstNameFieldError') 
        } else if (re.test(value)) {
            error = false
        } 
          this.setState({
            bankHolderFirstName: value,
            bankHolderFirstNameError: error,
          }, ()=> {
            if(priorErrState && !error) {
              this.setErrorMessage(false)
            }
          })
      }
    
      bankHolderMiddleNameChangedHandler = (event) => {
        const re = /[a-zA-Z0-9\s.,'-]+$/
         if (event.target.value === '' || re.test(event.target.value)){
            this.setState({
              bankHolderMiddleName: event.target.value
            })
          }
      }
    
      bankHolderMiddleNameOnBlur = (event) => {
        const re = /.*[A-Za-z]+.*$/
        let error = true
        let value = event.target.value
        let priorErrState = this.state.bankHolderMiddleNameError
        if (value === '') {
            error = false
            document.getElementById('bankHolMiddleName').setAttribute('aria-describedby', 'bankHolMiddleNameFieldError') 
        } else if (re.test(value)) {
            error = false
        } 
          this.setState({
            bankHolderMiddleName: value,
            bankHolderMiddleNameError: error,
          }, ()=> {
            if(priorErrState && !error) {
              this.setErrorMessage(false)
            }
          })
      }
    
      bankHolderLastNameChangedHandler = (event) => {
        const re = /[a-zA-Z0-9\s.,'-]+$/
         if (event.target.value === '' || re.test(event.target.value)){
          this.setState({
            bankHolderLastName: event.target.value
          })
        }
      }
    
      bankHolderLastNameOnBlur = (event) => {
        const re = /.*[A-Za-z]+.*$/
        let error = true
        let value = event.target.value
        let priorErrState = this.state.bankHolderLastNameError
        if (value === '') {
            document.getElementById('bankHolLastName').setAttribute('aria-describedby', 'bankHolLastNameFieldError') 
        } else if (re.test(value)) {
            error = false
        } 
          this.setState({
            bankHolderLastName: value,
            bankHolderLastNameError: error,
          }, ()=> {
            if(priorErrState && !error) {
              this.setErrorMessage(false)
            }
          })
      }

      bankHolderNickNameChangedHandler = (event) => {
       const re = /[a-zA-Z0-9\s.,'-]+$/
         if (event.target.value === '' || re.test(event.target.value)){
          this.setState({
            bankHolderNickName: event.target.value
          })
        }
       
      }
    
      bankHolderNickNameOnBlur = (event) => {
        const re = /.*[A-Za-z]+.*$/
        let error = true
        let value = event.target.value
        let priorErrState = this.state.bankHolderNickNameError
        if (value === '') {
            error = false
            document.getElementById('bankNickname').setAttribute('aria-describedby', 'bankHolNickNameFieldError') 
        } else if (re.test(value)) {
            error = false
        } 
          this.setState({
            bankHolderNickName: value,
            bankHolderNickNameError: error,
          }, ()=> {
            if(priorErrState && !error) {
              this.setErrorMessage(false)
            }
          })
      }
    
      accountNumberChangedHandler = (event) => {
        const re = /[0-9]+$/
         if (event.target.value === '' || re.test(event.target.value)){
          this.setState({
            accountNumber: event.target.value
          })
        }
      }
    
      accountNumberOnBlur = (event) => {
        const re = /^[0-9]{3,17}$/
        let error = true
        let value = event.target.value
        let priorErrState = this.state.accountNumberError
        if (value === '') {
            document.getElementById('accountNum').setAttribute('aria-describedby', 'accountNumFieldError') 
        } else if (re.test(value)) {
            error = false
        } 
          this.setState({
            accountNumber: value,
            accountNumberError: error,
          }, ()=> {
            if(priorErrState && !error) {
              this.setErrorMessage(false)
            }
          })
      }
    
      rountingNumberChangedHandler = (event) => {
        this.setState({
          routingNumber: event.target.value
        })
      }
    
      rountingNumberOnBlur = (event) => {
        const re = /^[0-3][0-9]{8}$/
        let error = true
        let value = event.target.value
        let priorErrState = this.state.routingNumberError
        if (value === '') {
            document.getElementById('routNumber').setAttribute('aria-describedby', 'routNumberFieldError') 
        } else if (re.test(value) && !routingNUmberValidator(value)) {
            error = false
        } 
          this.setState({
            routingNumber: value,
            routingNumberError: error,
          }, ()=> {
            if(priorErrState && !error) {
              this.setErrorMessage(false)
            }
          })
      }

    isEFTExistActive = ()=> {
      let existItem= items.filter(el=> el.creditCard == null).filter(item => (item.bankAccount.accountNumber === this.state.accountNumber && 
        item.bankAccount.routingNumber === this.state.routingNumber));
        
      if(existItem.length>0 && existItem[0].active.toLowerCase() === "true")
        return true
      
      return false
    }

    createBankAccountReq = ()=> {
      let bankRequest = {
        type : this.props.paymentMethod.toLocaleUpperCase(),
        firstName : this.state.bankHolderFirstName,
        middleName : this.state.bankHolderMiddleName,
        lastName : this.state.bankHolderLastName,
        routingNumber : this.state.routingNumber,
        accountNumber : this.state.accountNumber,
        authIndicator : this.props.isEditForm ? this.state.authIndicator : secureLocalStorage.getItem("accountStatus"),
        nickName : this.state.bankHolderNickName,
        itemId : this.props.isEditForm ? this.props.item.itemId : '',
        bankAccountId : this.props.isEditForm ? this.props.item.bankAccount.bankAccountId : '',
        autopayTimelines : this.props.isEditForm ? this.props.item.autopayTimelines : []
      }
      return bankRequest
    }


    updateBankAccount = ()=> {
      this.props.setLoading(true);
      this.props.resetAllFormsAndErrors();
        let bankRequest = this.createBankAccountReq()
        console.log(bankRequest)
        try {
          updateBankAccountInWallet(cloneDeep(items),bankRequest,true,cloneDeep(updateWalletData)).then(res => {
            console.log(res)
          if(res.success) {
            let bankAccCnt = bankRequest.type == 'CHECKING' ? bankRequest.itemId === this.props.recBankItemId ? 'manage my wallet - EFT checking - edit existing payment method|recurring payments ON' : bankRequest.itemId === this.props.scheduledBankItemId ? 'manage my wallet - EFT checking - edit existing payment method|recurring payemnts scheduled' :'manage my wallet - EFT checking - edit existing payment method' :
              bankRequest.itemId === this.props.recBankItemId ? 'manage my wallet - EFT savings - edit existing payment method|recurring payments ON' : bankRequest.itemId === this.props.scheduledBankItemId? 'manage my wallet - EFT savings - edit existing payment method|recurring payemnts scheduled' :'manage my wallet - EFT savings - edit existing payment method'
                        trackFormSubmit(' manage my wallet',bankAccCnt)
            sessionStorage.setItem('notificationCount', 0)
            sessionStorage.setItem('newSession', false);
            sessionStorage.setItem('updateRecurring', false)
              console.log('inside')
              this.props.getWalletDetails()
          } else {
            let errors = res.errors?.map(elt=> elt.detail)
            console.log(errors)
            if(res.statusCode >= 400 || statusFromUpdateWalletCall >= 400) {
              this.props.eftSubmitBtnClickErrorSet(
                {fields : [{error : true , label : `<b>${manageWalletFormAem.auth_mw_system_err_title}</b> ${manageWalletFormAem.auth_mw_system_err_txt}`}],
                count : 1,
                type : 'api'
                })
            }
            this.props.setLoading(false)
            window.scrollTo(0, 0)
            document.getElementById('alertbody_desktop').focus()
          }
          }).catch(error=> {
            this.props.setLoading(false)
            console.log(error) 
          })
        } catch (error) {
            this.props.setLoading(false)
            console.log(error)
        }
        
    }

    addBankAccount = ()=> {
      this.props.setLoading(true)
        let bankRequest = this.createBankAccountReq()
        try {
            addBankAccountInWallet(cloneDeep(items),bankRequest,false, cloneDeep(updateWalletData)).then(res => {
              console.log(res)
            if(res.success) {
              sessionStorage.setItem('notificationCount', 0)
                trackFormSubmit(' manage my wallet', bankRequest.type === 'CHECKING' ? 'manage my wallet - EFT checking - add new payment method' : 'manage my wallet - EFT savings - add new payment method', 'PII Information');
                this.props.getWalletDetails()
            } else {
              let errors = res.errors?.map(elt=> elt.detail)
              console.log(errors)
              if(res.statusCode >= 400 || statusFromUpdateWalletCall >= 400) {
                this.props.eftSubmitBtnClickErrorSet(
                  {fields : [{error : true , label : `<b>${manageWalletFormAem.auth_mw_system_err_title}</b> ${manageWalletFormAem.auth_mw_system_err_txt}`}],
                  count : 1,
                  type : 'api'
                  })
              }
              this.props.setLoading(false)
              window.scrollTo(0, 0)
              document.getElementById('alertbody_desktop').focus()
            }
            }).catch(error=> {
              this.props.setLoading(false)
              console.log(error) 
            })
        } catch (error) {
            this.props.setLoading(false)
            console.log(error)
        }
        
    }

   nachaCheck() {
        var isOldValues = false;
        if (this.state.oldAccountNumber == this.state.accountNumber.trim() &&
          this.state.oldRoutingNumber == this.state.routingNumber.trim()) {
          isOldValues = true;
        }
        this.setState({
          oldAccountNumber: this.state.accountNumber.trim(),
          oldRoutingNumber: this.state.routingNumber.trim()
        })
        this.setState({loading : true})
        if (!isOldValues) {
          this.props.eftSubmitBtnClickErrorSet(null)
          //check valid account details are present in previous trasactions or not (wrapperPojo saved in local storage)
          var isValidDetails = isValidAccountNumberAndRoutingNumber(this.state.accountNumber, this.state.routingNumber);
          if (!isValidDetails) {
            // call account validation wrapper and check accountStatus 023896964-11 getMemberID()
            accountValidationWrapper(getMemberID(), this.state.routingNumber, this.state.accountNumber, this.state.productType).then((response) => {
              if (response.data != null) {
                let accountStatus = response.data.accountStatus;
                if (accountStatus != null && accountStatus.length > 0 && (accountStatus.trim().toLowerCase() == "n" || accountStatus.trim().toLowerCase() == "no")) {
                  this.setState({ nachaErr: true });
                  this.showNachaAttemptErrorMessage();
                  this.setState({loading : false})
                } else {
                  // navigate to review and submit page
                  this.setState({authIndicator : accountStatus})
                  secureLocalStorage.setItem("accountStatus", accountStatus)
                  this.addBankAccount()
                }
              } else {
                this.setState({authIndicator : 'U'})
                secureLocalStorage.setItem("accountStatus", "U")
                this.addBankAccount()
              }
            }).catch(error => {
              console.log(error);
              this.setState({loading : false})
              let accountStatus = error.response?.data?.accountStatus;
              if (accountStatus != null && (accountStatus === 'U' || accountStatus === 'Y')) {
                this.setState({authIndicator : accountStatus})
                secureLocalStorage.setItem("accountStatus", accountStatus)
                this.addBankAccount()
              }
            });
          } else {
            // setting accountStatus to Y if wrapper service is not called
            this.setState({loading : false})
            secureLocalStorage.setItem("accountStatus", "Y")
            this.setState({authIndicator : 'Y'})
            this.addBankAccount()
          }
        } else {
          // Show attempts message and set attempt counter
          this.setState({ nachaErr: true });
          this.showNachaAttemptErrorMessage();
          this.setState({loading : false})
        }
    }

      validateField = ()=> {
          let firstNameErr = false, middNameErr = false, lastNameErr = false, routingNumberErr = false, accountNumberErr = false;
          if(this.state.bankHolderFirstName == null || this.state.bankHolderFirstName === '' || this.state.bankHolderFirstNameError) {
            firstNameErr = true
          }
          if(this.state.bankHolderMiddleNameError) {
            middNameErr = true
          }
          if(this.state.bankHolderLastName == null || this.state.bankHolderLastName === '' || this.state.bankHolderLastNameError) {
            lastNameErr = true
          }
          if(this.state.routingNumber == null || this.state.routingNumber === '' || this.state.routingNumberError) {
            routingNumberErr = true
          }
          if(this.state.accountNumber == null || this.state.accountNumber === '' || this.state.accountNumberError) {
            accountNumberErr = true
          }
          this.setState({bankHolderFirstNameError : firstNameErr, bankHolderMiddleNameError : middNameErr, bankHolderLastNameError : lastNameErr, routingNumberError : routingNumberErr, accountNumberError : accountNumberErr},
            ()=> this.setErrorMessage(true))
      }

      onSavePaymentClick = ()=> {
        if (this.props.superUser) {
          this.props.showSuperUserError(null, this.props.bankListIndex)
        } else {
          this.validateField()
        }
     }

      onCancelBtnClick = ()=> {
        if(this.props.isEditForm) {
          this.props.cancleBtnOnClickEditEFT(this.props.bankListIndex)
           //Wcag Implementation
          document.getElementById('bankEditLink_'+this.props.bankListIndex).setAttribute('aria-expanded','false')
          document.getElementById('bankEditLink_'+this.props.bankListIndex).removeAttribute('aria-labelledby')
          if(document.getElementById('eft_remove_'+this.props.bankListIndex)){
            document.getElementById('eft_remove_'+this.props.bankListIndex).removeAttribute('tabindex')
          } else{
            document.getElementById('eft_rec_remove_'+this.props.bankListIndex).removeAttribute('tabindex')
          }
        } else {
          this.props.addEftCancelBtnClick(true)
        }
      }

      componentDidMount() {
      }

      resetState = ()=> {
        this.setState({
          bankHolderFirstName : '', bankHolderMiddleName : '', bankHolderLastName : '', routingNumber : '', accountNumber : '',
          oldRoutingNumber : '', oldAccountNumber : '', bankHolderNickName : '', productType : 'GOVT', bankHolderFirstNameError : false,
          bankHolderMiddleNameError : false, bankHolderLastNameError : false, bankHolderNickNameError : false, routingNumberError : false,
          accountNumberError : false, authIndicator : '', attemptCounter : 4})
          this.props.eftSubmitBtnClickErrorSet(null)
      }

    render(){
        return(
            <Fragment>
              {this.state.loading ? <Spinner /> : null}
              <div className={(this.state.loading ? ' text-blur' : '')} id={this.props.isEditForm ? 'edit_eft_details_'+this.props.bankListIndex : 'add_eft_details'} role="region" aria-labelledby={this.props.isEditForm ? 'edit_eft_title_'+this.props.bankListIndex : 'add_eft_title'}>
                {this.props.paymentMethod !== 'CC' && <div className="eftForm">
                {this.props.isEditForm ? 
                <Typography.H6 id={'edit_eft_title_'+this.props.bankListIndex} style={{color: 'rgb(51, 51, 51)'}}>{manageWalletFormAem.auth_mw_edit_heading} {manageWalletFormAem.auth_mw_eft_heading} {this.props.paymentMethod === 'checking' ? manageWalletFormAem.auth_mw_checking_heading : manageWalletFormAem.auth_mw_savings_heading} {manageWalletFormAem.auth_mw_acc_details_heading}</Typography.H6> : 
                <Typography.H5 id="add_eft_title" style={{color: 'rgb(51, 51, 51)'}}>{manageWalletFormAem.auth_mw_add_heading} {manageWalletFormAem.auth_mw_eft_heading} {this.props.paymentMethod === 'checking' ? manageWalletFormAem.auth_mw_checking_heading : manageWalletFormAem.auth_mw_savings_heading} {manageWalletFormAem.auth_mw_acc_details_heading}</Typography.H5>}
                  
                    <fieldset>
                      <legend className="sr-only">{this.props.isEditForm ? 'Edit EFT - Checking account details' : 'Add EFT - Checking account details'}</legend>
                      <div className="row ml-0">
                        <div className="col-md-12 align-items-center formBorder">
                        <EFTForm className="pl-4 pr-4">
                        <EFTForm.Row className="mbt-med">
                            <div>
                            <div className="d-flex">
                                <label htmlFor="bankHolFirstName" className="eft-label-desc m-0 color-black">
                                {manageWalletFormAem.auth_mw_eft_first_name}
                                 </label>
                                <div className="ml-auto hmfm-id">
                                <span className="text-R uhc-sans-medium token-color-gray-dark-base uhc-font-14">
                                {manageWalletFormAem.auth_mw_required_txt}
                                </span>
                                </div>
                            </div>
                            <Input
                                type="text"
                                id="bankHolFirstName"
                                label={manageWalletFormAem.auth_mw_eft_first_name}
                                value={this.state.bankHolderFirstName}
                                changeHandler={this.bankHolderFirstNameChangedHandler}
                                blurHandler={this.bankHolderFirstNameOnBlur}
                                error={this.state.bankHolderFirstNameError}
                                maxlength='25'
                                pattern="[A-Za-z0-9\s\-\'\.]+"
                                required
                                fieldError="bankHolFirstNameFieldError"
                                invalid
                            />
                            
                            </div>
                        </EFTForm.Row>
                        <EFTForm.Row className="mbt-med">
                            <div>
                            <label htmlFor="bankHolMiddleName" className="eft-label-desc m-0 color-black">
                                {manageWalletFormAem.auth_mw_eft_mdl_name_label}
                            </label>
                            <Input
                                type="text"
                                id="bankHolMiddleName"
                                label={manageWalletFormAem.auth_mw_eft_mdl_name_label}
                                value={this.state.bankHolderMiddleName}
                                changeHandler={this.bankHolderMiddleNameChangedHandler}
                                blurHandler={this.bankHolderMiddleNameOnBlur}
                                maxlength='25'
                                pattern="[A-Za-z0-9\s\-\'\.]+"
                                error={this.state.bankHolderMiddleNameError}
                                fieldError="bankHolMiddleNameFieldError"
                                describe=""
                            />
                            
                            </div>
                        </EFTForm.Row>
                        <EFTForm.Row className="mbt-med">
                            <div>
                            <label htmlFor="bankHolLastName" className="eft-label-desc m-0 color-black">
                                {manageWalletFormAem.auth_mw_eft_lst_name_lbl}
                            </label>
                            <Input
                                type="text"
                                id="bankHolLastName"
                                label={manageWalletFormAem.auth_mw_eft_lst_name_lbl}
                                value={this.state.bankHolderLastName}
                                changeHandler={this.bankHolderLastNameChangedHandler}
                                blurHandler={this.bankHolderLastNameOnBlur}
                                error={this.state.bankHolderLastNameError}
                                maxlength='35'
                                pattern="[A-Za-z0-9\s\-\'\.]+"
                                required
                                fieldError="bankHolLastNameFieldError"
                                describe=""
                            />
                            
                            </div>
                        </EFTForm.Row>
                        <EFTForm.Row className="mbt-med">
                            <div>
                            <div className="uhc-label-help-text">
                                <label htmlFor="routNumber" className="eft-label-desc m-0 color-black uhx-flex-grow">
                                    {manageWalletFormAem.auth_mw_eft_routing_label}
                                </label>
                                <PaymentModals
                                modalContent={this.routingModal}
                                modalType='paymentRoutingNumber'
                                help_label_txt={this.routingModal.help_label_txt}
                                />
                            </div>
                            <Input
                                disabled = {this.props.isEditForm}
                                id="routNumber"
                                type="text"
                                label={manageWalletFormAem.auth_mw_eft_routing_label}
                                value={this.props.isEditForm ? '***' + this.state.routingNumber.slice(-4) : this.state.routingNumber}
                                changeHandler={this.rountingNumberChangedHandler}
                                blurHandler={this.rountingNumberOnBlur}
                                error={this.state.routingNumberError}
                                maxlength='9'
                                inputRef={(el) => (this.routingNumberRef = el)}
                                required
                                fieldError="routNumberFieldError"
                                describe=""
                            />

                            </div>
                        </EFTForm.Row>
                        <EFTForm.Row className="mbt-med">
                            <div>
                            <div className="uhc-label-help-text">
                                <label htmlFor="accountNum" className="eft-label-desc m-0 color-black uhx-flex-grow">
                                    {manageWalletFormAem.auth_mw_eft_acct_label}
                                </label>
                                <PaymentModals
                                modalContent={this.accModal}
                                modalType='paymentAccNumber'
                                help_label_txt={this.accModal.help_label_txt}
                                />
                            </div>

                            <Input
                                disabled = {this.props.isEditForm}
                                id="accountNum"
                                type="text"
                                label={manageWalletFormAem.auth_mw_eft_acct_label}
                                value={this.props.isEditForm ? '***' + this.state.accountNumber.slice(-4) : this.state.accountNumber}
                                changeHandler={this.accountNumberChangedHandler}
                                blurHandler={this.accountNumberOnBlur}
                                error={this.state.accountNumberError}
                                maxlength='17'
                                inputRef={(el) => (this.accountNumberRef = el)}
                                required
                                fieldError="accountNumFieldError"
                                describe=""
                            />

                            </div>
                        </EFTForm.Row>
                        <EFTForm.Row className="mbt-med">
                            <div>
                            <label htmlFor="bankNickname" className="eft-label-desc m-0 color-black">
                                {manageWalletFormAem.auth_mw_nickname_lbl}
                            </label>
                            <Input
                                type="text"
                                id="bankNickname"
                                label={manageWalletFormAem.auth_mw_nickname_lbl}
                                value={this.state.bankHolderNickName}
                                changeHandler={this.bankHolderNickNameChangedHandler}
                                blurHandler={this.bankHolderNickNameOnBlur}
                                maxlength='25'
                                error={this.state.bankHolderNickNameError}
                                fieldError="bankHolNickNameFieldError"
                                describe=""
                            />
                            
                            </div>
                        </EFTForm.Row>
                        {/* <EFTForm.Row className="mbt-lg">
                            <div className="col-md-12 p-0">
                            <Typography.Paragraph id="eftNotes" className="uhc-sans-medium token-color-gray-dark-base m-0" style={{ fontSize: '16px' }}>
                                    EFT- Checking payments typically take up to 3 business days for amount to reflect in your checking account.
                            </Typography.Paragraph>
                            </div>
                        </EFTForm.Row> */}
                        </EFTForm>
                          <div className={"row" + (this.props.eftButtonHide ? 'd-none' : 'd-block')} style={{ margin: '1px' }}>
                            <div
                              id="reviewsubmit"
                              className="col-12 p-3 tds-card"
                            >

                              {this.state.attemptCounter >= 1 && <button
                                name="next"
                                aria-disabled="false"
                                id="submitBtn"
                                className="tds-button tds-button--primary-one tds-margin-xsm-horizontal"
                                onClick={this.onSavePaymentClick}
                              >
                                {manageWalletFormAem.auth_save_pay_method_btn_txt}
                              </button>}
                              {this.state.attemptCounter < 1 && <PaymentModals
                                modalContent={this.nachaZeroModalContent}
                                modalType="nachazero"
                                btn_text={manageWalletFormAem.auth_save_pay_method_btn_txt}
                                />}

                              <button name="next" aria-disabled="false" onKeyDown={this.props.handleCancelBtnKey} id='cancelBtn'
                              className="tds-button tds-button--secondary-one ml-4" onClick={()=>this.onCancelBtnClick()}>
                                {manageWalletFormAem.auth_mw_cancel_btn_txt}
                                </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
              </div>}
            <div>
      
          </div>
        </div>
      </Fragment>
        )
    }
       
}