import { Tab } from '@uhc-tempo/components'
import React, { Component, createRef, Fragment } from 'react';
import BillingHistory from '../BillingHistory/BillingHistory';
import PaymentHistory from '../PaymentHistory/PaymentHistory';
import { overviewBillPayAem } from '../paymentOverview/paymentOverview';
import './HistoryTabs.css';
import {searchPaymentHistoryAPI} from '../../../server/api/searchPaymentHistory-api'
import { trackEvent } from '../../../server/util/analytics'
import moment from 'moment';
import {quickLinkHistoryButton} from '../QuickLinks/QuickLinks';
import { isIos } from '../shared-components/detectDevice';
import { serverCurrentTime } from '../../../server/api/serverTime-api'
import getInvoiceList from '../../../server/api/getInvoiceList-api';
import { logger } from '../../../server/logger';
import secureLocalStorage from 'react-secure-storage';
export let expAccordionState = [];
export class HistoryTabs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            accordionState: true,
            billingHistoryData: null,
            paymentHistoryData : null,
            pendingPaymentData: null,
            processedPaymentData : null,
            billingPrintStartDate : this.props?.readBillingAndTransDtl?.printStartDate,
            paymentPrintStartDate : this.props?.readBillingAndTransDtl?.printStartDate,
            creditBalance : this.props?.readBillingAndTransDtl?.creditBalance,
            selectedValue: {
                paymentHistory: 'payment.history.search.ninety.days',
                billingHistory: 'payment.history.search.ninety.days',
            },
            tabName: 'Payment',
            focus: '',
            loadingHistTable : false,
            billingCurrPage : 1,
            paymentCurrPage : 1,
            currPaymentMethod : this.props.plan.productType === 'SHIP' && this.props?.readBillingAndTransDtl ? this.props?.readBillingAndTransDtl?.paymentMethod : this.props.searchPaymentWalletResults ? this.props.searchPaymentWalletResults.currentPaymentMethod : '',
            pendingPaymentAPIError : false,
            invoiceList: [],
            isbInvoiceError: false
        }
        this.openAccordionRef = createRef();
        this.closedAccordionRef = createRef();
        this.accordionRef = React.createRef()
        this.accordionArrowRef = React.createRef()
        this.tabPaymentRef = React.createRef()
        this.tabBillingRef = React.createRef()
        this.chooseTab = this.chooseTab.bind(this);
      }
    

        chooseTab = (e, tab) => {
            let tabName;
            tab === 0 ? tabName = 'Payment' : tabName = 'Billing';
        let dtmParams = { eventType :'accordionOpen', eventName : tabName === 'Payment' ? 'show payment history':  tabName ==='Billing'? 'show billing history': null };
        if (!quickLinkHistoryButton) {
            trackEvent(dtmParams); 
        }
        e.preventDefault();
        this.setState({ tabName: tabName})
    }

    getCreditBalance = ()=> {
        let creditBalance = this.state.creditBalance
        if(this.props.plan.productType === 'SHIP') {
            return creditBalance && creditBalance > 0 && !this.props?.readBillingAndTransDtl?.paidInFull 
                    && (this.props?.readBillingAndTransDtl?.totalAmountDue === '0' || this.props?.readBillingAndTransDtl?.totalAmountDue === '0.00') ? '$'+parseFloat(creditBalance).toFixed(2) : null
        } else {
            return creditBalance && creditBalance > 0 ? '$'+parseFloat(creditBalance).toFixed(2) : null
        }
    }

    handlePayKeyPress = (event) => {
        if(event.key === 'ArrowRight'){
            this.tabBillingRef.current.click();
            this.tabBillingRef.current.focus();
        } else if(event.key === 'ArrowLeft') {
            this.tabBillingRef.current.click();
            this.tabBillingRef.current.focus();
        }
    }

    handleBillKeyPress = (event) => {
        if(event.key === 'ArrowRight'){
            this.tabPaymentRef.current.click();
            this.tabPaymentRef.current.focus();
        } else  if(event.key === 'ArrowLeft') {
            this.tabPaymentRef.current.click();
            this.tabPaymentRef.current.focus();
        }
    }

    setCurrentPage = (value , tabName)=> {
        if(tabName === 'Payment') {
            this.setState({paymentCurrPage : value})
        } else {
            this.setState({billingCurrPage : value})
        }
    }

    //Load default attributes for History tabs
    historyLoader = (tabName) => {
        this.setState({tabName: tabName});
        if(tabName === 'Payment'){
            this.tabPaymentRef.current.classList.add('active');
            this.tabBillingRef.current.classList.remove('active');
            this.tabPaymentRef.current.setAttribute('aria-selected','true');
            this.tabBillingRef.current.setAttribute('aria-selected','false');
            this.tabPaymentRef.current.setAttribute('tabindex','0');
            this.tabBillingRef.current.setAttribute('tabindex','-1');
                   
        } else {
            this.tabBillingRef.current.classList.add('active');
            this.tabPaymentRef.current.classList.remove('active');
            this.tabPaymentRef.current.setAttribute('aria-selected','false');
            this.tabBillingRef.current.setAttribute('aria-selected','true');
            this.tabPaymentRef.current.setAttribute('tabindex','-1');
            this.tabBillingRef.current.setAttribute('tabindex','0');
        }
        
    }

    //Open & close accordion
    toggleAccordion(e) {
        e.preventDefault();
        this.setState(prevState => {
            return {accordionState: !prevState.accordionState}
        }, ()=> {
        if (this.state.accordionState) {
            this.accordionRef.current.classList.remove('hideAccordionContent');
            this.accordionArrowRef.current.classList.remove('rotateAccordionArrow');
            let historyTabs = document.getElementById('historyTabs_' + this.props.planNum);
            setTimeout(() => {
                if (historyTabs) {
                    historyTabs.scrollIntoView( !isIos() && { behavior: 'smooth', block: 'start'})
                }
            },100)
    
            if(document.getElementById('pagi_' + this.props.planNum)){
                document.getElementById('pagi_' + this.props.planNum).classList.remove('hide');
            }
        } else {
            this.accordionRef.current.classList.add('hideAccordionContent');
            this.accordionArrowRef.current.classList.add('rotateAccordionArrow');
            document.getElementById('historyTabs_' + this.props.planNum).scrollIntoView( !isIos() && {behavior: "smooth", block: "end"})
            if(document.getElementById('pagi_' + this.props.planNum)){
                document.getElementById('pagi_' + this.props.planNum).classList.add('hide');
            }
         }
         expAccordionState[this.props.planNum] = this.state.accordionState 
        })
    }

    multiPlanAccordion() {
        expAccordionState = Array.from({length: this.props.fedShipPlanCount}, i => i = false);
        this.accordionRef.current.classList.add('hideAccordionContent');
        this.accordionArrowRef.current.classList.add('rotateAccordionArrow');
    }



    //Loading history data based on date range selected
    callHistoryApi = (dateRange, isPaymentHist)=> {
        searchPaymentHistoryAPI(this.props.plan, "7", dateRange, 'ACTIVE', 'ALL').then(res => {
            if(res.callFailed || !res.data.success) {
                this.props.handleServiceError(true);
            } 
            if(isPaymentHist) {

                const payHistData = [];
                let paymentHistData = {};
                if(res.data.readBillingAndTransactionDetailResult.paymentHistoryNew) {
                    res.data.readBillingAndTransactionDetailResult.paymentHistoryNew.forEach((d) => {
                            payHistData.push({withdrawalDate: d.paymentDate,
                            amount: d.paymentAmount ,
                            paymentState: d.paymentStatus,
                            bankAccountType: d.paymentMethod,
                            cardType: null,
                            cardNumberLastFour : null,
                            referenceNumber: d.referenceNumber});
                        });
                    }
    
                   if (this.state.processedPaymentData && dateRange !== "payment.history.search.previous.year") {
                        this.state.processedPaymentData.forEach((d) => {
                            payHistData.push({withdrawalDate: d.withdrawalDate,
                            amount: d.amount ,
                            paymentState: d.paymentState,
                            bankAccountType: d.bankAccount?.bankAccountType,
                            accountNumber : d.bankAccount?.accountNumber,
                            cardType: d.creditCard?.cardType,
                            cardNumberLastFour : d.creditCard?.cardNumberLastFour,
                            referenceNumber: d.confirmationNumber});
                        });
                    }
                    paymentHistData = JSON.stringify(payHistData);

                this.setState({paymentHistoryData: paymentHistData? JSON.parse(paymentHistData).sort((a,b)=> new Date(b.withdrawalDate) - new Date(a.withdrawalDate)) :paymentHistData
                    , paymentPrintStartDate : res.data.readBillingAndTransactionDetailResult.printStartDate}, ()=> this.setCurrentPage(1,'Payment'));
                   
            } else {
                this.setState({billingHistoryData: res.data.readBillingAndTransactionDetailResult.paymentHistory ? res.data.readBillingAndTransactionDetailResult.paymentHistory.sort((a,b)=> new Date(b.dueDate) - new Date(a.dueDate)) : res.data.readBillingAndTransactionDetailResult.paymentHistory
                ,billingPrintStartDate : res.data.readBillingAndTransactionDetailResult.printStartDate}, ()=> this.setCurrentPage(1,'Billing'))
            }   

        }).then(()=> this.setState({loadingHistTable : false}))
        .catch(e => {
            console.log(e);
        })
    }

    changeDateRange = (loadState, tab, focus, selectedValue) => {
        let dateSelectedProperty = {...this.state.selectedValue};
            tab === 'Payment' ? dateSelectedProperty.paymentHistory = selectedValue : dateSelectedProperty.billingHistory = selectedValue;
            this.setState({loadingHistTable: loadState, tabName: tab, focus: focus, selectedValue: dateSelectedProperty});
    }
    
    isPreEffective = () => {
        return JSON.parse(localStorage.getItem('consumerDetails')).preEffective && moment(JSON.parse(localStorage.getItem('consumerDetails')).planProfiles[0]?.planStartDate).isAfter(moment())
    }

  getPendingPayments (walletandPaymentsResp) {
        this.setState(
            {
                loadingHistTable: true
            })
                let pendingPaymentResp = {};
                pendingPaymentResp['data']=null;
                if(this.props.plan.productType === 'SHIP' && this.props?.readBillingAndTransDtl !== null && 
                    this.props?.readBillingAndTransDtl?.onetimePaymentMethods !== null && this.props?.readBillingAndTransDtl?.onetimePaymentMethods.length >0){
                    let  onetimePaymentMethods=this.props?.readBillingAndTransDtl?.onetimePaymentMethods.filter(elt => elt.pendingOrProcessedInd === "Null")
                        pendingPaymentResp['data']= onetimePaymentMethods?.length > 0 ? onetimePaymentMethods.sort((a,b) => new moment(b.paymentDate) - new moment(a.paymentDate)) : null
                    }else if(this.props.plan.productType === 'GOVT'){                     
                        const gpsPendingPayments = walletandPaymentsResp?.notProcessedPayments?.filter(payment => {
                            return payment.paymentState.toUpperCase() !== 'FAILED' && payment.paymentState.toUpperCase() !== 'PROCESSED' && payment.paymentState.toUpperCase() !== 'CANCELED';
                        })
                        pendingPaymentResp['data'] = gpsPendingPayments?.sort((a,b)=> new Date(b.withdrawalDate) - new Date(a.withdrawalDate));
                        
                    }
                if (pendingPaymentResp) {
                    this.setState({loadingHistTable: false}, 
                        () => {
                            let num = this.props.planNum
                            setTimeout(function () {
                                
                                let ele = document.getElementById('cancelPendingMsg_' + num) 
                                let cancelMsgID = ele ? ele.getElementsByTagName('a')[0] : null
                                if(cancelMsgID) {
                                    cancelMsgID.setAttribute('aria-label','contactLink_' + num);
                                }
                            }, 1000);
                        })
                    this.setState({pendingPaymentData: pendingPaymentResp});
                }
            
    }

     getIsbPendingPayments (billinghistoryresp, compassPaymentHistory, billinghistoryresp2) {
        this.setState(
            {
                loadingHistTable: true
            })
                let pendingPaymentResp = {};
                pendingPaymentResp['data']=null;

                let pendingPayHistData = [];
                let pendingPaymentHistData = {};
                pendingPaymentHistData['data']=null;
                pendingPaymentResp['data']=null;
                if(this.props.plan.productType === 'SHIP' && !this.props.isbBillingError && billinghistoryresp?.BillingPaymentResponse !== null && 
                    billinghistoryresp?.BillingPaymentResponse?.PaymentHistory !== null && billinghistoryresp?.BillingPaymentResponse?.PaymentHistory?.paymentList !==null && billinghistoryresp?.BillingPaymentResponse?.PaymentHistory?.paymentList?.length > 0){
                    let  onetimePaymentMethods = billinghistoryresp?.BillingPaymentResponse?.PaymentHistory?.paymentList?.filter(elt => elt.transactionType === "PAYMENT" && (elt.status === "Pending Payment" || elt.status === "Incomplete"))
                        pendingPaymentResp['data']= onetimePaymentMethods?.length > 0 ? onetimePaymentMethods.sort((a,b) => new moment(b.receivedDate) - new moment(a.receivedDate)) : null
                    }

         
                    if (pendingPaymentResp.data?.length > 0) {
     
                        pendingPaymentResp.data.forEach((d) => {
                            pendingPayHistData.push({paymentDate: d.receivedDate,
                            paymentAmount: d.tenderAmount ,
                            paymentState: d.status,
                            accountNumber : d.bankAccountNumber,
                            paymentMethod : d.tenderType
                         });
                        });
                    }

                    pendingPaymentResp['data']=null;
                    if(this.props.plan.productType === 'SHIP' && !this.props.isbBillingError && billinghistoryresp2?.BillingPaymentResponse !== null && 
                    billinghistoryresp2?.BillingPaymentResponse?.PaymentHistory !== null && billinghistoryresp2?.BillingPaymentResponse?.PaymentHistory?.paymentList !==null && billinghistoryresp?.BillingPaymentResponse?.PaymentHistory?.paymentList?.length > 0){
                        let  onetimePaymentMethods = billinghistoryresp2?.BillingPaymentResponse?.PaymentHistory?.paymentList?.filter(elt => elt.transactionType === "PAYMENT" && (elt.status === "Pending Payment" || elt.status === "Incomplete"))
                            pendingPaymentResp['data']= onetimePaymentMethods?.length > 0 ? onetimePaymentMethods.sort((a,b) => new moment(b.receivedDate) - new moment(a.receivedDate)) : null
                        }

                        if (pendingPaymentResp.data?.length > 0) {
     
                            pendingPaymentResp.data.forEach((d) => {
                                pendingPayHistData.push({paymentDate: d.receivedDate,
                                paymentAmount: d.tenderAmount ,
                                paymentState: d.status,
                                accountNumber : d.bankAccountNumber,
                                paymentMethod : d.tenderType
                             });
                            });
                        }
                    pendingPaymentHistData['data'] = pendingPayHistData;
                if (pendingPaymentHistData) {
                    this.setState({loadingHistTable: false}, 
                        () => {
                            let num = this.props.planNum
                            setTimeout(function () {
                                
                                let ele = document.getElementById('cancelPendingMsg_' + num) 
                                let cancelMsgID = ele ? ele.getElementsByTagName('a')[0] : null
                                if(cancelMsgID) {
                                    cancelMsgID.setAttribute('aria-label','contactLink_' + num);
                                }
                            }, 1000);
                        })
                    if(!this.props.isbBillingError) {
                        this.setState({pendingPaymentData:  pendingPaymentHistData.length > 0 ? JSON.parse(pendingPaymentHistData).sort((a,b)=> new Date(b.paymentDate) - new Date(a.paymentDate)) :pendingPaymentHistData, loadingHistTable: false });
                    } else {
                        this.setState({pendingPaymentData: null});
                    }
                }
    } 


    getProcessedPayments (walletandPaymentsResp) {
        let processedPaymentResp = {};
        var currenttime;
        if(secureLocalStorage.getItem('currSysTimeInMs')){
            currenttime = Number.parseInt(secureLocalStorage.getItem('currSysTimeInMs'), 10);		
        }else{				
        serverCurrentTime().then((time) => {
             currenttime = Number.parseInt(time.systemtimeinmillis, 10);	
             secureLocalStorage.setItem('currSysTimeInMs',time.systemtimeinmillis);									
          }); 
        }
        var filterDate = moment(currenttime).subtract(60,'days').format('YYYY-MM-DD')
        if (this.props.plan.productType === 'GOVT'){
            processedPaymentResp['data']=null;
            processedPaymentResp = walletandPaymentsResp.notProcessedPayments?.filter(payment => {
            var wdate = new Date(payment.withdrawalDate);
                
            return ((payment.paymentState === 'FAILED' || payment.paymentState === 'PROCESSED' || payment.paymentState === 'CANCELED') && (moment(wdate) >= moment(filterDate)) ); // last 60 days
            })
            this.setState({processedPaymentData: processedPaymentResp});
        }

        let payHistData = [];
        let paymentHistData = {};
        
        if(this.props?.readBillingAndTransDtl?.paymentHistoryNew)  {
            this.props.readBillingAndTransDtl.paymentHistoryNew.forEach((d) => {
                        payHistData.push({withdrawalDate: d.paymentDate,
                        amount: d.paymentAmount ,
                        paymentState: d.paymentStatus,
                        bankAccountType: d.paymentMethod,
                        accountNumber : null,
                        cardType: null,
                        cardNumberLastFour : null,
                        referenceNumber: d.referenceNumber});
                    });
                }
                if (processedPaymentResp?.length > 0) {
                    processedPaymentResp.forEach((d) => {
                        payHistData.push({withdrawalDate: d.withdrawalDate,
                        amount: d.amount ,
                        paymentState: d.paymentState,
                        bankAccountType: d.bankAccount?.bankAccountType,
                        accountNumber : d.bankAccount?.accountNumber,
                        cardType: d.creditCard?.cardType,
                        cardNumberLastFour : d.creditCard?.cardNumberLastFour,
                        referenceNumber: d.confirmationNumber });
                    });
                }
                paymentHistData = JSON.stringify(payHistData);
        this.setState({paymentHistoryData: paymentHistData.length > 0 ? JSON.parse(paymentHistData).sort((a,b)=> new Date(b.withdrawalDate) - new Date(a.withdrawalDate)) :paymentHistData});
        this.setState({billingHistoryData: this.props?.readBillingAndTransDtl?.paymentHistory ? this.props?.readBillingAndTransDtl?.paymentHistory.sort((a,b)=> new Date(b.dueDate) - new Date(a.dueDate)) : this.props?.readBillingAndTransDtl?.paymentHistory})    
        
    }
    

    getISBProcessedPayments (billinghistoryresp, compassPaymentHistory, billinghistoryresp2) {
        let payHistData = [];
        let paymentHistData = {};
        if(compassPaymentHistory)  {
            compassPaymentHistory.forEach((d) => {
                        payHistData.push({withdrawalDate: d.paymentDate,
                        amount: d.paymentAmount ,
                        paymentState: d.paymentStatus,
                        bankAccountType: d.paymentMethod,
                        accountNumber : d.bankAccountNumber,
                        cardType: null,
                        cardNumberLastFour : null,
                        referenceNumber: d.referenceNumber});
                    });
                }

        if(billinghistoryresp?.BillingPaymentResponse?.PaymentHistory?.paymentList)  {
            let processedData = billinghistoryresp?.BillingPaymentResponse?.PaymentHistory?.paymentList?.filter(elt =>
                elt.transactionType === "PAYMENT" && 
                (elt.status === "Frozen" || elt.status === 'Error' || elt.status === 'Rejected') &&
                !(elt.tenderType === 'Manual Pension Payment' || elt.tenderType === 'Pension Payment - Saving'))
            processedData.forEach((d) => {
                        payHistData.push({withdrawalDate: d.receivedDate,
                        amount: d.status === 'Rejected' ? d.tenderAmount : d.amount,
                        paymentState: d.status,
                        bankAccountType: d.tenderType,
                        accountNumber : d.bankAccountNumber,
                        cardType: null,
                        cardNumberLastFour : null,
                        referenceNumber: d.confirmationNumber});
                    });
                }
        if(billinghistoryresp2?.BillingPaymentResponse?.PaymentHistory?.paymentList)  {
            let processedData = billinghistoryresp2?.BillingPaymentResponse?.PaymentHistory?.paymentList?.filter(elt =>
                elt.transactionType === "PAYMENT" && 
                (elt.status === "Frozen" || elt.status === 'Error' || elt.status === 'Rejected') &&
                !(elt.tenderType === 'Manual Pension Payment' || elt.tenderType === 'Pension Payment - Saving'))
            processedData.forEach((d) => {
                        payHistData.push({withdrawalDate: d.receivedDate,
                        amount: d.status === 'Rejected' ? d.tenderAmount : d.amount,
                        paymentState: d.status,
                        bankAccountType: d.tenderType,
                        accountNumber : d.bankAccountNumber,
                        cardType: null,
                        cardNumberLastFour : null,
                        referenceNumber: d.confirmationNumber});
                    });
        }
                paymentHistData = JSON.stringify(payHistData);
        if(!this.props.searchPaymentHistoryError && !this.props.isbBillingError) {
            this.setState({paymentHistoryData: paymentHistData.length > 0 ? JSON.parse(paymentHistData).sort((a,b)=> new Date(b.withdrawalDate) - new Date(a.withdrawalDate)) :paymentHistData, loadingHistTable: false});    
        } else {
            this.setState({paymentHistoryData : null})
        }
    }

    IsbBillingData(isbBilling, isbBillingSecondCall) {
        let IsbBillingHistory = [];
        
            if (isbBilling !== null) {
                isbBilling.forEach(bill => {
                    IsbBillingHistory.push({
                        dueDate: bill.dueDate,
                        amount: bill.totalDueAmount,
                        status: bill.invoiceStatus,
                        remainingAmount: bill.invoiceBalanceDue,
                        invoiceNumber: bill.invoiceNumber,
                        invoiceLink: bill.invoiceLink,
                        invoiceDate: bill.invoiceDate
                    })
                })
            }

            if (isbBillingSecondCall !== null) {
                isbBillingSecondCall.forEach(bill => {
                    IsbBillingHistory.push({
                        dueDate: bill.dueDate,
                        amount: bill.totalDueAmount,
                        status: bill.invoiceStatus,
                        remainingAmount: bill.invoiceBalanceDue,
                        invoiceNumber: bill.invoiceNumber,
                        invoiceLink: bill.invoiceLink,
                        invoiceDate: bill.invoiceDate
                    })
                })
            }

            if (IsbBillingHistory.length > 0) {
                IsbBillingHistory.sort((a,b) => moment(b.dueDate) - moment(a.dueDate))
            }
            return IsbBillingHistory;
    }


    getIsbInvoiceList = async (individualId, toDate, fromDate, dateRange) => {
        
        let invoiceListResponse = await getInvoiceList(individualId, toDate, fromDate)
        let invoiceList = null;
        if(invoiceListResponse.callFailed || invoiceListResponse.httpStatus !== null) {
            this.setState({isbInvoiceError : true})
        } else {
            invoiceList = invoiceListResponse.response?.invoiceList;
        }
        let invoiceList2 = null;
        let billingHistoryData = null;
        if (dateRange === 'payment.history.search.twentyfour.months') {
            let secondYearDateStart = moment().subtract(12, 'months').format('YYYY-MM-DD');
            let invoiceListResponse2 = await getInvoiceList(individualId, secondYearDateStart, fromDate)
            if(invoiceListResponse2.callFailed || invoiceListResponse2.httpStatus !== null) {
                this.setState({isbInvoiceError : true})
            } else {
                invoiceList2 = invoiceListResponse2?.response?.invoiceList
            }
        }
        
            if(!this.state.isbInvoiceError) {
                billingHistoryData = this.IsbBillingData(invoiceList, invoiceList2)
            }
            this.setState({ invoiceListResponse, billingHistoryData }, () => this.setState({ loadingHistTable: false }));
            this.props.setIsbInvoiceResponse(invoiceListResponse);
        }

    componentDidUpdate(prevProps, prevState) {
            if (prevProps.isbBillingPayments !== this.props.isbBillingPayments || prevProps.isbBillingPayments2 !== this.props.isbBillingPayments2 || prevProps.compassPaymentHistory !== this.props.compassPaymentHistory) {
                this.getIsbPendingPayments(this.props.isbBillingPayments, this.props.isbBillingPayments2)
                this.getISBProcessedPayments(this.props.isbBillingPayments, this.props.compassPaymentHistory, this.props.isbBillingPayments2)
            }
    }
    componentDidMount() {
        let payhistory = document.getElementById('tabPayment_' + this.props.planNum);
        let isbInvoiceToDate = moment().format('YYYY-MM-DD')
        let isbPrev90Days = moment().subtract(90, 'days').format('YYYY-MM-DD')
        
        setTimeout(() => {
            if(payhistory){
                payhistory.removeAttribute('tabindex')
                payhistory.removeAttribute('role')
            }
        },100)
        if (this.props.plan.productType === 'SHIP' && this.props.shipPlanDetails && this.props.shipPlanDetails.length > 0) {
                logger.info('Props Plan of getIsbInvoiceList',this.props.plan);
                this.getIsbInvoiceList(this.props.shipPlanDetails[0]?.individualId, isbInvoiceToDate, isbPrev90Days);
                this.getIsbPendingPayments(this.props.isbBillingPayments, this.props.isbBillingPayments2)
                this.getISBProcessedPayments(this.props.isbBillingPayments, this.props.compassPaymentHistory, this.props.isbBillingPayments2)
        } else {
            this.getPendingPayments(this.props.searchPaymentWalletResults);
            this.getProcessedPayments(this.props.searchPaymentWalletResults);
        }

        if(!this.isPreEffective() || (this.isPreEffective() && this.props.isFirstBillGeN)){
            expAccordionState = Array.from({length: this.props.fedShipPlanCount}, i => i = true);
            if(this.props.fedShipPlanCount > 1) {
                this.setState({accordionState: false}, () => this.multiPlanAccordion())
            }
        }

    }

    handleKeyAccordion = (event) => {
        if(!this.state.accordionState && event.shiftKey && event.key === "Tab"){
            event.preventDefault();
            document.getElementById('recurrinBtn_' + this.props.planNum).focus();
        }
    } 

    render() {
        
        return (
            <>

            {this.isPreEffective() === true && !this.props.planData?.isFirstBillGen && !this.props.isFirstBillGeN ? (
                <div className="historyAccordion">
                    <button role="link" type="button" disabled className="accordionBtn tds-link tds-link--disabled">
                        <span >{overviewBillPayAem.auth_show_pay_bill_accord_label}</span>
                        <span>
                        <svg id="accordionArrow" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" focusable="false" aria-hidden="true" class="rotateAccordionArrow accordionArrow">
                            <title>EF25C192-9768-42BF-85FC-00D5171FF2DA@2x</title>
                            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g id="Full-icon-library-(all-24px)/Hardware/ic_keyboard_arrow_up" fill="#6F6F6F">
                                    <polygon id="🎨Icon-Color" points="7.41 15.41 12 10.83 16.59 15.41 18 14 12 8 6 14"></polygon>
                                </g>
                            </g>
                        </svg>
                        </span>
                    </button>
                    
                </div>
            ):
            (
                <Fragment>
                    <div className="accordionContent" id={'accordionContent_' + this.props.planNum} ref={this.accordionRef} role="region" aria-labelledby={'accordionid_' + this.props.planNum}>
                     <Tab autoResize={false} activeTab={this.state.tabName === 'Payment' ? 0 : 1} onTabChange={this.chooseTab} tabHeadings={[overviewBillPayAem.auth_pay_history_tabname, overviewBillPayAem.auth_bill_history_tabname]} className="tabs" id={'historyTabs_' + this.props.planNum} role="tablist" aria-label="Payment & Billing History Section">
                            <Tab.TabPanel id={'tabPayment_' + this.props.planNum}>
                            <PaymentHistory
                            planNum={this.props.planNum}
                            planData={this.props.planData}
                            estimatedAnnualPremium={this.props?.readBillingAndTransDtl?.estimatedAnnualPremium}
                            paymentHistoryData = {this.state.paymentHistoryData} 
                            pendingPaymentData = {this.state.pendingPaymentData}
                            processedPaymentData = {this.state.processedPaymentData}
                            plan={this.props.plan}
                            callHistoryApi={this.callHistoryApi}
                            getIsbBillingPayments={this.props.getIsbBillingPayments}
                            changeDateRange={this.changeDateRange}
                            setCurrentPage = {this.setCurrentPage}
                            loadingHistTable={this.state.loadingHistTable}
                            selectedValue={this.state.selectedValue.paymentHistory}
                            tabName={this.state.tabName}
                            currPage = {this.state.paymentCurrPage}
                            IsbPaymentPrintStartDate = {this.props.IsbPaymentPrintStartDate}
                            paymentPrintStartDate = {this.state.paymentPrintStartDate}
                            getCreditBalance = {this.getCreditBalance}
                            pendingPaymentAPIError = {this.state.pendingPaymentAPIError}
                            superUser={this.props.superUser}
                            superUserErr={this.props.superUserErr}
                            handleSuperUserErr={this.props.handleSuperUserErr}
                            isbBillingError={this.props.isbBillingError}
                            searchPaymentHistoryError={this.props.searchPaymentHistoryError}
                            shipPlanDetails={this.props.shipPlanDetails}
                            firstName = {this.props.memberFirstName}
                            middleName = {this.props.memberMiddleName}
                            lastName = {this.props.memberLastName}
                            />
                            </Tab.TabPanel>
                            
                            <Tab.TabPanel>
                            <BillingHistory
                            planNum={this.props.planNum}  
                            billHistoryData={this.state.billingHistoryData} 
                            plan={this.props.plan}
                            planData={this.props.planData}
                            callHistoryApi={this.callHistoryApi}
                            changeDateRange={this.changeDateRange}
                            setCurrentPage = {this.setCurrentPage}
                            selectedValue={this.state.selectedValue.billingHistory}
                            loadingHistTable={this.state.loadingHistTable}
                            tabName={this.state.tabName}
                            currPage = {this.state.billingCurrPage}
                            currPaymentMethod = {this.state.currPaymentMethod}
                            billingPrintStartDate = {this.state.billingPrintStartDate}
                            isbInvoiceList = {this.state.invoiceList}
                            getIsbInvoiceList = {this.getIsbInvoiceList}
                            isbInvoiceError={this.state.isbInvoiceError}
                            searchPaymentHistoryError={this.props.searchPaymentHistoryError}
                            shipPlanDetails={this.props.shipPlanDetails}
                            firstName = {this.props.memberFirstName}
                            middleName = {this.props.memberMiddleName}
                            lastName = {this.props.memberLastName}
                            />
                            </Tab.TabPanel>  
                        </Tab> 
                    </div>
                    <div className={this.state.accordionState ? 'historyAccordion' : 'historyAccordion--Collapse '} ref={this.openAccordionRef}>
                        <button aria-expanded={this.state.accordionState ? 'true' : 'false'} onKeyDown={this.handleKeyAccordion} aria-controls={'accordionContent_' + this.props.planNum} id={'accordionid_' + this.props.planNum} onClick={e => this.toggleAccordion(e)} className="accordionBtn tds-link tds-link--medium">
                            <span >{this.state.accordionState ? overviewBillPayAem.auth_hide_pay_bill_accord_label : overviewBillPayAem.auth_show_pay_bill_accord_label}</span>
                            <span>
                            <svg ref={this.accordionArrowRef} className="accordionArrow" width="24px" height="24px" focusable="false" aria-hidden="true" viewBox="0 0 24 24" version="1.1">
                                <title>EF25C192-9768-42BF-85FC-00D5171FF2DA@2x</title>
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <g fill="#196ECF">
                                        <polygon points="7.41 15.41 12 10.83 16.59 15.41 18 14 12 8 6 14"></polygon>
                                    </g>
                                </g>
                            </svg>
                        </span>
                        </button>
                        
                    </div>
                </Fragment>
            )
        }
        </>
            
            )
    }
}

export default HistoryTabs
