import axios from 'axios';
import appConfigs from '../app.config';

export const getM3PService = async (accountId) => {
    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        },

    }
    let m3pResponse = null;
    
    try {
	  const response = await axios.get(`${appConfigs.m3p_service_path}/${accountId}/v1`, {}, axiosConfig);
    m3pResponse = response.data;
    } catch (error) {
        console.error(error);
        m3pResponse = null;
    }

    console.log('m3pResponse', m3pResponse);
    return m3pResponse;
};