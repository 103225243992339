import { DatePicker, FormGroup, RadioButton, Typography, Form } from '@uhc-tempo/components'
import moment from 'moment'
import React, { Component } from 'react';
import { selectPaySumAem } from './FedPaymentForm';
import { trackLinkClick } from '../../../server/util/analytics'
import { isEditPayment, getOneTimePaymentFlag } from '../../../server/util/localStorageRepo';
import secureLocalStorage from 'react-secure-storage';


export class SchedulePayment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            firstDay: moment().add(1, 'day'),
            lastDate: moment().add(30, 'day'),
            scheduleChoice: this.props.isRedirectFromReview ? secureLocalStorage.getItem('scheduleChoice') : this.props.scheduleChoice,
        }
        // secureLocalStorage.setItem("scheduleChoice", this.props.scheduleChoice);
    }

    componentDidMount(){
        if(isEditPayment() || this.props.isRedirectFromReview){
            this.setState({
                scheduleChoice: this.props.isRedirectFromReview ? secureLocalStorage.getItem('scheduleChoice') : this.props.scheduleChoice
            })
        }
    }

    handleScheduleChoice(e) {
        if (e.target.value === 'schedule') {
            this.props.setPaymentDate(null);
            trackLinkClick(isEditPayment() ? 'mr.premium-payments.update-onetime-payment.schedule-payments.radio-button.schedule' : 'mr.premium-payments.schedule-payments.radio-button.schedule')
            console.log(isEditPayment() ? 'mr.premium-payments.update-onetime-payment.schedule-payments.radio-button.schedule' : 'mr.premium-payments.schedule-payments.radio-button.schedule')
            document.getElementById('schPayId').setAttribute('aria-describedby','sch_note_id')
        }
        else if (e.target.value === 'payNow') {
            this.props.setPaymentDate(moment().format('MM-DD-YYYY'))
            trackLinkClick(isEditPayment() ? 'mr.premium-payments.update-onetime-payment.schedule-payments.radio-button.pay-now' : 'mr.premium-payments.schedule-payments.radio-button.pay-now')
            console.log(isEditPayment() ? 'mr.premium-payments.update-onetime-payment.schedule-payments.radio-button.pay-now' : 'mr.premium-payments.schedule-payments.radio-button.pay-now')
            document.getElementById('schPayId').removeAttribute('aria-describedby')
        } 
        this.props.setScheduleChoice(e.target.value);
        secureLocalStorage.setItem("scheduleChoice", e.target.value);
        let choice = e.target.value
        setTimeout(() => {
            if(choice == 'schedule' && document.getElementById('scheduleDate')) {
                let schLabel = document.getElementById('scheduleDate').children[0].getElementsByTagName('label')[0]
                let schDtInput = document.getElementById('scheduleDate').children[0].children[1].getElementsByTagName('input')[0] 

                    schLabel.setAttribute('for','datePickerInputId')
                    let schInpAriaDes = schDtInput.getAttribute('aria-describedby')
                    schInpAriaDes = schInpAriaDes.split(' ').filter(el=> el.indexOf('instruction') > -1)[0]
                    schDtInput.setAttribute('aria-describedby',schInpAriaDes)
                
            }
        }, 1000)
    }
   analyticsDatePicker (){
        trackLinkClick('mr.premium-payments.schedule-payments.date-picker')
        console.log('mr.premium-payments.schedule-payments.date-picker')
    }
    render() {
        
        if((isEditPayment() || this.props.isRedirectFromReview) && this.state.scheduleChoice == 'schedule' && document.getElementById('scheduleDate')) {
            let schLabel = document.getElementById('scheduleDate').children[0].getElementsByTagName('label')[0]
            let schDtInput = document.getElementById('scheduleDate').children[0].children[1].getElementsByTagName('input')[0] 
            console.log(document.getElementById('scheduleDate').children[0].getElementsByTagName('label')[0])
            console.log(schLabel)
            setTimeout(() => {
                schLabel.setAttribute('for','datePickerInputId')
                let schInpAriaDes = schDtInput.getAttribute('aria-describedby')
                schInpAriaDes = schInpAriaDes.split(' ').filter(el=> el.indexOf('instruction') > -1)[0]
                schDtInput.setAttribute('aria-describedby',schInpAriaDes)
            }, 0)
        }

        return (
            <div className={isEditPayment()? 'scheduleRadiosUpdt' : 'scheduleRadios'}>
                <Form>
                    <FormGroup>
                    <legend className="sr-only">{this.props.schedulePayHeading}</legend>
                        {((isEditPayment() && this.props.isPaymentToday) || getOneTimePaymentFlag()) && <RadioButton 
                            className='payNowRadio'
                            checked={this.props.scheduleChoice === 'payNow'}
                            label={selectPaySumAem?.auth_fed_pay_now_radio}
                            value="payNow"
                            onChange={(e) => this.handleScheduleChoice(e)}
                        />}
                        {((isEditPayment() && this.props.isPaymentToday) || getOneTimePaymentFlag()) && <RadioButton 
                            className='scheduleRadio'
                            checked={this.props.scheduleChoice === 'schedule'}
                            label={selectPaySumAem?.auth_fed_sch_radio}
                            value="schedule"
                            id="schPayId"
                            onChange={(e) => this.handleScheduleChoice(e)} 
                        />}
                    </FormGroup>
                </Form>
                { this.props.scheduleChoice === 'schedule' &&
                    <div className={isEditPayment() ? 'datePicker updt' : 'datePicker'}>
                        <Typography.Paragraph id="sch_note_id">{selectPaySumAem?.auth_sch_pay_note}</Typography.Paragraph>
                        <DatePicker 
                            label={this.props.schedulePaymentDateLbl}
                            id="scheduleDate"
                            onDateChange={(d) => {this.analyticsDatePicker() 
                            this.props.setPaymentDate(d)}}
                            min={this.state.firstDay.format('YYYY-MM-DD')} 
                            max={this.state.lastDate.format('YYYY-MM-DD')} 
                            defaultValue={((isEditPayment() && !this.props.isPaymentToday) || (this.props.isRedirectFromReview && this.props.paymentDate)) ? moment(this.props.paymentDate, "MM/DD/YYYY").format('YYYY-MM-DD') : undefined}
                            instructionText={selectPaySumAem?.auth_sch_date_note}
                            invalidDateErrorText = {this.props.datePickerErrorText}
                            errorMessageProps={{ "id": "datePickerErrMsgId" }}
                            inputProps={{ "id": "datePickerInputId"}}
                            state = {this.props.cssState} 
                            />
                    </div>
                }
            </div>
        )
    }
}

export default SchedulePayment
