import React from 'react';
import './PageHeader.css';
import  OBreadcrumbs  from '../Breadcrumbs/OBreadcrumbs';
import { getOneTimePageLink, getOneTimePaymentFlag, getOverviewPageLink, getRecurringPageLink, getRecurringPaymentFlag } from '../../../server/util/localStorageRepo';
import { Typography } from '@uhc-tempo/components';
import secureLocalStorage from 'react-secure-storage';

const PageHeader = (props)=> {
    const mobilebackbtnhandler = () => {
        if(secureLocalStorage.getItem('currPage') === 'form' || secureLocalStorage.getItem('currPage') === 'fedForm' || secureLocalStorage.getItem('currPage') === 'ssa-rrb'){
            window.location.assign(getOverviewPageLink());
        } else if(secureLocalStorage.getItem('currPage') === 'review'){
            if(getOneTimePaymentFlag()){
                window.location.assign(getOneTimePageLink());
            } else {
                window.location.assign(getRecurringPageLink());
            }
         } else if(secureLocalStorage.getItem('currPage') === 'confirm'){
            window.location.assign(getOverviewPageLink());
         } else {
            window.location.assign(getOverviewPageLink());
         }
     }
    return(
        <React.Fragment>
        
        <div className="page-header">
            <div className="container">
                <div className="d-flex justify-content-between">
                    <OBreadcrumbs links={props.links}/>
                    {secureLocalStorage.getItem('currPage') === 'form' || secureLocalStorage.getItem('currPage') === 'fedForm' || secureLocalStorage.getItem('currPage') === 'review' || secureLocalStorage.getItem('currPage') === 'ssa-rrb' ? (
                        <ol className="tds-breadcrumbs__list uhc-breadcrumb-mobile" id="breadcrumb-2">
                        <li className="tds-breadcrumbs__listitem">
                                <svg
                                className="MuiSvgIcon-root tds-utility-icon tds-breadcrumbs__chevron MuiSvgIcon-fontSizeSmall arrow_reverse"
                                focusable="false"
                                viewBox="0 0 24 24"
                                color="#196ecf"
                                aria-hidden="true"
                                role="img"
                            >
                                <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                            </svg>
                            <a style={{cursor: 'pointer'}} onClick={() => mobilebackbtnhandler()} className="tds-breadcrumbs__link"><span className="tds-breadcrumbs__link-text">{props.back_btn}</span></a>
                            
                            </li>
                        </ol>
                    ) :null}
                    
                    
                </div>
                <div>
                       <Typography.H1 id="globalContentIdForSkipLink"> {props.title} </Typography.H1>
                    <Typography.H2 className="page-header-plan">
                        {props.plan}
                    </Typography.H2>
                </div>
            </div>
        </div>
        </React.Fragment>
    );
};

export default PageHeader;