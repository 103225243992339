import { Typography } from "@uhc-tempo/components";
import React, { useState } from "react";
import { getOneTimePaymentFlag, getRecurringPaymentFlag,
   isManageRecurring, isCurrentRecurring,getFuturePaymentMethod, isEditPayment,
   getPaymentMethod,
   getEftNumber,
   getMemberLastName,
   getMemberFirstName,
   getPlanInformation} from '../../../server/util/localStorageRepo';
import Enroll from '../../../src/tokens/icons/icon-enroll.png';
import { convertToCurrency } from '../../../server/util/util'
import moment from "moment";
import secureLocalStorage from "react-secure-storage";


const FedPaymentSummary = (props) => {
  secureLocalStorage.setItem('totalAmount',props.payAmount);
    const [currentState,]= useState({
        planInfo: getPlanInformation(),        
        firstName: getMemberFirstName().toLowerCase(),
        lastName: getMemberLastName().toLowerCase()
    })
    
    const [paymentToEdit, ] = useState(JSON.parse(secureLocalStorage.getItem('paymentToEdit')))
    const changeAccountTypeCase = (accountType) => {
      return accountType.charAt(0) + accountType.slice(1).toLowerCase().toLowerCase()
    }

    const currentMop = () => {
      let modifiedAccountType = paymentToEdit?.creditCard === null ? 'EFT-' + changeAccountTypeCase(paymentToEdit?.bankAccount?.bankAccountType) : changeAccountTypeCase(paymentToEdit?.creditCard?.cardType);
      let lastFour = paymentToEdit?.creditCard ? paymentToEdit.creditCard?.cardNumberLastFour : paymentToEdit?.bankAccount.accountNumber.slice(-4)

      return (
      <p className="uhc-sans-medium token-color-gray-dark-base m-0 pad5 labelPaddingTop upd-mop">
        {modifiedAccountType + ' ***' + lastFour}  
      </p>
      )
    }

    const currentPaymentDate = () => {
      return (
      <p className="uhc-sans-medium token-color-gray-dark-base m-0 pad5 labelPaddingTop">
        {props.paymentDate ? moment(props.paymentDate, 'MM/DD/YYYY').format('MM/DD/YYYY') : null}
      </p>
      )
    }
    return (     
        <div id="paymentsummaryID" className="col-md-4 mr-0">
        
          <div className="pl-5 pt-5 pr-5 pb-0">
            <div className="row payment-summary-t">
              <div className="col-10">
                <Typography.H3 className="payment-summary-title-txt">
                  {props.summaryCnt.summary_label}
                </Typography.H3>
              </div>
            </div>
          </div>
          {!getOneTimePaymentFlag() && getRecurringPaymentFlag() && isManageRecurring(currentState.planInfo.productType) ? (
              <Typography.H4 className="enroll_Banner">
              <span>
              <img src={Enroll} alt="" aria-hidden="true"/>
              </span>
              <span>
                {props.summaryCnt.enrolltext_label}
              </span>
            </Typography.H4>
          ) : null }
          

          <div className={getOneTimePaymentFlag() || isEditPayment() || props.cmsSSA || getRecurringPaymentFlag() && !isManageRecurring(currentState.planInfo.productType) ? "payment-summary-cont mt-5" : "payment-summary-cont"}>

          <ul className="row mt-2">
            <li className="col-md-5">
              <span className="token-font-family-heading-three token-color-gray-dark-base m-0 pad5 rightLineHgt">
                {props.summaryCnt.plan_label}
              </span>
            </li>
            <li className="col-md-7">
              <p className="uhc-sans-medium token-color-gray-dark-base m-0 pad5 labelPaddingTop">
                {secureLocalStorage.getItem("shipPlanNamesList") && currentState.planInfo.productType === 'SHIP' ? secureLocalStorage.getItem('multiShipPlanType') : currentState.planInfo.planType}
              </p>
            </li>
          </ul>
          <ul className="row mt-2">
            <li className="col-md-5">
              <span className="token-font-family-heading-three token-color-gray-dark-base m-0 pad5 rightLineHgt">
               {props.summaryCnt.membername_lable}
              </span>
            </li>
            <li className="col-md-7">
              <p className="uhc-sans-medium token-color-gray-dark-base mt-2 uhc-bold-capitalize">
              {currentState.firstName}{" "}{currentState.lastName}
              </p>
            </li>
          </ul>
          <ul className="row mt-2">
            <li className="col-md-5">
              <span className="token-font-family-heading-three token-color-gray-dark-base m-0 pad5 rightLineHgt">
                {props.summaryCnt.id_label}
              </span>
            </li>
            <li className="col-md-7">
                  <p className="uhc-sans-medium token-color-gray-dark-base m-0 pad5 labelPaddingTop">{currentState.planInfo.memberNumber}</p>   
            </li>
          </ul>
          {getRecurringPaymentFlag() && !isCurrentRecurring() ? (
            <>
            <ul className="row mt-2">
            <li className="col-md-5">
              <span className="token-font-family-heading-three token-color-gray-dark-base m-0 pad5 rightLineHgt">
                {props.summaryCnt.details_label}
              </span>
            </li>
            <li className="col-md-7">
                  <p className="uhc-sans-medium token-color-gray-dark-base m-0 pad5 labelPaddingTop">{props.cancelRecChecked ? props.summaryCnt.cancelRec_label : (getFuturePaymentMethod().indexOf('EFT') > -1 || getFuturePaymentMethod().indexOf('Credit') > -1) ? props.summaryCnt.updateautopay_label :  props.summaryCnt.setupautopay_label}</p>   
            </li>
            <li className="col-md-5">
              <span className="token-font-family-heading-three token-color-gray-dark-base m-0 pad5 twoLineTxtLabel">
                {props.summaryCnt.currentpaymentmethod_label}
              </span>
            </li>
            <li className="col-md-7">
              {getPaymentMethod() !== null && getPaymentMethod().indexOf('CMS-SSA/RRB') > -1 ? (
                <p className="uhc-sans-medium token-color-gray-dark-base m-0 pad5 labelPaddingTop">{props.summaryCnt.auth_cms_ssa_rrb_txt}</p>
              ) : <p className="uhc-sans-medium token-color-gray-dark-base m-0 pad5 labelPaddingTop">{props.summaryCnt.auth_direct_bill_txt}</p> }  
            </li>
          </ul>
          {!isManageRecurring(currentState.planInfo.productType) ? 
            <ul className="row mt-2">
            <li className="col-md-5">
              <span className="token-font-family-heading-three token-color-gray-dark-base m-0 pad5 twoLineTxtLabel">
              {props.summaryCnt.startDate_label}
              </span>
            </li>
            <li className="col-md-7">
            {currentPaymentDate()}
            </li>
          </ul> : null
          }
          
        </>
          ) : null}
          {getRecurringPaymentFlag() && isCurrentRecurring() ? (
            <ul className="row mt-2">
            <li className="col-md-5">
              <span className="token-font-family-heading-three token-color-gray-dark-base m-0 pad5 twoLineTxtLabel">
                {props.summaryCnt.currentpaymentmethod_label}
              </span>
            </li>
            <li className="col-md-7">
              {getPaymentMethod() !== null && getPaymentMethod().indexOf('Credit') > -1 ? (
                <p className="uhc-sans-medium token-color-gray-dark-base m-0 pad5 labelPaddingTop">Credit Card</p>
              ) : null }
              {getPaymentMethod() !== null && getPaymentMethod().indexOf('EFT') > -1 ? (
                <p className="uhc-sans-medium token-color-gray-dark-base m-0 pad5 labelPaddingTop">{getPaymentMethod().toLowerCase().indexOf('checking') > -1 ? props.summaryCnt.eftchecking_label : props.summaryCnt.eftsavings_label}{'***'}{getEftNumber()}</p>
              ) : null }
              {getPaymentMethod() !== null && getPaymentMethod().indexOf('CMS-SSA/RRB') > -1 ? (
                <p className="uhc-sans-medium token-color-gray-dark-base m-0 pad5 labelPaddingTop">CMS-SSA/RRB (SSA Deduction)</p>
              ) : null }  
            </li>
          </ul>
          ) : null}
          {(getOneTimePaymentFlag() && !props.cmsSSA ) ? (
              <ul className="row mt-2">
              <li className="col-md-5">
                <span className="token-font-family-heading-three token-color-gray-dark-base m-0 pad5 rightLineHgt">
                {props.summaryCnt.details_label}
                </span>
              </li>
              <li className="col-md-7">
                <p className="uhc-sans-medium token-color-gray-dark-base m-0 pad5 labelPaddingTop">{props.summaryCnt.onetimepayment_label}</p> 
              </li>
            </ul>
          ) :null}

          {isEditPayment() ? (
              <ul className="row mt-2">
              <li className="col-md-5">
                <span className="token-font-family-heading-three token-color-gray-dark-base m-0 pad5 rightLineHgt">
                {props.summaryCnt.details_label}
                </span>
              </li>
              <li className="col-md-7">
                <p className="uhc-sans-medium token-color-gray-dark-base m-0 pad5 labelPaddingTop">{props.summaryCnt.updateonetimepayment_label}</p> 
                {currentMop()}
                {currentPaymentDate()}
              </li>
            </ul>
          ) :null}
          { props.cmsSSA ?
          <ul className="row mt-2">
              <li className="col-md-5">
                <span className="token-font-family-heading-three token-color-gray-dark-base m-0 pad5 rightLineHgt">
                {props.summaryCnt.details_label}
                </span>
              </li>
              <li className="col-md-7">
                <p className="uhc-sans-medium token-color-gray-dark-base m-0 pad5 labelPaddingTop">{props.summaryCnt.summary_details}</p> 
              </li>
            </ul>
             : null }

          { props.cmsSSA ?
          <ul className="row mt-2">
              <li className="col-md-5">
                <span className="token-font-family-heading-three token-color-gray-dark-base m-0 pad5 noLineHgt">
                {props.summaryCnt.est_start_date_label}
                </span>
              </li>
              <li className="col-md-7">
                <p className="uhc-sans-medium token-color-gray-dark-base m-0 pad5 labelPaddingTop">{props.paymentDate}</p> 
              </li>
            </ul>
             : null }
          {(getOneTimePaymentFlag() && !props.cmsSSA ) || isEditPayment() ? (
            <div aria-live="polite" role="status">
            <ul className="row mt-5"> 
              <li className="col-md-5">
                <span className="token-font-family-heading-three m-0 totalLabel">
                {props.summaryCnt.total_label}
                </span>
              </li>
              <li className="col-md-7">
              <p className="tds-header__h3 token-font-family-heading-three pad5">
              {`${(/\d+/g).test(props.payAmount?.replace(/\$/g,'')) ? '$' + convertToCurrency(props.payAmount.replace(/\$/g,'').replace(/,/g,'')) : props.payAmount}`}</p>
              </li>
            </ul>
          </div>
            ) :null}
          

          </div>
      </div>
    );
}
export default FedPaymentSummary