import React from 'react';

const LocalPrintshop = () => {
	return (
		<svg
			width={24}
			height={24}
			aria-hidden="true"
			focusable="false"
			className="mt-1"
		>
			
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<path
					d="M13.6,4.16666667 L2.4,4.16666667 C1.072,4.16666667 0,5.28333333 0,6.66666667 L0,11.6666667 L3.2,11.6666667 L3.2,15 L12.8,15 L12.8,11.6666667 L16,11.6666667 L16,6.66666667 C16,5.28333333 14.928,4.16666667 13.6,4.16666667 L13.6,4.16666667 Z M11.2,13.3333333 L4.8,13.3333333 L4.8,9.16666667 L11.2,9.16666667 L11.2,13.3333333 L11.2,13.3333333 Z M13.6,7.5 C13.16,7.5 12.8,7.125 12.8,6.66666667 C12.8,6.20833333 13.16,5.83333333 13.6,5.83333333 C14.04,5.83333333 14.4,6.20833333 14.4,6.66666667 C14.4,7.125 14.04,7.5 13.6,7.5 L13.6,7.5 Z M12.8,0 L3.2,0 L3.2,3.33333333 L12.8,3.33333333 L12.8,0 L12.8,0 Z"
					fill="#196ECF"
				/>
			</g>
		</svg>
	);
};

export default LocalPrintshop;
