let commonConstants = {
    TOKENIZATION_REQUEST_TYPE : "tokenization",
    TOKENIZATION_PROCESSING_MODE : "queue",
    TOKENIZATION_ACCOUNT_TYPE : "R",
    TOKENIZATION_RETURN_URL_POLICY : "redirect-approval",
    TOKENIZATION_COUNTRY_CODE : "US",
    TOKENIZATION_VERIFICATION_MODE : "PF",
    TOKENIZATION_NONE : "none",
    TOKENIZATION_RESPONSE_TYPE_TOKENIZATION : "responseType=tokenization",
    TOKENIZATION_RESPONSE_TYPE_EXCEPTION : "responseType=exception",
    POST_PAYMENT_AUTHORIZATION_MEDIUM : "WEB", 
    POST_PAYMENT_AUTHORIZATION_MEDIUM_ID : "2",
    POST_PAYMENT_BANK_ACCOUNT_TYPE : "Credit Card",
    POST_PAYMENT_BANK_ACCOUNT_TYPE_ID : "6",
    POST_PAYMENT_METHOD_ID : "3",
    POST_PAYMENT_USE_PREVIOUS : "false",
    POST_PAYMENT_FREQUENCY_TYPE : "ONE-TIME",
    POST_PAYMENT_FREQUENCY_TYPE_ID : "2",
    POST_PAYMENT_VISA_CREDIT_CARD_TYPE : "VISA",
    POST_PAYMENT_VISA_CREDIT_CARD_TYPE_ID : "1",
    POST_PAYMENT_MASTERCARD_CREDIT_CARD_TYPE : "MASTERCARD",
    POST_PAYMENT_MASTERCARD_CREDIT_CARD_TYPE_ID : "2",
    POST_PAYMENT_DISCOVER_CREDIT_CARD_TYPE : "DISCOVER",
    POST_PAYMENT_DISCOVER_CREDIT_CARD_TYPE_ID : "3",
    PAYMENT_SUBMITTED : "Payment Submitted SuccessFully",
    BUSSINESS_TYPE_GOVT: "GOVT",
    BUSSINESS_TYPE_SHIP: "SHIP",
    ATTEMPT_COUNTER: 4,
    MOBILE_PAGINATION_RECORDS: 7,
    DESKTOP_PAGINATION_RECORDS: 7,
    CONTACT_US: '/medicare/help',
    PREM_DEDUCT: '/medicare/content/dam/shared/documents/Premium_Deduction_Form.pdf',
    INPUT_SOURCE_SYSTEM : 'PORTAL',
    PAYMENT_STATE :'NEW',
    SALE_TRANSACTION_REQUEST_TYPE:"sale",
    SALE_TRANSACTION_ACCOUNT_ID:"37019",
    SALE_TRANSACTION_ACCOUNT_TYPE:"R",
    SALE_TRANSACTION_INDUSTRY_TYPE:"RE",
    SALE_TRANSACTION_ORIGIN_CODE:"00000000001",
    PAYMENT_TRANSACTION_SOURCE : "MRCPWEB",
    UPG_SHIP_NON_PROD_ACCOUNTID: "37019",
    UPG_SHIP_PROD_ACCOUNTID: "35005",
    ACCOUNT_TYPE: "CHECKING",
    SOURCE: "WEB",
    EFT_TENDER_TYPE:"EFT",
    CC_TENDER_TYPE: "CC",
    CUSTOMER_TYPE: "COMPAS",
    ACTION: "CREATE",
    CANCEL_ACTION: "STOP"

} 

export default commonConstants;
