import axios from 'axios';
import { logger } from '../logger';
import appConfigs from '../app.config';
import commonConstants from '../util/commonConstants';
import { getEncStr} from '../util/encDec';
const isbOneTimePayments = axios.create({
  baseURL: appConfigs.api_base_url
});


export const eftOnetimePaymentSubmission = async (requestData) => {

    let eftOneTimePaymentRequest = {
        "createOneTimePaymentRequest": {
          "request": {
            "accountHolderFirstName": requestData.accountFirstname,
            "accountHolderLastName": requestData.accountLastname,
            "accountHolderMiddleName": requestData.accountMiddlename,
            "accountType": commonConstants.ACCOUNT_TYPE,
            "alternateCustomerLookupId": "",
            "amount": requestData.payAmount,
            "applyToRelatedHousehold": "N",
            "associationCode": "",
            "bankAccountNumber": requestData.bankNumber ? getEncStr(requestData.bankNumber) : requestData.bankNumber,
            "bankRoutingNumber": requestData.routingNumber ? getEncStr(requestData.routingNumber) : requestData.bankNumber,
            "billingCustomerId": "",
            "customerId": requestData.individualId,
            "customerType": commonConstants.CUSTOMER_TYPE,
            "isValidated": requestData.accountStatusWrapper,
            "memberFirstName":requestData.memberFirstname,
            "memberLastName": requestData.memberLastname,
            "membershipNumber": requestData.memberId,
            "source": commonConstants.SOURCE,
            "tenderType": commonConstants.EFT_TENDER_TYPE,
            "transactionDateTime": "",
            "transactionId": ""
          }
        },
        "paymentReconciliationRequest": {
          "memberFirstname": requestData.memberFirstname,
          "memberId": requestData.memberId,
          "memberLastname": requestData.memberLastname,
          "middleName": "",
          "paymentDate": requestData.paymentDate,
          "planCategory": requestData.planCategory,
          "sourcePortal": requestData.productBrand,
          "userName": requestData.userName
        }
      }
  return isbOneTimePayments.post(appConfigs.ship_isb_onetime_payment_submission, eftOneTimePaymentRequest)
  .catch(error => {
    logger.error(error.message);
    throw error;
  })  
}
export const ccOnetimePaymentSubmission = async (requestData) => {

    let ccOneTimePaymentRequest = {
        "createOneTimePaymentRequest": {
          "request": {
            "accountHolderFirstName": "",
            "accountHolderLastName": "",
            "accountHolderMiddleName": "",
            "accountType": "",
            "alternateCustomerLookupId": "",
            "amount": requestData.payAmount,
            "applyToRelatedHousehold": "N",
            "associationCode": (requestData.memberId.split('-')[1]).charAt(0),
            "bankAccountNumber": "",
            "bankRoutingNumber": "",
            "billingCustomerId": "",
            "customerId": requestData.individualId,
            "customerType": commonConstants.CUSTOMER_TYPE,
            "isValidated": "",
            "memberFirstName":requestData.memberFirstname,
            "memberLastName": requestData.memberLastname,
            "membershipNumber": requestData.memberId,
            "source": commonConstants.SOURCE,
            "tenderType": commonConstants.CC_TENDER_TYPE,
            "transactionDateTime": requestData.transactionDateTime,
            "transactionId": requestData.transactionId
          }
        },
        "paymentReconciliationRequest": {
          "memberFirstname": requestData.memberFirstname,
          "memberId": requestData.memberId,
          "memberLastname": requestData.memberLastname,
          "middleName": "",
          "paymentDate": requestData.paymentDate,
          "planCategory": requestData.planCategory,
          "sourcePortal": requestData.productBrand,
          "userName": requestData.userName
        }
      }
  return isbOneTimePayments.post(appConfigs.ship_isb_onetime_payment_submission, ccOneTimePaymentRequest)
  .catch(error => {
  logger.error(error.message);
  throw error;
  })  
  }

export default isbOneTimePayments;

