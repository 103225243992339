import React from 'react';
import './tokens/styles/bootstrap-grid.css';
import PaymentRouter from './containers/router/PaymentRouter';
import "@uhc-tempo/components/tempo.css";
import { App as UhcDpl } from "@uhc/pattern-library";
import './PaymentApp.css';


function PaymentApp() {
	
	return (
		<UhcDpl data-test="uhcDpl" className="btm70">
			<PaymentRouter></PaymentRouter>
		</UhcDpl>
	);
}

export default PaymentApp;
