const getConsumerDetails = async () => {

	let consumerData = null;

	if(localStorage.getItem('consumerDetails')) {
		consumerData = JSON.parse(localStorage.getItem('consumerDetails'));
		console.log('consumerDetails fetching from local storage.');
	} else  if(window.location.href.indexOf('localhost') > -1) {
		consumerData = require('../sample/consumerDetails.json')
		localStorage.setItem('consumerDetails',JSON.stringify(consumerData));
		consumerData = JSON.parse(localStorage.getItem('consumerDetails'));
		console.log('saving and fetching consumerDetails.');
	}
	return consumerData;
};


export default getConsumerDetails;
