import React, { Component, Fragment } from 'react';
import { Typography } from '@uhc-tempo/components';
import MobileHistoryTable from '../mobile/mobileHistoryTable/MobileHistoryTable';
import Table from '../Table/Table';
import { isMobile } from '../shared-components/detectDevice';
import { isDesktop } from '../../../server/util/util';
import ServiceError from '../errors/ServiceError';
import Spinner from '../../UI/Spinner/Spinner';

export class FedPaymentHistory extends Component {

    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
    }

    generateHistoryTable() {
        if (this.props.pendingPaymentAPIError && 
            this.props.pendingTabFocus){
            return (
                    <ServiceError insidePayHistTable="true" serviceErrorKey={this.serviceErrorKey}/>
            )
        }   
        else if  (((this.props.paymentHistoryData?.length > 0 && this.props.processedTabFocus) || (this.props.pendingTabFocus && this.props.pendingPaymentData?.data?.length > 0)) && !this.props.planData.isFirstBillGen) {
                return (
                    <div id={this.props.pendingTabFocus ? 'pending_history_data_' + this.props.planNum : 'processed_history_data_' + this.props.planNum}>
                    {isDesktop() &&
                    this.props.pendingTabFocus &&
                            <div className="pendingHeader ">
                                <Typography.H3>{this.props.overviewBillPayAem.auth_pay_pending_header}</Typography.H3>
                            </div>
                    }
                    { isDesktop() &&
                    this.props.processedTabFocus &&
                        <div className="processedHeader">
                        <Typography.H3>{this.props.overviewBillPayAem.auth_pay_processed_header}</Typography.H3>
                    </div>
                    }
                        {isDesktop() ?
                        <div className="historyTable" id={'historyTable_' + this.props.planNum}>
                            <Table
                                data={this.props.currentPosts}
                                history={this.props.tabName}
                                pendingTab={this.props.pendingTabFocus}
                                processedTab={this.props.processedTabFocus}
                                loading={this.props.loadingHistTable}
                                planNum={this.props.planNum}
                                payHistoryTable={this.props.paymentHistoryData}
                                desktopView={isDesktop()}
                                estimatedAnnualPremium={this.props.estimatedAnnualPremium}
                                plan={this.props.plan} 
                                superUser={this.props.superUser}
                                superUserErr={this.props.superUserErr}
                                handleSuperUserErr={this.props.handleSuperUserErr}
                                />
                        </div>
                        :
                        <div className={this.props.pendingTabFocus ?
                                "mobileHistoryTable pending" : 
                                "mobileHistoryTable payments"} id={'mobileHistoryTable_' + this.props.planNum}>
                    
                        <Table
                            data={this.props.currentPosts}
                            pendingTab={this.props.pendingTabFocus}
                            processedTab={this.props.processedTabFocus}
                            history={this.props.tabName}
                            planNum={this.props.planNum}
                            payHistoryTable={this.props.pendingPaymentData}
                            desktopView={isDesktop()}
                            loading={this.props.loadingHistTable}
                            estimatedAnnualPremium={this.props.estimatedAnnualPremium}
                            plan={this.props.plan} 
                            superUser={this.props.superUser}
                            superUserErr={this.props.superUserErr}
                            handleSuperUserErr={this.props.handleSuperUserErr}
                            />
                        </div>
                    }
                    </div>
                )
        }
        if ((((this.props.paymentHistoryData === null || this.props.paymentHistoryData?.length === 0 )&& this.props.processedTabFocus) || 
            ((this.props.pendingPaymentData?.data === null || this.props.pendingPaymentData?.data?.length === 0) && this.props.pendingTabFocus)) || this.props.planData?.isFirstBillGen) {
                if (isDesktop()) {
                    return (
                        <Fragment>
                            <div className="historyTable">
                                <Table
                                    data={[]}
                                    history={'Payment'}
                                    pendingTab={this.props.pendingTabFocus}
                                    processedTab={this.props.processedTabFocus}
                                    loading={this.props.loadingHistTable}
                                    planNum={this.props.planNum}
                                    payHistoryTable={this.props.processedTabFocus ? this.props.paymentHistoryData : this.props.pendingPaymentData?.data}
                                    plan={this.props.plan} 
                                    superUser={this.props.superUser}
                                    superUserErr={this.props.superUserErr}
                                    handleSuperUserErr={this.props.handleSuperUserErr}
                                    />
                            </div>
                            <div className={this.props.processedTabFocus !== true ? "noDataPrintDownload" : "noDataPrintDownload processed"} tabIndex="0" role="tabpanel" aria-labelledby={'tabPayment_' + this.props.planNum} id={'noPaymentData_' + this.props.planNum}>
                                <div className={this.props.processedTabFocus ? "noHistoryHeader processed": "noHistoryHeader pending"}>
                                    <div className={this.props.processedTabFocus ? "processedHeader noData" : "pendingHeader noData"}>
                                        {this.props.processedTabFocus ?
                                        <Typography.H3>{this.props.overviewBillPayAem.auth_pay_processed_header}</Typography.H3>
                                        :
                                        <Typography.H3>{this.props.pendingTabFocus && (this.props.pendingPaymentData?.data?.length > 0 && !this.props.pendingPaymentAPIError) ? this.props.overviewBillPayAem.auth_pay_pending_header : this.props.overviewBillPayAem.auth_pay_pending_header.replace(/\*/g, '')}</Typography.H3>
                                        }
                                        </div>
                                    <div className={isDesktop() ? "noDataDateSelector" : "mobileDateSelector"}>
                                        {(this.props.processedTabFocus && !this.props.planData.isFirstBillGen) &&
                                            <label htmlFor={'paymentSelect_' + this.props.planNum} className="noDataDateSelectorLabel">{this.props.overviewBillPayAem.auth_show_label}</label>
                                        }
                                        {
                                            (this.props.processedTabFocus && !this.props.planData.isFirstBillGen) ? this.props.getDateSelectorUI() : null
                                        }
                                    </div>
                                </div>
                                <div className={this.props.processedTabFocus ? "noHistory processed" : "noHistory pending"}>
                                {this.props.processedTabFocus ?
                                    <Typography.Paragraph>
                                        {this.props.overviewBillPayAem.auth_no_premium_avail_text}
                                    </Typography.Paragraph>
                                    :
                                    <Typography.Paragraph>
                                        {this.props.overviewBillPayAem.auth_no_pending_avail_text}
                                    </Typography.Paragraph>
                                    }
                                </div>
                            </div>
                        </Fragment>
                    )
            } else {
                return (
                    <Fragment>
                        <div className={this.props.pendingTabFocus ?
                            "mobileHistoryTable pending" : 
                            "mobileHistoryTable payments"} id={"mobileHistoryTable_" + this.props.planNum}>
                            <Table
                                data={[]}
                                pendingTab={this.props.pendingTabFocus}
                                processedTab={this.props.processedTabFocus}
                                history={'Payment'}
                                loading={this.props.loadingHistTable}
                                payHistoryTable={this.props.processedTabFocus ? this.props.paymentHistoryData : this.props.pendingPaymentData?.data}
                                plan={this.props.plan} 
                                superUser={this.props.superUser}
                                superUserErr={this.props.superUserErr}
                                handleSuperUserErr={this.props.handleSuperUserErr}
                                />
                        </div>
                        <div className={this.props.pendingTabFocus ? "noDataPrintDownload" : "noDataPrintDownload processed"} tabIndex="0" role="tabpanel" aria-labelledby={'tabPayment_' + this.props.planNum} id={'noPaymentData_' + this.props.planNum}>
                            <div className="noHistory">
                            {this.props.processedTabFocus ?
                                <Typography.Paragraph>
                                    {this.props.overviewBillPayAem.auth_no_premium_avail_text}
                                </Typography.Paragraph>
                                :
                                <Typography.Paragraph>
                                    {this.props.overviewBillPayAem.auth_no_pending_avail_text}
                                </Typography.Paragraph>
                                }
                            </div>
                        </div>
                    </Fragment>
                )
            }
        } else if (this.props.pendingPaymentData === null || this.props.planData?.isFirstBillGen) {
            return (
                <Fragment>
                    {isDesktop() ?
                    <div className="historyTable loading">
                        <Table
                            data={[]}
                            history={'Payment'}
                            pendingTab={this.props.pendingTabFocus}
                            processedTab={this.props.processedTabFocus}
                            loading={this.props.loadingHistTable}
                            planNum={this.props.planNum}
                            payHistoryTable={this.props.processedTabFocus ? this.props.paymentHistoryData : this.props.pendingPaymentData?.data}
                            desktopView={isDesktop()}
                            plan={this.props.plan} 
                            superUser={this.props.superUser}
                            superUserErr={this.props.superUserErr}
                            handleSuperUserErr={this.props.handleSuperUserErr}
                            />
                    </div>
                    :
                        <div className="mobileHistoryTable loading">
                            <Table
                                data={[]}
                                history={'Payment'}
                                pendingTab={this.props.pendingTabFocus}
                                processedTab={this.props.processedTabFocus}
                                loading={this.props.loadingHistTable}
                                planNum={this.props.planNum}
                                payHistoryTable={this.props.processedTabFocus ? this.props.paymentHistoryData : this.props.pendingPaymentData?.data}
                                desktopView={isDesktop()}
                                plan={this.props.plan} 
                                superUser={this.props.superUser}
                                superUserErr={this.props.superUserErr}
                                handleSuperUserErr={this.props.handleSuperUserErr}
                                />
                        </div>
                    }
                </Fragment>
            )
        }        
    }
  render() {
    return (
        <Fragment>
            {this.props.loadingHistTable ? <Spinner /> : this.generateHistoryTable()}
        </Fragment>
        )
    }
}

export default FedPaymentHistory;
