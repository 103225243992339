import moment from 'moment'
import React, { Component, Fragment } from 'react'
import { overviewBillPayAem } from '../paymentOverview/paymentOverview';
import { getMemberFullName } from '../../../server/util/localStorageRepo';
import { convertToCurrency, isDesktop } from '../../../server/util/util'
import Table from '../Table/Table';
import secureLocalStorage from 'react-secure-storage';

export class PrintHistory extends Component {
    constructor(props) {
        super(props);
        this.getStartDate = this.getStartDate.bind(this);
        this.getTotalRemaining = this.getTotalRemaining.bind(this);
    }

    //Get start date of date range selected
    getStartDate() {

        let startDate = this.props.tabName == 'Payment' ? 
                        this.props.paymentTab === 'Pending' && this.props.data && this.props.data.length > 0 ? 
                        moment(this.props.data[this.props.data.length-1]?.startDate).format('MMMM DD, YYYY')
                        : 
                        this.props.plan?.productType === 'SHIP' ? this.props.IsbPaymentPrintStartDate : this.props.paymentPrintStartDate 
                        : 
                        this.props.billingPrintStartDate
        return moment(new Date(startDate)).format('MMMM DD, YYYY')
    }

    //Get end date of date range selected
    getEndDate(previousYear) {
        let endDate = this.props.paymentTab === 'Pending' && this.props.data && this.props.data.length > 0 ?
                      moment(this.props.data[0]?.startDate).format('MMMM DD, YYYY')
                      :
                      (this.props.selectedValue !== "payment.history.search.previous.year") ?
                      moment().format('MMMM DD, YYYY')
                      :
                      previousYear.endOf('year').format('MMMM DD, YYYY')
        return moment(new Date(endDate)).format('MMMM DD, YYYY')
    }

    //Get status of a bill
    getBillingStatus(cell) {
        let isPaid = false
        if(this.props.plan.productType === 'SHIP') {
            isPaid = cell.paymentStatus === 'PAID' ? true : false
        } else {
            isPaid = (cell.balanceDue === 0 || cell.balanceDue < 0) ? true : false
        }
        return isPaid ? <span style={{ color: "rgb(0, 112, 0)" }}>{overviewBillPayAem.auth_paid_text}</span> : <span style={{ color: 'rgb(111, 111, 111)' }}>{overviewBillPayAem.auth_unpaid_text}</span>
    }
    
    //Get total remaining if bill isn't paid off
    getTotalRemaining(totalRemaining) {
        if (this.props.tabName === 'Payment') {
            return(
                <tr>
                    <td>{overviewBillPayAem.auth_total_amounts_txt}</td>
                    <td>${convertToCurrency(totalRemaining)}</td>
                    {this.props.plan.productType === 'GOVT' ? (
                    <td/>
                    ) : null}
                    <td/>
                    {this.props.paymentTab === 'Processed' ? (
                        <td/>
                    ) : null}
                </tr>
            )
        } else {
            return(
                <tr>
                    <td>{overviewBillPayAem.auth_total_amounts_txt}</td>
                    <td/>
                    <td/>
                    <td>${convertToCurrency(totalRemaining)}</td>
                </tr>
            )
        }
    }

    displayPlans(shipPlans) {
        let shipPlanList = [];
        if (this.props.plan.productType === 'SHIP' && shipPlans) {
            if (shipPlans?.length > 1 && Array.isArray(shipPlans)) {
            shipPlans.map((plan, index) => {
                shipPlanList.push(
                <Fragment key={"shipPlan_" + index}>
                     <p style={{margin: '.75rem 0'}}>Plan:<b style={ {marginLeft:'7.5rem'}}>{plan?.planName}</b></p>
                     <p style={{margin: '.75rem 0'}}>Effective date: <b style={ {marginLeft:'4rem'}}>{moment(plan?.planStartDate).format('MMMM D, YYYY')}</b></p>
                </Fragment>)
            })
         } else {
            shipPlanList.push(
                <Fragment key={"shipPlan_0"}>
                     <p style={{margin: '.75rem 0'}}>Plan:<b style={ {marginLeft:'7.5rem'}}>{shipPlans?.planName}</b></p>
                     <p style={{margin: '.75rem 0'}}>Effective date: <b style={ {marginLeft:'4rem'}}>{moment(shipPlans?.planStartDate).format('MMMM D, YYYY')}</b></p>
                </Fragment>)
         }
        } else {
            return <p style={{margin: '.75rem 0'}}>Plan: <b style={ {marginLeft:'7.5rem'}}>{this.props.plan?.planName}</b></p>
        }
        return shipPlanList;
    }

    render() {
        const previousYear = moment().subtract(1, 'years');
        let shipPlanNamesList = this.props.plan.productType === 'SHIP' && secureLocalStorage.getItem("shipPlanNamesList") ? JSON.parse(secureLocalStorage.getItem("shipPlanNamesList")) : null
        let planName = (this.props.plan?.productType === 'SHIP') ? (shipPlanNamesList) ? shipPlanNamesList : this.props.plan : this.props.plan?.planName
        return (

            this.props.data &&
            <div className="printHistory">
                <h2>{this.props.tabName === "Payment" ? 'Premium Payment History' : 'Premium Billing History'}</h2>
                <div className="printHistoryInfo">
                    <p style={{margin: '.75rem 0'}}>{this.props.tabName === "Payment" ? 'Payment history for: ' : 'Billing history for: '} <b style={ this.props.tabName === 'Billing' ? {marginLeft:'2.75rem'} : {marginLeft: '2rem'}}> {getMemberFullName()}</b></p>
                    <p style={{margin: '.75rem 0'}}>Member ID: <b style={ {marginLeft:'4.9rem'}}>{this.props.plan.memberNumber}</b></p>
                
                {(this.props.tabName === 'Payment' && this.props.paymentTab !== 'Pending') || (this.props.plan.productType === 'GOVT' && this.props.paymentTab !== 'Pending') ? 
                    <Fragment>
                    <p style={{margin: '.75rem 0'}}>From: <b style={ {marginLeft:'7rem'}}>{this.getStartDate(planName)}</b></p>
                    <p style={{margin: '.75rem 0'}}>To:<b style={ {marginLeft:'8.25rem'}}>{(this.props.selectedValue !== "payment.history.search.previous.year") ? this.getEndDate() : this.getEndDate(previousYear)}</b></p>
                    </Fragment>
                    : null }
                {this.displayPlans(planName)}
                {this.props.tabName === 'Payment' && this.props.paymentTab !== 'Pending' && this.props.getCreditBalance() &&
                    <p style={{margin: '.75rem 0'}}>Credit Balance: <b style={ {marginLeft:'3.6rem'}}>{this.props.getCreditBalance()}</b></p> }
                </div>
                <br />
                        <Table
                            data={this.props.data}
                            tabName={this.props.tabName}
                            history={this.props.tabName}
                            pendingTab={this.props.paymentTab === 'Pending' ? true : false}
                            processedTab={this.props.paymentTab === 'Processed' ? true : false}
                            loading={this.props.loadingHistTable}
                            planNum={this.props.planNum}
                            payHistoryTable={this.props.paymentHistoryData}
                            desktopView={isDesktop()}
                            printDownload={true}
                            plan={this.props.plan} 
                            printHistory={true}   
                            />

            </div>

        )
    }
}

export default PrintHistory

