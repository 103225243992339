// @flow

import { datadogRum } from '@datadog/browser-rum';
import { APPLICATION_ID, CLIENT_TOKEN, ENV} from './config';

export const initializeDatadog = async () => {
//   console.log("APPLICATION_ID:::::"+APPLICATION_ID);
//  console.log("CLIENT_TOKEN:::::"+CLIENT_TOKEN);
//  console.log("ENV ::::"+ENV);

  datadogRum.init({
    applicationId: APPLICATION_ID,
    clientToken: CLIENT_TOKEN,
    env: ENV,
    site: 'datadoghq.com',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    trackFrustrations: true,
    trackInteractions: true,
    trackUserInteractions: true,
    trackSessionAcrossSubdomains: true,
    useSecureSessionCookie: true,
    useCrossSiteSessionCookie: true
  });
  datadogRum.startSessionReplayRecording();
};

