import axios from '../../server/api/aem-api';
import appConfigs from "../../server/app.config";

const getUUIDfromHSID = async (UUIDfromHSID) => {
	let axiosConfig = {
		headers: {
			'Content-Type': 'application/json;charset=UTF-8',
			"Access-Control-Allow-Origin": "*",
			}
	  };

	return axios.get(appConfigs.sso_inbound_url + UUIDfromHSID, 
        {}, axiosConfig).then(response =>{
				return response;
		})
		.catch((error) => {
			console.log(error);
			return error.response;
		});
};

export default getUUIDfromHSID;