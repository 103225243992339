import axios from '../../server/api/ucpPayments-api';
import appConfigs from '../app.config';
import { getEncStr} from '../../server/util/encDec';
import { getPlanInformation } from '../util/localStorageRepo';

var statusFromUpdateWalletCall = "";

export const addBankAccountInWallet = async (items,bankAccountObj,isUpdate, updateWalletData) =>{
  
   let existItem= items.filter(el=> el.creditCard == null).filter(item => (item.bankAccount.accountNumber === bankAccountObj.accountNumber && 
                  item.bankAccount.routingNumber === bankAccountObj.routingNumber));
                  
        if(existItem.length>0 && existItem[0].active.toLowerCase() === "true")
            return " Item already exist in Wallet. Please add new account or rounting number. "
        if(existItem.length>0 && existItem[0].active.toLowerCase() === "false" && !isUpdate) {
          bankAccountObj.itemId=existItem[0].itemId
          bankAccountObj.bankAccountId=existItem[0].bankAccount.bankAccountId
          bankAccountObj.authIndicator=existItem[0].bankAccount.authorizationInd
          bankAccountObj.autopayTimelines=existItem[0].autopayTimelines
         // console.log(existItem[0].bankAccount.autopayTimelines)
          return updateBankAccountInWallet(items,bankAccountObj,true,updateWalletData);
        } else{
                var bankAccountRequest = {
                    active: "TRUE",
                    authorizationMedium: "WEB",
                    autopayTimelines: isUpdate ? bankAccountObj.autopayTimelines : [],
                    bankAccount: {
                      accountNumber: bankAccountObj.accountNumber,
                      authImageNumber: null,
                      authorizationInd: bankAccountObj.authIndicator,
                      bankAccountId: isUpdate ? bankAccountObj.bankAccountId : "",
                      bankAccountType: bankAccountObj.type,
                      bankName: isUpdate ? bankAccountObj.bankName :"",
                      firstName: bankAccountObj.firstName,
                      lastName: bankAccountObj.lastName,
                      middleName: bankAccountObj.middleName,
                      routingNumber: bankAccountObj.routingNumber
                    },
                    creditCard: null,
                    inputSourceSystem: "PORTAL",
                    itemId: isUpdate ? bankAccountObj.itemId : "",
                    nickname: bankAccountObj.nickName
              };
              
              items.push(bankAccountRequest); 
              updateWalletData.items=items;
              //console.log("update wallet request.."+JSON.stringify(updateWalletData));
             return addUpdateWalletServiceAPI(updateWalletData);
            }
}
export const updateBankAccountInWallet = async (items,bankAccountObj,isUpdate,updateWalletData) =>{
   let filtedItems= items.filter(item => !(item.itemId === bankAccountObj.itemId));
   return addBankAccountInWallet(filtedItems,bankAccountObj,isUpdate,updateWalletData)
}
 export const addUpdateWalletServiceAPI = async (items) => {
  let accNumber = null;
  let routingNumber = null;

  items.audit.modifiedBy = getPlanInformation().memberNumber
  items.items.map( elt => {
    if(elt.bankAccount != null){
      accNumber = getEncStr(elt.bankAccount.accountNumber)
      routingNumber= getEncStr(elt.bankAccount.routingNumber)
   elt.bankAccount.accountNumber = accNumber;
   elt.bankAccount.routingNumber= routingNumber;
  }
   return elt
  });

	return axios.post(appConfigs.update_wallet_service_path+getPlanInformation().accountId, 
        items).then(response =>{
           // console.log ('Update walltet service response ' +  JSON.stringify(response.data));
            sessionStorage.setItem('newSession', false)
        statusFromUpdateWalletCall = response.status;
				return response.data;
		})
		.catch((error) => {
            console.log(error?.response);
            statusFromUpdateWalletCall = error?.response?.status;
            return error?.response?.data
        });
        

};

export const addCreditCardInWallet = async (items,creditCardObj, updateDataPojo) =>{
  
  let existItem= items.filter(item => (item.cardType === creditCardObj.cardType && item.cardNumberLastFour === creditCardObj.cardNumberLastFour
      && item.expiration === creditCardObj.expiration));
                 
      if(existItem.length>0 && existItem[0].active.toLowerCase() === "true")
           return " Item already exist in Wallet. Please add new account or rounting number. "
      else{
            var creditCardRequest = {
                active: "TRUE",
                authorizationMedium: "WEB",
                autopayTimelines: creditCardObj.autopayTimelines,
                bankAccount: null,
                creditCard: {
                billingCode: creditCardObj.billingCode,
                cardNumberLastFour: creditCardObj.cardNumberLastFour,
                cardType: creditCardObj.cardType,
                creditCardId: "",
                creditCardToken: creditCardObj.token,
                declineCount: "",
                declineCountLastUpdated: "",
                encryptedTrack: "",
                expiration: creditCardObj.expiration,
                nameOnCard: creditCardObj.nameOnCard,
                unstored: "FALSE"
              },
                inputSourceSystem: "PORTAL",
                itemId: "",
                nickname: creditCardObj.nickName
          };
             items.push(creditCardRequest); 
             updateDataPojo.items=items;
            // console.log("credit card add request.."+JSON.stringify(updateDataPojo));
            return addUpdateWalletServiceAPI(updateDataPojo);
           }
}

export const updateCreditCardInWallet = async (items,creditCardItem,updateWalletData) =>{
  let filtedItems= items.filter(item => !(item.itemId === creditCardItem.itemId));
  filtedItems.push(creditCardItem)
  updateWalletData.items=filtedItems;
 // console.log("credit card add request.."+JSON.stringify(updateWalletData));
  return addUpdateWalletServiceAPI(updateWalletData);
}

export const removeItemFromWallet = async (items, itemId, updateWalletData) => {
    let otherItems = items.filter(el=> el.itemId !== itemId)
    let itemToRemove = items.filter(el=> el.itemId === itemId)
    itemToRemove[0].active = 'FALSE'
    otherItems.push(itemToRemove[0])
    updateWalletData.items = otherItems;
  return addUpdateWalletServiceAPI(updateWalletData)
}

export {statusFromUpdateWalletCall}