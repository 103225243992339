import React from "react";
import MfaModalPopup from "../modalPopUps/mfaModalPopup";
import { Markup } from 'interweave'
import secureLocalStorage from "react-secure-storage";

const MfaLogOutModal = ({isMFAInvalid}) => {
  console.log('Logout modal called.')
    const mfaLogOutHeader = secureLocalStorage.getItem('mfaLogOutHeader');
    const mfaLogOutContent = secureLocalStorage.getItem('mfaLogOutContent');
    const mfaLogoutTimer = secureLocalStorage.getItem('mfaLogoutTimer')
    setTimeout(function () {
      window.location.assign(window.location.origin +'/medicare/content/medicare/member/logout.html');
    }, mfaLogoutTimer)
    
    return (<MfaModalPopup
        isOpen={isMFAInvalid}
        arialabelled="SessionExpiredModal"
      >
        <div>
         <h2>{mfaLogOutHeader}</h2> 
        </div>
        <div>
         <p><Markup content ={mfaLogOutContent}/></p>
        </div>
      </MfaModalPopup>) 
      
}
export default MfaLogOutModal