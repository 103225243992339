import React, {Component} from 'react';
import { Markup } from 'interweave'
import { Typography, RadioButton, FormGroup, IconScale, Notification, Form, Modal } from '@uhc-tempo/components';
import PaymentModals from '../modals/PaymentModals'
import { manageWalletFormAem } from '../Wallet/ManageWallet'
import { eftFormAem } from '../Wallet/FedPaymentForm'

export default class PageError extends Component {
    render() {
        return (
        <>
            {this.props.errorFields && this.props.errorFields.count > 0 ? 
                 <Notification notificationType="error" id="alertbody_desktop" className="payment-error-box xs-hide" tabIndex="-1" dismissClickHandler={() => { return}}>
                    {this.props.errorFields?.type == 'field' && this.props.errorFields.count == 1 && <Typography.Paragraph className="num-errors-found"><strong>{this.props.errorFields.count} {this.props.page == 'wallet' ? manageWalletFormAem.auth_mw_error_has_text : this.props.page === 'shipPayment' ? this.props.eftSection?.errorshas_text_lbl + ' ' + this.props.eftSection.beenfound_text_lbl : eftFormAem.auth_mw_error_has_text}</strong></Typography.Paragraph>}
                    {this.props.errorFields?.type == 'field' && this.props.errorFields.count > 1 && <Typography.Paragraph className="num-errors-found"><strong>{this.props.errorFields.count} {this.props.page == 'wallet' ? manageWalletFormAem.auth_mw_errors_have_text : this.props.page === 'shipPayment' ? this.props.eftSection?.errorshave_text_lbl + ' ' + this.props.eftSection.beenfound_text_lbl :  eftFormAem.auth_mw_errors_have_text}</strong></Typography.Paragraph>}
                    {this.props.errorFields?.type == 'field' && this.props.errorFields?.fields.map(el=> (
                     el.error && <ul className="error-list"><li className="error-label">{this.props.page === 'shipPayment' ? el.fieldLabel : el.label}</li></ul>
                 ))}
     
            {this.props.errorFields?.type === 'nacha' && 
                <>
                    <div className="nachaErr nachaCls">
                    <p>{this.props.errorFields.content.header}</p>
                    <p>{this.props.errorFields.content.para1.replace('etfPaymentErrorCount',this.props.errorFields.count)}</p>
                    <p>&nbsp;</p>
                    {this.props.errorFields.content.para2}
                    <p>&nbsp;</p>
                    { this.props.page !== 'wallet' &&
                    <PaymentModals
                    modalContent={this.props.errorFields.content.earlyWarningModalContent}
                    eftSection={this.props.errorFields.content.earlyWarningContent}
                    modalType='earlyWarning'
                    />
                    }
                    </div>
                </> 
            }

            {this.props.errorFields?.type === 'api' && 
                <>
                    {this.props.errorFields?.fields.map(el=> (
                     el.error && <div className="error-list"><p className="error-label"><Markup content={el.label}/></p></div>
                 ))}
                </>
            }
                 </Notification>
            : null}
           
        </>
        )
    }
}
