import React, { Component } from 'react'
import { serverCurrentTime } from '../../../server/api/serverTime-api'
import { withRouter , Redirect} from 'react-router-dom'
import Moment from 'moment'
import { Button, Typography } from '@uhc-tempo/components'
import { Grid } from '@uhc/pattern-library'
import './paymentConfirmation.css'
import { trackPageLoad, trackLinkClick, trackImpression } from '../../../server/util/analytics'
import IconCheckMrk2CCirRGB from '../../tokens/icons/Icon_CheckMrk_2C_Cir_RGB'
import aemAxios, { content_path } from '../../../server/api/aem-api'
import { Markup } from 'interweave'
import Spinner from '../../UI/Spinner/Spinner';
import PageHeader from '../page-header/PageHeader'
import PaymentReceipt from '../PaymentReceipt/PaymentReceipt.js'
import { getPlanInformation, getOneTimePaymentFlag,  getRecurringPaymentFlag,isManageRecurring, getMemberFullName, getOverviewPageLink, getOneTimePageLink, getProductType,getSearchPaymentMethodResult, isEditPayment } from '../../../server/util/localStorageRepo';
import styled from 'styled-components';
import { ReactComponent as SavedLogo } from '../../tokens/icons/ic_done_green.svg'
import { convertToCurrency } from '../../../server/util/util';
import secureLocalStorage from 'react-secure-storage'

class PaymentConfirmation extends Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef()
    this.ref = React.createRef()

    let bclinkConfirm = [{title: 'Payment Overview', link: 'toOverview', click : this.gotoOverview},
    {title: 'Complete', link: ''}];

    this.state = {
      fullName: '',
      paidAmount: '',
      serverDate: '',
      nextMonthFirstDate: '',
      nextMonthStartDt: '',
      accountNumberFormated: '',
      accountNumber: '',
      cardNumber: '',
      cardType: '',
      paymentMethod: '',
      pageLoadViaFlow: false,
      hLinks : bclinkConfirm,
      cancelRecChecked: false,
      spinnerLoading : true,
      payWithSavedCC:false,
      saveCreditCard : false,
      isPayMethodSaved : false,
      isCmsSsa: false,
      withdrawalDate: ''
    }
  }

  gotoOverview = () => {
    window.location.href = getOverviewPageLink()
    console.log('go to overview page')
  }

  backAction = () => {
    window.location.assign(getOverviewPageLink())
  }

  componentDidMount() {
    secureLocalStorage.setItem('paymentFormData',null);
    document.body.removeAttribute('class');
    document.body.removeAttribute('style');
    
    window.history.pushState({page: 'confirm'}, "confirmPage", window.location.href);

    let isPageReloaded = true;
    secureLocalStorage.setItem('currPage', 'confirm');
    window.scrollTo(0,0);
    secureLocalStorage.setItem('isPaymentRedesignPage',false);
    if (this.props && this.props.location && this.props.location.state) {
      this.setState({
        cardType: this.props.location.state.cardType,
        cardNumber: this.props.location.state.cardNumber,
        paymentToEdit : this.props.location.state.paymentToEdit,
        ssaNextAvailableDate : this.props.location.state.ssaNextAvailableDate,
        isCmsSsa : this.props.location.state.isCmsSsa
      })
      if (this.props.location.state.pageLoadViaFlow) {
        isPageReloaded = false;
        this.setState({
          pageLoadViaFlow: true
        })
      }
    }
    if(isPageReloaded) {
      this.setState({
        showSessionModal: true
      })
      return
    }
    window.onpopstate = this.backAction;
    if (getRecurringPaymentFlag() && !isManageRecurring() && secureLocalStorage.getItem('isPastAmountDue') === 'true'){
      trackImpression('past-due-amount:yes')
    }
    trackPageLoad(this.props.location.state.isCmsSsa ? 'request ssa/rrb complete': getOneTimePaymentFlag() ? 'make a one time payment:payment submitted' : isManageRecurring()  ?  'manage recurring payment:payment submitted':
                  'set up recurring payment:payment submitted');
                  if(this.props.location.state.isCmsSsa && secureLocalStorage.getItem('isPastAmountDue') === 'true'){
                    trackImpression('past-due-amount:yes')   
                  }
               
    var paymentMethodType = ''
    let date = null;
    if(secureLocalStorage.getItem('currSysTimeInMs')){
       date = new Date(parseInt(secureLocalStorage.getItem('currSysTimeInMs')))
       this.setState({
        withdrawalDate: this.props.location.state.withdrawalDate ? Moment(this.props.location.state.withdrawalDate, 'YYYY-MM-DD').format('MM/DD/YYYY') : Moment(date).format('MM/DD/YYYY'),
        serverDate: this.props.location.state.paymentDate ? Moment(this.props.location.state.paymentDate, 'MM/DD/YYYY').format('MM/DD/YYYY') : Moment(date).format('MM/DD/YYYY'),
        nextMonthStartDt: (this.state.cancelRecChecked  && getProductType() === 'GOVT') ? Moment(date).add(1, 'months').startOf('month').format('MM/DD/YYYY') :this.props.location.state.paymentDate ? Moment(this.props.location.state.paymentDate, 'MM/DD/YYYY').format('MM/DD/YYYY') : Moment(date).add(1, 'months').startOf('month').format('MM/DD/YYYY'),
        nextMonthFirstDate : (this.state.cancelRecChecked  && getProductType() === 'GOVT') ? Moment(date).add(1, 'months').startOf('month').format('MMMM D') : this.props.location.state.paymentDate ? Moment(this.props.location.state.paymentDate, 'MM/DD/YYYY').format('MMMM D') : Moment(date).add(1, 'months').startOf('month').format('MMMM D'),
        ssaNextAvailableDate : this.props.location.state.ssaNextAvailableDate ? Moment(this.props.location.state.ssaNextAvailableDate, 'MM/DD/YYYY').format('MMMM D') : null
      })
    }else{
    serverCurrentTime().then((response) => {
      if (response) {
      date = new Date(parseInt(response.systemtimeinmillis))
      this.setState({
        withdrawalDate: this.props.location.state.withdrawalDate ? Moment(this.props.location.state.withdrawalDate, 'YYYY-MM-DD').format('MM/DD/YYYY') : Moment(date).format('MM/DD/YYYY'),
        serverDate: this.props.location.state.paymentDate ? Moment(this.props.location.state.paymentDate, 'MM/DD/YYYY').format('MM/DD/YYYY') : Moment(date).format('MM/DD/YYYY'),
        nextMonthStartDt: (this.state.cancelRecChecked  && getProductType() === 'GOVT') ? Moment(date).add(1, 'months').startOf('month').format('MM/DD/YYYY') :this.props.location.state.paymentDate ? Moment(this.props.location.state.paymentDate, 'MM/DD/YYYY').format('MM/DD/YYYY') : Moment(date).add(1, 'months').startOf('month').format('MM/DD/YYYY'),
        nextMonthFirstDate : (this.state.cancelRecChecked  && getProductType() === 'GOVT') ? Moment(date).add(1, 'months').startOf('month').format('MMMM D') : this.props.location.state.paymentDate ? Moment(this.props.location.state.paymentDate, 'MM/DD/YYYY').format('MMMM D') : Moment(date).add(1, 'months').startOf('month').format('MMMM D'),
        ssaNextAvailableDate : this.props.location.state.ssaNextAvailableDate ? Moment(this.props.location.state.ssaNextAvailableDate, 'MM/DD/YYYY').format('MMMM D') : null
      })
      secureLocalStorage.setItem('currSysTimeInMs',response.systemtimeinmillis);	
      }
    })
   }


    if (secureLocalStorage.getItem('selectedPaymentMethod')) {
      paymentMethodType = secureLocalStorage.getItem('selectedPaymentMethod')
    }

    if (getMemberFullName()) {
      this.setState({ fullName: getMemberFullName().toLowerCase() })
    }

    this.setState({
      paidAmount: '',
      //serverDate: '',
      accountNumberFormated: '',
      accountNumber: '',
      paymentMethod: this.props.location.state.paymentMethod,
      payWithSavedCC : this.props.location.state.payWithSavedCC,
      cancelRecChecked: this.props.location.state.cancelRecChecked,
      saveCreditCard : this.props.location.state.saveCreditCard,
    })

    var meta = document.createElement('meta');
    meta.name = "robots";
    meta.content = "noindex, nofollow";
    document.getElementsByTagName('head')[0].appendChild(meta);

    if (paymentMethodType === 'CC') {
      let pAmount = this.props.location.state.payAmount
      this.setState({
        paidAmount: pAmount.replace('$', ''),
      })
    } else {
      let accNumber = this.props.location.state.accountNumber
        this.setState({
          accountNumber: accNumber,
          paidAmount: this.props.location.state.payAmount,
          accountNumberFormated: accNumber?.replace(/\d{4}(?= \d{4})/g, '****'),
        })
    }

    aemAxios
      .get(content_path + '/payment-confirmation-new/payment-confirmation-new/jcr:content/data/master.json?timestamp=' + new Date().getTime())
      .then((response) => {
        let confirmationAgreement = "";

        this.setState(prevState => ({
          hLinks: [
            { ...prevState.hLinks[0], title: response.data.auth_bc_pay_overview_label },
            prevState.hLinks[1]
          ]
        }));

        if (getProductType() === 'SHIP') {
          confirmationAgreement = getOneTimePaymentFlag() ? response.data.auth_ele_sig_note : response.data.auth_ele_sig__setup_note_isb;
        } else if (getProductType() === 'GOVT') {
          confirmationAgreement = getOneTimePaymentFlag() ? response.data.auth_ele_sig_note_fed : response.data.auth_ele_sig__setup_note_fed;
        }
        this.setState({
          view_print_pdf: response.data.auth_print_label_text,
          back_btn: response.data.auth_back_confirm_link_text,
          onetime_pay_banner_text: response.data.auth_banner_text,
          setup_auto_pay_banner_text: response.data.auth_setup_auto_pay_banner_text,
          onetime_confirmation_label: getProductType() === 'SHIP' ? (this.state.paymentMethod === 'EFT' ? response.data.auth_confirmation_label.replace("<p>","").replace("</p>","") : this.state.paymentMethod === 'CC' ? response.data.auth_cc_confirmation_label.replace("<p>","").replace("</p>","") : response.data.auth_confirmation_label.replace("<p>","").replace("</p>",""))  : response.data.auth_confirmation_label_fed.replace("<p>","").replace("</p>",""),
          email_confirmation_msg: response.data.auth_email_confirmation_msg,
          setup_auto_pay_conf_label: getProductType() === 'SHIP' ? response.data.auth_setup_auto_pay_conf_label_isb.replace("<p>","").replace("</p>","") : response.data.auth_setup_auto_pay_conf_label_fed.replace("<p>","").replace("</p>",""),
          cancel_pay_conf_label: getProductType() === 'SHIP' ? response.data.auth_cancel_pay_conf_label.replace("<p>","").replace("</p>","") :
                                this.props.paymentsMethodValue?.indexOf('EFT') > -1 ? response.data.auth_cancel_pay_conf_label_fed_eft.replace("<p>","").replace("</p>","") :
                                this.props.paymentsMethodValue?.indexOf('Credit') > -1 ? response.data.auth_cancel_pay_conf_label_fed_cc.replace("<p>","").replace("</p>","") : '',
          manage_pay_conf_label: getProductType() === 'SHIP' ? response.data.auth_manage_pay_conf_label.replace("<p>","").replace("</p>","") : response.data.auth_manage_pay_conf_label_fed.replace("<p>","").replace("</p>",""),
          ssarrb_conf_label: response.data.auth_ssarrb_conf_label.replace("<p>","").replace("</p>",""),
          elec_sig_lbl: response.data.auth_elec_sig_lbl,
          ele_sig_note: confirmationAgreement,
          onetime_success_note: getProductType() === 'SHIP' ? response.data.auth_success_note : response.data.auth_success_note_fed,
          setup_auto_pay_success_note: getProductType() === 'SHIP' ? response.data.auth_setup_auto_pay_success_note : response.data.auth_setup_auto_pay_success_note_fed,
          manage_pay_success_note: getProductType() === 'SHIP' ? response.data.auth_manage_pay_success_note : response.data.auth_manage_pay_success_note_fed,
          cancel_pay_success_note: getProductType() === 'SHIP' ? response.data.auth_cancel_pay_success_note : response.data.auth_cancel_pay_success_note_fed,
          ssarrb_success_note: response.data.auth_ssarrb_success_note,
          ssarrb_banner_txt : response.data.auth_cms_ssarrb_banner_text,
          receipt_label: this.state.isCmsSsa ? response.data.auth_conf_reciept_label : response.data.auth_receipt_label,
          plan_label: response.data.auth_plan_label,
          name_label: response.data.auth_name_label,
          id_label: response.data.auth_id_label,
          details_label: response.data.auth_details_label,
          total_label: response.data.auth_total_label,
          onetime_another_pay_due_lbl: response.data.auth_another_payment_due_lbl,
          setup_auto_pay_another_pay_due_lbl: response.data.auth_setup_auto_pay_another_pay_due_lbl,
          ssarrb_auto_pay_another_pay_due_lbl: response.data.ssarrb_auto_pay_outstand_bal_text,
          onetime_another_pay_due_note: response.data.auth_another_pay_due_note,
          ssarrb_estimated_dt : response.data.auth_ssarrb_estimated_start_dt,
          ssarrb_receipt_pay_note: response.data.auth_another_pay_due_note,
          ssa_rrb_no_ref_num_note: response.data.auth_ssarrb_no_ref_num_note,
          setup_auto_pay_another_pay_due_note: getProductType() === 'SHIP' ? response.data.auth_setup_auto_pay_another_pay_due_note : response.data.auth_setup_auto_pay_another_pay_due_note_fed,
          setup_auto_pay_another_pay_due_note_1: response.data.auth_setup_auto_pay_another_pay_due_note_1,
          ssarrb_auto_pay_another_pay_due_note: response.data.auth_ssarrb_outstanding_bal_note,
          manage_pay_due_note: response.data.auth_manage_pay_another_pay_due_note,
          return_pay_overview_lbl: response.data.auth_return_pay_overview_lbl,
          ele_pay_auth_sign_lnk: response.data.auth_ele_pay_auth_sign_lnk,
          pay_rcpt_auto_pay_lbl: response.data.auth_pay_rcpt_auto_pay_lbl,
          pay_rcpt_auto_pay_note: response.data.auth_pay_rcpt_auto_pay_note,
          pay_rcpt_auto_pay_start_dt_lbl: response.data.auth_pay_rcpt_auto_pay_start_dt_lbl,
          pay_rcpt_auto_pay_stop_dt_lbl: response.data.auth_pay_rcpt_stop_date_label,
          pay_rcpt_notification_lbl: response.data.auth_pay_rcpt_notification_lbl,
          make_onetime_pay_link: response.data.auth_make_onetime_pay_link,
          pay_rcpt_ot_pay_lbl: response.data.auth_pay_rcpt_ot_pay_lbl,
          pay_rcpt_ssarrb_pay_lbl: response.data.auth_pay_rcpt_ssarrb_pay_lbl,
          print_footer_content: response.data.auth_print_footer_content,
          manage_pay_banner_txt: response.data.auth_manage_pay_banner_text,
          cancel_pay_banner_txt: response.data.auth_cancel_pay_banner_txt,
          return_to_overview: response.data.auth_return_pay_overview,
          go_to_overview: response.data.auth_go_to_pay_overview,
          cancel_auto_pay_label: response.data.auth_pay_rec_cancel_label,
          eft_checking_txt_label: response.data.auth_eft_check_label,
          eft_savings_txt_label: response.data.auth_eft_save_label,
          view_your_txt: response.data.auth_view_your_txt,
          resgister_now_txt: response.data.auth_register_now_text,
          manage_auto_pay_label: getProductType() === 'SHIP' ? response.data.auth_pay_rcpt_manage_lbl_ship : response.data.auth_pay_rcpt_manage_ap_lbl,
          below_text_label: response.data.auth_below_text_label,
          setup_rec_total_text: getProductType() === 'SHIP' ? response.data.auth_isb_setup_rec_total_text : response.data.auth_setup_rec_total_text_fed,
          reference_label_text: response.data.auth_reference_label_text,
          email_note:response.data.auth_email_note,
          saved_label_text: response.data.auth_pay_rcpt_saved_label,
          cc_ele_sig_note: getProductType() === 'SHIP' ? response.data.auth_ship_cr_ele_sig_note : response.data.auth_cr_ele_sig_note,
          cc_ele_sig_note_setup: response.data.auth_cr_ele_sig_note_setup,
          update_pay_banner_txt : response.data.auth_update_pay_banner_txt,
          update_confirmation_label_fed : response.data.auth_update_confirmation_label_fed,
          pay_rcpt_update_ot_pay_lbl : response.data.auth_pay_rcpt_update_ot_pay_lbl,
          update_success_note_fed : response.data.auth_update_success_note_fed
        });
      })
      .catch((error) => {
        this.setState({
          loadError: true,
          loading: false
        });
      });
      setTimeout(()=> {
        this.setState({spinnerLoading : false})
      }, 3000)

  }


  navigateTo = () => {
    let path = getOverviewPageLink()
    if((!isManageRecurring(getProductType()) && getRecurringPaymentFlag()) || (this.state.isCmsSsa && secureLocalStorage.getItem('isPastAmountDue') === 'true')) {
      path = secureLocalStorage.getItem('isPastAmountDue') === 'true' ? getOneTimePageLink(): getOverviewPageLink();
        if (secureLocalStorage.getItem('isPastAmountDue') === 'true'){
          trackLinkClick('Pay now');
        } else{
          trackLinkClick('Return to Payment Overview');
        }
      secureLocalStorage.setItem("recurringPaymentFlag", false)
      secureLocalStorage.setItem("oneTimePaymentFlag", true)
      window.onpopstate = ()=> {}
    } else{
      trackLinkClick('Return to Payment Overview');
    }
    window.location.assign(path);
  }

  scrollTo = (ele, fieldName) => {
    ele.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })
    ele.focus()
  }

  scrollToESignBox = () =>{
    trackLinkClick("Authorizations")
    this.myRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })
    this.myRef.current.focus()
  }
  render() {
    let banner_txt = null;
    let confirmation_label= null;
    let payment_method =null;
    let another_pay_due_lbl=null;
    let another_pay_due_note= null;
    let ssarrb_receipt_pay_note=null;
    let another_pay_due_note_1 =null;
    let success_note =null;
    let return_button_lbl = null;
    let totalAmountDue = this.props.location.state.isCmsSsa ? this.props.location.state.totalAmountDue : secureLocalStorage.getItem('totalAmountDue');
    let isPastAmtDue = secureLocalStorage.getItem('isPastAmountDue');

    //This is setup recurring
    if(getRecurringPaymentFlag() && !isManageRecurring() ) {
      banner_txt = this.state.setup_auto_pay_banner_text;
      confirmation_label = this.state.setup_auto_pay_conf_label;
      payment_method = this.state.pay_rcpt_auto_pay_lbl;
      another_pay_due_lbl=this.state.setup_auto_pay_another_pay_due_lbl;
      another_pay_due_note =this.state.setup_auto_pay_another_pay_due_note;
      another_pay_due_note_1=this.state.setup_auto_pay_another_pay_due_note_1;
      success_note= this.state.setup_auto_pay_success_note;
      return_button_lbl = isPastAmtDue === 'true' ? this.state.make_onetime_pay_link: this.state.go_to_overview ;
    }


    // Manage Recurring/ Update EFT
    if(getRecurringPaymentFlag() && isManageRecurring() && this.state.cancelRecChecked === false) {
      payment_method = this.state.manage_auto_pay_label;
      banner_txt = this.state.manage_pay_banner_txt;
      success_note = this.state.manage_pay_success_note;
      confirmation_label = this.state.manage_pay_conf_label;
      return_button_lbl = this.state.return_to_overview;
      another_pay_due_note_1=this.state.setup_auto_pay_another_pay_due_note_1;
    }
    //This is one-time payment
    if (getOneTimePaymentFlag() ) {
      banner_txt = this.state.onetime_pay_banner_text;
      confirmation_label = this.state.onetime_confirmation_label;
      payment_method= this.state.pay_rcpt_ot_pay_lbl;
      another_pay_due_lbl=this.state.onetime_another_pay_due_lbl;
      another_pay_due_note =this.state.onetime_another_pay_due_note;
      success_note = this.state.onetime_success_note;
      return_button_lbl= this.state.return_pay_overview_lbl;
    }
    //This is cancel recurring
    if(this.state.cancelRecChecked) {
      payment_method = this.state.manage_auto_pay_label;
      banner_txt = this.state.cancel_pay_banner_txt;
      success_note = this.state.cancel_pay_success_note;
      confirmation_label = this.state.cancel_pay_conf_label;
      return_button_lbl = this.state.return_to_overview;
      //another_pay_due_note_1=this.state.setup_auto_pay_another_pay_due_note_1;
    }

    //This is for CMS-SSA Flow
    if(this.state.isCmsSsa){
      banner_txt = this.state.ssarrb_banner_txt;
      payment_method= this.state.pay_rcpt_ssarrb_pay_lbl;
      success_note = this.state.ssarrb_success_note;
      confirmation_label = this.state.ssarrb_conf_label;
      ssarrb_receipt_pay_note = this.state.ssarrb_receipt_pay_note;
      another_pay_due_note =this.state.ssarrb_auto_pay_another_pay_due_note;
      another_pay_due_lbl=this.state.ssarrb_auto_pay_another_pay_due_lbl;
      return_button_lbl = isPastAmtDue === 'true' ? this.state.make_onetime_pay_link: this.state.go_to_overview ;
    }

    //This is update one-time payment
    if (isEditPayment() ) {
      banner_txt = this.state.update_pay_banner_txt;
      confirmation_label = this.state.update_confirmation_label_fed;
      payment_method= this.state.pay_rcpt_update_ot_pay_lbl;
      another_pay_due_note =this.state.onetime_another_pay_due_note;
      success_note = this.state.update_success_note_fed; 
      return_button_lbl= this.state.return_pay_overview_lbl;
    }


    let paymentDetails = null;
    let electronicSignature =null; 
    
    if(this.state.paymentMethod === 'CC' &&  this.props.location.state.savePayMethodChecked && !isEditPayment() ){
      if(this.props.location.state.isPayMethodSaved) {
      paymentDetails = (<span>{this.state.cardType + '***' + this.state.cardNumber}
       <p><div style={{display: 'flex', align: 'baseline'}} ><SavedLogo/><span className="savedText">{this.state.saved_label_text}</span></div></p>
      </span>
      )}
      else{
      paymentDetails = (<span>{this.state.cardType + '***' + this.state.cardNumber}
       <p><div style={{display: 'flex', align: 'baseline'}} ><span className="savedText">{"Unable to save method of payment to your wallet"}</span></div></p>
      </span>
      ) 
      }
    } else if (this.state.paymentMethod === 'CC' && isEditPayment() ) {
      paymentDetails = <span>{isEditPayment() ? this.props.location.state?.currentMop : this.state.cardType + '***' + this.state.paymentToEdit?.creditCard?.cardNumberLastFour}</span>
    }else if (this.state.paymentMethod === 'CC') {
      paymentDetails = <span>{this.state.cardType + '***' + this.state.cardNumber}</span>
    } else  if (this.state.payWithSavedCC) {
      paymentDetails = <div>{getSearchPaymentMethodResult().type + '****' + getSearchPaymentMethodResult().numberLastFour}</div>
    } else if(this.props.location.state?.cancelRecChecked){
      paymentDetails = (
        <span>
           {this.state.cancel_auto_pay_label}
        </span>
      )
    } else if (this.state.paymentMethod === 'EFT' &&  this.props.location.state.savePayMethodChecked  && !isEditPayment()){
      if(this.props.location.state.isPayMethodSaved){
      paymentDetails = (
        <span>
           {this.props.location.state.bankAccountType.toLowerCase().indexOf('checking') > -1 ? this.state.eft_checking_txt_label : this.state.eft_savings_txt_label}{this.state.accountNumberFormated.substring(this.state.accountNumberFormated.length - 4)}
           <p><div style={{display: 'flex', align: 'baseline'}} ><SavedLogo/><span className="savedText">{this.state.saved_label_text}</span></div></p>
        </span>
      )}else{
        paymentDetails = (
          <span>
             {this.props.location.state.bankAccountType.toLowerCase().indexOf('checking') > -1 ? this.state.eft_checking_txt_label : this.state.eft_savings_txt_label}{this.state.accountNumberFormated.substring(this.state.accountNumberFormated.length - 4)}
             <p><div style={{display: 'flex', align: 'baseline'}} ><span className="savedText">{"Unable to save method of payment to your wallet"}</span></div></p>
          </span>
        ) 
      }
    } else if (getProductType() === 'GOVT'){
      paymentDetails = (
        <span>
           {isEditPayment() ? this.props.location.state?.currentMop : this.props.location.state?.bankAccountType && this.props.location.state.bankAccountType.toLowerCase().indexOf('checking') > -1 ? this.state.eft_checking_txt_label : this.state.eft_savings_txt_label}{!isEditPayment() ? this.state.accountNumberFormated?.substring(this.state.accountNumberFormated?.length - 4) : null}
        </span>
      )
    } else {
      paymentDetails = (
        <span>
           {this.state.eft_checking_txt_label}{this.state.accountNumberFormated.substring(this.state.accountNumberFormated.length - 4)}
        </span>
      )
    }

    if (getProductType() === 'GOVT' && (this.state.paymentMethod === 'CC' || this.state.payWithSavedCC )) {
      electronicSignature = <Markup content={getOneTimePaymentFlag() ? this.state.cc_ele_sig_note : this.state.cc_ele_sig_note_setup}/>
    } else if(getProductType() === 'SHIP' && this.state.paymentMethod === 'CC'){
      electronicSignature = <Markup content={getOneTimePaymentFlag() ? this.state.cc_ele_sig_note : this.state.ele_sig_note}/>
    } else{
      electronicSignature = <Markup content={this.state.ele_sig_note}/>
    }

    let signin_component = null;

    return (
      <div>
        {this.state.spinnerLoading ? <Spinner /> : null}
        <div className={this.state.spinnerLoading ? 'text-blur' : ''}>
        <div className="d-print-none">
        <PageHeader links={this.state.hLinks} back_btn={this.state.back_btn} title={banner_txt}
            plan={secureLocalStorage.getItem("shipPlanNamesList") && getProductType() === 'SHIP' ? secureLocalStorage.getItem('multiShipPlanType') : getProductType().planType}
            />
        { this.state.showSessionModal ?
                       <Redirect to="/service-error" push={true} />
                      : null }
        </div>
        <div ref={this.ref}>

            <Grid className="uhc-container-width" id="confirmationpage">
              <Grid.Item>
                <div className="row justify-content-center">
                  <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 print-col mt-4">
                    <div className="uhc-tile-brd-wdgt uhc-margin-bottom-40">
                      <div className="text-center mt-3 d-print-mt d-print-none">
                        <IconCheckMrk2CCirRGB />
                      </div>
                      <Typography.H3 className={this.state.isCmsSsa ? 'ssatextalgn' : 'text-center'}>{this.state.cancelRecChecked ?  success_note : <div>{success_note}</div>} 
                        {isManageRecurring() && !getOneTimePaymentFlag() && !isEditPayment() && !this.state.isCmsSsa ? this.state.nextMonthFirstDate + 'st.':
                        getProductType() === 'GOVT' && !getOneTimePaymentFlag() && !isEditPayment() && !this.state.isCmsSsa ? this.state.nextMonthFirstDate + 'st.' : this.state.isCmsSsa ? <span style={{paddingLeft:'8px'}}>{this.state.ssaNextAvailableDate + 'st.'}</span> : ''}
                      </Typography.H3>
                        <> 
                          <Typography.Paragraph className="pt-3 pb-3 d-print-none">
                            {confirmation_label}
                           </Typography.Paragraph>
                          {window.innerWidth > 770  ? (
                            <hr className="hr-separator-solid mt-4 d-print-none"></hr>
                          ) : null}
                          {!this.state.cancelRecChecked  && getProductType() === 'GOVT' ? (
                            <>
                            <hr className={window.innerWidth < 770 ? "hr-separator-solid mt-4 d-print-none" : "hr-separator-solid mt-4 d-print-none uhc-hide"}></hr>
                            <Typography.Paragraph className="pt-3 pb-3 d-print-none">
                                {this.state.email_note}
                            </Typography.Paragraph>
                            </>
                          ) : null}

                        </>
                    </div>
                    { (getProductType() === 'SHIP' && getOneTimePaymentFlag()) &&
                    <div className="uhc-tile-brd-wdgt uhc-margin-bottom-40">
                      <Typography.Paragraph className="pt-3 pb-3 d-print-none">
                        {this.state.email_confirmation_msg}
                      </Typography.Paragraph>
                    </div>
                    }
                    <div className="sm-hide">{signin_component}</div>
                    {(getOneTimePaymentFlag() ) && secureLocalStorage.getItem('fedShipPlanCount') > 1 ? (  
                      <div className="payment-confirm-due-box">
                      <div className="payment-confirm-due-box-padding" >
                        <h3 className="tds-header__h1 mt-4 mb-2">
                        {another_pay_due_lbl}
                        </h3>
                        <Typography.Paragraph className="tds-margin-sm-top-only">
                        {another_pay_due_note}
                        </Typography.Paragraph>
                        <br/>
                        <Button
                          buttonType="primary-one"
                          className="mt-4 mb-4 uhc-btn-hide d-print-none"
                          id="payment-confirm-return-btn"
                          disabledBorder={false}
                          loading={false}
                          size="md"
                          onClick={this.navigateTo}
                        >
                          {return_button_lbl}
                        </Button>
                        </div>
                    </div>
                  ): (((getRecurringPaymentFlag() && !isManageRecurring()) || this.state.isCmsSsa) && secureLocalStorage.getItem('fedShipPlanCount') > 1 && isPastAmtDue === 'true') ? (  
                    <div className="payment-confirm-due-box">
                    <div className="payment-confirm-due-box-padding" id="another_pay_note_head">
                      <h3 className="tds-header__h1 mt-4 mb-2">
                          {this.state.isCmsSsa? this.state.ssarrb_auto_pay_another_pay_due_lbl : this.state.setup_auto_pay_another_pay_due_lbl}{' '}{'$' + convertToCurrency(totalAmountDue)}{'.'}
                      </h3>
                      <Typography.Paragraph className="tds-margin-sm-top-only mt-4">
                          <Markup content={another_pay_due_note}/>
                      </Typography.Paragraph>
                      <br/>
                      <Button
                        buttonType="primary-one"
                        className="mt-4 mb-4 uhc-btn-hide d-print-none"
                        id="payment-confirm-return-btn"
                        disabledBorder={false}
                        loading={false}
                        aria-describedby="another_pay_note_head"
                        size="md"
                        onClick={this.navigateTo}
                      >
                        {return_button_lbl}
                      </Button>
                      </div>
                  </div>
                ): (((getRecurringPaymentFlag() && !isManageRecurring()) || this.state.isCmsSsa) && isPastAmtDue === 'true') ? (
                      <div className="payment-confirm-due-box">
                      <div className="payment-confirm-due-box-padding" >
                        <h3 className="tds-header__h1 mt-4 mb-2" id="another_pay_note_head">
                            {this.state.isCmsSsa? this.state.ssarrb_auto_pay_another_pay_due_lbl : this.state.setup_auto_pay_another_pay_due_lbl}{' '}{'$' + convertToCurrency(totalAmountDue)}{'.'}
                        </h3>
                        <Typography.Paragraph className="tds-margin-sm-top-only mt-4">
                        <Markup content={another_pay_due_note}/>
                        </Typography.Paragraph>
                        <br/>
                        <Button
                          buttonType="primary-one"
                          className="mt-4 mb-4 d-print-none"
                          id="makePaymentBtn"
                          disabledBorder={false}
                          loading={false}
                          size="md"
                          aria-describedby="another_pay_note_head"
                          onClick={this.navigateTo}
                        >
                          {return_button_lbl}
                        </Button>
                        </div>
                    </div>
                  ):
                  <Button
                    buttonType="primary-one"
                    className="mt-4 mb-4 uhc-btn-hide d-print-none"
                    disabledBorder={false}
                    id="return-to-overview-btn"
                    loading={false}
                    size="md"
                    onClick={this.navigateTo}
                  >
                    {return_button_lbl}
                  </Button>}

                  </div>
                  <PaymentReceipt
                   scrollToESignBox={this.scrollToESignBox}
                   ref={this.ref}
                   paidAmount={this.state.paidAmount}
                   paymentDetails={paymentDetails}
                   serverDate={this.state.serverDate}
                   withdrawalDate={this.state.withdrawalDate}
                   paymentDate={this.props?.location?.state?.paymentDate}
                   eaipTransactionDate={this.props?.location?.state?.eaipTransactionDate}
                   paymentMethodSelected={this.props?.location?.state?.paymentMethod}
                   receipt_label={this.state.receipt_label}
                   plan_label ={this.state.plan_label}
                   name_label={this.state.name_label}
                   id_label={this.state.id_label}
                   details_label={this.state.details_label}
                   total_label={this.state.total_label}
                   another_pay_due_note={another_pay_due_note}
                   return_pay_overview_lbl={this.state.return_pay_overview_lbl}
                   ele_pay_auth_sign_lnk ={this.state.ele_pay_auth_sign_lnk}
                   another_pay_due_note_1 = {another_pay_due_note_1}
                   payment_method= {payment_method}
                   pay_rcpt_auto_pay_note ={this.state.pay_rcpt_auto_pay_note}
                   pay_rcpt_notification_lbl = {this.state.pay_rcpt_notification_lbl}
                   pay_rcpt_auto_pay_start_dt_lbl ={this.state.pay_rcpt_auto_pay_start_dt_lbl}
                   start_date ={this.state.nextMonthStartDt}
                   ssarrb_start_date ={this.props?.location?.state?.ssaNextAvailableDate}
                   cancelRecChecked = {this.state.cancelRecChecked}
                   view_your_txt = {this.state.view_your_txt}
                   pay_rcpt_auto_pay_stop_dt_lbl={this.state.pay_rcpt_auto_pay_stop_dt_lbl}
                   manage_pay_due_note={this.state.manage_pay_due_note}
                   setup_rec_total_text={this.state.setup_rec_total_text}
                   below_text_label={this.state.below_text_label}
                   reference_label_text={this.state.reference_label_text}
                   email_note={this.state.auth_email_note}
                   view_print_pdf={this.state.view_print_pdf}
                   isCmsSsa = {this.state.isCmsSsa}
                   ssarrb_receipt_pay_note = {ssarrb_receipt_pay_note}
                   ssa_rrb_no_ref_num_note = {this.state.ssa_rrb_no_ref_num_note}
                   ssarrb_estimated_dt = {this.state.ssarrb_estimated_dt}
                   monthlyPaymentPremium={this.props?.location?.state.monthlyPaymentPremium}
                   paymentsMethodValue={this.props?.location?.state.paymentsMethodValue}
                   />
                </div>
              </Grid.Item>
              <Grid.Item className="d-print-none">
                <div className="row sm-show">
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">{signin_component}</div>
                </div>
              </Grid.Item>
             </Grid>


       {!this.state.cancelRecChecked && !this.state.isCmsSsa ? (
         <div className="payment-confirm-esign-box d-print-mb">
         <div className="container" ref={this.myRef}>
           <div className="row">
             <div className="col-md-12">
             <Typography.Paragraph className="uhc-capitalize">
                   {this.state.elec_sig_lbl} {this.state.fullName}
                   </Typography.Paragraph>
                   <br/>
                   <p className="tds-typography__paragraph pt-2 mw-100">
                   {electronicSignature}
                   </p>
             </div>
           </div>
         </div>
         </div>
       ) :null}



        <div className="d-print-mb mt-4 btm70 printcontent d-print-none">
        <div className="container">
        <div className="row">
                  <div className="col-md-12">
          <Markup content={this.state.print_footer_content}/>
          </div>
                </div>
          </div>
        </div>

        </div>
        </div>
      </div>
    )
  }
}

const BodyWrapperBlurry = styled.div`
  filter: blur(8px);
  -webkit-filter: blur(8px);
`;

export default withRouter(PaymentConfirmation)

