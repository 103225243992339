const winston = require('winston')
const SplunkStreamEvent = require('winston-splunk-httplogger')
const { format } = winston
const { combine, colorize, simple, timestamp, printf } = format

const ErrorSymbol = Symbol('LogError')

const transports = [
  new winston.transports.Console({
    format: combine(
      timestamp(),
      colorize(),
      printf((info) => {
        const { level, message } = info
        const error = info[ErrorSymbol]

        const clonedInfo = { ...info }
        delete clonedInfo.level
        delete clonedInfo.message
        return `[${level}]: ${message} ${JSON.stringify(clonedInfo)} ${
          error ? JSON.stringify(error, Object.getOwnPropertyNames(error)) : ''
        }`
      })
    ),
    handleExceptions: true,
  }),
]

const accessLogTransports = [
  new winston.transports.Console({
    format: combine(colorize(), simple()),
  }),
]

if (process.env.SPLUNK) {
  accessLogTransports.push(
    new SplunkStreamEvent({
      handleExceptions: true,
      handleRejections: true,
      splunk: {
        token: process.env.SPLUNK_TOKEN,
        url: process.env.SPLUNK_URL,
        index: process.env.SPLUNK_INDEX,
        sourcetype: process.env.SPLUNK_SOURCE_TYPE,
        port: '443',
        protocol: 'https',
      },
    })
  )
  transports.push(
    new SplunkStreamEvent({
      handleExceptions: true,

      splunk: {
        token: process.env.SPLUNK_TOKEN,
        url: process.env.SPLUNK_URL,
        index: process.env.SPLUNK_INDEX,
        sourcetype: process.env.SPLUNK_SOURCE_TYPE,
        port: '443',
        protocol: 'https',
      },
    })
  )
}

const accessLogger = winston.createLogger({
  level: 'info',
  format: winston.format.json(),

  transports: accessLogTransports,
  exitOnError: false,
})

const logger = winston.createLogger({
  level: process.env.LOG_LEVEL || 'debug',
  format: winston.format.json(),

  transports,
  exitOnError: false,
})

process.on('unhandledRejection', (reason) => {
  throw reason
})

module.exports = { accessLogger, logger, ErrorSymbol }
