import axios from 'axios';
import appConfigs from '../app.config';

const serverTime = axios.create({
	baseURL: appConfigs.api_base_url
});

export const serverCurrentTime = async () => {

	return serverTime.get(appConfigs.server_time)
	.then((response) => {
		return response.data;
	})
	.catch((error) => {
		console.log(error);
	});

};

