/* eslint-disable no-script-url */
import moment from 'moment';
import React, { Component, Fragment } from 'react'
import axios from '../../../server/api/aem-api';
import appConfigs from '../../../server/app.config';
import config from '../../../server/config';
import '../payment-details/PaymentForm.css';
import '../payment-details/FedPaymentForm.css'
import { trackPageLoad, trackFormSubmit, trackLinkClick, trackError, trackImpression } from '../../../server/util/analytics'
import { Typography, Notification, FormGroup, Accordion, AccordionGroup, Form, RadioButton, Button, Checkbox } from '@uhc-tempo/components';
import { getPlanInformation, getOneTimePaymentFlag, getRecurringPaymentFlag, getOverviewPageLink, getPaymentMethod, getSearchPaymentMethodResult,
   isManageRecurring, getProductType, isEditPayment, isCurrentRecurring } from '../../../server/util/localStorageRepo';
import getSearchPaymentHistory from '../../api/searchPaymentHistory';
import Spinner from '../../UI/Spinner/Spinner';
import { convertToCurrency, isDesktop } from '../../../server/util/util'
import FedPaymentAmount from '../payment-details/FedPaymentAmount'
import FedPaymentSummary from '../summary/FedPaymentSummary'
import PageHeader from '../page-header/PageHeader';
import FedPayMethods from './FedPayMethods';
import PaymentModals from '../modals/PaymentModals';
import SchedulePayment from './SchedulePayment';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import FedPayRadioBtn from '../payment-details/FedPayRadioBtn';
import FedPayEftForm from '../payment-details/FedPayEftForm';
import CreditCardGPS from '../CreditCard/CreditCardGPS';
import PageError from '../payment-details/PageError';
import searchPaymentWalletResultsAPI from '../../../server/api/searchPaymentWalletResults-api';
import { isAndroid } from '../shared-components/detectDevice';
import { Markup } from 'interweave';
import secureLocalStorage from 'react-secure-storage';

export let eftFormAem = null;
export let selectPaySumAem = null;

export class FedPaymentForm extends Component {
    constructor(props) {
        super(props);
        this.otherAmountRef = React.createRef()
        this.isDesktop = isDesktop()
        this.setPaymentDate = this.setPaymentDate.bind(this);
        this.showSuperUserError = this.showSuperUserError.bind(this);
        this.setScheduleChoice = this.setScheduleChoice.bind(this);
        this.setRecScheduleChoice = this.setRecScheduleChoice.bind(this);
        this.setAuthIndicator = this.setAuthIndicator.bind(this);

        this.state = {
            fullMOPList: false,
            scheduleChoice: 'payNow',
            recScheduleChoice: 'startNextMonth',
            paymentDate: moment().format('MM-DD-YYYY'),
            walletRetrieveCompleted: false,
            currentDay: moment(),
            nextDay: moment().add(1, 'day'),
            thirtyDays: moment().add(31, 'days'),
            isServiceError: false,
            defaultPaidAmoutPlaceHolder : '$__.__',
            payAmount: '0.00',
            serverDate: '',
            otherAmount: '',
            otherAmountDisplay: '',
            paymentMethod: '',
            bankHolderFirstName: '',
            bankHolderMiddleName: '',
            bankHolderLastName: '',
            routingNumber: '',
            accountNumber: '',
            pastAmountDue: '',
            pastAmountAndCurentCharges: '',
            currentCharges: '',
            currentPaymentDate: '',
            firstDayOfMonth: '',
            hTitle: "",
            hLinks: [{}],
            otherAmountError: '',
            amtselectcss: 'default',
            // conditional flag based
            formValidated: false,
            onload: true, 
            pastAmountDueChecked: false,
            pastAmountAndCurentChecked: false,
            onlyCurrentChargesChecked: false,
            otherAmountChecked: false,
            payInFullChecked: false,
            loading: true,
            cMSSSAFound: false, 
            pageLoadViaFlow: false,
            isDefaultEftPaymentSelectionAvailable: false,
            pastAmountDueFound: '',
            pastAmountAndCurentChargesFound: '',
            onlyCurrentChargesFound: '',
            otherAmountErrorDisp: false,
            paymentHistoryCallCompleted: false,
            isAddPayMethodOpen : false,
            datePickerCssState : 'default',
            datePickerError : false,
            datePickerPageErrorText : '',
            totalPendingAmount : '0.00',
            paymentToEdit: secureLocalStorage.getItem('paymentToEdit') && JSON.parse(secureLocalStorage.getItem('paymentToEdit')),
            superUser: localStorage.getItem("superUser") === 'true' ? true : false,
            mfaValidation: false,
            eftNumber: (this.props.location.state) ? this.props.location.state.eftNumber : secureLocalStorage.getItem('eftNumber'),
            paymentsMethodValue:(this.props.location.state) ? this.props.location.state.paymentsMethodValue : secureLocalStorage.getItem('paymentsMethodValue'),
            monthlyPaymentPremium: (this.props.location.state && this.props.location.state.fromPage === 'overview') ? this.props.location.state.monthlyPaymentPremium : '', 
            authIndicator : '',
          }

          this.eftRef = React.createRef()
        }

    scrollToElement = (el, block) => {
      el.focus()
      el.scrollIntoView({behavior: 'smooth',block: block, inline: "nearest"})
    }

    setAuthIndicator = (authIndicator) => {
      this.setState({authIndicator})
    }

    async getWalletDetails() {
        const response = await searchPaymentWalletResultsAPI(getPlanInformation().accountId);
        let items = [];
        if(response.walletStatusCode === 200) {
            items = response.activeItems; 
            //EAPR and Total Amount calculation based on payment states
            this.setState({ totalPendingAmount: response.pendingAmount, ssaDates: response.ssaDates})
            secureLocalStorage.setItem('upgAccountId', response.masterCustId)
        } else if (response.walletStatusCode !== 200) {
            items = [];
        }
        if(response.walletStatusCode !== 200 || response.paymentStatusCode !== 200) {
          this.setState({isServiceError : true})
        }
        if(items.length > 0) {
            this.setState({activeWalletItems : items,
                        activeBankItems : items.filter(el=> el.creditCard == null && el.active.toLowerCase() === 'true'),
                        activeCCItems : items.filter(el=> el.bankAccount == null && el.active.toLowerCase() === 'true'),
                        }, () => {
                          this.state.activeWalletItems.sort((a,b) => moment(a.audit?.modifiedOn).isBefore(b.audit?.modifiedOn) ? 1 : -1)
                          secureLocalStorage.setItem("activeWalletItemsCC", JSON.stringify(this.state.activeWalletItems))
                        })
        }
        window.scrollTo(0,0)
        trackImpression((this.state.activeWalletItems?.length > 0) ? 'payments-method:saved' : 'payments-method:none saved');
        console.log((this.state.activeWalletItems?.length > 0) ? 'payments-method:saved' : 'payments-method:none saved')
        }

    amountsExplainedModal = {}
    selectAmntCnt = {}
    summaryCnt= {}

    callSelectPaySumAemAPISummary() {
      try {
          axios.get(appConfigs.aem_base_url + config.REACT_APP_CONTENT_PATH + '/fed-payment-amt-and-summary/fed-payment-amt-and-summary/jcr:content/data/master.json?timestamp=' + new Date().getTime())
              .then(response => {
                selectPaySumAem = response.data
                  if (selectPaySumAem) {

                    let linksOneTime = [{ title: selectPaySumAem.auth_bc_po_text, link:'javascript:', click:() => window.location.assign(getOverviewPageLink()) },
                        { title: selectPaySumAem.auth_bc_motp_text, link: '' }];

                    let linksUpdateOneTime = [{ title: selectPaySumAem.auth_bc_po_text, link:'javascript:', click:() => window.location.assign(getOverviewPageLink()) },
                        { title: selectPaySumAem.auth_bc_update_onetime_txt, link: '' }];
                    
                    let linksRecurring = [{ title: selectPaySumAem.auth_bc_po_text, link:'javascript:', click:() => window.location.assign(getOverviewPageLink())},
                        { title: selectPaySumAem.auth_bc_setup_rec_text, link: '' }];

                    let linksManagePaymentMethod = [{ title: selectPaySumAem.auth_bc_po_text, link:'javascript:', click:() => window.location.assign(getOverviewPageLink()) },
                        { title: selectPaySumAem.auth_bc_mng_rec_text, link: '' }];
                    
                    this.setState({
                      hTitle: getOneTimePaymentFlag() ? selectPaySumAem.auth_fed_motp_title : isEditPayment() ? selectPaySumAem.auth_update_otp_label : getRecurringPaymentFlag() && !isManageRecurring() ? selectPaySumAem.auth_fed_sr_title : selectPaySumAem.auth_fed_mpm_title,
                      hLinks: getOneTimePaymentFlag() ? linksOneTime : isEditPayment() ? linksUpdateOneTime : getRecurringPaymentFlag() && !isManageRecurring() ? linksRecurring : linksManagePaymentMethod,
                      back_btn: selectPaySumAem.auth_bc_bck_link,
                    }) 

                    this.amountsExplainedModal = {
                      link_label: selectPaySumAem.auth_fed_pay_amt_link_text,
                      header: selectPaySumAem.auth_fed_amt_exp_header,
                      section_1: getProductType() === 'GOVT' && getPaymentMethod().indexOf('CMS-SSA/RRB') > -1 ? selectPaySumAem.auth_fed_wo_pay_in_full_content : selectPaySumAem.auth_fed_amt_exp_modal_sec,
                      btn_txt: selectPaySumAem.auth_fed_modal_btn_close_txt
                    }

                    this.cancelPaymentModal = {
                      header: isEditPayment() ? selectPaySumAem.payment_cancel_update_header : selectPaySumAem.auth_mw_cancelmodal_title,
                      cancelpay_txt: isEditPayment() ? selectPaySumAem.payment_cancel_update_txt: '',
                      btn_txt1: selectPaySumAem.auth_mw_stayonpage_btn,
                      backto_overview: isEditPayment() ? selectPaySumAem.payment_update_cancel_btn : selectPaySumAem.auth_mw_back_pay_overview_btn,
                      cancelConfirmModal: {
                        header: selectPaySumAem.auth_mw_pay_cancel_text,
                        bckTxt: selectPaySumAem.auth_mw_takingback_content,
                        cancelpay_txt: isEditPayment() ? selectPaySumAem.payment_update_cancel_btn : selectPaySumAem.auth_mw_cancelpay_btn,
                        backto_overview: selectPaySumAem.auth_mw_back_pay_overview_btn
                      }
                    }

                    this.ssbModal = {
                      desc: selectPaySumAem.auth_fed_ssb_modal_content,
                      header: selectPaySumAem.auth_fed_ssb_modal_title,
                      ssb_btn_txt1: selectPaySumAem.auth_fed_ssb_modal_button_pdf,
                      ssb_btn_txt2: selectPaySumAem.auth_fed_ssb_modal_button_contact,
                      setup_footer_note: selectPaySumAem.auth_fed_setup_footer_note,
                      ssb_link_text: selectPaySumAem.auth_ssb_link_text
                    }
                
                    this.selectAmntCnt = {
                      auth_spa_header_section_1 : selectPaySumAem.auth_spa_section_heading,
                      auth_pa_header_section_1 : selectPaySumAem.auth_pa_section_heading,
                      auth_payamt_sec_note : selectPaySumAem.auth_fed_pay_amt_sec_note,
                      auth_spa_radio_1 : selectPaySumAem.auth_due_today_radio,
                      auth_spa_radio_2 : selectPaySumAem.auth_due_upcoming_radio,
                      auth_spa_radio_3 : selectPaySumAem.auth_other_amt_radio,
                      auth_upcoming_charges_label : selectPaySumAem.auth_fed_upcoming_charges_label,
                      auth_due_label : selectPaySumAem.auth_fed_due_label,
                      auth_enteramt_label : selectPaySumAem.auth_fed_enter_amt_text,
                      auth_pay_in_full : selectPaySumAem.auth_pay_full_radio,
                      auth_fed_updt_otp_note : selectPaySumAem.auth_fed_updt_otp_note,
                      auth_update_amount_label : selectPaySumAem.auth_update_amount_label,
                    }
                    
                    this.cancelRecurringPayment = {
                      cancelRecurring: selectPaySumAem.auth_fed_mpm_radio_header1,
                      cancelRecurringNote: selectPaySumAem.auth_cancel_rec_note,
                      whatToUpdate: selectPaySumAem.auth_fed_mpm_sec_heading,
                      paymentMethodBeginNextMonth: selectPaySumAem.auth_fed_mpm_sec_note,
                      cancelRecurringRadioLbl: selectPaySumAem.auth_fed_cancel_autopay_label
                    }

                    this.summaryCnt = {
                      summary_label : selectPaySumAem.auth_fed_pay_summ_title,
                      plan_label : selectPaySumAem.auth_fed_plan_label,
                      membername_lable : selectPaySumAem.auth_fed_mem_name_label,
                      id_label : selectPaySumAem.auth_fed_id_label,
                      details_label : selectPaySumAem.auth_fed_pay_details_label,
                      total_label : selectPaySumAem.auth_fed_total_label,
                      enrolltext_label : selectPaySumAem.auth_fed_enroll_text,
                      onetimepayment_label : selectPaySumAem.auth_fed_otp_label,
                      updateonetimepayment_label : selectPaySumAem.auth_update_otp_label,
                      eftchecking_label : selectPaySumAem.auth_fed_eft_checking_label,
                      eftsavings_label : selectPaySumAem.auth_fed_eft_saving_label,
                      setupautopay_label : selectPaySumAem.auth_fed_sap_label,
                      updateautopay_label : selectPaySumAem.auth_fed_mpm_title,
                      currentpaymentmethod_label : selectPaySumAem.auth_fed_pay_method_label,
                      cancelRec_label : selectPaySumAem.auth_fed_cancel_autopay_label,
                      startDate_label : selectPaySumAem.auth_start_date_label_text,
                      auth_cms_ssa_rrb_txt :  selectPaySumAem.auth_cms_ssa_rrb_txt,
                      auth_direct_bill_txt :  selectPaySumAem.auth_direct_bill_txt
                    }
                    this.choosePay = {
                      choosePayHeading : getOneTimePaymentFlag() ? '3. ' + selectPaySumAem.payment_choose_heading : getRecurringPaymentFlag() ? '2. ' +  selectPaySumAem.payment_choose_heading : selectPaySumAem.payment_choose_heading,
                      choosePaySubHeading : selectPaySumAem.payment_choose_subheading,
                      updateCurrentPayMethodHeading : selectPaySumAem.auth_fed_mpm_radio_header2,
                      choosePaySubHeading2 : selectPaySumAem.payment_choose_subheading_2,
                      noWalletItemsFound : selectPaySumAem.payment_no_wallet_items_found,
                      showMorePaymentMethods : selectPaySumAem.payment_show_more_methods,
                      showLessPaymentMethods : selectPaySumAem.payment_show_less_methods,
                      recurringChoosePayNote : selectPaySumAem.auth_fed_setup_auto_pay_note,
                    }

                    this.schedulePay = {
                      schedulePayHeading : selectPaySumAem.payment_schedule_heading,
                      schedulePaymentDateLbl : selectPaySumAem.payment_schedule_date_lbl,
                      reviewSubmitBtnTxt : selectPaySumAem.payment_review_submit_btn,
                      cancelBtnTxt : selectPaySumAem.payment_cancel_btn_lbl


                    }
                      this.setState({ selectPaySumAemRendered: true })
                  }
              })
      } catch (error) {
          // need to write error handing
      }
    }
    nachaZeroModalContent = {}
    callEftFormAemAPISummary() {
      try {
          axios.get(appConfigs.aem_base_url + config.REACT_APP_CONTENT_PATH + '/manage-wallet-eft-form-content/manage-wallet-eft-form-content/jcr:content/data/master.json?timestamp=' + new Date().getTime())
              .then(response => {
                eftFormAem = response.data
                  if (eftFormAem) {
                    this.nachaZeroModalContent = {
                      header : eftFormAem.auth_mw_zero_attempt_modal_heading,
                      model_msg : eftFormAem.auth_mw_zero_attempt_modal_content,
                      btn_text : this.props.page === 'wallet' ? eftFormAem.auth_mw_close_btn_txt : eftFormAem.auth_mw_goto_pay_btn_txt,
                      wallet_ews: eftFormAem.auth_mw_zero_attempt_modal_content_wallet,
                      ews_contact: eftFormAem.auth_mw_zero_attempt_modal_ews_contact,
                      page: 'payment'
                      
                  }
                      this.setState({ eftFormAemRendered: true })
                  }
              })
      } catch (error) {
          // need to write error handing
      }
    }
  
    hydrateStateWithLocalStorage = () => {
        console.log('In hydrate storage')
        this.setState({
            // pastAmountDueFound: secureLocalStorage.getItem('pastAmountDueFound'),
            pastAmountAndCurentChargesFound: getPaymentMethod().includes("CMS") ? false : this.state.pastAmountAndCurentChargesFound,
            onlyCurrentChargesFound: getPaymentMethod().includes("CMS") ? false : this.state.onlyCurrentChargesFound ,
            // pastAmountDue: secureLocalStorage.getItem('pastAmountDue'),
            // pastAmountAndCurentCharges: secureLocalStorage.getItem('pastAmountAndCurentCharges'),
            currentPaymentDate: secureLocalStorage.getItem('currentPaymentDate'),
            firstDayOfMonth: secureLocalStorage.getItem('firstDayOfMonth'),
            estimatedAnnualPremium: secureLocalStorage.getItem('estimatedAnnualPremium'),
            superUser: localStorage.getItem("superUser") === 'true' ? true : false,
            isEditPayment: isEditPayment(),
        }, () => {
            let isRedirect = ((this.props && this.props.location && this.props.location.state) && this.props.location.state.fromPage !== 'overview');
          if (!isRedirect) {
            if (this.state.pastAmountDueFound && parseFloat(this.state.pastAmountDue) <= parseFloat(this.state.estimatedAnnualPremium)) {  
                this.setState({ pastAmountDueChecked: true, payAmount: '$' + convertToCurrency(this.state.pastAmountDue)})
                secureLocalStorage.setItem('payAmount', '$' + convertToCurrency(this.state.pastAmountDue))
                secureLocalStorage.setItem('pastAmountDueChecked', true)
            } else if (this.state.pastAmountAndCurentChargesFound && parseFloat(this.state.pastAmountAndCurentCharges) <= parseFloat(this.state.estimatedAnnualPremium)) {
                this.setState({ pastAmountAndCurentChecked: true, payAmount: '$' + convertToCurrency(this.state.pastAmountAndCurentCharges)})
                secureLocalStorage.setItem('payAmount', '$' + convertToCurrency(this.state.pastAmountAndCurentCharges))
                secureLocalStorage.setItem('pastAmountAndCurentChecked', true)
            } else if (this.state.onlyCurrentChargesFound && parseFloat(this.state.currentCharges) <= parseFloat(this.state.estimatedAnnualPremium)) {
                this.setState({ onlyCurrentChargesChecked: true, payAmount: '$' + convertToCurrency(this.state.currentCharges)})
                secureLocalStorage.setItem('payAmount', '$' + convertToCurrency(this.state.currentCharges))
                secureLocalStorage.setItem('onlyCurrentChargesChecked', true)
            } else if (secureLocalStorage.getItem('estimatedAnnualPremium') > 0) {
                this.setState({ payInFullChecked: true, payAmount: '$' + convertToCurrency(secureLocalStorage.getItem('estimatedAnnualPremium'))})
                secureLocalStorage.setItem('payAmount', '$' + convertToCurrency(secureLocalStorage.getItem('estimatedAnnualPremium')))
                secureLocalStorage.setItem('payInFullChecked', true)
            } else if (getOneTimePaymentFlag()) {
                this.setState({ otherAmountChecked: true })
                secureLocalStorage.setItem('otherAmountChecked', true)
            }
          }
        })
        if (secureLocalStorage.getItem('estimatedAnnualPremium') != null) {
          let amt = parseFloat(secureLocalStorage.getItem('estimatedAnnualPremium'))
          if(isEditPayment()) {
            amt =  (parseFloat(secureLocalStorage.getItem('estimatedAnnualPremium')) + parseFloat(this.state.paymentToEdit.amount));
          }
            this.setState({ estimatedAnnualPremium: amt});
        }
        }
        isPaymentToday = ()=> {
          if(isEditPayment()) {
            return moment(this.state.paymentToEdit.withdrawalDate).format("YYYY-MM-DD") === moment(this.state.paymentToEdit.audit.createdOn).format("YYYY-MM-DD")
          }
          return false;
        }

        loadStateEditPayment = () => {
          
            console.log('Setting edit payment options')
            this.setState({ 
              accountNumber: this.state.paymentToEdit?.bankAccount?.accountNumber,
              routingNumber:  this.state.paymentToEdit?.bankAccount?.routingNumber,
              bankHolderFirstName:  this.state.paymentToEdit?.bankAccount?.firstName,
              bankHolderMiddleName:  this.state.paymentToEdit?.bankAccount?.middleName,
              bankHolderLastName:  this.state.paymentToEdit?.bankAccount?.lastName,
              cardNumberLastFour:  this.state.payymentToEdit?.creditCard?.cardNumberLastFour,
              cardType: this.state.paymentToEdit?.creditCard?.cardType,
              creditCardId: this.state.paymentToEdit?.creditCard?.creditCardId,
              creditCardToken: this.state.paymentToEdit?.creditCard?.creditCardToken,
              otherAmountDisplay: ( !isAndroid() ? (this.state.paymentToEdit?.amount*100) : this.state.paymentToEdit?.amount.toFixed(2)),
              bankAccountType: this.state.paymentToEdit?.bankAccount?.bankAccountType,
              bankAccountId: this.state.paymentToEdit?.bankAccount?.bankAccountId,
              paymentMethod: this.state.paymentToEdit?.bankAccount === null ? 'CC' : 'EFT',
              paymentDate: moment(this.state.paymentToEdit?.withdrawalDate).format('MM/DD/YYYY'),
              scheduleChoice: (isEditPayment() && this.isPaymentToday()) ? 'payNow' : 'schedule',
              payAmount: isNaN(this.state.paymentToEdit?.amount) ? this.state.defaultPaidAmoutPlaceHolder : '$' + this.state.paymentToEdit?.amount.toFixed(2),
              otherAmountChecked : true,
              confirmationNumber: this.state.paymentToEdit?.confirmationNumber
            })
            if (secureLocalStorage.getItem('estimatedAnnualPremium') != null) {
              let amt = parseFloat(secureLocalStorage.getItem('estimatedAnnualPremium'))
              if(isEditPayment()) {
                amt =  (parseFloat(secureLocalStorage.getItem('estimatedAnnualPremium')) + parseFloat(this.state.paymentToEdit.amount));
              }
                this.setState({ estimatedAnnualPremium: amt});
            }
        }

      setCurrentAndPastCharges = (readBillingAndTransactionDetailResult) => {
        let productType = getProductType();
        var currentChargesFound = false;
        // eslint-disable-next-line no-unused-vars
        var currentPaymentDate = '';
        let currentCharges = 0;
        let pastAmountDueAndCurrentCharges = 0;
        let pastAmountDue = 0;
        let currentFormateDate = moment(Number.parseInt(secureLocalStorage.getItem('currSysTimeInMs'), 10)).format('MM/DD/YYYY');
        let pastAmountDueFound = false;
        let onlyCurrentChargesFound = false;
        let pastAmountAndCurrentChargesFound = false;
        if (productType === 'GOVT' && readBillingAndTransactionDetailResult.paymentHistory) {
          readBillingAndTransactionDetailResult.paymentHistory.forEach((paymentHistory) => {  
            var filterDate = moment(paymentHistory.dueDate);
            console.log(filterDate, ' ', currentFormateDate);
            console.log(filterDate.isAfter(currentFormateDate));
            if (moment(filterDate) > moment(currentFormateDate)) {
              currentCharges = paymentHistory.balanceDue;
              if (currentCharges > 0) {
                currentChargesFound = true;
                currentPaymentDate = filterDate;
                this.setState({currentPaymentDate : filterDate.format('MM/DD/YYYY')});
                secureLocalStorage.setItem("currentPaymentDate", filterDate.format('MM/DD/YYYY'));
              }
            }
          });
        }
        pastAmountDue = readBillingAndTransactionDetailResult.totalAmountDue ? (parseFloat(readBillingAndTransactionDetailResult.totalAmountDue) > parseFloat(this.state.totalPendingAmount) ?
        parseFloat(readBillingAndTransactionDetailResult.totalAmountDue - this.state.totalPendingAmount): 0 ): 0
      if (pastAmountDue > 0) {
        pastAmountDueFound = true;
      }
      if (pastAmountDueFound && currentChargesFound) {
        console.log('Both past and current charges found');
        pastAmountDueAndCurrentCharges = pastAmountDue + currentCharges;
        pastAmountAndCurrentChargesFound = true;
      } else if (!pastAmountDueFound && currentChargesFound) {
        onlyCurrentChargesFound = true;
        pastAmountDueFound = false;
      }
      console.log(pastAmountAndCurrentChargesFound);
      secureLocalStorage.setItem('pastAmountDue', pastAmountDue);
      secureLocalStorage.setItem('pastAmountDueFound', pastAmountDueFound);
      secureLocalStorage.setItem('currentChargesFound', currentChargesFound);
      secureLocalStorage.setItem('pastAmountAndCurentCharges', pastAmountDueAndCurrentCharges);
      secureLocalStorage.setItem('pastAmountAndCurentChargesFound', pastAmountAndCurrentChargesFound);
      secureLocalStorage.setItem('onlyCurrentChargesFound', onlyCurrentChargesFound);
      secureLocalStorage.setItem('currentCharges', currentCharges);
      this.setState({
        currentCharges: currentCharges, 
        pastAmountDue: pastAmountDue, 
        pastAmountDueFound: pastAmountDueFound, 
        currentChargesFound: currentChargesFound, 
        pastAmountAndCurentCharges: pastAmountDueAndCurrentCharges, 
        pastAmountAndCurentChargesFound: pastAmountAndCurrentChargesFound, 
        onlyCurrentChargesFound: onlyCurrentChargesFound });
    }

      callPaymentHistory() {
        if (!this.state.paymentHistoryCallCompleted) {
          getSearchPaymentHistory('', '', getPlanInformation(),this.state.totalPendingAmount).then((payresponse) => {
            if (payresponse.status === 200) {
              this.setCurrentAndPastCharges(payresponse.data.readBillingAndTransactionDetailResult);
              this.hydrateStateWithLocalStorage();
              if (getRecurringPaymentFlag()) {
                let nextMonth = this.cmsCancelledInCommittment() ? moment(this.state.ssaDates?.ssaStopDate).add(1, 'M').startOf('month') : moment().add(1, 'M').startOf('month')
                console.log(nextMonth);
                this.setState({ paymentDate: nextMonth.format('MM-DD-YYYY')});
                secureLocalStorage.setItem('paymentDate', nextMonth.format('MM-DD-YYYY'));
                secureLocalStorage.setItem('ssaDates', JSON.stringify(this.state.ssaDates));
              }
              this.setState({ wrapperPojo: getSearchPaymentMethodResult() ? 
                getSearchPaymentMethodResult().wrapperResponse 
                : 
                null,
              monthlyPaymentPremium: payresponse.data.readBillingAndTransactionDetailResult.premium.basePremium,});
              setTimeout(()=> {
                this.setState({ paymentHistoryCallCompleted: true, loading: false });
              },1000)
            } else {
              this.setState({ isServiceError: true });
            }
          })
        }
      }

      handleRecScheduleChoice(e) {
        let schFutureRadio = document.getElementById('sch_Future_Radio')
        let scheduleText=getRecurringPaymentFlag() && !isManageRecurring() ? selectPaySumAem?.auth_sch_rec_pay_sub_heading : isManageRecurring() ? selectPaySumAem?.auth_updt_sch_sub_heading:null
        if (e.target.value === 'scheduleFutureMonth') {
          let firstFutureMonth = this.cmsCancelledInCommittment() ? moment(this.state.ssaDates?.ssaStopDate).add(2, 'M').startOf('month') : moment().add(2, 'M').startOf('month');
          this.setState({paymentDate : firstFutureMonth.format('MM-DD-YYYY')})
          secureLocalStorage.setItem('paymentDate', firstFutureMonth.format('MM-DD-YYYY'))
          if(schFutureRadio){
            schFutureRadio.setAttribute('aria-describedby','redSch_note_id')
          }
          trackLinkClick(scheduleText+":schedule for a future month")
          console.log(scheduleText+":schedule for a future month")
        } else if(e.target.value === 'startNextMonth'){
          let nextMonth = this.cmsCancelledInCommittment() ? moment(this.state.ssaDates?.ssaStopDate).add(1, 'M').startOf('month') :  moment().add(1, 'M').startOf('month');
          this.setState({paymentDate : nextMonth.format('MM-DD-YYYY')})
          secureLocalStorage.setItem('paymentDate', nextMonth.format('MM-DD-YYYY'))
          if(schFutureRadio){
            schFutureRadio.removeAttribute('aria-describedby')
          }
          trackLinkClick(scheduleText+":start next month")
          console.log(scheduleText+":start next month")
        }
        this.setRecScheduleChoice(e.target.value);
        secureLocalStorage.setItem("recScheduleChoice", e.target.value);
        if(this.state.cancelRecChecked) {
          this.setState({cancelRecChecked : false})
        }
      }

      chooseFutureMonth(e) {
        this.setState({paymentDate : e.target.value})
        secureLocalStorage.setItem('paymentDate', e.target.value)
      }

      clickToAdd = (openIframe)=> {
        let btns = Array.from(document.getElementsByTagName('button'))
          let btn = btns && btns.length > 0 ? btns.filter(el=> el.id.match(/1001/g)) : null
          console.log(btn)
          if(btn && btn.length > 0) {
            console.log(btn[0])
            btn[0].click()
            if(openIframe) {
              setTimeout(()=> {
                this.setState({ paymentMethod:  "CCIframe", ccClicked : true })
              },1000)
            }
            } else {
              setTimeout(()=>this.clickToAdd(openIframe),1000)
            }
      }

    cmsCancelledInCommittment() {
      return moment().isBefore(this.state.ssaDates?.ssaStopDate);
    }

    componentDidMount() {
        
      secureLocalStorage.setItem('paymentFormData',null);
        secureLocalStorage.setItem('currPage', 'fedForm');
        window.scrollTo(0, 0)
        if (!(this.props && this.props.location && this.props.location.state) || (this.props.location.state.fromPage && (this.props.location.state.fromPage === 'overview' || this.props.location.state.fromPage === 'manageWallet'))) {
          if(isEditPayment()){
            this.loadStateEditPayment();
            this.callEftFormAemAPISummary();
            this.callSelectPaySumAemAPISummary();
            this.setState({ paymentHistoryCallCompleted: true, loading: false }, ()=> {
            });
          } else{
            if(isEditPayment()) {
              this.setState({paymentToEdit : this.props.location.state.paymentToEdit}, ()=> {
                this.loadStateEditPayment();
              })
            }
            if(getOneTimePaymentFlag()) {
              secureLocalStorage.setItem('paymentDate', moment().format('MM-DD-YYYY'))
            }
            if (getRecurringPaymentFlag()) {
              let nextMonth = moment().add(1, 'M').startOf('month')
              this.setState({ paymentDate: nextMonth.format('MM-DD-YYYY')})
              secureLocalStorage.setItem("recScheduleChoice", 'startNextMonth');
              secureLocalStorage.setItem('paymentDate', nextMonth.format('MM-DD-YYYY'))
            }
            try {
              this.getWalletDetails().then(()=> {
                this.callPaymentHistory();
              })
            } catch (error) {
              this.setState({ isServiceError: true})
            }
            this.callEftFormAemAPISummary();
            this.callSelectPaySumAemAPISummary();
          }
        } else {
          let openIframe = false
          this.hydrateStateWithLocalStorage()
          this.callEftFormAemAPISummary();
          this.callSelectPaySumAemAPISummary();
        
          this.setState({
            paymentMethod: this.props.location.state.paymentMethod,
            payAmount: this.props.location.state.paidAmount ? this.props.location.state.paidAmount : '0.00',
            pastAmountDueChecked: this.props.location.state.pastAmountDueChecked,
            pastAmountAndCurentChecked: this.props.location.state.pastAmountAndCurentChecked,
            onlyCurrentChargesChecked: this.props.location.state.onlyCurrentChargesChecked,
            payInFullChecked: this.props.location.state.payInFullChecked,
            otherAmountChecked: this.props.location.state.otherAmountChecked,
            bankHolderFirstName: this.props.location.state.bankHolderFirstName,
            bankHolderMiddleName: this.props.location.state.bankHolderMiddleName,
            bankHolderLastName: this.props.location.state.bankHolderLastName,
            routingNumber: this.props.location.state.routingNumber,
            accountNumber: this.props.location.state.accountNumber,
            cancelRecChecked: this.props.location.state.cancelRecChecked,
            activeWalletItems: (this.props.location.state.paymentBy &&  this.props.location.state.paymentBy.indexOf("addCC") > -1) ? JSON.parse(secureLocalStorage.getItem("activeWalletItemsCC")) : this.props.location.state.activeWalletItems,
            selectedPayIndex : this.props.location.state.selectedPayIndex,
            paymentBy: this.props.location.state.paymentBy,
            paymentDate : this.props.location.state.paymentDate,
            fullMOPList : this.props.location.state.selectedPayIndex && parseInt(this.props.location.state.selectedPayIndex) > 1 ? this.props.location.state.fullMOPList : false,
            eftBankType : this.props.location.state.eftBankType,
            bankAccountType : this.props.location.state.bankAccountType,
            isAddPayMethodOpen : this.props.location.state.isAddPayMethodOpen,
            nickName : this.props.location.state.nickName,
            scheduleChoice : this.props.location.state.scheduleChoice,
            recScheduleChoice : this.props.location.state.recScheduleChoice,
            cardType: this.props.location.state.cardType,
            cardNumberLastFour: this.props.location.state.cardNumber,
            creditCardToken: this.props.location.state.token,
            billingCode : this.props.location.state.billingCode,
            nameOnCard : this.props.location.state.nameOnCard,
            expiration : this.props.location.state.expirationDate,
            itemId : this.props.location.state.itemId,
            authorizationInd : this.props.location.state.authorizationInd,
            paymentToEdit: this.props.location.state.paymentToEdit,
            ssaDates: this.props.location?.state?.ssaDates,
            pastAmountDue: this.props.location.state.pastAmountDue || secureLocalStorage.getItem('pastAmountDue'),
            pastAmountAndCurentCharges: this.props.location.state.pastAmountAndCurentCharges || secureLocalStorage.getItem('pastAmountAndCurentCharges'),
            currentCharges: this.props.location.state.currentCharges || secureLocalStorage.getItem('currentCharges'),
            pastAmountDueFound: this.props.location.state.pastAmountDueFound || JSON.parse(secureLocalStorage.getItem('pastAmountDueFound')),
            pastAmountAndCurentChargesFound: this.props.location.state.pastAmountAndCurentChargesFound || JSON.parse(secureLocalStorage.getItem('pastAmountAndCurentChargesFound')),
            onlyCurrentChargesFound: this.props.location.state.onlyCurrentChargesFound || JSON.parse(secureLocalStorage.getItem('onlyCurrentChargesFound')),
          }) 

          if (this.props.location.state.otherAmountChecked && this.props.location.state.payAmount) {
            let amount = !isAndroid() ? this.props.location.state.payAmount.replace('$', '') * 100 : this.props.location.state.payAmount.replace('$', '')
            this.setState({ otherAmountDisplay: amount })
          }
          if (this.props.location.state.paymentBy === 'addCC') {
            openIframe = true
          }
        
        if (this.props.location.state.pageLoadViaFlow) {
          this.setState({pageLoadViaFlow: true})
        }
        
        this.setState({ loading: false }, ()=> {
          
          if(this.props.location.state.paymentBy === 'addEFT' || this.props.location.state.paymentBy === 'addCC') {
            secureLocalStorage.setItem('isPageFromReview', true)
            setTimeout(()=>this.clickToAdd(openIframe),2500)
          }
        });
      }
        //start
        trackPageLoad(isEditPayment() ? 'update onetime payment': getOneTimePaymentFlag() ? 'make a one time payment' :  isManageRecurring() ? 'update payment method':'set up recurring payment')
        console.log(isEditPayment() ? 'update onetime payment' : getOneTimePaymentFlag() ? 'make a one time payment' :  isManageRecurring() ? 'update payment method':'set up recurring payment')
  }

  isValidDatePicker = ()=> {
    let value = document.getElementById('datePickerInputId').value
    var pattern = /(0\d{1}|1[0-2])\/([0-2]\d{1}|3[0-1])\/(20)(\d{2})/
    var m = value.match(pattern);
    if (!m) {
      return false
    }
    return true
  }
  isDatePickerError = ()=> {
    if(getRecurringPaymentFlag()) {
      return false
    } else if(document.getElementById('datePickerErrMsgId') && document.getElementById('datePickerErrMsgId').innerText?.indexOf('range') > -1) {
      return true
    } else if(!this.state.paymentDate) {
      return true
    } else if(document.getElementById('datePickerInputId') && !this.isValidDatePicker()) {
      return true
    }
    return false
  }

  ccRadioPageErrorCount = ()=> {
    let errCount = [this.state.otherAmountError !== '', this.isDatePickerError()].filter(el=> el === true).length
    return errCount
  }

  pageErrorCount = ()=> {
    let errCount = [this.state.otherAmountError !== '', this.isDatePickerError(), !this.state.paymentMethod].filter(el=> el ===true).length
    return errCount
  }

  showErrorsOnCCRadioCheck = (isOnSubmitBtn)=> {
    let count = this.ccRadioPageErrorCount()
    this.setState({
      errorFields : {fields : [
                      {name : 'otherAmt' , error : this.state.otherAmountError !== '', label : selectPaySumAem.auth_other_amt_radio},
                      {name : 'datePicker' , error : this.isDatePickerError(), label : eftFormAem.auth_mw_payment_dt_err_lbl}
                    ],
                    count : count,
                    type : 'field',
                    src : 'paymentform'
                  }
    })
    if(isOnSubmitBtn && count > 0) {
      window.scrollTo(0, 0)
      if(this.isDatePickerError()) {
        this.setDatePickerErrorMsg()
      }
      this.setState({
        alert: true,
        loading: false
      }, () => document.getElementById('alertbody_desktop').focus())
    }
  }

  showErrors = (isOnSubmitBtn)=> {
    let count = this.pageErrorCount()
    this.setState({
      errorFields : {fields : [
                      {name : 'otherAmt' , error : this.state.otherAmountError !== '', label : isEditPayment() ? selectPaySumAem.auth_update_amount_label: selectPaySumAem.auth_other_amt_radio},
                      {name : 'datePicker' , error : this.isDatePickerError(), label : eftFormAem.auth_mw_payment_dt_err_lbl},
                      {name : 'paymethod' , error : !this.state.paymentMethod, label : eftFormAem.auth_mw_choose_pay_mtd_err_lbl}
                    ],
                    count : count,
                    type : 'field',
                    src : 'paymentform'
                  }
    })
    if(isOnSubmitBtn && count > 0) {
      window.scrollTo(0, 0)
      this.setState({
        alert: true,
        loading: false
      }, () => document.getElementById('alertbody_desktop').focus())
    }
  }

      setPaymentDate(date) {
        if(this.state.paymentMethod === 'CCIframe') {
          this.setState({paymentMethod : ''})
        }
        console.log('In setPaymentDate Method: ', date)
        this.setState({ datePickerCssState : 'default', paymentDate: date}, 
        ()=> {
          secureLocalStorage.setItem('paymentDate', this.state.paymentDate)
            this.setState({datePickerError : false}, ()=>{
              let errFields = this.state.errorFields
              if(this.state.errorFields && this.state.errorFields.count && this.state.errorFields.count > 0) {
                let fields = errFields.fields
                let count = errFields.count
                if(fields.filter(el=> el.name === 'datePicker' && el.error).length > 0) {
                  fields = fields.filter(el=> el.name !== 'datePicker')
                  errFields.fields = fields
                  errFields.count = count-1 
                  console.log(errFields)
                  this.setState({errorFields : errFields})
                }
              }
            })
          
        })
      }

      /* setCalendarOpenStatus(state) {
        this.setState({ calendarOpen: state })
      } */
      removeOtherAmntError = ()=> {
        if(this.state.errorFields && this.state.errorFields.fields && this.state.errorFields.fields.length > 0) {
          let errFields = this.state.errorFields
          let fields = errFields.fields
          let count = errFields.count
          if(fields.filter(el=> el.name === 'otherAmt' && el.error).length > 0) {
            fields = fields.filter(el=> el.name !== 'otherAmt')
            errFields.fields = fields
            errFields.count = count-1 
            console.log(errFields)
            this.setState({errorFields : errFields})
          }
        }
      }

      handleAmountSelection = (event) => {
        console.log(event.target.value)
        let selectedAmount = ''
        let otherAmt = ''
        let payAmountdueCheck = false
        let pastAmountAndCurentChecked = false
        let onlyCurrentChargesChecked = false
        let payInFullChecked = false
        let otherAmountChecked = false
        // eslint-disable-next-line no-unused-vars
        let paymentType = "";
        secureLocalStorage.setItem('pastAmountDueChecked', false)
        secureLocalStorage.setItem('pastAmountAndCurentChecked', false)
        secureLocalStorage.setItem('onlyCurrentChargesChecked', false)
        secureLocalStorage.setItem('payInFullChecked', false)
        secureLocalStorage.setItem('otherAmountChecked', false)
        if (event.target.id === 'pastAmountDueFound') {
          payAmountdueCheck = true
          trackLinkClick('mr.premium-payments.radio-button.due-today');
          secureLocalStorage.setItem('pastAmountDueChecked', true)
          this.setState({ payAmount: document.getElementById('duetodayamt').innerText.replace(',', '') }, () => secureLocalStorage.setItem('payAmount', this.state.payAmount))
        } else if (event.target.id === 'pastAmountAndCurentChargesFound') {
          pastAmountAndCurentChecked = true
          trackLinkClick('mr.premium-payments.radio-button.due-today.current-charges');
          secureLocalStorage.setItem('pastAmountAndCurentChecked', true)
          this.setState({ payAmount: document.getElementById('duetodayincomingamt').innerText.replace(',', '') }, () => secureLocalStorage.setItem('payAmount', this.state.payAmount))
        } else if (event.target.id === 'onlyCurrentChargesFound') {
          onlyCurrentChargesChecked = true
          trackLinkClick('mr.premium-payments.radio-button.current-charges');
          secureLocalStorage.setItem('onlyCurrentChargesChecked', true)
          this.setState({ payAmount: document.getElementById('upcomingchargesamt').innerText.replace(',', '') }, () => secureLocalStorage.setItem('payAmount', this.state.payAmount))
        } else if (event.target.id === 'payInFull') {
          payInFullChecked = true
          trackLinkClick('mr.premium-payments.radio-button.pay-in-full');
          secureLocalStorage.setItem('payInFullChecked', true)
          this.setState({ payAmount: document.getElementById('payinfullamt').innerText.replace(',', '') }, () => secureLocalStorage.setItem('payAmount', this.state.payAmount))
        } else {
          otherAmountChecked = true
          trackLinkClick('mr.premium-payments.radio-button.other-amount');
          document.getElementById('otherAmountInput').focus()
          secureLocalStorage.setItem('otherAmountChecked', true)
          document.getElementById('otherAmountInput').removeAttribute('tabindex');
        }
        if (!otherAmountChecked) {
          document.getElementById('otherAmountInput').setAttribute('tabindex', '-1');
        }
        if (event.target.id === 'otherAmount') {
          this.setState({ payAmount: this.state.defaultPaidAmoutPlaceHolder })
        } else {
          paymentType = (this.state.isDefaultEftPaymentSelectionAvailable ? "EFT" : this.state.paymentMethod);
        }
    
        if (payAmountdueCheck || pastAmountAndCurentChecked || onlyCurrentChargesChecked || payInFullChecked) {
          this.setState({
            otherAmountErrorDisp: false,
            otherAmountError: '',
            otherAmountErrorClass: '',
            amtselectcss: 'default'
          }, this.removeOtherAmntError())
          
        }
    
        this.setState({
          otherAmount: otherAmt,
          otherAmountDisplay: '',
          checkedPayAmount: selectedAmount,
          pastAmountDueChecked: payAmountdueCheck,
          pastAmountAndCurentChecked: pastAmountAndCurentChecked,
          onlyCurrentChargesChecked: onlyCurrentChargesChecked,
          payInFullChecked: payInFullChecked,
          otherAmountChecked: otherAmountChecked,
        })
      }

      setDatePickerErrorMsg = ()=> {
        if(this.state.scheduleChoice === 'schedule' && document.getElementById('scheduleDate')) {
          let schDtInput = document.getElementById('scheduleDate').children[0].children[1].children[0].getElementsByTagName('input')[0]
          let schInpAriaDes = schDtInput.getAttribute('aria-describedby')

          schInpAriaDes = schInpAriaDes.split(' ').filter(el=> el.indexOf('instruction') > -1)[0] + ' datePickerErrMsgId' 
          schDtInput.setAttribute('aria-describedby',schInpAriaDes)
        }
        if(document.getElementById('datePickerErrMsgId') && document.getElementById('datePickerErrMsgId').innerText?.indexOf('range') > -1) {
          this.setState({datePickerPageErrorText : 'Payment date', datePickerError : true})
        } else if(!this.state.paymentDate) {
          this.setState({datePickerCssState : 'error', datePickerErrorText : 'The date is invalid', datePickerPageErrorText : 'Payment date', datePickerError : true})
        } else if(document.getElementById('datePickerInputId') && !this.isValidDatePicker()) {
          this.setState({datePickerCssState : 'error', datePickerErrorText : 'The date is invalid', datePickerPageErrorText : 'Payment date', datePickerError : true})
        }
        
      }

      handleEmptyOtherAmount = () => {
        let value = this.state.otherAmountDisplay;
        this.handleOtherAmountSelection(value, false);
      }

      validateOtherAmountValue = () => {
        let value = this.state.otherAmountDisplay;
        this.handleOtherAmountSelection(value, true);
      }
      //If value = 1 then displayed value would be 0.01
      handleOtherAmountSelection = (value, isOnBlurAction) => {
        const amount = (value !== undefined) ? !isAndroid() ? !this.state.changeDetailsView ? value / 100 : value : value : '';
        if (this.state.otherAmountChecked) {
          this.setState({ amtselectcss: 'default' })
        }
        let isError = false
        const regex = /^[0-9]*\.?[0-9]*$/
        if (amount === '' || regex.test(amount)) {
          if (isOnBlurAction && this.state.otherAmountChecked && (amount === 0 || amount === '')) {
            this.setState({
              otherAmountErrorDisp : true,
              otherAmountError: (
                <p className="invalid-feedback" id="enter-amt-error" style={{ display: 'block' }} aria-live="assertive">
                  <span className="sr-only">Error:</span> {'Enter amount'}
                </p>
              ),
              otherAmountErrorClass: 'is-invalid',
              payAmount: this.state.defaultPaidAmoutPlaceHolder,
              showFrameClass: 'hide',
              paymentMethod: this.state.paymentMethod === 'CCIframe' ? '' : this.state.paymentMethod,
            }, () => document.getElementById('otherAmountInput').setAttribute('aria-describedby', 'enter-amt-error'));
            return
          }
          if (amount > 0) {
            this.setState({
              payAmount: isNaN(amount) ? this.state.defaultPaidAmoutPlaceHolder : '$' + !isAndroid() ? convertToCurrency(amount) : amount.toFixed(2),
            })
            if ((isNaN(amount) || amount < 1) && isOnBlurAction) {
              isError = true;
              trackError((getOneTimePaymentFlag() ? 'make a one time payment' :  isManageRecurring() ? 'update payment method':'set up recurring payment'), 'Other Amount', 'field validation error', 'invalid');
              this.setState({
                otherAmountError: (
                  <p className="invalid-feedback" id="amt-exceed-error" style={{ display: 'block' }} aria-live="assertive">
                    <span className="sr-only">Error:</span> {eftFormAem.auth_wallet_amt_must_exceed_error}
                  </p>
                ),
                otherAmountErrorClass: 'is-invalid',
                payAmount: this.state.defaultPaidAmoutPlaceHolder,
                showFrameClass: 'hide',
                paymentMethod: this.state.paymentMethod === 'CCIframe' ? '' : this.state.paymentMethod,
                }, () => document.getElementById('otherAmountInput').setAttribute('aria-describedby', 'amt-exceed-error'))
              
    
            } else if (this.state.estimatedAnnualPremium < amount && isOnBlurAction) {
              
                isError = true;
                this.setState({
                  otherAmountError: (
                    <p className="invalid-feedback" id="eaip-error" style={{ display: 'block' }} aria-live="assertive">
                      <span className="sr-only">Error:</span> {eftFormAem.auth_wallet_eaip_error} ${convertToCurrency(this.state.estimatedAnnualPremium)}
                    </p>
                  ),
                  otherAmountErrorClass: 'is-invalid',
                  payAmount: this.state.defaultPaidAmoutPlaceHolder,
                  showFrameClass: 'hide',
                  paymentMethod: this.state.paymentMethod === 'CCIframe' ? '' : this.state.paymentMethod,
                }, () => document.getElementById('otherAmountInput').setAttribute('aria-describedby', 'eaip-error'))
                
              
            } else {
              if (isOnBlurAction) {
                this.removeOtherAmntError()
              }
              this.setState({
                otherAmountError: '',
                otherAmountErrorClass: '',
              }, () => document.getElementById('otherAmountInput').removeAttribute('aria-describedby'))
            }
          } else {
            this.setState({
              otherAmountError: '',
              otherAmountErrorClass: '',
              payAmount: this.state.defaultPaidAmoutPlaceHolder,
            })
          }
          this.setState({
            otherAmountDisplay: value,
            otherAmount: amount,
            otherAmountChecked: true,
            pastAmountDueChecked: false,
            pastAmountAndCurentChecked: false,
            onlyCurrentChargesChecked: false,
            payInFullChecked: false,
            errorMessage: isError ? 'Other Amount' : '',
          }, () => {
            if (amount === '' || regex.test(amount)) {
              if (isOnBlurAction && this.state.otherAmountChecked && amount === 0) {
                this.setState({
                  otherAmountError: (
                    <p className="invalid-feedback" id="enter-amt-error" style={{ display: 'block' }} aria-live="assertive">
                     <span className="sr-only">Error:</span> {eftFormAem.auth_wallet_enter_amt_error}
                    </p>
                  ),
                  otherAmountErrorClass: 'is-invalid',
                  payAmount: this.state.defaultPaidAmoutPlaceHolder,
                  showFrameClass: 'hide',
                  paymentMethod: this.state.paymentMethod === 'CCIframe' ? '' : this.state.paymentMethod,
                }, () => document.getElementById('otherAmountInput').setAttribute('aria-describedby', 'enter-amt-error'));
                return
              }
            }
            if (amount !== '' && regex.test(amount) && isOnBlurAction) {
              secureLocalStorage.setItem('payAmount', '$' + isAndroid() ? convertToCurrency(amount) : amount.toFixed(2))
              secureLocalStorage.setItem('pastAmountDueChecked', false)
              secureLocalStorage.setItem('pastAmountAndCurentChecked', false)
              secureLocalStorage.setItem('onlyCurrentChargesChecked', false)
              secureLocalStorage.setItem('payInFullChecked', false)
              secureLocalStorage.setItem('otherAmountChecked', true)
            }
          })
        }
      }

      setScheduleChoice(choice) { 
        this.setState({ scheduleChoice: choice})
      }

      setRecScheduleChoice(choice) { 
        this.setState({ recScheduleChoice: choice})
      }

      eftSubmitBtnClickErrorSet = (errorFields)=> {
        if(errorFields && errorFields.type && errorFields.type.indexOf('nacha')> -1) {
          this.setState({errorFields : errorFields})
          return
        }

        if(this.state.isAddPayMethodOpen && errorFields) {
          let otherErrCount = [this.state.otherAmountError !== '', this.isDatePickerError()].filter(el=> el === true).length
          let otherErrfields = [
            {name : 'otherAmt' , error : this.state.otherAmountError !== '', label : selectPaySumAem.auth_other_amt_radio},
            {name : 'datePicker' , error : this.isDatePickerError(), label : eftFormAem.auth_mw_payment_dt_err_lbl}
          ]
          if(this.isDatePickerError()) {
            this.setDatePickerErrorMsg()
          }
          errorFields.count = errorFields.count + otherErrCount
          errorFields.fields = [...otherErrfields, ...errorFields.fields]
          this.setState({errorFields : errorFields})
        } else if(this.state.isAddPayMethodOpen) {
          this.showErrors(true)
        }
        
      }

      navigateTo = ()=> {
        if(this.state.isAddPayMethodOpen) {
         
          this.eftRef.current.onSavePaymentClick()

        } else if ((this.state.paymentMethod !== 'CCIframe' && this.state.cancelRecChecked !== true && this.state.superUser)) {
            this.setState({ superUserError: true, formalidated: false})
        } else {
          let isOtherAmntErr = this.state.otherAmountError !== ''
          let datePickerErr = this.isDatePickerError()
          let choosePayMethodErr = !this.state.paymentMethod
          choosePayMethodErr = this.state.cancelRecChecked ? false : choosePayMethodErr
          let errCount = [isOtherAmntErr, datePickerErr, choosePayMethodErr].filter(el=> el === true).length
          if(errCount > 0) {
            this.setDatePickerErrorMsg()
            this.showErrors(true)
          } else {
            this.setState({formValidated : true})
           }
        }
      }

      //Navigate to review and submit page
      navigateToReviewAndSubmitPage = () => {
        // navigate to review and submit page
        this.setState({ formValidated: true });
        window.scrollTo(0, 0)
        trackLinkClick('“Review and Submit');
        let payOrScheduletxt = this.state.scheduleChoice === 'payNow' ? ' pay now | ' : ' schedule | ';
        let checkOrsavings =  this.state.bankAccountType === 'CHECKING' ? ' checking' : ' savings';
        let analyticsPage = getOneTimePaymentFlag() ? 'make a one time payment' :  isManageRecurring() ? 'update payment method':'set up recurring payment'
        trackFormSubmit(analyticsPage, analyticsPage, this.state.pastAmountDueChecked && this.state.paymentMethod === 'EFT' ? 'Due Today |' +payOrScheduletxt+ 'EFT' +checkOrsavings : this.state.pastAmountAndCurentChecked && this.state.paymentMethod === 'EFT' ?
        'Due today + Upcoming charges |' +payOrScheduletxt+ 'EFT' +checkOrsavings : this.state.otherAmountChecked && this.state.paymentMethod === 'EFT' ? 'Other Amount |' +payOrScheduletxt+ 'EFT' +checkOrsavings : this.state.onlyCurrentChargesChecked && this.state.paymentMethod === 'EFT' ? 'Upcoming charges |' +payOrScheduletxt+ ' EFT' +checkOrsavings : this.state.payInFullChecked && this.state.paymentMethod === 'EFT' ? 'Pay In Full |' +payOrScheduletxt+ 'EFT' +checkOrsavings : this.state.cancelRecChecked && this.state.paymentMethod === 'EFT' ? 'Cancel payment' :
         this.state.paymentMethod === 'CC' && this.state.pastAmountDueChecked ? 'Due Today |' +payOrScheduletxt+ 'credit card' : this.state.paymentMethod === 'CC' && this.state.pastAmountAndCurentChecked ? 'Due today + Upcoming charges |' +payOrScheduletxt+ 'credit card' : this.state.paymentMethod === 'CC' && this.state.otherAmountChecked ? 'Other Amount |' +payOrScheduletxt+ 'credit card' :
         this.state.paymentMethod === 'CC' && this.state.onlyCurrentChargesChecked ? 'Upcoming charges |' +payOrScheduletxt+ 'credit card' : this.state.paymentMethod === 'CC'&& this.state.payInFullChecked ? 'Pay In Full |' +payOrScheduletxt+ 'credit card' : this.state.cancelRecChecked ? 'cancel recurring payments | checked':'')     
     
              console.log(analyticsPage, analyticsPage, this.state.pastAmountDueChecked && this.state.paymentMethod === 'EFT' ? 'Due Today |' +payOrScheduletxt+ 'EFT' +checkOrsavings : this.state.pastAmountAndCurentChecked && this.state.paymentMethod === 'EFT' ?
              'Due today + Upcoming charges |' +payOrScheduletxt+ 'EFT' +checkOrsavings : this.state.otherAmountChecked && this.state.paymentMethod === 'EFT' ? 'Other Amount |' +payOrScheduletxt+ 'EFT' +checkOrsavings : this.state.onlyCurrentChargesChecked && this.state.paymentMethod === 'EFT' ? 'Upcoming charges |' +payOrScheduletxt+ ' EFT' +checkOrsavings : this.state.payInFullChecked && this.state.paymentMethod === 'EFT' ? 'Pay In Full |' +payOrScheduletxt+ 'EFT' +checkOrsavings : this.state.cancelRecChecked && this.state.paymentMethod === 'EFT' ? 'Cancel payment' :
               this.state.paymentMethod === 'CC' && this.state.pastAmountDueChecked ? 'Due Today |' +payOrScheduletxt+ 'credit card' : this.state.paymentMethod === 'CC' && this.state.pastAmountAndCurentChecked ? 'Due today + Upcoming charges |' +payOrScheduletxt+ 'credit card' : this.state.paymentMethod === 'CC' && this.state.otherAmountChecked ? 'Other Amount |' +payOrScheduletxt+ 'credit card' :
               this.state.paymentMethod === 'CC' && this.state.onlyCurrentChargesChecked ? 'Upcoming charges |' +payOrScheduletxt+ 'credit card' : this.state.paymentMethod === 'CC'&& this.state.payInFullChecked ? 'Pay In Full |' +payOrScheduletxt+ 'credit card' : this.state.cancelRecChecked ? 'cancel recurring payments | checked':'')     
           }

      resetEFTWalletPayMethod = ()=> {
        this.setState({
          bankHolderFirstName: '',
          bankHolderMiddleName: '',
          bankHolderLastName: '',
          routingNumber: '',
          accountNumber: '',
          bankAccountId : '',
          authorizationInd : '',
          bankAccountType : ''
        })
      }

      resetCCWalletPaymMethod = ()=> {
        this.setState({
          billingCode: '',
          cardNumberLastFour: '',
          cardType: '',
          creditCardToken: '',
          expiration: '',
          nameOnCard: '',
          creditCardId : ''
        })
      }
      analyticsTrackEvent =(isChecked) => {
        trackLinkClick(isChecked ? "checked:Cancel recurring payments":"unchecked:Cancel recuring payments")
        console.log(isChecked ? "checked:Cancel recurring payments":"unchecked:Cancel recuring payments")
      }
      resetWalletPayMethod = ()=> {
        this.resetEFTWalletPayMethod()
        this.resetCCWalletPaymMethod()
        this.setState({
          itemId : '',
          paymentType : '',
          paymentMethod : '',
          selectedPayIndex : '',
          paymentBy : '',
          superUserError: false,
        })
      }

      scrollToAddPaymentSection = ()=> {
        let btns = Array.from(document.getElementsByTagName('button'))
          let btn = btns && btns.length > 0 ? btns.filter(el=> el.id.match(/1001/g)) : null
          setTimeout(()=> {
            if(btn) {
              btn[0].scrollIntoView({behavior: 'smooth',block: "start", inline: "nearest"})
            }
          }, 200)
      }

      //Payment Method Handling eft checking saving Credit
      handlePaymentMethodSelection = (event)=> {
        let value = event.target.value
        this.setState({ superUserError: null})
        if(value) {
            let eftType = ''
            if(value !== 'CCIframe') {
              eftType = value
              this.setState({paymentMethod : 'EFT'},()=> {
                this.scrollToAddPaymentSection()
              })
            }
            this.setState({eftBankType : eftType})
            this.eftRef.current.resetOnlyField()
            this.setState({errorFields : null})
            
            if(value === 'CCIframe') {
              if(getOneTimePaymentFlag() && this.ccRadioPageErrorCount() > 0) {
                this.showErrorsOnCCRadioCheck(true)
                this.setState({paymentMethod : "",})
              } else {
                this.setState({ccClicked : true, paymentMethod : value},()=> {
                  secureLocalStorage.setItem('scheduleChoice', this.state.scheduleChoice);
                  this.scrollToAddPaymentSection()
                })
              }
              if (this.state.superUser) {
                this.showSuperUserError();
              }
              /* if(document.getElementById('add_eft_details')){
                document.getElementById('add_eft_details').removeAttribute('aria-labelledby');
              } */
              
            }
        }
      }

      removeChoosePaymentMethodErrorWallet = ()=> {
        if(this.state.errorFields && this.state.errorFields.fields && this.state.errorFields.fields.length > 0) {
          let errFields = this.state.errorFields
          let fields = errFields.fields
          let count = errFields.count
          if(fields.filter(el=> el.name === 'paymethod' && el.error).length > 0) {
            fields = fields.filter(el=> el.name !== 'paymethod')
            errFields.fields = fields
            errFields.count = count-1 
            this.setState({errorFields : errFields})
          }
        }
      }

      //Payment Method Handling from wallet item
      handlePaymentMethodWalletSelection = (event)=> {
        let selectPayMethod = event.target.getAttribute('data-paymentMethod')
        selectPayMethod = selectPayMethod ? JSON.parse(selectPayMethod) : {}

        let selectPayMethodType = event.target.getAttribute('data-paymentMethod-type')
        selectPayMethodType = selectPayMethodType ? selectPayMethodType : ''

        let selectPayIndex = event.target.getAttribute('data-index')
        selectPayIndex = selectPayIndex ? selectPayIndex : ''

        if(selectPayMethod && selectPayMethodType) {
          if(this.state.cancelRecChecked) {
            this.setState({cancelRecChecked : false, recScheduleChoice:'startNextMonth'})
          }
          if(this.state.isAddPayMethodOpen) {
            this.eftRef.current.resetOnlyField()
            this.setState({errorFields : null})
            let btns = Array.from(document.getElementsByTagName('button'))
            let btn = btns && btns.length > 0 ? btns.filter(el=> el.id.match(/1001/g)) : null
            if(btn) {
              btn[0].click()
            }
          }
          this.setState({selectedPayIndex : selectPayIndex}) 
            if(selectPayMethodType === 'CC') {
              this.resetEFTWalletPayMethod()
              this.setState({
                billingCode: selectPayMethod.creditCard.billingCode,
                cardNumberLastFour: selectPayMethod.creditCard.cardNumberLastFour,
                cardType: selectPayMethod.creditCard.cardType,
                creditCardToken: selectPayMethod.creditCard.creditCardToken,
                expiration: selectPayMethod.creditCard.expiration,
                nameOnCard: selectPayMethod.creditCard.nameOnCard,
                creditCardId : selectPayMethod.creditCard.creditCardId,
                itemId : selectPayMethod.itemId,
                paymentBy : 'wallet',
                paymentMethod : 'CC',
                superUserError: false,
              },()=> this.removeChoosePaymentMethodErrorWallet())
              
            } else {
              this.resetCCWalletPaymMethod()
              this.setState({
                bankHolderFirstName: selectPayMethod.bankAccount.firstName,
                bankHolderMiddleName: selectPayMethod.bankAccount.middleName,
                bankHolderLastName: selectPayMethod.bankAccount.lastName,
                routingNumber: selectPayMethod.bankAccount.routingNumber,
                accountNumber: selectPayMethod.bankAccount.accountNumber,
                bankAccountId : selectPayMethod.bankAccount.bankAccountId,
                authorizationInd : selectPayMethod.bankAccount.authorizationInd,
                bankAccountType : selectPayMethod.bankAccount.bankAccountType,
                itemId : selectPayMethod.itemId,
                paymentBy : 'wallet',
                paymentMethod : 'EFT',
                superUserError: false,
              },()=> this.removeChoosePaymentMethodErrorWallet())
            }
        }
      }
    
    showSuperUserError() {
      this.setState({ superUserError: true })
    }

    showMoreLessMethods() {
      if(this.state.fullMOPList && parseInt(this.state.selectedPayIndex) > 1) {
        this.resetWalletPayMethod()
      }
      if(this.state.isAddPayMethodOpen) {
        this.setState({isAddPayMethodOpen : false})
        this.eftRef.current.resetOnlyField()
        this.setState({errorFields : null})
        let btns = Array.from(document.getElementsByTagName('button'))
            let btn = btns && btns.length > 0 ? btns.filter(el=> el.id.match(/1001/g)) : null
            if(btn) {
              btn[0].click()
              this.scrollToElement(document.getElementById('showMoreLess'), 'start')
            }    
      }
      if(this.state.fullMOPList){
        this.scrollToElement(document.getElementById('choose_payID'), 'start')
        trackLinkClick('make a one time payment:accordian collapse:show less payment method');
        console.log('make a one time payment:accordian collapse:show less payment method');  
      }
      this.setState({fullMOPList: !this.state.fullMOPList},() => {
        if(this.state.fullMOPList){
          document.getElementById('payradio_2').focus()
          document.getElementById('showMoreLess').setAttribute('aria-expanded','true')
          trackLinkClick('make a one time payment:accordian expand:show more payment method');
          console.log('make a one time payment:accordian expand:show more payment method'); 
        } else {
          document.getElementById('showMoreLess').setAttribute('aria-expanded','false')
        }
      })
    }

    toggleAddPayment = ()=> {
      if(secureLocalStorage.getItem('isPageFromReview') && secureLocalStorage.getItem('isPageFromReview')) {
        this.eftRef.current.setEFTWhileRedirect({
          bankHolderFirstName : this.state.bankHolderFirstName,
          bankHolderMiddleName : this.state.bankHolderMiddleName,
          bankHolderLastName : this.state.bankHolderLastName,
          routingNumber : this.state.routingNumber,
          accountNumber : this.state.accountNumber,
          authorizationInd : this.state.authIndicator,
          nickName : this.state.nickName
        })
        secureLocalStorage.removeItem('isPageFromReview')
      } else {
        console.log('toggle called')
      let isOpenAddPay = this.state.isAddPayMethodOpen
      this.setState({isAddPayMethodOpen : !isOpenAddPay, superUserError: null},()=> {
        if(this.state.isAddPayMethodOpen) {
          if(this.state.cancelRecChecked) {
            this.setState({cancelRecChecked : false, recScheduleChoice:'startNextMonth'})
          }
          if(this.state.fullMOPList) {
            this.setState({fullMOPList: false})
          }
          let btns = Array.from(document.getElementsByTagName('button'))
          let btn = btns && btns.length > 0 ? btns.filter(el=> el.id.match(/1001/g)) : null
          setTimeout(()=> {
            if(btn) {
              this.scrollToElement(btn[0],"start")
            }
          }, 200)
          this.setState({paymentMethod : 'EFT', eftBankType : 'checking', errorFields : null})
          trackLinkClick((getOneTimePaymentFlag() ? 'make a one time payment' :  isManageRecurring() ? 'update payment method':'set up recurring payment') +':accordian expand:add new payment method');
          console.log((getOneTimePaymentFlag() ? 'make a one time payment' :  isManageRecurring() ? 'update payment method':'set up recurring payment') +':accordian expand:add new payment method');
        } else {
          this.eftRef.current.resetOnlyField()
        this.setState({errorFields : null})
        trackLinkClick((getOneTimePaymentFlag() ? 'make a one time payment' :  isManageRecurring() ? 'update payment method':'set up recurring payment') +':accordian collapse:add new payment method');
          console.log((getOneTimePaymentFlag() ? 'make a one time payment' :  isManageRecurring() ? 'update payment method':'set up recurring payment') +':accordian collapse:add new payment method');
        }
      })
      this.resetWalletPayMethod()
      }
    }

    addEftCancelBtnClick = (isScroll)=> {
      let btns = Array.from(document.getElementsByTagName('button'))
      let btn = btns && btns.length > 0 ? btns.filter(el=> el.id.match(/1001/g)) : null
      if(btn) {
        btn[0].click()
        isScroll && this.scrollToElement(btn[0],"center")
        this.eftRef.current.resetState()
      }
      trackLinkClick('make a one time payment:cancel - add new payment method');
      console.log('make a one time payment: cancel - add new payment method'); 
    }
     
    triggerAnalyticsMakePayment =() =>{
      let analyticsPaymethod = this.state.isAddPayMethodOpen ? 'add new payment | ' : 'existing payment | '
      let payOrScheduletxt = this.state.scheduleChoice === 'payNow' ? ' pay now | ' +analyticsPaymethod : ' schedule | ' +analyticsPaymethod;
      let checkOrsavings =  this.state.bankAccountType === 'CHECKING' ? ' checking' : ' savings';
      let analyticsPage = isEditPayment() ? 'Update one time payment' : getOneTimePaymentFlag() ? 'make a one time payment' :  isManageRecurring() ? 'update recurring payments':'set up recurring payment'
      if(isEditPayment()){
        trackLinkClick('Update one time payment : Review and Submit');
        payOrScheduletxt=this.state.scheduleChoice === 'payNow' ? 'pay now': 'schedule'
        trackFormSubmit(analyticsPage,analyticsPage,payOrScheduletxt);
        console.log(analyticsPage,analyticsPage,payOrScheduletxt);
      }if(getRecurringPaymentFlag()){
        let forminName=this.state.cancelRecChecked ? 'cancel recurring payments':(this.state.recScheduleChoice === 'startNextMonth' ? 'start next month':'schedule for a future month') +' | '+analyticsPaymethod+(this.state.paymentMethod === 'EFT' ? 'EFT'+checkOrsavings :'Credit/debit')
        if(this.state.cancelRecChecked){
          trackFormSubmit(analyticsPage, forminName,"PII Information")
        }else{
          trackFormSubmit(analyticsPage, analyticsPage,forminName)
        console.log(analyticsPage, analyticsPage,forminName)
        }
      }else{
      trackFormSubmit(analyticsPage, analyticsPage, this.state.pastAmountDueChecked && this.state.paymentMethod === 'EFT' ? 'Due Today |' +payOrScheduletxt+ 'EFT' +checkOrsavings : this.state.pastAmountAndCurentChecked && this.state.paymentMethod === 'EFT' ?
      'Due today + Upcoming charges |' +payOrScheduletxt+ 'EFT' +checkOrsavings : this.state.otherAmountChecked && this.state.paymentMethod === 'EFT' ? 'Other Amount |' +payOrScheduletxt+ 'EFT' +checkOrsavings : this.state.onlyCurrentChargesChecked && this.state.paymentMethod === 'EFT' ? 'Upcoming charges |' +payOrScheduletxt+ ' EFT' +checkOrsavings : this.state.payInFullChecked && this.state.paymentMethod === 'EFT' ? 'Pay In Full |' +payOrScheduletxt+ 'EFT' +checkOrsavings : this.state.cancelRecChecked && this.state.paymentMethod === 'EFT' ? 'Cancel payment' :
       this.state.paymentMethod === 'CC' && this.state.pastAmountDueChecked ? 'Due Today |' +payOrScheduletxt+ 'credit card' : this.state.paymentMethod === 'CC' && this.state.pastAmountAndCurentChecked ? 'Due today + Upcoming charges |' +payOrScheduletxt+ 'credit card' : this.state.paymentMethod === 'CC' && this.state.otherAmountChecked ? 'Other Amount |' +payOrScheduletxt+ 'credit card' :
       this.state.paymentMethod === 'CC' && this.state.onlyCurrentChargesChecked ? 'Upcoming charges |' +payOrScheduletxt+ 'credit card' : this.state.paymentMethod === 'CC'&& this.state.payInFullChecked ? 'Pay In Full |' +payOrScheduletxt+ 'credit card' : this.state.cancelRecChecked ? 'cancel recurring payments | checked':'')     
   
            console.log(analyticsPage, analyticsPage, this.state.pastAmountDueChecked && this.state.paymentMethod === 'EFT' ? 'Due Today |' +payOrScheduletxt+ 'EFT' +checkOrsavings : this.state.pastAmountAndCurentChecked && this.state.paymentMethod === 'EFT' ?
            'Due today + Upcoming charges |' +payOrScheduletxt+ 'EFT' +checkOrsavings : this.state.otherAmountChecked && this.state.paymentMethod === 'EFT' ? 'Other Amount |' +payOrScheduletxt+ 'EFT' +checkOrsavings : this.state.onlyCurrentChargesChecked && this.state.paymentMethod === 'EFT' ? 'Upcoming charges |' +payOrScheduletxt+ ' EFT' +checkOrsavings : this.state.payInFullChecked && this.state.paymentMethod === 'EFT' ? 'Pay In Full |' +payOrScheduletxt+ 'EFT' +checkOrsavings : this.state.cancelRecChecked && this.state.paymentMethod === 'EFT' ? 'Cancel payment' :
             this.state.paymentMethod === 'CC' && this.state.pastAmountDueChecked ? 'Due Today |' +payOrScheduletxt+ 'credit card' : this.state.paymentMethod === 'CC' && this.state.pastAmountAndCurentChecked ? 'Due today + Upcoming charges |' +payOrScheduletxt+ 'credit card' : this.state.paymentMethod === 'CC' && this.state.otherAmountChecked ? 'Other Amount |' +payOrScheduletxt+ 'credit card' :
             this.state.paymentMethod === 'CC' && this.state.onlyCurrentChargesChecked ? 'Upcoming charges |' +payOrScheduletxt+ 'credit card' : this.state.paymentMethod === 'CC'&& this.state.payInFullChecked ? 'Pay In Full |' +payOrScheduletxt+ 'credit card' : this.state.cancelRecChecked ? 'cancel recurring payments | checked':'')     
         }
      }

    checkBankAccountInfo() {
      let validatedBankInfo = this.state.activeWalletItems.filter(account => {
        if ((account?.bankAccount?.accountNumber !== null && account?.bankAccount?.routingNumber !== null && account?.bankAccount?.accountNumber !== '' && account?.bankAccount?.routingNumber !== '') || account.creditCard !== null) {
          return account;
        }
      })
      return validatedBankInfo;
    }


    setBankAccBeforeReviewSubmit = (obj)=> {
      this.setState({
        bankHolderFirstName : obj.bankHolderFirstName,
        bankHolderMiddleName : obj.bankHolderMiddleName,
        bankHolderLastName : obj.bankHolderLastName,
        routingNumber : obj.routingNumber,
        accountNumber : obj.accountNumber,
        authorizationInd : obj.authorizationInd,
        nickName : obj.nickName,
        paymentBy : 'addEFT',
        formValidated : true
      })
    }

    generateFutureMonthList() {
      let monthList = [];
      let numOfMonths = this.cmsCancelledInCommittment() ? 3 : 4
      for (let i = 2; i <= numOfMonths; i++) {
        let date = this.cmsCancelledInCommittment() ? moment(this.state.ssaDates?.ssaStopDate).add(i, 'M').startOf('month') : moment().add(i, 'M').startOf('month')
        monthList.push(<Form.Select.Option selected = {moment(date,'MM-DD-YYYY').format('MM-DD-YYYY') === moment(this.state.paymentDate,'MM-DD-YYYY')?.format('MM-DD-YYYY')} value={`${date.format('MM-DD-YYYY')}`}>{`${date.format('MMMM YYYY')}`}</Form.Select.Option>)
      }
      return monthList;
    }

    closeAddPayment = ()=> {
      let isOpenAddPay = this.state.isAddPayMethodOpen
      this.setState({isAddPayMethodOpen : !isOpenAddPay})
      this.eftRef.current.resetState()
    }
    checkformValidation = () =>{
      if(this.state.formValidated && !this.state.superUserError ){
        this.triggerAnalyticsMakePayment()
        let securePaymentFormData = JSON.parse(secureLocalStorage.getItem("paymentFormData"));
        let paymentFormData={
            "bankHolderFirstName": this.state.bankHolderFirstName,
            "bankHolderMiddleName": this.state.bankHolderMiddleName,
            "bankHolderLastName": this.state.bankHolderLastName,
            "routingNumber": this.state.routingNumber,
            "accountNumber": this.state.accountNumber,
            "nickName": this.state.nickName,
            "paymentMethod": this.state.paymentMethod,
            "authorizationInd" : this.state.authorizationInd,
            "bankAccountType" : this.state.bankAccountType,
            "eftBankType" : this.state.eftBankType,
            "payAmount": this.state.payAmount.replace(',',''),
            "pastAmountDueChecked": this.state.pastAmountDueChecked,
            "pastAmountAndCurentChecked": this.state.pastAmountAndCurentChecked,
            "onlyCurrentChargesChecked": this.state.onlyCurrentChargesChecked,
            "payInFullChecked": this.state.payInFullChecked,
            "otherAmountChecked": this.state.otherAmountChecked,
            "pageLoadViaFlow": true,
            "cancelRecChecked": this.state.cancelRecChecked,
            "billingCode": this.state.billingCode,
            "cardNumberLastFour": this.state.cardNumberLastFour,
            "cardType": this.state.cardType,
            "creditCardToken": this.state.creditCardToken,
            "expiration": this.state.expiration,
            "nameOnCard": this.state.nameOnCard,
            "creditCardId" : this.state.creditCardId,
            "itemId" : this.state.itemId,
            "paymentType" : this.state.paymentType,
            "paymentBy" : this.state.paymentBy,
            "paymentDate" : this.state.paymentDate,
            "activeWalletItems" : this.state.activeWalletItems,
            "selectedPayIndex" : this.state.selectedPayIndex,
            "fullMOPList" : this.state.fullMOPList,
            "isAddPayMethodOpen" : this.state.isAddPayMethodOpen,
            "scheduleChoice" : this.state.scheduleChoice,
            "paymentToEdit" : this.state.paymentToEdit,
            "recScheduleChoice" : this.state.recScheduleChoice,
            "ssaDates": this.state.ssaDates,
            "totalAmountDue": securePaymentFormData?.totalAmountDue ? securePaymentFormData.totalAmountDue : this.props.location?.state?.totalAmountDue,
          }
          secureLocalStorage.setItem("paymentFormData", JSON.stringify(paymentFormData));
        return true;
      }else if(this.state.errorFields && this.state.errorFields.count > 0 && this.state.errorFields.type !== 'nacha'){
      let analyticsPage = isEditPayment() ? 'update one time payment' : getOneTimePaymentFlag() ? 'make a one time payment' :  isManageRecurring() ? 'update payment method':'set up recurring payment'  
      var analyticErrorMsg='' 
      let index=0
      let invalidTxt=''
      this.state.errorFields.fields.map (function (field) {
        if(index === 0 && field.error){
          analyticErrorMsg=field.label
          index=index+1
          invalidTxt='invalid'
        }else if(field.error){
           analyticErrorMsg=analyticErrorMsg+'|'+field.label
           invalidTxt=invalidTxt+'|invalid'
        }
      })
      trackError(analyticsPage, analyticErrorMsg, 'form validation error', invalidTxt);
      console.log(analyticsPage, analyticErrorMsg, 'form validation error', invalidTxt)
      
      return false;} 
      else {return false;}
    }

    render() {
      if (this.state.isServiceError) {
        return <Redirect to="/service-error" push={true} />;
      }
      if (this.state.pageLoadViaFlow) {
        secureLocalStorage.setItem('selectedPaymentMethod', this.state.paymentMethod)
      }
        return (
            <Fragment>
            {this.state.eftFormAemRendered && this.state.selectPaySumAemRendered && !this.state.loading ? 
            (<div className={(this.state.loading ? ' text-blur' : '')}>

            <PageHeader 
                links={this.state.hLinks} 
                back_btn={this.state.back_btn} 
                title={this.state.hTitle}
                plan={getPlanInformation().planType} 
                />
             {this.checkformValidation() ? (
            <div>
              <Redirect
                to={{
                  pathname: '/federal-payment-review-and-submit',
                  state: {
                    bankHolderFirstName: this.state.bankHolderFirstName,
                    bankHolderMiddleName: this.state.bankHolderMiddleName,
                    bankHolderLastName: this.state.bankHolderLastName,
                    routingNumber: this.state.routingNumber,
                    accountNumber: this.state.accountNumber,
                    nickName: this.state.nickName,
                    paymentMethod: this.state.paymentMethod,
                    authorizationInd : this.state.authorizationInd,
                    bankAccountType : this.state.bankAccountType,
                    eftBankType : this.state.eftBankType,
                    payAmount: this.state.payAmount.replace(',',''),
                    pastAmountDueChecked: this.state.pastAmountDueChecked,
                    pastAmountAndCurentChecked: this.state.pastAmountAndCurentChecked,
                    onlyCurrentChargesChecked: this.state.onlyCurrentChargesChecked,
                    payInFullChecked: this.state.payInFullChecked,
                    otherAmountChecked: this.state.otherAmountChecked,
                    pageLoadViaFlow: true,
                    cancelRecChecked: this.state.cancelRecChecked,
                    billingCode: this.state.billingCode,
                    cardNumberLastFour: this.state.cardNumberLastFour,
                    cardType: this.state.cardType,
                    creditCardToken: this.state.creditCardToken,
                    expiration: this.state.expiration,
                    nameOnCard: this.state.nameOnCard,
                    creditCardId : this.state.creditCardId,
                    itemId : this.state.itemId,
                    paymentType : this.state.paymentType,
                    paymentBy : this.state.paymentBy,
                    paymentDate : this.state.paymentDate,
                    activeWalletItems : this.state.activeWalletItems,
                    selectedPayIndex : this.state.selectedPayIndex,
                    fullMOPList : this.state.fullMOPList,
                    isAddPayMethodOpen : this.state.isAddPayMethodOpen,
                    scheduleChoice : this.state.scheduleChoice,
                    paymentToEdit: this.state.paymentToEdit,
                    recScheduleChoice : this.state.recScheduleChoice,
                    ssaDates: this.state.ssaDates,
                    eftNumber: this.state.eftNumber,
                    paymentsMethodValue: this.state.paymentsMethodValue,
                    monthlyPaymentPremium: this.state.monthlyPaymentPremium,
                    pastAmountDue: this.state.pastAmountDue,
                    pastAmountAndCurentCharges: this.state.pastAmountAndCurentCharges,
                    currentCharges: this.state.currentCharges,
                    pastAmountDueFound: this.state.pastAmountDueFound,
                    pastAmountAndCurentChargesFound: this.state.pastAmountAndCurentChargesFound,
                    onlyCurrentChargesFound: this.state.onlyCurrentChargesFound,
                    totalAmountDue: this.props.location?.state?.totalAmountDue,
                    authIndicator: this.state.authIndicator,
                  },
                }}
              />
            </div>
          ) : (
                
                <div className='paymentDetails fedEFTPage' id={getOneTimePaymentFlag() ? 'oneTimeFed' : 'recurringFed'}>
                    <div className='container'>
                      
                    <PageError errorFields = {this.state.errorFields} page='payment'/>
                        
                        <div className='row'>
                            <div className='paymentSumSort'>
                              {/* Federal Payment Summary Section */}
                                <FedPaymentSummary 
                                  paymentDate={this.state.paymentDate}
                                  summaryCnt = {this.summaryCnt}
                                  payAmount={this.state.payAmount}
                                  serverDate={this.state.serverDate}
                                  cancelRecChecked = {this.state.cancelRecChecked}
                                  cmsCancelledInCommittment = {this.cmsCancelledInCommittment}
                                  eftNumber = {this.state.eftNumber}
                                />
                            <div className="paymentDetailsIpad fedForm col-md-7">
                              {/* Federal Payment Amount Section */}
                                <div className="paymentAmountRadios">
                                    <FedPaymentAmount 
                                        selectAmntCnt = {this.selectAmntCnt}
                                        pastAmountDueFound={this.state.pastAmountDueFound}
                                        pastAmountDue={this.state.pastAmountDue}
                                        pastAmountDueChecked={this.state.pastAmountDueChecked}
                                        firstDayOfMonth={this.state.firstDayOfMonth}
                                        pastAmountAndCurentChargesFound={this.state.pastAmountAndCurentChargesFound}
                                        pastAmountAndCurentCharges={this.state.pastAmountAndCurentCharges}
                                        pastAmountAndCurentChecked={this.state.pastAmountAndCurentChecked}
                                        currentPaymentDate={this.state.currentPaymentDate}
                                        onlyCurrentChargesFound={this.state.onlyCurrentChargesFound}
                                        otherAmountChecked={this.state.otherAmountChecked}
                                        otherAmountErrorclassName={this.state.otherAmountErrorClass}
                                        otherAmountDisplay={this.state.otherAmountDisplay}
                                        otherAmountError={this.state.otherAmountError}
                                        cMSSSAFound={this.state.cMSSSAFound}
                                        handleAmountSelection={this.handleAmountSelection}
                                        handleOtherAmountSelection={this.handleOtherAmountSelection}
                                        validateOtherAmountValue={this.validateOtherAmountValue}
                                        handleEmptyOtherAmount={this.handleEmptyOtherAmount}
                                        otherAmountRef={this.otherAmountRef}
                                        onlyCurrentChargesChecked={this.state.onlyCurrentChargesChecked}
                                        payInFullChecked={this.state.payInFullChecked}
                                        currentCharges={this.state.currentCharges}
                                        estimatedAnnualPremium={this.state.estimatedAnnualPremium}
                                        amtExplained={this.amountsExplainedModal}
                                        amtselectcss={this.state.amtselectcss}
                                        payAmount={(this.props.location.state || isEditPayment())? this.state.payAmount.replace('$', '') : undefined}                                        
                                        changeDetailsView={this.props.location.state ? true : false}
                                        paymentsMethodValue={this.state.paymentsMethodValue}
                                    />
                                </div>
                                {/* Federal cancel recurring section */}
                                {
                                 getRecurringPaymentFlag() && isManageRecurring() && 
                                 <div className="cancelRecSec">
                                   <Typography.H3>{this.cancelRecurringPayment.whatToUpdate}</Typography.H3>
                                  <div className="fedRecNote">
                                      {!isCurrentRecurring() ? <Markup content={this.cancelRecurringPayment.paymentMethodBeginNextMonth} />   : null }
                                   </div>

                                  <Typography.H4 className="cancel_Rec_Title">{this.cancelRecurringPayment.cancelRecurring}</Typography.H4>
                                  <Typography.Paragraph><Markup content={this.cancelRecurringPayment.cancelRecurringNote}/></Typography.Paragraph>
                                  <Checkbox
                                    id="cancelCheck"
                                    label={this.cancelRecurringPayment.cancelRecurringRadioLbl}
                                    checked = {this.state.cancelRecChecked}
                                    onChange= {()=> {
                                      if(!this.state.cancelRecChecked) {
                                        this.analyticsTrackEvent(true)
                                        this.resetWalletPayMethod()
                                        this.setState({recScheduleChoice:''})
                                        if(this.state.isAddPayMethodOpen) {
                                          this.eftRef.current.resetOnlyField()
                                          this.setState({errorFields : null, superUserError: false})
                                          let btns = Array.from(document.getElementsByTagName('button'))
                                          let btn = btns && btns.length > 0 ? btns.filter(el=> el.id.match(/1001/g)) : null
                                          if(btn) {
                                            btn[0].click()
                                          }
                                        }
                                      } else {
                                        this.setState({recScheduleChoice:'startNextMonth'})
                                        this.analyticsTrackEvent(false)
                                      }

                                      this.setState({cancelRecChecked : !this.state.cancelRecChecked , errorFields : !this.state.paymentMethod ? null : this.state.errorFields})
                                    }}
                                    className="uhc-lineheight-22"
                                />
                
                                 </div> 
                                }
                                {/* Federal Payment Schedule Section */}
                                { getOneTimePaymentFlag() || isEditPayment() ? 
                                  <div className={isEditPayment() ? 'paymentSchedule updt' : 'paymentSchedule'}>
                                  <Typography.H3>{this.schedulePay.schedulePayHeading}</Typography.H3>
                                  <SchedulePayment 
                                      setPaymentDate={this.setPaymentDate}
                                      scheduleChoice={this.state.scheduleChoice}
                                      setScheduleChoice={this.setScheduleChoice}
                                      paymentDate={this.state.paymentDate}
                                      isRedirectFromReview = {this.state.pageLoadViaFlow}
                                      schedulePayHeading={this.schedulePay.schedulePayHeading}
                                      schedulePaymentDateLbl={this.schedulePay.schedulePaymentDateLbl}
                                      cssState = {this.state.datePickerCssState}
                                      datePickerErrorText = {this.state.datePickerErrorText}
                                      onBlurDatePicker = {this.onBlurDatePicker}
                                      paymentToEdit={this.state.paymentToEdit}
                                      isPaymentToday = {this.isPaymentToday()}
                                      />
                                      {isEditPayment() && <Typography.Paragraph className='update-payment-notice'><Markup content={selectPaySumAem?.auth_updt_otp_note}/></Typography.Paragraph>}
                                  </div>
                                : null}
                              {/* Federal Choose a payment method Section */}
                              { !isEditPayment() && 

                              <div className={getRecurringPaymentFlag() ? 'choosePayMethod mt-5' : 'choosePayMethod'} id="choose_payID">
                                  {getRecurringPaymentFlag() && 
                                      <>
                                      {
                                        getRecurringPaymentFlag() && !isManageRecurring() ? 
                                        <Typography.H3>{'1. ' + selectPaySumAem?.auth_sch_rec_pay_sub_heading} </Typography.H3>
                                        : isManageRecurring() ?
                                        <Typography.H4 className="cancel_Rec_Title">{selectPaySumAem?.auth_updt_sch_sub_heading} </Typography.H4>
                                        : null
                                      }

                                      <Typography.Paragraph id="srp_note" className={isManageRecurring() ? "mt-4 ml-0" : "mt-4"}><Markup content={selectPaySumAem?.auth_sch_rec_pay_note}/></Typography.Paragraph>
                                      <div className={isManageRecurring() ? "scheduleRadios recSchPayRadios" : 'scheduleRadios mt-4'}>
                                      <Form>
                                        <FormGroup>
                                        <legend class="sr-only">{getRecurringPaymentFlag() && !isManageRecurring() ? selectPaySumAem?.auth_sch_rec_pay_sub_heading : isManageRecurring() ? selectPaySumAem?.auth_updt_sch_sub_heading : null}</legend>
                                            <RadioButton 
                                                checked={this.state.recScheduleChoice === 'startNextMonth'}
                                                label={this.cmsCancelledInCommittment() ? 'Start next available month' : selectPaySumAem?.auth_start_next_month_radio_lbl}
                                                value="startNextMonth"
                                                onChange={(e) => this.handleRecScheduleChoice(e)}
                                                aria-describedby="srp_note"
                                            />
                                            <RadioButton 
                                                checked={this.state.recScheduleChoice === 'scheduleFutureMonth'}
                                                label={selectPaySumAem?.auth_sch_future_month_radio_lbl}
                                                value="scheduleFutureMonth"
                                                onChange={(e) => this.handleRecScheduleChoice(e)}
                                                id="sch_Future_Radio"
                                            />
                                        </FormGroup>
                                    </Form>
                                    { this.state.recScheduleChoice === 'scheduleFutureMonth' &&
                                      <div className="datePicker recSchPayDP">
                                          <Typography.Paragraph id="redSch_note_id"><Markup content={selectPaySumAem?.auth_sch_future_note}/></Typography.Paragraph>
                                      
                                            <label for="sch_Month_ID" className="tds-text__paragraph recSchLabel">{selectPaySumAem?.auth_month_label_text}</label>
                                            <Form.Select id="sch_Month_ID" onChange={e => this.chooseFutureMonth(e)}>
                                              {this.generateFutureMonthList()}
                                            </Form.Select>
                                          
                                      </div>
                                    }
                                    </div>
                                      </>
                                      }
                                  {(getOneTimePaymentFlag() || (getRecurringPaymentFlag() && !isManageRecurring())) &&  <Typography.H3>{this.choosePay.choosePayHeading}</Typography.H3>}
                                  {getRecurringPaymentFlag() && isManageRecurring() && <Typography.H4 className="cancel_Rec_Title">{this.choosePay.updateCurrentPayMethodHeading}</Typography.H4>}

                                  {
                                    getOneTimePaymentFlag() ? 
                                    <Typography.H4>{this.choosePay.choosePaySubHeading}</Typography.H4>
                                    :
                                    getRecurringPaymentFlag() && !isManageRecurring() ?
                                    <Typography.H4>{this.choosePay.choosePaySubHeading}</Typography.H4>
                                    :
                                    getRecurringPaymentFlag() && isManageRecurring() ?
                                    <Typography.H5 className="recentSaved">{this.choosePay.choosePaySubHeading}</Typography.H5>
                                    : null
                                  }
                                  
                                  <div className='paymentMethods recentSaved'>
                                  <fieldset className="recentlySavedItems">
                                    <legend className="sr-only">{this.choosePay.choosePayHeading} {this.choosePay.choosePaySubHeading}</legend>
                                      {!this.state.loading && this.state.activeWalletItems?.length > 0 ?
                                      this.checkBankAccountInfo().length > 0 ?
                                      <FedPayMethods 
                                      handlePaymentMethodWalletSelection={this.handlePaymentMethodWalletSelection}
                                      startingIndex='0' 
                                      endingIndex='2'
                                      selectedPayIndex = {this.state.selectedPayIndex}
                                      paymentMethods={this.state.activeWalletItems} />
                                      :
                                      <div className="noSavedPayments">
                                        <Typography.Paragraph>{this.choosePay.noWalletItemsFound}</Typography.Paragraph>
                                      </div>
                                      :
                                      <div className="noSavedPayments">
                                        <Typography.Paragraph>{this.choosePay.noWalletItemsFound}</Typography.Paragraph>
                                      </div>
                                      }
                                      </fieldset>
                                  </div>
                                  {this.state.fullMOPList && this.state.activeWalletItems?.length > 2 &&
                                  <Typography.H4>{this.choosePay.choosePaySubHeading2}</Typography.H4>
                                  }
                                  {this.state.activeWalletItems?.length > 2 && !this.state.loading &&  
                                  <div className={this.state.activeWalletItems?.length > 2 ? 'paymentMethods moreMethods' : 'paymentMethods moreMethods showMoreLessHide'}>
                                    <fieldset className="moreSavedItems" id="morePayMethods">
                                    <legend className="sr-only"> {this.choosePay.choosePaySubHeading2}</legend>
                                  {this.state.fullMOPList &&
                                      <FedPayMethods 
                                          handlePaymentMethodWalletSelection={this.handlePaymentMethodWalletSelection} 
                                          startingIndex='2' 
                                          endingIndex={this.state.activeWalletItems?.length}
                                          selectedPayIndex = {this.state.selectedPayIndex}
                                          paymentMethods={this.state.activeWalletItems} 
                                          />
                                  }
                                  </fieldset>
                                  {this.state.activeWalletItems  ? 
                                  this.state.fullMOPList ? 
                                  <Button noIcon id='showMoreLess' aria-expanded="false" aria-controls="morePayMethods" className='showLessMorePayments' onClick={() => this.showMoreLessMethods()}>{this.state.activeWalletItems?.length > 2 && this.choosePay.showLessPaymentMethods}</Button> 
                                  : 
                                  <Button noIcon id='showMoreLess' aria-expanded="false" aria-controls="morePayMethods" className='showLessMorePayments' onClick={() => this.showMoreLessMethods()}>{this.state.activeWalletItems?.length > 2 && this.choosePay.showMorePaymentMethods}</Button> 
                                  :
                                  null}
                                    </div>
                                  }
                              </div>
                              }
                              {/* Federal Add payment method Section */}
                              {!isEditPayment() &&
                              <div className="addPaymentMethod fedEftForm">
                              <AccordionGroup headingLevel={4} onAccordionToggle={() => {this.toggleAddPayment()}} contentId='1001'>
                              <Accordion title={eftFormAem.auth_mw_add_new_pay_method_text}>
                                <FedPayRadioBtn handlePaymentMethodSelection = {this.handlePaymentMethodSelection}
                                  paymentMethod = {this.state.paymentMethod} eftBankType = {this.state.eftBankType}/>
                                  <FedPayEftForm 
                                    ref = {this.eftRef}
                                    eftBankType = {this.state.eftBankType}
                                    addEftCancelBtnClick = {this.addEftCancelBtnClick}
                                    eftSubmitBtnClickErrorSet = {this.eftSubmitBtnClickErrorSet} 
                                    setLoading = {this.setLoading} 
                                    getWalletDetails = {this.getWalletDetails}
                                    getWalletItemNum={this.getWalletItemNum} 
                                    paymentMethod = {this.state.paymentMethod}
                                    eftButtonHide = {false}
                                    isAddPayMethodOpen = {this.state.isAddPayMethodOpen}
                                    checkOtherError = {this.pageErrorCount}
                                    setBankAccBeforeReviewSubmit = {this.setBankAccBeforeReviewSubmit}
                                    superUser={this.state.superUser}
                                    showSuperUserError={this.showSuperUserError}
                                    page={'payment'}
                                    authIndicator={this.state.authorizationInd}
                                    setAuthIndicator={this.setAuthIndicator}
                                    />
                              {(this.state.paymentMethod === 'CCIframe' || this.state.ccClicked) && 
                                <CreditCardGPS 
                                  isPayment = {true} 
                                  productType={getProductType()} 
                                  addCardText = {eftFormAem.auth_mw_add_card_txt} 
                                  paymentMethod = {this.state.paymentMethod} 
                                  iframeShow={this.state.paymentMethod === 'CCIframe' ? 'show' : 'hide'}
                                  paymentsMethodValue={this.state.paymentsMethodValue}
                                />
                              }
                              </Accordion>
                              </AccordionGroup>
                            </div>
                            }
                            {
                            !this.state.isAddPayMethodOpen && <div className='fedpaymentBtnGroup'>
                               <button
                                name="next"
                                aria-disabled="false"
                                id="submitBtn"
                                className="tds-button tds-button--primary-one tds-margin-xsm-horizontal"
                                onClick={this.navigateTo}
                                >
                                {this.schedulePay.reviewSubmitBtnTxt}
                                </button>

                                <PaymentModals
                                modalContent={this.cancelPaymentModal}
                                modalType='cancelPayment'
                                modalID="cancelPayBtn"
                                cancel_btn_txt={this.schedulePay.cancelBtnTxt}
                                />
                            </div>
                        }
                            {
                              (this.state.superUserError && this.state.paymentMethod !== 'CCIframe') ? <div className={this.state.isAddPayMethodOpen ? 'superUserError eft' : 'superUserError'}><Notification  notificationType='error'> <span style={{fontWeight: 'bold' }}> {eftFormAem.auth_wallet_memberauth_not_allowed + ' ' }</span> {eftFormAem.auth_wallet_memberauth_error_msg} </Notification></div> : ''
                            }
                          {getRecurringPaymentFlag() ? <div className="tds-typography__paragraph mt-5 uhc-margin-left-right">

                            <PaymentModals
                              modalContent={this.ssbModal}
                              modalType='ssb'
                              setup_footer_note={this.ssbModal.setup_footer_note}
                              ssb_link_text={this.ssbModal.ssb_link_text} />
                          </div> : null}

                                </div>
                           </div>
                       </div>
                   </div>
                </div>
            )}
            </div>) : <Spinner />}
            </Fragment>
        )
    }
}

export default FedPaymentForm
