import axios from 'axios';
import { logger } from '../logger';
import commonConstants from '../../server/util/commonConstants';
import appConfigs from '../app.config';
import { getMemberID } from '../util/localStorageRepo';

const ccPayments = axios.create({
	baseURL: appConfigs.api_base_url
});


let tokenizationRequest = {
    "requestType": commonConstants.TOKENIZATION_REQUEST_TYPE,
    "accountId":"",
    "processingMode": commonConstants.TOKENIZATION_PROCESSING_MODE,
    "accountType": commonConstants.TOKENIZATION_ACCOUNT_TYPE,
    "returnURLPolicy": commonConstants.TOKENIZATION_RETURN_URL_POLICY,
    "returnURL":"",
    "cancelURL":"",
    "notifyURL": commonConstants.TOKENIZATION_NONE,
    "styleURL":"",
    "holderName":"",
    "street":null,
    "_ud_street2":null,
    "city":null,
    "state":null,
    "zipCode":null,
    "countryCode": commonConstants.TOKENIZATION_COUNTRY_CODE,
    "parsedData":"Y",
    "verificationMode": commonConstants.TOKENIZATION_VERIFICATION_MODE,
    "_ud_input1": '',
    "_ud_input2":null,
    "_ud_checkbox1Value":0, 
    "_ud_checkbox2Value":0,
    "_ud_sessionId":null,
    "userCode":null
};

export const getTokenization = async (upgAccountId, returnURL, cancelURL, styleURL, upgParseData, isEncodeUri = false) => {
    tokenizationRequest.accountId = upgAccountId;
    tokenizationRequest.returnURL = returnURL;
    tokenizationRequest.cancelURL = isEncodeUri ? encodeURIComponent(cancelURL) : cancelURL;
    tokenizationRequest.styleURL = styleURL;
    tokenizationRequest.parsedData = isEncodeUri ? encodeURIComponent(upgParseData) : upgParseData;
	return ccPayments.post(appConfigs.get_tokenization, tokenizationRequest)
	.catch(error => {
		logger.error(error.message);
		throw error;
	})
};



export const saveAndUpdateToken = async (token, transactionId, accountId) => {
    let saveAndUpdateTokenRequest = {
        "tokenNum": token, 
        "transId": transactionId,
        "tokenizationStatus":"created",
        "accountId": accountId,
        "uuid": JSON.parse(localStorage.getItem('consumerDetails')).userName,
    };
	return ccPayments.post(appConfigs.save_or_update_token, saveAndUpdateTokenRequest)
	.catch(error => {
		logger.error(error.message);
		throw error;
	})
};

export const ccPostPaymentResults = async (requestData) => {
    /* let postPaymentRequest = {
        "paymentMethodsRequest": {
            "controlModifiers": {
                "gpsSourceSystemParameters": {
                    "clientId": "",
                    "userId": ""
                }
            },
            "paymentMethods": {
                "accountHolderFirstName": requestData.accountHolderFirstName,
                "accountHolderLastName": requestData.accountHolderLastName,
                "accountHolderMiddleName": requestData.accountHolderMiddleName,
                "authImageNumber": "",
                "authorizationMedium": commonConstants.POST_PAYMENT_AUTHORIZATION_MEDIUM,
                "authorizationMediumId": commonConstants.POST_PAYMENT_AUTHORIZATION_MEDIUM_ID,
                "bankAccountType": commonConstants.POST_PAYMENT_BANK_ACCOUNT_TYPE,
                "bankAccountTypeId": commonConstants.POST_PAYMENT_BANK_ACCOUNT_TYPE_ID,
                "bankNumber": requestData.bankNumber,
                "billingProfileId": "",
                "ccBillingId": requestData.ccBillingId,
                "ccCustId": "",
                "ccExpiration": requestData.ccExpiration,      
                "ccNumberLastFour": requestData.ccNumberLastFour,
                "confirmationNumber": "",
                "confirmationType": "",
                "creditCard": {
                    "encryptedTrack": "",
                    "expiration": requestData.expiration,
                    "nameOnCard": requestData.nameOnCard,
                    "numberLastFour": requestData.numberLastFour,
                    "type": requestData.creditCardType,
                    "typeId": requestData.creditCardTypeId,
                    "unstoreInd": ""
                },
                "householdBillingProfileId": "",
                "householdId": requestData.householdId,
                "initWithdrawalProcessedInd": "",
                "inputSourceSystem": "",
                "inputType": "",
                "insuredPlanId": requestData.insuredPlanId, 
                "keepCardOnFileInd": "",
                "oneTimeAmount": requestData.payAmount,
                "paymentFrequencyType": commonConstants.POST_PAYMENT_FREQUENCY_TYPE,
                "paymentFrequencyTypeId": commonConstants.POST_PAYMENT_FREQUENCY_TYPE_ID,
                "paymentMethod": "",    
                "paymentMethodAccount": "",
                "paymentMethodId": commonConstants.POST_PAYMENT_METHOD_ID,
                "routingNumber":null,
                "startDate": "",
                "stopDate": "",
                "usePrevious": commonConstants.POST_PAYMENT_USE_PREVIOUS,
                "withdrawalDay": ""
            }
        },
        "findServiceRequestnfo": {
            "userName": null,
            "password": null,
            "requestType": null,
            "accountId": requestData.accountId, 
            "transactionId": requestData.transactionId 
        },
        "paymentReconciliationRequest": {
            "paymentDate": requestData.paymentDate,
            "memberId": requestData.memberId, 
            "memberFirstname": requestData.memberFirstname, 
            "memberLastname": requestData.memberLastname, 
            "userName": "", 
            "planCategory": requestData.planCategory,
            "sourcePortal": requestData.sourcePortal 
        }
    } */

    let postPaymentRequest = {
        "postPaymentMethodSubmissionRequest": {
          "data": {
            "paymentMethods": {
             "accountHolderFirstName": requestData.accountHolderFirstName,
                "accountHolderLastName": requestData.accountHolderLastName,
                "accountHolderMiddleName": requestData.accountHolderMiddleName,
              "accountHolderPrefix": "",
              "accountHolderSuffix": "",
              "authImageNumber": "",
              "authorizationMedium": commonConstants.POST_PAYMENT_AUTHORIZATION_MEDIUM,
                "authorizationMediumId": commonConstants.POST_PAYMENT_AUTHORIZATION_MEDIUM_ID,
                "bankAccountType": commonConstants.POST_PAYMENT_BANK_ACCOUNT_TYPE,
                "bankAccountTypeId": commonConstants.POST_PAYMENT_BANK_ACCOUNT_TYPE_ID,
                "bankNumber": requestData.bankNumber,
                "billingProfileId": "",
                "ccBillingId": requestData.ccBillingId,
                "ccCustId": "",
                "ccExpiration": requestData.ccExpiration,      
                "ccNumberLastFour": requestData.ccNumberLastFour,
                "confirmationNumber": "",
                "confirmationType": "",
                "creditCard": {
                    "encryptedTrack": "",
                    "expiration": requestData.expiration,
                    "nameOnCard": requestData.nameOnCard,
                    "numberLastFour": requestData.numberLastFour,
                    "type": requestData.creditCardType,
                    "typeId": requestData.creditCardTypeId,
                    "unstoreInd": ""
                },
              "householdBillingProfileId": "",
              "householdId":requestData.householdId,
              "individualId": "",
              "initWithdrawalProcessedInd": "",
              "inputSourceSystem": "",
              "inputType": "",
              "insuredPlanId": requestData.insuredPlanId,
              "keepCardOnFileInd": "",
              "oneTimeAmount": requestData.payAmount,
              "oneTimeAmountOverrideInd": "O",
              "paymentFrequencyType": commonConstants.POST_PAYMENT_FREQUENCY_TYPE,
              "paymentFrequencyTypeId": commonConstants.POST_PAYMENT_FREQUENCY_TYPE_ID,
              "paymentMethod": "",
              "paymentMethodAccount": "",
              "paymentMethodId": commonConstants.POST_PAYMENT_METHOD_ID,
              "paymentUpdateType": "",
              "routingNumber": null,
              "startDate": "",
              "stopDate": "",
              "usePrevious":commonConstants.POST_PAYMENT_USE_PREVIOUS,
              "withdrawalDay": ""
            }
          },
          "meta": {
            "controlModifiers": {
              "gpsSourceSystemParameters": {
                "clientId": "",
                "userId": ""
              },
              "businessType": [
                commonConstants.BUSSINESS_TYPE_GOVT
              ]
            }
          }
        },
        "findServiceRequestnfo": {
          "accountId": requestData.accountId,
          "password": null,
          "requestType": null,
          "transactionId":  requestData.transactionId,
          "userName": null
        },
        "paymentReconciliationRequest": {
          "memberFirstname": requestData.memberFirstname,
          "memberId":  requestData.memberId,
          "memberLastname": requestData.memberLastname,
          "paymentDate": requestData.paymentDate,
          "planCategory": requestData.planCategory,
          "sourcePortal": requestData.sourcePortal,
          "userName": ""
        }
      }
	return ccPayments.post(appConfigs.cc_post_payment_results, postPaymentRequest)
	.catch(error => {
		logger.error(error.message);
		throw error;
	})
};



export const findPostPaymentDetails = async (transactionId, accountId) => {
    let findPaymentDetailsRequest = {
        "transactionId": transactionId,
        "accountId": accountId
    }
	return ccPayments.post(appConfigs.find_payment_details, findPaymentDetailsRequest)
	.catch(error => {
		logger.error(error.message);
		throw error;
	})
};

export default ccPayments;
