const isPlainObject = obj => {
  return Object.prototype.toString.call(obj) === '[object Object]';
}

export function triggerEvent(label, params) {
  if (typeof label === 'string') {
    const event = document.createEvent('customEvent')
    event.initCustomEvent(label, true, true, isPlainObject(params) ? params : {})
    document.body.dispatchEvent(event)
  }
}

export function trackEvent(params) {
  const { eventType, eventName } = params
  switch (eventType) {
    case 'accordionOpen':
      triggerEvent(eventType, { accordionName: eventName })
      break
    default:
      triggerEvent(eventType, eventName)
  }
}

export function trackExitLinkClick(linkName) {
  var event = document.createEvent('customEvent')
  event.initCustomEvent('exitLink', true, true, {
    linkName: `${linkName}`, //set to the name of the button or link clicked
  });
  document.body.dispatchEvent(event);
}
export function trackLinkClick(linkName) {
  var event = document.createEvent('customEvent')
  event.initCustomEvent('linkClick', true, true, {
    linkName: `${linkName}`, //set to the name of the button or link clicked
  });
  document.body.dispatchEvent(event);
}

export function trackImpression(contentTitles) {
  var event = document.createEvent('customEvent')
  event.initCustomEvent('impressions', true, true, {
    contentTitles: `${contentTitles}`, 
  });
  document.body.dispatchEvent(event);
}

export function trackModalWindowClick(pageNameIn, modalNameIn) {
  const businessUnit = 'uhc';
  const website = 'mr';
  const siteSectionL1 = 'payments';
  const modalConstName = 'modal';
  var event = document.createEvent('customEvent')
  event.initCustomEvent('modalWindow', true, true, {
    modalName: `${businessUnit}:${website}:${siteSectionL1}:${pageNameIn}:${modalConstName}:${modalNameIn}`,
  });
  document.body.dispatchEvent(event);
}

export function trackModalWindowEarlyWarnings(pageNameIn, modalNameIn) {
  const businessUnit = 'uhc';
  const website = 'mr';
  const siteSectionL1 = 'payments';
  var event = document.createEvent('customEvent')
  event.initCustomEvent('modalWindow', true, true, {
    modalName: `${businessUnit}:${website}:${siteSectionL1}:${pageNameIn}:${modalNameIn}`,
  });
  document.body.dispatchEvent(event);
}

export function trackLinkClickEarlyWarnings(linkName) {
  var event = document.createEvent('customEvent')
  event.initCustomEvent('linkClick', true, true, {
    linkName: `${linkName}`, //set to the name of the button or link clicked
  });
  document.body.dispatchEvent(event);
}

export function trackError(errName, errFields, errType, errCodes) {
  var event = document.createEvent('customEvent')
  event.initCustomEvent('error', true, true, {
    taskName: `${errName}`,
    errorFields: `${errFields}`,
    errorType: `${errType}`,
    errorCodes: `${errCodes}`
  });
  document.body.dispatchEvent(event);
}

export function trackPageLoad(page) {
  const businessUnit = 'uhc';
  const website = 'mr';
  const siteSectionL1 = 'payments';
  const params = {
    pageName: `${businessUnit}:${website}:${siteSectionL1}:${page}`,
    businessUnit: businessUnit,
    website: website,
    siteSectionL1: siteSectionL1
  }
  triggerEvent('pageLoad', params)
}

export function trackPageLoadwithTask(pageIn, taskNameIn) {
  const businessUnit = 'uhc';
  const website = 'mr';
  const siteSectionL1 = 'payments';
  const params = {
    pageName: `${businessUnit}:${website}:${siteSectionL1}:${pageIn}`,
    businessUnit: businessUnit,
    website: website,
    siteSectionL1: siteSectionL1,
    taskStart: 'true',
    taskName: taskNameIn,
  }
  triggerEvent('pageLoad', params)
}

export function trackFormSubmit(page, formInname, formInfield) {
  const businessUnit = 'uhc';
  const website = 'mr';
  const siteSectionL1 = 'payments';
  const params = {
    pageName: `${businessUnit}:${website}:${siteSectionL1}:${page}`,
    businessUnit: businessUnit,
    website: website,
    siteSectionL1: siteSectionL1,
    formname: formInname,
    formfield: formInfield,
  }
  triggerEvent('formsubmit', params);
}

