import axios from 'axios';
import { logger } from '../../server/logger';
import appConfigs from "../../server/app.config";
const decAATokenService = axios.create({
	baseURL: appConfigs.api_base_url
});

export const getDecryptAATokenService = async (token) => {
	var decAATokenRequest = {	
			"token": token
    }


return decAATokenService.post(appConfigs.hsid_mfa_aatoken_decrypt_service_path, decAATokenRequest)
.catch(error => {
logger.error(error.message);
throw error;
})  
}

export default decAATokenService;
