import React, { Component , Fragment} from 'react';
import Iframe from 'react-iframe';
import Spinner from '../../UI/Spinner/Spinner'
import { getTokenization } from '../../../server/api/ccPayments-api'
import aemAxios, { content_path } from '../../../server/api/aem-api';
import appConfigs from '../../../server/app.config';
import { Typography , Notification} from '@uhc-tempo/components';
import { Markup } from 'interweave'
import secureLocalStorage from 'react-secure-storage';

class CreditCardGPS extends Component {
  constructor(props) {
    super(props)
    this.state = {
      requestId: '',
      loading: true,
      iframeClass: '',
      isServiceError : false,
      pretokenerror : false,
      cc_error : {},
      pre_credit_service_err :'',
      cc_sub_heading: ''
    }
  }

   componentDidMount() {
    secureLocalStorage.setItem('lastUsedEft', JSON.stringify(this.props.lastUsedEft));
    secureLocalStorage.setItem('paymentsMethodValue', this.props.paymentsMethodValue);
    console.log('Before iframe loading')
    if(this.props.iframeShow == 'show') {
      console.log('Iframe loading')
    let credit_card_json;
    aemAxios
    .get(content_path + '/payments-credit-card/payments-credit-card/jcr:content/data/master.json' + '?timestamp=' + new Date().getTime())
    .then((response) => {
      let credit_card_modal = {
        pageTitle: response.data.pageTitle,
        csPhone: response.data.csPhone,
        headerTitle: response.data.headerTitle,
        requireHolderName: response.data.requireHolderName,
        iFrame: response.data.iFrame,
        continueButton: this.props.isPayment ? response.data.continueButton : response.data.auth_cc_save_pay_method_btn_txt,
        warning_msg: response.data.warning_msg,
        warning_msg_title: response.data.warning_msg_title,
        holderNameLabel: response.data.holderNameLabel,
        accountNumberLabel: response.data.accountNumberLabel, 
        expireDateLabel: response.data.expireDateLabel,
        _ud_input1Label : this.props.productType === 'GOVT' ? response.data.auth_cc_edit_nickname_lbl : null,
        cancelButton2 : response.data.auth_cc_cancel_btn_txt,
        cardInfo: (response.data.cardInfo && response.data.cardInfo != null ? response.data.cardInfo : ""),
        message2: response.data.auth_cr_dt_inst_txt,
      }

      let isEncodeUri = response.data.auth_isEncodeURI == 'true' ? true : false

      let cc_error = {
        pre_credit_service_err: response.data.pre_credit_service_error
      }
      this.setState({
        cc_error : cc_error,
        cc_sub_heading : response.data.auth_add_card_details_heading,
        cc_change_sub_heading: response.data.auth_change_card_details_heading
      })
      credit_card_json = JSON.stringify(credit_card_modal);

        
            let merchantId = this.props.productType === 'SHIP' ? this.props.upg_accountId : secureLocalStorage.getItem('upgAccountId');
            let redirectUrl = window.location.pathname.charAt(window.location.pathname.length -1) === '/' ? '#/ccredirectgps' : '#/ccredirectgps';
            let returnUrl = window.location.origin + window.location.pathname + (window.location.pathname.charAt(window.location.pathname.length -1) === '/' ? '' : '/') +'cc-iframe' + redirectUrl;
            getTokenization(
              merchantId,
              returnUrl,
              returnUrl,
              "/resources/hpp/" + merchantId + "/paypage_card_payment_v3.css",
              credit_card_json, isEncodeUri
          ).then((response) => {
            if (
              response.data !== null &&
              response.data.success &&
              response.data.data !== null
            ) {
              let rid = response.data.data.requestId;
              let myIframe = document.getElementById('ccIframe');
              if(myIframe) {
                myIframe.setAttribute('sandbox','allow-forms allow-scripts allow-same-origin allow-top-navigation')
              }

              this.setState({
                requestId: appConfigs.upg_redirection_url + rid
              });
            } else {
              this.setState({pretokenerror: true, loading: false});
            }
          }).catch(error => {
            this.setState({ pretokenerror: true, loading: false});
          })
        
    })
    .catch((error) => {
      this.setState({
        loading: false
      });
    });
  }
    }

  hideSpinner = () => {
    this.setState({
      loading: false
    })
  }

  render() {
    return (
    <div className ={'hide '+this.props.iframeShow} id="cc_debit_frame" role="region" aria-labelledby="add_cc_title">
    { this.state.pretokenerror ? (
           <div id="TopAlertError" role="alert">
            <Notification notificationType="error" className="errorBanner">
                <Markup content={this.state.cc_error.pre_credit_service_err} />
            </Notification>
         </div>
         ): (
    <div className="p-0 mt-4 cc-frame">
      <Typography.H5 id="add_cc_title" style={{color: 'rgb(51, 51, 51)'}}>{this.props.addCardText}</Typography.H5>
        <div className="row">
        <div id='ccId' className={this.state.loading ? 'col-md-8  p-0 text-semi-blur' : 'col-md-8  p-0'} style = {{pointerEvents : this.state.loading ? 'none' : ''}} >
          {this.state.loading ? <Spinner/> : null}

            <Iframe
            id='ccIframe'
            src={this.state.requestId}
            onLoad={this.hideSpinner}
            frameborder="0"
            width="0px"
            height="0px"
            scrolling="auto"
            className={this.props.productType === 'SHIP' ? "frame-area ship" : "frame-area"}
            title="credit card iframe"
            ></Iframe>
        </div>
        </div>
    </div>
     )
    }
    </div>
    )
  }
}
export default CreditCardGPS
