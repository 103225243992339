import React, { Component } from 'react'
import './cmsSSAReq.css';
import appConfigs from '../../../server/app.config';
import config from '../../../server/config';
import FedPaymentSummary from '../summary/FedPaymentSummary';
import axios from '../../../server/api/aem-api';
import PaymentModals from '../modals/PaymentModals';
import moment from 'moment';
import PageHeader from '../page-header/PageHeader';
import { getOverviewPageLink, getPlanInformation } from '../../../server/util/localStorageRepo';
import { Typography, Form, Button, ErrorMessage, Notification } from '@uhc-tempo/components';
import { getWalletServiceAPI } from '../../../server/api/getWalletService-api';
import { addUpdateWalletServiceAPI } from '../../../server/api/addUpdateWalletService-api';
import { Redirect } from 'react-router-dom';
import Spinner from '../../UI/Spinner/Spinner';
import { trackPageLoad,trackError,trackLinkClick} from '../../../server/util/analytics'
import PageError from '../payment-details/PageError';
import secureLocalStorage from 'react-secure-storage';
export class cmsSSAReq extends Component {
    constructor(props) {
		super(props)
        this.state = {
            paymentDate: secureLocalStorage.getItem("cmsNextAvailableStartDate") ? moment(secureLocalStorage.getItem("cmsNextAvailableStartDate")).format('MM/DD/YYYY') : null,
            payAmount: "0.00",
            serverDate: moment().format('MM/DD/YYYY'),
            cancelRecChecked: false,
            back_btn: '',
            hLinks: [],
            hTitle: '',
            cmsAemRendered: true,
            ssaNextAvailableDate: '',
            isRequestSSASuc : false,
            isSSACheckBox : false,
            isSSACheckBoxError : 'default',
            loading: true,
            superUser: localStorage.getItem('superUser') === 'true' ? true : false,
            showSuperUserErr: false
        }
	}


    summaryCnt= {}

    componentDidMount() {
        trackPageLoad('request ssa/rrb deduction');
        window.scroll(0,0)
        this.callCmsSSAAem();
    }


    callCmsSSAAem() {
        
        try {
            axios.get(appConfigs.aem_base_url + config.REACT_APP_CONTENT_PATH + '/cms-ssa-request/cms-ssa-request-content/jcr:content/data/master.json?timestamp=' + new Date().getTime())
            .then(resp => {
                if (resp) {
                    this.setState({
                        header: resp.data.auth_cms_ssa_header,
                        breadcrumbOoverview: resp.data.auth_cms_ssa_breadcrumb_overview,
                        explainModalHeader: resp.data.auth_cms_ssa_explain_modal_header,
                        modalCloseBtn: resp.data.auth_cms_ssa_close_btn_txt,
                        requestHeader: resp.data.auth_cms_ssa_request_header,
                        chkboxErrMsg: resp.data.auth_sel_agreement_sbt_req_text,
                        subReqNotificationErr: resp.data.auth_sel_agreement_noti_error_text,
                        subReqApiNotificationErr: resp.data.auth_sel_agreement_api_notify_error_text,
                        cancelModalBtn_txt: resp.data.auth_cms_ssa_cancel_confirm_btn_txt,
                        cancelConfirmHeader: resp.data.auth_cms_ssa_cancel_confirm_header,
                        cancelSSAReqBtn: resp.data.auth_cms_ssa_cancel_txt,
                        submitReqBtn: resp.data.auth_cms_ssa_submit_req_btn_txt,
                        optInCheckLbl: resp.data.auth_cms_ssa_req_optIn_lbl,
                        cmsSSAStartNote: resp.data.auth_cms_ssa_req_start_note,
                        stayOnPageBtn: resp.data.auth_cms_ssa_stay_btn_txt,
                        backToOverviewBtn: resp.data.auth_cms_ssa_backTo_Overview_txt,
                        requestCancelBtn: resp.data.auth_cms_ssa_cancel_txt,
                        cancelModalHeader: resp.data.auth_cms_ssa_cancel_modal_header,
                        deductExplLink: resp.data.auth_ssarrb_deduction_inf_link,
                        deductExplModalContent: resp.data.auth_cms_ssa_explain_modal_content,
                        back_btn: resp.data.auth_cms_ssa_back_btn,
                        member_auth_error_title: resp.data.auth_member_auth_error_title,
                        member_auth_error_msg: resp.data.auth_member_auth_err_msg,
                        hLinks: [
                            {
                            title: resp.data.auth_cms_ssa_breadcrumb_overview,
                            link: getOverviewPageLink(),
                            }, 
                            {
                                title: resp.data.auth_cms_ssa_breadcrumb,
                                link: ''
                            }],
                        hTitle: resp.data.auth_cms_ssa_header

                    })
                        this.ssaExplain = {
                            header: resp.data.auth_cms_ssa_explain_modal_header,
                            desc: resp.data.auth_cms_ssa_explain_modal_content,
                            btn_txt: resp.data.auth_cms_ssa_close_btn_txt,
                        }
                    
                        this.cancelSSAModal = {
                            header: resp.data.auth_cms_ssa_cancel_modal_header,
                            cancel_btn_txt: resp.data.auth_cms_ssa_cancel_txt,
                            btn_txt1: resp.data.auth_cms_ssa_stay_btn_txt,
                            backto_overview: resp.data.auth_cms_ssa_backTo_Overview_txt,
                            cancelConfirmModal: {
                              header: resp.data.auth_cms_ssa_cancel_confirm_header,
                              bckTxt: resp.data.auth_cms_ssa_cancel_confirm_btn_txt,
                              cancelpay_txt: resp.data.auth_cms_ssa_req_cancel_btn_txt,
                              
                              backto_overview: resp.data.auth_cms_ssa_backTo_Overview_txt
                            }
                          }

                          this.summaryCnt = {
                            summary_label : resp.data.auth_fed_pay_summ_title,
                            plan_label : resp.data.auth_fed_plan_label,
                            membername_lable : resp.data.auth_fed_mem_name_label,
                            id_label : resp.data.auth_fed_id_label,
                            details_label : resp.data.auth_fed_pay_details_label,
                            summary_details: resp.data.auth_fed_pay_summ_details,
                            est_start_date_label: resp.data.auth_fed_summ_est_start_date_label,
                                }
                }
                secureLocalStorage.setItem('currPage', 'ssa-rrb')
                this.setState({ loading: false })
            })
        }
        catch(error) {
            console.log(error);
        }
    }


    cancelAllFutureRecurring = (walletData)=> {
        let today = moment().format('YYYY-MM-DD')
        let endDateMnth = moment(today).endOf('month').format('YYYY-MM-DD')
        walletData.items.map(elt=> {
        // remove all future time line.
        let timelines = elt.autopayTimelines.filter(el=> moment(el.startDate) < moment(endDateMnth))
        elt.autopayTimelines = timelines
        return elt
        })

        return walletData
    }

    futureItemIsAfterSSARequestDate = (item,ssaNextAvailableDate)=> {
        let today = moment().format('YYYY-MM-DD')
        
        let timelines = item.autopayTimelines.filter(el=> (moment(el.startDate, "'YYYY-MM-DD'") > moment(today,'YYYY-MM-DD')) &&
                                                    !(moment(el.startDate, "'YYYY-MM-DD'") < moment(ssaNextAvailableDate,'YYYY-MM-DD')))
        return timelines && (timelines.length > 0)
    }

    setNewSSARequestTimeLine = (walletData,ssaNextAvailableDate)=> {
        let ssaTimeLine = {
            "startDate": ssaNextAvailableDate,
            "stopDate": null,
            "audit": {
                "createdBy": "PORTAL",
            }
        }
        let ssaTimelines = walletData.ssaTimelines
        ssaTimelines.push(ssaTimeLine)
        walletData.ssaTimelines = ssaTimelines
        return walletData

    }

    showError = ()=> {
        let count = [!this.state.isSSACheckBox, this.state.isApiError].filter(el=> el === true).length

        if(count > 0) {
            this.setState({
                errorFields : {fields : [
                                {name : 'ssaCheckBox' , error : !this.state.isSSACheckBox, label : this.state.subReqNotificationErr},
                                {name : 'ssaApiError' , error : this.state.isApiError, label : this.state.subReqApiNotificationErr}
                              ],
                              count : 1,
                              type : 'api',
                              src : 'ssaForm'
                            }
              }, () => document.getElementById('alertbody_desktop').focus())
        window.scroll(0,0);
        } else {
            this.setState({errorFields : null})
        }
    }

    callWalletUpdateServiceAPI = (updateData)=> {
        addUpdateWalletServiceAPI(updateData).then(response=> {
          secureLocalStorage.setItem("confirmationNumber", 'Not available' );
          console.log(response);
          if(response && response.success) {
              //write success view code
              this.setState({isRequestSSASuc : true})
              secureLocalStorage.setItem("confirmationNumber", 'Not applicable for this payment method' );
          } else {
            // write error view code
            this.setState({isApiError : true, loading: false}, ()=> {this.showError()})
            window.scrollTo(0, 0);
          }
    
        }).catch((error) => {
            console.log(error);
            // write error view code
            this.setState({isApiError : true, loading: false}, ()=> {this.showError()})
            window.scrollTo(0, 0);
      })
    }

    putStopDateOnFutureRecurring = (futureItem,walletData,ssaNextAvailableDate)=> {
        let today = moment().format('YYYY-MM-DD')
        let newRecStopDate = moment(ssaNextAvailableDate,'YYYY-MM-DD').add(-1,'day').format('YYYY-MM-DD')
        let filteredFutureItems = walletData.items.filter(el=> el.itemId != futureItem.itemId)
        futureItem.autopayTimelines.map(el=> {
            if(moment(el.startDate, "'YYYY-MM-DD'") > moment(today,'YYYY-MM-DD')) {
                el.stopDate = newRecStopDate
            }
            return el
        })
        filteredFutureItems.push(futureItem)
        walletData.items = filteredFutureItems
        return walletData
    }

    putStopDateOnCurrentRecurring = (currentItem,walletData,ssaNextAvailableDate)=> {
        let today = moment().format('YYYY-MM-DD')
        let newRecStopDate = moment(ssaNextAvailableDate,'YYYY-MM-DD').add(-1,'day').format('YYYY-MM-DD')
        let filteredCurrentItems = walletData.items.filter(el=> el.itemId != currentItem.itemId)
        currentItem.autopayTimelines.map(el=> {
            if(!(moment(el.startDate, "'YYYY-MM-DD'") > moment(today,'YYYY-MM-DD')) && (el.stopDate == null || !(moment(el.stopDate, "'YYYY-MM-DD'") < moment(today,'YYYY-MM-DD')))) {
                if(el.stopDate == null) {
                    el.stopDate = newRecStopDate
                    return el
                }   
                if(moment(el.stopDate, 'YYYY-MM-DD').format('YYYY-MM-DD') == moment().endOf('month').format('YYYY-MM-DD')) {
                    return el
                } else {
                    el.stopDate = newRecStopDate
                }
            }
            return el
        })
        filteredCurrentItems.push(currentItem)
        walletData.items = filteredCurrentItems
        return walletData
    }


    onSSACheckBox = ()=> {
        if(!this.state.isSSACheckBox) {
            this.setState({isSSACheckBox : true, isSSACheckBoxError : 'default'}, ()=> {this.showError()})
        }
        this.setState({isSSACheckBox : !this.state.isSSACheckBox})
    }

    submitSSARequest = async ()=> {
        trackLinkClick('submit request');
        if (this.state.superUser) {
            this.setState({ showSuperUserErr: true})
            return;
        }

        if(!this.state.isSSACheckBox) {
            this.setState({isSSACheckBoxError : 'error', isApiError: false}, ()=> {this.showError()})
            trackError('request ssa/rrb deduction', 'select agreement to submit request', 'field validation error', 'invalid');
            console.log('request ssa/rrb deduction', 'select agreement to submit request', 'field validation error', 'invalid')
            return
        }
        
        let payload = null
        let getWalletResponse = null
        let ssaNextAvailableDate = null
        try {
            this.setState({isApiError: false, loading: true })
            const response = await getWalletServiceAPI(getPlanInformation().accountId);
            console.log(response);
            if(response.data.statusCode === 200) {
                payload = response.data.gpGetWalletResponse.data
                getWalletResponse = response.data;
                ssaNextAvailableDate = response.data.gpGetWalletResponse.data.options.ssaNextAvailableStartDate
                this.setState({
                    ssaNextAvailableDate: moment(ssaNextAvailableDate).format('MM/DD/YYYY')
                })
                if(ssaNextAvailableDate) {
                    if(!getWalletResponse.currentItem && !getWalletResponse.futureItem) {
                        let afterSetNewSSATimeLine = this.setNewSSARequestTimeLine(payload, ssaNextAvailableDate)
                        this.callWalletUpdateServiceAPI(afterSetNewSSATimeLine)
                    } else if(getWalletResponse.currentItem && !getWalletResponse.futureItem) {
                        let afterPutStopDate = this.putStopDateOnCurrentRecurring(getWalletResponse.currentItem,payload,ssaNextAvailableDate)
                        let afterSetNewSSATimeLine = this.setNewSSARequestTimeLine(afterPutStopDate,ssaNextAvailableDate)
                        this.callWalletUpdateServiceAPI(afterSetNewSSATimeLine)
                    } else if(getWalletResponse.currentItem && getWalletResponse.futureItem && this.futureItemIsAfterSSARequestDate(getWalletResponse.futureItem, ssaNextAvailableDate)) {
                        let afterPutStopDate = this.putStopDateOnCurrentRecurring(getWalletResponse.currentItem,payload,ssaNextAvailableDate)
                        let afterFutureRemove = this.cancelAllFutureRecurring(afterPutStopDate)
                        let afterSetNewSSATimeLine = this.setNewSSARequestTimeLine(afterFutureRemove,ssaNextAvailableDate)
                        this.callWalletUpdateServiceAPI(afterSetNewSSATimeLine)
                    } else if(getWalletResponse.currentItem && getWalletResponse.futureItem && !this.futureItemIsAfterSSARequestDate(getWalletResponse.futureItem, ssaNextAvailableDate)) {
                        let afterPutStopDate = this.putStopDateOnFutureRecurring(getWalletResponse.futureItem, payload, ssaNextAvailableDate)
                        let afterSetNewSSATimeLine = this.setNewSSARequestTimeLine(afterPutStopDate, ssaNextAvailableDate)
                        this.callWalletUpdateServiceAPI(afterSetNewSSATimeLine)
                    } else if(!getWalletResponse.currentItem && getWalletResponse.futureItem && this.futureItemIsAfterSSARequestDate(getWalletResponse.futureItem, ssaNextAvailableDate)) {
                        let afterFutureRemove = this.cancelAllFutureRecurring(payload)
                        let afterSetNewSSATimeLine = this.setNewSSARequestTimeLine(afterFutureRemove,ssaNextAvailableDate)
                        this.callWalletUpdateServiceAPI(afterSetNewSSATimeLine)
                    } else if(!getWalletResponse.currentItem && getWalletResponse.futureItem && !this.futureItemIsAfterSSARequestDate(getWalletResponse.futureItem, ssaNextAvailableDate)) {
                        let afterPutStopDate = this.putStopDateOnFutureRecurring(getWalletResponse.futureItem, payload, ssaNextAvailableDate)
                        let afterSetNewSSATimeLine = this.setNewSSARequestTimeLine(afterPutStopDate, ssaNextAvailableDate)
                        this.callWalletUpdateServiceAPI(afterSetNewSSATimeLine)
                    }
                }
            } else {
                this.setState({isApiError : true, loading: false}, ()=> {this.showError()})
            }
        } catch (error) {
            this.setState({isApiError : true, loading: false}, ()=> {this.showError()})  
        }         
    }
        
    render() {
        if (this.state.isRequestSSASuc) {
            // this.setState({ loading: false})
            return <Redirect 
                    to= {{
                        pathname : "/cms-ssa-confirmed", 
                        state : 
                            {
                                isCmsSsa : true, 
                                ssaNextAvailableDate: this.state.ssaNextAvailableDate, 
                                loading: false, 
                                pageLoadViaFlow: true,
                                paymentsMethodValue: this.props.location.state.paymentsMethodValue,
                                totalAmountDue: this.props.location?.state?.totalAmountDue,

                            }
                        }}
                    />;
          }

        return (
            <>
            {this.state.loading ? <Spinner /> : null}
            <div className={(this.state.loading ? ' text-blur' : '')}>
                <PageHeader
                links={this.state.hLinks} 
                back_btn={this.state.back_btn} 
                title={this.state.hTitle}
                />
                <div className='container'>
                <PageError errorFields = {this.state.errorFields} page='ssaForm'/>
                    <div className='row'>
                        <div className='ssa-rrb-sum'>
                            <div className="paymentDetailsIpad ssa-rrb-container">
                                <div className='ssa-rrb-header'>
                                    <Typography.H3>{this.state.requestHeader}</Typography.H3>
                                </div>
                                <div className='ssa-rrb-content'>
                                    { this.state.cmsAemRendered &&
                                    <PaymentModals 
                                    modalType ='ssaDeductExpl'
                                    modalContent = {this.ssaExplain}
                                    deductLink = {this.state.deductExplLink}
                                    />
                                    }
                                    <div className='ssa-rrb-check'>
                                        <Form>
                                            <Form.Group state={this.state.isSSACheckBoxError} groupName="ssa-rrb">
                                                <Form.Checkbox checked={this.state.isSSACheckBox} onChange={()=> this.onSSACheckBox()} label={this.state.optInCheckLbl} id="ssaRRBOptIn" value="startSSA" />
                                                <ErrorMessage state="error">{this.state.chkboxErrMsg} </ErrorMessage>
                                            </Form.Group>
                                        </Form>
                                    </div>
                                    <div className='paymentExplanation'>
                                        <Typography.Paragraph bodyStyle={2}>
                                            {this.state.cmsSSAStartNote}   
                                        </Typography.Paragraph>
                                    </div>
                                </div>
                                <div className="ssa-rrb-btnGrp">
                                    <Button buttonType='primary-one' onClick={this.submitSSARequest}>
                                        {this.state.submitReqBtn}
                                    </Button>
                                    { this.state.cmsAemRendered &&
                                    <PaymentModals
                                        modalContent={this.cancelSSAModal}
                                        modalType='cancelSSAReq'
                                        modalID="cancelPayBtn"
                                        cancel_btn_txt={this.state.cancelSSAReqBtn}
                                    />
                                    }
                                </div>
                                {(this.state.showSuperUserErr)&& <Notification  className={'superUserErr'} notificationType='error'> <span style={{fontWeight: 'bold' }}> {this.state.member_auth_error_title + ' ' }</span> {this.state.member_auth_error_msg} </Notification>}
                            </div>
                            <FedPaymentSummary
                            paymentDate={this.state.paymentDate}
                            summaryCnt = {this.summaryCnt}
                            payAmount={this.state.payAmount}
                            serverDate={this.state.serverDate}
                            cancelRecChecked = {this.state.cancelRecChecked}
                            cmsSSA = {true}

                            />
                        </div>   
                    </div>             
                </div>
            </div></>
        )
    }
}

export default cmsSSAReq
