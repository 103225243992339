import customerAccountSummary from '../../server/api/getCustomerAccountSummary';
import Moment from 'moment';
import { serverCurrentTime } from '../../server/api/serverTime-api';
import {getProductType} from '../../server/util/localStorageRepo';
import secureLocalStorage from 'react-secure-storage';

export const getCustomerAccountSummaryAPI = async (customerId) => {
  function resetLocalStorage() {
    secureLocalStorage.setItem('firstDayOfMonth', '')
    secureLocalStorage.setItem('pastAmountDue', '')
    secureLocalStorage.setItem('cMSSSAFound', '')
    secureLocalStorage.setItem('pastAmountDueFound', '')
    secureLocalStorage.setItem('currentCharges', '')
    secureLocalStorage.setItem('currentChargesFound', '')
    secureLocalStorage.setItem('currentPaymentDate', '')
    secureLocalStorage.setItem('pastAmountAndCurentCharges', '')
    secureLocalStorage.setItem('pastAmountAndCurentChargesFound', '')
    secureLocalStorage.setItem('onlyCurrentChargesFound', '')
    secureLocalStorage.setItem('estimatedAnnualPremium', '')
  }

  return customerAccountSummary(customerId)
    .then((response) => {
      var pastAmountDue
      var pastAmountAndCurentCharges
      var onlyCurrentChargesFound = false
      var currentCharges
      var pastAmountDueFound = false
      var pastAmountAndCurentChargesFound = false
      var currentChargesFound = false
      var currentPaymentDate
      var firstDayOfMonth
      let nextPremiumAmount
      var pendingAmount = "0.00"
      var currentBalance = "0.00"
      var pastDueAmount = "0.00"

      if (response?.response !== null) {

        currentBalance = response.response?.currentBillingStatus?.currentBalance ? response.response?.currentBillingStatus?.currentBalance : "0.00";
        pastDueAmount = response.response?.currentBillingStatus?.pastDueAmount ? response.response?.currentBillingStatus?.pastDueAmount : "0.00";
        nextPremiumAmount =
          parseFloat(currentBalance) -
          parseFloat(pastDueAmount)
        pastAmountDue = parseFloat(response.response?.currentBillingStatus?.pastDueAmount)
        pendingAmount = parseFloat(JSON.parse(secureLocalStorage.getItem('pendingAmount')));

        pastAmountDue = pastAmountDue > pendingAmount ? pastAmountDue - pendingAmount : 0;
        if (pastAmountDue > 0) {
          pastAmountDueFound = true
        }

        var target = 1
        var currenttime
        serverCurrentTime().then((time) => {
          currenttime = Number.parseInt(time.systemtimeinmillis, 10)
        })
        var today = Moment(currenttime).date(target).calendar('MM/DD/YYYY')
        firstDayOfMonth = Moment(currenttime).startOf('month').format('MM/DD/YYYY')

        if (getProductType() === 'SHIP') {
          currentChargesFound =
            nextPremiumAmount != null && nextPremiumAmount !== '' && parseFloat(nextPremiumAmount) > 0 ? true : false
          if (currentChargesFound) {
            currentPaymentDate = Moment(today).add(1, 'months').startOf('month').format('MM/DD/YYYY')
            currentCharges = parseFloat(nextPremiumAmount)
          }
        }

        if (pastAmountDueFound && currentChargesFound) {
          pastAmountAndCurentCharges = pastAmountDue + currentCharges

          pastAmountAndCurentChargesFound = true
        } else if (!pastAmountDueFound && currentChargesFound) {
          onlyCurrentChargesFound = true
        }

        resetLocalStorage()
        secureLocalStorage.setItem('firstDayOfMonth', firstDayOfMonth)
        secureLocalStorage.setItem('pastAmountDue', pastAmountDue)
        secureLocalStorage.setItem('pastAmountDueFound', pastAmountDueFound)
        secureLocalStorage.setItem('currentCharges', currentCharges)
        secureLocalStorage.setItem('currentChargesFound', currentChargesFound)
        secureLocalStorage.setItem('currentPaymentDate', currentPaymentDate)
        secureLocalStorage.setItem('pastAmountAndCurentCharges', pastAmountAndCurentCharges)
        secureLocalStorage.setItem('pastAmountAndCurentChargesFound', pastAmountAndCurentChargesFound)
        secureLocalStorage.setItem('onlyCurrentChargesFound', onlyCurrentChargesFound)

        return response
      }
    })
    .catch((error) => {
      console.log(error.message)
      return {
        callFailed: true,
      }
    })
}   