import React, { Component } from 'react';
// eslint-disable-next-line no-unused-vars
import {BrowserRouter as Router,Route, Switch, withRouter } from 'react-router-dom';
import PaymentSuccessComponent from '../../components/payments/PaymentSuccessComponent';
import ServiceError from '../../components/errors/ServiceError';
import ManageWallet from '../../components/Wallet/ManageWallet';
import PaymentForm from '../../components/payment-details/PaymentForm';
import PaymentReview from '../../components/paymentReview/PaymentReview';
import PaymentConfirmation from '../../components/PaymentConfirmation/PaymentConfirmation';
import CCRedirect from '../../components/static/CCRedirect';
import PaymentOverview from '../../components/paymentOverview/paymentOverview';
import CCRedirectGPS from '../../components/static/CCRedirectGPS';
import CCTokenSuccess from '../../components/payments/CCTokenSuccess';
import FedPaymentForm from '../../components/Wallet/FedPaymentForm';
import FedPaymentReview from '../../components/paymentReview/FedPaymentReview';
import CmsSSAReq from '../../components/cmsSSAReq/cmsSSAReq'
class PaymentRouter extends Component {
	
	render() {
		return (
			<div>
				<Switch>
					<Route path="/manage-wallet" render={() => <ManageWallet />} />
					<Route path="/service-error" render={() => <ServiceError />} />
					<Route path="/success-payments" render={() => <PaymentSuccessComponent />} />
					<Route path="/cc-success" render={() => <CCTokenSuccess />} />
					<Route path="/payment-review-and-submit" render={(props) => <PaymentReview {...props} />} />
					<Route path="/federal-payment-review-and-submit" render={(props) => <FedPaymentReview {...props} />} />
					<Route path={['/payment-confirmed','/cms-ssa-confirmed']} render={(props) => <PaymentConfirmation {...props} />} />
					<Route path="/ccredirect" render={() => <CCRedirect />} />
					<Route path="/ccredirectgps" render={() => <CCRedirectGPS/>} />
					<Route path={["/make-payment", '/recurring']} render={(props) => <PaymentForm {...props} />} />
					<Route path={["/federal-make-payment", "/federal-recurring"]} render={(props) => <FedPaymentForm {...props} />} />
					<Route path={["/federal-update-payment"]} render={(props) => <FedPaymentForm {...props} />} />
					<Route path="/cms-ssa" render={(props) => <CmsSSAReq {...props} />} />
					<Route path="/" render={(props) => <PaymentOverview {...props} />} />
				</Switch>
			</div> )
	}
}

export default withRouter(PaymentRouter);
