import React from 'react'

const Input = (props) => {
  let errorMsg = null;
  let infoMsg = null;

  let inputClasses = 'form-control ibc'
  errorMsg=(
    <>
  </>
  )
  if (props.error) {
    inputClasses = inputClasses + ' is-invalid'
    if (props.planNotRecognized) {
      errorMsg = (
        <div style={{ display: 'block' }} className="invalid-feedback">
          Could not recognize {props.label}
        </div>
      )
    } else {
      errorMsg = (
        <p id = {props.fieldError} style={{ display: 'block' }} className="invalid-feedback" aria-live="assertive">
         <span>Error:</span> Enter a valid {props.label}
        </p>
      )
    }
  } else if (props.info) {
    infoMsg = (
      <div style={{ display: 'block', fontStyle: 'italic', fontSize: '14px' }}>
        {props.info}
      </div>
    );
  }
  else if(props.inValidError){
    inputClasses = inputClasses + ' is-invalid'
    errorMsg = (
      <div id = {props.fieldError} style={{ display: 'block' }} className="invalid-feedback">
        Enter your Plan Member ID exactly as it appears on your member ID card (health insurance card).
        <small id={props.describe} className="form-help-text text-muted">7-11 digit number. May contain
        "-" before the last two numbers </small>
      </div>
     
    )
  }

  let value = null
  if (props.value) {
    value = props.value
  }

  return (
    <React.Fragment>
      <div>
        <input
          className={inputClasses}
          placeholder={props.placeholder}
          maxLength={props.maxlength}
          max={props.max}
          type={props.type}
          id={props.id}
          value={value || ''}
          name={props.id}
          disabled={props.disabled}
          readOnly={props.readonly}
          onChange={(event) => props.changeHandler(event)}
          ref={props.inputRef}
          aria-required={props.required}
          aria-invalid={props.invalid}
          autoComplete="off"
          onBlur= {(event) => props.blurHandler(event)}
        />
      </div>
      {errorMsg || infoMsg}
    </React.Fragment>
  )
}

export default Input
