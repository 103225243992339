import React, {Component} from 'react';
import PlanSummary from '../Plan/PlanSummary/PlanSummary';
import HistoryTabs from '../HistoryTabs/HistoryTabs';
import './paymentOverview.css';
import {searchPaymentHistoryAPI} from '../../../server/api/searchPaymentHistory-api'
import { overviewBillPayAem, overviewSummAem } from '../paymentOverview/paymentOverview';
import Spinner from '../../UI/Spinner/Spinner'
import {searchPaymentWalletResultsAPI} from '../../../server/api/searchPaymentWalletResults-api'
import ServiceError from '../../components/errors/ServiceError';
import Moment from 'moment';
import moment from 'moment';
import { serverCurrentTime } from '../../../server/api/serverTime-api';
import getCustomerAccountSummary from '../../../server/api/getCustomerAccountSummary';
import getBillingPayments from '../../../server/api/getBillingPayments-api';
import secureLocalStorage from 'react-secure-storage';

class PlanCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            firstDayOfMonth : '',
            readBillingAndTransDtl : null,
            customerAccountSummary : null,
            fedEftBankNumber : '',
            IsbPaymentPrintStartDate: '',
            futureFedPayMethod : '',
            futureFedPayMethodNumber : '',
            futureFedPayMethodStartDate : '',
            loading : true,
            searchPaymentMethodResult : null,
            lastFourDigits : null,
            serviceError : false,
            searchPaymentWalletResults : null,
            premiumBreakDownResults :null,
            isbBillingPayments: null,
            isbBillingError: false,
            searchPaymentHistoryError: false,
            isbPremiumDetailsError: false,
            mfaRedirectionStatus: false,
            paymentMethodISBStopDate: '',
            upcomingAmount: '0.00',
            isbInvoiceResponse: null,
            isbCustomerNotFoundError: false,
        }
        this.isMfaRedirecting = this.isMfaRedirecting.bind(this);
    }

    
    componentDidMount() {
        
            if (this.props.plan.productType === 'SHIP' && this.props.shipPlanDetails && this.props.shipPlanDetails.length > 0 ) {
                secureLocalStorage.setItem("isbLastUsedEftPayments","");
                this.callIsbPlanCardAPIS(this.props.shipPlanDetails[0]?.individualId);
            }
        this.callPlanCardAPIS();   
    }

    getLastFourDigitOfCC = (res)=> {
        if(res.currentPaymentMethod && res.currentPaymentMethod.indexOf('Credit') > -1) {
            let ccItem = res.activeItems.filter(el=> el.itemId === res.currentItemId)
            ccItem = ccItem[0]
            return ccItem ? ccItem.creditCard ? ccItem.creditCard.cardNumberLastFour : '' : ''
        } else {
            return ''
        }
    }

    getEFTLastDigit = (res)=> {
        if(res.currentPaymentMethod && res.currentPaymentMethod.indexOf('EFT') > -1) {
            let eftItem = res.activeItems.filter(el=> el.itemId === res.currentItemId)
            eftItem = eftItem[0]
            return eftItem.bankAccount.accountNumber
        } else {
            return ''
        }
    }

    getFutureFedPayMethodNumber = (res)=> {
        if(res.futurePaymentMethod && res.futurePaymentMethod.indexOf('Credit') > -1) {
            let ccItem = res.activeItems.filter(el=> el.itemId === res.futureItemId)
            ccItem = ccItem[0]
            return ccItem ? ccItem.creditCard ? ccItem.creditCard.cardNumberLastFour : '' : ''
        } else if(res.futurePaymentMethod &&  res.futurePaymentMethod.indexOf('EFT') > -1) {
            let eftItem = res.activeItems.filter(el=> el.itemId === res.futureItemId)
            eftItem = eftItem[0]
            return eftItem ? eftItem.bankAccount ? eftItem.bankAccount.accountNumber : '' : ''
        }
        return ''
    }


    isUpcomingNotRec = (res)=> {
        if((res.currentPaymentMethod && (res.currentPaymentMethod.indexOf('EFT') > -1 || res.currentPaymentMethod.indexOf('Credit') > -1)) 
        && (!res.futurePaymentMethod) ) {
            return true
        }
        return false
    }

    calculateTotalAmountDue = (res) => {
        if(res && res.data && res.data.readBillingAndTransactionDetailResult && res.data.pendingAmount && res.data.pendingAmount > 0) {
            res.data.readBillingAndTransactionDetailResult.totalAmountDue = Number.parseFloat(res.data.readBillingAndTransactionDetailResult.totalAmountDue) > Number.parseFloat(res.data.pendingAmount) ? 
                (parseFloat(res.data.readBillingAndTransactionDetailResult.totalAmountDue).toFixed(2) - res.data.pendingAmount) : 0.00;
        }
        return res.data?.readBillingAndTransactionDetailResult;
    }

    isbCalculateAmount = (res , pendingAmount) => {
        let currentBalance = '0.00';
        let pastDueAmount = '0.00';
        if(res && res.response && res.response.currentBillingStatus) {
            let currentBillingStatus = res.response.currentBillingStatus;
            currentBalance = currentBillingStatus?.currentBalance ? currentBillingStatus?.currentBalance : '0.00';
            pastDueAmount = currentBillingStatus?.pastDueAmount ? currentBillingStatus?.pastDueAmount : '0.00';
            let upcomingAmount =  (pastDueAmount != null && currentBalance != null) ? parseFloat(currentBalance) - parseFloat(pastDueAmount) : '0.00';
            this.setState({upcomingAmount});
        }
        pendingAmount = pendingAmount ? pendingAmount : '0.00';
        if(pendingAmount > 0) {
            res.response.currentBillingStatus.pastDueAmount = Number.parseFloat(pastDueAmount) > Number.parseFloat(pendingAmount) ? parseFloat(pastDueAmount).toFixed(2) - pendingAmount : 0.00;
        }
        return res;
    }
    
    calculatateEapr = (res,paymentResponse) =>{
        if(res && res.estimatedAnnualPremium && (res.estimatedAnnualPremium !== 0.00 || res.estimatedAnnualPremium !=="0.00")){

            if(paymentResponse && paymentResponse.pendingAmount && paymentResponse.pendingAmount >0){
                res.estimatedAnnualPremium =Number.parseFloat(res.estimatedAnnualPremium) >  Number.parseFloat(paymentResponse.pendingAmount) 
                                            ? (parseFloat(res.estimatedAnnualPremium).toFixed(2) - paymentResponse.pendingAmount) : 0.00;
                res.totalAmountDue =Number.parseFloat(res.totalAmountDue) > Number.parseFloat(paymentResponse.pendingAmount) ? 
                    (parseFloat(res.totalAmountDue).toFixed(2) - paymentResponse.pendingAmount) : 0.00;
            }     
        }
        res.estimatedAnnualPremium = parseFloat(res.estimatedAnnualPremium).toFixed(2);
        return res;
    }
    
    setSearchPaymentHistoryError = (val) =>{
        this.setState({searchPaymentHistoryError : val})
    }

    setIsbInvoiceResponse = (val) => {
        this.setState({isbInvoiceResponse : val}); 
    }
    isbPaymentMethodStopDate = (customerAccountSummary) => {
        if(customerAccountSummary?.response?.currentPaymentMethodList && customerAccountSummary?.response?.currentPaymentMethodList.length > 0) {
            let eftPaymentMethodList = customerAccountSummary?.response?.currentPaymentMethodList.filter(a => a.paymentMethod === 'EFT');
            if(eftPaymentMethodList.length > 0) {
                return eftPaymentMethodList[0].paymentMethodStopDate;
            }
        }
        return '';
    }
    //Plan API calls
    async callIsbPlanCardAPIS(individualId) {
        let date = null;
        let consumerDetails = JSON.parse(localStorage.getItem('consumerDetails'));
            if(secureLocalStorage.getItem('currSysTimeInMs')){
                 date = new Date(parseInt(secureLocalStorage.getItem('currSysTimeInMs')))	
                 let CSTDate=date.toLocaleString("en-US", {timeZone: "America/Chicago"}) 
                 let formattedTodayDate= Moment(CSTDate).format('YYYY-MM-DD')
                 secureLocalStorage.setItem('currentDate',formattedTodayDate);								
            } else{
                serverCurrentTime().then((time) => {
                 date = new Date(parseInt(time.systemtimeinmillis))
                 let CSTDate=date.toLocaleString("en-US", {timeZone: "America/Chicago"}) 
                 let formattedTodayDate= Moment(CSTDate).format('YYYY-MM-DD')
                 secureLocalStorage.setItem('currentDate',formattedTodayDate);	
                 secureLocalStorage.setItem('currSysTimeInMs',time.systemtimeinmillis);									
                 });
            }
        let isbBillingToDate = moment().format('YYYY-MM-DD')
        let isbLast12Months = moment().subtract(365, 'days').format('YYYY-MM-DD');
        if(this.isPreEffective()){
            isbBillingToDate = moment(consumerDetails.planProfiles[0]?.planStartDate).format('YYYY-MM-DD');
            isbLast12Months = '';
        }
        let customerAccountSummary = await getCustomerAccountSummary(individualId)
        if (
            customerAccountSummary == null ||
            customerAccountSummary.callFailed ||
            customerAccountSummary.httpStatus !== null
          ) {
            this.setState({ serviceError: true })
          } else {
            this.setState({ customerAccountSummary: customerAccountSummary, serviceError: false });
          }
        this.getIsbBillingPayments(individualId, isbBillingToDate, isbLast12Months);
        
        if(this.isPreEffective() && customerAccountSummary?.errorResponse?.httpStatus == 400 && customerAccountSummary?.errorResponse?.serverMessage?.messageText?.toLowerCase()?.includes('customer not found')) {
            this.setState({ isbCustomerNotFoundError: true })
        } else if (!this.state.serviceError) {
            this.setState({
              paymentMethodISBStopDate : this.isbPaymentMethodStopDate(this.state.customerAccountSummary)
            })
            if (this.props.fedShipPlanCount === 1) {
              this.props.setPlanData({
                isRec: this.isIsbRecPaymentSHIP(this.state.customerAccountSummary?.response),
                paymentMethod: this.isbPaymentMethod(this.state.customerAccountSummary?.response),
                isPreEffective: this.isPreEffective(),
                isFirstBillGen : (this.isPreEffective() && this.props.plan.productType === 'SHIP') ? true : false,
              })
            } else if (this.props.fedShipPlanCount > 1) {
              this.props.setPlanData({})
            }
          }
    }

    getIsbBillingPayments = async (individualId, toDate, fromDate, dateRange = null) => {
        let lastYearDate = moment().subtract(1, 'year');
        let ninetyDaysAgo = moment().subtract(90, 'days');
        let compassPaymentHistory = null;
        let billingPaymentsResponse2 = null;
        let billingPaymentsResponse = await getBillingPayments(individualId, toDate, fromDate);
        if(billingPaymentsResponse.callFailed) {
            this.setState({isbBillingError : true})
        }
        if (dateRange === 'payment.history.search.twentyfour.months') {
            let secondYearDateStart = moment().subtract(12, 'months').format('YYYY-MM-DD');
            billingPaymentsResponse2 = await getBillingPayments(individualId, secondYearDateStart, fromDate)
            if(billingPaymentsResponse2.callFailed) {
                this.setState({isbBillingError : true})
            }
        }
        if (dateRange) {
            console.log('Refresh search payment history')
            let res = await searchPaymentHistoryAPI(this.props.plan, "7", dateRange, 'ACTIVE', 'ALL')
            if((res.callFailed || !res.data.success)) {
                compassPaymentHistory = null;
                this.setState({searchPaymentHistoryError: true})
            }
            if(!this.state.searchPaymentHistoryError) {
                compassPaymentHistory = res.data.readBillingAndTransactionDetailResult.paymentHistoryNew
                console.log(compassPaymentHistory);
                this.setState({compassPaymentHistory})
                this.setState({IsbPaymentPrintStartDate : res.data?.readBillingAndTransactionDetailResult?.printStartDate})
            }
        } else {
            compassPaymentHistory = this.state?.readBillingAndTransDtl? this.state?.readBillingAndTransDtl?.paymentHistoryNew : null;
            this.setState({compassPaymentHistory})
        }
        let currentPayMethodStartDate=overviewSummAem.auht_isbCurrentPaymentMethodStartDate ? moment(overviewSummAem.auht_isbCurrentPaymentMethodStartDate).format('YYYY-MM-DD'):'';
        let currentPayMethodStoptDate=overviewSummAem.auth_isbCurrentPaymentMethodStopDate ? moment(overviewSummAem.auth_isbCurrentPaymentMethodStopDate).format('YYYY-MM-DD'):'';
        if(!this.state.isbBillingError) {
            if(dateRange == null){
                if(billingPaymentsResponse?.BillingPaymentResponse?.PaymentHistory?.paymentList && billingPaymentsResponse?.BillingPaymentResponse?.PaymentHistory?.paymentList?.length > 0)  {
                    let isbLastUsedEftPayments=billingPaymentsResponse?.BillingPaymentResponse?.PaymentHistory?.paymentList
                    .filter(a => a.transactionType ==="PAYMENT" && (a.tenderType === "EFT" || a.tenderType === "Automatic Payment - Checking" || a.tenderType === "Automatic Payment - Saving") && a.source ==="WEB" 
                    && (a.status ==="Pending Payment" || a.status ==="Frozen" || a.status ==="Incomplete") && (moment(a.receivedDate).isAfter(lastYearDate)));
                    secureLocalStorage.setItem("isbLastUsedEftPayments",isbLastUsedEftPayments.length >0 ? JSON.stringify(isbLastUsedEftPayments) : "") ;
                }
                let filteredPaymentHistory = billingPaymentsResponse?.BillingPaymentResponse?.PaymentHistory?.paymentList?.filter(a=> moment (a.receivedDate).isAfter(ninetyDaysAgo));
                billingPaymentsResponse['BillingPaymentResponse']['PaymentHistory']['paymentList'] = filteredPaymentHistory;
            }
            this.setState({isbBillingPayments : billingPaymentsResponse, isbBillingPayments2: billingPaymentsResponse2}) 
            if(billingPaymentsResponse?.BillingPaymentResponse?.PaymentMethod?.paymentMethodList && billingPaymentsResponse?.BillingPaymentResponse?.PaymentMethod?.paymentMethodList?.length > 0)  {
                let currentDate=secureLocalStorage.getItem("currentDate");
                let filteredPaymentMethodList = null;
                if (currentDate >= currentPayMethodStartDate && currentDate <= currentPayMethodStoptDate) {
                    filteredPaymentMethodList =
                    billingPaymentsResponse?.BillingPaymentResponse?.PaymentMethod?.paymentMethodList.filter(
                      (a) => a.paymentMethodType === 'EFT' && 
                      (a.endDate == null || a.endDate >= currentDate)
                    )
                } else {
                    filteredPaymentMethodList =
                    billingPaymentsResponse?.BillingPaymentResponse?.PaymentMethod?.paymentMethodList.filter(
                      (a) =>
                        a.paymentMethodType === 'EFT' &&
                        a.startDate <= currentDate &&
                        (a.endDate == null || a.endDate >= currentDate)
                    )
                }

                // for pre-effective
                   if(this.isPreEffective()){
                        filteredPaymentMethodList =
                        billingPaymentsResponse?.BillingPaymentResponse?.PaymentMethod?.paymentMethodList.filter(
                          (a) =>
                            a.paymentMethodType === 'EFT' &&
                            a.startDate >= currentDate &&
                            (a.endDate == null || a.endDate >= currentDate) 
                        )
                    }

                secureLocalStorage.setItem("accountAutoPayId",filteredPaymentMethodList.length > 0 ? filteredPaymentMethodList[0].accountAutoPayId : null);
            }
        } else if(this.state.isbBillingError) {
            this.setState({isbBillingPayments : null, isbBillingPayments2: null})
        }
        if(dateRange == null) {
            this.setState({
                customerAccountSummary: this.isbCalculateAmount(
                this.state.customerAccountSummary,
                this.state?.isbBillingPayments?.BillingPaymentResponse?.PendingAmount
              ),
              loading: false} , () => {
                secureLocalStorage.setItem('pendingAmount', this.state?.isbBillingPayments?.BillingPaymentResponse?.PendingAmount ? this.state?.isbBillingPayments?.BillingPaymentResponse?.PendingAmount : '0.00');
              });

        }
    }

    async callPlanCardAPIS() {
        try {
            let searchPaymentMethodResponse = null;
            let lastFourDigits = null;
            let noApiError = true
            let isPayMethodAPISucc = false
            let date = null;
            if(secureLocalStorage.getItem('currSysTimeInMs')){
                 date = new Date(parseInt(secureLocalStorage.getItem('currSysTimeInMs')))	
                 let CSTDate=date.toLocaleString("en-US", {timeZone: "America/Chicago"}) 
                 let formattedTodayDate= Moment(CSTDate).format('YYYY-MM-DD')
                 secureLocalStorage.setItem('currentDate',formattedTodayDate);								
            } else{
                serverCurrentTime().then((time) => {
                 date = new Date(parseInt(time.systemtimeinmillis))
                 let CSTDate=date.toLocaleString("en-US", {timeZone: "America/Chicago"}) 
                 let formattedTodayDate= Moment(CSTDate).format('YYYY-MM-DD')
                 secureLocalStorage.setItem('currentDate',formattedTodayDate);	
                 secureLocalStorage.setItem('currSysTimeInMs',time.systemtimeinmillis);									
                 });
            }



            const searchPaymentResponse = await searchPaymentHistoryAPI(this.props.plan, "7", 'payment.history.search.ninety.days', 'ACTIVE', 'ALL')
            if(searchPaymentResponse.callFailed || !searchPaymentResponse.data.success) {
                if(this.props.plan.productType === 'GOVT') {
                    this.setState({serviceError : true})
                    noApiError = false
                } else {
                    this.setState({searchPaymentHistoryError: true})
                }
            }
          if(this.props.plan.productType === 'GOVT'){ 
             const resData = await searchPaymentWalletResultsAPI(this.props.plan.accountId)
             if(resData && (resData.callFailed || !resData.success || resData.walletStatusCode !== 200 || resData.paymentStatusCode !== 200)) {
                noApiError = false
                this.setState({serviceError : true})
             }

             if(resData.success) {
                isPayMethodAPISucc = true
             }

             if(isPayMethodAPISucc) {
                lastFourDigits =  this.getLastFourDigitOfCC(resData)
                let fedEftBankNumber = this.getEFTLastDigit(resData)
                this.setState({fedEftBankNumber : fedEftBankNumber, searchPaymentWalletResults : resData})      
                let isUpcomingCancelPayment= this.isUpcomingNotRec(resData)
                this.setState({futureFedPayMethodNumber : this.getFutureFedPayMethodNumber(resData), futureFedPayMethod : resData.futurePaymentMethod})
                if(isUpcomingCancelPayment) {
                    let paymentStopDate=Moment(resData.currentPaymentMethodStopDate).format('MM/DD/YYYY')
                    let firstDayOfNextMonth=Moment(paymentStopDate).add(1, 'day').format('MM/DD/YYYY')
                    this.setState({futureFedPayMethod : 'DirectPay', futureFedPayMethodStartDate : firstDayOfNextMonth}) // todo
                } else if(resData.futurePaymentMethodStartDate){
                    this.setState({futureFedPayMethodStartDate : Moment(resData.futurePaymentMethodStartDate).format('MM/DD/YYYY')}) 
                }
                 searchPaymentMethodResponse = {"wrapperResponse":resData.wrapperPojo};
             }         
            }
            if (!this.state.serviceError) {
              this.setState(
                {
                  firstDayOfMonth: searchPaymentResponse.data?.firstDayOfMonth
                    ? searchPaymentResponse.data.firstDayOfMonth
                    : moment().startOf('month').format('MM/DD/YYYY'),
                  readBillingAndTransDtl:
                    this.props.plan.productType === 'GOVT'
                      ? this.calculatateEapr(
                          searchPaymentResponse.data?.readBillingAndTransactionDetailResult,
                          this.state.searchPaymentWalletResults
                        )
                      : this.calculateTotalAmountDue(searchPaymentResponse),
                  searchPaymentMethodResult: JSON.stringify(searchPaymentMethodResponse),
                  lastFourDigits: lastFourDigits,
                },
                () => {
                  this.setState({
                    loading: this.state.mfaRedirectionStatus,
                    compassPaymentHistory: this.state?.readBillingAndTransDtl?.paymentHistoryNew,
                    IsbPaymentPrintStartDate : this.state?.readBillingAndTransDtl?.printStartDate,
                  })
                  if (noApiError && this.props.fedShipPlanCount === 1) {
                    if (this.props.plan.productType === 'GOVT') {
                      this.props.setPlanData({
                        isRec: this.isRecPaymentGOVT(this.state.searchPaymentWalletResults),
                        isFuturePaymentMethod: this.state.searchPaymentHistoryError
                          ? false
                          : this.isFuturePaymentMethodGOVT(this.state.searchPaymentWalletResults),
                        paymentMethod: this.state.searchPaymentHistoryError
                          ? null
                          : isPayMethodAPISucc
                          ? this.state.searchPaymentWalletResults.currentPaymentMethod
                          : '',
                
                        isPreEffective: this.isPreEffective(this.state?.readBillingAndTransDtl),
                        isFirstBillGen: this.isFirstBillGeN(),
                        isCMSRequested: this.state.searchPaymentWalletResults.ssaDates.ssaStartDate != null,
                      })
                    }
                  } else if (noApiError && this.props.fedShipPlanCount > 1) {
                    this.props.setPlanData({})
                  }
                }
              )
            }
        } catch (error) {
            console.log(error)
        }
       
    }

    isPreEffective (readBillingAndTransDtl) {
        return JSON.parse(localStorage.getItem('consumerDetails')).preEffective && moment(JSON.parse(localStorage.getItem('consumerDetails')).planProfiles[0]?.planStartDate).isAfter(moment())
    }

    isFirstBillGeN() {
        let consumerDetails = JSON.parse(localStorage.getItem('consumerDetails'));
        return this.isPreEffective() &&  this.state.readBillingAndTransDtl?.paymentHistory?.map(bill => { return moment(bill?.dueDate,'YYYY-MM-DD').isSame(moment(consumerDetails?.planProfiles[0]?.planStartDate, 'MM/DD/YYYY'))}).includes(true);
    }
    isbPaymentMethod = (customerAccountSummary) => {
        if(customerAccountSummary?.response?.currentPaymentMethodList && customerAccountSummary?.response?.currentPaymentMethodList.length > 0) {
            let eftPaymentMethodList = customerAccountSummary?.response?.currentPaymentMethodList.filter(a => a.paymentMethod === 'EFT');
            if(eftPaymentMethodList.length > 0) {
                return eftPaymentMethodList[0].paymentMethod;
            }
        }
        return '';
    }
    isIsbRecPaymentSHIP(customerAccountSummary){
        if(customerAccountSummary?.currentPaymentMethodList && customerAccountSummary?.currentPaymentMethodList.length > 0) {
            let eftPaymentMethodList = customerAccountSummary?.currentPaymentMethodList.filter(a => a.paymentMethod === 'EFT');
            return eftPaymentMethodList.length > 0 ? true : false;
        }
        return false;
    }

    isRecPaymentGOVT(res) {
        return (res && res.currentPaymentMethod && (res.currentPaymentMethod?.toLowerCase().indexOf('eft') > -1 || res.currentPaymentMethod?.toLowerCase().indexOf('credit') > -1 || (res.currentPaymentMethod.toLowerCase().indexOf('cms') > -1 && res.futurePaymentMethod))) 
    }

    isFuturePaymentMethodSHIP(readBillingAndTransDtl){
        let futurePaymentMethod=readBillingAndTransDtl?.futurePaymentMethod;
        let paymentMethod=readBillingAndTransDtl?.paymentMethod;
            return (this.state.futureFedPayMethod && paymentMethod.indexOf('Monthly Bill') > -1 && this.state.futureFedPayMethodStartDate) ? true : (futurePaymentMethod && futurePaymentMethod.indexOf('EFT') > -1 && !(readBillingAndTransDtl.paymentMethod.indexOf('EFT') > -1)) ? true : false
    }
    isFuturePaymentMethodGOVT(res){
        return res && res.futurePaymentMethod && res.currentPaymentMethod.toLowerCase().indexOf('direct') > -1 && (res.futurePaymentMethod.toLowerCase().indexOf('eft') > -1 || res.futurePaymentMethod.toLowerCase().indexOf('credit') > -1)
    }

    handleServiceError = (isServiceError) => { this.setState({ serviceError: isServiceError })}

    gototop = () => { 
        let gotop = document.getElementById('home_gotop').children[0]
        gotop.scrollTo({top:0, left:0, behavior:'smooth'})
        if(gotop){
            gotop.focus()
        }
    }

    isMfaRedirecting(mfaRedirectionStatus, isPostMfa) {
        this.setState({ mfaRedirectionStatus: this.props.plan.productType === 'GOVT' && mfaRedirectionStatus && isPostMfa }) 
    }

    render() {
       return(
        <>  
            
            {this.state.serviceError || this.state.isbCustomerNotFoundError ? <div className="overviewcontainer"><div className="planContainer"><ServiceError isbCustomerNotFoundError = {this.state.isbCustomerNotFoundError}/></div></div> : this.state.loading ? <Spinner /> : (
                <div className={(this.state.loading ? 'overviewcontainer text-blur' : 'overviewcontainer')}>
                <div className="planContainer" id={'plan_' + this.props.planNum} aria-labelledby={'planNameID_' + this.props.planNum}>
                    <PlanSummary
                    {...this.props}
                    readBillingAndTransDtl = {this.state.readBillingAndTransDtl}
                    firstDayOfMonth = {this.state.firstDayOfMonth}
                    searchPaymentMethodResult = {this.state.searchPaymentMethodResult}
                    lastFourDigits = {this.state.lastFourDigits}
                    isPreEffective = {this.isPreEffective(this.state.readBillingAndTransDtl)}
                    isFirstBillGeN = {this.isFirstBillGeN()}
                    fedEftBankNumber = {this.state.fedEftBankNumber.substring(this.state.fedEftBankNumber.length-4)}
                    futureFedPayMethodNumber = {this.state.futureFedPayMethodNumber.length > 4 ? this.state.futureFedPayMethodNumber.substring(this.state.futureFedPayMethodNumber.length-4) : this.state.futureFedPayMethodNumber}
                    futureFedPayMethod = {this.state.futureFedPayMethod}
                    futureFedPayMethodStartDate = {this.state.futureFedPayMethodStartDate}
                    searchPaymentWalletResults = {this.state.searchPaymentWalletResults}
                    recurringOn = {this.props.plan.productType === 'SHIP' ? this.isIsbRecPaymentSHIP(this.state.customerAccountSummary?.response) : this.isRecPaymentGOVT(this.state.searchPaymentWalletResults)}
                    recurringFuture = {this.props.plan.productType === 'SHIP' ? this.isFuturePaymentMethodSHIP(this.state.readBillingAndTransDtl) : this.isFuturePaymentMethodGOVT(this.state.searchPaymentWalletResults)}
                    superUser={this.props.superUser}
                    superUserErr={this.props.superUserErr}
                    handleSuperUserErr={this.props.handleSuperUserErr}
                    premiumBreakDownResults={this.state.premiumBreakDownResults}
                    handleServiceError={this.handleServiceError}
                    customerAccountSummary={this.state.customerAccountSummary?.response}
                    billingPayments={this.props.billingPayments}
                    hideISBBanner={this.props.hideISBBanner}
                    closeISBBanner={this.props.closeISBBanner}
                    isbBillingPayments={this.state.isbBillingPayments}
                    isbPremiumDetailsError={this.state.isbPremiumDetailsError}
                    isbBillingError={this.state.isbBillingError}
                    isMFA={this.props.isMFA}
                    isMFAInvalid = {this.props.isMFAInvalid}
                    isbPendingAmount={this.state.isbPendingAmount}
                    displayErrorMessage ={this.props.displayErrorMessage}
                    isMfaRedirecting={this.isMfaRedirecting}
                    paymentMethodISBStopDate={this.state.paymentMethodISBStopDate}
                    upcomingAmount={this.state.upcomingAmount}
                    shipPlanDetails={this.props.shipPlanDetails}
                    isbInvoiceResponse={this.state.isbInvoiceResponse}
                    />
                    <HistoryTabs 
                    {...this.props}
                    readBillingAndTransDtl={this.state.readBillingAndTransDtl}
                    plan={this.props.plan}
                    fedShipPlanCount = {this.props.fedShipPlanCount}
                    handleServiceError = {this.handleServiceError}
                    isFirstBillGeN = {this.isFirstBillGeN()}
                    searchPaymentWalletResults = {this.state.searchPaymentWalletResults}
                    superUser={this.props.superUser}
                    superUserErr={this.props.superUserErr}
                    handleSuperUserErr={this.props.handleSuperUserErr}
                    billingPayments={this.props.billingPayments}
                    IsbPaymentPrintStartDate={this.state.IsbPaymentPrintStartDate}
                    isbBillingPayments={this.state.isbBillingPayments}
                    isbBillingPayments2={this.state.isbBillingPayments2}
                    compassPaymentHistory={this.state.compassPaymentHistory}
                    getIsbBillingPayments={this.getIsbBillingPayments}
                    setIsbInvoiceResponse={this.setIsbInvoiceResponse}
                    loadHistTable={this.state.loadHistTable}
                    isbBillingError={this.state.isbBillingError}
                    searchPaymentHistoryError={this.state.searchPaymentHistoryError}
                    setSearchPaymentHistoryError={this.setSearchPaymentHistoryError}
                    isbPrintStartDate={this.state.isbPrintStartDate}
                    shipPlanDetails={this.props.shipPlanDetails}
                    />
                </div>
                <div className="gototop">
                    <button className="tds-link tds-link--medium" onClick={() => this.gototop()}>
                        <span>{overviewBillPayAem.auth_gototop_label}</span>
                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" focusable="false" aria-hidden="true"><title>Go To Top</title><g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><g  fill="#196ECF"><polygon points="12 4 10.59 5.41 16.17 11 4 11 4 13 16.17 13 10.59 18.59 12 20 20 12"></polygon></g></g></svg>
                    </button>
                </div>
            </div>
            )}
        </>    
        );
    }
}

export default PlanCard
