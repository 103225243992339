import axios from 'axios';
import { logger } from '../logger';
import appConfigs from '../app.config';
import commonConstants from '../util/commonConstants';
import { getEncStr} from '../../server/util/encDec';
export const eftCreatePaymentsubmissionResults = async (commonRequest,requestData) => {  

    let eftCreatePaymentRequest = {
        
            "createPaymentReq": {
              "amount": commonRequest.payAmount,
              "annualPremiumOverride": "FALSE",
              "authorizationMedium": commonConstants.POST_PAYMENT_AUTHORIZATION_MEDIUM,
              "audit": {
                "createdBy": commonRequest.memberId
              },
              "bankAccount": {
                "accountNumber": getEncStr(requestData.accountNumber),
                "authImageNumber": "",
                "authorizationInd": requestData.authorizationInd,
                "bankAccountId": null,
                "bankAccountType": requestData.bankAccountType,
                "bankName": "",
                "firstName": requestData.accountFirstname,
                "lastName": requestData.accountLastname,
                "middleName": requestData.accountMiddlename,
                "routingNumber": getEncStr(requestData.routingNumber)
              },
              "creditCard": null,
              "householdId": "",
              "inputSourceSystem": commonConstants.INPUT_SOURCE_SYSTEM,
              //"merchantAccount": commonRequest.merchantAccount,
              "paymentId": null,
              "paymentState": commonConstants.PAYMENT_STATE,
              "providerTransactionId": "",
              "requestDate": commonRequest.currentDate,
              "upgTransactionId": "",
              "walletItemId": commonRequest.walletItemId,
              "withdrawalDate": commonRequest.withdrawalDate
            },
            "enc_householdId":commonRequest.enc_householdId,
            "paymentReconciliationRequest": {
                "memberFirstname": commonRequest.memberFirstname,
                "memberId":  commonRequest.memberId,
                "memberLastname": commonRequest.memberLastname,
                "middleName" :commonRequest.memberMiddlename,
                "paymentDate": commonRequest.paymentDate,
                "planCategory": commonRequest.planCategory,
                "sourcePortal": commonRequest.productBrand,
                "userName": commonRequest.userName,
              }
          }
      
  return axios.post(appConfigs.eft_create_payment_service_path , 
    eftCreatePaymentRequest)
    .catch(error => {
        logger.error(error.message);
        throw error;
    })
  };

  export default eftCreatePaymentsubmissionResults;